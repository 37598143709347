import { Descriptions, Button, Modal, Tag } from 'antd';
import { CodeOutlined } from '@ant-design/icons';
import { useShow } from '@refinedev/core';
import { IAddress } from "../../../../interfaces/address.d";

export const ContractFields: React.FC = () => {
    const { queryResult } = useShow<IAddress>();
    const { data } = queryResult;
    const record = data?.data;

    if (!record) return null;

    return (
        <>
            <Descriptions.Item label="Contract ABI">
                {record.abis ? (
                    <Button 
                        icon={<CodeOutlined />}
                        onClick={() => {
                            Modal.info({
                                title: 'Contract ABI',
                                width: 800,
                                content: (
                                    <pre style={{ maxHeight: '60vh', overflow: 'auto' }}>
                                        {JSON.stringify(record.abis, null, 2)}
                                    </pre>
                                ),
                            });
                        }}
                    >
                        View ABI
                    </Button>
                ) : 'No ABI available'}
            </Descriptions.Item>
            <Descriptions.Item label="Verified">
                <Tag color={record.verified ? 'success' : 'warning'}>
                    {record.verified ? 'Yes' : 'No'}
                </Tag>
            </Descriptions.Item>
        </>
    );
}; 
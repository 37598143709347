interface LabelDisplay {
    id: string;
    name: string;
    color: string;
    source?: string;
    logo?: string;
    verified?: boolean;
    isGlobal?: boolean;
    chain_ids?: string[];
    main_label_chain_ids?: string[];
}

interface AddressLabels {
    mainLabel?: LabelDisplay;  // The primary label to show in the chart
    additionalLabels: LabelDisplay[];  // Other labels for the address
}

interface EntityDisplay {
    id: string;
    name: string;
    color?: string;
    logo?: string;
    chain_ids?: string[];
    main_chain_ids?: string[];
    source_system?: string;
    isGlobal?: boolean;
}

interface AddressEntities {
    mainEntity?: EntityDisplay;  // Primary entity to show in the chart
    additionalEntities: EntityDisplay[];  // Other entities for the address
}

export const collectAddressLabels = (
    address_items_map: Record<string, any>,
    tx: any,
    address: string,
    currentChainId: string
): AddressLabels => {
    const labels = new Map<string, LabelDisplay>();
    const isToAddress = address?.toLowerCase() === tx.to_address?.toLowerCase();
    const isFromAddress = address?.toLowerCase() === tx.from_address?.toLowerCase();
    const addressType = isToAddress ? 'to' : 
                       isFromAddress ? 'from' : 
                       undefined;
    
    // Add address_items_map labels
    address_items_map?.[address]?.labels?.forEach((label: any) => {
        // Handle temporary labels
        if (label.id?.startsWith("address_label_temp_") && !label.logo) {
            const metadataKey = `${addressType}_metadata`;
            // First try to find matching entity by name
            const entityWithLogo = tx?.[metadataKey]?.entities?.find(
                (entity: any) => entity.id.startsWith("temp_") || 
                               (entity.id?.startsWith("temp_") && entity.name === label.name)
            );
            
            // If not found, try to find any temp entity
            const fallbackEntity = !entityWithLogo && tx?.[metadataKey]?.entities?.find(
                (entity: any) => entity.id?.startsWith("temp_")
            );
            
            if (entityWithLogo?.logo || fallbackEntity?.logo) {
                label.logo = entityWithLogo?.logo || fallbackEntity?.logo;
            }
        }

        if (
            label.id?.startsWith("label_") || 
            label.chain_ids?.includes(currentChainId) ||
            (label.id?.startsWith("address_label_") && (!label.chain_ids || label.chain_ids.length === 0))
        ) {
            labels.set(label.id, {
                ...label,
                isGlobal: label?.id?.startsWith("address_label_")
            });
        }
    });

    // Add global address labels
    address_items_map?.[`${addressType}_address_label`]?.forEach((label: any) => {
        const isGlobal = label?.id?.startsWith("address_label_");
        if (label.chain_ids?.includes(currentChainId)) {
            labels.set(label.id, {
                ...label,
                isGlobal
            });
        }
    });

    // Convert to array and sort by priority
    const allLabels = Array.from(labels.values());
    const sortedLabels = allLabels.sort((a, b) => {
        // First priority: main label for current chain
        const aIsMain = a.main_label_chain_ids?.includes(currentChainId) ? 1 : 0;
        const bIsMain = b.main_label_chain_ids?.includes(currentChainId) ? 1 : 0;
        if (aIsMain !== bIsMain) return bIsMain - aIsMain;

        // Second priority: global labels
        const aIsGlobal = a.isGlobal ? 1 : 0;
        const bIsGlobal = b.isGlobal ? 1 : 0;
        return bIsGlobal - aIsGlobal;
    });

    // Find the main label (first label that's marked as main for the current chain)
    const mainLabel = sortedLabels.find(label => label.main_label_chain_ids?.includes(currentChainId));
    
    // All other labels are additional
    const additionalLabels = sortedLabels.filter(label => label !== mainLabel);

    return {
        mainLabel,
        additionalLabels
    };
};

export const selectLabel = (
    address_items_map: Record<string, any>,
    tx: any,
    address: string,
    currentChainId: string
): string | undefined => {
    const { mainLabel, additionalLabels } = collectAddressLabels(address_items_map, tx, address, currentChainId);
    if (mainLabel) {
        return mainLabel.name;
    } else if (additionalLabels.length > 0) {
        return additionalLabels[0].name;
    } else {
        return undefined;
    }
}; 

export const collectAddressEntities = (
    address_items_map: Record<string, any>,
    tx: any,
    address: string,
    currentChainId: string
): AddressEntities => {
    const entities = new Map<string, EntityDisplay>();
    const isToAddress = address?.toLowerCase() === tx.to_address?.toLowerCase();
    const isFromAddress = address?.toLowerCase() === tx.from_address?.toLowerCase();
    const addressType = isToAddress ? 'to' : 
                       isFromAddress ? 'from' : 
                       undefined;
    
    // Add address_items_map entities
    address_items_map?.[address]?.entities?.forEach((entity: any) => {
        if (entity.id && entity.name) {
            entities.set(entity.id, {
                ...entity,
                isGlobal: !entity.chain_ids || entity.chain_ids.length === 0
            });
        }
    });

    // Add transaction metadata entities only if it's a from/to address
    if (addressType) {
        const metadataKey = `${addressType}_metadata`;
        tx?.[metadataKey]?.entities?.forEach((entity: any) => {
            if (entity.id?.startsWith("temp_")) {
                entities.set(entity.id, {
                    id: entity.id,
                    name: entity.name,
                    color: entity.color,
                    logo: entity.logo,
                    source_system: entity.source_system,
                    chain_ids: entity.chain_ids || [],
                    isGlobal: true
                });
            }
        });
    }

    // Convert to array and sort by priority
    const allEntities = Array.from(entities.values());
    const sortedEntities = allEntities.sort((a, b) => {
        // First priority: main entity for current chain
        const aIsMain = a.main_chain_ids?.includes(currentChainId) ? 1 : 0;
        const bIsMain = b.main_chain_ids?.includes(currentChainId) ? 1 : 0;
        if (aIsMain !== bIsMain) return bIsMain - aIsMain;

        // Second priority: entity active on current chain (but not main)
        const aIsActiveOnChain = a.chain_ids?.includes(currentChainId) ? 1 : 0;
        const bIsActiveOnChain = b.chain_ids?.includes(currentChainId) ? 1 : 0;
        if (aIsActiveOnChain !== bIsActiveOnChain) return bIsActiveOnChain - aIsActiveOnChain;

        // Third priority: global entities
        const aIsGlobal = a.isGlobal ? 1 : 0;
        const bIsGlobal = b.isGlobal ? 1 : 0;
        if (aIsGlobal !== bIsGlobal) return bIsGlobal - aIsGlobal;

        // Fourth priority: verified/source system
        const aIsVerified = a.source_system === 'moralis' ? 1 : 0;
        const bIsVerified = b.source_system === 'moralis' ? 1 : 0;
        return bIsVerified - aIsVerified;
    });

    // Find the main entity (first entity that's marked as main for the current chain)
    const mainEntity = sortedEntities.find(entity => entity.main_chain_ids?.includes(currentChainId));
    
    // If no main entity, use the first entity that's active on the current chain
    const activeEntity = !mainEntity && sortedEntities.find(entity => entity.chain_ids?.includes(currentChainId));
    
    // Use main entity, active entity, or undefined
    const primaryEntity = mainEntity || activeEntity;
    
    // All other entities are additional
    const additionalEntities = sortedEntities.filter(entity => entity !== primaryEntity);

    return {
        mainEntity: primaryEntity as EntityDisplay | undefined,
        additionalEntities
    };
};

export const selectEntityName = (
    address_items_map: Record<string, any>,
    tx: any,
    address: string,
    currentChainId: string
): string | undefined => {
    const { mainEntity, additionalEntities } = collectAddressEntities(address_items_map, tx, address, currentChainId);
    if (mainEntity) {
        return mainEntity.name;
    } else if (additionalEntities.length > 0) {
        return additionalEntities[0].name;
    } else {
        return undefined;
    }
};

export const selectEntityLogo = (
    address_items_map: Record<string, any>,
    tx: any,
    address: string,
    currentChainId: string
): string | undefined => {
    const { mainEntity, additionalEntities } = collectAddressEntities(address_items_map, tx, address, currentChainId);
    const { mainLabel, additionalLabels } = collectAddressLabels(address_items_map, tx, address, currentChainId);

    if (mainEntity?.logo) {
        return mainEntity.logo;
    } else if (additionalEntities.length > 0 && additionalEntities[0].logo) {
        return additionalEntities[0].logo;
    } else if (mainLabel?.logo) {
        return mainLabel.logo;
    } else if (additionalLabels.length > 0 && additionalLabels[0].logo) {
        return additionalLabels[0].logo;
    } else {
        return undefined;
    }
};

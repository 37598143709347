import { Card, Space, Avatar, Tag, List, Typography, Statistic, Row, Col, Tooltip, Skeleton } from 'antd';
import { DollarOutlined, PercentageOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { IDeFiProtocol, IDeFiPosition, IDeFiToken } from "interfaces/address.d";
import { formatUSD, shortenAddress } from '../../../utils/formatters';
import { useOne } from '@refinedev/core';

const { Text, Link } = Typography;

interface DefiDetailsByProtocolProps {
    address: string;
    chainId: string;
    protocolId: string;
}

interface DefiDetailsByProtocolResponse {
    request_id: string;
    results: {
        [chainId: string]: {
            data: {
                positions: IDeFiPosition[];
            }[];
            cursor: string | null;
            has_more: boolean;
        };
    };
    errors: Record<string, any>;
}

export const DefiDetailsByProtocol: React.FC<DefiDetailsByProtocolProps> = ({ 
    address,
    chainId,
    protocolId
}) => {
    const { data, isLoading } = useOne<DefiDetailsByProtocolResponse>({
        resource: "defi",
        id: address,
        meta: {
            address: address,
            chain_ids: [chainId],
            protocol: protocolId,
            limit: 1,
            operation: "getDefiPositionsByProtocol",
        },
        queryOptions: {
            enabled: true,
            refetchOnWindowFocus: false,
        }
    });

    if (isLoading) {
        return <LoadingSkeleton />;
    }

    const positions = data?.data.results[chainId]?.data[0]?.positions;

    if (!positions?.length) {
        return <Text type="secondary">No positions found</Text>;
    }

    const renderPositionDetails = (details: any) => (
        <List
            size="small"
            dataSource={Object.entries(details).filter(([_, value]) => value !== undefined)}
            renderItem={([key, value]) => {
                // Handle boolean values
                if (typeof value === 'boolean') {
                    return (
                        <List.Item>
                            <Space>
                                <Text type="secondary">{key.replace(/_/g, ' ').toUpperCase()}:</Text>
                                {value ? 
                                    <CheckCircleOutlined style={{ color: '#52c41a' }}/> : 
                                    <CloseCircleOutlined style={{ color: '#ff4d4f' }}/>
                                }
                            </Space>
                        </List.Item>
                    );
                }

                // Handle projected earnings
                if (key === 'projected_earnings_usd' && typeof value === 'object') {
                    return (
                        <>
                            <List.Item>
                                <Text type="secondary">PROJECTED EARNINGS:</Text>
                            </List.Item>
                            {Object.entries(value || {}).map(([period, amount]) => (
                                <List.Item key={period} style={{ paddingLeft: 24 }}>
                                    <Space>
                                        <Text type="secondary">{period.toUpperCase()}:</Text>
                                        <Text>{formatUSD(amount as number)}</Text>
                                    </Space>
                                </List.Item>
                            ))}
                        </>
                    );
                }

                return (
                    <List.Item>
                        <Space>
                            <Text type="secondary">{key.replace(/_/g, ' ').toUpperCase()}:</Text>
                            <Text>
                                {typeof value === 'number' ? (
                                    key.includes('price') || key.includes('value') ? 
                                        `${formatUSD(value)}` :
                                    key.includes('apy') || key.includes('share') ? 
                                        `${value.toLocaleString()}%` :
                                        formatUSD(value)
                                ) : (
                                    value?.toString() || 'N/A'
                                )}
                            </Text>
                        </Space>
                    </List.Item>
                );
            }}
        />
    );

    return (
        <Space direction="vertical" style={{ width: '100%' }} size="large">
            {positions.map((position, index) => (
                <Card 
                    key={index}
                    title={
                        <Space>
                            <Text strong>{position?.label?.toUpperCase()}</Text>
                            <Tag color="green">
                                {formatUSD(position?.balance_usd)}
                            </Tag>
                        </Space>
                    }
                >
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Card title="Tokens" size="small">
                            <List
                                dataSource={position?.tokens || []}
                                renderItem={(token: IDeFiToken) => (
                                    <List.Item>
                                        <Space>
                                            <Avatar size="small" src={token.logo} />
                                            <Text>{token.name}</Text>
                                            <Tag color="blue">{token.symbol}</Tag>
                                            {token.contract_address && (
                                                <Tooltip title={token.contract_address}>
                                                    <Link href={`https://etherscan.io/address/${token.contract_address}`} target="_blank">
                                                        {shortenAddress(token.contract_address)}
                                                    </Link>
                                                </Tooltip>
                                            )}
                                            <Text>Balance: {Number(token.balance_formatted).toLocaleString()}</Text>
                                            <Text type="secondary">
                                                {formatUSD(token.usd_value)}
                                            </Text>
                                        </Space>
                                    </List.Item>
                                )}
                            />
                        </Card>

                        {position?.position_details && (
                            <Card title="Position Details" size="small">
                                {renderPositionDetails(position?.position_details)}
                            </Card>
                        )}
                    </Space>
                </Card>
            ))}
        </Space>
    );
}; 

const LoadingSkeleton = () => (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
        <Card>
            <Skeleton.Avatar size={64} active />
            <Skeleton.Input style={{ width: 200, marginLeft: 16 }} active />
        </Card>
        <Card>
            <Skeleton.Input style={{ width: '100%' }} active />
            <List>
                {[1, 2, 3].map((i) => (
                    <List.Item key={i}>
                        <Skeleton.Avatar size="small" active />
                        <Skeleton.Input style={{ width: 150, marginLeft: 8 }} active />
                        <Skeleton.Input style={{ width: 100, marginLeft: 8 }} active />
                    </List.Item>
                ))}
            </List>
        </Card>
    </Space>
);
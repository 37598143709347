import { Space, TableColumnType, Tag, Typography } from 'antd';
// import { AddressLink } from './AddressLink';
import { EntityIcon } from '../components/EntityIcon';
import { shortenAddress } from '../../../../utils/formatters';
import { Link } from 'react-router-dom';
import { Key } from 'antd/es/table/interface';
import { IGroup } from 'interfaces/group';
import type { Transaction } from '../types';
import { IAddress, IAddressItem } from 'interfaces/address';
import { extractAddressesFromTransaction } from 'utils/extractAddresses';
import { selectEntityName, selectEntityLogo } from '../../../graph/utils/addressMetadata';

export const getEntitiesColumn = (
    cdn_domain_name: string,
    address_items_map: Record<string, IAddress>
): TableColumnType<Transaction> => ({
    title: 'Entities',
    dataIndex: 'entities',
    key: 'entities',
    width: 300,
    filters: [
        { text: 'Has Entity', value: 'has_entity' },
        { text: 'Has Label', value: 'has_label' },
        { text: 'Is Contract', value: 'is_contract' },
        { text: 'Is Verified', value: 'is_verified' },
    ],
    onFilter: (value: boolean | Key, record: Transaction) => {
        const addressItems = Object.values(address_items_map || {});
        switch (value) {
            case 'has_entity':
                return addressItems.some((item: any) => item.entities?.length > 0);
            case 'has_label':
                return addressItems.some((item: any) => item.labels?.length > 0);
            case 'is_contract':
                return addressItems.some((item: any) => item.is_contract);
            case 'is_verified':
                return addressItems.some((item: any) => item.verified);
            default:
                return false;
        }
    },
    sorter: (a, b) => {
        // Sort by number of entities, then labels
        const aItems = Object.values(a.address_items_map || {});
        const bItems = Object.values(b.address_items_map || {});
        const aCount: any = aItems.reduce((acc, item: any) => acc + (item.entities?.length || 0), 0);
        const bCount: any = bItems.reduce((acc, item: any) => acc + (item.entities?.length || 0), 0);
        return aCount - bCount;
    },
    render: (_: any, record: Transaction) => {
        const addresses = extractAddressesFromTransaction(record);
        
        return (
            <Space direction="vertical" size="small" style={{ width: '100%', minWidth: '100px' }}>
                {addresses.map((addressData, index) => {
                    const entityName = selectEntityName(address_items_map, record, addressData.address, record.chain_id);
                    const entityLogo = selectEntityLogo(address_items_map, record, addressData.address, record.chain_id);
                    const entityColor = address_items_map[addressData.address]?.entities?.[0]?.color;
                    
                    if (!entityName) return null;

                    return (
                        <Space key={index} direction="vertical" style={{ width: '100%' }}>
                            <Space>
                                {entityLogo && (
                                    <EntityIcon 
                                        name={entityName}
                                        logo={entityLogo}
                                        color={entityColor}
                                    />
                                )}
                                <Link to={`/entities/${entityName}`}>
                                    <Tag color={entityColor ? `#${entityColor}` : 'purple'}>
                                        {entityName}
                                    </Tag>
                                </Link>
                            </Space>
                        </Space>
                    );
                })}
            </Space>
        );
    }
}); 

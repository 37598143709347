import styled from "@emotion/styled";
import { Typography, Timeline as AntdTimeline } from "antd";

export const Timeline = styled(AntdTimeline)`
    .ant-timeline-item-head {
        background-color: transparent;
    }
`;

export const TimelineItem = styled(AntdTimeline.Item)``;

export const TimelineContent = styled.div<{ backgroundColor: string }>`
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 6px;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const CreatedAt = styled(Typography.Text)`
    font-size: 12px;
    cursor: default;
`;

export const Number = styled(Typography.Text)`
    cursor: pointer;
`;

export const StyledTimelineItem = styled(AntdTimeline.Item)`
    .ant-timeline-item-head {
        background-color: transparent;
    }
`;

export const EventContent = styled.div<{ backgroundColor: string }>`
    padding: 12px;
    border-radius: 6px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    margin-bottom: 16px;
`;

export const TimeStamp = styled(Typography.Text)`
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    cursor: default;
`;

export const EventLink = styled(Typography.Link)`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    
    &:hover {
        text-decoration: underline;
    }
`;
import { SubscriptionStatus } from '../../graphql/types';

const LEADER_CHECK_INTERVAL = 1000;
const LEADER_TIMEOUT = 5000;

interface LeaderMessage {
    type: 'leader-check' | 'leader-active' | 'leader-departed' | 'status-check' | 'status-response' | 'subscription-status';
    tabId: string;
    timestamp: number;
    status?: {
        isLeader: boolean;
        hasSubscription: boolean;
        state: string;
        lastMessageAt?: Date;
        client: boolean;
    };
    subscriptions?: SubscriptionStatus[];
    senderTabId?: string;
}

export const setupLeaderElection = (
    onLeaderChange: (isLeader: boolean) => void,
    debug: (message: string, data?: any) => void,
    tabId: string,
    getSubscriptionStatus?: () => SubscriptionStatus[]
) => {
    const channel = new BroadcastChannel('leader-election');
    let isLeader = false;
    let lastLeaderMessage = 0;
    let electionTimeout: NodeJS.Timeout;
    let heartbeatInterval: NodeJS.Timeout;
    let electionInProgress = false;
    let isChannelClosed = false;

    const safePostMessage = (message: LeaderMessage) => {
        try {
            if (!isChannelClosed) {
                channel.postMessage(message);
            }
        } catch (error) {
            debug('Failed to post message', { error, message });
            if (error instanceof Error && error.message.includes('Channel is closed')) {
                isChannelClosed = true;
            }
        }
    };

    const sendHeartbeat = () => {
        if (!isLeader) return;
        
        safePostMessage({ 
            type: 'leader-active',
            tabId,
            timestamp: Date.now(),
            subscriptions: getSubscriptionStatus?.()
        });
    };

    const electLeader = () => {
        if (electionInProgress || isChannelClosed) {
            debug('Election skipped', { electionInProgress, isChannelClosed });
            return;
        }

        debug('Starting leader election');
        electionInProgress = true;
        
        if (electionTimeout) {
            clearTimeout(electionTimeout);
        }

        // First, check if there's an existing leader
        safePostMessage({ 
            type: 'leader-check',
            tabId,
            timestamp: Date.now()
        });

        // Wait for responses
        electionTimeout = setTimeout(() => {
            const timeSinceLastLeader = Date.now() - lastLeaderMessage;
            
            debug('Election timeout reached', {
                timeSinceLastLeader,
                hasExistingLeader: lastLeaderMessage > 0
            });

            // Only become leader if we haven't heard from another leader recently
            if (timeSinceLastLeader > LEADER_TIMEOUT) {
                debug('No recent leader activity, becoming leader');
                isLeader = true;
                onLeaderChange(true);
                
                // Start heartbeat immediately
                sendHeartbeat();
                if (heartbeatInterval) clearInterval(heartbeatInterval);
                heartbeatInterval = setInterval(sendHeartbeat, LEADER_CHECK_INTERVAL);
                
                lastLeaderMessage = Date.now();
            } else {
                debug('Recent leader activity detected, remaining follower');
            }
            electionInProgress = false;
        }, 1000);
    };

    // Add connection state handlers
    const handleOffline = () => {
        debug('Connection lost, stepping down as leader');
        if (isLeader) {
            safePostMessage({ 
                type: 'leader-departed',
                tabId,
                timestamp: Date.now()
            });
            isLeader = false;
            onLeaderChange(false);
        }
    };

    const handleOnline = () => {
        debug('Connection restored, participating in election');
        electLeader();
    };

    const handleBeforeUnload = () => {
        if (isLeader) {
            debug('Tab closing, notifying departure');
            safePostMessage({ 
                type: 'leader-departed',
                tabId,
                timestamp: Date.now()
            });
        }
    };

    const handleMessage = (event: MessageEvent<LeaderMessage>) => {
        const { type, tabId: senderTabId, timestamp, status, subscriptions } = event.data;
        
        if (senderTabId === tabId) return; // Ignore own messages
        
        debug('Received coordination event', { type, senderTabId, timestamp });

        switch (type) {
            case 'leader-check':
                if (isLeader) {
                    debug('Responding to leader check as active leader');
                    sendHeartbeat();
                }
                break;

            case 'leader-active':
                lastLeaderMessage = Date.now();
                
                if (subscriptions) {
                    debug('Leader subscription status:', subscriptions);
                }
                
                if (isLeader) {
                    debug('Another tab claimed leadership, stepping down');
                    isLeader = false;
                    onLeaderChange(false);
                    if (heartbeatInterval) {
                        clearInterval(heartbeatInterval);
                    }
                }
                break;

            case 'leader-departed':
                debug('Leader departed, preparing new election');
                lastLeaderMessage = 0;
                if (heartbeatInterval) {
                    clearInterval(heartbeatInterval);
                }
                // Start new election with random delay to prevent race conditions
                setTimeout(() => {
                    debug('Starting delayed election after leader departure');
                    electLeader();
                }, Math.random() * 1000);
                break;

            case 'status-check':
                safePostMessage({
                    type: 'status-response',
                    tabId,
                    senderTabId,
                    status,
                    timestamp: Date.now()
                });
                break;

            case 'status-response':
                debug(`Tab ${senderTabId} status:`, {
                    ...status,
                    responseTime: Date.now() - timestamp
                });
                break;
        }
    };

    // Add event listeners
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
    window.addEventListener('beforeunload', handleBeforeUnload);
    channel.addEventListener('message', handleMessage);

    // Setup heartbeat check for leader timeout
    const heartbeatCheckInterval = setInterval(() => {
        if (!isLeader && !electionInProgress && Date.now() - lastLeaderMessage > LEADER_TIMEOUT) {
            debug('Leader timeout detected, starting new election');
            electLeader();
        }
    }, LEADER_CHECK_INTERVAL);

    // Start initial election
    setTimeout(electLeader, Math.random() * 1000);

    return () => {
        debug('Cleaning up leader election');
        
        // Remove event listeners
        window.removeEventListener('offline', handleOffline);
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('beforeunload', handleBeforeUnload);
        
        if (isLeader && !isChannelClosed) {
            try {
                safePostMessage({ 
                    type: 'leader-departed',
                    tabId,
                    timestamp: Date.now()
                });
            } catch (error) {
                debug('Failed to send departure message', { error });
            }
        }
        
        if (heartbeatInterval) clearInterval(heartbeatInterval);
        if (heartbeatCheckInterval) clearInterval(heartbeatCheckInterval);
        if (electionTimeout) clearTimeout(electionTimeout);
        
        isChannelClosed = true;
        channel.removeEventListener('message', handleMessage);
        try {
            channel.close();
        } catch (error) {
            debug('Failed to close channel', { error });
        }
    };
}; 
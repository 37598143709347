import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { S3_CANDIDATE_ATTRIBUTES, fetchCdnAttribute } from 'utils/cdnHelpers';

import { useShow, IResourceComponentsProps, useNotification, useUpdate } from "@refinedev/core";

import { Show, DeleteButton, ShowButton, useModalForm, EditButton, DateField, ListButton, RefreshButton } from "@refinedev/antd";

import { Alert, Avatar, Button, Card, Checkbox, Col, Divider, Form, Input, List, Modal, Radio, Row, Select, SelectProps, Skeleton, Space, Spin, Statistic, Switch, Tag, Tooltip, Typography, Collapse } from "antd";

import { ITransaction, IUser } from "interfaces";
import { EditOutlined, DeleteOutlined, SettingOutlined, ExpandAltOutlined, CloseOutlined, CheckOutlined, ArrowUpOutlined, ArrowDownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';

const { Title, Text } = Typography;
import { extractAddressesFromParams } from "../../utils/extractAddresses"
import { useModal } from "@refinedev/antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { TransactionGraph } from "components/graph/TransactionGraph";
import { CHAIN_CONFIG } from "../../interfaces/chain.d"
import { useConfig } from "contexts/config";
import { DecodedInputModal } from "./components/DecodedInputlModal";
import { GroupAssignmentsCard } from './components/GroupAssignmentsCard';
import { IntegrationDetailsModal } from './components/IntegrationDetailsModal';
import { IntegrationResults } from './components/IntegrationResults';
import { SharedWithUsers } from './components/SharedWithUsers';

import { formatDate, shortenAddress, getTagColorByParamType, getTooltipTitleForParamType } from "../../utils/formatters"
import { TransactionContext } from './components/TransactionContext';
import { renderAddressAvatar } from "../../components/address-avatar";
import { EventsList } from './components/EventsList';
import { InternalTransactionsList } from "./components/InternalTransactionsList";
import ContractStateHighlighter, { ContractStateData } from "components/ContractStateViewer/ContractStateHighlighter";

export const TransactionShow: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const { open } = useNotification();
    
    const { token, org_id, tenant_id, user_id, region, continent, qa_environment, domain, cdn_domain_name } = useConfig();
  
    const { queryResult } = useShow<ITransaction>(
        {
            resource: "transactions",
            id: window.location.pathname.split("/").pop(),
            queryOptions: {
                enabled: true,
                queryKey: ["transactions", window.location.pathname.split("/").pop()],
            },
        }
    );
    const { query: contractStateQueryResult } = useShow<any>(
        {
            resource: "contract_states",
            id: window.location.pathname.split("/").pop(),
            queryOptions: {
                enabled: true,
                queryKey: ["contract_states", window.location.pathname.split("/").pop()],
            },
        }
    );
    const { data: contractStateData, isLoading: isContractStateLoading, isFetching: isContractStateFetching, isRefetching: isContractStateRefetching } = contractStateQueryResult;

    const { data, isLoading, isFetching, isRefetching } = queryResult;
    const record = data?.data;
    const [displayInternalTransactionsCount, setDisplayInternalTransactionsCount] = useState<number>(10);
    const [displayEventsCount, setDisplayEventsCount] = useState<number>(15);

    const [enrichedTransaction, setEnrichedTransaction] = useState<any>(null);
    const [isLoadingAttributes, setIsLoadingAttributes] = useState(false);

    useEffect(() => {
        const fetchLargeAttributes = async () => {
            if (!record) return;

            setIsLoadingAttributes(true);
            const enriched: any = { ...record };
            
            // Check if any attribute needs to be fetched from CDN
            const hasS3References = S3_CANDIDATE_ATTRIBUTES.some(
                attr => record[attr as keyof ITransaction]?.__s3_reference__
            );

            if (hasS3References) {
                try {
                    const largeAttributes = await fetchCdnAttribute(
                        org_id,
                        tenant_id,
                        user_id,
                        record.block_timestamp,
                        record.chain_id,
                        record.hash,
                        record.type,
                    );
                    if (largeAttributes) {
                        // Merge large attributes into the enriched transaction
                        S3_CANDIDATE_ATTRIBUTES.forEach(attr => {
                            if (record[attr as keyof ITransaction]?.__s3_reference__) {
                                enriched[attr] = largeAttributes[attr];
                            }
                        });
                    }
                } catch (error) {
                    console.error('Failed to fetch large attributes:', error);
                }
            }

            setEnrichedTransaction(enriched);
            setIsLoadingAttributes(false);
        };

        fetchLargeAttributes();
    }, [record]);


    const { 
        show, 
        close, modalProps 
    } = useModal();

    const showIntegrationDetails = async (record: any) => {
        console.log("Show integration details")
        console.log(record)
        show()
        setShowIntegrationIdDetails(record);
      };
    const [showIntegrationIdDetails, setShowIntegrationIdDetails] = useState("");

    const showDecodedTransaction = async () => {
        setDecodedTransactionDetails({
            "decoded_call": decodedTransactionData?.decoded_call, 
            "decoded_internal_transactions": [], 
            "decoded_events": []
        });
        setShowTransactionDecodedInput()
    };

    const showInternalTransaction = async (record: any) => {
        setDecodedTransactionDetails({
            "decoded_call": {}, 
            "decoded_internal_transactions": [record], 
            "decoded_events": []
        });
        setShowTransactionDecodedInput()
    };

    const showEvent = async (record: any) => {
        setDecodedTransactionDetails({
            "decoded_call": {}, 
            "decoded_internal_transactions": [], 
            "decoded_events": [record]
        });
        setShowTransactionDecodedInput()
    };

    

    const [isLoadingReimportTransaction, setIsLoadingReimportTransaction] = React.useState<boolean>(false);

    const [isImportModalVisible, setIsImportModalVisible] = useState(false);
    const [importForm] = Form.useForm();

    const [isImportingTransaction, setIsImportingTransaction] = useState(false);

    async function reimportTransaction(values?: { 
        force_update: boolean,
        simple_import: boolean,
        create_address_labels: boolean 
    }, type: "reimport" | "import" = "reimport") {
        try {
            setIsLoadingReimportTransaction(true)
            const requestHeaders = {
                Authorization: `Bearer ${token.__raw}`,
                Accept: "application/json, text/plain, */*",
                "Source-Platform": "dashboard",
                "Source-Region": region.use_region,
                "Destination-Region": region.use_region,
            };

            const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
            console.log("Sending the request")


            let url
            if (qa_environment == "blue"){
                url = `${apiUrl}/private/blue/ANY/${continent.toUpperCase()}/v0/transactions/import-transaction`
            }  
            else{
                url = `${apiUrl}/private/ANY/${continent.toUpperCase()}/v0/transactions/import-transaction`
            }

            console.log(url)
            const hash = record?.hash
            const chain_id = record?.chain_id
            const force_update = values?.force_update ?? true

            let postData
            if (type == "reimport"){
                postData = {
                    "hash": hash,
                    "chain": chain_id,
                    "force_update": force_update,
                    "simple_import": values?.simple_import ?? true,
                    "create_address_labels": values?.create_address_labels ?? true
                }
            } else {
                postData = {
                    "hash": hash,
                    "chain": chain_id,
                    "force_update": true,
                    "simple_import": true,
                    "create_address_labels": true
                };
            }
            
            try {
                const response = await axios.post(
                    url,
                    JSON.stringify(postData), {
                        headers: requestHeaders
                    }
                )  
           
                setIsLoadingReimportTransaction(false)

                if (type == "reimport"){
                    open?.({
                        type: "success",
                        message: "Successfully requested the reprocess of the transaction",
                        description: "Successfully requested the reprocess of the transaction",
                        key: "hash",
                    })
                    navigate(`/transactions/show/${response?.data?.result?.message_id}`)
                }

                console.log(response)
                return response?.data;                
                
            } catch (error: any) {
                console.log(error); 
                setIsLoadingReimportTransaction(false)

                open?.({
                    type: "error",
                    message: "Error",
                    description: error.response?.data?.error?.message,
                    key: "transactions-import",
                });
            } 
        } catch (error: any) {
            const errorMessage = error.response?.data?.reason || error.message;
            open?.({
                type: "error",
                message: "Error importing transaction",
                description: errorMessage,
            });
            throw error;
        } finally {
            setIsImportingTransaction(false);
        }            
    }

    const {
        modalProps: editTagsModalProps,
        formProps: editTagsFormProps,
        show: showEditTags,
        close: closeEditTags,
        onFinish: handleEditTagsFinish,
        setId: setEditTagsId,
    } = useModalForm({
        id: `${record?.["user_id-block_timestamp-chain_id-hash-type"]}`,
        action: "edit", 
        resource: "transactions",
        redirect: "show",
        warnWhenUnsavedChanges: false,
        meta: {
            operation: "edit_tags"
        },
    });

    const {
        modalProps: editGroupsModalProps,
        formProps: editGroupsFormProps,
        show: showEditGroups,
        close: closeEditGroups,
        onFinish: handleEditGroupsFinish,
        setId: setEditGroupsId
    } = useModalForm({
        id: `${record?.["user_id-block_timestamp-chain_id-hash-type"]}`,
        action: "edit",
        resource: "transactions",
        redirect: "show",
        warnWhenUnsavedChanges: false,
        meta: {
            operation: "edit_groups"
        },
    });

    const getKnownAddresses = (uniqueAddresses: any) => {
        const knownAddresses = Array.from(uniqueAddresses).filter((address: unknown) => {
            const addressStr = (address as string).toLowerCase();
            return (
                (record?.is_search_transaction && addressStr === record?.from_address?.toLowerCase() && record?.from_address_entity) ||
                (record?.is_search_transaction && addressStr === record?.to_address?.toLowerCase() && record?.to_address_entity) ||
                record?.address_items_map?.[addressStr as keyof typeof record.address_items_map]?.labels?.[0]?.name ||
                record?.address_items_map?.[address as keyof typeof record.address_items_map]?.main_labels?.chain_id?.name
            );
        });
        return knownAddresses;
    }

    // Add this state near your other states
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Update the form submission handler
    const handleEditTagsFormSubmit = async (values: any) => {
        setIsSubmitting(true); // Start loading

        const formData = {
            tags: values.tags || [],
            group_names: record?.group_names?.map((group: any) => group.name) || [],
            from_address_label: record?.from_address_label
                ?.filter((label: any) => !label?.id?.startsWith("address_label_"))
                ?.map((label: any) => label?.name) || [],
            to_address_label: record?.to_address_label
                ?.filter((label: any) => !label?.id?.startsWith("address_label_"))
                ?.map((label: any) => label?.name) || [],
            notes: record?.notes || "",
        };

        try {
            if (record?.is_search_transaction) {
                const importResponse = await reimportTransaction({force_update: true, simple_import: true, create_address_labels: true}, "import");
                
                if (importResponse?.result?.message_id) {
                    setEditTagsId(importResponse.result.message_id);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    
                    await handleEditTagsFinish(formData);
                    queryResult.refetch();
                    closeEditTags();
                    
                    open?.({
                        type: "success",
                        message: "Transaction imported and tags updated successfully",
                    });
                }
            } else {
                await handleEditTagsFinish(formData);
                closeEditTags();
            }
        } catch (error) {
            // Handle error
        } finally {
            setIsSubmitting(false); // Stop loading regardless of outcome
        }
    };

    const handleGroupFormSubmit = async (values: any) => {
        setIsSubmitting(true); // Start loading

        // Add default values to the form data with filtered labels
        const formData = {
            group_names: values.group_names || [],
            tags: record?.tags?.map((tag: any) => tag.name) || [],
            from_address_label: record?.from_address_label
                ?.filter((label: any) => !label?.id?.startsWith("address_label_"))
                ?.map((label: any) => label?.name) || [],
            to_address_label: record?.to_address_label
                ?.filter((label: any) => !label?.id?.startsWith("address_label_"))
                ?.map((label: any) => label?.name) || [],
            notes: record?.notes || "",
        };

        try {
            if (record?.is_search_transaction) {
                try {
                    const importResponse = await reimportTransaction({force_update: true, simple_import: true, create_address_labels: true}, "import");
                
                    if (importResponse?.result?.message_id) {
                        setEditGroupsId(importResponse.result.message_id);
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        
                        await handleEditGroupsFinish(formData);
                        queryResult.refetch();
                        closeEditGroups();
                        
                        open?.({
                            type: "success",
                            message: "Transaction imported and groups updated successfully",
                        });
                    }
                } catch (error) {
                    return;
                }
            } else {
                await handleEditGroupsFinish(formData);
                closeEditGroups();
            }
        } catch (error) {
            // Handle error
        } finally {
            setIsSubmitting(false); // Stop loading regardless of outcome
            closeEditGroups();
        }            
    };

    const handleFromAddressLabelFormSubmit = async (values: any) => {
        setIsSubmitting(true); // Start loading

        // Add default values to the form data with filtered labels
        const formData = {
            group_names: record?.group_names?.map((group: any) => group.name) || [],
            tags: record?.tags?.map((tag: any) => tag.name) || [],
            from_address_label: values.from_address_label || [],
            to_address_label: record?.to_address_label
                ?.filter((label: any) => !label?.id?.startsWith("address_label_"))
                ?.map((label: any) => label?.name) || [],
            notes: record?.notes || "",
        };

        try {
            if (record?.is_search_transaction) {
                const importResponse = await reimportTransaction({force_update: true, simple_import: true, create_address_labels: true}, "import");
                
                if (importResponse?.result?.message_id) {
                    setEditFromAddressLabelId(importResponse.result.message_id);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    
                    await handleEditFromAddressLabelFinish(formData);
                    queryResult.refetch();
                    closeEditFromAddressLabel();
                    
                    open?.({
                        type: "success",
                        message: "Transaction imported and from address labels updated successfully",
                    });
                }
            } else {
                await handleEditFromAddressLabelFinish(formData);
                closeEditFromAddressLabel();
            }
        } catch (error) {
            // Handle error
        } finally {
            setIsSubmitting(false); // Stop loading regardless of outcome
            closeEditFromAddressLabel(); // Ensure modal closes even if there's an error
        }
    };

    const handleToAddressLabelFormSubmit = async (values: any) => {
        setIsSubmitting(true); // Start loading

        // Add default values to the form data with filtered labels
        const formData = {
            group_names: record?.group_names?.map((group: any) => group.name) || [],
            tags: record?.tags?.map((tag: any) => tag.name) || [],
            from_address_label: record?.from_address_label
                ?.filter((label: any) => !label?.id?.startsWith("address_label_"))
                ?.map((label: any) => label?.name) || [],
            to_address_label: values.to_address_label || [],
            notes: record?.notes || "",
        };

        try {
            if (record?.is_search_transaction) {
                try {
                    const importResponse =await reimportTransaction({force_update: true, simple_import: true, create_address_labels: true}, "import");
                    
                    if (importResponse?.result?.message_id) {
                        setEditToAddressLabelId(importResponse.result.message_id);
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        
                        await handleEditToAddressLabelFinish(formData);
                        queryResult.refetch();
                        closeEditToAddressLabel();
                        
                        open?.({
                            type: "success",
                            message: "Transaction imported and to address labels updated successfully",
                        });
                    }
                } catch (error) {
                    return;
                }
            } else {
                await handleEditToAddressLabelFinish(formData);
                closeEditToAddressLabel();
            }
        } catch (error) {
            // Handle error
        } finally {
            setIsSubmitting(false); // Stop loading regardless of outcome
            closeEditToAddressLabel(); // Ensure modal closes even if there's an error
        }              
    };

    const {
        modalProps: editFromAddressLabelModalProps,
        formProps: editFromAddressLabelFormProps,
        show: showFromAddressLabel,
        close: closeEditFromAddressLabel,
        onFinish: handleEditFromAddressLabelFinish,
        setId: setEditFromAddressLabelId
    } = useModalForm({
        id: `${record?.["user_id-block_timestamp-chain_id-hash-type"]}`,
        action: "edit",
        resource: "transactions",
        redirect: "show",
        warnWhenUnsavedChanges: false,
        meta: {
            operation: "edit_from_address_label"
        },
    });

    const {
        modalProps: editToAddressLabelModalProps,
        formProps: editToAddressLabelFormProps,
        show: showToAddressLabel,
        close: closeEditToAddressLabel,
        onFinish: handleEditToAddressLabelFinish,
        setId: setEditToAddressLabelId
    } = useModalForm({
        id: `${record?.["user_id-block_timestamp-chain_id-hash-type"]}`,
        action: "edit",
        resource: "transactions",
        redirect: "show",
        warnWhenUnsavedChanges: false,
        meta: {
            operation: "edit_to_address_label"
        },
    });

    const { 
        show: setShowTransactionDecodedInput, 
        close: closeTransactionDecodedInput, 
        modalProps: transactionDecodedInputModalProps 
    } = useModal(
        
    );

    const [graphKey, setGraphKey] = useState<number>(0);

    const decoded_events = record?.logs
    const decoded_call = {
        ...record?.decoded_call,
        input: record?.input || "0x",
        output: record?.output || "0x"
    };
    
    const decoded_internal_transactions = record?.internal_transactions
    const [decodedTransactionData, setDecodedTransactionData] = useState<any>(record?.decoded_transaction ?  record?.decoded_transaction : {
        "decoded_call": decoded_call, 
        "decoded_internal_transactions": decoded_internal_transactions, 
        "decoded_events": decoded_events
    });
    if (!decodedTransactionData?.decoded_call?.input) {
        decodedTransactionData.decoded_call.input = record?.input;
    }
    if (!decodedTransactionData?.decoded_call?.output) {
        decodedTransactionData.decoded_call.output = record?.output;
    }   

    useEffect(() => {
        if (enrichedTransaction?.decoded_transaction) {
            const decoded = { ...enrichedTransaction.decoded_transaction };
            
            // Safely initialize decoded_call if it doesn't exist
            if (!decoded.decoded_call) {
                decoded.decoded_call = {};
            }
    
            // Add input/output from main transaction if not present in decoded_call
            if (!decoded.decoded_call.input && enrichedTransaction.input) {
                decoded.decoded_call.input = enrichedTransaction.input;
            }
            if (!decoded.decoded_call.output && enrichedTransaction.output) {
                decoded.decoded_call.output = enrichedTransaction.output;
            }
    
            setDecodedTransactionData(decoded);
        }
    }, [enrichedTransaction]);

    const [decodedTransactionDetails, setDecodedTransactionDetails] = useState<any>(decodedTransactionData);

    const { mutate: updateTransactionNotes } = useUpdate(
        {
            resource: "transactions",
            id: record?.["user_id-block_timestamp-chain_id-hash-type"],
        }
    );

    const handleNotesChange = async (newNotes: string) => {
        try {
            // Add your API call here to save the notes
            await updateTransactionNotes(
                {
                    id: record?.["user_id-block_timestamp-chain_id-hash-type"],
                    values: {
                        notes: newNotes,
                        tags: record?.tags,
                        group_names: record?.group_names,
                        from_address_label: record?.from_address_label,
                        to_address_label: record?.to_address_label,
                    }
                }
            );
            // Optionally refresh the transaction data
            queryResult.refetch();
        } catch (error) {
            throw error; // This will be caught by the TransactionContext component
        }
    };

    // Create a state to track the selected address from TransactionContext
    const [selectedContractAddress, setSelectedContractAddress] = useState<string | undefined>();

    return (
        <Show isLoading={isLoading} 
        headerButtons={({deleteButtonProps }) => (
            <>
            {isLoadingAttributes && (
                <Tooltip title="Loading additional transaction details">
                    <Spin size="small" style={{ marginRight: 8 }} />
                </Tooltip>
            )}
            <Space>
                <ListButton/>
                <RefreshButton/>
                <Tooltip title="Delete all information about this transaction on the platform">
                    <DeleteButton onSuccess={() => {
                        navigate("/transactions");
                    }}/>
                </Tooltip>
            </Space>
            </>
        )}
        footerButtons={({deleteButtonProps }) => (
            <>
                {record?.is_search_transaction ? 
                    <Button 
                        type="primary" 
                        loading={isLoadingReimportTransaction} 
                        onClick={() => setIsImportModalVisible(true)}
                    >
                        Import
                    </Button> : 
                    ""
                }
            <>

                <Modal
                    title="Import Transaction"
                    open={isImportModalVisible}
                    onCancel={() => {
                    setIsImportModalVisible(false);
                    importForm.resetFields();
                    }}
                    onOk={() => {
                    importForm.validateFields().then((values) => {
                        const { importType, ...otherValues } = values;
                        reimportTransaction({
                        ...otherValues,
                        simple_import: importType === 'simple'
                        });
                        setIsImportModalVisible(false);
                        importForm.resetFields();
                    });
                    }}
                >
                    <Form 
                    form={importForm} 
                    layout="vertical"
                    initialValues={{
                        importType: 'simple',
                        force_update: false,
                        create_address_labels: true,
                    }}
                    >
                    <Form.Item
                        name="importType"
                        label="Import Type"
                    >
                        <Radio.Group>
                        <Space direction="vertical">
                            <Radio value="simple">
                            Simple Import
                            <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                                Quick import with basic processing (recommended for simple transactions)
                            </Typography.Text>
                            </Radio>
                            <Radio value="advanced">
                            Advanced Import
                            <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                                Detailed import with full data processing and validation
                            </Typography.Text>
                            </Radio>
                        </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="force_update"
                        valuePropName="checked"
                    >
                        <Checkbox>
                        Force Update
                        <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                            Overwrite existing transaction data
                        </Typography.Text>
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="create_address_labels"
                        valuePropName="checked"
                    >
                        <Checkbox>
                        Create Address Labels
                        <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                            Automatically generate labels for addresses
                        </Typography.Text>
                        </Checkbox>
                    </Form.Item>
                    </Form>        
                    </Modal>
                </>
            </>
        )}
        >
        <Row gutter={16}>
            <Col span={16}>
                {/* Transaction Overview Section */}
                <Card title="Transaction Overview" size="small">
                    <Space direction="vertical" style={{ width: '100%' }} size="middle">
                        {/* Hash */}
                        <Space direction="vertical" size="small">
                            <Text type="secondary">Transaction Hash</Text>
                            <Text copyable>{record?.hash}</Text>
                        </Space>

                        {/* Value */}
                        {record?.value && record?.value !== null && (
                            <Space direction="vertical" size="small">
                                <Text type="secondary">Value</Text>
                                <Text>
                                    {(Number(record.value) / 1e18).toFixed(6)} {' '}
                                    {CHAIN_CONFIG[record?.chain_id as keyof typeof CHAIN_CONFIG]?.token || 'Native Token'}
                                </Text>
                            </Space>
                        )}

                        {/* Status and Block Info */}
                        <Space direction="vertical" size="small">
                            <Text type="secondary">Status & Block</Text>
                            <Space>
                                {record?.receipt_status === "0" ? (
                                    <Tag icon={<CloseCircleOutlined />} color="error">Failed</Tag>
                                ) : (
                                    <Tag icon={<CheckCircleOutlined />} color="success">Confirmed</Tag>
                                )}
                                <Text>Block #{record?.block_number}</Text>
                                <Text>|</Text>
                                <Tooltip title={new Date(record?.block_timestamp || "").toLocaleString()}>
                                    {formatDate(record?.block_timestamp || "")}
                                </Tooltip>
                            </Space>                
                            {/* Chain Info */}
                            <Space direction="vertical" size="small">
                                <Text type="secondary">Network</Text>
                                <Space>
                                    <Tooltip title={CHAIN_CONFIG[record?.chain_id as keyof typeof CHAIN_CONFIG]?.name || record?.chain}>
                                        <Avatar 
                                            src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`} 
                                        /> 
                                    </Tooltip>
                                    <Text>
                                        {!record?.chain 
                                            ? CHAIN_CONFIG[record?.chain_id as keyof typeof CHAIN_CONFIG]?.name 
                                            : record?.chain?.[0]?.toUpperCase() + record?.chain?.slice(1)
                                        }
                                    </Text>
                                </Space>
                            </Space>
                        </Space>
                    </Space>
                </Card>

                {/* Addresses Section */}
                <Card title="Addresses" size="small" style={{ marginTop: 16 }}>
                    <Space direction="vertical" style={{ width: '100%' }} size="middle">
                        {/* From Address */}
                        <Space direction="vertical" size="small">
                            <Text type="secondary">From</Text>
                            <Space align="center">
                                <Space>
                                    <Link to={"/addresses/show/" + (record?.address_items_map?.[record?.from_address]?.id || record?.from_address)}>
                                        <Text style={{ color: "#1890ff", cursor: "pointer" }} copyable>{record?.from_address}</Text>
                                    </Link>
                                    {renderAddressAvatar(record, 'from')}
                                    <Button 
                                        type="link" 
                                        icon={<InfoCircleOutlined />}
                                        onClick={() => showFromAddressLabel(`${record?.["user_id-block_timestamp-chain_id-hash-type"]}`)}
                                    >
                                        Edit labels
                                    </Button>
                                </Space>
                            </Space>
                        </Space>
                        {/* To Address */}
                        <Space direction="vertical" size="small">
                            <Text type="secondary">To</Text>
                            <Space direction="vertical" size={8} style={{ width: '100%' }}>
                                {/* Case 1: Contract creation via proxy (both to_address and receipt_contract_address exist) */}
                                {record?.to_address && record?.receipt_contract_address ? (
                                    <>
                                        {/* Proxy Address */}
                                        <Space align="center">
                                            <Link to={"/addresses/show/" + (record?.address_items_map?.[record?.to_address]?.id || record?.to_address)}>
                                                <Text style={{ color: "#1890ff", cursor: "pointer" }} copyable>{record?.to_address}</Text>
                                            </Link>
                                            {renderAddressAvatar(record, 'to')}
                                            <Tag color="blue">Proxy</Tag>
                                            <Button 
                                                type="link" 
                                                icon={<InfoCircleOutlined />}
                                                onClick={() => showToAddressLabel(`${record?.["user_id-block_timestamp-chain_id-hash-type"]}`)}
                                            >
                                                Edit labels
                                            </Button>
                                        </Space>
                                        
                                        {/* Created Contract Address */}
                                        <Space align="center">
                                            <Link to={"/addresses/show/" + (record?.address_items_map?.[record?.receipt_contract_address]?.id || record?.receipt_contract_address)}>
                                                <Text style={{ color: "#1890ff", cursor: "pointer" }} copyable>{record?.receipt_contract_address}</Text>
                                            </Link>
                                            {renderAddressAvatar(
                                                {
                                                    chain_id: record?.chain_id,
                                                    address_items_map: { [record?.receipt_contract_address]: record?.address_items_map?.[record?.receipt_contract_address] }
                                                },
                                                'to',
                                                record?.receipt_contract_address
                                            )}
                                            <Tag color="green">Contract Created</Tag>
                                        </Space>
                                    </>
                                ) : (
                                    /* Case 2: Direct contract creation (only receipt_contract_address exists) */
                                    record?.receipt_contract_address ? (
                                        <Space align="center">
                                            <Link to={"/addresses/show/" + (record?.address_items_map?.[record?.receipt_contract_address]?.id || record?.receipt_contract_address)}>
                                                <Text style={{ color: "#1890ff", cursor: "pointer" }} copyable>{record?.receipt_contract_address}</Text>
                                            </Link>
                                            {renderAddressAvatar(
                                                {
                                                    chain_id: record?.chain_id,
                                                    address_items_map: { [record?.receipt_contract_address]: record?.address_items_map?.[record?.receipt_contract_address] }
                                                },
                                                'to',
                                                record?.receipt_contract_address
                                            )}
                                            <Tag color="green">Contract Created</Tag>
                                            <Button 
                                                type="link" 
                                                icon={<InfoCircleOutlined />}
                                                onClick={() => showToAddressLabel(`${record?.["user_id-block_timestamp-chain_id-hash-type"]}`)}
                                            >
                                                Edit labels
                                            </Button>
                                        </Space>
                                    ) : (
                                        /* Case 3: Regular transaction (only to_address exists) */
                                        record?.to_address ? (
                                            <Space align="center">
                                                <Link to={"/addresses/show/" + (record?.address_items_map?.[record?.to_address]?.id || record?.to_address)}>
                                                    <Text style={{ color: "#1890ff", cursor: "pointer" }} copyable>{record?.to_address}</Text>
                                                </Link>
                                                {renderAddressAvatar(record, 'to')}
                                                <Button 
                                                    type="link" 
                                                    icon={<InfoCircleOutlined />}
                                                    onClick={() => showToAddressLabel(`${record?.["user_id-block_timestamp-chain_id-hash-type"]}`)}
                                                >
                                                    Edit labels
                                                </Button>
                                            </Space>
                                        ) : (
                                            /* Case 4: No recipient (old-style contract creation) */
                                            <Text type="secondary">No recipient (contract creation)</Text>
                                        )
                                    )
                                )}
                            </Space>
                        </Space>
                    </Space>
                </Card>     

                {/* Transaction Details Section */}
                <Card title="Transaction Details" size="small" style={{ marginTop: 16 }}>
                    <Space direction="vertical" style={{ width: '100%' }} size="middle">
                        {/* Gas Details */}
                        <Space direction="vertical" size="small">
                            <Text type="secondary">Gas Information</Text>
                            <Space wrap>
                                <Statistic 
                                    title="Gas Price" 
                                    value={record?.gas_price ? (Number(record.gas_price) / 1e9).toFixed(2) : "N/A"}
                                    suffix={CHAIN_CONFIG[record?.chain_id as keyof typeof CHAIN_CONFIG]?.gasUnit || "Gas Units"}
                                />
                                <Statistic 
                                    title="Gas Limit" 
                                    value={record?.gas || "N/A"}
                                />
                                <Statistic 
                                    title="Transaction Fee" 
                                    value={record?.gas_price && record?.receipt_gas_used ? 
                                        ((Number(record.gas_price) * Number(record.receipt_gas_used)) / 1e18).toFixed(6) : "N/A"
                                    }
                                    suffix={CHAIN_CONFIG[record?.chain_id as keyof typeof CHAIN_CONFIG]?.token || "Native Token"}
                                />
                            </Space>
                        </Space>

                        {/* Decoded Input */}
                        <Space direction="vertical">
                            <Text type="secondary">Decoded input of the transaction</Text>

                            {decodedTransactionData?.decoded_call && (
                                // Only show if there's actual input or output data
                                (decodedTransactionData.decoded_call.input !== '0x' || 
                                decodedTransactionData.decoded_call.output !== '0x') ? (
                                    // If we have a label, show the decoded call
                                    decodedTransactionData.decoded_call.label ? (
                                        <Space align="start" style={{ width: '100%' }}>
                                    <div style={{ width: 80, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>                    
                                        <Space>
                                            {(() => {
                                            // Extract all unique addresses from the input parameters
                                            const uniqueAddresses = new Set(
                                                decodedTransactionData?.decoded_call?.params?.flatMap((param: any) => extractAddressesFromParams(param)) || []
                                            );

                                            // Filter for addresses that have labels/entities
                                            const knownAddresses = getKnownAddresses(uniqueAddresses);

                                            // Calculate number of unknown addresses
                                            const unknownCount = uniqueAddresses.size - knownAddresses.length;

                                            // Only show avatars if we have known addresses or unknown ones to count
                                            return (knownAddresses.length > 0 || unknownCount > 0) && (
                                                <Space size={-6}>
                                                    {/* Show maximum 3 avatars */}
                                                    {knownAddresses.slice(0, 3).map((address, index) => (
                                                        <Tooltip title={
                                                            record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity
                                                                ? `${record?.from_address_entity} (${(address as string).slice(0, 6)}...${(address as string).slice(-6)})`
                                                                : record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity
                                                                    ? `${record?.to_address_entity} (${(address as string).slice(0, 6)}...${(address as string).slice(-6)})`
                                                                    : record?.address_items_map?.[address as string]?.labels?.[0]?.name
                                                                        ? `${record?.address_items_map?.[address as string]?.labels?.[0]?.name} (${(address as string).slice(0, 6)}...${(address as string).slice(-6)})`
                                                                        : "unknown address label"
                                                        }>
                                                            <Avatar 
                                                                src={
                                                                    record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity_logo
                                                                        ? record?.from_address_entity_logo
                                                                        : record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity_logo
                                                                            ? record?.to_address_entity_logo
                                                                            : record?.address_items_map?.[address as string]?.labels?.[0]?.source
                                                                                ? record?.address_items_map?.[address as string]?.labels?.[0]?.source
                                                                                : `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`
                                                                }
                                                                style={{ 
                                                                    border: '1px solid #f0f0f0',
                                                                    marginLeft: index === 0 ? 0 : '-8px',
                                                                    zIndex: 3 - index
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    ))}

                                                    {/* Show +X more if there are additional addresses */}
                                                    {(knownAddresses.length > 3 || unknownCount > 0) && (
                                                        <Tooltip title={(() => {
                                                            const tooltipParts = [];
                                                            
                                                            // Add remaining known addresses to tooltip
                                                            if (knownAddresses.length > 3) {
                                                                const remainingKnown = knownAddresses.slice(3).map((address: any) => {
                                                                    if (record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity) {
                                                                        return `${record?.from_address_entity} (${address.slice(0, 6)}...${address.slice(-6)})`;
                                                                    } else if (record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity) {
                                                                        return `${record?.to_address_entity} (${address.slice(0, 6)}...${address.slice(-6)})`;
                                                                    } else {
                                                                        const entityLabel = record?.address_items_map?.[address as string]?.labels?.[0]?.name;
                                                                        return entityLabel ? `${entityLabel} (${(address as string).slice(0, 6)}...${(address as string).slice(-6)})` : null;
                                                                    }
                                                                }).filter(Boolean);
                                                                if (remainingKnown.length > 0) {
                                                                    tooltipParts.push(remainingKnown.join('\n'));
                                                                }
                                                            }

                                                            // Add unknown addresses count to tooltip
                                                            if (unknownCount > 0) {
                                                                tooltipParts.push(`${unknownCount} more unknown address${unknownCount > 1 ? 'es' : ''}`);
                                                            }

                                                            return tooltipParts.join('\n');
                                                        })()}>
                                                            <Avatar 
                                                                size="small"
                                                                style={{ 
                                                                    marginLeft: '-8px',
                                                                    background: '#1890ff',
                                                                    color: 'white',
                                                                    fontWeight: 'bold',
                                                                    zIndex: 0
                                                                }}
                                                            >
                                                                +{(knownAddresses.length > 3 ? knownAddresses.length - 3 : 0) + unknownCount}
                                                            </Avatar>
                                                        </Tooltip>
                                                    )}
                                                </Space>
                                            );
                                        })()}                 
                                        <Tooltip title={`Function: ${decodedTransactionData?.decoded_call?.signature}`}>
                                            <Tag color="orange">
                                                {decodedTransactionData?.decoded_call?.label}
                                            </Tag>
                                        </Tooltip>
                                        {decodedTransactionData?.decoded_call?.params?.slice(0, 0).map((param: any) => (
                                            <Tooltip title={getTooltipTitleForParamType(record?.address_items_map, param)}>
                                                <Tag color={getTagColorByParamType(param.type)}>
                                                    {param.name}
                                                </Tag>
                                            </Tooltip>
                                        ))}
                                        {decodedTransactionData?.decoded_call?.params?.length > 0 && (
                                            <Tooltip title="Click 'Show decoded input' to see all parameters">
                                                <Tag style={{cursor: "pointer"}} onClick={() => showDecodedTransaction()} color="grey">
                                                    +{decodedTransactionData?.decoded_call?.params.length - 0} more params
                                                </Tag>
                                            </Tooltip>
                                        )}
                                        <Button 
                                            onClick={() => showDecodedTransaction()}
                                            size="small"
                                        >
                                            Show decoded input
                                        </Button>
                                    </Space>
                                    </div>
                                    </Space>
                                    ) : (
                                        // If no label but has input/output, show "Failed to decode" with raw data
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <Alert
                                                message="Failed to decode transaction"
                                                description="To decode this transaction, please add the contract ABI."
                                                type="warning"
                                                showIcon
                                            />
                                            <Collapse size="small" ghost>
                                                <Collapse.Panel 
                                                    header="Raw Transaction Data" 
                                                    key="1"
                                                    extra={
                                                        <Space>
                                                            {decodedTransactionData?.decoded_call?.input && decodedTransactionData.decoded_call.input !== '0x' && (
                                                                <Typography.Text copyable={{ text: decodedTransactionData.decoded_call.input }}>
                                                                    Copy Input
                                                                </Typography.Text>
                                                            )}
                                                            {decodedTransactionData?.decoded_call?.output && decodedTransactionData.decoded_call.output !== '0x' && (
                                                                <Typography.Text copyable={{ text: decodedTransactionData.decoded_call.output }}>
                                                                    Copy Output
                                                                </Typography.Text>
                                                            )}
                                                        </Space>
                                                    }
                                                >
                                                    <Space direction="vertical" style={{ width: '100%' }}>
                                                        {decodedTransactionData?.decoded_call?.input && decodedTransactionData.decoded_call.input !== '0x' && (
                                                            <div>
                                                                <Text type="secondary">Input:</Text>
                                                                <div style={{ 
                                                                    background: '#f5f5f5', 
                                                                    padding: '8px', 
                                                                    borderRadius: '4px',
                                                                    wordBreak: 'break-all',
                                                                    fontSize: '12px'
                                                                }}>
                                                                    {decodedTransactionData.decoded_call.input}
                                                                </div>
                                                            </div>
                                                        )}
                                                        {decodedTransactionData?.decoded_call?.output && decodedTransactionData.decoded_call.output !== '0x' && (
                                                            <div>
                                                                <Text type="secondary">Output:</Text>
                                                                <div style={{ 
                                                                    background: '#f5f5f5', 
                                                                    padding: '8px', 
                                                                    borderRadius: '4px',
                                                                    wordBreak: 'break-all',
                                                                    fontSize: '12px'
                                                                }}>
                                                                    {decodedTransactionData.decoded_call.output}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Space>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </Space>
                                    )
                                ) : (
                                    // If no input and no output, show "Nothing to decode"
                                    <Alert
                                        message="Nothing to decode"
                                        description="This transaction contains no input or output data."
                                        type="info"
                                        showIcon
                                    />
                                )
                            )}
                        </Space>
                    </Space>
                </Card>

                {/* Contract State Section */}
                <Divider></Divider>
                <ContractStateHighlighter
                    contractAddress={record?.receipt_contract_address || record?.to_address || '0x8b51538396e5f79b3607720c63e7bc6dee1495b3'}
                    chainId={record?.chain_id || '0x1'}
                    transactionHash={record?.hash || ''}
                    transactionBlockNumber={record?.block_number ? parseInt(record?.block_number) : undefined}
                    contractStateData={contractStateData?.data as unknown as ContractStateData}
                />

                {/* Included by Section */}
                {record?.included_by && (
                    <Card title="Included by" size="small" style={{ marginTop: 16 }}>
                        <Space direction="vertical" style={{ width: '100%' }} size="middle">
                            {/* Included by */}
                            <Space direction="vertical" size="small">
                                <Text type="secondary">Included by</Text>
                                <Text>
                                    {record?.included_by?.map((record: any) => (
                                        <Tag color={"#" + record.color}>{record.name}</Tag>
                                    ))}
                                </Text>
                            </Space>
                        </Space>
                    </Card>
                )}

                {/* Tags Section */}
                <Card title="Tags" size="small" style={{ marginTop: 16 }}>
                    <Space direction="vertical" style={{ width: '100%' }} size="middle">
                        <Text type="secondary">Tags</Text>
                        <Space>
                        <Text>
                            {record?.tags?.map((tag: any) => (
                                <Tag color={"#" + tag.color}>{tag.name}</Tag>
                            ))}
                        </Text>
                        <Button 
                            type="primary" 
                            icon={<EditOutlined />} 
                            onClick={() => showEditTags(`${record?.["user_id-block_timestamp-chain_id-hash-type"]}`)}
                            size="small"
                        >
                            Edit Tags
                        </Button>
                        </Space>
                    </Space>
                </Card> 

                {/* Group(s) Section */}
                <Card title="Group(s)" size="small" style={{ marginTop: 16 }}>
                    <Space direction="vertical" style={{ width: '100%' }} size="middle">
                        <Text>
                            {record?.group_names && record.group_names.length > 0 ? (
                                record.group_names.map((group: any) => (
                                    <Tag color={"#" + group.color}>{group.name}</Tag>
                                ))
                            ) : (
                                <Text>No groups assigned yet to this transaction</Text>
                            )}
                        </Text>
                        <Button 
                            type="primary" 
                            icon={<EditOutlined />} 
                            onClick={() => showEditGroups(`${record?.["user_id-block_timestamp-chain_id-hash-type"]}`)}
                            size="small"
                        >
                            Edit Groups
                        </Button>

                        <Row gutter={16}>
                            {
                            record?.group_assignments?.map((groupAssignment: any, index: any) => (
                                <GroupAssignmentsCard 
                                    key={index}
                                    record={groupAssignment}
                                    cdn_domain_name={cdn_domain_name}
                                    groupNames={record.group_names}
                                />
                            ))}
                        </Row>                
                    </Space>
                </Card>
                
                    {!record?.is_search_transaction && (
                        <>
                            <Divider />
                            <Title level={5}>Processed by the following Integrations:</Title>
                            
                            <IntegrationDetailsModal
                                modalProps={modalProps}
                                transactionRecord={record}
                                integrationId={showIntegrationIdDetails}
                                integrationName={record?.integration_results?.find(
                                    (integration: any) => integration.integration_id === showIntegrationIdDetails
                                )?.integration_name}
                                onClose={() => {
                                    setShowIntegrationIdDetails("");
                                    close();
                                }}
                            />
                            <IntegrationResults
                                integrationResults={record?.integration_results}
                                transactionResults={record}
                                onShowDetails={showIntegrationDetails}
                                loading={isLoading}
                            />                    
                        </>
                    )}
                    <Divider></Divider>
                    {
                        <SharedWithUsers
                            sharedWith={record?.shared_with}
                            ownerId={record?.owner_id}
                            transactionId={record?.["user_id-block_timestamp-chain_id-hash-type"] || ""}
                            token={token}
                            region={region}
                            continent={continent}
                            domain={domain}
                            qa_environment={qa_environment}
                        />
                    }   
                {
                    /* When not is_search_transaction */
                }
                <Divider></Divider>
                
                <DecodedInputModal
                    modalProps={transactionDecodedInputModalProps}
                    close={closeTransactionDecodedInput}
                    decoded_transaction={decodedTransactionDetails}
                    addressData={{
                        is_search_transaction: record?.is_search_transaction,
                        from_address: record?.from_address,
                        to_address: record?.to_address,
                        from_address_entity: record?.from_address_entity,
                        to_address_entity: record?.to_address_entity,
                        from_address_entity_logo: record?.from_address_entity_logo,
                        to_address_entity_logo: record?.to_address_entity_logo,
                        address_items_map: record?.address_items_map
                    }}
                    chainId={record?.chain_id}
                />                 
                    
                    <InternalTransactionsList 
                        decodedTransactionData={decodedTransactionData}
                        record={record}
                        displayCount={displayInternalTransactionsCount}
                        setDisplayCount={setDisplayInternalTransactionsCount}
                        showInternalTransaction={showInternalTransaction}
                        cdn_domain_name={cdn_domain_name}
                    />

                    <EventsList 
                        decodedTransactionData={decodedTransactionData}
                        record={record}
                        displayCount={displayEventsCount}
                        setDisplayCount={setDisplayEventsCount}
                        showEvent={showEvent}
                        cdn_domain_name={cdn_domain_name}
                    />

                      
                     <Divider></Divider>
                     

                            { 
                                isLoading || isFetching || isRefetching ? 
                                 <Spin spinning={isLoading || isFetching || isRefetching}> </Spin> 
                                 
                                 : 
                                 <div>
                                 <Divider></Divider>
                                 <Title level={3}>Graph:</Title>
                                 <TransactionGraph 
                                    data={[enrichedTransaction].filter(Boolean)} // Convert to array and filter out undefined
                                    address_items_map={record?.address_items_map || {}}                        
                                    filteredData={[enrichedTransaction].filter(Boolean)} // Convert to array and filter out undefined
                                    chainIdToName={Object.fromEntries(Object.entries(CHAIN_CONFIG).map(([k,v]) => [k, v.name]))} // Extract just the name field
                                    cdn_domain_name={cdn_domain_name || ''} // Provide empty string fallback
                                    graphKey={graphKey} // You'll need to add this state
                                    setGraphKey={setGraphKey} // You'll need to add this state setter
                                    options={{
                                        showReplay: true,
                                        showLive: false,
                                        showTimeRange: false,
                                        showSettings: true,
                                        showShare: true
                                    }}
                                />
                                </div>
                            }

                        <Modal 
                            {...editFromAddressLabelModalProps} 
                            width="1000" 
                            style={{ minWidth: "200px" }}
                            centered 
                            title="Edit From Address Labels" 
                            confirmLoading={isImportingTransaction || isSubmitting}
                            okButtonProps={{
                                loading: isImportingTransaction || isSubmitting,
                                disabled: isImportingTransaction || isSubmitting,
                                onClick: async () => {
                                    const values = editFromAddressLabelFormProps.form?.getFieldsValue();
                                    await handleFromAddressLabelFormSubmit(values);
                                }
                            }}
                            destroyOnClose={true}
                        >
                            <Form 
                                {...editFromAddressLabelFormProps}
                                initialValues={{
                                    from_address_label: record?.from_address_label
                                        ?.filter((label: any) => !label?.id?.startsWith("address_label_"))
                                        ?.map((label: any) => label?.name) || []
                                }}
                                onFinish={(values) => handleFromAddressLabelFormSubmit(values)}
                            >
                                <Alert
                                    message="Transaction-specific vs Global Address Labels"
                                    description={
                                        <>
                                            <Text>There are two types of address labels:</Text>
                                            <ul>
                                                <li>Transaction-specific labels (edited here): Apply only to this transaction</li>
                                                <li>
                                                    Global address labels (managed in <Link to="/settings?tab=labels">Settings &gt; Labels</Link>): 
                                                    Apply to all transactions with this address
                                                </li>
                                            </ul>
                                        </>
                                    }
                                    type="info"
                                    showIcon
                                    style={{ marginBottom: 16 }}
                                />
                                {record?.is_search_transaction && (
                                    <Alert
                                        message="This transaction needs to be imported first"
                                        description="When you save these from address labels, we'll first import the transaction and then apply the labels."
                                        type="info"
                                        showIcon
                                        style={{ marginBottom: 16 }}
                                    />
                                )}
                                <Form.Item
                                    label="To Address Labels"
                                    name="to_address_label"
                                    hidden={true}
                                    initialValue={[]}
                                ></Form.Item>                                        
                                <Form.Item
                                    label="Groups"
                                    name="group_names"
                                    initialValue={[]}
                                    hidden={true}
                                ></Form.Item>          
                                <Form.Item
                                    label="Tags"
                                    name="tags"
                                    hidden={true}
                                    initialValue={[]}
                                ></Form.Item>                                   
                                <Form.Item
                                    label="From Address Labels"
                                    name="from_address_label"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select at least one from address label',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="tags"
                                        tokenSeparators={[',']}
                                        placeholder="Select from address labels or enter new ones"
                                        style={{ width: '100%' }}
                                        defaultValue={record?.from_address_label
                                            ?.filter((label: any) => !label?.id?.startsWith("address_label_"))
                                            ?.map((label: any) => label?.name) || []}
                                        options={record?.from_address_label
                                            ?.filter((label: any) => !label?.id?.startsWith("address_label_"))
                                            ?.map((label: any) => ({
                                                label: label?.name,
                                                value: label?.name,
                                            }))}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            ((option?.label ?? '').toString()).toLowerCase().includes(input.toLowerCase())
                                        }
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Text type="secondary" style={{ padding: '0 8px' }}>
                                                    Type to create new labels (press Enter or comma to add)
                                                </Text>
                                            </>
                                        )}
                                    />
                                </Form.Item>
                            </Form>            
                        </Modal>

                        <Modal 
                            {...editToAddressLabelModalProps} 
                            width="1000" 
                            style={{ minWidth: "200px" }}
                            centered 
                            title="Edit To Address Labels" 
                            confirmLoading={isImportingTransaction || isSubmitting}
                            okButtonProps={{
                                loading: isImportingTransaction || isSubmitting,
                                disabled: isImportingTransaction || isSubmitting,
                                onClick: async () => {
                                    const values = editToAddressLabelFormProps.form?.getFieldsValue();
                                    await handleToAddressLabelFormSubmit(values);
                                }
                            }}
                            destroyOnClose={true}
                        >
                            <Form 
                                {...editToAddressLabelFormProps}
                                initialValues={{
                                    to_address_label: record?.to_address_label
                                        ?.filter((label: any) => !label?.id?.startsWith("address_label_"))
                                        ?.map((label: any) => label?.name) || []
                                }}
                                onFinish={(values) => handleToAddressLabelFormSubmit(values)}
                            >
                                <Alert
                                    message="Transaction-specific vs Global Address Labels"
                                    description={
                                        <>
                                            <Text>There are two types of address labels:</Text>
                                            <ul>
                                                <li>Transaction-specific labels (edited here): Apply only to this transaction</li>
                                                <li>
                                                    Global address labels (managed in <Link to="/settings?tab=labels">Settings &gt; Labels</Link>): 
                                                    Apply to all transactions with this address
                                                </li>
                                            </ul>
                                        </>
                                    }
                                    type="info"
                                    showIcon
                                    style={{ marginBottom: 16 }}
                                />
                                {record?.is_search_transaction && (
                                    <Alert
                                        message="This transaction needs to be imported first"
                                        description="When you save these to address labels, we'll first import the transaction and then apply the labels."
                                        type="info"
                                        showIcon
                                        style={{ marginBottom: 16 }}
                                    />
                                )}
                                <Form.Item
                                    label="From Address Labels"
                                    name="from_address_label"
                                    hidden={true}
                                    initialValue={[]}
                                ></Form.Item>                                        
                                <Form.Item
                                    label="Groups"
                                    name="group_names"
                                    initialValue={[]}
                                    hidden={true}
                                ></Form.Item>          
                                <Form.Item
                                    label="Tags"
                                    name="tags"
                                    hidden={true}
                                    initialValue={[]}
                                ></Form.Item>                                   
                                <Form.Item
                                    label="To Address Labels"
                                    name="to_address_label"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select at least one to address label',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="tags"
                                        tokenSeparators={[',']}
                                        placeholder="Select to address labels or enter new ones"
                                        style={{ width: '100%' }}
                                        defaultValue={record?.to_address_label
                                            ?.filter((label: any) => !label?.id?.startsWith("address_label_"))
                                            ?.map((label: any) => label?.name) || []}
                                        options={record?.to_address_label
                                            ?.filter((label: any) => !label?.id?.startsWith("address_label_"))
                                            ?.map((label: any) => ({
                                                label: label?.name,
                                                value: label?.name,
                                            }))}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            ((option?.label ?? '').toString()).toLowerCase().includes(input.toLowerCase())
                                        }
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Text type="secondary" style={{ padding: '0 8px' }}>
                                                    Type to create new labels (press Enter or comma to add)
                                                </Text>
                                            </>
                                        )}
                                    />
                                </Form.Item>
                            </Form>            
                        </Modal>   

                        <Modal 
                            {...editTagsModalProps} 
                            width="1000" 
                            style={{ minWidth: "500px" }}
                            centered 
                            title="Edit Transaction Tags" 
                            confirmLoading={isImportingTransaction || isSubmitting}
                            okButtonProps={{
                                loading: isImportingTransaction || isSubmitting,
                                disabled: isImportingTransaction || isSubmitting,
                                onClick: async () => {
                                    const values = editTagsFormProps.form?.getFieldsValue();
                                    await handleEditTagsFormSubmit(values);
                                }
                            }}
                            destroyOnClose={true}
                        >
                            <Form 
                                {...editTagsFormProps}
                                initialValues={{
                                    tags: record?.tags?.map((tag: any) => tag.name) || []
                                }}
                                onFinish={(values) => 
                                    { 
                                        setEditTagsId(`${record?.["user_id-block_timestamp-chain_id-hash-type"]}`)
                                        handleEditTagsFormSubmit(values)
                                    }}
                            >
     
                                {record?.is_search_transaction && (
                                    <Alert
                                        message="This transaction needs to be imported first"
                                        description="When you save these tags, we'll first import the transaction and then apply the tags."
                                        type="info"
                                        showIcon
                                        style={{ marginBottom: 16 }}
                                    />
                                )}                      
                                <Form.Item
                                    label="From Address Labels"
                                    name="from_address_label"
                                    hidden={true}
                                    initialValue={[]}
                                ></Form.Item>          
                                <Form.Item
                                    label="To Address Labels"
                                    name="to_address_label"
                                    hidden={true}
                                    initialValue={[]}
                                ></Form.Item>                                        
                                <Form.Item
                                    label="Groups"
                                    name="group_names"
                                    initialValue={[]}
                                    hidden={true}
                                ></Form.Item>                    
                                <Form.Item
                                    label="Tags"
                                    name="tags"
                                    rules={[{ required: false }]}
                                >
                                    <Select
                                        mode="tags"
                                        tokenSeparators={[',']}
                                        placeholder="Select tags or enter new ones"
                                        style={{ width: '100%' }}
                                        defaultValue={record?.tags?.map((tag: any) => tag.name) || []}
                                        options={record?.tags?.map((tag: any) => ({
                                            label: tag.name,
                                            value: tag.name,
                                        }))}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label as string ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Text type="secondary" style={{ padding: '0 8px' }}>
                                                    Type to create new tags (press Enter or comma to add)
                                                </Text>
                                            </>
                                        )}
                                    />
                                </Form.Item>
                            </Form>
                        </Modal>    
                        <Modal 
                            {...editGroupsModalProps} 
                            width="1000" 
                            centered 
                            title="Edit Transaction Groups" 
                            confirmLoading={isImportingTransaction || isSubmitting}
                            okButtonProps={{
                                loading: isImportingTransaction || isSubmitting,
                                disabled: isImportingTransaction || isSubmitting,
                                onClick: async () => {
                                    const values = editGroupsFormProps.form?.getFieldsValue();
                                    await handleGroupFormSubmit(values);
                                }                         
                            }}
                            destroyOnClose={true}
                            style={{ minWidth: "500px" }}
                        >
                            <Form 
                                {...editGroupsFormProps}
                                initialValues={{
                                    group_names: record?.group_names?.map((group: any) => group.name) || []
                                }}
                                onFinish={(values) => handleGroupFormSubmit(values)}
                            >
                                {record?.is_search_transaction && (
                                    <Alert
                                        message="This transaction needs to be imported first"
                                        description="When you save these groups, we'll first import the transaction and then apply the groups."
                                        type="info"
                                        showIcon
                                        style={{ marginBottom: 16 }}
                                    />
                                )}
                                <Form.Item
                                    label="Tags"
                                    name="tags"
                                    hidden={true}
                                    initialValue={[]}
                                ></Form.Item>        
                                <Form.Item
                                    label="From Address Labels"
                                    name="from_address_label"
                                    hidden={true}
                                    initialValue={[]}
                                ></Form.Item>          
                                <Form.Item
                                    label="To Address Labels"
                                    name="to_address_label"
                                    hidden={true}
                                    initialValue={[]}
                                ></Form.Item>                                        
                                <Form.Item
                                    label="Groups"
                                    name="group_names"
                                    rules={[{ required: false }]}
                                >
                                    <Select
                                        mode="tags"
                                        tokenSeparators={[',']}
                                        placeholder="Select groups or enter new ones"
                                        style={{ width: '100%' }}
                                        defaultValue={record?.group_names?.map((group: any) => group.name) || []}
                                        options={record?.group_names?.map((group: any) => ({
                                            label: group.name,
                                            value: group.name,
                                        }))}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label as string ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Text type="secondary" style={{ padding: '0 8px' }}>
                                                    Type to create new groups (press Enter or comma to add)
                                                </Text>
                                            </>
                                        )}
                                    />
                                </Form.Item>
                            </Form>
                        </Modal>                                 
                    </Col>
                    <Col span={8}>
                        <TransactionContext
                            addressItemsMap={record?.address_items_map || {}}
                            transactionRecord={record}
                            chainId={record?.chain_id}
                            category={record?.category}
                            summary={record?.summary}
                            entities={[
                                ...(record?.entities?.map((entity: any) => ({
                                    logo: entity.logo,
                                    name: entity.name,
                                    type: entity.type
                                })) || []),
                                ...(record?.contracts?.map((contract: any) => ({
                                    logo: contract.logo,
                                    name: contract.name,
                                    type: 'Contract'
                                })) || []),
                                // Add entities from address_items_map with logo matching from metadata
                                ...Object.entries(record?.address_items_map || {}).flatMap(([address, item]: [string, any]) => 
                                    item.entities?.map((entity: any) => {
                                        // Try to find matching entity in transaction's from_metadata or to_metadata
                                        let logo = entity.logo;
                                        // If no logo, try to find it in metadata regardless of ID format
                                        if (!logo) {
                                            // Check if this is the from_address or to_address to determine which metadata to use
                                            const isFromAddress = address.toLowerCase() === record?.from_address?.toLowerCase();
                                            const isToAddress = address.toLowerCase() === record?.to_address?.toLowerCase();
                                            
                                            const metadata = isFromAddress ? record?.from_metadata : 
                                                           isToAddress ? record?.to_metadata : null;
                                            
                                            const metadataEntity = metadata?.entities?.find(meta => 
                                                meta.id?.startsWith('temp_entity_') && 
                                                meta.name === entity.name
                                            );
                                            logo = metadataEntity?.logo;
                                        }

                                        return {
                                            logo,
                                            name: entity.name,
                                            type: 'Entity',
                                            address
                                        };
                                    }) || []
                                )
                            ].filter(Boolean)}
                            notes={record?.notes}
                            onNotesChange={handleNotesChange}
                            transactionId={record?.["user_id-block_timestamp-chain_id-hash-type"]}
                            onAddressSelect={(address: string) => {
                                // Update our local state when an address is selected in TransactionContext
                                setSelectedContractAddress(address);
                                console.log("Address selected for contract viewer:", address);
                            }}
                        />
                    </Col>
                </Row>
            </Show>
        );
    };


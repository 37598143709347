import React from 'react';
import { Badge, Popover, Tag, Typography, Space, Form, Button } from 'antd';
import { 
    ShareAltOutlined, 
    SettingOutlined, 
    AimOutlined,
    ThunderboltOutlined,
    LoadingOutlined,
    PlayCircleOutlined,
    PauseCircleOutlined,
    WarningOutlined
} from '@ant-design/icons';
const { Text } = Typography;
import { SubscriptionStatus } from '../../../graphql/types';

interface LiveIndicatorProps {
    subscriptionStatus: SubscriptionStatus;
}

const getStatusColor = (status: SubscriptionStatus['state']) => {
    switch (status) {
        case 'connected': return '#52c41a';
        case 'connecting': return '#faad14';
        case 'error': return '#f5222d';
        case 'disconnected': return '#999';
    }
};

const getStatusIcon = (status: SubscriptionStatus['state']) => {
    switch (status) {
        case 'connected': return <ThunderboltOutlined style={{ color: '#52c41a' }} />;
        case 'connecting': return <LoadingOutlined />;
        case 'error': return <WarningOutlined />;
        case 'disconnected': return <PauseCircleOutlined />;
    }
};

export const LiveIndicator: React.FC<LiveIndicatorProps> = ({
    subscriptionStatus
}) => {
    const isActive = subscriptionStatus?.isEnabled && subscriptionStatus?.state === 'connected';
    
    return (
        <Popover
            title={
                <Space>
                    {isActive ? (
                        <ThunderboltOutlined style={{ color: getStatusColor(subscriptionStatus?.state) }} />
                    ) : (
                        <PauseCircleOutlined style={{ color: getStatusColor('disconnected') }} />
                    )}
                    <Text strong>Live Transaction Feed</Text>
                </Space>
            }
            content={
                <Space direction="vertical" size="small">
                    <div>
                        <Text type="secondary">Status: </Text>
                        <Badge 
                            status={isActive ? "processing" : "default"} 
                            text={isActive ? "Active" : "Paused"} 
                        />
                    </div>
                    {subscriptionStatus?.isEnabled && (
                        <>
                            <div>
                                <Text type="secondary">Listening to:</Text>
                                <div style={{ marginLeft: 8, marginTop: 4 }}>
                                    {subscriptionStatus.orgId && (
                                        <Tag color="blue">Org: {subscriptionStatus.orgId}</Tag>
                                    )}
                                    {subscriptionStatus.tenantId && (
                                        <Tag color="purple">Tenant: {subscriptionStatus.tenantId}</Tag>
                                    )}
                                    {subscriptionStatus.userId && (
                                        <Tag color="cyan">User: {subscriptionStatus.userId}</Tag>
                                    )}
                                </div>
                            </div>       
                            {subscriptionStatus?.addresses && subscriptionStatus?.addresses?.length > 0 && (
                                <div>
                                    <Text type="secondary">Watching Addresses:</Text>
                                    <div style={{ marginLeft: 8, marginTop: 4 }}>
                                        {subscriptionStatus?.addresses?.map(addr => (
                                            <Tag key={addr} color="green">{addr?.substring(0, 6)}...{addr?.substring(addr?.length - 4)}</Tag>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </Space>
            }
            placement="bottomRight"
        >
            <Button
                icon={isActive ? <ThunderboltOutlined /> : <PauseCircleOutlined />}
                type={isActive ? "primary" : "default"}
                style={{
                    animation: isActive ? 'pulse 2s infinite' : 'none'
                }}
            >
                Live Feed
            </Button>
        </Popover>                            
    )
}; 
import type { Transaction } from '../components/transactions/table/types';

export const extractAddressesFromParams = (param: any): string[] => {
    if (!param) return [];
    
    const addresses: string[] = [];

    // Helper function to check if a string is a valid address
    const isAddress = (value: string): boolean => {
        return typeof value === 'string' && value.startsWith('0x') && value.length === 42;
    };

    // Helper function to process a value and extract addresses
    const processValue = (value: any) => {
        if (typeof value === 'string') {
            // Handle comma-separated values
            if (value.includes(',')) {
                value.split(',').forEach(part => {
                    const trimmed = part.trim();
                    if (isAddress(trimmed)) {
                        addresses.push(trimmed.toLowerCase());
                    }
                });
            } else if (isAddress(value)) {
                addresses.push(value.toLowerCase());
            }
        } else if (Array.isArray(value)) {
            // Handle array of values
            value.forEach(item => {
                if (typeof item === 'string' && isAddress(item)) {
                    addresses.push(item.toLowerCase());
                } else if (typeof item === 'object') {
                    traverse(item);
                }
            });
        }
    };

    // Recursive function to traverse the object
    const traverse = (obj: any) => {
        if (!obj) return;

        if (Array.isArray(obj)) {
            obj.forEach(item => traverse(item));
            return;
        }

        if (typeof obj === 'object') {
            // Check if it's a parameter with type and value
            if (obj.type && obj.value) {
                if (obj.type === 'address') {
                    processValue(obj.value);
                } else if (obj.type === 'address[]') {
                    if (Array.isArray(obj.value)) {
                        obj.value.forEach((addr: string) => {
                            if (isAddress(addr)) {
                                addresses.push(addr.toLowerCase());
                            }
                        });
                    } else {
                        processValue(obj.value); // Handle comma-separated string case
                    }
                } else if (obj.type === 'bytes' && typeof obj.value === 'object' && obj.value.is_proxy_call) {
                    // Handle proxy call data
                    if (obj.value.address && isAddress(obj.value.address)) {
                        addresses.push(obj.value.address.toLowerCase());
                    }
                    
                    // Process proxy call parameters
                    if (obj.value.params && Array.isArray(obj.value.params)) {
                        obj.value.params.forEach((proxyParam: any) => {
                            // Special handling for address[] type in proxy params
                            if (proxyParam.type === 'address[]' && Array.isArray(proxyParam.value)) {
                                proxyParam.value.forEach((addr: string) => {
                                    if (isAddress(addr)) {
                                        addresses.push(addr.toLowerCase());
                                    }
                                });
                            } else {
                                traverse(proxyParam);
                            }
                        });
                    }
                }
            }

            // Check all properties for addresses
            Object.entries(obj).forEach(([key, value]) => {
                // Skip type and name fields to avoid duplicates
                if (key === 'type' || key === 'name') return;
                
                if (typeof value === 'string' && isAddress(value)) {
                    addresses.push(value.toLowerCase());
                } else if (Array.isArray(value)) {
                    // Handle arrays of addresses directly
                    value.forEach((item: any) => {
                        if (typeof item === 'string' && isAddress(item)) {
                            addresses.push(item.toLowerCase());
                        } else if (typeof item === 'object') {
                            traverse(item);
                        }
                    });
                } else if (typeof value === 'object') {
                    traverse(value);
                }
            });
        }
    };

    traverse(param);
    return Array.from(new Set(addresses)); // Return unique addresses only
};

export interface AddressLocation {
  address: string;
  locations: {
    type: string;
    path: string;
    description: string;
  }[];
}

export const extractAddressesFromTransaction = (
  transaction?: Transaction | null, 
  includeLocations: boolean = false
): AddressLocation[] => {
  if (!transaction) {
    return [];
  }

  const addressSet = new Set<string>();
  const addressLocations: Record<string, Set<{type: string; path: string; description: string}>> = {};

  const addAddress = (address: string, type: string, path: string, description: string) => {
    if (!address) return;
    
    addressSet.add(address.toLowerCase());
    if (!addressLocations[address.toLowerCase()]) {
      addressLocations[address.toLowerCase()] = new Set();
    }
    addressLocations[address.toLowerCase()].add({ type, path, description });
  };

  // Helper function to process address arrays
  const processAddressArray = (
    addresses: string[], 
    type: string, 
    basePath: string, 
    paramName: string, 
    contextLabel: string
  ) => {
    addresses.forEach((addr, idx) => {
      if (addr) {
        addAddress(
          addr,
          type,
          `${basePath}[${idx}]`,
          `'${paramName}[${idx}]' in '${contextLabel}'`
        );
      }
    });
  };

  // Main transaction addresses
  if (transaction.from_address) {
    addAddress(
      transaction.from_address, 
      'main', 
      'from_address', 
      'Transaction sender'
    );
  }
  
  // For to_address, explicitly check for "null" string
  if (transaction.to_address && transaction.to_address !== "null" && transaction.to_address != "") {
    addAddress(
      transaction.to_address, 
      'main', 
      'to_address', 
      'Transaction recipient'
    );
  }

  // Add receipt_contract_address (for contract creation transactions)
  if (transaction.receipt_contract_address && transaction.receipt_contract_address !== "null" && transaction.receipt_contract_address != "") {
    addAddress(
      transaction.receipt_contract_address,
      'main',
      'receipt_contract_address',
      'Newly deployed contract'
    );
  }

  // Decoded call addresses
  if (transaction.decoded_transaction?.decoded_call) {
    // Contract address
    if (transaction.decoded_transaction.decoded_call.address) {
      addAddress(
        transaction.decoded_transaction.decoded_call.address,
        'decoded_call_contract',
        'decoded_call.address',
        `Contract being called with '${transaction.decoded_transaction.decoded_call.label || 'function'}'`
      );
    }

    // Function parameters (including proxy data)
    transaction.decoded_transaction.decoded_call.params?.forEach((param: any, index: number) => {
      if (param.type === 'address' && param.value) {
        addAddress(
          param.value,
          'decoded_call',
          `decoded_call.params[${index}]`,
          `'${param.name || 'param'}' in '${transaction.decoded_transaction.decoded_call.label || 'function call'}'`
        );
      } else if (param.type === 'address[]' && Array.isArray(param.value)) {
        processAddressArray(
          param.value,
          'decoded_call',
          `decoded_call.params[${index}].value`,
          param.name || 'param',
          transaction.decoded_transaction.decoded_call.label || 'function call'
        );
      } else if (param.type === 'bytes' && typeof param.value === 'object' && param.value.is_proxy_call) {
        // Handle proxy call target address
        if (param.value.address) {
          addAddress(
            param.value.address,
            'proxy_target',
            `decoded_call.params[${index}].value.address`,
            `Target contract for proxy call '${param.value.label || 'function'}'`
          );
        }
        
        // Extract addresses from proxy call parameters
        if (param.value.params) {
          param.value.params.forEach((proxyParam: any, proxyIndex: number) => {
            if (proxyParam.type === 'address' && proxyParam.value) {
              addAddress(
                proxyParam.value,
                'proxy_param',
                `decoded_call.params[${index}].value.params[${proxyIndex}]`,
                `'${proxyParam.name || `param_${proxyIndex}`}' in proxy call '${param.value.label || 'function'}'`
              );
            } else if (proxyParam.type === 'address[]' && Array.isArray(proxyParam.value)) {
              // Handle address arrays in proxy parameters
              processAddressArray(
                proxyParam.value,
                'proxy_param',
                `decoded_call.params[${index}].value.params[${proxyIndex}].value`,
                proxyParam.name || `param_${proxyIndex}`,
                `proxy call '${param.value.label || 'function'}'`
              );
            } else {
              // For other parameter types, use the generic extractor
              const addresses = extractAddressesFromParams(proxyParam);
              addresses.forEach(addr => {
                addAddress(
                  addr,
                  'proxy_param',
                  `decoded_call.params[${index}].value.params[${proxyIndex}]`,
                  `'${proxyParam.name || `param_${proxyIndex}`}' in proxy call '${param.value.label || 'function'}'`
                );
              });
            }
          });
        }
      } else {
        // For other parameter types, use the generic extractor
        const addresses = extractAddressesFromParams(param);
        addresses.forEach(addr => {
          addAddress(
            addr,
            'decoded_call',
            `decoded_call.params[${index}]`,
            `Found in '${param.name || `param_${index}`}' of '${transaction.decoded_transaction.decoded_call?.label || 'function call'}'`
          );
        });
      }
    });
  }

  // Decoded events addresses
  if (transaction.decoded_transaction?.decoded_events) {
    Object.values(transaction.decoded_transaction.decoded_events).forEach((event: any) => {
      // Add the contract address from the event object itself
      if (event.address) {
        addAddress(
          event.address,
          'decoded_event_contract',
          `event.address`,
          `Contract emitting '${event.decoded_event?.label || 'event'}'`
        );
      }
      
      // Add the contract address from the decoded_event
      if (event.decoded_event?.address) {
        addAddress(
          event.decoded_event.address,
          'decoded_event_contract',
          `decoded_event.address`,
          `Contract emitting '${event.decoded_event.label || 'event'}'`
        );
      }

      // Add addresses from event parameters
      if (event.decoded_event?.params) {
        event.decoded_event.params.forEach((param: any, index: number) => {
          if (param.type === 'address' && param.value) {
            addAddress(
              param.value,
              'decoded_event',
              `decoded_event.params[${index}]`,
              `'${param.name || 'param'}' in '${event.decoded_event.label || 'event'}'`
            );
          } else {
            // For other parameter types, use the generic extractor
            const addresses = extractAddressesFromParams(param);
            addresses.forEach(addr => {
              addAddress(
                addr,
                'decoded_event',
                `decoded_event.params[${index}]`,
                `Found in '${param.name || `param_${index}`}' of '${event.decoded_event?.label || 'event'}'`
              );
            });
          }
        });
      }
    });
  }

  // Decoded internal transactions
  if (transaction.decoded_transaction?.decoded_internal_transactions) {
    Object.values(transaction.decoded_transaction.decoded_internal_transactions).forEach((tx: any) => {
      if (tx.from) {
        addAddress(
          tx.from,
          'internal_tx',
          `internal_tx.from`,
          'Internal transaction sender'
        );
      }
      if (tx.to) {
        addAddress(
          tx.to,
          'internal_tx',
          `internal_tx.to`,
          'Internal transaction recipient'
        );
      }
      
      // Process parameters of internal transactions
      if (tx.params) {
        tx.params.forEach((param: any, index: number) => {
          const addresses = extractAddressesFromParams(param);
          addresses.forEach(addr => {
            addAddress(
              addr,
              'internal_tx_param',
              `internal_tx.params[${index}]`,
              `Found in '${param.name || `param_${index}`}' of internal transaction`
            );
          });
        });
      }
    });
  }

  // Native transfers
  transaction.native_transfers?.forEach((transfer: any, index: number) => {
    if (transfer?.from_address) {
      addAddress(
        transfer.from_address, 
        'native_transfer', 
        `native_transfers[${index}].from_address`, 
        'Native token sender'
      );
    }
    if (transfer?.to_address && transfer.to_address !== "null" && transfer.to_address != "") {
      addAddress(
        transfer.to_address, 
        'native_transfer', 
        `native_transfers[${index}].to_address`, 
        'Native token recipient'
      );
    }
  });

  // ERC20 transfers
  transaction.erc20_transfers?.forEach((transfer: any, index: number) => {
    if (transfer?.from_address) {
      addAddress(
        transfer.from_address, 
        'erc20_transfer', 
        `erc20_transfers[${index}].from_address`, 
        'ERC20 token sender'
      );
    }
    if (transfer?.to_address && transfer.to_address !== "null" && transfer.to_address != "") {
      addAddress(
        transfer.to_address, 
        'erc20_transfer', 
        `erc20_transfers[${index}].to_address`, 
        'ERC20 token recipient'
      );
    }
  });

  // NFT transfers
  transaction.nft_transfers?.forEach((transfer: any, index: number) => {
    if (transfer?.from_address) {
      addAddress(
        transfer.from_address, 
        'nft_transfer', 
        `nft_transfers[${index}].from_address`, 
        'NFT sender'
      );
    }
    if (transfer?.to_address && transfer.to_address !== "null" && transfer.to_address != "") {
      addAddress(
        transfer.to_address, 
        'nft_transfer', 
        `nft_transfers[${index}].to_address`, 
        'NFT recipient'
      );
    }
  });

  return Array.from(addressSet).map(address => ({
    address,
    locations: includeLocations 
      ? Array.from(addressLocations[address])
      : []
  }));
};

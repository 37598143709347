import { generateClient } from '@aws-amplify/api';
import { Amplify } from 'aws-amplify';
import { safeJSONParse } from '../utils/parser';
import { SubscriptionOptions } from './types';
import { AppSyncClient } from '../contexts/config/types';
import { SUBSCRIBE } from './subscriptions';

let client: AppSyncClient;

// Initialize client function
export const initializeClient = (region: string = 'us-west-2') => {
    console.log('Initializing AppSync client with region:', region);
    
    try {
        // Configure Amplify
        Amplify.configure({
            API: {
                GraphQL: {
                    endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT || "https://dhcim4ityncm3cb2asrunelpem.appsync-api.us-west-2.amazonaws.com/graphql",
                    region: region,
                    defaultAuthMode: 'lambda'
                }
            }
        });

        // Create client
        client = generateClient() as unknown as AppSyncClient;
        console.log('AppSync client created successfully');
        return client;
    } catch (error) {
        console.error('Failed to initialize AppSync client:', error);
        throw error;
    }
};

// Initialize client with default region
initializeClient();

// Export subscription creation function
export const createSubscription = async (channelName: string, options: SubscriptionOptions) => {
    // Ensure client is initialized
    if (!client) {
        console.log('Client not initialized, initializing with default region');
        initializeClient();
    }

    if (!client) {
        throw new Error('AppSync client not initialized');
    }

    // Destructure only what we need from options to avoid unnecessary re-renders
    const { 
        token, 
        debug,
        onMessage,
        onError,
        onComplete,
        channels = [],
        processedMessageIds = new Set()
    } = options;

    // Memoize channel config to prevent unnecessary lookups
    const channelConfig = channels.find(c => c.name === channelName);
    if (!channelConfig) {
        debug.error(`Channel configuration not found: ${channelName}`);
        return null;
    }

    debug.log(`Setting up subscription for channel: ${channelName}`);

    try {
        debug.log(`Making GraphQL subscription request for channel: ${channelName}`);
        const observable = await client.graphql({ 
            query: SUBSCRIBE,
            variables: { name: channelName },
            authToken: `Bearer ${token}`
        });

        debug.log(`Got subscription observable for channel: ${channelName}`, observable);

        if (!observable) {
            debug.error(`No observable returned for channel: ${channelName}`);
            return null;
        }

        debug.log(`Creating subscription for channel: ${channelName}`);
        const subscription = observable.subscribe({
            next: (response: any) => {
                try {
                    debug.log(`Raw message received on channel: ${channelName}`, response);
                    const data = response.data?.subscribe?.data;
                    
                    if (!data) {
                        debug.log(`Empty message received on channel: ${channelName}`);
                        return;
                    }

                    debug.log(`Processing message data on channel: ${channelName}`, data);

                    // Parse message data
                    const parsedData = safeJSONParse(data);
                    if (!parsedData) {
                        debug.error(`Failed to parse message data on channel: ${channelName}`, data);
                        return;
                    }

                    const messageId = parsedData.id || 
                                    parsedData.message_id || 
                                    `msg-${Date.now()}-${Math.random()}`;

                    // Check for duplicate messages
                    if (processedMessageIds.has(messageId)) {
                        debug.log(`Skipping duplicate message ${messageId} on channel: ${channelName}`);
                        return;
                    }

                    processedMessageIds.add(messageId);

                    const channelMessage = {
                        channel: channelName,
                        data: parsedData,
                        messageId,
                        timestamp: Date.now()
                    };

                    debug.log(`Dispatching message on channel: ${channelName}`, channelMessage);

                    // Use memoized channel config for message handling
                    channelConfig?.onMessage?.(channelMessage);
                    
                    // Call global handler
                    onMessage?.(channelMessage);
                } catch (error) {
                    debug.error(`Processing error on channel ${channelName}:`, error);
                    onError?.(error);
                }
            },
            error: (error) => {
                debug.error(`Subscription error on channel ${channelName}:`, error);
                onError?.(error);
            }
        });

        if (!subscription) {
            debug.error(`Failed to create subscription object for channel: ${channelName}`);
            return null;
        }

        debug.log(`Subscription setup complete for channel: ${channelName}`, {
            hasSubscription: !!subscription,
            subscriptionObject: subscription
        });

        return subscription;
    } catch (error) {
        debug.error(`Setup error for channel ${channelName}:`, error);
        onError?.(error);
        return null;
    }
};

// Export the client and initialization function
export { client };

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Typography, Table, Button, Space, Empty, Spin, Alert, Tag, message, Modal, Input, Form, Select, Divider, List, Card, Tabs, Collapse, Checkbox } from 'antd';
import { LinkOutlined, CopyOutlined, ReloadOutlined, ApartmentOutlined, ApiOutlined, SwapOutlined, EyeOutlined, ArrowRightOutlined, SaveOutlined, HistoryOutlined, SendOutlined, PlayCircleOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { getExplorerAddressLink, getExplorerName } from '../../utils/chainExplorer';
import { abiCache } from '../../utils/abiCache';
import { shortenAddress } from '../../utils/formatters';

const { Text, Paragraph, Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

// Add a new component for array visualization
const ArrayValueModal = ({ 
  isOpen, 
  onClose, 
  arrayData, 
  title,
  onExploreAddress,
  chainId
}: { 
  isOpen: boolean; 
  onClose: () => void; 
  arrayData: any[]; 
  title: string;
  onExploreAddress: (address: string, name: string) => void;
  chainId: string;
}) => {
  return (
    <Modal
      title={`${title} (${arrayData.length} items)`}
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>
      ]}
      width={600}
    >
      <List
        dataSource={arrayData}
        renderItem={(item, index) => (
          <List.Item>
            <Card 
              size="small" 
              title={`Item ${index}`}
              style={{ width: '100%' }}
            >
              {typeof item === 'string' && /^0x[a-fA-F0-9]{40}$/.test(item) ? (
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Text code>{item}</Text>
                  <Space>
                    {getExplorerAddressLink(chainId, item) && (
                      <Button 
                        type="link" 
                        size="small" 
                        icon={<LinkOutlined />} 
                        onClick={() => window.open(getExplorerAddressLink(chainId, item)!, '_blank')}
                      >
                        View on {getExplorerName(chainId)}
                      </Button>
                    )}
                    <Button 
                      type="link"
                      size="small"
                      icon={<ApartmentOutlined />}
                      onClick={() => {
                        onClose();
                        onExploreAddress(item, `${title}[${index}]`);
                      }}
                    >
                      Explore Contract
                    </Button>
                    <Button 
                      type="text" 
                      size="small" 
                      icon={<CopyOutlined />} 
                      onClick={() => {
                        copy(item);
                        message.success('Address copied');
                      }}
                    >
                      Copy
                    </Button>
                  </Space>
                </Space>
              ) : (
                <Text>{typeof item === 'object' ? JSON.stringify(item) : String(item)}</Text>
              )}
            </Card>
          </List.Item>
        )}
      />
    </Modal>
  );
};

// Update the RenderStateValue component
const RenderStateValue = ({ 
  value, 
  name, 
  onExploreAddress,
  onViewArray
}: { 
  value: any; 
  name: string; 
  onExploreAddress: (address: string, name: string) => void;
  onViewArray: (array: any[], name: string) => void;
}) => {
  // Handle null/undefined
  if (value === null || value === undefined) {
    return <Text type="secondary">null</Text>;
  }
  
  // Handle BigInt
  if (typeof value === 'bigint') {
    return <Text code>{value.toString()}</Text>;
  }
  
  // Handle booleans
  if (typeof value === 'boolean') {
    return (
      <Tag color={value ? 'success' : 'error'}>
        {value ? 'True' : 'False'}
      </Tag>
    );
  }
  
  // Handle strings (including addresses)
  if (typeof value === 'string') {
    // Check if it's an address
    if (/^0x[a-fA-F0-9]{40}$/.test(value)) {
      return (
        <Space>
          <Text code>{value.substring(0, 6)}...{value.substring(38)}</Text>
          <Button
            type="link"
            size="small"
            icon={<ApartmentOutlined />}
            onClick={() => onExploreAddress(value, name)}
            title="Explore this contract"
          />
          <Button 
            type="text" 
            size="small" 
            icon={<CopyOutlined />} 
            onClick={() => {
              copy(value);
              message.success('Address copied');
            }}
            title="Copy address"
          />
        </Space>
      );
    }
    
    // Regular string
    return <Text>{value}</Text>;
  }
  
  // Handle arrays
  if (Array.isArray(value)) {
    return (
      <Space>
        <Text>{`Array(${value.length})`}</Text>
        <Button
          type="link"
          size="small"
          icon={<EyeOutlined />}
          onClick={() => onViewArray(value, name)}
          title="View array contents"
        />
      </Space>
    );
  }
  
  // Handle objects
  if (typeof value === 'object') {
    return <Text>{`Object`}</Text>;
  }
  
  // Default for other types
  return <Text>{String(value)}</Text>;
};

interface RelatedContractExplorerProps {
  address: string;
  chainId: string;
  fetchAbi: (address: string, chainId: string) => Promise<any>;
  publicClient: any;
  onClose: () => void;
  parentContractName?: string;
  onExploreAddress: (address: string, name: string) => void;
}

const RelatedContractExplorer: React.FC<RelatedContractExplorerProps> = ({ 
  address, 
  chainId, 
  fetchAbi, 
  publicClient,
  onClose,
  parentContractName,
  onExploreAddress
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [contractState, setContractState] = useState<any[]>([]);
  const [contractName, setContractName] = useState<string>('');
  
  // State for manual ABI modal
  const [isAbiModalVisible, setIsAbiModalVisible] = useState(false);
  const [manualAbi, setManualAbi] = useState('');
  const [abiModalError, setAbiModalError] = useState<string | null>(null);
  
  // State for proxy implementation
  const [isProxyModalVisible, setIsProxyModalVisible] = useState(false);
  const [proxyImplementationAddress, setProxyImplementationAddress] = useState('');
  const [proxyModalError, setProxyModalError] = useState<string | null>(null);
  const [isUsingProxy, setIsUsingProxy] = useState(false);
  const [proxyImplementationName, setProxyImplementationName] = useState('');
  
  // State for array modal
  const [isArrayModalVisible, setIsArrayModalVisible] = useState(false);
  const [currentArrayData, setCurrentArrayData] = useState<any[]>([]);
  const [currentArrayName, setCurrentArrayName] = useState('');
  
  // Reference to the current ABI being used
  const currentAbiRef = useRef<any[]>([]);
  const currentAbiSourceRef = useRef<string>('');
  
  // Common proxy patterns to detect
  const PROXY_PATTERNS = [
    { name: 'implementation', type: 'function' },
    { name: 'getImplementation', type: 'function' },
    { name: 'target', type: 'function' },
    { name: 'getTarget', type: 'function' },
    { name: 'masterCopy', type: 'function' },
    { name: 'logic', type: 'function' },
    { name: 'getLogic', type: 'function' },
    { name: 'getProxyImplementation', type: 'function' },
    { name: 'getProxyTarget', type: 'function' },
    { name: 'getImplementationAddress', type: 'function' },
    { name: 'getTargetAddress', type: 'function' },
    { name: 'getLogicAddress', type: 'function' },
    { name: 'getProxyAddress', type: 'function' },
    { name: 'getProxyImplementationAddress', type: 'function' },
    { name: 'getProxyTargetAddress', type: 'function' },
    { name: 'getLogicContractAddress', type: 'function' },
    { name: 'getImplementationContract', type: 'function' },
    { name: 'getTargetContract', type: 'function' },
    { name: 'getLogicContract', type: 'function' },
    { name: 'getProxyImplementationContract', type: 'function' },
    { name: 'getProxyTargetContract', type: 'function' },
    { name: 'getLogicContractAddress', type: 'function' },
    { name: 'getImplementationContractAddress', type: 'function' },
    { name: 'getTargetContractAddress', type: 'function' },
    { name: 'getLogicContractAddress', type: 'function' },
    { name: 'getProxyImplementationContractAddress', type: 'function' },
    { name: 'getProxyTargetContractAddress', type: 'function' },
    { name: 'getLogicContractAddress', type: 'function' },
  ];
  
  // Add state for function calls
  const [contractFunctions, setContractFunctions] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>('state');
  const [functionInputs, setFunctionInputs] = useState<Record<string, Record<string, string>>>({});
  const [functionResults, setFunctionResults] = useState<Record<string, any>>({});
  const [functionLoading, setFunctionLoading] = useState<Record<string, boolean>>({});
  const [functionErrors, setFunctionErrors] = useState<Record<string, string>>({});
  
  // Add state for ABI modal context
  const [abiModalTitle, setAbiModalTitle] = useState("Set Contract ABI Manually");
  const [abiModalOkText, setAbiModalOkText] = useState("Load Contract State");
  const [isAbiModalForProxy, setIsAbiModalForProxy] = useState(false);
  
  // Function to show the ABI modal with the current ABI if available
  const showAbiModal = (isForProxy = false) => {
    if (currentAbiRef.current && currentAbiRef.current.length > 0) {
      setManualAbi(JSON.stringify(currentAbiRef.current, null, 2));
    } else {
      // Check if we have a cached ABI for this address
      const cachedAbi = abiCache.get(chainId, address);
      if (cachedAbi) {
        setManualAbi(JSON.stringify(cachedAbi.abi, null, 2));
      } else {
        setManualAbi('');
      }
    }
    
    // Set the context for this ABI modal session
    if (isForProxy) {
      setAbiModalTitle("Set Proxy Implementation ABI Manually");
      setAbiModalOkText("Load Using Implementation ABI");
      setIsAbiModalForProxy(true);
    } else {
      setAbiModalTitle("Set Contract ABI Manually");
      setAbiModalOkText("Load Contract State");
      setIsAbiModalForProxy(false);
    }
    
    setAbiModalError(null);
    setIsAbiModalVisible(true);
  };
  
  // Function to show the proxy modal
  const showProxyModal = () => {
    setProxyModalError(null);
    setIsProxyModalVisible(true);
  };
  
  // Function to handle manual ABI submission
  const handleManualAbiSubmit = async () => {
    try {
      setAbiModalError(null);
      
      // Validate ABI JSON
      let parsedAbi;
      try {
        parsedAbi = JSON.parse(manualAbi);
        if (!Array.isArray(parsedAbi)) {
          throw new Error('ABI must be an array');
        }
      } catch (err) {
        setAbiModalError('Invalid ABI format. Please provide a valid JSON array.');
        return;
      }
      
      // Close modal
      setIsAbiModalVisible(false);
      
      // Set loading state
      setLoading(true);
      setError(null); // Clear any previous errors
      
      // Handle differently based on whether this is for a proxy or not
      if (isAbiModalForProxy) {
        // This is a manually entered proxy implementation ABI
        abiCache.set(chainId, address, parsedAbi, 'proxy');
        currentAbiRef.current = parsedAbi;
        currentAbiSourceRef.current = 'proxy';
        setIsUsingProxy(true);
      } else {
        // This is a regular manual ABI
        abiCache.set(chainId, address, parsedAbi, 'manual');
        currentAbiRef.current = parsedAbi;
        currentAbiSourceRef.current = 'manual';
        setIsUsingProxy(false);
      }
      
      // Try to find contract name
      const contractNameEntry = parsedAbi.find((entry: any) => 
        entry.name === 'name' && 
        entry.type === 'function' && 
        entry.inputs?.length === 0 &&
        entry.outputs?.length === 1 &&
        entry.outputs[0].type === 'string'
      );
      
      if (contractNameEntry) {
        try {
          const name = await publicClient.readContract({
            address,
            abi: [contractNameEntry],
            functionName: 'name'
          });
          setContractName(name);
        } catch (e) {
          console.log('Could not get contract name:', e);
        }
      }
      
      // Get read functions from ABI
      const readFunctions = parsedAbi.filter((item: any) => 
        item.type === 'function' && 
        (item.stateMutability === 'view' || item.stateMutability === 'pure')
      );
      
      // Separate functions with no inputs (for state display)
      const stateReadFunctions = readFunctions.filter((func: any) => 
        !func.inputs || func.inputs.length === 0
      );
      
      // Functions with inputs (for function calls tab)
      const callableFunctions = readFunctions.filter((func: any) => 
        func.inputs && func.inputs.length > 0
      );
      
      setContractFunctions(callableFunctions);
      
      if (readFunctions.length === 0) {
        setLoading(false);
        setError('No readable functions found in the provided ABI');
        return;
      }
      
      // Call each no-input read function to get state
      const stateData = [];
      let successCount = 0;
      let errorCount = 0;
      
      for (const func of stateReadFunctions) {
        try {
          const result = await publicClient.readContract({
            address,
            abi: [func],
            functionName: func.name
          });
          
          stateData.push({
            name: func.name,
            value: result,
            type: func.outputs?.[0]?.type || 'unknown'
          });
          successCount++;
        } catch (e) {
          console.log(`Error calling ${func.name}:`, e);
          errorCount++;
          // Skip functions that fail
        }
      }
      
      setContractState(stateData);
      setLoading(false);
      
      if (stateData.length === 0 && callableFunctions.length === 0) {
        if (errorCount > 0) {
          setError(`All ${errorCount} functions failed to execute. This might be a proxy contract or the ABI might not match this contract.`);
        } else {
          setError('No readable state or functions found with the provided ABI');
        }
      } else {
        if (errorCount > 0) {
          message.info(`Loaded ${successCount} state values. ${errorCount} functions failed to execute.`);
        } else {
          message.success('Contract loaded successfully');
        }
      }
    } catch (err: any) {
      setLoading(false);
      setError('Error using manual ABI: ' + (err.message || 'Unknown error'));
    }
  };
  
  // Function to handle proxy implementation submission
  const handleProxyImplementationSubmit = async () => {
    try {
      setProxyModalError(null);
      
      // Validate implementation address
      if (!proxyImplementationAddress || !/^0x[a-fA-F0-9]{40}$/.test(proxyImplementationAddress)) {
        setProxyModalError('Please enter a valid Ethereum address');
        return;
      }
      
      // Close modal
      setIsProxyModalVisible(false);
      
      // Set loading state
      setLoading(true);
      
      // Fetch ABI from the implementation address
      const implementationAbi = await fetchAbi(proxyImplementationAddress, chainId);
      if (!implementationAbi || !Array.isArray(implementationAbi)) {
        throw new Error('Failed to load ABI from implementation contract');
      }
      
      // Save to cache
      abiCache.set(chainId, proxyImplementationAddress, implementationAbi, 'fetched');
      abiCache.set(chainId, address, implementationAbi, 'proxy');
      
      // Update current ABI reference
      currentAbiRef.current = implementationAbi;
      currentAbiSourceRef.current = 'proxy';
      
      // Try to find contract name from implementation
      const contractNameEntry = implementationAbi.find(entry => 
        entry.name === 'name' && 
        entry.type === 'function' && 
        entry.inputs?.length === 0 &&
        entry.outputs?.length === 1 &&
        entry.outputs[0].type === 'string'
      );
      
      if (contractNameEntry) {
        try {
          // IMPORTANT: Use the PROXY address (address), not the implementation address
          const name = await publicClient.readContract({
            address, // Use the proxy address here!
            abi: [contractNameEntry],
            functionName: 'name'
          });
          setContractName(name);
        } catch (e) {
          console.log('Could not get contract name:', e);
        }
      }
      
      // Get read functions from implementation ABI
      const readFunctions = implementationAbi.filter(item => 
        item.type === 'function' && 
        (item.stateMutability === 'view' || item.stateMutability === 'pure')
      );
      
      // Separate functions with no inputs (for state display)
      const stateReadFunctions = readFunctions.filter(func => 
        !func.inputs || func.inputs.length === 0
      );
      
      // Functions with inputs (for function calls tab)
      const callableFunctions = readFunctions.filter(func => 
        func.inputs && func.inputs.length > 0
      );
      
      setContractFunctions(callableFunctions);
      
      // Call each no-input read function to get state FROM THE PROXY ADDRESS
      const stateData = [];
      for (const func of stateReadFunctions) {
        try {
          // IMPORTANT: Use the PROXY address (address), not the implementation address
          const result = await publicClient.readContract({
            address, // Use the proxy address here!
            abi: [func],
            functionName: func.name
          });
          
          stateData.push({
            name: func.name,
            value: result,
            type: func.outputs?.[0]?.type || 'unknown'
          });
        } catch (e) {
          console.log(`Error calling ${func.name}:`, e);
          // Skip functions that fail
        }
      }
      
      setContractState(stateData);
      setIsUsingProxy(true);
      setLoading(false);
      
      if (stateData.length === 0 && callableFunctions.length === 0) {
        message.info('No readable state or functions found with the implementation ABI');
      } else {
        message.success('Contract loaded using implementation ABI');
      }
    } catch (err: any) {
      setLoading(false);
      setError('Error using implementation ABI: ' + (err.message || 'Unknown error'));
    }
  };
  
  // Function to call a contract function
  const callContractFunction = async (functionName: string, functionAbi: any) => {
    try {
      setFunctionLoading(prev => ({ ...prev, [functionName]: true }));
      setFunctionErrors(prev => ({ ...prev, [functionName]: '' }));
      
      const inputs = functionInputs[functionName] || {};
      const args = functionAbi.inputs.map((input: any) => {
        const value = inputs[input.name || ''] || '';
        
        // Convert input values based on type
        if (input.type === 'uint256' || input.type.startsWith('uint')) {
          return value ? BigInt(value) : 0n;
        } else if (input.type === 'bool') {
          return value === 'true';
        } else if (input.type === 'address') {
          return value || '0x0000000000000000000000000000000000000000';
        } else {
          return value || '';
        }
      });
      
      // Always use the current contract address (which might be a proxy)
      // but with the ABI that was loaded (which might be from an implementation)
      const result = await publicClient.readContract({
        address, // Always use the current address
        abi: [functionAbi],
        functionName,
        args
      });
      
      setFunctionResults(prev => ({ ...prev, [functionName]: result }));
      setFunctionLoading(prev => ({ ...prev, [functionName]: false }));
    } catch (err: any) {
      console.error(`Error calling ${functionName}:`, err);
      setFunctionErrors(prev => ({ ...prev, [functionName]: err.message || 'Error calling function' }));
      setFunctionLoading(prev => ({ ...prev, [functionName]: false }));
    }
  };
  
  // Function to handle input change
  const handleFunctionInputChange = (functionName: string, inputName: string, value: string) => {
    setFunctionInputs(prev => ({
      ...prev,
      [functionName]: {
        ...(prev[functionName] || {}),
        [inputName]: value
      }
    }));
  };
  
  // Function to reset a function call
  const resetFunctionCall = (functionName: string) => {
    setFunctionInputs(prev => ({ ...prev, [functionName]: {} }));
    setFunctionResults(prev => {
      const newResults = { ...prev };
      delete newResults[functionName];
      return newResults;
    });
    setFunctionErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[functionName];
      return newErrors;
    });
  };
  
  // Load the contract state for the related address
  const loadRelatedContractState = useCallback(async (customAbi?: any[]) => {
    try {
      setLoading(true);
      setError(null);
      
      // Reset proxy flag when loading a new contract
      setIsUsingProxy(false);
      setProxyImplementationAddress('');
      setProxyImplementationName('');
      
      // Check cache first
      let abi;
      
      if (customAbi) {
        abi = customAbi;
        currentAbiSourceRef.current = 'custom';
      } else if (abiCache.has(chainId, address)) {
        console.log(`Using cached ABI for ${address}`);
        const cachedAbi = abiCache.get(chainId, address)!;
        abi = cachedAbi.abi;
        currentAbiSourceRef.current = cachedAbi.source;
        
        // Only set proxy flag if this ABI was explicitly saved as a proxy implementation
        if (cachedAbi.source === 'proxy') {
          setIsUsingProxy(true);
        }
      } else {
        // Fetch ABI for the related contract
        abi = await fetchAbi(address, chainId);
        if (abi && Array.isArray(abi)) {
          abiCache.set(chainId, address, abi, 'fetched');
          currentAbiSourceRef.current = 'fetched';
        }
      }
      
      if (!abi || !Array.isArray(abi)) {
        throw new Error('Failed to load ABI for related contract');
      }
      
      // Update current ABI reference
      currentAbiRef.current = abi;
      
      // Check if this might be a proxy contract
      let implementationAddress = '';
      let implementationFound = false;
      
      // Try to detect proxy implementation
      for (const pattern of PROXY_PATTERNS) {
        const proxyFn = abi.find(entry => 
          entry.name === pattern.name && 
          entry.type === pattern.type &&
          entry.inputs?.length === 0
        );
        
        if (proxyFn) {
          try {
            const implementation = await publicClient.readContract({
              address,
              abi: [proxyFn],
              functionName: proxyFn.name
            });
            
            if (implementation && typeof implementation === 'string' && 
                implementation.startsWith('0x') && implementation.length === 42) {
              implementationAddress = implementation;
              implementationFound = true;
              console.log(`Found proxy implementation via ${proxyFn.name}: ${implementation}`);
              break;
            }
          } catch (e) {
            console.log(`Error checking proxy function ${proxyFn.name}:`, e);
          }
        }
      }
      
      // If we found a proxy implementation, suggest using it
      if (implementationFound && implementationAddress) {
        try {
          // Try to get the implementation contract's name
          const implAbi = await fetchAbi(implementationAddress, chainId);
          if (implAbi && Array.isArray(implAbi)) {
            const nameEntry = implAbi.find(entry => 
              entry.name === 'name' && 
              entry.type === 'function' && 
              entry.inputs?.length === 0
            );
            
            if (nameEntry) {
              try {
                // IMPORTANT: Use the PROXY address (address), not the implementation address
                const name = await publicClient.readContract({
                  address, // Use the proxy address here!
                  abi: [nameEntry],
                  functionName: 'name'
                });
                if (name && typeof name === 'string') {
                  setProxyImplementationName(name);
                }
              } catch (e) {
                console.log('Could not get implementation name:', e);
              }
            }
          }
        } catch (e) {
          console.log('Error fetching implementation ABI:', e);
        }
        
        setProxyImplementationAddress(implementationAddress);
        
        // Show proxy alert after a short delay to let the UI render first
        setTimeout(() => {
          Modal.confirm({
            title: 'Proxy Contract Detected',
            content: (
              <div>
                <Paragraph>
                  This appears to be a proxy contract pointing to implementation at:
                </Paragraph>
                <Paragraph strong>
                  {implementationAddress}
                  {proxyImplementationName && ` (${proxyImplementationName})`}
                </Paragraph>
                <Paragraph>
                  Would you like to view this contract using the implementation's ABI?
                </Paragraph>
              </div>
            ),
            okText: 'Use Implementation ABI',
            cancelText: 'Continue with Current ABI',
            onOk: async () => {
              try {
                // Fetch implementation ABI
                const implAbi = await fetchAbi(implementationAddress, chainId);
                if (!implAbi || !Array.isArray(implAbi)) {
                  throw new Error('Failed to load implementation ABI');
                }
                
                // Get read functions from implementation ABI
                const readFunctions = implAbi.filter(item => 
                  item.type === 'function' && 
                  (item.stateMutability === 'view' || item.stateMutability === 'pure') &&
                  (!item.inputs || item.inputs.length === 0)
                );
                
                // Call each read function to get state FROM THE PROXY ADDRESS
                const stateData = [];
                for (const func of readFunctions) {
                  try {
                    // IMPORTANT: Use the PROXY address (address), not the implementation address
                    const result = await publicClient.readContract({
                      address, // Use the proxy address here!
                      abi: [func],
                      functionName: func.name
                    });
                    
                    stateData.push({
                      name: func.name,
                      value: result,
                      type: func.outputs?.[0]?.type || 'unknown'
                    });
                  } catch (e) {
                    console.log(`Error calling ${func.name}:`, e);
                    // Skip functions that fail
                  }
                }
                
                setContractState(stateData);
                setIsUsingProxy(true);
                setLoading(false);
                
                if (stateData.length === 0) {
                  message.info('No readable state found with the implementation ABI');
                } else {
                  message.success('Contract state loaded using implementation ABI');
                }
              } catch (err: any) {
                setLoading(false);
                setError('Error using implementation ABI: ' + (err.message || 'Unknown error'));
              }
            }
          });
        }, 500);
      }
      
      // If no proxy detected or user chose not to use it, continue with normal loading
      loadContractStateWithAbi(abi);
      
    } catch (err: any) {
      console.error('Error loading related contract:', err);
      setError(err.message || 'Failed to load related contract data');
      setLoading(false);
    }
  }, [address, chainId, fetchAbi, publicClient]);
  
  // Load contract state with a specific ABI
  const loadContractStateWithAbi = async (abi: any[]) => {
    try {
      // Try to find contract name
      const contractNameEntry = abi.find(entry => 
        entry.name === 'name' && 
        entry.type === 'function' && 
        entry.inputs?.length === 0 &&
        entry.outputs?.length === 1 &&
        entry.outputs[0].type === 'string'
      );
      
      if (contractNameEntry) {
        try {
          const name = await publicClient.readContract({
            address,
            abi: [contractNameEntry],
            functionName: 'name'
          });
          setContractName(name);
        } catch (e) {
          console.log('Could not get contract name:', e);
        }
      }
      
      // Get read functions from ABI (both with and without inputs)
      const readFunctions = abi.filter(item => 
        item.type === 'function' && 
        (item.stateMutability === 'view' || item.stateMutability === 'pure')
      );
      
      // Separate functions with no inputs (for state display)
      const stateReadFunctions = readFunctions.filter(func => 
        !func.inputs || func.inputs.length === 0
      );
      
      // Functions with inputs (for function calls tab)
      const callableFunctions = readFunctions.filter(func => 
        func.inputs && func.inputs.length > 0
      );
      
      setContractFunctions(callableFunctions);
      
      // Call each no-input read function to get state
      const stateData = [];
      for (const func of stateReadFunctions) {
        try {
          const result = await publicClient.readContract({
            address,
            abi: [func],
            functionName: func.name
          });
          
          stateData.push({
            name: func.name,
            value: result,
            type: func.outputs?.[0]?.type || 'unknown'
          });
        } catch (e) {
          console.log(`Error calling ${func.name}:`, e);
          // Skip functions that fail
        }
      }
      
      setContractState(stateData);
    } catch (err: any) {
      console.error('Error loading contract state with ABI:', err);
      setError(err.message || 'Failed to load contract state');
    } finally {
      setLoading(false);
    }
  };
  
  // Function to handle viewing array data
  const handleViewArray = (arrayData: any[], name: string) => {
    setCurrentArrayData(arrayData);
    setCurrentArrayName(name);
    setIsArrayModalVisible(true);
  };
  
  useEffect(() => {
    loadRelatedContractState();
  }, [loadRelatedContractState]);
  
  // Add this to the component to ensure proxy state is reset when exploring a new address
  useEffect(() => {
    // Reset proxy state when the address changes
    setIsUsingProxy(false);
    setProxyImplementationAddress('');
    setProxyImplementationName('');
    
    // Reset the current ABI source if it was a proxy
    if (currentAbiSourceRef.current === 'proxy') {
      currentAbiSourceRef.current = '';
    }
  }, [address]);
  
  // Render function input fields
  const renderFunctionInputs = (func: any) => {
    const inputs = functionInputs[func.name] || {};
    
    return (
      <Form layout="vertical">
        {func.inputs.map((input: any, index: number) => (
          <Form.Item 
            key={`${func.name}-${input.name || index}`}
            label={`${input.name || `param${index}`} (${input.type})`}
          >
            <Input
              value={inputs[input.name || `param${index}`] || ''}
              onChange={(e) => handleFunctionInputChange(
                func.name, 
                input.name || `param${index}`, 
                e.target.value
              )}
              placeholder={`Enter ${input.type} value`}
            />
          </Form.Item>
        ))}
        <Form.Item>
          <Space>
            <Button 
              type="primary" 
              onClick={() => callContractFunction(func.name, func)}
              loading={functionLoading[func.name]}
              icon={<PlayCircleOutlined />}
            >
              Call Function
            </Button>
            <Button 
              onClick={() => resetFunctionCall(func.name)}
              disabled={functionLoading[func.name]}
            >
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };
  
  // Render function result
  const renderFunctionResult = (func: any) => {
    const result = functionResults[func.name];
    const error = functionErrors[func.name];
    
    if (error) {
      return <Alert type="error" message={error} />;
    }
    
    if (result === undefined) {
      return null;
    }
    
    return (
      <div>
        <Divider orientation="left">Result</Divider>
        <RenderStateValue 
          value={result} 
          name={func.name} 
          onExploreAddress={onExploreAddress}
          onViewArray={handleViewArray}
        />
      </div>
    );
  };
  
  return (
    <div style={{ padding: '0 12px' }}>
      <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Space direction="vertical" size={0}>
          <Space align="center">
            <Text strong style={{ fontSize: '16px' }}>
              {contractName ? contractName : 'Contract'}
            </Text>
            <Tag color="blue">
              {shortenAddress(address)}
              <Button 
                type="text" 
                size="small" 
                icon={<CopyOutlined />} 
                onClick={() => {
                  copy(address);
                  message.success('Address copied');
                }}
                style={{ marginLeft: 4 }}
              />
            </Tag>
            {isUsingProxy && (
              <Tag color="purple">
                <Space size={4}>
                  <SwapOutlined />
                  <span>Proxy Implementation</span>
                  {proxyImplementationAddress && (
                    <Button
                      type="text"
                      size="small"
                      icon={<ApartmentOutlined />}
                      onClick={() => onExploreAddress(proxyImplementationAddress, 'Implementation')}
                      style={{ marginLeft: 4 }}
                    />
                  )}
                </Space>
              </Tag>
            )}
          </Space>
          {parentContractName && (
            <Text type="secondary" style={{ fontSize: '12px' }}>
              Referenced by {parentContractName}
            </Text>
          )}
        </Space>
        <Space>
          <Button
            icon={<ApiOutlined />}
            onClick={() => showAbiModal(false)}
            size="small"
            title="Set ABI Manually"
          />
          {getExplorerAddressLink(chainId, address) && (
            <Button 
              type="link" 
              icon={<LinkOutlined />} 
              onClick={() => window.open(getExplorerAddressLink(chainId, address)!, '_blank')}
            >
              {getExplorerName(chainId)}
            </Button>
          )}
          <Button 
            type="primary" 
            onClick={() => loadRelatedContractState()} 
            icon={<ReloadOutlined />}
            size="small"
          >
            Refresh
          </Button>
        </Space>
      </div>
      
      {loading ? (
        <div style={{ textAlign: 'center', padding: '40px' }}>
          <Spin tip="Loading contract state..." />
        </div>
      ) : error ? (
        <Alert 
          type="warning" 
          message={error}
          description={
            <Space direction="vertical" style={{ marginTop: 8 }}>
              <Text>This might be a proxy contract. Try one of these options:</Text>
              <Space>
                <Button size="small" type="primary" onClick={showProxyModal}>
                  Use Proxy Implementation
                </Button>
                <Button size="small" onClick={() => showAbiModal(false)}>
                  Provide ABI Manually
                </Button>
              </Space>
            </Space>
          }
        />
      ) : (
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <TabPane tab="State" key="state">
            {contractState.length === 0 ? (
              <Empty 
                description={
                  <div>
                    <p>No readable state found for this contract</p>
                    <Space>
                      <Button type="primary" size="small" onClick={showProxyModal}>
                        Try as Proxy Contract
                      </Button>
                      <Button size="small" onClick={() => showAbiModal(false)}>
                        Try with Custom ABI
                      </Button>
                    </Space>
                  </div>
                } 
              />
            ) : (
              <Table
                dataSource={contractState}
                columns={[
                  {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    width: '40%',
                    render: (name) => (
                      <Text strong style={{ wordBreak: 'break-word' }}>
                        {name}
                      </Text>
                    )
                  },
                  {
                    title: 'Value',
                    dataIndex: 'value',
                    key: 'value',
                    render: (value, record) => (
                      <RenderStateValue 
                        value={value} 
                        name={record.name} 
                        onExploreAddress={onExploreAddress}
                        onViewArray={handleViewArray}
                      />
                    )
                  }
                ]}
                pagination={false}
                size="small"
                rowKey="name"
              />
            )}
          </TabPane>
          <TabPane tab="Functions" key="functions">
            {contractFunctions.length === 0 ? (
              <Empty description="No callable read functions found in this contract" />
            ) : (
              <Collapse>
                {contractFunctions.map((func) => (
                  <Panel 
                    header={
                      <Space>
                        <Text strong>{func.name}</Text>
                        <Text type="secondary">
                          ({func.inputs.map((i: any) => i.type).join(', ')})
                          {func.outputs && func.outputs.length > 0 ? 
                            ` → (${func.outputs.map((o: any) => o.type).join(', ')})` : 
                            ' → ()'}
                        </Text>
                      </Space>
                    } 
                    key={func.name}
                  >
                    {renderFunctionInputs(func)}
                    {renderFunctionResult(func)}
                  </Panel>
                ))}
              </Collapse>
            )}
          </TabPane>
        </Tabs>
      )}
      
      {/* Array Data Modal */}
      <ArrayValueModal
        isOpen={isArrayModalVisible}
        onClose={() => setIsArrayModalVisible(false)}
        arrayData={currentArrayData}
        title={currentArrayName}
        onExploreAddress={onExploreAddress}
        chainId={chainId}
      />
      
      {/* Manual ABI Modal with enhancements */}
      <Modal
        title={abiModalTitle}
        open={isAbiModalVisible}
        onCancel={() => setIsAbiModalVisible(false)}
        onOk={handleManualAbiSubmit}
        okText={abiModalOkText}
        width={700}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Space>
            <Text>ABI Source:</Text>
            {currentAbiSourceRef.current === 'manual' && <Tag color="blue">Manually Entered</Tag>}
            {currentAbiSourceRef.current === 'fetched' && <Tag color="green">Fetched from Explorer</Tag>}
            {currentAbiSourceRef.current === 'proxy' && <Tag color="purple">Proxy Implementation</Tag>}
            {currentAbiSourceRef.current === 'custom' && <Tag color="orange">Custom</Tag>}
            {!currentAbiSourceRef.current && <Tag color="default">None</Tag>}
          </Space>
          
          <Paragraph>
            {isAbiModalForProxy 
              ? "Paste the implementation ABI JSON for this proxy contract:" 
              : "Paste the ABI JSON for this contract:"}
          </Paragraph>
          <TextArea
            rows={10}
            value={manualAbi}
            onChange={(e) => setManualAbi(e.target.value)}
            placeholder="[{...ABI JSON array...}]"
          />
          
          {abiModalError && (
            <Alert 
              message={abiModalError} 
              type="error" 
              style={{ marginTop: 16 }} 
            />
          )}
          <Paragraph style={{ marginTop: '16px' }} type="secondary">
            You can find ABIs on {getExplorerName(chainId)}, contract verification sites, or from the contract developers.
          </Paragraph>
          {isAbiModalForProxy ? (
            <Alert
              message="Proxy Implementation ABI"
              description="You are providing an implementation ABI for a proxy contract. This will allow you to interact with the proxy using the implementation's interface."
              type="info"
              showIcon
              style={{ marginTop: 8 }}
            />
          ) : (
            <Paragraph type="secondary">
              <strong>Note:</strong> For proxy contracts, use the "Use Proxy Implementation" button instead of manual ABI entry.
            </Paragraph>
          )}
        </Space>
      </Modal>
      
      {/* Proxy Implementation Modal */}
      <Modal
        title="Use Proxy Implementation"
        open={isProxyModalVisible}
        onCancel={() => setIsProxyModalVisible(false)}
        onOk={handleProxyImplementationSubmit}
        okText="Load Implementation ABI"
        width={700}
        footer={[
          <Button key="cancel" onClick={() => setIsProxyModalVisible(false)}>
            Cancel
          </Button>,
          <Button 
            key="manual" 
            type="default" 
            onClick={() => {
              setIsProxyModalVisible(false);
              showAbiModal(true);
            }}
          >
            Provide Implementation ABI Manually
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            onClick={handleProxyImplementationSubmit}
            disabled={!proxyImplementationAddress || !/^0x[a-fA-F0-9]{40}$/.test(proxyImplementationAddress)}
          >
            Load Implementation ABI
          </Button>
        ]}
      >
        <Paragraph>
          Enter the address of the implementation contract:
        </Paragraph>
        <Input
          value={proxyImplementationAddress}
          onChange={(e) => setProxyImplementationAddress(e.target.value)}
          placeholder="0x..."
          style={{ marginBottom: 16 }}
        />
        {proxyModalError && (
          <Alert 
            message={proxyModalError} 
            type="error" 
            style={{ marginBottom: 16 }} 
          />
        )}
        <Paragraph type="secondary">
          This will load the ABI from the implementation contract but use it to read state from the current contract address.
        </Paragraph>
        <Paragraph type="secondary" style={{ marginTop: 8 }}>
          If you already have the implementation ABI, you can click "Provide ABI Manually" instead.
        </Paragraph>
        {proxyImplementationAddress && (
          <div style={{ marginTop: 16 }}>
            <Divider orientation="left">Quick Actions</Divider>
            <Space>
              {getExplorerAddressLink(chainId, proxyImplementationAddress) && (
                <Button 
                  icon={<LinkOutlined />} 
                  onClick={() => window.open(getExplorerAddressLink(chainId, proxyImplementationAddress)!, '_blank')}
                >
                  View on {getExplorerName(chainId)}
                </Button>
              )}
              <Button 
                icon={<ApartmentOutlined />} 
                onClick={() => {
                  setIsProxyModalVisible(false);
                  onExploreAddress(proxyImplementationAddress, 'Implementation');
                }}
              >
                Explore Implementation
              </Button>
            </Space>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RelatedContractExplorer; 
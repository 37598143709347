import { List, Space, Avatar, Tag, Collapse, Statistic, Row, Col, Typography, Tooltip, Button, Skeleton, Divider } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { shortenAddress, getTooltipTitleForParamType, getTagColorByParamType } from '../../../utils/formatters';
import { EditButton } from '@refinedev/antd';
import { useState } from 'react';
import { extractAddressesFromParams } from '../../../utils/extractAddresses';
const { Title, Text, Link } = Typography;

interface EventsListProps {
    decodedTransactionData: any;
    record: any;
    displayCount: number;
    setDisplayCount: (count: number) => void;
    showEvent: (event: any) => void;
    cdn_domain_name: string;
}

export const EventsList: React.FC<EventsListProps> = ({
    decodedTransactionData,
    record,
    displayCount,
    setDisplayCount,
    showEvent,
    cdn_domain_name,
}) => {
    
    // Calculate the container height based on number of events
    const eventCount = decodedTransactionData?.decoded_events ? 
        Object.keys(decodedTransactionData.decoded_events).length : 0;
    
    // Each event item is roughly 100px tall, add some padding
    // Min height of 120px for single event, max height of 400px
    const containerHeight = Math.min(
        Math.max(eventCount * 100 + 20, 120), 
        400
    );

    // Helper function to get known addresses
    const getKnownAddresses = (addresses: Set<string>) => {
        return Array.from(addresses).filter(address => {
            if (record?.is_search_transaction) {
                return (
                    (address === record?.from_address?.toLowerCase() && record?.from_address_entity) ||
                    (address === record?.to_address?.toLowerCase() && record?.to_address_entity) ||
                    record?.address_items_map?.[address]?.labels?.length > 0
                );
            }
            return record?.address_items_map?.[address]?.labels?.length > 0;
        });
    };

    if (!decodedTransactionData?.decoded_events || 
        decodedTransactionData.decoded_events.length === 0) {
        return (
            <>
                <Title level={5}>Events:</Title>
                <div style={{ 
                    padding: '24px',
                    textAlign: 'center',
                    background: '#fafafa',
                    border: '1px solid #f0f0f0',
                    borderRadius: '4px'
                }}>
                    <Text type="secondary">No events found</Text>
                </div>
                <Divider></Divider>
            </>
        );
    }

    return (
            decodedTransactionData?.decoded_events ?
            <>
                <Title level={5} style={{ marginBottom: 16 }}>Events:</Title>
                <div 
                    id="eventsScrollContainer"
                    style={{ 
                        height: `${containerHeight}px`,
                        overflow: 'auto',
                        border: '1px solid #f0f0f0',
                        borderRadius: '4px',
                        padding: '0 16px'
                    }}
                >
                <InfiniteScroll
                    dataLength={displayCount}
                    next={function() { setDisplayCount(displayCount + 10) }}
                    hasMore={decodedTransactionData?.decoded_events ? 
                        displayCount < Object.values(decodedTransactionData.decoded_events).length : 
                        false
                    }
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                    scrollableTarget="eventsScrollContainer"
                    style={{ overflow: 'hidden' }}
                >
                    <List
                    dataSource={decodedTransactionData?.decoded_events ? 
                        Object.values(decodedTransactionData.decoded_events)?.slice(0, displayCount) : 
                        []
                    }
                    renderItem={(item:any, index) => (
                        <List.Item>
                        <List.Item.Meta
                            avatar={
                                <div style={{ width: 80, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  {/* Added center justification */}
                                    <Space size={-6}>
                                    {(() => {
                                        // Create a Set of unique addresses from all sources
                                        const uniqueAddresses = new Set([
                                            item.address?.toLowerCase(),
                                            item.decoded_event?.address?.toLowerCase(),
                                            ...(extractAddressesFromParams(item.decoded_event?.params) || [])
                                        ].filter(Boolean));

                                        // Filter for addresses that have labels/entities
                                        const knownAddresses = getKnownAddresses(uniqueAddresses);

                                        // Calculate number of unknown addresses
                                        const unknownCount = uniqueAddresses.size - knownAddresses.length;

                                        // Only show avatars if we have known addresses or unknown ones to count
                                        return (knownAddresses.length > 0 || unknownCount > 0) && (
                                            <Space size={-6}>
                                                {/* Show maximum 3 avatars */}
                                                {knownAddresses?.slice(0, 3)?.map((address, index) => ( 
                                                    <Tooltip title={
                                                        record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity
                                                            ? `${record?.from_address_entity} (${address?.slice(0, 6)}...${address?.slice(-6)})`
                                                            : record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity
                                                                ? `${record?.to_address_entity} (${address?.slice(0, 6)}...${address?.slice(-6)})`
                                                                : record?.address_items_map?.[address as string]?.labels?.[0]?.name
                                                                ? `${record?.address_items_map?.[address as string]?.labels?.[0]?.name} (${(address as string)?.slice(0, 6)}...${(address as string)?.slice(-6)})`
                                                                    : "unknown address label"
                                                    }>
                                                        <Avatar 
                                                            src={
                                                                record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity_logo
                                                                    ? record?.from_address_entity_logo
                                                                    : record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity_logo
                                                                        ? record?.to_address_entity_logo
                                                                        : record?.address_items_map?.[address as string]?.labels?.[0]?.logo || record?.address_items_map?.[address as string]?.labels?.[0]?.logo || `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`
                                                            }
                                                            style={{ 
                                                                border: '1px solid #f0f0f0',
                                                                marginLeft: index === 0 ? 0 : '-8px',
                                                                zIndex: 3 - index
                                                            }}
                                                        />
                                                    </Tooltip>
                                                ))}

                                                {/* Show +X more if there are additional known addresses beyond 3 or any unknown addresses */}
                                                {(knownAddresses.length > 3 || unknownCount > 0) && (
                                                    <Tooltip title={(() => {
                                                        const tooltipParts = [];
                                                        
                                                        // Add remaining known addresses to tooltip
                                                        if (knownAddresses?.length > 3) {
                                                            const remainingKnown = knownAddresses?.slice(3)?.map(address => {
                                                                if (record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity) {
                                                                    return `${record?.from_address_entity} (${address?.slice(0, 6)}...${address?.slice(-6)})`;
                                                                } else if (record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity) {
                                                                    return `${record?.to_address_entity} (${address?.slice(0, 6)}...${address?.slice(-6)})`;
                                                                } else {
                                                                    const entityLabel = record?.address_items_map?.[address as string]?.labels?.[0]?.name;
                                                                    return entityLabel ? `${entityLabel} (${address as string}.slice(0, 6)}...${address as string}.slice(-6)})` : null;
                                                                }
                                                            }).filter(Boolean);
                                                            if (remainingKnown.length > 0) {
                                                                tooltipParts.push(remainingKnown.join('\n'));
                                                            }
                                                        }

                                                        // Add unknown addresses count to tooltip
                                                        if (unknownCount > 0) {
                                                            tooltipParts.push(`${unknownCount} more unknown address${unknownCount > 1 ? 'es' : ''}`);
                                                        }

                                                        return tooltipParts.join('\n');
                                                    })()}>
                                                        <Avatar 
                                                            size="small"
                                                            style={{ 
                                                                marginLeft: '-8px',
                                                                background: '#1890ff',
                                                                color: 'white',
                                                                fontWeight: 'bold',
                                                                zIndex: 0
                                                            }}
                                                        >
                                                            +{(knownAddresses?.length > 3 ? knownAddresses?.length - 3 : 0) + unknownCount}
                                                        </Avatar>
                                                    </Tooltip>
                                                )}
                                            </Space>
                                        );
                                    })()}
                                </Space>
                                </div>
                            }
                            title={<Tooltip title={item.decoded_event?.signature}><a onClick={() => showEvent(item)}>{item.decoded_event ? item.decoded_event.label || item.decoded_event.name : item.label}</a></Tooltip>}
                            description={<Button onClick={(e) => showEvent(item)} size="small">Show decoded event info</Button>}
                        />
                        
                        <Space>
                            {item?.decoded_event?.params?.slice(0, 3)?.map((param: any) => (
                                <Tooltip title={getTooltipTitleForParamType(record?.address_items_map, param)}>
                                    <Tag color={getTagColorByParamType(param.type)}>
                                        {param.name}
                                    </Tag>
                                </Tooltip>
                            ))}
                                {item?.decoded_event?.params?.length > 3 && (
                                <Tooltip title="Click 'Show decoded event info' to see all parameters">
                                    <Tag style={{cursor: "pointer"}} onClick={() => showEvent(item)} color="grey">+{item?.decoded_event?.params?.length - 3} more</Tag>
                                </Tooltip>
                            )}
                            <Tooltip title={
                                record?.is_search_transaction && item.decoded_event?.address === record?.from_address && record?.from_address_entity
? `${record?.from_address_entity} (${item.address})`
: record?.is_search_transaction && item.address === record?.to_address && record?.to_address_entity
    ? `${record?.to_address_entity} (${item.address})`
    : record?.address_items_map?.[item.address]?.labels?.[0]?.address_name
        ? `${record?.address_items_map?.[item.address]?.labels?.[0]?.address_name} (${item.address})`
        : record?.address_items_map?.[item.address]?.main_labels?.chain_id?.name 
            ? `${record?.address_items_map?.[item.address]?.main_labels?.chain_id?.name} (${item.address})`
            : record?.address_items_map?.[item.address]?.labels?.[0]?.name
                ? `${record?.address_items_map?.[item.address]?.labels?.[0]?.name} (${item.address})`
                : `unknown address label (${item.address?.slice(0, 6)}...${item.address?.slice(-6)})`
}>
<Button onClick={(e) => showEvent(item)}
size="small"
>{item?.address ? `${item?.address?.slice(0, 6)}...${item?.address?.slice(-6)}` : ""}</Button>
</Tooltip>
                            <EditButton
                                size="small"
                                hideText={true}
                                recordItemId={record?.["user_id-block_timestamp-chain_id-hash-type"]}
                                resource="transactions-share-with"
                                meta={{"remove_user_email": item.email}}
                            />
                        </Space>
                        </List.Item>
                    )}
                    />
                </InfiniteScroll> 
                </div>
        </>:
        ""
    );
};
import { useState } from 'react';
import { Alert, Row, Col, Card, Skeleton, Avatar, Tooltip, Space, Tag, Typography, Divider, Pagination } from 'antd';
import { 
    ExpandAltOutlined, EditOutlined, TagsOutlined, 
    TeamOutlined, GroupOutlined, LinkOutlined, ApiOutlined 
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Meta from 'antd/es/card/Meta';
import { ITransaction, IntegrationResult } from 'interfaces';  // Import the transaction interface

const { Text, Paragraph } = Typography;
const PAGE_SIZE = 6; // Show 6 integrations per page

interface IntegrationResultsProps {
    integrationResults?: IntegrationResult[];
    transactionResults?: ITransaction;
    onShowDetails: (integrationId: string) => void;
    loading?: boolean;
}

export const IntegrationResults: React.FC<IntegrationResultsProps> = ({
    integrationResults = [],
    transactionResults,
    onShowDetails,
    loading
}) => {
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate pagination
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const paginatedResults = integrationResults.slice(startIndex, endIndex);
    const total = integrationResults.length;

    const getMergedData = (integration: IntegrationResult) => {
        // Get the integration config data
        const integrationConfig = transactionResults?.integrations?.find(
            config => config.id === integration.integration_id
        );
        
        // Get the integration results data
        const additionalData = transactionResults?.[integration.integration_id]?.ITEM_ATTACH_TRANSACTIONS_CONFIG;

        return {
            tags: [
                ...(integration.tags || []),
                ...(integration.main_tags || []),
                ...(additionalData?.tags || []),
                ...(integration.global_tags || []),
                ...(additionalData?.global_tags || [])
            ].filter((tag, index, self) => self.indexOf(tag) === index),

            fromLabels: [
                ...(integration.from_address_labels || []),
                ...(integration.main_from_address_labels || []),
                ...(additionalData?.from_address_labels || []),
                ...(integration.global_from_address_labels || []),
                ...(additionalData?.global_from_address_labels || [])
            ].filter((label, index, self) => self.indexOf(label) === index),

            toLabels: [
                ...(integration.to_address_labels || []),
                ...(integration.main_to_address_labels || []),
                ...(additionalData?.to_address_labels || []),
                ...(integration.global_to_address_labels || []),
                ...(additionalData?.global_to_address_labels || [])
            ].filter((label, index, self) => self.indexOf(label) === index),

            groups: [
                ...(integration.groups || []),
                ...(integration.main_groups || []),
                ...(additionalData?.group_names || [])
            ].filter((group, index, self) => self.indexOf(group) === index),

            // Add stages information
            stages: integrationConfig?.stages || []
        };
    };

    const renderTags = (tags: string[] | null | undefined, color: string = 'blue') => {
        if (!tags || tags.length === 0) return null;
        return (
            <Space wrap size={[0, 8]}>
                {tags.map((tag, index) => (
                    <Tag color={color} key={index}>{tag}</Tag>
                ))}
            </Space>
        );
    };

    const getItemCount = (integration: IntegrationResult) => {
        const additionalData = transactionResults?.[integration.integration_id]?.ITEM_ATTACH_TRANSACTIONS_CONFIG;
        
        return (
            (integration.tags?.length || 0) +
            (integration.from_address_labels?.length || 0) +
            (integration.to_address_labels?.length || 0) +
            (integration.groups?.length || 0) +
            (additionalData?.tags?.length || 0) +
            (additionalData?.from_address_labels?.length || 0) +
            (additionalData?.to_address_labels?.length || 0) +
            (additionalData?.group_names?.length || 0)
        );
    };

    if (!integrationResults || integrationResults.length === 0) {
        return (
            <Alert
                message="No Integration Results"
                description={
                    <>
                        This transaction hasn't been processed by any integrations yet. Integrations can:
                        <ul style={{ marginTop: '8px', marginBottom: 0 }}>
                            <li>Add tags, labels, and group assignments automatically</li>
                            <li>Decode input and output data of the transaction</li>
                            <li>Add token flows that are displayed in the transaction graph</li>
                        </ul>
                    </>
                }
                type="info"
                showIcon
                style={{ marginTop: 16 }}
            />
        );
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                {loading ? (
                    // Loading skeletons
                    [...Array(PAGE_SIZE)].map((_, index) => (
                        <Col xs={24} sm={24} md={12} lg={8} key={`skeleton-${index}`}>
                            <Card>
                                <Skeleton loading={true} avatar active />
                            </Card>
                        </Col>
                    ))
                ) : (
                    paginatedResults.map((integration) => {
                        const mergedData = getMergedData(integration);
                        const itemCount = getItemCount(integration);
                        
                        return (
                            <Col xs={24} sm={24} md={12} lg={8} key={integration.integration_id}>
                                <Card
                                    hoverable
                                    onClick={() => onShowDetails(integration.integration_id)}
                                    style={{ 
                                        cursor: 'pointer',
                                        height: '100%' 
                                    }}
                                    actions={[
                                        <Tooltip title="Edit Integration">
                                            <Link 
                                                to={`https://dashboard.${process.env.REACT_APP_API_BASE_URL}/integrations/show/${integration.integration_id}`} 
                                                target="_blank"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <EditOutlined />
                                            </Link>
                                        </Tooltip>
                                    ]}
                                >
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <Space align="start">
                                            <Avatar 
                                                size={64}
                                                src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/integrations/logos/png/${integration.integration_id}.png`}
                                            />
                                            <div>
                                                <Text strong style={{ 
                                                    fontSize: '16px',
                                                    display: 'block'
                                                }}>
                                                    {integration.integration_name}
                                                </Text>
                                                <Tag color={itemCount > 0 ? "processing" : "default"}>
                                                    {itemCount} {itemCount === 1 ? 'modification' : 'modifications'}
                                                </Tag>
                                            </div>
                                        </Space>
                                    </Space>
                                </Card>
                            </Col>
                        );
                    })
                )}
            </Row>
            
            {total > PAGE_SIZE && (
                <Row justify="end" style={{ marginTop: 16 }}>
                    <Pagination
                        current={currentPage}
                        total={total}
                        pageSize={PAGE_SIZE}
                        onChange={setCurrentPage}
                        showSizeChanger={false}
                        showTotal={(total) => `Total ${total} integration${total !== 1 ? 's' : ''}`}
                    />
                </Row>
            )}
        </>
    );
};
import React from 'react';
import { Tag, Button, Checkbox, Divider, Space, TableColumnType, Typography } from 'antd';
import type { ColumnType, FilterDropdownProps } from 'antd/es/table/interface';
import type { Transaction } from '../types';

interface GetMethodColumnProps {
    transactions: Transaction[];
}

export const getMethodColumn = ({
    transactions,
}: GetMethodColumnProps): TableColumnType<Transaction> => {
    const uniqueMethods = Array.from(new Set(transactions
        ?.map(item => item.method_label)
        .filter(Boolean)));

    const filterOptions = uniqueMethods.map(method => ({ 
        text: method, 
        value: method 
    }));

    return {
        title: 'Method',
        dataIndex: 'method_label',
        key: 'method_label',
        width: 200,
        filters: filterOptions.map(option => ({
            text: option.text,
            value: option.value as React.Key
        })),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
            const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                    setSelectedKeys(filterOptions.map(option => option.value as React.Key).filter(Boolean));
                } else {
                    setSelectedKeys([]);
                }
            };

            return (
                <div style={{ padding: 8 }}>
                    <Checkbox
                        indeterminate={selectedKeys.length > 0 && selectedKeys.length < filterOptions.length}
                        checked={selectedKeys.length === filterOptions.length}
                        onChange={(e) => handleSelectAll(e as unknown as React.ChangeEvent<HTMLInputElement>)}
                        style={{ marginBottom: 8 }}
                    >
                        Select All
                    </Checkbox>
                    <Divider style={{ margin: '8px 0' }} />
                    {filterOptions.map((option, index) => (
                        <div key={`${option.value}-${index}`} style={{ marginBottom: 8 }}>
                            <Checkbox
                                checked={selectedKeys.includes(option.value as React.Key)}
                                onChange={(e) => {
                                    const newSelectedKeys = e.target.checked
                                        ? [...selectedKeys, option.value].filter((key): key is React.Key => key !== undefined)
                                        : selectedKeys.filter(key => key !== option.value);
                                    setSelectedKeys(newSelectedKeys);
                                }}
                            >
                                <Tag color="gold">{option.text}</Tag>
                            </Checkbox>
                        </div>
                    ))}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            size="small"
                        >
                            Apply
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters?.();
                                confirm();
                            }}
                            size="small"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            );
        },
        onFilter: (value, record) => record.method_label === value,
        sorter: (a, b) => (a.method_label || '').localeCompare(b.method_label || ''),
        render: (method_label: string, record: any) => {
            // Get the method label from either method_label or decoded_call
            const methodText = method_label || record.decoded_call?.label || 'Unknown';
            
            return methodText ? (
                <Typography.Text style={{ whiteSpace: 'nowrap', minWidth: 200, display: 'inline-block' }}>
                    <Tag color="gold">
                        {methodText}
                    </Tag>
                </Typography.Text>
            ) : (
                <Typography.Text style={{ whiteSpace: 'nowrap', minWidth: 200, display: 'inline-block' }}></Typography.Text>
            );
        }
    };
};
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { 
  Card, 
  Tabs, 
  Space, 
  Typography, 
  Timeline, 
  Tag, 
  Empty, 
  Spin, 
  Alert, 
  Descriptions, 
  Collapse, 
  Tooltip, 
  Button,
  Badge,
  Divider,
  List,
  Avatar,
  Switch
} from 'antd';
import { 
  GlobalOutlined, 
  HistoryOutlined, 
  PlayCircleOutlined, 
  LinkOutlined,
  SwapOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
  LockOutlined,
  UnlockOutlined,
  WarningOutlined,
  InfoCircleOutlined,
  CompressOutlined
} from '@ant-design/icons';
import ContractRelationshipAnalyzer from './ContractRelationshipAnalyzer';
import ContractCrossChainComparison from './ContractCrossChainComparison';
import ContractInsights from './ContractInsights';
import { renderAddressAvatar } from "../../components/address-avatar";

const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;

// Define types for the contract state data
interface AddressInfo {
  address: string;
  isContract: boolean;
  contractDetails?: any;
  labels?: string[];
  tags?: string[];
  entities?: string[];
}

interface StateValue {
  [key: string]: any;
}

interface BlockState {
  blockNumber: number | string;
  state: StateValue;
}

interface ContractState {
  before?: BlockState[];
  after?: BlockState[];
  latest?: BlockState[];
}

interface KeyStateVariable {
  key: string;
  params?: string[];
  [key: string]: any;
}

interface ContractData {
  address: string;
  chainId: string;
  name: string;
  keyStateVariables: (string | KeyStateVariable)[];
  states: ContractState;
}

interface ContractsData {
  [key: string]: ContractData;
}

// Add type exports
export interface AddressItemsMap {
  [address: string]: {
    labels?: Array<{
      id: string;
      name: string;
      address_name?: string;
      color: string;
      source: string;
      logo?: string;
      chain_ids?: string[];
      main_label_chain_ids?: string[];
      verified?: boolean;
    }>;
    tags?: any[];
    groups?: any[];
    entities?: Array<{
      id: string;
      name: string;
      color: string;
      chain_ids?: string[];
      main_chain_ids?: string[];
      logo?: string;
    }>;
    is_contract?: boolean;
    verified?: boolean;
  };
}

export interface ContractStateData {
  contracts: {
    [key: string]: {
      address: string;
      chainId: string;
      name?: string;
      states: {
        before?: Array<{ blockNumber: number | string; state: any }>;
        after?: Array<{ blockNumber: number | string; state: any }>;
        latest?: Array<{ blockNumber: number | string; state: any }>;
      };
      keyStateVariables?: Array<string | KeyStateVariable>;
    };
  };
  address_items_map?: AddressItemsMap;
}

export interface ContractStateHighlighterProps {
  contractAddress: string;
  chainId: string;
  transactionHash: string;
  transactionBlockNumber?: number;
  contractStateData?: ContractStateData;
}

const ContractStateHighlighter: React.FC<ContractStateHighlighterProps> = ({
  contractAddress,
  chainId,
  transactionHash,
  transactionBlockNumber,
  contractStateData
}) => {
  const [activeTab, setActiveTab] = useState('1');
  
  // Safely access contractStateData to prevent errors
  const safeContractStateData = useMemo(() => {
    return contractStateData || { contracts: {}, address_items_map: {} };
  }, [contractStateData]);
  
  // Extract contracts from data
  const contracts = useMemo(() => {
    if (!safeContractStateData.contracts) return [];
    
    return Object.values(safeContractStateData.contracts);
  }, [safeContractStateData.contracts]);
  
  // Get chain name from chain ID
  const getChainName = useCallback((chainId: string) => {
    const chainNames: { [key: string]: string } = {
      '0x1': 'Ethereum',
      '0x38': 'BNB Chain',
      '0x89': 'Polygon',
      '0xa': 'Optimism',
      '0xa4b1': 'Arbitrum',
      '0xa86a': 'Avalanche',
      // Add more chains as needed
    };
    
    return chainNames[chainId] || `Chain ${chainId}`;
  }, []);
  
  // Format address for display
  const formatAddress = useCallback((address: string, addressItemsMap?: AddressItemsMap) => {
    if (!address) return 'Unknown';
    
    const shortAddress = `${address.slice(0, 6)}...${address.slice(-4)}`;
    
    // If we have address metadata, show it
    if (addressItemsMap && addressItemsMap[address.toLowerCase()]) {
      const metadata = addressItemsMap[address.toLowerCase()];
      
      // If we have a label, show it with the address
      if (metadata.labels && metadata.labels.length > 0) {
        return (
          <Space>
            <Text copyable={{ text: address }}>{shortAddress}</Text>
            <Tag color={`#${metadata.labels[0].color}`}>{metadata.labels[0].name}</Tag>
          </Space>
        );
      }
      
      // If we have an entity, show it with the address
      if (metadata.entities && metadata.entities.length > 0) {
        return (
          <Space>
            <Text copyable={{ text: address }}>{shortAddress}</Text>
            <Tag color={`#${metadata.entities[0].color}`}>{metadata.entities[0].name}</Tag>
          </Space>
        );
      }
    }
    
    // Default to just the short address with copy button
    return <Text copyable={{ text: address }}>{shortAddress}</Text>;
  }, []);
  
  // Render a value based on its type
  const renderValue = useCallback((value: any, depth: number = 0, addressItemsMap?: AddressItemsMap) => {
    if (value === null || value === undefined) {
      return <Text type="secondary">null</Text>;
    }
    
    if (typeof value === 'boolean') {
      return <Text>{value ? 'true' : 'false'}</Text>;
    }
    
    if (typeof value === 'number' || typeof value === 'bigint') {
      return <Text>{value.toString()}</Text>;
    }
    
    if (typeof value === 'string') {
      // Check if it's an address (0x followed by 40 hex chars)
      if (/^0x[a-fA-F0-9]{40}$/i.test(value)) {
        return formatAddress(value, addressItemsMap);
      }
      
      // Check if it's a hash (0x followed by 64 hex chars)
      if (/^0x[a-fA-F0-9]{64}$/i.test(value)) {
        return <Text copyable={{ text: value }}>{`${value.slice(0, 10)}...${value.slice(-8)}`}</Text>;
      }
      
      // Regular string
      return <Text copyable={value.length > 20 ? { text: value } : undefined}>{value}</Text>;
    }
    
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return <Text type="secondary">[]</Text>;
      }
      
      if (depth > 2) {
        return <Text type="secondary">[Array with {value.length} items]</Text>;
      }
      
      return (
        <div style={{ marginLeft: 20 }}>
          {value.map((item, index) => (
            <div key={index}>
              <Text type="secondary">[{index}]: </Text>
              {renderValue(item, depth + 1, addressItemsMap)}
            </div>
          ))}
        </div>
      );
    }
    
    if (typeof value === 'object') {
      // Special case for address objects
      if (value.address && typeof value.address === 'string') {
        const addressDisplay = formatAddress(value.address, addressItemsMap);
        
        // If it has contract details, show a summary
        if (value.isContract && value.contractDetails) {
          return (
            <Space direction="vertical" style={{ width: '100%' }}>
              <Space>
                {addressDisplay}
                {value.isContract && <Tag color="blue">Contract</Tag>}
              </Space>
              {Object.keys(value.contractDetails).length > 0 && (
                <Collapse ghost size="small">
                  <Panel header="Contract Details" key="1">
                    <div style={{ marginLeft: 20 }}>
                      {Object.entries(value.contractDetails)
                        .filter(([key]) => key !== '_proxy' && key !== '_implementation')
                        .map(([key, val]) => (
                          <div key={key}>
                            <Text type="secondary">{key}: </Text>
                            {renderValue(val, depth + 1, addressItemsMap)}
                          </div>
                        ))}
                    </div>
                  </Panel>
                </Collapse>
              )}
            </Space>
          );
        }
        
        return (
          <Space>
            {addressDisplay}
            {value.isContract && <Tag color="blue">Contract</Tag>}
          </Space>
        );
      }
      
      // Regular object
      const entries = Object.entries(value);
      if (entries.length === 0) {
        return <Text type="secondary">{'{}'}</Text>;
      }
      
      if (depth > 2) {
        return <Text type="secondary">{`{Object with ${entries.length} properties}`}</Text>;
      }
      
      return (
        <div style={{ marginLeft: 20 }}>
          {entries.map(([key, val]) => (
            <div key={key}>
              <Text type="secondary">{key}: </Text>
              {renderValue(val, depth + 1, addressItemsMap)}
            </div>
          ))}
        </div>
      );
    }
    
    // Fallback for any other type
    return <Text>{String(value)}</Text>;
  }, [formatAddress]);
  
  // Create a map of key variables for quick lookup - moved outside renderStateValues
  const createKeyVariableMap = useCallback((keyVariables?: (string | KeyStateVariable)[]) => {
    const map = new Map<string, KeyStateVariable>();
    
    if (keyVariables) {
      keyVariables.forEach(variable => {
        if (typeof variable === 'string') {
          map.set(variable, { key: variable });
        } else {
          map.set(variable.key, variable);
        }
      });
    }
    
    return map;
  }, []);
  
  // Render state values in a structured way
  const renderStateValues = useCallback((
    keys: string[], 
    state: any, 
    prevState?: any, 
    nextState?: any,
    keyVariables?: (string | KeyStateVariable)[],
    changedKeys?: Set<string>,
    addressItemsMap?: AddressItemsMap
  ) => {
    if (!state) return <Empty description="No state data available" />;
    
    // Use the helper function instead of useMemo directly
    const keyVariableMap = createKeyVariableMap(keyVariables);
    
    return (
      <div style={{ width: '100%' }}>
        <Descriptions 
          column={1} 
          bordered 
          size="small"
          style={{ width: '100%' }}
          labelStyle={{ width: '30%', verticalAlign: 'top', whiteSpace: 'normal' }}
          contentStyle={{ width: '70%' }}
        >
          {keys.map(key => {
            const value = state[key];
            const prevValue = prevState?.[key];
            const nextValue = nextState?.[key];
            
            // Determine if this value has changed or will change
            const hasChanged = changedKeys?.has(key) || 
              (prevState && JSON.stringify(prevValue) !== JSON.stringify(value));
            
            const willChange = nextState && JSON.stringify(value) !== JSON.stringify(nextValue);
            
            // Check if this is a key with parameters
            const keyVariable = keyVariableMap.get(key);
            const hasParams = keyVariable && keyVariable.params && keyVariable.params.length > 0;
            
            // Format the display key
            let displayKey = key;
            
            if (hasParams && keyVariable?.params) {
              // For function calls with parameters, show them nicely
              const paramNames = keyVariable.params.map((p, i) => `param${i + 1}`).join(', ');
              displayKey = `${key}(${paramNames})`;
            }
            
            return (
              <Descriptions.Item 
                key={key}
                label={
                  <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: 4 }}>
                      <Text strong style={{ marginRight: 8, wordBreak: 'break-word' }}>{displayKey}</Text>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {hasChanged && <Tag color="orange" style={{ marginRight: 4, marginBottom: 4 }}>Changed</Tag>}
                        {willChange && !hasChanged && <Tag color="purple" style={{ marginRight: 4, marginBottom: 4 }}>Will Change</Tag>}
                        {hasParams && <Tag color="cyan" style={{ marginRight: 4, marginBottom: 4 }}>With Params</Tag>}
                      </div>
                    </div>
                  </div>
                }
                style={{
                  backgroundColor: hasChanged || willChange ? '#fafafa' : undefined,
                  borderLeft: hasChanged ? '3px solid #faad14' : willChange ? '3px solid #1890ff' : undefined,
                }}
              >
                <div style={{ width: '100%' }}>
                  {hasParams && keyVariable?.params ? (
                    <div style={{ marginBottom: 12 }}>
                      <Card 
                        size="small" 
                        title="Parameters" 
                        style={{ marginBottom: 12 }}
                        bodyStyle={{ padding: '12px' }}
                      >
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                          <tbody>
                            {keyVariable.params.map((param, index) => (
                              <tr key={index} style={{ borderBottom: index < keyVariable.params!.length - 1 ? '1px solid #f0f0f0' : 'none' }}>
                                <td style={{ padding: '8px', width: '20%', verticalAlign: 'top' }}>
                                  <Text strong>param{index + 1}:</Text>
                                </td>
                                <td style={{ padding: '8px', width: '80%', wordBreak: 'break-all' }}>
                                  {renderValue(param, 0, addressItemsMap)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Card>
                      
                      <div style={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 8
                      }}>
                        <Text strong style={{ marginBottom: 4 }}>Return Value:</Text>
                        <div style={{ 
                          wordBreak: 'break-all', 
                          whiteSpace: 'normal'
                        }}>
                          {renderValue(value, 0, addressItemsMap)}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ 
                      wordBreak: 'break-all', 
                      whiteSpace: 'normal'
                    }}>
                      {renderValue(value, 0, addressItemsMap)}
                    </div>
                  )}
                </div>
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      </div>
    );
  }, [renderValue, createKeyVariableMap]);
  
  // Render the multi-chain view
  const renderMultiChainView = useCallback(() => {
    if (!contracts || contracts.length === 0) {
      return <Empty description="No contract data available" />;
    }
    
    return (
      <div style={{ width: '100%', maxWidth: '100%' }}>
        {contracts.map((contract) => {
          const chainName = getChainName(contract.chainId);
          
          // Extract states
          const beforeState = contract.states.before?.[0]?.state || null;
          const afterState = contract.states.after?.[0]?.state || null;
          const latestState = contract.states.latest?.[0]?.state || null;
          
          // Get block numbers
          const beforeBlockNumber = contract.states.before?.[0]?.blockNumber;
          const afterBlockNumber = contract.states.after?.[0]?.blockNumber;
          const latestBlockNumber = contract.states.latest?.[0]?.blockNumber;
          
          // Determine if this is a contract creation (no before state)
          const isContractCreation = !beforeState && afterState;
          
          // Extract proxy information
          const beforeProxy = beforeState?._proxy?.implementation;
          const afterProxy = afterState?._proxy?.implementation;
          const latestProxy = latestState?._proxy?.implementation;
          
          // Extract implementation details
          const beforeImpl = beforeState?._implementation;
          const afterImpl = afterState?._implementation;
          const latestImpl = latestState?._implementation;
          
          // Get all unique keys across all states
          const allKeys = new Set<string>();
          if (beforeState) Object.keys(beforeState).forEach(key => {
            if (key !== '_proxy' && key !== '_implementation') allKeys.add(key);
          });
          if (afterState) Object.keys(afterState).forEach(key => {
            if (key !== '_proxy' && key !== '_implementation') allKeys.add(key);
          });
          if (latestState) Object.keys(latestState).forEach(key => {
            if (key !== '_proxy' && key !== '_implementation') allKeys.add(key);
          });
          
          // Convert to array and sort
          const allKeysArray = Array.from(allKeys).sort();
          
          // Determine which keys changed between states
          const changedKeysAfter = new Set<string>();
          const changedKeysLatest = new Set<string>();
          
          // Only compare if both states exist
          if (beforeState && afterState) {
            allKeysArray.forEach(key => {
              if (JSON.stringify(beforeState[key]) !== JSON.stringify(afterState[key])) {
                changedKeysAfter.add(key);
              }
            });
          } else if (isContractCreation && afterState) {
            // For contract creation, all keys in the after state are considered "changed"
            Object.keys(afterState).forEach(key => {
              if (key !== '_proxy' && key !== '_implementation') {
                changedKeysAfter.add(key);
              }
            });
          }
          
          // Compare after and latest states
          if (afterState && latestState) {
            allKeysArray.forEach(key => {
              if (JSON.stringify(afterState[key]) !== JSON.stringify(latestState[key])) {
                changedKeysLatest.add(key);
              }
            });
          }
          
          // Determine which keys to show for each state
          const beforeKeys = beforeState ? allKeysArray.filter(key => beforeState[key] !== undefined) : [];
          const afterKeys = isContractCreation 
            ? allKeysArray.filter(key => afterState && afterState[key] !== undefined)
            : allKeysArray.filter(key => changedKeysAfter.has(key));
          const latestKeys = allKeysArray.filter(key => changedKeysLatest.has(key));
          
          return (
            <div key={`${contract.chainId}-${contract.address}`} style={{ marginBottom: 24, width: '100%' }}>
              <Card 
                title={
                  <Space>
                    <Text strong>{contract.name || `Contract ${formatAddress(contract.address)}`}</Text>
                    <Tag color="blue">{chainName}</Tag>
                    {isContractCreation && <Tag color="green">Contract Creation</Tag>}
                  </Space>
                }
                extra={
                  <Space>
                    <Tooltip title={contract.address}>
                      <Text copyable={{ text: contract.address }}>{formatAddress(contract.address)}</Text>
                    </Tooltip>
                  </Space>
                }
                style={{ marginBottom: 16, width: '100%' }}
              >
                <ContractInsights 
                  contract={contract}
                  formatAddress={formatAddress}
                  addressItemsMap={safeContractStateData.address_items_map}
                />
                
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 20 }}>
                  {beforeState && (
                    <div style={{ display: 'flex', marginBottom: 16 }}>
                      <div style={{ 
                        width: '200px', 
                        padding: '12px', 
                        backgroundColor: '#f0f5ff', 
                        borderRadius: '4px',
                        marginRight: 16
                      }}>
                        <Space direction="vertical" size={0}>
                          <Text strong>Before Transaction</Text>
                          <Text>Block #{beforeBlockNumber}</Text>
                          {beforeProxy && (
                            <Space>
                              <Text type="secondary">Proxy:</Text>
                              <Text copyable={{ text: beforeProxy }}>
                                {formatAddress(beforeProxy, safeContractStateData.address_items_map)}
                              </Text>
                            </Space>
                          )}
                          <Text type="secondary">{beforeKeys.length} state variables</Text>
                        </Space>
                      </div>
                      <div style={{ flex: 1 }}>
                        <Card 
                          size="small"
                          style={{ width: '100%' }}
                          bordered
                        >
                          {renderStateValues(
                            beforeKeys,
                            beforeState,
                            null,
                            afterState,
                            contract.keyStateVariables,
                            new Set(),
                            safeContractStateData.address_items_map
                          )}
                        </Card>
                      </div>
                    </div>
                  )}
                  
                  {afterState && (
                    <div style={{ display: 'flex', marginBottom: 16 }}>
                      <div style={{ 
                        width: '200px', 
                        padding: '12px', 
                        backgroundColor: '#f6ffed', 
                        borderRadius: '4px',
                        marginRight: 16
                      }}>
                        <Space direction="vertical" size={0}>
                          <Text strong>{isContractCreation ? 'Contract Creation' : 'After Transaction'}</Text>
                          <Text>Block #{afterBlockNumber}</Text>
                          {afterProxy && (
                            <Space>
                              <Text type="secondary">Proxy:</Text>
                              <Text copyable={{ text: afterProxy }}>
                                {formatAddress(afterProxy, safeContractStateData.address_items_map)}
                              </Text>
                            </Space>
                          )}
                          {beforeProxy && afterProxy && beforeProxy !== afterProxy && (
                            <Tag color="orange">Implementation Changed</Tag>
                          )}
                          <Text type="secondary">
                            {isContractCreation 
                              ? `${afterKeys.length} initial state variables` 
                              : `${changedKeysAfter.size} changed variables`}
                          </Text>
                        </Space>
                      </div>
                      <div style={{ flex: 1 }}>
                        <Card 
                          size="small"
                          style={{ width: '100%' }}
                          bordered
                        >
                          {afterKeys.length > 0 ? (
                            renderStateValues(
                              afterKeys,
                              afterState,
                              beforeState,
                              latestState,
                              contract.keyStateVariables,
                              changedKeysAfter,
                              safeContractStateData.address_items_map
                            )
                          ) : (
                            <Empty description={
                              isContractCreation 
                                ? "No initial state variables" 
                                : "No state variables changed"
                            } />
                          )}
                        </Card>
                      </div>
                    </div>
                  )}
                  
                  {latestState && latestBlockNumber !== afterBlockNumber && (
                    <div style={{ display: 'flex', marginBottom: 16 }}>
                      <div style={{ 
                        width: '200px', 
                        padding: '12px', 
                        backgroundColor: '#f9f0ff', 
                        borderRadius: '4px',
                        marginRight: 16
                      }}>
                        <Space direction="vertical" size={0}>
                          <Text strong>Latest State</Text>
                          <Text>Block #{latestBlockNumber}</Text>
                          {latestProxy && (
                            <Space>
                              <Text type="secondary">Proxy:</Text>
                              <Text copyable={{ text: latestProxy }}>
                                {formatAddress(latestProxy, safeContractStateData.address_items_map)}
                              </Text>
                            </Space>
                          )}
                          {afterProxy && latestProxy && afterProxy !== latestProxy && (
                            <Tag color="orange">Implementation Changed</Tag>
                          )}
                          <Text type="secondary">{changedKeysLatest.size} changed since transaction</Text>
                        </Space>
                      </div>
                      <div style={{ flex: 1 }}>
                        <Card 
                          size="small"
                          style={{ width: '100%' }}
                          bordered
                        >
                          {latestKeys.length > 0 ? (
                            renderStateValues(
                              latestKeys,
                              latestState,
                              afterState,
                              null,
                              contract.keyStateVariables,
                              changedKeysLatest,
                              safeContractStateData.address_items_map
                            )
                          ) : (
                            <Empty description="No changes since transaction" />
                          )}
                        </Card>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* Add a summary section showing all current values */}
                <Collapse ghost style={{ marginTop: 16 }}>
                  <Panel header="View Complete Current State" key="1">
                    <Card 
                      size="small" 
                      style={{ width: '100%' }}
                      title={
                        latestProxy && (
                          <Space>
                            <Text type="secondary">Proxy Implementation:</Text>
                            <Text copyable={{ text: latestProxy }}>
                              {formatAddress(latestProxy, safeContractStateData.address_items_map)}
                            </Text>
                            {latestImpl?.name && <Tag color="purple">{latestImpl.name}</Tag>}
                          </Space>
                        )
                      }
                    >
                      {renderStateValues(
                        Object.keys(latestState || afterState || beforeState || {}).filter(
                          k => k !== '_proxy' && k !== '_implementation'
                        ),
                        latestState || afterState || beforeState || {},
                        null,
                        null,
                        contract.keyStateVariables,
                        new Set(),
                        safeContractStateData.address_items_map
                      )}
                    </Card>
                  </Panel>
                </Collapse>
              </Card>
            </div>
          );
        })}
      </div>
    );
  }, [contracts, getChainName, formatAddress, renderStateValues, safeContractStateData.address_items_map]);
  
  // Loading state
  if (!contractStateData) {
    return (
      <Card title="Contract State" style={{ marginTop: 16 }}>
        <div style={{ display: 'flex', justifyContent: 'center', padding: 24 }}>
          <Spin tip="Loading contract state..." />
        </div>
      </Card>
    );
  }
  
  // No data state
  if (Object.keys(safeContractStateData.contracts || {}).length === 0) {
    return (
      <Card title="Contract State" style={{ marginTop: 16 }}>
        <Empty description="No contract state data available" />
      </Card>
    );
  }
  
  return (
    <Card title="Contract State" style={{ marginTop: 16 }}>
      <Tabs 
        activeKey={activeTab} 
        onChange={setActiveTab}
        items={[
          {
            key: '1',
            label: 'State Changes',
            children: renderMultiChainView()
          },
          {
            key: '2',
            label: 'Contract Relationships',
            children: <ContractRelationshipAnalyzer 
              formatAddress={formatAddress}
              contractStateData={safeContractStateData}
            />
          },
          {
            key: '3',
            label: 'Cross-Chain Comparison',
            children: <ContractCrossChainComparison 
              formatAddress={formatAddress}
              addressItemsMap={safeContractStateData.address_items_map as AddressItemsMap || {}}
              contractStateData={safeContractStateData}
              getChainName={getChainName}
            />
          }    
        ]}
      />
    </Card>
  );
};

export default ContractStateHighlighter;
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Button, Slider, Space, Tooltip, Modal, Switch, Typography, Select, Row, Col, Card, Dropdown, Menu, Radio, Checkbox } from 'antd';
import { 
    PlayCircleOutlined, 
    PauseCircleOutlined, 
    StepForwardOutlined,
    StepBackwardOutlined,
    ReloadOutlined,
    SettingOutlined,
    CheckCircleOutlined,
    RetweetOutlined
} from '@ant-design/icons';

export interface ReplaySettings {
    speed: number;           // Replay speed multiplier
    showMempool: boolean;    // Show transactions when first seen in mempool
    highlightNew: boolean;   // Highlight new nodes/edges
    autoCenter: boolean;     // Auto-center on new transactions
    showTimestamps: boolean; // Show timestamps during replay
    loop: boolean;  // Added loop setting
    stepMode: 'individual' | 'timestamp'; // Add new setting
    deriveStates: boolean;  // New setting
    showStateLabels: boolean;  // New setting for labels    
}

interface TransactionReplayProps {
    transactions: any[];
    onReplayStep: (step: number) => void;  // This is the handler from TransactionGraph
    onReset: () => void;
    onStepForward: () => void;
    onStepBack: () => void;
    onReplayComplete: (playing: boolean) => void;
    isReplaying: boolean;
    currentStep: number;
    totalSteps: number;
    currentTimestamp: string;
    nextTimestamp: string;
    settings: ReplaySettings;
    onSettingsChange: (settings: ReplaySettings) => void;
}

export const TransactionReplay: React.FC<TransactionReplayProps> = ({
    transactions,
    onReplayStep,
    onReset,
    onStepForward,
    onStepBack,
    onReplayComplete,
    isReplaying,
    currentStep,
    totalSteps,
    currentTimestamp,
    nextTimestamp,
    settings,
    onSettingsChange
}) => {
    const [showSettings, setShowSettings] = useState(false);

    const getTimestampTooltip = useCallback(() => {
        if (currentStep === 0) {
            return nextTimestamp ? nextTimestamp : 'Start';
        }
        return currentTimestamp ? currentTimestamp : 'Start';
    }, [currentTimestamp, nextTimestamp, currentStep]);

    const getNextTimestampTooltip = useCallback(() => {
        return nextTimestamp ? new Date(nextTimestamp).toLocaleString() : 'End';
    }, [nextTimestamp]);

    // Handle slider change
    const handleSliderChange = useCallback((value: number) => {
        // Use the handler from TransactionGraph
        onReplayStep(value);
    }, [onReplayStep]);

    const isComplete = currentStep === totalSteps && !settings.loop;

    return (
        <>
            <Card size="small" style={{ marginBottom: 16 }}>
                <Row gutter={[16, 16]} align="middle">
                    <Col>
                        <Space>
                            <Tooltip title={currentStep > 0 ? 
                                `Step Back (${currentTimestamp ? currentTimestamp : 'Start'})` : 
                                'At Beginning'}>
                                <Button
                                    icon={<StepBackwardOutlined />}
                                    onClick={onStepBack}
                                    disabled={isReplaying || currentStep === 0}
                                />
                            </Tooltip>

                            <Tooltip title={
                                isComplete ? "Replay Complete" : 
                                isReplaying ? `Current: ${getTimestampTooltip()}` :
                                currentStep === 0 ? `Play from ${nextTimestamp ? nextTimestamp : 'Start'}` :
                                `Play from ${currentTimestamp ? currentTimestamp: 'Start'}`
                            }>
                                <Button
                                    icon={isComplete ? <CheckCircleOutlined /> : 
                                        (isReplaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />)}
                                    onClick={() => onReplayComplete(!isReplaying)}
                                    disabled={isComplete && !settings.loop}
                                    type={isComplete ? "default" : "primary"}
                                    ghost={!isComplete}
                                />
                            </Tooltip>
                            
                            <Tooltip title={currentStep < totalSteps ? 
                                `Step Forward (${nextTimestamp ? nextTimestamp: 'End'})` : 
                                'At End'}>
                                <Button
                                    icon={<StepForwardOutlined />}
                                    onClick={onStepForward}
                                    disabled={isReplaying || (currentStep === totalSteps && !settings.loop)}
                                />
                            </Tooltip>
                        </Space>
                    </Col>

                    {/* Progress */}
                    <Col flex="auto">
                        <Slider
                            style={{ width: '90%', minWidth: '200px' }}
                            value={currentStep}
                            min={0}
                            max={totalSteps}
                            onChange={handleSliderChange}
                            tooltip={{
                                formatter: (value) => `${value || 0}/${totalSteps}`
                            }}
                            disabled={isReplaying}
                            marks={{
                                0: 'Start',
                                [totalSteps]: 'End'
                            }}
                        />
                    </Col>

                    {/* Settings */}
                    <Col>
                        <Space>
                            <Select
                                value={settings.speed}
                                onChange={(value) => onSettingsChange({ ...settings, speed: value })}
                                style={{ width: 90 }}
                                options={[
                                    { value: 0.25, label: '0.25x' },
                                    { value: 0.5, label: '0.5x' },
                                    { value: 1, label: '1x' },
                                    { value: 2, label: '2x' },
                                    { value: 4, label: '4x' }
                                ]}
                            />

                            <Tooltip title={settings.loop ? "Loop Enabled" : "Loop Disabled"}>
                                <Button
                                    icon={<RetweetOutlined />}
                                    onClick={() => onSettingsChange({ ...settings, loop: !settings.loop })}
                                    type={settings.loop ? "primary" : "default"}
                                />
                            </Tooltip>

                            <Tooltip title="Reset">
                                <Button
                                    icon={<ReloadOutlined />}
                                    onClick={() => {
                                        onReset();
                                    }}
                                />
                            </Tooltip>

                            <Tooltip title="Advanced Settings">
                                <Button
                                    icon={<SettingOutlined />}
                                    onClick={() => setShowSettings(true)}
                                />
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>
            </Card>

            <Modal
                title="Advanced Replay Settings"
                open={showSettings}
                onCancel={() => setShowSettings(false)}
                footer={null}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Typography.Text>Replay Speed</Typography.Text>
                    <Slider
                        min={0.1}
                        max={10}
                        step={0.1}
                        value={settings.speed}
                        onChange={(value) => onSettingsChange({ ...settings, speed: value })}
                    />
                    
                    <Switch
                        checked={settings.showMempool}
                        onChange={(checked) => onSettingsChange({ ...settings, showMempool: checked })}
                    /> Show Mempool Transactions
                    
                    <Switch
                        checked={settings.highlightNew}
                        onChange={(checked) => onSettingsChange({ ...settings, highlightNew: checked })}
                    /> Highlight New Elements
                    
                    <Switch
                        checked={settings.autoCenter}
                        onChange={(checked) => onSettingsChange({ ...settings, autoCenter: checked })}
                    /> Auto-center on New Transactions
                    
                    <Switch
                        checked={settings.showTimestamps}
                        onChange={(checked) => onSettingsChange({ ...settings, showTimestamps: checked })}
                    /> Show Timestamps
                    
                    <Switch
                        checked={settings.loop}
                        onChange={(checked) => onSettingsChange({ ...settings, loop: checked })}
                    /> Loop Replay

                    <Menu>
                        <Menu.Item key="stepMode">
                            <div style={{ padding: '8px' }}>
                                <Typography.Text>Step Mode</Typography.Text>
                                <Radio.Group
                                value={settings.stepMode}
                                onChange={e => onSettingsChange({ ...settings, stepMode: e.target.value })}
                            >
                                <Radio value="individual">Individual</Radio>
                                <Radio value="timestamp">By Timestamp</Radio>
                                </Radio.Group>
                            </div>
                        </Menu.Item>
                        <Menu.Item key="deriveStates">
                            <div style={{ padding: '8px' }}>
                                <Checkbox
                                    checked={settings.deriveStates}
                                    onChange={e => onSettingsChange({ ...settings, deriveStates: e.target.checked })}
                                >
                                    Show Transaction States
                                </Checkbox>
                            </div>
                        </Menu.Item>
                        <Menu.Item key="showStateLabels">
                            <div style={{ padding: '8px' }}>
                                <Checkbox
                                    checked={settings.showStateLabels}
                                    onChange={e => onSettingsChange({ ...settings, showStateLabels: e.target.checked })}
                                >
                                    Show State Labels
                                </Checkbox>
                            </div>
                        </Menu.Item>                        
                    </Menu>
                </Space>
            </Modal>
        </>
    );
}; 
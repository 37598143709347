import React from 'react';
import { Typography } from 'antd';
import type { TableColumnType } from 'antd';
import type { Transaction } from '../types';

export const getSummaryColumn = (): TableColumnType<Transaction> => ({
    title: 'Summary',
    dataIndex: 'summary',
    key: 'summary',
    width: 300,
    sorter: (a: Transaction, b: Transaction) => (a.summary || '').localeCompare(b.summary || ''),
    render: (summary: string) => summary ? (
        <Typography.Text style={{ whiteSpace: 'nowrap', minWidth: 300,  display: 'inline-block'  }}>
            {summary}
        </Typography.Text>
    ) : <Typography.Text style={{ whiteSpace: 'nowrap', minWidth: 300,  display: 'inline-block'  }}></Typography.Text>
});
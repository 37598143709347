import { Modal, Space, Card, Tag, Typography, Divider, Alert, Tooltip } from 'antd';
import { TagsOutlined, LinkOutlined, TeamOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ModalProps } from 'antd/lib/modal';

const { Text, Paragraph } = Typography;

interface IntegrationResult {
    integration_id: string;
    integration_name: string;
    global_tags?: string[];
    global_from_address_labels?: string[];
    global_to_address_labels?: string[];
    main_tags?: string[];
    main_from_address_labels?: string[];
    main_to_address_labels?: string[];
    main_groups?: string[];
    tags?: string[];
    from_address_labels?: string[];
    to_address_labels?: string[];
    groups?: string[];
}

interface IntegrationDetailsModalProps {
    modalProps: ModalProps;
    integrationId?: string;
    integrationName?: string;
    transactionRecord?: {
        integration_results?: IntegrationResult[];
        [integrationId: string]: {
            ITEM_ATTACH_TRANSACTIONS_CONFIG: {
                global_tags: string[];
                from_address_labels: string[];
                global_to_address_labels: string[];
                group_names: string[];
                to_address_labels: string[];
                tags: string[];
            };
        } | any;  // Allow for other properties in the record
    };
    onClose: () => void;
}

const MODIFICATION_DESCRIPTIONS = {
    global: {
        title: "Global Modifications",
        description: "These modifications apply to all transactions"
    },
    main: {
        title: "Main Modifications",
        description: "Primary modifications for this transaction"
    },
    direct: {
        title: "Direct Modifications",
        description: "Specific to this transaction instance"
    },
    tags: "Transaction tags for categorization and filtering",
    from_labels: "Labels identifying the source address",
    to_labels: "Labels identifying the destination address",
    groups: "Group assignments for organization"
};

export const IntegrationDetailsModal: React.FC<IntegrationDetailsModalProps> = ({
    modalProps,
    integrationId,
    integrationName,
    transactionRecord,
    onClose
}) => {
    // Get the integration result from both sources
    const integrationResult = transactionRecord?.integration_results?.find(
        result => result.integration_id === integrationId
    );
    const additionalData = integrationId ? 
        transactionRecord?.[integrationId]?.ITEM_ATTACH_TRANSACTIONS_CONFIG : 
        undefined;

    // Merge the data
    const mergedData = {
        tags: [
            ...(integrationResult?.tags || []),
            ...(additionalData?.tags || [])
        ],
        global_tags: [
            ...(integrationResult?.global_tags || []),
            ...(additionalData?.global_tags || [])
        ],
        from_address_labels: [
            ...(integrationResult?.from_address_labels || []),
            ...(additionalData?.from_address_labels || [])
        ],
        global_from_address_labels: [
            ...(integrationResult?.global_from_address_labels || []),
            ...(additionalData?.global_from_address_labels || [])
        ],
        to_address_labels: [
            ...(integrationResult?.to_address_labels || []),
            ...(additionalData?.to_address_labels || [])
        ],
        global_to_address_labels: [
            ...(integrationResult?.global_to_address_labels || []),
            ...(additionalData?.global_to_address_labels || [])
        ],
        groups: [
            ...(integrationResult?.groups || []),
            ...(additionalData?.group_names || [])
        ],
        main_groups: integrationResult?.main_groups || []
    };

    const renderTagSection = (titleContent: React.ReactNode, tags?: string[], color: string = 'blue', tooltip?: string) => {
        if (!tags || tags.length === 0) return null;
        
        return (
            <div>
                <Space align="center">
                    <Text strong>{titleContent}</Text>
                    {tooltip && (
                        <Tooltip title={tooltip}>
                            <InfoCircleOutlined style={{ color: '#8c8c8c', fontSize: '14px' }} />
                        </Tooltip>
                    )}
                </Space>
                <Space style={{ marginLeft: 8, marginTop: 8 }} wrap>
                    {tags.map((tag, index) => (
                        <Tag key={index} color={color}>{tag}</Tag>
                    ))}
                </Space>
            </div>
        );
    };

    const renderSection = (title: string, data: {
        tags?: string[],
        fromLabels?: string[],
        toLabels?: string[],
        groups?: string[]
    }, bgColor: string, type: 'global' | 'main' | 'direct') => {
        const itemCount = Object.values(data).reduce((acc, curr) => acc + (curr?.length || 0), 0);
        
        return (
            <Card 
                title={
                    <Space>
                        <Text strong>{MODIFICATION_DESCRIPTIONS[type].title}</Text>
                        <Tooltip title={MODIFICATION_DESCRIPTIONS[type].description}>
                            <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                        </Tooltip>
                        <Tag color={itemCount > 0 ? "processing" : "default"}>
                            {itemCount} {itemCount === 1 ? 'item' : 'items'}
                        </Tag>
                    </Space>
                }
                bordered={false}
                style={{ background: bgColor }}
            >
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    {renderTagSection(
                        <Space><TagsOutlined /> Tags</Space>, 
                        data.tags, 
                        'blue',
                        MODIFICATION_DESCRIPTIONS.tags
                    )}
                    {renderTagSection(
                        <Space><LinkOutlined /> From Labels</Space>, 
                        data.fromLabels, 
                        'green',
                        MODIFICATION_DESCRIPTIONS.from_labels
                    )}
                    {renderTagSection(
                        <Space><LinkOutlined /> To Labels</Space>, 
                        data.toLabels, 
                        'orange',
                        MODIFICATION_DESCRIPTIONS.to_labels
                    )}
                    {renderTagSection(
                        <Space><TeamOutlined /> Groups</Space>, 
                        data.groups, 
                        'purple',
                        MODIFICATION_DESCRIPTIONS.groups
                    )}
                    
                    {!itemCount && (
                        <Alert
                            type="info"
                            message="No modifications"
                            showIcon
                        />
                    )}
                </Space>
            </Card>
        );
    };

    return (
        <Modal 
            {...modalProps} 
            width={1000}
            centered 
            title={`Integration Details: ${integrationName}`}
            onOk={onClose}
            onCancel={onClose}
        >
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                {/* Global Section */}
                {renderSection("Global Information", {
                    tags: mergedData.global_tags,
                    fromLabels: mergedData.global_from_address_labels,
                    toLabels: mergedData.global_to_address_labels,
                    groups: mergedData.main_groups
                }, '#f0f5ff', 'global')}

                {/* Main Section */}
                {renderSection("Main Information", {
                    tags: mergedData.tags,
                    fromLabels: mergedData.from_address_labels,
                    toLabels: mergedData.to_address_labels,
                    groups: mergedData.groups
                }, '#f6ffed', 'main')}

                {/* Direct Section */}
                {renderSection("Direct Information", {
                    tags: additionalData?.tags,
                    fromLabels: additionalData?.from_address_labels,
                    toLabels: additionalData?.to_address_labels,
                    groups: additionalData?.group_names
                }, '#fff7e6', 'direct')}
            </Space>
        </Modal>
    );
}; 
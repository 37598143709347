/**
 * Safely parse JSON data with error handling
 * @param data - The data to parse
 * @returns The parsed data or the original data if parsing fails
 */
export const safeJSONParse = (data: any) => {
    if (typeof data === 'string') {
        try {
            return JSON.parse(data);
        } catch (error) {
            console.error('Failed to parse JSON:', error);
            return data;
        }
    }
    return data;
};

/**
 * Format data for debugging
 * @param data - The data to format
 * @returns Formatted string representation of the data
 */
export const formatDebugData = (data: any): string => {
    if (!data) return '';
    
    try {
        if (typeof data === 'object') {
            return JSON.stringify(data, null, 2);
        }
        return String(data);
    } catch (error) {
        return 'Error formatting debug data';
    }
};

/**
 * Parse a string into a Date object
 * @param dateString - The date string to parse
 * @returns Date object or null if parsing fails
 */
export const safeDateParse = (dateString: string): Date | null => {
    try {
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? null : date;
    } catch {
        return null;
    }
};

/**
 * Parse a string into a number
 * @param numberString - The string to parse
 * @param defaultValue - Value to return if parsing fails
 * @returns Parsed number or default value
 */
export const safeNumberParse = (numberString: string, defaultValue: number = 0): number => {
    const parsed = Number(numberString);
    return isNaN(parsed) ? defaultValue : parsed;
};

/**
 * Parse boolean strings
 * @param value - The value to parse
 * @returns Parsed boolean or null if parsing fails
 */
export const safeBooleanParse = (value: any): boolean | null => {
    if (typeof value === 'boolean') return value;
    if (typeof value === 'string') {
        const normalized = value.toLowerCase().trim();
        if (normalized === 'true') return true;
        if (normalized === 'false') return false;
    }
    return null;
};

/**
 * Parse a string into an array
 * @param arrayString - The string to parse
 * @returns Parsed array or empty array if parsing fails
 */
export const safeArrayParse = (arrayString: string): any[] => {
    try {
        const parsed = JSON.parse(arrayString);
        return Array.isArray(parsed) ? parsed : [];
    } catch {
        return [];
    }
}; 
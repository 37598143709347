export const ALL_COLUMNS = [
    { key: 'chain_id', label: 'Chain' },
    { key: 'hash', label: 'Hash' },
    { key: 'method_label', label: 'Method' },
    { key: 'summary', label: 'Summary' },
    { key: 'from_address', label: 'From' },
    { key: 'to_address', label: 'To' },
    { key: 'value', label: 'Value' },
    { key: 'block_timestamp', label: 'Block Time' },
    { key: 'groups', label: 'Groups' },
    { key: 'entities', label: 'Entities' },
    { key: 'shared_with', label: 'Shared With' },
    { key: 'receipt_status', label: 'Receipt Status' },
    { key: 'found_in', label: 'Found In' },
    { key: 'actions', label: 'Actions' },
] as const;

export const DEFAULT_COLUMNS = [
    'shared_with',
    'chain_id', 
    'hash', 
    'method_label', 
    'summary', 
    'from_address', 
    'to_address', 
    'value', 
    'groups',
    'entities',
    'block_timestamp', 
    'found_in', 
    'receipt_status',
    'actions'
];
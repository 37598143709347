import { Space, Avatar, Typography, Tag, Tooltip } from 'antd';
import type { TableColumnType } from 'antd';
import type { Transaction } from '../types';
import { Key } from 'react';

export const getValueColumn = (cdn_domain_name: string): TableColumnType<Transaction> => ({
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: 200,
    filters: [
        { text: 'Has Native Transfer', value: 'native' },
        { text: 'Has ERC20 Transfer', value: 'erc20' },
        { text: 'Has NFT Transfer', value: 'nft' },
        { text: 'Has Contract Interaction', value: 'contract' },
    ],
    onFilter: (value: boolean | Key, record: Transaction) => {
        const filterValue = value.toString();
        
        const hasNativeTransfers = Array.isArray(record?.native_transfers) && record.native_transfers.length > 0;
        const hasERC20Transfers = Array.isArray(record?.erc20_transfers) && record.erc20_transfers.length > 0;
        const hasNFTTransfers = Array.isArray(record?.nft_transfers) && record.nft_transfers.length > 0;
        const hasContractInteractions = Array.isArray(record?.contract_interactions) && record.contract_interactions.length > 0;

        switch(filterValue) {
            case 'native': return hasNativeTransfers;
            case 'erc20': return hasERC20Transfers;
            case 'nft': return hasNFTTransfers;
            case 'contract': return hasContractInteractions;
            default: return true;
        }
    },
    sorter: (a: Transaction, b: Transaction) => {
        const valueA = a.native_transfers?.[0]?.value_formatted || '0';
        const valueB = b.native_transfers?.[0]?.value_formatted || '0';
        return Number(valueA) - Number(valueB);
    },
    render: (_: string, record: Transaction) => {
        const nativeTransfers = record.native_transfers || [];
        const erc20Transfers = record.erc20_transfers || [];
        const nftTransfers = record.nft_transfers || [];
        const contractInteractions = record?.contract_interactions || [];
        
        return (
            <div style={{ minWidth: 200, minHeight: 21 }}>
            <Space direction="vertical">
                {/* Native Transfers */}
                {Array.isArray(nativeTransfers) && nativeTransfers.map((transfer: any, index: number) => (
                    <Space key={`native-${index}`} style={{ whiteSpace: 'nowrap' }}>
                         <Tooltip title={transfer.summary || 'No Summary Available'}>
                            <Tag color="purple">Native</Tag>
                         </Tooltip>
                        {transfer.token_logo && (
                            <Tooltip title={transfer.token_symbol}>
                                <Avatar 
                                    size="small" 
                                    src={transfer.token_logo}
                                    style={{ backgroundColor: 'transparent' }}
                                />
                            </Tooltip>
                        )}
                        <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                            {transfer.value_formatted} {transfer.token_symbol}
                        </Typography.Text>
                        {transfer.direction && (
                            <Tag 
                                color={transfer.direction === 'send' ? 'red' : 'green'}
                                style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                            >
                                {transfer.direction}
                            </Tag>
                        )}
                    </Space>
                ))}
                
                {/* ERC20 Transfers */}
                {Array.isArray(erc20Transfers) && erc20Transfers.map((transfer: any, index: number) => (
                    <Space key={`erc20-${index}`} style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title={transfer.summary || 'No Summary Available'}>
                            <Tag color="blue">ERC20</Tag>
                        </Tooltip>
                        {transfer.token_logo && (
                            <Tooltip title={`${transfer.token_name} (${transfer.token_symbol})`}>
                                <Avatar 
                                    size="small" 
                                    src={transfer.token_logo}
                                    style={{ backgroundColor: 'transparent' }}
                                />
                            </Tooltip>
                        )}
                        <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                            {transfer.value_formatted} {transfer.token_symbol}
                        </Typography.Text>
                        {transfer.direction && (
                            <Tag 
                                color={transfer.direction === 'send' ? 'red' : 'green'}
                                style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                            >
                                {transfer.direction}
                            </Tag>
                        )}
                        {transfer.security_score && (
                            <Tooltip title="Token Security Score">
                                <Tag color={Number(transfer.security_score) >= 80 ? 'green' : 'orange'}>
                                    Score: {transfer.security_score}
                                </Tag>
                            </Tooltip>
                        )}
                    </Space>
                ))}

                {/* NFT Transfers */}
                {Array.isArray(nftTransfers) && nftTransfers.map((transfer: any, index: number) => (
                    <Space key={`nft-${index}`} style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title={transfer.summary || 'No Summary Available'}>
                            <Tag color="magenta">NFT</Tag>
                        </Tooltip>
                        <Space direction="vertical" size={0}>
                            <Space>
                                <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                                    {transfer.contract_type}
                                </Typography.Text>
                                <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                                    ID: {transfer.token_id}
                                </Typography.Text>
                                {transfer.amount && transfer.amount !== "1" && (
                                    <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                                        × {transfer.amount}
                                    </Typography.Text>
                                )}
                            </Space>
                            <Space>
                                {transfer.from_address_entity_logo && (
                                    <Tooltip title={transfer.from_address_entity || transfer.from_address_label}>
                                        <Avatar size="small" src={transfer.from_address_entity_logo} />
                                    </Tooltip>
                                )}
                                <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                                    →
                                </Typography.Text>
                                {transfer.to_address_entity_logo && (
                                    <Tooltip title={transfer.to_address_entity || transfer.to_address_label}>
                                        <Avatar size="small" src={transfer.to_address_entity_logo} />
                                    </Tooltip>
                                )}
                            </Space>
                        </Space>
                        {transfer.verified_collection && (
                            <Tooltip title="Verified Collection">
                                <Tag color="green">Verified</Tag>
                            </Tooltip>
                        )}
                        {transfer.possible_spam && (
                            <Tooltip title="Possible Spam">
                                <Tag color="red">Spam</Tag>
                            </Tooltip>
                        )}
                    </Space>
                ))}

                {/* Contract Interactions */}
                {contractInteractions && Object.entries(contractInteractions).map(([interactionType, interactions]) => (
                    Array.isArray(interactions) && interactions.map((interaction: any, index: number) => (
                        <Space key={`${interactionType}-${index}`} style={{ whiteSpace: 'nowrap' }}>
                            <Tooltip title={interaction.summary || 'No Summary Available'}>
                                <Tag color="red">   
                                    {"Contract - " + interactionType.charAt(0).toUpperCase() + interactionType.slice(1)}
                                </Tag>
                            </Tooltip>
                            <Space direction="vertical" size={0}>
                                <Space>
                                    <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                                        {interactionType === 'approvals' 
                                            ? <>Approve {interaction.token?.token_symbol}</>
                                            : interaction.method_label || interaction.method || interaction.name || 'Unknown Method'
                                        }                                     
                                    </Typography.Text>
                                    {(interaction.value_formatted || interaction.value) && (
                                        <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                                            Value: {interaction.value_formatted || interaction.value} {interaction.token?.token_symbol}
                                        </Typography.Text>
                                    )}
                                </Space>
                                <Space>
                                    {(interaction.spender?.entity_logo || interaction.contract_entity_logo) && (
                                        <Tooltip title={
                                            interaction.spender?.entity || 
                                            interaction.spender?.address_label || 
                                            interaction.contract_entity || 
                                            interaction.contract_label
                                        }>
                                            <Avatar 
                                                size="small" 
                                                src={interaction.spender?.entity_logo || interaction.contract_entity_logo} 
                                            />
                                        </Tooltip>
                                    )}
                                    {interaction.spender?.address || interaction.contract_address && (
                                        <Typography.Text copyable={{ 
                                            text: interaction.spender?.address || interaction.contract_address 
                                        }}>
                                        {`${(interaction.spender?.address || interaction.contract_address || '')?.slice(0, 6)}...${(interaction.spender?.address || interaction.contract_address || '')?.slice(-4)}`}
                                    </Typography.Text>
                                    )}
                                </Space>
                            </Space>
                            {interaction.verified_contract && (
                                <Tooltip title="Verified Contract">
                                    <Tag color="green">Verified</Tag>
                                </Tooltip>
                            )}
                            {interaction.possible_spam && (
                                <Tooltip title="Possible Spam Contract">
                                    <Tag color="red">Spam</Tag>
                                </Tooltip>
                            )}
                        </Space>
                    ))
                ))}
            </Space>
            </div>
        );
    }
}); 
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { IResourceComponentsProps, useList } from "@refinedev/core";

import {
    List,
    useModalForm,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Space, Button, Form, Input, Radio, Modal, Tooltip, Select, Card, Alert, Typography, Checkbox, Avatar, notification } from "antd";
import { ImportOutlined, FilterOutlined } from '@ant-design/icons';

import { TransactionTable } from '../../components/transactions/table';

import { CHAIN_MAPPING } from "../../interfaces/chain.d";
import { ChainAvatar } from "../../components/chain-avatar";
import { TransactionGraph } from '../../components/graph/TransactionGraph';
import { useConfig } from 'contexts/config/ConfigContext';
import { CHAIN_CONFIG } from "../../interfaces/chain.d"
import { createDebugger } from 'contexts/leader/debug';
import { useSubscriptions } from 'contexts/subscriptions/useSubscriptions';

const VALID_STATUSES = [
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Failed', value: 'failed' }
];
// Define search types for DynamoDB
const SEARCH_OPTIONS = {
    USER: {
        description: 'Search my transactions',
        value: 'user'
    },    
    TRANSACTION_HASH: {
        description: 'Search by transaction hash',
        value: 'transaction_hash'
    },
    TAG: {
        description: 'Search by tag',
        value: 'tag'
    },
    LABEL: {
        description: 'Search by label',
        value: 'label'
    },
    GROUP: {
        description: 'Search by group',
        value: "group"
    },
    ADDRESS: {
        description: 'Search by address',
        value: 'address'
    },
    CHAIN: {
        description: 'Search by chain',
        value: 'chain'
    },
    STATUS: {
        description: 'Search by status',
        value: 'status',
    },
    FUNCTION_CALL: {
        description: 'Search by function call',
        value: 'function_call'
    },
    INCLUDE_ID: {
        description: 'Search by include_id',
        value: 'include_id'
    },
    ENTITIES: {
        description: 'Search by entities',
        value: 'entities'
    }
} as const;

interface SearchQuery {
    type: keyof typeof SEARCH_OPTIONS;
    value: string;
}

export const TransactionsList: React.FC<IResourceComponentsProps> = () => {
    const [searchQuery, setSearchQuery] = useState<SearchQuery | null>(null);
    const [debouncedQuery, setDebouncedQuery] = useState<SearchQuery | null>(null);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [initialPaginationCursors, setInitialPaginationCursors] = useState({
        database: {},
        moralis: {},
        status: {}
    });
    const [filteredTransactions, setFilteredTransactions] = useState<any[]>([]);
    const [addressItemsMap, setAddressItemsMap] = useState<any>({});
    const [graphKey, setGraphKey] = useState(0);    
    const [searchError, setSearchError] = useState<string | null>(null);
    const { org_id, tenant_id, user_id, user, token, cdn_domain_name } = useConfig();
    const [searchInputValue, setSearchInputValue] = useState<string>('');

    // Debounce search query
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedQuery(searchQuery);
        }, 300);

        return () => clearTimeout(timer);
    }, [searchQuery]);
        
    const { data, isLoading, isFetching, isRefetching, refetch } = useList({
        resource: "transactions",
        filters: searchQuery ? [
            {
                field: searchQuery.type.toLowerCase(),
                operator: "eq",
                value: searchQuery.value,
            },
        ] : [],
        pagination: {
            pageSize: 100,
        },
        queryOptions: {
            enabled: (
                // Load on initial page load
                !searchQuery || 
                // Load when "MY_TRANSACTIONS" is selected (regardless of value)
                searchQuery.type === "USER"
            ),
            onError: (error: any) => {
                setSearchError(error instanceof Error ? error.message : 'An error occurred during search');
            },
            onSuccess: (data: any) => {
                // Extract pagination cursor from headers
                const lastEvaluatedKey = data?.data?.last_evaluated_key;
                if (lastEvaluatedKey) {
                    setInitialPaginationCursors((prev: any) => ({
                        ...prev,
                        database: { key: lastEvaluatedKey }
                    }));
                }
                const processedTransactions = data?.data?.transactions?.map((tx: any) => ({
                    ...tx,
                    // Ensure all required fields are present
                    address: tx.address,
                    // Add any computed fields needed for display
                    formattedTimestamp: dayjs(tx.block_timestamp).format('YYYY-MM-DD HH:mm:ss'),
                    // Add any address labels from address_items_map
                    address_items_map: data?.data?.address_items_map
                }));
                setTransactions(processedTransactions);
                setFilteredTransactions(processedTransactions); // Initially set filtered to all transactions
                setAddressItemsMap(data?.data?.address_items_map);
            },
        },
    });

    // Log the pagination cursors to verify they're being set correctly
    useEffect(() => {
        console.log("Updated initialPaginationCursors:", initialPaginationCursors);
    }, [initialPaginationCursors]);

    // Update filtered transactions when main transactions change
    useEffect(() => {
        // Ensure unique transactions by chain_id and hash
        const uniqueTransactions = filteredTransactions?.reduce((acc, tx) => {
            const key = `${tx.chain_id}_${tx.hash}`;
            acc[key] = tx;
            return acc;
        }, {} as Record<string, any>);

        setFilteredTransactions(Object.values(uniqueTransactions || []));
    }, [transactions]);

 
    const { status, isLeader, controls} = useSubscriptions({
        channels: [
            {
                name: `${org_id}-${tenant_id}-${user_id}-streamed-transactions`,
                onMessage: (message) => {
                    // Broadcast messages have a channel property
                    if (message.data?.channel) {
                        message = message.data;
                    }   
                                        
                    const transaction = message.data;
                    notification.open({
                        message: 'New transaction',
                        description: `New transaction: ${transaction.hash}`
                    });
                    
                    setTransactions(prev => {
                        // Create unique key for transaction
                        const txKey = `${transaction.chain_id}_${transaction.hash}`;
                        
                        // Find if transaction already exists
                        const existingIndex = prev.findIndex(tx => 
                            `${tx.chain_id}_${tx.hash}` === txKey
                        );

                        if (existingIndex >= 0) {
                            // Replace existing transaction
                            const updated = [...prev];
                            updated[existingIndex] = {
                                ...prev[existingIndex],
                                ...transaction,
                                updatedAt: new Date().toISOString()
                            };
                            return updated;
                        } else {
                            // Add new transaction
                            return [...prev, transaction];
                        }
                    });
                }
            },
            // You can add more channels here
            {
                name: `${org_id}-${tenant_id}-${user_id}-address-watch`,
                onMessage: (message) => {
                    // Handle address updates
                    console.log('Address update:', message.data);
                }
            }
        ],
        token: token.__raw,
        orgId: org_id,
        tenantId: tenant_id,
        userId: user_id,
        debug: createDebugger({
            prefix: 'Subscriptions',
            tabId: `Tab-${Math.random().toString(36).substr(2, 5)}`
        }),
        // Optional global message handler
        onMessage: (message: any) => {
            console.log(`Message from ${message.channel}:`, message.data);
        },
        // Optional error handler
        onError: (error: any) => {
            console.error('Subscription error:', error);
        }
    });
    
    const handleSearch = useCallback((query: SearchQuery | null) => {
        console.log(query)
        if (!query) return;
        setSearchError(null); // Clear any previous errors
        setSearchQuery(prev => query);
    }, []);

    useEffect(() => {
        if (searchQuery && searchQuery.value) {
            refetch(); // Fetch based on the updated searchQuery
        }
    }, [searchQuery?.value, refetch]);

    // Import Transaction Modal
    const {
        modalProps: importModalProps,
        formProps: importFormProps,
        show: showImportModal
    } = useModalForm({
        action: "create",
        resource: "transactions-import",
        onMutationSuccess: () => {
            // Refresh transaction list after successful import
            handleSearch(searchQuery);
            refetch();
        }
    });
    
    // Handle search type change
    const handleSearchTypeChange = (type: string) => {
        console.log(type)
        if (type === "user") {
            // Reset search input
            setSearchInputValue('');
            // Set query for user's transactions
            handleSearch({
                type: "USER",
                value: user_id as string
            });
        } else {
            // Reset search query and enable search input
            setSearchInputValue('');
            setSearchQuery(prev => ({ ...prev, type } as SearchQuery));
        }
    };

    // Memoized chain options for import modal
    const chainOptions = useMemo(() => 
        Object.entries(CHAIN_MAPPING).map(([value, chain]) => ({
            label: (
                <Space>
                    <ChainAvatar chainId={value} size="small" />
                    {chain}
                </Space>
            ),
            value
        }))
    , []);

    return (
        <List
            headerButtons={() => (
                <Space>
                    {/* Search Form */}
                    <Form layout="inline">
                        <Form.Item>
                            <Select
                                style={{ width: 250 }}
                                placeholder="Search by"
                                onChange={handleSearchTypeChange}
                                options={Object.entries(SEARCH_OPTIONS).map(([key, value]) => ({
                                    label: value.description,
                                    value: value.value
                                }))}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 350 }}>
                        <Tooltip 
                                title={searchQuery ? SEARCH_OPTIONS[searchQuery.type]?.description : ''}
                                placement="topLeft"
                            >
                                {searchQuery?.type.toLowerCase() === 'status' ? (
                                    <Select 
                                        style={{ width: '100%' }}
                                        options={VALID_STATUSES}
                                        placeholder="Select status"
                                        value={searchInputValue}
                                        onChange={(value) => {
                                            // First update the input value
                                            setSearchInputValue(value);
                                            // Use the new value directly in the search handler
                                            if (searchQuery?.type) {
                                                handleSearch({ 
                                                    ...searchQuery, 
                                                    value: value // Use the new value directly instead of depending on state
                                                });
                                            }
                                        }}                                            
                                    />
                                ) : searchQuery?.type.toLowerCase() === 'chain' ? (
                                    <Select 
                                        style={{ width: '100%' }}
                                        placeholder="Select chain"
                                        value={searchInputValue}
                                        onChange={(value) => {
                                            // First update the input value
                                            setSearchInputValue(value);
                                            // Use the new value directly in the search handler
                                            if (searchQuery?.type) {
                                                handleSearch({ 
                                                    ...searchQuery, 
                                                    value: value // Use the new value directly instead of depending on state
                                                });
                                            }
                                        }}                                        
                                        options={Object.entries(CHAIN_CONFIG).map(([chainId, config]) => ({
                                            label: (
                                                <Space>
                                                    <Avatar 
                                                        size="small" 
                                                        src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                                                    />
                                                    {config.name}
                                                </Space>
                                            ),
                                            value: chainId
                                        }))}
                                    />
                                ) : (
                                    <Input.Search
                                        disabled={searchQuery?.type === "USER"}
                                        value={searchInputValue}
                                        onChange={(e) => setSearchInputValue(e.target.value)}
                                        placeholder={searchQuery ? SEARCH_OPTIONS[searchQuery.type]?.description : 'Enter search value...'}
                                        loading={isLoading || isFetching || isRefetching}
                                        onSearch={(value) => {
                                            if (searchQuery?.type && value && searchQuery.type !== "USER") {
                                                handleSearch({ ...searchQuery, value });
                                            }
                                        }}
                                        enterButton
                                    />
                                )}
                            </Tooltip>
                        </Form.Item>
                    </Form>

                    <Link to="/advanced-search">
                        <Button icon={<FilterOutlined/>}>
                            Advanced Search
                        </Button>
                    </Link>

                    <Button 
                        type="primary"
                        icon={<ImportOutlined/>}
                        onClick={() => showImportModal()}
                    >
                        Import Transaction
                    </Button>
                </Space>
            )}
        >
            
            {/* Error Alert */}
            {searchError && (
                <Alert
                    message="Search Error"
                    description={searchError}
                    type="error"
                    showIcon
                    style={{ marginBottom: 16 }}
                />
            )}

            {/* Transactions Table */}
            <Card>
                <TransactionTable
                    tableId="transactions"
                    resource="transactions"
                    searchAddress={''}
                    initialPaginationCursors={initialPaginationCursors}
                    address_items_map={addressItemsMap}
                    activeChains={[]}
                    selectedChains={[]}
                    identifier={'transactions'}
                    transactions={transactions}
                    setTransactions={setTransactions}
                    setGraphKey={setGraphKey}
                    onTransactionDataChange={setFilteredTransactions}
                    isLoading={isLoading || isFetching || isRefetching}
                    showTransactionControlPanel={false}
                />             
            </Card>
            <Card>
                <TransactionGraph 
                    data={transactions}
                    filteredData={filteredTransactions}
                    chainIdToName={CHAIN_MAPPING}
                    graphKey={graphKey}
                    setGraphKey={setGraphKey}
                    cdn_domain_name={process.env.REACT_APP_CDN_DOMAIN_NAME || ''}
                    address_items_map={addressItemsMap}
                    subscriptionStatus={{
                        isEnabled: true,
                        isLeader: isLeader,
                        orgId: org_id,
                        tenantId: tenant_id,
                        userId: user_id,
                        addresses: [ /* your watched addresses */],
                        state: status[`${org_id}-${tenant_id}-${user_id}-streamed-transactions`]?.state || 'disconnected',
                    }}
                    options={{
                        showReplay: true,
                        showLive: true,
                        showTimeRange: true,
                        showSettings: true,
                        showShare: true
                    }}
                />
            </Card>
            {/* Import Modal */}
            <Modal 
                {...importModalProps} 
                title="Import Transaction"
                width={600}           
            >
                <Form {...importFormProps} layout="vertical"
                        initialValues={{
                        import_type: 'simple',
                        force_update: false,
                        create_address_labels: true,
                    }}     
                >
                    <Form.Item
                        label="Transaction Hash"
                        name="hash"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="0x..." />
                    </Form.Item>

                    <Form.Item
                        label="Chain"
                        name="chain_id"
                        rules={[{ required: true }]}
                    >
                        <Select
                            showSearch
                            options={chainOptions}
                            placeholder="Select chain"
                            optionFilterProp="children"
                            filterOption={(input, option) => 
                                option?.label.props.children[1].toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="import_type"
                        label="Import Type"
                    >
                        <Radio.Group>
                        <Space direction="vertical">
                            <Radio value="simple">
                            Simple Import
                            <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                                Quick import with basic processing (recommended for simple transactions)
                            </Typography.Text>
                            </Radio>
                            <Radio value="advanced">
                            Advanced Import
                            <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                                Detailed import with full data processing and validation
                            </Typography.Text>
                            </Radio>
                        </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="force_update"
                        valuePropName="checked"
                    >
                        <Checkbox>
                        Force Update
                        <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                            Overwrite existing transaction data
                        </Typography.Text>
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="create_address_labels"
                        valuePropName="checked"
                    >
                        <Checkbox>
                        Create Address Labels
                        <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                            Automatically generate labels for addresses
                        </Typography.Text>
                        </Checkbox>
                    </Form.Item>
                </Form>     
            </Modal>
        </List>
    );
};

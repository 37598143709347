export const chainColors: Record<string, { fill: string; stroke: string }> = {
    // Mainnets
    "0x1": {  // Ethereum
        fill: 'rgba(98, 126, 234, 0.1)',
        stroke: 'rgb(98, 126, 234)'
    },
    "0x89": {  // Polygon
        fill: 'rgba(130, 71, 229, 0.1)',
        stroke: 'rgb(130, 71, 229)'
    },
    "0x38": {  // BSC
        fill: 'rgba(240, 185, 11, 0.1)',
        stroke: 'rgb(240, 185, 11)'
    },
    "0xa86a": {  // Avalanche
        fill: 'rgba(232, 65, 66, 0.1)',
        stroke: 'rgb(232, 65, 66)'
    },
    "0xa": {  // Optimism
        fill: 'rgba(255, 4, 32, 0.1)',
        stroke: 'rgb(255, 4, 32)'
    },
    "0xa4b1": {  // Arbitrum
        fill: 'rgba(40, 160, 240, 0.1)',
        stroke: 'rgb(40, 160, 240)'
    },
    "0x64": {  // Gnosis
        fill: 'rgba(73, 169, 166, 0.1)',
        stroke: 'rgb(73, 169, 166)'
    },
    "0xfa": {  // Fantom
        fill: 'rgba(19, 181, 236, 0.1)',
        stroke: 'rgb(19, 181, 236)'
    },
    "0xa4ec": {  // Celo
        fill: 'rgba(53, 208, 127, 0.1)',
        stroke: 'rgb(53, 208, 127)'
    },
    "0x504": {  // Moonbeam
        fill: 'rgba(83, 77, 242, 0.1)',
        stroke: 'rgb(83, 77, 242)'
    },
    "0x505": {  // Moonriver
        fill: 'rgba(195, 58, 161, 0.1)',
        stroke: 'rgb(195, 58, 161)'
    },
    "0x19": {  // Cronos
        fill: 'rgba(18, 25, 38, 0.1)',
        stroke: 'rgb(18, 25, 38)'
    },
    "0x63564c40": {  // Harmony
        fill: 'rgba(0, 217, 187, 0.1)',
        stroke: 'rgb(0, 217, 187)'
    },
    "0x2019": {  // Klaytn
        fill: 'rgba(255, 102, 0, 0.1)',
        stroke: 'rgb(255, 102, 0)'
    },
    "0x8ae": {  // Kava
        fill: 'rgba(255, 87, 54, 0.1)',
        stroke: 'rgb(255, 87, 54)'
    },
    "0x144": {  // zkSync Era
        fill: 'rgba(140, 100, 255, 0.1)',
        stroke: 'rgb(140, 100, 255)'
    },
    "0xe708": {  // Linea
        fill: 'rgba(0, 0, 0, 0.1)',
        stroke: 'rgb(0, 0, 0)'
    },
    "0x2105": {  // Base
        fill: 'rgba(0, 82, 255, 0.1)',
        stroke: 'rgb(0, 82, 255)'
    },
    "0x82750": {  // Scroll
        fill: 'rgba(68, 146, 255, 0.1)',
        stroke: 'rgb(68, 146, 255)'
    },

    // Testnets
    "0x5": {  // Goerli (ETH)
        fill: 'rgba(98, 126, 234, 0.05)',
        stroke: 'rgba(98, 126, 234, 0.7)'
    },
    "0xaa36a7": {  // Sepolia (ETH)
        fill: 'rgba(98, 126, 234, 0.05)',
        stroke: 'rgba(98, 126, 234, 0.7)'
    },
    "0x13881": {  // Mumbai (Polygon)
        fill: 'rgba(130, 71, 229, 0.05)',
        stroke: 'rgba(130, 71, 229, 0.7)'
    },
    "0x61": {  // BSC Testnet
        fill: 'rgba(240, 185, 11, 0.05)',
        stroke: 'rgba(240, 185, 11, 0.7)'
    },
    "0xa869": {  // Avalanche Fuji
        fill: 'rgba(232, 65, 66, 0.05)',
        stroke: 'rgba(232, 65, 66, 0.7)'
    },
    "0x1a4": {  // Optimism Goerli
        fill: 'rgba(255, 4, 32, 0.05)',
        stroke: 'rgba(255, 4, 32, 0.7)'
    },
    "0x66eed": {  // Arbitrum Goerli
        fill: 'rgba(40, 160, 240, 0.05)',
        stroke: 'rgba(40, 160, 240, 0.7)'
    },
    "0xfa2": {  // Fantom Testnet
        fill: 'rgba(19, 181, 236, 0.05)',
        stroke: 'rgba(19, 181, 236, 0.7)'
    },
    "0xaef3": {  // Celo Alfajores
        fill: 'rgba(53, 208, 127, 0.05)',
        stroke: 'rgba(53, 208, 127, 0.7)'
    },
    "0x507": {  // Moonbase Alpha
        fill: 'rgba(83, 77, 242, 0.05)',
        stroke: 'rgba(83, 77, 242, 0.7)'
    },
    "0x152": {  // Cronos Testnet
        fill: 'rgba(18, 25, 38, 0.05)',
        stroke: 'rgba(18, 25, 38, 0.7)'
    },
    "0x118": {  // zkSync Era Testnet
        fill: 'rgba(140, 100, 255, 0.05)',
        stroke: 'rgba(140, 100, 255, 0.7)'
    },
    "0xe704": {  // Linea Testnet
        fill: 'rgba(0, 0, 0, 0.05)',
        stroke: 'rgba(0, 0, 0, 0.7)'
    },
    "0x14a33": {  // Base Goerli
        fill: 'rgba(0, 82, 255, 0.05)',
        stroke: 'rgba(0, 82, 255, 0.7)'
    },
    "0x8274f": {  // Scroll Sepolia
        fill: 'rgba(68, 146, 255, 0.05)',
        stroke: 'rgba(68, 146, 255, 0.7)'
    },

    // Additional Mainnets
    "0x4268": {  // Ethereum Holesky
        fill: 'rgba(98, 126, 234, 0.1)',
        stroke: 'rgb(98, 126, 234)'
    },
    "0x44d": {  // Polygon zkEVM
        fill: 'rgba(130, 71, 229, 0.1)',
        stroke: 'rgb(130, 71, 229)'
    },
    "0x1388": {  // Mantle
        fill: 'rgba(0, 200, 206, 0.1)',
        stroke: 'rgb(0, 200, 206)'
    },
    "0xcc": {  // opBNB
        fill: 'rgba(240, 185, 11, 0.1)',
        stroke: 'rgb(240, 185, 11)'
    },
    "0x1b58": {  // Zetachain
        fill: 'rgba(33, 242, 159, 0.1)',
        stroke: 'rgb(33, 242, 159)'
    },
    "0x2eb": {  // Flow
        fill: 'rgba(0, 239, 139, 0.1)',
        stroke: 'rgb(0, 239, 139)'
    },
    "0x7e4": {  // Ronin
        fill: 'rgba(47, 172, 255, 0.1)',
        stroke: 'rgb(47, 172, 255)'
    },
    "0x46f": {  // Lisk
        fill: 'rgba(2, 195, 204, 0.1)',
        stroke: 'rgb(2, 195, 204)'
    },
    "0x171": {  // Pulsechain
        fill: 'rgba(255, 88, 96, 0.1)',
        stroke: 'rgb(255, 88, 96)'
    },
    "0x15b38": {  // Chiliz
        fill: 'rgba(236, 24, 84, 0.1)',
        stroke: 'rgb(236, 24, 84)'
    },
    "0x13e31": {  // Blast
        fill: 'rgba(105, 76, 255, 0.1)',
        stroke: 'rgb(105, 76, 255)'
    },

    // Additional Testnets
    "0x13882": {  // Polygon Amoy
        fill: 'rgba(130, 71, 229, 0.05)',
        stroke: 'rgba(130, 71, 229, 0.7)'
    },
    "0x66eee": {  // Arbitrum Sepolia
        fill: 'rgba(40, 160, 240, 0.05)',
        stroke: 'rgba(40, 160, 240, 0.7)'
    },
    "0x14a34": {  // Base Sepolia
        fill: 'rgba(0, 82, 255, 0.05)',
        stroke: 'rgba(0, 82, 255, 0.7)'
    },
    "0xaa37dc": {  // Optimism Sepolia
        fill: 'rgba(255, 4, 32, 0.05)',
        stroke: 'rgba(255, 4, 32, 0.7)'
    },
    "0xe705": {  // Linea Sepolia
        fill: 'rgba(0, 0, 0, 0.05)',
        stroke: 'rgba(0, 0, 0, 0.7)'
    },
    "0x27d8": {  // Gnosis Chiado
        fill: 'rgba(73, 169, 166, 0.05)',
        stroke: 'rgba(73, 169, 166, 0.7)'
    },
    "0x15b32": {  // Chiliz Testnet
        fill: 'rgba(236, 24, 84, 0.05)',
        stroke: 'rgba(236, 24, 84, 0.7)'
    },
    "0xa0c71fd": {  // Blast Sepolia
        fill: 'rgba(105, 76, 255, 0.05)',
        stroke: 'rgba(105, 76, 255, 0.7)'
    },
    "0x12c": {  // zkSync Sepolia
        fill: 'rgba(140, 100, 255, 0.05)',
        stroke: 'rgba(140, 100, 255, 0.7)'
    },
    "0x138b": {  // Mantle Sepolia
        fill: 'rgba(0, 200, 206, 0.05)',
        stroke: 'rgba(0, 200, 206, 0.7)'
    },
    "0x98a": {  // Polygon zkEVM Cardona
        fill: 'rgba(130, 71, 229, 0.05)',
        stroke: 'rgba(130, 71, 229, 0.7)'
    },
    "0x1b59": {  // Zetachain Testnet
        fill: 'rgba(33, 242, 159, 0.05)',
        stroke: 'rgba(33, 242, 159, 0.7)'
    },
    "0x221": {  // Flow Testnet
        fill: 'rgba(0, 239, 139, 0.05)',
        stroke: 'rgba(0, 239, 139, 0.7)'
    },
    "0x7e5": {  // Ronin Saigon Testnet
        fill: 'rgba(47, 172, 255, 0.05)',
        stroke: 'rgba(47, 172, 255, 0.7)'
    },
    "0x106a": {  // Lisk Sepolia
        fill: 'rgba(2, 195, 204, 0.05)',
        stroke: 'rgba(2, 195, 204, 0.7)'
    }
};

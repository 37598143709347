import { useState, useEffect } from 'react';

const DEFAULT_COLUMNS = [
    'chain_id',
    'method_label',
    'from_address',
    'to_address',
    'value',
    'block_timestamp',
    'found_in',
    'actions'
];

export const useColumnSettings = (user: any, tableId: string) => {
    const local_storage_prefix = "DASHBOARD-" + 
        process.env.REACT_APP_ENV + "-" + 
        user?.sub + "-" + 
        user?.[process.env.REACT_APP_BASE_URL + "/org_id"] + "-" + 
        user?.[process.env.REACT_APP_BASE_URL + "/tenant_id"];

    const storageKey = `${local_storage_prefix}-${tableId}-columns`;
    
    const [visibleColumns, setVisibleColumns] = useState<string[]>(() => {
        const savedColumns = localStorage.getItem(storageKey);
        return savedColumns ? JSON.parse(savedColumns) : DEFAULT_COLUMNS;
    });

    useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(visibleColumns));
    }, [visibleColumns, storageKey]);

    return {
        visibleColumns,
        setVisibleColumns,
    };
}; 
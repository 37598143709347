import { Modal, Descriptions, Space, Avatar, Tag, Typography, Image, Card } from 'antd';
import { INFT } from "../../../interfaces/address.d";
import { ChainAvatar } from './StatsTab';
import { shortenAddress } from 'utils/formatters';
import { CHAIN_MAPPING } from 'interfaces/chain.d';
import { NFTMediaViewer } from './NFTMediaViewer';

const { Text, Link } = Typography;

interface NFTDetailModalProps {
    visible: boolean;
    onClose: () => void;
    nft: INFT;
    chainId: string;
}

export const NFTDetailModal: React.FC<NFTDetailModalProps> = ({
    visible,
    onClose,
    nft,
    chainId,
}) => {
    // Parse metadata if it exists
    const metadata = nft.metadata ? JSON.parse(nft.metadata) : null;
    
    return (
        <Modal
            title={
                <Space>
                    <Avatar src={nft.media || metadata?.image} />
                    <span>{metadata?.name || nft.name} {nft.token_id && `#${nft.token_id}`}</span>
                    <ChainAvatar chainId={chainId} size="small" />
                    {nft.contract_type && <Tag color="blue">{nft.contract_type}</Tag>}
                </Space>
            }
            open={visible}
            onCancel={onClose}
            width={800}
            footer={null}
        >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
                {/* NFT Image */}
                <NFTMediaViewer
                    staticImage={nft.media || metadata?.image}
                    animatedUrl={metadata?.animation_url}
                    name={metadata?.name || nft.name}
                    style={{ maxWidth: '100%' }}
                />

                {/* Description from metadata */}
                {metadata?.description && (
                    <Text type="secondary">{metadata.description}</Text>
                )}

                {/* Basic NFT Information */}
                <Descriptions bordered column={2}>
                    <Descriptions.Item label="Collection">
                        {nft.name}
                        {nft.verified_collection && (
                            <Tag color="green" style={{ marginLeft: 8 }}>Verified</Tag>
                        )}
                        {nft.possible_spam && (
                            <Tag color="red" style={{ marginLeft: 8 }}>Possible Spam</Tag>
                        )}
                    </Descriptions.Item>
                    
                    <Descriptions.Item label="Token ID">
                        {nft.token_id}
                    </Descriptions.Item>

                    {nft.owner_of && (
                        <Descriptions.Item label="Owner">
                            <Link href={`/addresses/${nft.owner_of}`} target="_blank">
                                {shortenAddress(nft.owner_of)}
                            </Link>
                        </Descriptions.Item>
                    )}

                    {nft.minter_address && nft.minter_address !== "ERC1155 tokens don't have a single minter" && (
                        <Descriptions.Item label="Minter">
                            <Link href={`/addresses/${nft.minter_address}`} target="_blank">
                                {shortenAddress(nft.minter_address)}
                            </Link>
                        </Descriptions.Item>
                    )}

                    {nft.contract_type && (
                        <Descriptions.Item label="Contract Type">
                            {nft.contract_type}
                        </Descriptions.Item>
                    )}

                    {nft.amount && nft.amount !== "1" && (
                        <Descriptions.Item label="Amount">
                            {nft.amount}
                        </Descriptions.Item>
                    )}
                </Descriptions>

                {/* Rarity Information */}
                {(nft.rarity_rank || nft.rarity_percentage || nft.rarity_label) && (
                    <Card title="Rarity">
                        <Descriptions bordered column={2}>
                            {nft.rarity_rank && (
                                <Descriptions.Item label="Rank">
                                    #{nft.rarity_rank}
                                </Descriptions.Item>
                            )}
                            {nft.rarity_percentage && (
                                <Descriptions.Item label="Percentage">
                                    {nft.rarity_percentage}%
                                </Descriptions.Item>
                            )}
                            {nft.rarity_label && (
                                <Descriptions.Item label="Label">
                                    <Tag color="blue">{nft.rarity_label}</Tag>
                                </Descriptions.Item>
                            )}
                        </Descriptions>
                    </Card>
                )}

                {/* Floor Price Information */}
                {(nft.floor_price || nft.floor_price_usd) && (
                    <Card title="Floor Price">
                        <Descriptions bordered column={2}>
                            {nft.floor_price && nft.floor_price_currency && (
                                <Descriptions.Item label={`Price (${nft.floor_price_currency})`}>
                                    {nft.floor_price}
                                </Descriptions.Item>
                            )}
                            {nft.floor_price_usd && (
                                <Descriptions.Item label="USD Value">
                                    ${Number(nft.floor_price_usd).toLocaleString()}
                                </Descriptions.Item>
                            )}
                        </Descriptions>
                    </Card>
                )}

                {/* Last Sale Information */}
                {nft.last_sale && (
                    <Card title="Last Sale">
                        <Descriptions bordered column={2}>
                            {nft.last_sale.price_formatted && nft.last_sale.payment_token?.token_symbol && (
                                <Descriptions.Item label="Price">
                                    {nft.last_sale.price_formatted} {nft.last_sale.payment_token.token_symbol}
                                </Descriptions.Item>
                            )}
                            {nft.last_sale.usd_price_at_sale && (
                                <Descriptions.Item label="USD Value">
                                    ${Number(nft.last_sale.usd_price_at_sale).toLocaleString()}
                                </Descriptions.Item>
                            )}
                            {nft.last_sale.buyer_address && (
                                <Descriptions.Item label="Buyer">
                                    <Link href={`/addresses/${nft.last_sale.buyer_address}`} target="_blank">
                                        {shortenAddress(nft.last_sale.buyer_address)}
                                    </Link>
                                </Descriptions.Item>
                            )}
                            {nft.last_sale.seller_address && (
                                <Descriptions.Item label="Seller">
                                    <Link href={`/addresses/${nft.last_sale.seller_address}`} target="_blank">
                                        {shortenAddress(nft.last_sale.seller_address)}
                                    </Link>
                                </Descriptions.Item>
                            )}
                        </Descriptions>
                    </Card>
                )}
            </Space>
        </Modal>
    );
};


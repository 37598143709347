import { GraphNode, GraphLink } from 'interfaces/graph';

// Function to process proxy calls in transactions
export const processProxyCall = (
    tx: any,
    createLink: any
): { nodes: string[], links: GraphLink[] } => {
    const nodes: string[] = [];
    const links: GraphLink[] = [];
    
    if (!tx.decoded_transaction?.decoded_call || !tx.decoded_transaction?.decoded_call.params) {
        return { nodes, links };
    }
    
    const chainId = tx.chain_id;
    const fromAddress = tx.from_address.toLowerCase();
    
    // Find the proxy call parameter (usually in a bytes parameter with is_proxy_call flag)
    const proxyParam = tx.decoded_transaction?.decoded_call.params.find((param: any) => 
        param.type === 'bytes' && 
        typeof param.value === 'object' && 
        param.value.is_proxy_call
    );
    
    if (!proxyParam) {
        return { nodes, links };
    }
    
    const proxyData = proxyParam.value;
    const proxyTarget = proxyData.address.toLowerCase();
    
    // Add nodes to our collection
    const addNodeToCollection = (address: string) => {
        if (address && !nodes.includes(address.toLowerCase())) {
            nodes.push(address.toLowerCase());
        }
    };
    
    // 1. Create a link from the sender to the contract being called
    if (fromAddress && tx.decoded_transaction?.decoded_call.address) {
        const link = createLink(
            tx,
            "contract_call",
            `${fromAddress.toLowerCase()}`,
            `${tx.decoded_transaction?.decoded_call.address.toLowerCase()}`,
            chainId
        );
        links.push(link);
        
        // Add nodes to our collection
        addNodeToCollection(fromAddress.toLowerCase());
        addNodeToCollection(tx.decoded_transaction?.decoded_call.address.toLowerCase());
    }
    
    // 2. Create a link from the contract to the proxy target (implementation)
    if (tx.decoded_transaction?.decoded_call.address && proxyTarget) {
        const link = createLink(
            tx,
            "proxy_implementation",
            `${tx.decoded_transaction?.decoded_call.address.toLowerCase()}`,
            `${proxyTarget.toLowerCase()}`,
            chainId
        );
        links.push(link);
        
        // Add node to our collection
        addNodeToCollection(proxyTarget.toLowerCase());
    }
    
    // 3. Process addresses in proxy parameters
    if (proxyData.params && Array.isArray(proxyData.params)) {
        proxyData.params.forEach((param: any) => {
            // Handle direct address parameters
            if (param.type === 'address' && param.value && param.value !== '0x0000000000000000000000000000000000000000') {
                const paramAddress = param.value;
                
                // Create a link from the proxy target to this address
                const link = createLink(
                    tx,
                    "proxy_param",
                    `${proxyTarget.toLowerCase()}`,
                    `${paramAddress.toLowerCase()}`,
                    chainId,
                );
                links.push(link);
                
                // Add node to our collection
                addNodeToCollection(paramAddress.toLowerCase());
            }
            
            // Handle address arrays
            if (param.type === 'address[]' && Array.isArray(param.value)) {
                param.value.forEach((addr: string, idx: number) => {
                    if (addr && addr !== '0x0000000000000000000000000000000000000000') {
                        // Create a link from the proxy target to this address
                        const link = createLink(
                            tx,
                            "proxy_param",
                            `${proxyTarget.toLowerCase()}`,
                            `${addr.toLowerCase()}`,
                            chainId,
                        );
                        links.push(link);
                        
                        // Add node to our collection
                        addNodeToCollection(addr.toLowerCase());
                    }
                });
            }
        });
    }
    
    // 4. If there's a created proxy address in the events, link it
    const proxyCreationEvent = tx.decoded_transaction?.decoded_events && 
        Object.values(tx.decoded_transaction.decoded_events).find((event: any) => 
            event.decoded_event?.label === 'ProxyCreation' || 
            event.decoded_event?.name === 'ProxyCreation'
        );
    
    if (proxyCreationEvent) {
        const proxyAddressParam = proxyCreationEvent.decoded_event?.params?.find((param: any) => 
            param.name === 'proxy' && param.type === 'address'
        );
        
        if (proxyAddressParam && proxyAddressParam.value) {
            const proxyAddress = proxyAddressParam.value;
            
            // Create a link from the contract to the created proxy
            const proxy_link_event = createLink(    
                tx,
                "proxy_creation",
                `${tx.decoded_transaction?.decoded_call.address.toLowerCase()}`,
                `${proxyAddress.toLowerCase()}`,
                chainId
            );
            links.push(proxy_link_event);
            
            // Add node to our collection
            addNodeToCollection(proxyAddress.toLowerCase());
            
            // Create links from the proxy to its owners
            const ownersParam = proxyData.params?.find((param: any) => 
                param.name === '_owners' && param.type === 'address[]'
            );
            
            if (ownersParam && Array.isArray(ownersParam.value)) {
                ownersParam.value.forEach((ownerAddr: string, idx: number) => {
                    if (ownerAddr && ownerAddr !== '0x0000000000000000000000000000000000000000') {
                        // Create a link from the proxy to this owner
                        const link = createLink(
                            tx,
                            "proxy_owner",
                            `${proxyAddress.toLowerCase()}`,
                            `${ownerAddr.toLowerCase()}`,
                            chainId
                        );
                        links.push(link);
                        
                        // Add node to our collection
                        addNodeToCollection(ownerAddr.toLowerCase());
                    }
                });
            }
            
            // Link proxy to implementation
            const link = createLink(
                tx,
                "proxy_implementation",
                `${proxyAddress.toLowerCase()}`,
                `${proxyTarget.toLowerCase()}`,
                chainId
            );
            links.push(link);
        }
    }
    
    return { nodes, links };
}; 
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const subscribe = /* GraphQL */ `subscription Subscribe($name: String!) {
  subscribe(name: $name) {
    data
    name
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeSubscriptionVariables,
  APITypes.SubscribeSubscription
>;

// Define each query/subscription separately
export const SUBSCRIBE = /* GraphQL */ `
    subscription Subscribe($name: String!) {
        subscribe(name: $name) {
            data
            name
        }
    }
`;

export const PING = /* GraphQL */ `
    query Ping {
        ping {
            timestamp
            status
        }
    }
`;

// Export as a group if needed
export const subscriptions = {
    subscribe: SUBSCRIBE,
    ping: PING
} as const;
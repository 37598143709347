import { MutableRefObject } from 'react';
import { Debug } from '../leader/debug';
import { PING } from '../../graphql/subscriptions';
import { Client } from '@aws-amplify/api';
import { SubscriptionStatus } from '../../graphql/types';

interface HeartbeatOptions {
    token: string;
    client: Client;
    debug: {
        log: (message: string, ...args: any[]) => void;
        error: (message: string, ...args: any[]) => void;
    };
    isLeaderRef: { current: boolean };
    reconnectingRef: { current: boolean };
    subscriptionsRef: { current: { [key: string]: { unsubscribe: () => void } | null } };
    setupSubscription: (channelName: string, attempt: number) => Promise<any>;
}

const HEARTBEAT_INTERVAL = 60000; // 60 seconds
const STALE_THRESHOLD = 120000;   // 2 minutes

export const setupHeartbeat = (
    status: { [key: string]: SubscriptionStatus },
    options: HeartbeatOptions
) => {
    const {
        token,
        client,
        debug,
        isLeaderRef,
        reconnectingRef,
        subscriptionsRef,
        setupSubscription
    } = options;

    const lastPingRef = { current: new Date() };
    const lastPongRef = { current: new Date() };

    debug.log('🔄 Setting up heartbeat monitor');
    
    // Setup periodic ping using the subscribe query
    const pingInterval = setInterval(async () => {
        if (!isLeaderRef.current || reconnectingRef.current) {
            debug.log('Skipping heartbeat - not leader or reconnecting');
            return;
        }

        lastPingRef.current = new Date();
        debug.log('💓 Sending heartbeat ping');
        
        try {
            // Use async/await with proper typing
            const result = await client.graphql({
                query: PING,
                authToken: `Bearer ${token}`
            });

            lastPongRef.current = new Date();
            debug.log('✅ Heartbeat ping successful', {
                lastPing: lastPingRef.current,
                lastPong: lastPongRef.current,
                result
            });

            // Check for stale connections
            Object.entries(status).forEach(([channel, channelStatus]) => {
                const lastMessage = channelStatus.lastMessageAt;
                const timeSinceLastMessage = lastMessage ? 
                    new Date().getTime() - new Date(lastMessage).getTime() : 
                    Infinity;

                if (timeSinceLastMessage > STALE_THRESHOLD) {
                    debug.log(`⚠️ Connection appears stale for channel: ${channel} last message at: ${lastMessage}`);
                    reconnectingRef.current = true;
                    
                    if (subscriptionsRef.current[channel]) {
                        debug.log(`🔄 Reconnecting stale channel: ${channel}`);
                        subscriptionsRef.current[channel]?.unsubscribe();
                    }
                    
                    setTimeout(() => {
                        if (isLeaderRef.current) {
                            setupSubscription(channel, 0);
                        }
                        reconnectingRef.current = false;
                    }, 1000);
                }
            });
        } catch (error) {
            debug.error('❌ Heartbeat ping failed', error);
        }
    }, HEARTBEAT_INTERVAL);

    return () => {
        debug.log('Cleaning up heartbeat monitor');
        clearInterval(pingInterval);
    };
}; 
import { Button, Space, Typography } from 'antd';
import type { TableColumnType } from 'antd';
import type { Transaction } from '../types';
import { ShowButton } from '@refinedev/antd';

export const getActionsColumn = (
    setJsonModalData: (data: Transaction) => void
): TableColumnType<Transaction> => ({
    title: 'Actions',
    key: 'actions',
    width: 200,
    render: (_: any, record: Transaction) => (
        <Typography.Text style={{ whiteSpace: 'nowrap', minWidth: 300,  display: 'inline-block'  }}>
            <Button 
                type="link" 
                onClick={() => setJsonModalData(record)}
            >
                Show Raw Data
            </Button>
            <ShowButton resource="transactions" recordItemId={record?.["user_id-block_timestamp-chain_id-hash-type"] ? record?.["user_id-block_timestamp-chain_id-hash-type"] : `${record?.chain_id}_${record?.hash}`}> Go to Transaction</ShowButton>
        </Typography.Text>
    )
}); 
import React from "react";
import { IResourceComponentsProps, useNotification } from "@refinedev/core";
import { Edit, ListButton, RefreshButton, SaveButton, useForm } from "@refinedev/antd";
import { 
    Form, Card, Space, Tag, Typography, Steps, 
    Select, Divider, Alert, Row, Col, Avatar,
    Badge, Descriptions
} from "antd";
import {
    CloudServerOutlined, GlobalOutlined, TeamOutlined,
    ApiOutlined, SettingOutlined, SaveOutlined
} from '@ant-design/icons';
import { IIntegration } from "interfaces";
import { useNavigate, useParams } from 'react-router-dom';

const { Title, Text } = Typography;
const { Option } = Select;

export const IntegrationEdit: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { open } = useNotification();

    const { 
        formLoading,
        formProps, 
        saveButtonProps, 
        queryResult 
    } = useForm<IIntegration>({
        resource: "integrations",
        action: "edit",
        id: params.id,
        redirect: false
    });

    const record = queryResult?.data?.data?.get_integration_result;
    const [isLoadingNewVersion, setIsLoadingNewVersion] = React.useState<boolean>(false);

    const regionColors = {
        "us-west-2": "green",
        "us-east-1": "magenta",
        "eu-central-1": "blue",
        "eu-west-1": "cyan"
    };

    const handleSave = async () => {
        setIsLoadingNewVersion(true);
        try {
            // Your save logic here
            await saveButtonProps.onClick?.(null as any);
            open?.({
                type: "success",
                message: "Integration updated successfully",
                description: "The changes have been saved",
            });
        } catch (error) {
            open?.({
                type: "error",
                message: "Failed to update integration",
                description: "Please try again",
            });
        } finally {
            setIsLoadingNewVersion(false);
        }
    };

    return (
        <Edit
            isLoading={formLoading}
            saveButtonProps={{
                ...saveButtonProps,
                onClick: handleSave,
                loading: isLoadingNewVersion
            }}
            headerButtons={({ refreshButtonProps }) => (
                <Space>
                    <ListButton />
                    <RefreshButton {...refreshButtonProps} />
                </Space>
            )}
        >
            <Form {...formProps} layout="vertical">
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    {/* Header Card */}
                    <Card>
                        <Row gutter={[16, 16]} align="middle">
                            <Col span={16}>
                                <Space align="start">
                                    <Avatar 
                                        size={64}
                                        src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/integrations/logos/png/${record?.id}.png`}
                                    />
                                    <Space direction="vertical">
                                        <Title level={3} style={{ margin: 0 }}>{record?.name}</Title>
                                        <Space>
                                            <Badge 
                                                status={record?.enabled ? "success" : "default"} 
                                                text={record?.enabled ? "Enabled" : "Disabled"}
                                            />
                                            <Tag color={regionColors[record?.region as keyof typeof regionColors]}>
                                                {record?.region}
                                            </Tag>
                                        </Space>
                                    </Space>
                                </Space>
                            </Col>
                        </Row>
                    </Card>

                    <Row gutter={16}>
                        {/* Left Column - Details */}
                        <Col span={12}>
                            <Card title={
                                <Space>
                                    <TeamOutlined />
                                    <span>Integration Details</span>
                                </Space>
                            }>
                                <Descriptions column={1}>
                                    <Descriptions.Item label="Environment">
                                        <Tag>{record?.environment}</Tag>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Organization ID">
                                        {record?.org_id}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Tenant ID">
                                        {record?.tenant_id}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Created By">
                                        {record?.created_by}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Integration ID">
                                        <Text copyable>{record?.id}</Text>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </Col>

                        {/* Right Column - Configuration */}
                        <Col span={12}>
                            <Card title={
                                <Space>
                                    <SettingOutlined />
                                    <span>Configuration</span>
                                </Space>
                            }>
                                <Form.Item
                                    label="Status"
                                    name="status"
                                    initialValue={record?.status}
                                    rules={[{ required: true }]}
                                >
                                    <Select>
                                        <Option value="active">Active</Option>
                                        <Option value="inactive">Inactive</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Pipeline Stages"
                                    name="integration_stages"
                                    initialValue={record?.integration_stages}
                                    rules={[{ required: true }]}
                                    help="Select the stages where this integration will be executed"
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Select stages"
                                    >
                                        <Option value="pre-process">Pre-process</Option>
                                        <Option value="process">Process</Option>
                                        <Option value="post-process">Post-process</Option>
                                    </Select>
                                </Form.Item>

                                <Steps
                                    progressDot
                                    current={record?.integration_stages?.length}
                                    direction="vertical"
                                    items={[
                                        {
                                            title: 'Pre-process',
                                            description: 'Initial data preparation'
                                        },
                                        {
                                            title: 'Process',
                                            description: 'Main integration logic'
                                        },
                                        {
                                            title: 'Post-process',
                                            description: 'Final data handling'
                                        }
                                    ]}
                                />
                            </Card>
                        </Col>
                    </Row>

                    <Alert
                        message="Configuration Changes"
                        description="Changes to the integration configuration will take effect immediately after saving."
                        type="info"
                        showIcon
                    />
                </Space>
            </Form>
        </Edit>
    );
};


import { useTable } from "@refinedev/core";
import {
    List,
    EditButton,
    ShowButton,
    CreateButton,
    RefreshButton,
    DeleteButton,
} from "@refinedev/antd";
import {
    Table,
    Space,
    Button,
    Tag,
    Typography,
    Tooltip,
    Switch,
    Card,
    Statistic,
    Row,
    Col,
    Empty,
    Dropdown,
    Menu,
    Input,
    Badge,
    Alert,
    Modal,
} from "antd";
import {
    EyeOutlined,
    EditOutlined,
    SettingOutlined,
    GlobalOutlined,
    TwitterOutlined,
    ReloadOutlined,
    FilterOutlined,
    DownloadOutlined,
    UploadOutlined,
    BarsOutlined,
    AppstoreOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { IEntity, EntityType, EntityStatus } from "../../interfaces/entity.d";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const { Text } = Typography;

interface TableSettings {
    pageSize: number;
    visibleColumns: string[];
    sortField?: string;
    sortOrder?: "ascend" | "descend";
}

export const EntityList: React.FC = () => {
    const { user } = useAuth0();
    
    // Create storage prefix
    const local_storage_prefix = "DASHBOARD-" + 
        process.env.REACT_APP_ENV + "-" + 
        user?.[process.env.REACT_APP_BASE_URL + '/org_id'] + "-" + 
        user?.[process.env.REACT_APP_BASE_URL + '/tenant_id'] + "-" + 
        user?.[process.env.REACT_APP_BASE_URL + '/sub'];

    // Local storage for table settings
    const [tableSettings, setTableSettings] = useLocalStorage<TableSettings>(
        `${local_storage_prefix}_entity_list_settings`,
        {
            pageSize: 10,
            visibleColumns: ["name", "type", "status", "risk_score", "actions"],
        }
    );

    const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | undefined>(undefined);

    const {
        tableQueryResult,
        current,
        setCurrent,
        pageSize,
        setPageSize,
        sorters,
        setSorters,
        filters,
        setFilters,
    } = useTable<IEntity>({
        resource: "entities",
        pagination: {
            pageSize: tableSettings.pageSize,
            mode: 'server',
        },
        meta: {
            limit: tableSettings.pageSize,
            last_evaluated_key: lastEvaluatedKey,
            search: undefined,
        },
        sorters: {
            mode: 'server',
        },
        filters: {
            mode: 'server',
        },
    });

    // Handle page size changes
    const handlePageSizeChange = (current: number, size: number) => {
        setLastEvaluatedKey(undefined);  // Reset last_evaluated_key
        setPageSize(size);               // Update page size
    };

    // Create antd table props from useTable return values
    const tableProps = {
        dataSource: tableQueryResult.data?.data || [],
        loading: tableQueryResult.isLoading,
        pagination: {
            total: tableQueryResult.data?.total || 0,
            pageSize: tableSettings.pageSize,
            current: current,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50, 100],
            onChange: (page: number, newPageSize: number) => {
                setCurrent(page);
                if (newPageSize !== tableSettings.pageSize) {
                    setPageSize(newPageSize);
                    // Save page size preference to local storage
                    setTableSettings({
                        ...tableSettings,
                        pageSize: newPageSize,
                    });
                }
            },
            showTotal: (total: number) => `Total ${total} items`,
            onShowSizeChange: handlePageSizeChange
        },
        onChange: (
            pagination: any,
            filters: any,
            sorter: any
        ) => {
            setSorters([{
                field: sorter.field,
                order: sorter.order
            }]);
            setFilters(
                Object.keys(filters).map((key) => ({
                    field: key,
                    operator: "eq",
                    value: filters[key]
                }))
            );
        },
        locale: {
            emptyText: 'No entities found',
        },        
    };

    // Derive statistics from table data
    const stats = {
        total: tableQueryResult.data?.total || 0,
        active: tableQueryResult.data?.data.filter(
            (entity) => entity.status === EntityStatus.ACTIVE
        ).length || 0,
        highRisk: tableQueryResult.data?.data.filter(
            (entity) => (entity.risk_score || 0) >= 75
        ).length || 0,
        verified: tableQueryResult.data?.data.filter(
            (entity) => entity.verified === true
        ).length || 0,
    };

    // Add view mode state
    const [viewMode, setViewMode] = useState<'table' | 'grid'>('table');
    
    // Track selected rows
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    // Add search state
    const [searchText, setSearchText] = useState('');

    // Add column settings modal state
    const [isColumnSettingsVisible, setIsColumnSettingsVisible] = useState(false);

    
    // Move the column toggle handler outside the modal component
    const handleColumnToggle = (columnKey: string, checked: boolean) => {
        const newVisibleColumns = checked
            ? [...tableSettings.visibleColumns, columnKey]
            : tableSettings.visibleColumns.filter(k => k !== columnKey);
        
        setTableSettings({
            ...tableSettings,
            visibleColumns: newVisibleColumns,
        });
    };

    // Move modal component outside the render method
    const renderColumnSettingsModal = () => (
        <Modal
            title="Column Settings"
            open={isColumnSettingsVisible}
            onCancel={() => setIsColumnSettingsVisible(false)}
            onOk={() => setIsColumnSettingsVisible(false)}
        >
            {columns.map(column => (
                <div key={column.key} style={{ marginBottom: 8 }}>
                    <Switch
                        checked={tableSettings.visibleColumns.includes(column.key)}
                        onChange={(checked) => handleColumnToggle(column.key, checked)}
                    /> 
                    <Text style={{ marginLeft: 8 }}>{column.title}</Text>
                </div>
            ))}
        </Modal>
    );

    // Handle row selection changes
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    // Row selection config
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ],
    };

    // Bulk actions menu
    const bulkActionsMenu = (
        <Menu>
            <Menu.Item key="export" icon={<DownloadOutlined />}>
                Export Selected
            </Menu.Item>
            <Menu.Item key="delete" icon={<DeleteOutlined />} danger>
                Delete Selected
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: true,
            filterable: true,
            render: (text: string, record: IEntity) => (
                <Space>
                    {record.logo && (
                        <img
                            src={record.logo}
                            alt={text}
                            style={{ width: 24, height: 24, borderRadius: '50%' }}
                        />
                    )}
                    <Text strong>{text}</Text>
                </Space>
            ),
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            sorter: true,
            filters: Object.values(EntityType).map(type => ({
                text: type,
                value: type,
            })),
            render: (type: EntityType) => (
                <Tag color={getEntityTypeColor(type)}>{type}</Tag>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: Object.values(EntityStatus).map(status => ({
                text: status,
                value: status,
            })),
            render: (status: EntityStatus) => (
                <Tag color={getStatusColor(status)}>{status}</Tag>
            ),
        },
        {
            title: "Risk Score",
            dataIndex: "risk_score",
            key: "risk_score",
            sorter: true,
            render: (score?: number) => score && (
                <Tooltip title={`Risk Level: ${getRiskLevel(score)}`}>
                    <Tag color={getRiskColor(score)}>{score}</Tag>
                </Tooltip>
            ),
        },
        {
            title: "Links",
            key: "links",
            render: (_: any, record: IEntity) => (
                <Space>
                    {record.website && (
                        <Tooltip title="Website">
                            <Button
                                icon={<GlobalOutlined />}
                                href={record.website}
                                target="_blank"
                                type="link"
                            />
                        </Tooltip>
                    )}
                    {record.twitter && (
                        <Tooltip title="Twitter">
                            <Button
                                icon={<TwitterOutlined />}
                                href={`https://twitter.com/${record.twitter}`}
                                target="_blank"
                                type="link"
                            />
                        </Tooltip>
                    )}
                </Space>
            ),
        },
        {
            title: "Actions",
            key: "actions",
            render: (_: any, record: IEntity) => (
                <Space>
                    <ShowButton
                        size="small"
                        recordItemId={record.id}
                        icon={<EyeOutlined />}
                    />
                    <EditButton
                        size="small"
                        recordItemId={record.id}
                        icon={<EditOutlined />}
                    />
                    <DeleteButton
                        size="small"
                        recordItemId={record.id}
                        icon={<DeleteOutlined />}
                    />
                </Space>
            ),
        },
    ];

    // Expandable row configuration
    const expandedRowRender = (record: IEntity) => (
        <Card size="small">
            <Row gutter={16}>
                <Col span={8}>
                    <Statistic
                        title="Related Entities"
                        value={record.related_entity_ids?.length || 0}
                    />
                </Col>
                <Col span={8}>
                    <Statistic
                        title="Child Entities"
                        value={record.child_entity_ids?.length || 0}
                    />
                </Col>
                <Col span={8}>
                    <Statistic
                        title="Last Updated"
                        value={record.updated_at ? new Date(record.updated_at).toLocaleDateString() : 'N/A'}
                    />
                </Col>
            </Row>
            {record.description && (
                <Text type="secondary" style={{ marginTop: 16, display: 'block' }}>
                    {record.description}
                </Text>
            )}
        </Card>
    );

    return (
        <List
            headerButtons={[
                <Input.Search
                    key="search"
                    placeholder="Search entities..."
                    onSearch={value => setSearchText(value)}
                    style={{ width: 200 }}
                />,
                <Button.Group key="viewMode">
                    <Button
                        type={viewMode === 'table' ? 'primary' : 'default'}
                        icon={<BarsOutlined />}
                        onClick={() => setViewMode('table')}
                    />
                    <Button
                        type={viewMode === 'grid' ? 'primary' : 'default'}
                        icon={<AppstoreOutlined />}
                        onClick={() => setViewMode('grid')}
                    />
                </Button.Group>,
                <RefreshButton
                    key="refresh"
                    icon={<ReloadOutlined />}
                    onClick={() => {
                        tableQueryResult.refetch();
                    }}
                    loading={tableQueryResult.isFetching}
                >
                    Refresh
                </RefreshButton>,
                <CreateButton key="create" />,
                <Button
                    key="settings"
                    icon={<SettingOutlined />}
                    onClick={() => {
                        setIsColumnSettingsVisible(true)
                    }}
                >
                    Columns
                </Button>,
            ]}
        >
            <Card>
                {selectedRowKeys.length > 0 && (
                    <Alert
                        message={
                            <Space>
                                <Text>Selected {selectedRowKeys.length} items</Text>
                                <Dropdown overlay={bulkActionsMenu}>
                                    <Button>
                                        Bulk Actions <DownloadOutlined />
                                    </Button>
                                </Dropdown>
                            </Space>
                        }
                        type="info"
                        style={{ marginBottom: 16 }}
                    />
                )}

                <Row gutter={16} style={{ marginBottom: 16 }}>
                    <Col span={6}>
                        <Statistic
                            title="Total Entities"
                            value={stats.total}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic
                            title="Active Entities"
                            value={stats.active}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic
                            title="High Risk Entities"
                            value={stats.highRisk}
                            valueStyle={{ color: '#cf1322' }}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic
                            title="Verified Entities"
                            value={stats.verified}
                            valueStyle={{ color: '#3f8600' }}
                        />
                    </Col>
                </Row>

                {viewMode === 'table' ? (
                    <Table
                        {...tableProps}
                        rowSelection={{
                            ...rowSelection,
                            type: 'checkbox' // Explicitly set type as 'checkbox' instead of string
                        }}
                        rowKey={(record) => record.id}  // Explicitly set the row key function
                        pagination={{
                            ...tableProps.pagination,
                            pageSizeOptions: tableProps.pagination.pageSizeOptions,
                            showSizeChanger: tableProps.pagination.showSizeChanger,
                        }}
                        columns={columns.filter(col => 
                            tableSettings.visibleColumns.includes(col.key)
                        )}
                        locale={{
                            emptyText: 'No entities found',
                        }}
                        expandable={{
                            expandedRowRender,
                            rowExpandable: record => 
                                !!record.description || 
                                (record.related_entity_ids?.length || 0) > 0 ||
                                (record.child_entity_ids?.length || 0) > 0,
                        }}
                        onChange={(pagination, filters, sorter) => {
                            // Handle table changes
                            if (pagination.pageSize !== tableSettings.pageSize) {
                                setTableSettings({
                                    ...tableSettings,
                                    pageSize: pagination.pageSize || 10,
                                });
                            }
                        }}
                        summary={pageData => (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={2}>
                                        <Text strong>
                                            Total Items: {tableQueryResult.data?.total || 0}
                                        </Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>
                                        <Text strong>
                                            Page: {current || 1}
                                        </Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        )}
                    />
                ) : (
                    <Row gutter={[16, 16]}>
                        {(tableQueryResult.data?.data || []).map(entity => (
                            <Col key={entity.id} xs={24} sm={12} md={8} lg={6}>
                                <Card
                                    hoverable
                                    cover={entity.logo && (
                                        <img
                                            alt={entity.name}
                                            src={entity.logo}
                                            style={{ height: 200, objectFit: 'cover' }}
                                        />
                                    )}
                                    actions={[
                                        <ShowButton recordItemId={entity.id} />,
                                        <EditButton recordItemId={entity.id} />,
                                        <DeleteButton recordItemId={entity.id} />,
                                    ]}
                                >
                                    <Card.Meta
                                        title={entity.name}
                                        description={
                                            <Space direction="vertical">
                                                <Tag color={getEntityTypeColor(entity.type)}>
                                                    {entity.type}
                                                </Tag>
                                                <Text type="secondary">
                                                    {entity.description?.slice(0, 100)}...
                                                </Text>
                                            </Space>
                                        }
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}

                {!tableQueryResult.data?.data?.length && !tableQueryResult.isLoading && (
                    <Empty
                        description={
                            <Space direction="vertical" align="center">
                                <Text>No entities found</Text>
                                <CreateButton size="small">Create New Entity</CreateButton>
                            </Space>
                        }
                    />
                )}
            </Card>
            {renderColumnSettingsModal()}
        </List>
    );
};

// Utility functions for colors and formatting
const getEntityTypeColor = (type: EntityType): string => {
    const colors: Record<EntityType, string> = {
        company: 'blue',
        individual: 'green',
        dao: 'purple',
        contract: 'orange',
        label: 'cyan',
        exchange: 'magenta',
        wallet: 'gold',
    };
    return colors[type] || 'default';
};

const getStatusColor = (status: EntityStatus): string => {
    const colors: Record<EntityStatus, string> = {
        active: 'success',
        inactive: 'default',
        pending: 'processing',
        deleted: 'error',
        blocked: 'error',
    };
    return colors[status] || 'default';
};

const getRiskColor = (score: number): string => {
    if (score >= 75) return 'red';
    if (score >= 50) return 'orange';
    if (score >= 25) return 'yellow';
    return 'green';
};

const getRiskLevel = (score: number): string => {
    if (score >= 75) return 'High';
    if (score >= 50) return 'Medium';
    if (score >= 25) return 'Low';
    return 'Safe';
};

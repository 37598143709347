import { Drawer, Space, Avatar, Typography, Statistic, Card, List, Tag, Collapse, Tooltip, Skeleton, Divider } from 'antd';
import { DollarOutlined, PercentageOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { IDeFiProtocol } from "../../../interfaces/address.d";
import { formatUSD, shortenAddress } from '../../../utils/formatters';
import { Link } from 'react-router-dom';
import { DefiDetailsByProtocol } from './DefiDetailsByProtocol';

const { Text } = Typography;
const { Panel } = Collapse;

interface DeFiProtocolDetailProps {
    address: string;
    protocol?: IDeFiProtocol;
    visible: boolean;
    onClose: () => void;
}

export const DeFiProtocolDetail: React.FC<DeFiProtocolDetailProps> = ({ 
    address,
    protocol,
    visible,
    onClose
}) => {
    if (!protocol) return null;

    const LoadingSkeleton = () => (
        <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Card>
                <Skeleton.Avatar size={64} active />
                <Skeleton.Input style={{ width: 200, marginLeft: 16 }} active />
            </Card>

            <Card>
                <Skeleton.Input style={{ width: '100%' }} active />
                <Space size="large" wrap style={{ marginTop: 16 }}>
                    <Skeleton.Input style={{ width: 200 }} active />
                    <Skeleton.Input style={{ width: 200 }} active />
                </Space>
            </Card>

            <Card>
                <Skeleton.Input style={{ width: '100%' }} active />
                <List>
                    {[1, 2, 3].map((i) => (
                        <List.Item key={i}>
                            <Skeleton.Avatar size="small" active />
                            <Skeleton.Input style={{ width: 150, marginLeft: 8 }} active />
                            <Skeleton.Input style={{ width: 100, marginLeft: 8 }} active />
                        </List.Item>
                    ))}
                </List>
            </Card>
        </Space>
    );

    return (
        <Drawer
            title={
                <Space>
                    {protocol.protocol_logo && <Avatar src={protocol.protocol_logo} />}
                    <Text strong>{protocol.protocol_name || 'Unknown Protocol'}</Text>
                    {protocol.protocol_url && (
                        <Link to={protocol.protocol_url} target="_blank">
                            Visit Protocol
                        </Link>
                    )}
                </Space>
            }
            width={720}
            open={visible}
            onClose={onClose}
        >
            {!protocol ? <LoadingSkeleton /> : (
                <Space direction="vertical" style={{ width: '100%' }} size="large">
                    <Card>
                        <Space size="large" wrap>
                            {typeof protocol.total_usd_value === 'number' && (
                                <Statistic
                                    title="Total Value"
                                    value={formatUSD(protocol.total_usd_value)}
                                    valueStyle={{ fontSize: '16px' }}
                                    prefix={<DollarOutlined/>}
                                    suffix="USD"
                                />
                            )}
                            {protocol.total_unclaimed_usd_value && Number(protocol.total_unclaimed_usd_value) > 0 && (
                                <Statistic
                                    title="Unclaimed Value"
                                    value={formatUSD(Number(protocol.total_unclaimed_usd_value))}
                                    valueStyle={{ fontSize: '16px' }}
                                    prefix={<DollarOutlined />}
                                    suffix="USD"
                                />
                            )}
                        </Space>
                    </Card>

                    {protocol.position && (
                        <List
                            dataSource={[protocol.position]}
                            renderItem={(position) => (
                                <Card 
                                    title={
                                        <Space>
                                            <Text strong>{position.label?.toUpperCase() || 'Position'}</Text>
                                            {typeof position.balance_usd === 'number' && (
                                                <Tag color="green">
                                                    {formatUSD(position.balance_usd)}
                                                </Tag>
                                            )}
                                            {position.total_unclaimed_usd_value > 0 && (
                                                <Tooltip title="Unclaimed Rewards">
                                                    <Tag color="gold">
                                                        {formatUSD(Number(position.total_unclaimed_usd_value))}
                                                    </Tag>
                                                </Tooltip>
                                            )}
                                        </Space>
                                    }
                                    style={{ marginBottom: 16 }}
                                >
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        {position.tokens && position.tokens.length > 0 && (
                                            <List
                                                header={<Text strong>Tokens</Text>}
                                                dataSource={position.tokens}
                                                renderItem={(token) => (
                                                    <List.Item>
                                                        <Space>
                                                            {token.logo && (
                                                                <Avatar size="small" src={token.logo} />
                                                            )}
                                                            <Text>{token.name || 'Unknown Token'}</Text>
                                                            {token.symbol && (
                                                                <Tag color="blue">{token.symbol}</Tag>
                                                            )}
                                                            {token.contract_address && (
                                                                <Link to={`https://etherscan.io/address/${token.contract_address}`} target="_blank">
                                                                    {shortenAddress(token.contract_address)}
                                                                </Link>
                                                            )}
                                                            <Text>Balance: {
                                                                Number(token.balance_formatted) < 0.000001 
                                                                    ? "< 0.000001"
                                                                    : formatUSD(Number(token.balance_formatted || 0))
                                                            }</Text>
                                                            {typeof token.usd_value === 'number' && (
                                                                <Text type="secondary">
                                                                    {token.usd_value > 0.01
                                                                        ? `(${formatUSD(token.usd_value)})`
                                                                        : '(< $0.01)'
                                                                    }
                                                                </Text>
                                                            )}
                                                        </Space>
                                                    </List.Item>
                                                )}
                                            />
                                        )}

                                        {position.position_details && Object.keys(position.position_details).length > 0 && (
                                            <Collapse ghost>
                                                <Panel header="Position Details" key="1">
                                                    <List
                                                        size="small"
                                                        dataSource={Object.entries(position.position_details)
                                                            .filter(([_, value]) => value !== undefined && value !== null)}
                                                        renderItem={([key, value]) => {
                                                            if (key === 'projected_earnings_usd' && typeof value === 'object' && value !== null) {
                                                                return (
                                                                    <>
                                                                        <List.Item>
                                                                            <Text type="secondary">PROJECTED EARNINGS:</Text>
                                                                        </List.Item>
                                                                        {Object.entries(value)
                                                                            .filter(([_, amount]) => amount !== undefined && amount !== null)
                                                                            .map(([period, amount]) => (
                                                                                <List.Item key={period} style={{ paddingLeft: 24 }}>
                                                                                    <Space>
                                                                                        <Text type="secondary">{period.toUpperCase()}:</Text>
                                                                                        <Text>{formatUSD(Number(amount))}</Text>
                                                                                    </Space>
                                                                                </List.Item>
                                                                            ))}
                                                                    </>
                                                                );
                                                            }

                                                            return (
                                                                <List.Item>
                                                                    <Space>
                                                                        <Text type="secondary">
                                                                            {key.replace(/_/g, ' ').toUpperCase()}:
                                                                        </Text>
                                                                        {typeof value === 'boolean' ? (
                                                                            value ? 
                                                                                <CheckCircleOutlined style={{ color: '#52c41a' }}/> : 
                                                                                <CloseCircleOutlined style={{ color: '#ff4d4f' }}/>
                                                                        ) : (
                                                                            <Text>{
                                                                                typeof value === 'number' ? 
                                                                                    key.includes('price') || key.includes('value') ? 
                                                                                        `${formatUSD(value)}` : 
                                                                                    key.includes('apy') || key.includes('share') ? 
                                                                                        `${value.toLocaleString()}%` :
                                                                                        value.toLocaleString() :
                                                                                    String(value)
                                                                            }</Text>
                                                                        )}
                                                                    </Space>
                                                                </List.Item>
                                                            );
                                                        }}
                                                    />
                                                </Panel>
                                            </Collapse>
                                        )}
                                    </Space>
                                </Card>
                            )}
                        />
                    )}

                    <Divider orientation="left">
                        <Text strong>Detailed Information</Text>
                    </Divider>

                    <DefiDetailsByProtocol address={address} protocolId={protocol.protocol_id} chainId={protocol.chain_id} />
                </Space>
            )}
        </Drawer>
    );
}; 
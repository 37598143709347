export enum ChainType {
    EVM = 'evm',
    BITCOIN = 'bitcoin',
    COSMOS = 'cosmos',
    SOLANA = 'solana',
    // Add other chain types as needed
}

// Chain ID Mappings
export const CHAIN_MAPPING: Record<string, string> = {
    // Mainnets
    "0x1": "Ethereum",
    "0x89": "Polygon",
    "0x38": "BNB Chain",
    "0xa86a": "Avalanche",
    "0xfa": "Fantom",
    "0x19": "Cronos",
    "0x2a15c308d": "Palm",
    "0xa4b1": "Arbitrum One",
    "0x64": "Gnosis Chain",
    "0x15b38": "Chiliz",
    "0x2105": "Base",
    "0xa": "Optimism",
    "0x2019": "Klaytn",
    "0xa4ec": "Celo",
    "0x8ae": "Kava",
    "0x440": "Metis",
    "0x63564c40": "Harmony",
    "0x2b6653dc": "zkSync",
    "0x171": "Aurora",
    "0x1388": "Polygon (Legacy)",
    "0x505": "Moonriver",
    "0x504": "Moonbeam",
    "0x144": "zkSync Era",
    "0x118": "Fuse",
    "0x405": "Linea",
    "0x44d": "Scroll",
    "0xa0c71fd": "Mantle",

    // Testnets
    "0x5": "Ethereum Goerli",
    "0xaa36a7": "Ethereum Sepolia",
    "0x13881": "Polygon Mumbai",
    "0x61": "BNB Chain Testnet",
    "0x27d8": "Gnosis Chiado",
    "0x15b32": "Chiliz Spicy",
    "0x14a33": "Base Goerli",
    "0x1a4": "Optimism Goerli",
    "0x82750": "Klaytn Baobab",
    "0xaef3": "Celo Alfajores",
    "0x8ad": "Kava Testnet",
    "0x24c": "Metis Goerli",
    "0x6357d2e0": "Harmony Testnet",
    "0x118": "zkSync Testnet",
    "0x66eee": "Aurora Testnet",
    "0x507": "Moonbase Alpha",
    "0x145": "zkSync Era Testnet",
    "0x7a": "Fuse Testnet",
    "0xe704": "Linea Testnet",
    "0x8274f": "Scroll Testnet",
    "0xa0c71fd": "Mantle Testnet"
};

// Network Categories
export const MAINNET_CHAINS = [
    "eth", "polygon", "bsc", "avalanche", "fantom", "arbitrum", "optimism",
    "klaytn", "celo", "kava", "metis", "harmony", "palm", "cronos", "gnosis",
    "base", "zkSync", "aurora", "matic", "moonriver", "moonbeam", "zkSync_era",
    "fuse", "linea", "scroll", "mantle"
];

export const TESTNET_CHAINS = [
    "goerli", "sepolia", "mumbai", "bsc_testnet", "gnosis_chiado",
    "chiliz_spicy", "base_goerli", "optimism_goerli", "klaytn_baobab",
    "celo_alfajores", "kava_testnet", "metis_goerli", "harmony_testnet",
    "zkSync_testnet", "aurora_testnet", "moonbase", "zkSync_era_testnet",
    "fuse_testnet", "linea_testnet", "scroll_testnet", "mantle_testnet"
];

// Chain Architecture Types
export const LAYER1_CHAINS = [
    "eth", "bsc", "avalanche", "fantom", "klaytn", "celo", "kava",
    "harmony", "matic", "moonriver", "moonbeam", "fuse"
];

export const LAYER2_CHAINS = [
    "polygon", "arbitrum", "optimism", "metis", "base", "zkSync",
    "zkSync_era", "linea", "scroll", "mantle"
];

export const SIDECHAINS = [
    "palm", "gnosis", "cronos", "aurora"
];

// Chain Features
export interface IChainFeatures {
    eip1559: boolean;
    token_standards: string[];
    supports_nft: boolean;
    supports_defi: boolean;
    supports_smart_contracts: boolean;
    supports_account_abstraction: boolean;
}

export const FEATURES_SUPPORT: Record<string, IChainFeatures> = {
    eth: {
        eip1559: true,
        token_standards: ["ERC20", "ERC721", "ERC1155", "ERC4626"],
        supports_nft: true,
        supports_defi: true,
        supports_smart_contracts: true,
        supports_account_abstraction: true
    },
    polygon: {
        eip1559: true,
        token_standards: ["ERC20", "ERC721", "ERC1155", "ERC4626"],
        supports_nft: true,
        supports_defi: true,
        supports_smart_contracts: true,
        supports_account_abstraction: true
    }
    // Add more chains as needed
};

// Chain Configuration
export interface IChainConfig {
    token: string;
    gasUnit: string;
    name: string;
    color?: string;
    block_time?: number;
    finality?: number;
    consensus?: string;
    max_tps?: number;
    avg_gas_price?: string;
    native_token_decimals?: number;
}

export const CHAIN_CONFIG: Record<string, IChainConfig> = {
    // Mainnets
    "0x1": { token: "ETH", gasUnit: "Gwei", name: "Ethereum", color: "#627EEA" },
    "0x89": { token: "MATIC", gasUnit: "Gwei", name: "Polygon", color: "#8247E5" },
    "0x38": { token: "BNB", gasUnit: "Gwei", name: "BNB Chain", color: "#F0B90B" },
    "0xa4b1": { token: "ARB", gasUnit: "Gwei", name: "Arbitrum", color: "#28A0F0" },
    "0x2105": { token: "ETH", gasUnit: "Gwei", name: "Base", color: "#0052FF" },
    "0xa": { token: "ETH", gasUnit: "Gwei", name: "Optimism", color: "#FF0420" },
    "0xe708": { token: "ETH", gasUnit: "Gwei", name: "Linea", color: "#121212" },
    "0xa86a": { token: "AVAX", gasUnit: "nAVAX", name: "Avalanche", color: "#E84142" },
    "0xfa": { token: "FTM", gasUnit: "Gwei", name: "Fantom", color: "#1969FF" },
    "0x19": { token: "CRO", gasUnit: "Gwei", name: "Cronos", color: "#002D74" },
    "0x64": { token: "xDAI", gasUnit: "Gwei", name: "Gnosis", color: "#133629" },
    "0x15b38": { token: "CHZ", gasUnit: "Gwei", name: "Chiliz", color: "#CD0124" },
    "0x171": { token: "PLS", gasUnit: "Gwei", name: "PulseChain", color: "#F90000" },
    "0x504": { token: "GLMR", gasUnit: "Gwei", name: "Moonbeam", color: "#53CBC8" },
    "0x505": { token: "MOVR", gasUnit: "Gwei", name: "Moonriver", color: "#F2B705" },
    "0x13e31": { token: "ETH", gasUnit: "Gwei", name: "Blast", color: "#FFAA3E" },
    "0x144": { token: "ETH", gasUnit: "Gwei", name: "zkSync Era", color: "#8C8DFC" },
    "0x1388": { token: "MNT", gasUnit: "Gwei", name: "Mantle", color: "#0D0D0D" },
    "0xcc": { token: "BNB", gasUnit: "Gwei", name: "BNB", color: "#F0B90B" },
    "0x44d": { token: "MATIC", gasUnit: "Gwei", name: "Polygon zkEVM", color: "#7B3FE4" },
    "0x1b58": { token: "ZETA", gasUnit: "Gwei", name: "ZetaChain", color: "#F4B728" },
    "0x2eb": { token: "FLOW", gasUnit: "FLOW units", name: "Flow", color: "#00EF8B" },
    "0x7e4": { token: "RON", gasUnit: "Gwei", name: "Ronin", color: "#1273EA" },
    "0x46f": { token: "LSK", gasUnit: "LSK units", name: "Lisk", color: "#0D4EA0" },
    
    // Testnets
    "0xaa36a7": { token: "ETH", gasUnit: "Gwei", name: "Sepolia", color: "#CFB5F0" },
    "0x4268": { token: "ETH", gasUnit: "Gwei", name: "Holesky", color: "#A9A9A9" },
    "0x13882": { token: "MATIC", gasUnit: "Gwei", name: "Polygon Amoy", color: "#B196DC" },
    "0x61": { token: "tBNB", gasUnit: "Gwei", name: "BSC Testnet", color: "#F0B90B" },
    "0x66eee": { token: "ARB", gasUnit: "Gwei", name: "Arbitrum Sepolia", color: "#96C0E6" },
    "0x14a34": { token: "ETH", gasUnit: "Gwei", name: "Base Sepolia", color: "#0052FF" },
    "0xaa37dc": { token: "ETH", gasUnit: "Gwei", name: "Optimism Sepolia", color: "#FF6B81" },
    "0xe705": { token: "ETH", gasUnit: "Gwei", name: "Linea Sepolia", color: "#5F5F5F" },
    "0xfa2": { token: "FTM", gasUnit: "Gwei", name: "Fantom Testnet", color: "#1969FF" },
    "0x27d8": { token: "xDAI", gasUnit: "Gwei", name: "Gnosis Chiado", color: "#48A9A6" },
    "0x15b32": { token: "CHZ", gasUnit: "Gwei", name: "Chiliz Testnet", color: "#CD0124" },
    "0x507": { token: "DEV", gasUnit: "Gwei", name: "Moonbase", color: "#53CBC8" },
    "0xa0c71fd": { token: "ETH", gasUnit: "Gwei", name: "Blast Sepolia", color: "#FFAA3E" },
    "0x12c": { token: "ETH", gasUnit: "Gwei", name: "zkSync Sepolia", color: "#8C8DFC" },
    "0x138b": { token: "MNT", gasUnit: "Gwei", name: "Mantle Sepolia", color: "#0D0D0D" },
    "0x98a": { token: "MATIC", gasUnit: "Gwei", name: "Polygon zkEVM Cardona", color: "#7B3FE4" },
    "0x1b59": { token: "ZETA", gasUnit: "Gwei", name: "Zetachain Testnet", color: "#F4B728" },
    "0x221": { token: "FLOW", gasUnit: "FLOW units", name: "Flow Testnet", color: "#00EF8B" },
    "0x7e5": { token: "RON", gasUnit: "Gwei", name: "Ronin Saigon Testnet", color: "#1273EA" },
    "0x106a": { token: "LSK", gasUnit: "LSK units", name: "Lisk Sepolia", color: "#0D4EA0" },

    // Non-EVM chains
    "0x0": { token: "BTC", gasUnit: "Sats/byte", name: "Bitcoin", color: "#F7931A" },
    "0x1399c": { token: "SOL", gasUnit: "Lamports", name: "Solana", color: "#14F195" },
    "0x162": { token: "DOT", gasUnit: "Plancks", name: "Polkadot", color: "#E6007A" },
};

// Block Explorers
export const EXPLORER_URLS: Record<string, string> = {
    eth: "https://etherscan.io",
    polygon: "https://polygonscan.com",
    bsc: "https://bscscan.com",
    avalanche: "https://snowtrace.io",
    fantom: "https://ftmscan.com",
    arbitrum: "https://arbiscan.io",
    optimism: "https://optimistic.etherscan.io",
    zkSync: "https://explorer.zksync.io",
    base: "https://basescan.org",
    linea: "https://lineascan.build",
    scroll: "https://scrollscan.com",
    mantle: "https://explorer.mantle.xyz",
    // Testnets
    goerli: "https://goerli.etherscan.io",
    sepolia: "https://sepolia.etherscan.io",
    mumbai: "https://mumbai.polygonscan.com"
};

// Native Currency Symbols
export const CURRENCY_SYMBOLS: Record<string, string> = {
    eth: "ETH",
    polygon: "MATIC",
    bsc: "BNB",
    avalanche: "AVAX",
    fantom: "FTM",
    arbitrum: "ETH",
    optimism: "ETH",
    klaytn: "KLAY",
    celo: "CELO",
    kava: "KAVA",
    metis: "METIS",
    harmony: "ONE",
    zkSync: "ETH",
    base: "ETH",
    linea: "ETH",
    scroll: "ETH",
    mantle: "MNT"
};

// Default Gas Limits
export const DEFAULT_GAS_LIMITS: Record<string, number> = {
    eth: 21000,
    polygon: 21000,
    bsc: 21000,
    avalanche: 21000,
    fantom: 21000,
    arbitrum: 700000,
    optimism: 21000,
    base: 21000,
    zkSync: 21000,
    linea: 21000,
    scroll: 21000,
    mantle: 21000
};

// Public RPC Endpoints
export const PUBLIC_RPC_ENDPOINTS: Record<string, string> = {
    eth: "https://eth.llamarpc.com",
    polygon: "https://polygon-rpc.com",
    bsc: "https://bsc-dataseed1.binance.org",
    avalanche: "https://api.avax.network/ext/bc/C/rpc",
    fantom: "https://rpc.ftm.tools",
    arbitrum: "https://arb1.arbitrum.io/rpc",
    optimism: "https://mainnet.optimism.io",
    zkSync: "https://mainnet.era.zksync.io",
    base: "https://mainnet.base.org",
    linea: "https://rpc.linea.build",
    scroll: "https://rpc.scroll.io",
    mantle: "https://rpc.mantle.xyz",
    // Testnets
    goerli: "https://goerli.infura.io/v3/",
    sepolia: "https://sepolia.infura.io/v3/",
    mumbai: "https://rpc-mumbai.maticvigil.com"
};

// Moralis Chain Information
export interface IMoralisChainInfo {
    name: string;
    type: "Mainnet" | "Testnet";
    chain_id: string;
    decimal_id: number;
    evm_chain: string;
    features: string[];
}

export const MORALIS_CHAIN_INFO: Record<string, IMoralisChainInfo> = {
    eth: {
        name: "Ethereum Mainnet",
        type: "Mainnet",
        chain_id: "0x1",
        decimal_id: 1,
        evm_chain: "EvmChain.ETHEREUM",
        features: ["Web3_Data_API", "Streams_API", "Auth_API", "RPC_Nodes", "Extended_RPC_API"]
    },
    polygon: {
        name: "Polygon Mainnet",
        type: "Mainnet",
        chain_id: "0x89",
        decimal_id: 137,
        evm_chain: "EvmChain.POLYGON",
        features: ["Web3_Data_API", "Streams_API", "Auth_API", "RPC_Nodes", "Extended_RPC_API"]
    }
    // Add more chains as needed
};

// Utility Types
export type ChainId = keyof typeof CHAIN_MAPPING;
export type ChainName = typeof CHAIN_MAPPING[ChainId];

// Utility functions
export const isTestnet = (chainIdOrName: string): boolean => {
    const chainName = CHAIN_MAPPING[chainIdOrName] || chainIdOrName;
    return TESTNET_CHAINS.includes(chainName);
};

export const isLayer2 = (chainIdOrName: string): boolean => {
    const chainName = CHAIN_MAPPING[chainIdOrName] || chainIdOrName;
    return LAYER2_CHAINS.includes(chainName);
};

export const getExplorerUrl = (chainIdOrName: string): string | undefined => {
    const chainName = CHAIN_MAPPING[chainIdOrName] || chainIdOrName;
    return EXPLORER_URLS[chainName];
};

export const getCurrencySymbol = (chainIdOrName: string): string | undefined => {
    const chainName = CHAIN_MAPPING[chainIdOrName] || chainIdOrName;
    return CURRENCY_SYMBOLS[chainName];
};

// Wallet Chain Configuration for wallet_addEthereumChain
export interface IWalletChainConfig {
    chainId: string;           // Hex chain ID
    chainIdDecimal: number;    // Decimal chain ID
    chainName: string;         // Human-readable chain name
    nativeCurrency: {
        name: string;
        symbol: string;
        decimals: number;
    };
    rpcUrls: string[];
    blockExplorerUrls: string[];
    iconUrls?: string[];       // Optional chain logo URLs
}

export const WALLET_CHAIN_MAPPING: Record<string, IWalletChainConfig> = {
    // Mainnets
    "0x1": {
        chainId: "0x1",
        chainIdDecimal: 1,
        chainName: "Ethereum Mainnet",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://eth.llamarpc.com", "https://mainnet.infura.io/v3/"],
        blockExplorerUrls: ["https://etherscan.io"]
    },
    "0x89": {
        chainId: "0x89",
        chainIdDecimal: 137,
        chainName: "Polygon Mainnet",
        nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18
        },
        rpcUrls: ["https://polygon-rpc.com", "https://polygon-mainnet.infura.io/v3/"],
        blockExplorerUrls: ["https://polygonscan.com"]
    },
    "0x38": {
        chainId: "0x38",
        chainIdDecimal: 56,
        chainName: "BNB Smart Chain",
        nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18
        },
        rpcUrls: ["https://bsc-dataseed1.binance.org", "https://bsc-dataseed2.binance.org"],
        blockExplorerUrls: ["https://bscscan.com"]
    },
    "0xa86a": {
        chainId: "0xa86a",
        chainIdDecimal: 43114,
        chainName: "Avalanche C-Chain",
        nativeCurrency: {
            name: "Avalanche",
            symbol: "AVAX",
            decimals: 18
        },
        rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
        blockExplorerUrls: ["https://snowtrace.io"]
    },
    "0xfa": {
        chainId: "0xfa",
        chainIdDecimal: 250,
        chainName: "Fantom Opera",
        nativeCurrency: {
            name: "Fantom",
            symbol: "FTM",
            decimals: 18
        },
        rpcUrls: ["https://rpc.ftm.tools"],
        blockExplorerUrls: ["https://ftmscan.com"]
    },
    "0x19": {
        chainId: "0x19",
        chainIdDecimal: 25,
        chainName: "Cronos Mainnet",
        nativeCurrency: {
            name: "Cronos",
            symbol: "CRO",
            decimals: 18
        },
        rpcUrls: ["https://evm.cronos.org"],
        blockExplorerUrls: ["https://cronoscan.com"]
    },
    "0xa4b1": {
        chainId: "0xa4b1",
        chainIdDecimal: 42161,
        chainName: "Arbitrum One",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://arb1.arbitrum.io/rpc"],
        blockExplorerUrls: ["https://arbiscan.io"]
    },
    "0x64": {
        chainId: "0x64",
        chainIdDecimal: 100,
        chainName: "Gnosis Chain",
        nativeCurrency: {
            name: "xDAI",
            symbol: "xDAI",
            decimals: 18
        },
        rpcUrls: ["https://rpc.gnosischain.com"],
        blockExplorerUrls: ["https://gnosisscan.io"]
    },
    "0x2105": {
        chainId: "0x2105",
        chainIdDecimal: 8453,
        chainName: "Base",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://mainnet.base.org"],
        blockExplorerUrls: ["https://basescan.org"]
    },
    "0xa": {
        chainId: "0xa",
        chainIdDecimal: 10,
        chainName: "Optimism",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://mainnet.optimism.io"],
        blockExplorerUrls: ["https://optimistic.etherscan.io"]
    },
    "0x405": {
        chainId: "0x405",
        chainIdDecimal: 59144,
        chainName: "Linea",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://rpc.linea.build"],
        blockExplorerUrls: ["https://lineascan.build"]
    },
    "0x44d": {
        chainId: "0x44d",
        chainIdDecimal: 534352,
        chainName: "Scroll",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://rpc.scroll.io"],
        blockExplorerUrls: ["https://scrollscan.com"]
    },
    "0x144": {
        chainId: "0x144",
        chainIdDecimal: 324,
        chainName: "zkSync Era",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://mainnet.era.zksync.io"],
        blockExplorerUrls: ["https://explorer.zksync.io"]
    },
    
    // Testnets
    "0xaa36a7": {
        chainId: "0xaa36a7",
        chainIdDecimal: 11155111,
        chainName: "Sepolia Testnet",
        nativeCurrency: {
            name: "Sepolia Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://sepolia.infura.io/v3/"],
        blockExplorerUrls: ["https://sepolia.etherscan.io"]
    },
    "0x13881": {
        chainId: "0x13881",
        chainIdDecimal: 80001,
        chainName: "Polygon Mumbai",
        nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18
        },
        rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
        blockExplorerUrls: ["https://mumbai.polygonscan.com"]
    },
    "0x61": {
        chainId: "0x61",
        chainIdDecimal: 97,
        chainName: "BNB Smart Chain Testnet",
        nativeCurrency: {
            name: "tBNB",
            symbol: "tBNB",
            decimals: 18
        },
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        blockExplorerUrls: ["https://testnet.bscscan.com"]
    }
    // Add more chains as needed
};


import React, { useMemo, useRef } from 'react';
import { Slider, Card, Typography, theme, Space, Alert } from 'antd';
import { GraphData } from 'interfaces/graph';
import dayjs from 'dayjs';
import type { SliderRangeProps } from 'antd/es/slider';
import { ClockCircleOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

interface TimeRangeSliderProps {
    graphData: GraphData;  // filtered data
    fullData: GraphData;   // full unfiltered data
    onTimeRangeChange: (range: [number, number]) => void;
}

export const TimeRangeSlider: React.FC<TimeRangeSliderProps> = ({
    graphData,
    fullData,
    onTimeRangeChange,
}) => {
    const { token } = theme.useToken();
    const ONE_YEAR_MS = 365 * 24 * 60 * 60 * 1000;
    const ONE_MONTH_MS = 30 * 24 * 60 * 60 * 1000;

    const {
        histogramData,
        timeRange,
        bucketSize,
        hasData
    } = useMemo(() => {
        const allTimestamps = [
            ...fullData.nodes.flatMap((node: any) => node.block_timestamps || []),
            ...fullData.links.map((link: any) => link.block_timestamp)
        ].map((ts: any) => dayjs(ts).valueOf()).filter(Boolean);

        if (allTimestamps.length === 0) {
            const now = dayjs().valueOf();
            // Only use 1-year minimum for initial display
            return {
                histogramData: [],
                timeRange: [now - ONE_YEAR_MS, now + ONE_MONTH_MS] as [number, number],
                bucketSize: 0,
                hasData: false
            };
        }

        const minTime = Math.min(...allTimestamps);
        const maxTime = Math.max(...allTimestamps);
        
        // Use 1-year minimum only for initial display range
        const adjustedMinTime = Math.min(minTime, maxTime - ONE_YEAR_MS);
        const adjustedMaxTime = maxTime + ONE_MONTH_MS;

        const numberOfBuckets = 50;
        const bucketSize = Math.ceil((adjustedMaxTime - adjustedMinTime) / numberOfBuckets);
        const histogram = new Array(numberOfBuckets).fill(0);

        allTimestamps.forEach(timestamp => {
            const bucketIndex = Math.floor((timestamp - adjustedMinTime) / bucketSize);
            if (bucketIndex >= 0 && bucketIndex < numberOfBuckets) {
                histogram[bucketIndex]++;
            }
        });

        const maxCount = Math.max(...histogram);
        const normalizedHistogram = histogram.map(count => 
            maxCount > 0 ? (count / maxCount) * 100 : 0
        );

        return {
            histogramData: normalizedHistogram,
            timeRange: [adjustedMinTime, adjustedMaxTime] as [number, number],
            bucketSize,
            hasData: allTimestamps.length > 0
        };
    }, [fullData]);

    const handleChange: SliderRangeProps['onChange'] = (value) => {
        if (!hasData || !Array.isArray(value)) return;
        
        // Ensure value is a tuple of two numbers
        const range: [number, number] = [value[0], value[1]];
        
        // Pass through the selected range without any modifications
        onTimeRangeChange(range);
    };

    return (
        <Space direction="vertical" style={{ width: '100%' }} size="small">
            <Space align="center" size="small">
                <ClockCircleOutlined/>
                <Title level={5} style={{ margin: 0, fontSize: '14px' }}>Time Range Filter</Title>
                <Text type="secondary" style={{ fontSize: '12px' }}>
                    {hasData 
                        ? "Drag the slider to filter transactions by time range"
                        : "No transaction data available for filtering"
                    }
                </Text>
            </Space>

            <Card size="small" bodyStyle={{ padding: '12px' }}>
                <div style={{ padding: '0 8px' }}>
                    <div style={{ 
                        position: 'relative', 
                        height: 40,
                        marginBottom: 8,
                        opacity: hasData ? 1 : 0.5
                    }}>
                        {/* Histogram with adjusted z-index */}
                        <div style={{ 
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'flex-end',
                            gap: 1,
                            zIndex: 0
                        }}>
                            {histogramData.map((height, index) => (
                                <div
                                    key={index}
                                    style={{
                                        flex: 1,
                                        height: `${height}%`,
                                        backgroundColor: token.colorPrimary + '33',
                                        minWidth: 1
                                    }}
                                />
                            ))}
                        </div>

                        {/* Slider with higher z-index */}
                        <Slider
                            range
                            min={timeRange[0]}
                            max={timeRange[1]}
                            defaultValue={timeRange}
                            onChange={handleChange}
                            disabled={!hasData}
                            tipFormatter={value => dayjs(value).format('YYYY-MM-DD HH:mm')}
                            style={{ 
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                margin: 0,
                                zIndex: 1
                            }}
                        />
                    </div>

                    {/* Time labels */}
                    <div style={{ 
                        display: 'flex',
                        justifyContent: 'space-between',
                        opacity: hasData ? 1 : 0.5
                    }}>
                        <Text type="secondary" style={{ fontSize: '12px' }}>
                            {dayjs(timeRange[0]).format('YYYY-MM-DD HH:mm')}
                        </Text>
                        <Text type="secondary" style={{ fontSize: '12px' }}>
                            {dayjs(timeRange[1]).format('YYYY-MM-DD HH:mm')}
                        </Text>
                    </div>
                </div>
            </Card>
        </Space>
    );
};
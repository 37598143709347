import React, { useMemo, useRef, useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useModalForm } from "@refinedev/antd";
import { 
    Form, 
    Input, 
    Select, 
    Tag, 
    Space, 
    Card, 
    Alert, 
    Typography,
    InputNumber,
    Button,
    Tooltip,
    Modal,
    Upload,
    Spin,
    SelectProps,
    Avatar
} from "antd";
import { InfoCircleOutlined, QuestionCircleOutlined, TagsOutlined, TeamOutlined, PlusOutlined } from "@ant-design/icons";
import { 
    AddressType, 
    ADDRESS_TYPE_COLORS,
    AddressStatus,
    IAddress 
} from "../../interfaces/address.d";
import { ENTITY_TYPE_COLORS, EntityType, IEntity, IEntitySelectOption } from "../../interfaces/entity.d";
import { CHAIN_MAPPING } from "../../interfaces/chain.d";
import { ChainAvatar } from "../../components/chain-avatar";
import { ITag } from "interfaces/tag";
import { IAddressLabel } from "interfaces/label";
import { useConfig } from "contexts/config";
import debounce from 'lodash/debounce';
import { IUserSelectOption } from "interfaces/user";
import { IUser } from "interfaces/user";

const { TextArea } = Input;

const RiskScoreInput: React.FC<any> = ({ value, onChange, ...props }) => {
    const getColor = (score: number) => {
        if (score >= 7.5) return '#ff4d4f';
        if (score >= 5) return '#faad14';
        if (score >= 2.5) return '#52c41a';
        return '#1890ff';
    };

    const getRiskLevel = (score: number) => {
        if (score >= 7.5) return 'High Risk';
        if (score >= 5) return 'Medium Risk';
        if (score >= 2.5) return 'Low Risk';
        return 'Safe';
    };

    return (
        <Space>
            <InputNumber
                {...props}
                value={value}
                onChange={onChange}
                min={0}
                max={10}
                step={0.1}
                style={{ width: 120 }}
            />
            {value !== undefined && (
                <Tag color={getColor(value)}>
                    {getRiskLevel(value)}
                </Tag>
            )}
        </Space>
    );
};

export interface DebounceSelectProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
    onFocus?: () => void;
    onBlur?: () => void;
    onSelect?: () => void;
}


export const AddressEdit: React.FC<IResourceComponentsProps> = () => {
    const { token, region, continent, domain, qa_environment } = useConfig();
    const { formProps, saveButtonProps, queryResult } = useForm<IAddress>(
        {
            meta: {
                include_transactions_in_response: "false"
            }
        }
    );
    const record = queryResult?.data?.data;
    
    // Add modal form hooks for labels and entities
    const {
        modalProps: editLabelsModalProps,
        formProps: editLabelsFormProps,
        show: showEditLabelsModal
    } = useModalForm({
        action: "edit",
        resource: "addresses",
        id: record?.address,    
        meta: {
            idName: "address",
            include_transactions_in_response: "false"
        },
        warnWhenUnsavedChanges: true,
        queryOptions: {
            enabled: false
        },
        onMutationSuccess: () => {
            queryResult?.refetch();
        },
        invalidates: ['all']
    });

    const {
        modalProps: editEntitiesModalProps,
        formProps: editEntitiesFormProps,
        show: showEditEntitiesModal
    } = useModalForm({
        action: "edit",
        resource: "addresses",
        id: record?.address,    
        meta: {
            idName: "address",
            include_transactions_in_response: "false"
        },
        warnWhenUnsavedChanges: true,
        queryOptions: {
            enabled: false
        },
        onMutationSuccess: () => {
            queryResult?.refetch();
        },
        invalidates: ['all']
    });

    // Add modal form hook for tags
    const {
        modalProps: editTagsModalProps,
        formProps: editTagsFormProps,
        show: showEditTagsModal
    } = useModalForm({
        action: "edit",
        resource: "addresses",
        id: record?.address,    
        meta: {
            idName: "address",
            include_transactions_in_response: "false"
        },
        warnWhenUnsavedChanges: true,
        queryOptions: {
            enabled: false
        },
        onMutationSuccess: () => {
            queryResult?.refetch();
        },
        invalidates: ['all']
    });

    // Add modal form hook for shared with
    const {
        modalProps: sharedWithModalProps,
        formProps: sharedWithFormProps,
        show: showSharedWithModal
    } = useModalForm({
        action: "edit",
        resource: "addresses",
        id: record?.address,    
        meta: {
            idName: "address",
            include_transactions_in_response: "false"
        },
        warnWhenUnsavedChanges: true,
        queryOptions: {
            enabled: false
        },
        onMutationSuccess: () => {
            queryResult?.refetch();
        },
        invalidates: ['all']
    });
    
    const getAllLabels = () => {
        if (record?.labels && record.labels.length > 0) {
            return record.labels;
        }
        return Object.values(record?.address_items_map || {})
            .flatMap((item: any) => item.labels || [])
            .filter((label: any, index: number, self: any) => 
                index === self.findIndex((l: any) => l.id === label.id)
            );
    };

    const getAllTags = () => {
        if (record?.tags && record.tags.length > 0) {
            return record.tags;
        }
        return Object.values(record?.address_items_map || {})
            .flatMap((item: any) => item.tags || [])
            .filter((tag: any, index: number, self: any) => 
                index === self.findIndex((t: any) => t.id === tag.id)
            );
    };

    const getAllEntities = () => {
        // If main object has entities, use those
        if (record?.entities && record.entities.length > 0) {
            return record.entities;
        }
        
        // Otherwise, get from address_items_map
        const entities = Object.values(record?.address_items_map[record?.address]?.entities || [])
            .map((entity: any) => ({      // Use any temporarily to handle the transformation
                ...entity,
                id: entity.SK || entity.id,
                name: entity.name,
                'org_id-tenant_id': entity.PK,
                SK: undefined,
                PK: undefined
            })) as IEntity[];              // Cast the result back to IEntity[]
        return entities;
    };


    const getAllSharedWith = () => {
        // If main object has shared_with, use those
        if (record?.shared_with && record.shared_with.length > 0) {
            return record.shared_with;
        }
        
        // Otherwise, get from address_items_map
        return Object.values(record?.address_items_map || {})
            .flatMap((item: any) => item.shared_with || [])
    };

    const fetchUserList = async (search: string): Promise<IUserSelectOption[]> => {
        try {
            const requestHeaders = {
                Authorization: `Bearer ${token.__raw}`,
                Accept: "application/json, text/plain, */*",
                "Source-Platform": "dashboard",
                "Source-Region": region.use_region,
                "Destination-Region": region.use_region,
            };

            const apiUrl = `https://${region.use_region_alias}.${continent.toLowerCase()}.api.${domain}`;
            let url;
            
            if (qa_environment === "blue") {
                url = `${apiUrl}/management/permissions/blue/GET/${continent.toUpperCase()}/v0/users`;
            } else {
                url = `${apiUrl}/management/permissions/GET/${continent.toUpperCase()}/v0/users`;
            }

            const response = await fetch(url, { headers: requestHeaders });
            const data = await response.json();

            return data.result
                .filter((user: IUser) => user.status !== "disabled" && user.status !== "pending_invitation")
                .map((user: IUser): IUserSelectOption => ({
                    label: user.name ? `${user.name} (${user.email})` : user.email,
                    value: user.id,
                    status: user.status,
                    roles: user.roles
                }));
        } catch (error) {
            console.error('Error fetching users:', error);
            return [];
        }
    };
        
    const fetchEntityList = async (search: string): Promise<IEntitySelectOption[]> => {
        try {
            const requestHeaders = {
                Authorization: `Bearer ${token.__raw}`,
                Accept: "application/json, text/plain, */*",
                "Source-Platform": "dashboard",
                "Source-Region": region.use_region,
                "Destination-Region": region.use_region,
            };

            const apiUrl = `https://${region.use_region_alias}.${continent.toLowerCase()}.api.${domain}`;
            let url;
            
            if (qa_environment === "blue") {
                url = `${apiUrl}/private/entities/blue/GET/${continent.toUpperCase()}/v0/entities`;
            } else {
                url = `${apiUrl}/private/entities/GET/${continent.toUpperCase()}/v0/entities`;
            }

            const response = await fetch(url, { headers: requestHeaders });
            const data = await response.json();

            return data.result
                .map((entity: IEntity): IEntitySelectOption => ({
                    color: ENTITY_TYPE_COLORS[entity.type as EntityType],
                    name: entity.name,
                    label: entity.name,
                    value: entity.id,
                    type: entity.type
                }));
        } catch (error) {
            console.error('Error fetching entities:', error);
            return [];
        }
    };


    function DebounceSelect<
    ValueType extends { key?: string; label: React.ReactNode; value: string | number; type?: string; color?: string } = any,
>({ fetchOptions, debounceTimeout = 800, onFocus, ...props }: DebounceSelectProps<ValueType>) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState<ValueType[]>([]);
    const [open, setOpen] = useState(false);
    const fetchRef = useRef(0);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    const handleFocus = () => {
        setOpen(true);
        setFetching(true);
        fetchOptions('').then((newOptions) => {
            setOptions(newOptions);
            setFetching(false);
        });
        onFocus?.();
    };
    
    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            onFocus={handleFocus}
            open={open}
            onSelect={() => setOpen(false)}
            onBlur={() => setOpen(false)}
            {...props}
            options={options}
            tagRender={({ label, value, closable, onClose }) => {
                const option = options.find((opt: any) => opt.label === label);
                const entityObj = getAllEntities().find((entity: any) => entity.name === value);
                return (
                    <Tag 
                        closable={closable}     
                        onClose={onClose} 
                        style={{ marginRight: 3 }}
                        color={entityObj?.type ? ENTITY_TYPE_COLORS[entityObj?.type as EntityType] : option?.color}
                    >
                        {option?.label || label}
                    </Tag>
                );
            }}
        />
    );
}    
    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Alert
                    message={
                        <Space>
                            <InfoCircleOutlined/>
                            Address Configuration
                        </Space>
                    }
                    description="Update the address details, type, associated entities, and risk parameters. Changes will be applied immediately upon saving."
                    type="info"
                    showIcon
                />

                <Card 
                    title={
                        <Space>
                            Basic Information
                            <Tooltip title="Configure the fundamental properties of this address">
                                <QuestionCircleOutlined/>
                            </Tooltip>
                        </Space>
                    }
                >
                    <Form {...formProps} layout="vertical">
                        <Form.Item
                            label="Address"
                            name="address"
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label={
                                <Space>
                                    Status
                                    <Tooltip title="Current status of the address">
                                        <QuestionCircleOutlined/>
                                    </Tooltip>
                                </Space>
                            }
                            name="status"
                            rules={[{ required: true }]}
                        >
                            <Select
                                options={Object.values(AddressStatus).map(status => ({
                                    label: status.charAt(0).toUpperCase() + status.slice(1),
                                    value: status
                                }))}
                            />
                        </Form.Item>

                        <Form.Item
                            label={
                                <Space>
                                    Address Type
                                    <Tooltip title="Select the category that best describes this address's purpose or nature">
                                        <QuestionCircleOutlined/>
                                    </Tooltip>
                                </Space>
                            }
                            name="type"
                            rules={[{ required: true }]}
                        >
                            <Select
                                options={Object.entries(AddressType).map(([key, value]) => ({
                                    label: (
                                        <Tag color={ADDRESS_TYPE_COLORS[value as AddressType]}>
                                            {key.split('_').map(word => 
                                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                            ).join(' ')}
                                        </Tag>
                                    ),
                                    value: value,
                                }))}
                                optionRender={(option) => (
                                    <Tag color={ADDRESS_TYPE_COLORS[option.value as AddressType]}>
                                        {option.label}
                                    </Tag>
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            label={
                                <Space>
                                    Risk Score
                                    <Tooltip title="Assign a risk score from 0 (lowest risk) to 10 (highest risk)">
                                        <QuestionCircleOutlined/>
                                    </Tooltip>
                                </Space>
                            }
                            name="risk_score"
                            rules={[
                                { 
                                    type: 'number',
                                    min: 0,
                                    max: 10,
                                    message: 'Risk score must be between 0 and 10'
                                }
                            ]}
                        >
                            <RiskScoreInput />
                        </Form.Item>

                        <Form.Item
                            label={
                                <Space>
                                    Notes
                                    <Tooltip title="Additional information about this address">
                                        <QuestionCircleOutlined/>
                                    </Tooltip>
                                </Space>
                            }
                            name="notes"
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Form>
                </Card>

                <Card>
                    <Space>
                        <Button 
                            onClick={() => showEditLabelsModal(record?.address)} 
                            icon={<TagsOutlined/>}
                        >
                            Edit Labels
                        </Button>
                        <Button 
                            onClick={() => showEditEntitiesModal(record?.address)} 
                            icon={<TeamOutlined/>}
                        >
                            Edit Entities
                        </Button>
                    </Space>
                </Card>

                {/* Labels Modal */}
                <Modal
                    {...editLabelsModalProps}
                    title="Edit Labels"
                    width={800}
                    centered
                    afterOpenChange={() => {
                        editLabelsFormProps.form?.setFieldsValue({
                            labels: getAllLabels().map((label: IAddressLabel) => ({
                                label_id: label.id,
                                chain_ids: label.chain_ids || [],
                                main_label_chain_ids: label.main_label_chain_ids || []
                            })) || []
                        });
                    }}
                >
                    <Alert
                        message="Label Management"
                        description="Labels help categorize this address. First select the chains where the label applies, then mark which labels are main identifiers for specific chains."
                        type="info"
                        showIcon
                        style={{ marginBottom: 16 }}
                    />
                    <Form 
                        {...editLabelsFormProps}
                    >
                        <Form.List name="labels">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <div key={key} style={{ marginBottom: 16, border: '1px solid #f0f0f0', padding: 16, borderRadius: 8 }}>
                                            <Space direction="vertical" style={{ width: '100%' }}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'label_id']}
                                                    label={
                                                        <Space>
                                                            Label
                                                            <Tooltip title="Select an existing label or create a new one">
                                                                <QuestionCircleOutlined/>
                                                            </Tooltip>
                                                        </Space>
                                                    }
                                                    rules={[{ required: true, message: 'Please select a label' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        disabled={!!editLabelsFormProps.form?.getFieldValue(['labels', name, 'label_id'])}
                                                        style={{ width: '100%' }}
                                                        mode="tags"
                                                        options={editLabelsFormProps.form?.getFieldValue(['labels', name, 'label_id']) 
                                                            ? getAllLabels().map((label: IAddressLabel) => ({
                                                                label: label.address_name,
                                                                value: label.id,
                                                                color: label.color
                                                            }))
                                                            : [] // Empty options for new labels
                                                        }
                                                        filterOption={(input, option) =>
                                                            (option?.label?.toString() || '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        tagRender={({ label, value, closable, onClose }) => {
                                                            // If it's a new label (no options shown), only keep the last value
                                                            if (!editLabelsFormProps.form?.getFieldValue(['labels', name, 'label_id'])) {
                                                                const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
                                                                editLabelsFormProps.form?.setFieldValue(['labels', name, 'label_id'], [lastValue]);
                                                            } else {
                                                                // For existing labels, allow normal behavior
                                                                editLabelsFormProps.form?.setFieldValue(['labels', name, 'label_id'], value);
                                                            }                                                            
                                                            const labelObj = getAllLabels().find((l: IAddressLabel) => l.id === value);
                                                            return (
                                                                <Tag 
                                                                    closable={closable} 
                                                                    onClose={onClose} 
                                                                    style={{ marginRight: 3 }}
                                                                    color={labelObj?.color ? `#${labelObj.color}` : undefined}
                                                                >
                                                                    {label}
                                                                </Tag>
                                                            );
                                                        }}
                                                    />
                                                </Form.Item>  

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'logo']}
                                                    label="Label Image"
                                                >
                                                    <Upload.Dragger
                                                        name="file"
                                                        action="https://us.api.sandbox.authe.io/private/labels/blue/ANY/US/v0/labels/upload-image"
                                                        headers={{
                                                            Authorization: `Bearer ${token.__raw}`,
                                                            Accept: "application/json, text/plain, */*",
                                                            "Source-Platform": "dashboard",
                                                            "Source-Region": region.use_region,
                                                            "Destination-Region": region.use_region,
                                                            "filename": "testhere.png"
                                                        }}
                                                        listType="picture"
                                                        maxCount={1}
                                                        onChange={(info) => {
                                                            if (info.file.status === 'done') {
                                                                // Get the URL from the response
                                                                const imageUrl = info.file.response.url;
                                                                // Set it to the logo field
                                                                editLabelsFormProps.form?.setFieldValue(
                                                                    ['labels', name, 'logo'],
                                                                    imageUrl
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <p className="ant-upload-text">
                                                            Drag & drop a label image here
                                                        </p>
                                                    </Upload.Dragger>
                                                </Form.Item>

                                                <Space direction="vertical" style={{ width: '100%' }}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'chain_ids']}
                                                        label={
                                                            <Space>
                                                                Applied on Chains
                                                                <Tooltip title="Select the chains where this label should be applied">
                                                                    <QuestionCircleOutlined/>
                                                                </Tooltip>
                                                            </Space>
                                                        }
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Select chains"
                                                            options={Object.entries(CHAIN_MAPPING).map(([chain_id, name]) => ({
                                                                label: (
                                                                    <Space>
                                                                        <ChainAvatar chainId={chain_id} size="small" />
                                                                        {name}
                                                                    </Space>
                                                                ),
                                                                value: chain_id
                                                            }))}
                                                        />
                                                    </Form.Item>
                                                </Space>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'main_label_chain_ids']}
                                                    label={
                                                        <Space>
                                                            Main Label on Chains
                                                            <Tooltip title="Designate this label as the main label for specific chains">
                                                                <QuestionCircleOutlined/>
                                                            </Tooltip>
                                                        </Space>
                                                    }
                                                    dependencies={['chain_ids']}
                                                    rules={[
                                                        {
                                                            validator: async (_, value) => {
                                                                if (!value) return Promise.resolve();
                                                                
                                                                const allValues = editLabelsFormProps.form?.getFieldsValue() as {
                                                                    labels: Array<{
                                                                        address_name: string;
                                                                        chain_ids: string[];
                                                                        main_label_chain_ids: string[];
                                                                    }>;
                                                                };
                                                                
                                                                // Check for duplicates across other labels
                                                                const otherLabelsChainIds = allValues.labels
                                                                    .filter((_: any, index: number) => index !== name)
                                                                    .flatMap((label: any) => label.main_label_chain_ids || []);

                                                                const duplicateChains = value.filter((chainId: string) => 
                                                                    otherLabelsChainIds.includes(chainId)
                                                                );

                                                                if (duplicateChains.length > 0) {
                                                                    const chainNames = duplicateChains
                                                                        .map((chainId: string) => CHAIN_MAPPING[chainId])
                                                                        .join(', ');
                                                                    throw new Error(
                                                                        `Chain(s) ${chainNames} already selected as main label in another label`
                                                                    );
                                                                }

                                                                // Validate against selected chains
                                                                const currentLabel = allValues.labels[name];
                                                                const selectedChains = currentLabel.chain_ids || [];

                                                                const invalidChains = value.filter(
                                                                    (chainId: string) => !selectedChains.includes(chainId)
                                                                );

                                                                if (invalidChains.length > 0) {
                                                                    const chainNames = invalidChains
                                                                        .map((chainId: string) => CHAIN_MAPPING[chainId])
                                                                        .join(', ');
                                                                    throw new Error(
                                                                        `Chain(s) ${chainNames} must be selected in "Applied on Chains" first`
                                                                    );
                                                                }
                                                            }
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        style={{ width: '100%' }}
                                                        placeholder="Select chains"
                                                        options={Object.entries(CHAIN_MAPPING).map(([chain_id, name]) => ({
                                                            label: (
                                                                <Space>
                                                                    <ChainAvatar chainId={chain_id} size="small" />
                                                                    {name}
                                                                </Space>
                                                            ),
                                                            value: chain_id
                                                        }))}
                                                    />
                                                </Form.Item>

                                                <Button type="link" onClick={() => remove(name)} danger>
                                                    Remove Label
                                                </Button>
                                            </Space>
                                        </div>
                                    ))}
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                        Add Label
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    </Form>
                </Modal>

                {/* Entities Modal */}
                <Modal
                    {...editEntitiesModalProps}
                    title="Edit Entities"
                    width={800}
                    centered
                    afterOpenChange={() => {
                        const initialEntities = getAllEntities()?.map((entity: IEntity) => {
                            return {
                                id: entity.id,
                                name: entity.name,
                                type: entity.type,
                                chain_ids: entity.chain_ids || [],
                                main_chain_ids: entity.main_chain_ids || []
                            };
                        }) || [];

                        editEntitiesFormProps.form?.setFieldsValue({
                            entities: initialEntities
                        });
                    }}
                >
                    <Alert
                        message="Entity Management"
                        description="Associate this address with entities and specify which entities are main identifiers for specific chains."
                        type="info"
                        showIcon
                        style={{ marginBottom: 16 }}
                    />
                    <Form {...editEntitiesFormProps}>
                        <Form.List name="entities">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <div key={key} style={{ marginBottom: 16, border: '1px solid #f0f0f0', padding: 16, borderRadius: 8 }}>
                                            <Space direction="vertical" style={{ width: '100%' }}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'name']}
                                                    label={
                                                        <Space>
                                                            Entity
                                                            <Tooltip title="Select an entity to associate with this address">
                                                                <QuestionCircleOutlined/>
                                                            </Tooltip>
                                                        </Space>
                                                    }
                                                    rules={[{ required: true, message: 'Please select an entity' }]}
                                                >
                                                <DebounceSelect
                                                    disabled={!!editEntitiesFormProps.form?.getFieldValue(['entities', name, 'id'])}
                                                    placeholder="Select entity"
                                                    labelInValue
                                                    mode="multiple"
                                                    showSearch
                                                    defaultOpen={true}
                                                    fetchOptions={async (search: string) => {
                                                        const options = await fetchEntityList(search);
                                                        return options.filter((option: any) => !getAllEntities().map((e: any) => e.id).includes(option.value))
                                                            .sort((a: any, b: any) => 
                                                                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                                                            );
                                                    }}
                                                    onFocus={() => {
                                                        // Trigger initial search with empty string
                                                        fetchEntityList('');
                                                    }}
                                                    onChange={(value) => {
                                                        const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
                                                        
                                                        // Ensure we preserve the ID if it exists
                                                        const currentEntity = editEntitiesFormProps.form?.getFieldValue(['entities', name]);
                                                        const entityId = currentEntity?.id || lastValue?.value;
                                                        
                                                        editEntitiesFormProps.form?.setFieldValue(
                                                            ['entities', name], 
                                                            {
                                                                id: entityId,
                                                                value: entityId,
                                                                label: lastValue.label,
                                                                name: lastValue.label,
                                                                chain_ids: editEntitiesFormProps.form?.getFieldValue(['entities', name, 'chain_ids']) || [],
                                                                main_chain_ids: editEntitiesFormProps.form?.getFieldValue(['entities', name, 'main_chain_ids']) || []
                                                            }
                                                        );
                                                    }}
                                                    tagRender={({ label, value, closable, onClose }) => {
                                                        // Existing entity
                                                        const entityObj = getAllEntities().find((e: any) => e.id === value || e.name === label);                                                        
                                                        // Check if it's a new entity
                                                        if (value === label && !entityObj) {
                                                            return (
                                                                <Tag 
                                                                    closable={false} 
                                                                    onClose={onClose} 
                                                                    style={{ marginRight: 3 }}
                                                                >
                                                                    {value}
                                                                </Tag>
                                                            );
                                                        }
                                                        
                                                        return (
                                                            <Tag 
                                                                closable={false} 
                                                                onClose={onClose} 
                                                                style={{ marginRight: 3 }}
                                                                color={entityObj?.type ? ENTITY_TYPE_COLORS[entityObj.type as EntityType] : undefined}
                                                            >
                                                                {entityObj?.name || label}
                                                            </Tag>
                                                        );
                                                    }}
                                                />
                                                </Form.Item>

                                                {/* Hidden field to store the entity ID */}
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'id']}
                                                    hidden
                                                >
                                                    <Input />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'chain_ids']}
                                                    label={
                                                        <Space>
                                                            Applied on Chains
                                                            <Tooltip title="Select the chains where this entity should be applied">
                                                                <QuestionCircleOutlined/>
                                                            </Tooltip>
                                                        </Space>
                                                    }
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        style={{ width: '100%' }}
                                                        placeholder="Select chains"
                                                        options={Object.entries(CHAIN_MAPPING).map(([chain_id, name]) => ({
                                                            label: (
                                                                <Space>
                                                                    <ChainAvatar chainId={chain_id} size="small" />
                                                                    {name}
                                                                </Space>
                                                            ),
                                                            value: chain_id
                                                        }))}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'main_chain_ids']}
                                                    label={
                                                        <Space>
                                                            Main Entity on Chains
                                                            <Tooltip title="Designate this entity as the main entity for specific chains">
                                                                <QuestionCircleOutlined/>
                                                            </Tooltip>
                                                        </Space>
                                                    }
                                                    dependencies={['chain_ids']}
                                                    rules={[
                                                        {
                                                            validator: async (_, value) => {
                                                                if (!value) return Promise.resolve();
                                                                
                                                                const allValues = editEntitiesFormProps.form?.getFieldsValue() as {
                                                                    entities: Array<{
                                                                        entity_id: string;
                                                                        chain_ids: string[];
                                                                        main_chain_ids: string[];
                                                                    }>;
                                                                };     

                                                                // Check for duplicates across other entities
                                                                const otherEntitiesChainIds = allValues.entities
                                                                    .filter((_: any, index: number) => index !== name)
                                                                    .flatMap((entity: any) => entity.main_chain_ids || []);

                                                                const duplicateChains = value.filter((chainId: string) => 
                                                                    otherEntitiesChainIds.includes(chainId)
                                                                );

                                                                if (duplicateChains.length > 0) {
                                                                    const chainNames = duplicateChains
                                                                        .map((chainId: string) => CHAIN_MAPPING[chainId])
                                                                        .join(', ');
                                                                    throw new Error(
                                                                        `Chain(s) ${chainNames} already selected as main entity in another entity`
                                                                    );
                                                                }

                                                                // Validate against selected chains
                                                                const currentEntity = allValues.entities[name];
                                                                const selectedChains = currentEntity.chain_ids || [];

                                                                const invalidChains = value.filter(
                                                                    (chainId: string) => !selectedChains.includes(chainId)
                                                                );

                                                                if (invalidChains.length > 0) {
                                                                    const chainNames = invalidChains
                                                                        .map((chainId: string) => CHAIN_MAPPING[chainId])
                                                                        .join(', ');
                                                                    throw new Error(
                                                                        `Chain(s) ${chainNames} must be selected in "Applied on Chains" first`
                                                                    );
                                                                }
                                                            }
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        style={{ width: '100%' }}
                                                        placeholder="Select chains"
                                                        options={Object.entries(CHAIN_MAPPING).map(([chain_id, name]) => ({
                                                            label: (
                                                                <Space>
                                                                    <ChainAvatar chainId={chain_id} size="small" />
                                                                    {name}
                                                                </Space>
                                                            ),
                                                            value: chain_id
                                                        }))}
                                                    />
                                                </Form.Item>

                                                <Button type="link" onClick={() => remove(name)} danger>
                                                    Remove Entity
                                                </Button>
                                            </Space>
                                        </div>
                                    ))}
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                        Add Entity
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    </Form>
                </Modal>

                <Card>
                    <Space>
                        <Button 
                            onClick={() => showEditTagsModal(record?.address)} 
                            icon={<TagsOutlined/>}
                        >
                            Edit Tags
                        </Button>
                    </Space>
                </Card>

                {/* Tags Modal */}
                <Modal
                    {...editTagsModalProps}
                    title="Edit Tags"
                    width={800}
                    centered
                    afterOpenChange={() => {
                        editTagsFormProps.form?.setFieldsValue({
                            tags: getAllTags()?.map((tag: ITag) => tag.id)
                        });
                    }}
                >
                    <Alert
                        message="Tag Management"
                        description="Select multiple tags to associate with this address. You can add or remove tags as needed."
                        type="info"
                        showIcon
                        style={{ marginBottom: 16 }}
                    />
                    <Form {...editTagsFormProps}>
                        <Form.Item
                            name="tags"
                            rules={[{ required: false }]}
                        >
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                placeholder="Select tags"
                                options={getAllTags()
                                    .map((tag: ITag) => ({
                                        label: (
                                            <Tag color={`#${tag.color}`}>
                                                {tag.name}
                                            </Tag>
                                        ),
                                        value: tag.id,
                                        color: tag.color
                                    }))
                                    .sort((a: any, b: any) => {
                                        const labelA = a.label.props.children.toLowerCase();
                                        const labelB = b.label.props.children.toLowerCase();
                                        return labelA.localeCompare(labelB);
                                    })
                                }
                                filterOption={(input, option) =>
                                    option?.label &&
                                    typeof option.label === 'object' && 
                                    'props' in option.label &&
                                    typeof option.label.props.children === 'string' &&
                                    option.label.props.children.toLowerCase().includes(input.toLowerCase())
                                }
                                tagRender={({ label, value, closable, onClose }) => {
                                    // Check if it's a new tag (value will equal label)
                                    if (value === label) {
                                        return (
                                            <Tag 
                                                closable={closable} 
                                                onClose={onClose} 
                                                style={{ marginRight: 3 }}
                                            >
                                                {value}
                                            </Tag>
                                        );
                                    }
                                    
                                    // Existing tag
                                    const tagObj = getAllTags().find((tag: ITag) => tag.id === value);
                                    return (
                                        <Tag 
                                            closable={closable} 
                                            onClose={onClose} 
                                            style={{ marginRight: 3 }}
                                            color={tagObj?.color ? `#${tagObj.color}` : undefined}
                                        >
                                            {tagObj?.name}
                                        </Tag>
                                    );
                                }}
                                onChange={(values: any) => {
                                    const formattedValues = values.map((value: any) => {
                                        const tagObj = getAllTags().find((tag: ITag) => tag.id === value);
                                        if (tagObj) {
                                            return {
                                                label: tagObj.name,
                                                value: tagObj.id,
                                                color: tagObj.color
                                            };
                                        }
                                        // New tag
                                        return {
                                            label: value,
                                            value: value,
                                            color: undefined
                                        };
                                    });
                                    editTagsFormProps.form?.setFieldValue('tags', formattedValues);
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Modal>

                <Card>                   
                    <Button
                        type="primary"
                        onClick={() => {
                            showSharedWithModal(record?.address);  
                        }}
                        icon={<TeamOutlined/>}
                    >
                        Shared With
                    </Button>
                </Card>
                {/* Add this modal with your other modals */}
                <Modal {...sharedWithModalProps}
                    title="Shared With"
                    width={800}
                    centered
                    afterOpenChange={(visible) => {
                        if (visible) {
                                sharedWithFormProps.form?.setFieldsValue({
                                    shared_with: getAllSharedWith()?.map((user: any) => ({
                                        label: user.name,
                                        value: user.id
                                    })) || []
                                });
                            fetchUserList('');
                        }
                    }}
                >
                    <Form {...sharedWithFormProps} layout="vertical">
                        <Alert
                            message="Share Management"
                            description="Share this address with other users to grant them access."
                            type="info"
                            showIcon
                            style={{ marginBottom: 16 }}
                        />
                        <Form.Item name="shared_with">
                            <DebounceSelect
                                mode="multiple"
                                placeholder="Select users"
                                fetchOptions={fetchUserList}
                                tagRender={({ label, value, closable, onClose }: any) => (
                                    <Tag
                                        closable={closable}
                                        onClose={onClose}
                                        style={{ marginRight: 3 }}
                                    >
                                        {label}
                                    </Tag>
                                )}
                                optionRender={(option) => (
                                    <Space>
                                        <Avatar size="small">
                                            {option?.label?.toString()?.substring(0, 2).toUpperCase()}
                                        </Avatar>
                                        <span>{option?.label}</span>
                                        <Typography.Text type="secondary">
                                            ({option?.value})
                                        </Typography.Text>
                                    </Space>
                                )}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </Space>
        </Edit>
    );
};




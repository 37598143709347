import React, { useEffect, useState } from 'react';
import { useOne } from "@refinedev/core";
import { Show, RefreshButton } from "@refinedev/antd";
import { 
    Card, 
    Row, 
    Col, 
    Typography, 
    Space, 
    Statistic, 
    Progress, 
    Alert, 
    Tooltip, 
    Spin,
    Badge,
    Tabs,
    Switch,
    notification
} from "antd";
import {
    TransactionOutlined,
    RiseOutlined,
    LoadingOutlined,
    DashboardOutlined,
    BarChartOutlined,
    NodeIndexOutlined
} from "@ant-design/icons";
import { useConfig } from '../../contexts/config';
import { Transaction, TransactionTable } from "components/transactions/table";
import { TransactionGraph } from 'components/graph/TransactionGraph';
import { CHAIN_MAPPING } from "../../interfaces/chain.d";
import { ISubscription } from "../../interfaces";
import { useSubscriptions } from '../../contexts/subscriptions/useSubscriptions';
import { createDebugger } from '../../contexts/leader/debug';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

export const DashboardList: React.FC = () => {
    const { org_id, tenant_id, user_id, user, token } = useConfig();
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);
    const [graphKey, setGraphKey] = useState(0);

    // Fetch transaction data
    const { data: transactionData, isLoading, refetch } = useOne({
        resource: "transactions",
        id: "",
        meta: { /* your meta data */ },
        queryOptions: {
            enabled: true,
        },        
    });

    // Fetch subscription data for import progress
    const { data: subscriptionData, refetch: refetchSubscription } = useOne<ISubscription>({
        resource: "subscriptions",
        id: "main",
    });

    // Update filtered transactions when main transactions change
    useEffect(() => {
        // Ensure unique transactions by chain_id and hash
        const uniqueTransactions = filteredTransactions?.reduce((acc, tx) => {
            const key = `${tx.chain_id}_${tx.hash}`;
            acc[key] = tx;
            return acc;
        }, {} as Record<string, Transaction>);

        setFilteredTransactions(Object.values(uniqueTransactions || []));
    }, [transactions]);

    
    const { status, isLeader, controls} = useSubscriptions({
        channels: [
            {
                name: `${org_id}-${tenant_id}-${user_id}-streamed-transactions`,
                onMessage: (message) => {
                    // Broadcast messages have a channel property
                    if (message.data?.channel) {
                        message = message.data;
                    }                    
                    console.log('Message from transactions channel:', message.data);

                    const transaction = message.data;
                    notification.open({
                        message: 'New transaction',
                        description: `New transaction: ${transaction.hash}`
                    });
                    
                    setTransactions(prev => {
                        // Create unique key for transaction
                        const txKey = `${transaction.chain_id}_${transaction.hash}`;
                        
                        // Find if transaction already exists
                        const existingIndex = prev.findIndex(tx => 
                            `${tx.chain_id}_${tx.hash}` === txKey
                        );

                        if (existingIndex >= 0) {
                            // Replace existing transaction
                            const updated = [...prev];
                            updated[existingIndex] = {
                                ...prev[existingIndex],
                                ...transaction,
                                updatedAt: new Date().toISOString()
                            };
                            return updated;
                        } else {
                            // Add new transaction
                            return [...prev, transaction];
                        }
                    });
                }
            },
            // You can add more channels here
            {
                name: `${org_id}-${tenant_id}-${user_id}-address-watch`,
                onMessage: (message) => {
                    // Handle address updates
                    console.log('Address update:', message.data);
                }
            }
        ],
        token: token.__raw,
        orgId: org_id,
        tenantId: tenant_id,
        userId: user_id,
        debug: createDebugger({
            prefix: 'Subscriptions',
            tabId: `Tab-${Math.random().toString(36).substr(2, 5)}`
        }),
        // Optional global message handler
        onMessage: (message) => {
            console.log(`Message from ${message.data?.channel}:`, message.data);
        },
        // Optional error handler
        onError: (error) => {
            console.error('Subscription error:', error);
        }
    });

    // Use status to show connection state
    useEffect(() => {
        console.log('Status:', status);
        if (status.transactions?.state === 'error') {
            notification.error({
                message: 'Connection Error',
                description: status.transactions.error
            });
        }
    }, [status]);

    const renderImportProgress = () => {
        const {
            total_transactions_in_progress = 0,
            total_transactions_to_be_processed = 0,
            total_transactions_processed = 0,
            total_transactions_to_import = 0,
            max_nr_of_moralis_transactions_per_day = 0,
            superseded_daily_spend = false
        } = subscriptionData?.data || {};

        return (
            <Card className="dashboard-stats-card">
                <Row gutter={[24, 24]}>
                    <Col xs={24} lg={6}>
                        <Statistic 
                            title="Processed Transactions"
                            value={total_transactions_processed}
                            prefix={<Badge status="success" />}
                        />
                    </Col>
                    <Col xs={24} lg={6}>
                        <Statistic 
                            title="In Progress"
                            value={total_transactions_in_progress}
                            prefix={<LoadingOutlined />}
                        />
                    </Col>
                    <Col xs={24} lg={6}>
                        <Statistic 
                            title="Pending"
                            value={total_transactions_to_be_processed}
                            prefix={<Badge status="warning" />}
                        />
                    </Col>
                    <Col xs={24} lg={6}>
                        <Statistic 
                            title="Daily Limit"
                            value={max_nr_of_moralis_transactions_per_day}
                            prefix={<RiseOutlined />}
                        />
                    </Col>
                    <Col span={24}>
                        <Progress 
                            percent={Math.round((Number(total_transactions_processed) / Number(total_transactions_to_import)) * 100)}
                            success={{ percent: (Number(total_transactions_processed) / Number(total_transactions_to_import)) * 100 }}
                            format={percent => `${percent}% Complete`}
                        />
                    </Col>
                    {superseded_daily_spend && (
                        <Col span={24}>
                            <Alert
                                message="Daily Import Limit Reached"
                                description="You've reached your daily transaction import limit. Consider upgrading your plan for higher limits."
                                type="warning"
                                showIcon
                            />
                        </Col>
                    )}
                </Row>
            </Card>
        );
    };

    return (
        <Show
            title={
                <Space>
                    <DashboardOutlined />
                    <Title level={3}>Dashboard</Title>
                </Space>
            }
            headerButtons={[
                <RefreshButton key="refresh" loading={isLoading} onClick={() => refetch()} />
            ]}
        >
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                {renderImportProgress()}
                <Switch 
                checked={status[`${org_id}-${tenant_id}-${user_id}-streamed-transactions`]?.state === 'connected'}
                onChange={(checked) => {
                    controls.toggleSubscription();
                }}
                checkedChildren="Live Updates On"
                unCheckedChildren="Live Updates Off"
            />                
                <Tabs defaultActiveKey="1">
                    <TabPane 
                        tab={<><TransactionOutlined /> Transactions</>} 
                        key="1"
                    >
                        <Card>
                            <TransactionTable
                                tableId="dashboard"
                                searchAddress={''}
                                initialPaginationCursors={{
                                    moralis: {},
                                    database: {},
                                    status: {}
                                }}
                                address_items_map={transactionData?.data?.address_items_map || {}}
                                activeChains={[]}
                                selectedChains={[]}
                                identifier={String(transactionData?.data?.id) || ''}
                                transactions={transactions}
                                setTransactions={setTransactions}
                                setGraphKey={setGraphKey}
                                onTransactionDataChange={setFilteredTransactions}
                                {...transactionData?.data}
                            />
                        </Card>
                    </TabPane>
                    
                    <TabPane 
                        tab={<><NodeIndexOutlined /> Graph View</>} 
                        key="2"
                    >
                        <Card>
                            <TransactionGraph 
                                data={transactions}
                                filteredData={filteredTransactions}
                                chainIdToName={CHAIN_MAPPING}
                                graphKey={graphKey}
                                setGraphKey={setGraphKey}
                                cdn_domain_name={process.env.REACT_APP_CDN_DOMAIN_NAME || ''}
                                address_items_map={transactionData?.data?.address_items_map || {}}
                                subscriptionStatus={{
                                    isLeader: isLeader,
                                    isEnabled: true,
                                    orgId: org_id,
                                    tenantId: tenant_id,
                                    userId: user_id,
                                    addresses: [/* your watched addresses */],
                                    state: status.transactions?.state || 'disconnected'
                                }}
                            />
                        </Card>
                    </TabPane>

                    <TabPane 
                        tab={<><BarChartOutlined /> Analytics</>} 
                        key="3"
                    >
                        <iframe
                            src={`https://pdx.${process.env.REACT_APP_CONTINENT}.private.${process.env.REACT_APP_DOMAIN}/private/analytics/metabase/public/dashboard/7e63f6bc-c189-4298-b2f6-4108c2a5b4e7?org_id_tenant_id=${org_id}-${tenant_id}&user_id=${user_id}#hide_parameters=org_id_tenant_id,user_id`}
                            frameBorder={0}
                            width="100%"
                            height={800}
                            allowTransparency
                        />
                    </TabPane>
                </Tabs>
            </Space>
        </Show>
    );
};
import React, { useState, useEffect } from 'react';
import { Card, Space, Typography, Tag, Avatar, Input, Button, message, Select, Tooltip, Modal } from 'antd';
import { 
    SwapOutlined, 
    ShoppingOutlined, 
    BorderOutlined,
    WalletOutlined,
    AppstoreOutlined,
    EditOutlined,
    SaveOutlined,
    CloseOutlined,
    StarFilled,
    DollarOutlined,
    PictureOutlined,
    ApiOutlined,
    CodeOutlined
} from '@ant-design/icons';
import { IAddress, IAddressItem, IEntity, EntityType, ENTITY_TYPE_COLORS, ITransaction } from '../../../interfaces';
import { CHAIN_MAPPING } from "../../../interfaces/chain.d";
import { renderAddressAvatar } from '../../../components/address-avatar';
import { extractAddressesFromTransaction } from '../../../utils/extractAddresses';
import type { AddressLocation } from '../../../utils/extractAddresses';
import ContractStateViewerWithABI from 'components/ContractStateViewer/ContractStateViewerWithABI';
import { useConfig } from 'contexts/config';


const { Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

interface EntityCard {
    id?: string;
    logo?: string;
    name: string;
    type: string;
}

interface TransactionContextProps {
    category?: string;
    summary?: string;
    entities?: EntityCard[];
    notes?: string;
    onNotesChange?: (notes: string) => Promise<void>;
    transactionId?: string;
    addressItemsMap: Record<string, IAddressItem>;
    transactionRecord: any;
    chainId?: string;
    onAddressSelect?: (address: string) => void;
}

const getCategoryIcon = (category?: string) => {
    switch (category?.toLowerCase()) {
        case 'dex trade':
            return <SwapOutlined />;
        case 'nft':
            return <ShoppingOutlined />;
        case 'bridge':
            return <BorderOutlined />;
        case 'transfer':
            return <WalletOutlined />;
        default:
            return <AppstoreOutlined />;
    }
};

interface AddressSelectorProps {
    addressItemsMap: Record<string, IAddressItem>;
    onSelect?: (address: string) => void;
    value?: string;
    chainId?: string;
    transactionRecord: any;
}

const AddressSelector: React.FC<AddressSelectorProps> = ({
    addressItemsMap,
    onSelect,
    value,
    chainId,
    transactionRecord
}) => {
    const [expandedAddresses, setExpandedAddresses] = useState<Set<string>>(new Set());
    const addressesWithLocations = extractAddressesFromTransaction(transactionRecord, true);
    console.log("addressesWithLocations", addressesWithLocations);
    const getLocationColor = (type: string) => {
        switch (type) {
            case 'main': return 'blue';
            case 'native_transfer': return 'green';
            case 'erc20_transfer': return 'purple';
            case 'nft_transfer': return 'orange';
            case 'decoded_call': return 'cyan';
            case 'decoded_call_contract': return 'geekblue';
            case 'decoded_event': return 'magenta';
            case 'decoded_event_contract': return 'volcano';
            case 'proxy_target': return 'gold';
            case 'proxy_param': return 'lime';
            case 'internal_tx': return 'red';
            case 'internal_tx_param': return 'pink';
            default: return 'default';
        }
    };

    const getLocationIcon = (type: string) => {
        switch (type) {
            case 'main': return <WalletOutlined />;
            case 'native_transfer': return <SwapOutlined />;
            case 'erc20_transfer': return <DollarOutlined />;
            case 'nft_transfer': return <PictureOutlined />;
            case 'decoded_call': return <AppstoreOutlined />;
            case 'decoded_call_contract': return <CodeOutlined />;
            case 'decoded_event': return <AppstoreOutlined />;
            case 'decoded_event_contract': return <CodeOutlined />;
            case 'proxy_target': return <ApiOutlined />;
            case 'proxy_param': return <ApiOutlined />;
            case 'internal_tx': return <SwapOutlined />;
            case 'internal_tx_param': return <AppstoreOutlined />;
            default: return <AppstoreOutlined />;
        }
    };

    const formatLocationType = (type: string) => {
        return type.split('_').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    };

    // Sort addresses by priority
    const sortedAddresses = addressesWithLocations.sort((a, b) => {
        const aIsMain = a.locations.some(loc => loc.type === 'main');
        const bIsMain = b.locations.some(loc => loc.type === 'main');
        if (aIsMain && !bIsMain) return -1;
        if (!aIsMain && bIsMain) return 1;

        const aInMap = addressItemsMap[a.address];
        const bInMap = addressItemsMap[b.address];
        if (aInMap && !bInMap) return -1;
        if (!aInMap && bInMap) return 1;

        return 0;
    });

    const toggleExpand = (address: string, e: React.MouseEvent) => {
        e.stopPropagation();
        setExpandedAddresses(prev => {
            const next = new Set(prev);
            if (next.has(address)) {
                next.delete(address);
            } else {
                next.add(address);
            }
            return next;
        });
    };

    const renderLocationTags = (address: string, locations: AddressLocation['locations']) => {
        const isExpanded = expandedAddresses.has(address);
        const MAX_VISIBLE_TAGS = 3;
        const visibleLocations = isExpanded ? locations : locations.slice(0, MAX_VISIBLE_TAGS);
        
        return (
            <Space wrap size={[0, 8]} style={{ maxWidth: '100%' }}>
                <Text type="secondary" style={{ fontSize: '12px' }}>Found in:</Text>
                {visibleLocations.map((location, index) => (
                    <Tooltip key={index} title={location.description}>
                        <Tag color={getLocationColor(location.type)}>
                            {getLocationIcon(location.type)} {formatLocationType(location.type)}
                        </Tag>
                    </Tooltip>
                ))}
                {locations.length > MAX_VISIBLE_TAGS && (
                    <Button 
                        type="link" 
                        size="small"
                        onClick={(e) => toggleExpand(address, e)}
                        style={{ padding: '0 4px' }}
                    >
                        {isExpanded ? 'Show Less' : `+${locations.length - MAX_VISIBLE_TAGS} more`}
                    </Button>
                )}
            </Space>
        );
    };

    const renderOption = (addressLocation: AddressLocation) => {
        const address = addressLocation.address;
        const item = addressItemsMap[address];
        
        return (
            <Option key={address} value={address}>
                <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <Space>
                        <Text copyable style={{ fontSize: '14px' }}>
                            {address}
                        </Text>
                        {addressLocation.locations.some(loc => loc.type === 'main')}
                    </Space>

                    {renderLocationTags(address, addressLocation.locations)}

                    {item && renderAddressAvatar(
                        { 
                            chain_id: chainId,
                            address_items_map: { [address]: item }
                        },
                        'from',
                        address,
                        {
                            maxGlobalLabels: 5,
                            maxUserLabels: 5,
                            maxTotalLabels: 10,
                            maxGlobalEntities: 5,
                            maxUserEntities: 5,
                            maxTotalEntities: 10,
                            displayStyle: 'tags',
                            hideAddress: true
                        }
                    )}
                </Space>
            </Option>
        );
    };

    // Calculate required height based on selected value
    const getSelectHeight = () => {
        if (!value) return undefined;
        
        const selectedAddress = addressesWithLocations.find(a => a.address === value);
        if (!selectedAddress) return undefined;

        const item = addressItemsMap[value];
        let height = 60; // base height

        // Add height for location tags
        if (selectedAddress.locations.length > 0) {
            height += 60; // height for "Found in" section
        }

        // Add height for labels/entities
        if (item?.labels?.length || item?.entities?.length) {
            height += 60;
        }

        return height;
    };

    return (
        <Select
            showSearch
            style={{ 
                width: '100%',
                height: getSelectHeight()
            }}
            placeholder="Select an address"
            optionFilterProp="children"
            value={value}
            onChange={onSelect}
            filterOption={(input, option) => 
                option?.key?.toString().toLowerCase().includes(input.toLowerCase()) ?? false
            }
            listItemHeight={70}
            listHeight={256}
        >
            {sortedAddresses.map(renderOption)}
        </Select>
    );
};

export const TransactionContext: React.FC<TransactionContextProps> = ({
    category,
    summary,
    entities,
    notes: initialNotes = '',
    onNotesChange,
    transactionId,
    addressItemsMap,
    transactionRecord,
    chainId,
    onAddressSelect
}) => {
    const [notes, setNotes] = useState(initialNotes);
    const [tempNotes, setTempNotes] = useState(initialNotes);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState<string>();

    useEffect(() => {
        setNotes(initialNotes);
        setTempNotes(initialNotes);
    }, [initialNotes]);

    const handleSave = async () => {
        if (!onNotesChange) return;
        
        setIsSaving(true);
        try {
            await onNotesChange(tempNotes);
            setNotes(tempNotes);
            setIsEditing(false);
            message.success('Notes saved successfully');
        } catch (error) {
            message.error('Failed to save notes');
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = () => {
        setTempNotes(notes);
        setIsEditing(false);
    };

    const { domain, region, token } = useConfig();

    return (
        <Card 
            title="Transaction Context" 
            size="small"
            style={{ 
                position: 'sticky',
                top: 24,
                marginLeft: 16
            }}
        >
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                {/* Address Selector */}
                <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <Text type="secondary">Addresses</Text>
                    <AddressSelector 
                        addressItemsMap={addressItemsMap}
                        value={selectedAddress}
                        onSelect={(address) => {
                            setSelectedAddress(address);
                            console.log("selectedAddress", address);
                            onAddressSelect?.(address);
                        }}
                        chainId={chainId}
                        transactionRecord={transactionRecord}
                    />
                </Space>

                {/* Category */}
                {category && (
                    <Space direction="vertical" size="small">
                        <Text type="secondary">Category</Text>
                        <Tag icon={getCategoryIcon(category)}>
                            {category}
                        </Tag>
                    </Space>
                )}

                {/* Summary */}
                {summary && (
                    <Space direction="vertical" size="small">
                        <Text type="secondary">Summary</Text>
                        <Paragraph style={{ marginBottom: 0 }}>
                            {summary}
                        </Paragraph>
                    </Space>
                )}
                {/* Entities */}
                {entities && entities.length > 0 && (
                    <Space direction="vertical" size="small" style={{ width: '100%' }}>
                        <Text type="secondary">Entities Involved in this Transaction</Text>
                        <Space wrap>
                            {(() => {
                                // Deduplicate entities
                                const uniqueEntities = new Map();
                                
                                // First pass: add all non-temporary entities
                                entities.forEach(entity => {
                                    const entityId = entity.id || entity.name;
                                    if (!entityId.startsWith('temp_entity_')) {
                                        uniqueEntities.set(entityId, entity);
                                    }
                                });
                                
                                // Second pass: add temporary entities only if no entity with the same name exists
                                entities.forEach(entity => {
                                    const entityId = entity.id || entity.name;
                                    if (entityId.startsWith('temp_entity_')) {
                                        // Check if we already have an entity with this name
                                        const exists = Array.from(uniqueEntities.values()).some(
                                            e => e.name === entity.name
                                        );
                                        
                                        if (!exists) {
                                            uniqueEntities.set(entityId, entity);
                                        }
                                    }
                                });
                                
                                // Return the deduplicated entities
                                return Array.from(uniqueEntities.values()).map((entity, index) => {
                                    // Only create links for non-temporary entities
                                    const isLinkable = entity.id?.startsWith("temp_entity_") || !entity.id ? entity.name : entity.id;
                                    const entityLink = entity.id?.startsWith("temp_entity_") || !entity.id ? `/entities/show/name=${entity.name}` : `/entities/show/${entity.id}`;
                                    const entityCard = (
                                        <Card 
                                            key={index}
                                            size="small"
                                            style={{ 
                                                width: 120,
                                                textAlign: 'center',
                                                marginBottom: 8,
                                                cursor: isLinkable ? 'pointer' : 'default'
                                            }}
                                            hoverable={isLinkable}
                                        >
                                            <Space direction="vertical" size="small">
                                                {entity.logo ? (
                                                    <Avatar 
                                                        size="large"
                                                        src={entity.logo}
                                                        style={{ marginBottom: 4 }}
                                                    />
                                                ) : (
                                                    <Avatar 
                                                        size="large"
                                                        style={{ marginBottom: 4 }}
                                                    >
                                                        {entity.name.charAt(0)}
                                                    </Avatar>
                                                )}
                                                <Text strong>{entity.name}</Text>
                                                <Tag>{entity.type}</Tag>
                                            </Space>
                                        </Card>
                                    );
                                    
                                    // Wrap in a link if it's a non-temporary entity
                                    if (isLinkable && entityLink) {
                                        return (
                                            <a 
                                                key={index}
                                                href={entityLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: 'none' }}
                                            >
                                                {entityCard}
                                            </a>
                                        );
                                    }
                                    
                                    return entityCard;
                                });
                            })()}
                        </Space>
                    </Space>
                )}

                {/* Notes Section */}
                <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                        <Text type="secondary">Notes</Text>
                        {!isEditing && (
                            <Button 
                                type="text" 
                                icon={<EditOutlined />} 
                                onClick={() => setIsEditing(true)}
                                size="small"
                            >
                                Edit
                            </Button>
                        )}
                    </Space>
                    
                    {isEditing ? (
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <TextArea
                                value={tempNotes}
                                onChange={(e) => setTempNotes(e.target.value)}
                                autoSize={{ minRows: 3, maxRows: 6 }}
                                placeholder="Add your notes about this transaction..."
                            />
                            <Space>
                                <Button 
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    onClick={handleSave}
                                    loading={isSaving}
                                    size="small"
                                >
                                    Save
                                </Button>
                                <Button 
                                    icon={<CloseOutlined />}
                                    onClick={handleCancel}
                                    size="small"
                                >
                                    Cancel
                                </Button>
                            </Space>
                        </Space>
                    ) : (
                        <Paragraph style={{ 
                            marginBottom: 0,
                            whiteSpace: 'pre-wrap',
                            minHeight: '22px'
                        }}>
                            {notes || 'No notes added yet.'}
                        </Paragraph>
                    )}
                </Space>
            </Space>
            
            <div style={{ 
                width: '100%', 
                marginTop: 16,
                overflow: 'hidden'
            }}>
                <ContractStateViewerWithABI
                    contractAddress={selectedAddress || ''}
                    chainId={chainId || ''}
                    title="Contract State"
                    transactionContext={{
                        selectedAddress: selectedAddress || '',
                        key: `${selectedAddress}_${chainId}`
                    }}
                    apiDomain={domain || ''}
                    region={region.use_region}
                    regionAlias={region.use_region_alias}
                    token={token.__raw}
                    key={`contract_viewer_${selectedAddress}_${chainId}`}
                />
            </div>
        </Card>
    );
}; 
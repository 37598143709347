import React from 'react';
import { Space, Typography, Tag, Tooltip, Avatar } from 'antd';
import { StarFilled } from '@ant-design/icons';
import type { HighlightedAddressProps } from '../types';

export const HighlightedAddress: React.FC<HighlightedAddressProps> = ({ 
    address, 
    searchAddress,
    address_items_map = {}
}) => (
    <Space direction="vertical" size={2}>
        <Space>
            <Typography.Text copyable style={{ whiteSpace: 'nowrap' }}>
                {address}
            </Typography.Text>
            {address === searchAddress && (
                <Tag color="gold">Searched Address</Tag>
            )}
        </Space>
        {address_items_map[address] && (
            <Space size={4} wrap>
                {/* Regular labels */}
                {address_items_map[address].labels.map((label) => (
                    <Tooltip 
                        key={label.id} 
                        title={
                            <>
                                {label.name}
                                {label.main_label_chain_ids && label.main_label_chain_ids.length > 0 && (
                                    <div>Active on {label.main_label_chain_ids.length} chains</div>
                                )}
                            </>}
                    >
                        <Tag 
                            color={`#${label.color}`}
                            style={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 4 
                            }}
                        >
                            {label.logo && (
                                <Avatar 
                                    src={label.logo} 
                                    size="small"
                                    style={{ flexShrink: 0 }}
                                >
                                    {label?.name?.substring(0, 2)}
                                </Avatar>
                            )}
                            {label?.name}
                        </Tag>
                    </Tooltip>
                ))}
                {/* Main labels by chain */}
                {Object.entries(address_items_map[address].main_labels || {}).map(([chainId, labels]) => 
                    labels.map((label) => (
                        <Tooltip 
                            key={`${label.id}-${chainId}`}
                            title={`${label.name} (Main label for chain ${chainId})`}
                        >
                            <Tag 
                                color={`#${label.color}`}
                                style={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: 4 
                                }}
                            >
                                {label.logo && (
                                    <Avatar 
                                        src={label.logo} 
                                        size="small"
                                        style={{ flexShrink: 0 }}
                                    >
                                        {label?.name?.substring(0, 2)}
                                    </Avatar>
                                )}
                                {label.name}
                                <StarFilled style={{ color: '#faad14', fontSize: '12px' }} />
                            </Tag>
                        </Tooltip>
                    ))
                )}
            </Space>
        )}
    </Space>
); 
import { Card, Row, Col, Statistic, Space, Avatar, Select, Tooltip, Empty, Table, Button, Divider, Tag } from 'antd';
import { useList, useOne } from '@refinedev/core';
import { 
    WalletOutlined, 
    SwapOutlined, 
    PictureOutlined,
    AppstoreOutlined,
    BlockOutlined,
    ArrowLeftOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { CHAIN_MAPPING } from "../../../interfaces/chain.d";
import { IWalletStats } from "../../../interfaces/address.d";
import { Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { shortenHash } from '../../../utils/formatters';
import { getExplorerTransactionLink } from '../../../utils/chainExplorer';
const { Text, Link } = Typography;
import { shortenAddress } from '../../../utils/formatters';

interface StatsTabProps {
    isEmbedded?: boolean;
    record: {
        address: string;
        active_chain_ids?: string[];
    };
}

// Update the response type to match the API structure
interface StatsResponse {
    request_id: string;
    results: {
        [chainId: string]: {
            data: IWalletStats[];
            cursor: string | null;
            has_more: boolean;
        };
    };
    errors: Record<string, any>;
}

interface IChainActivityResponse {
    request_id: string;
    results: {
        [chainId: string]: {
            data: IMoralisChainActivityResponse[];
            cursor: string | null;
            has_more: boolean;
        };
    };
    errors: Record<string, any>;
};

// Avatar components (similar to EntityShow)
export const ChainAvatar = ({ chainId, size = 'small' }: { 
    chainId: string;
    size?: 'small' | 'default' | 'large';
}) => (
    <Tooltip 
        title={CHAIN_MAPPING[chainId] || chainId}
        overlayStyle={{ zIndex: 1100 }}
    >
        <Avatar 
            src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`} 
            size={size}
            style={{ backgroundColor: '#f0f2f5' }}
        />
    </Tooltip>
);

interface ChainComparisonData {
    chainId: string;
    chainName: string;
    transactions: number;
    tokenTransfers: number;
    nftTransfers: number;
    nftsOwned: number;
    collections: number;
    firstTransaction?: string;
    lastTransaction?: string;
    daysSinceFirstTx?: number;
    daysSinceLastTx?: number;
}

export const StatsTab: React.FC<StatsTabProps> = ({ record, isEmbedded = false }) => {
    const availableChains = record?.active_chain_ids || [];
    const ALL_CHAINS = 'all';
    const [selectedChainId, setSelectedChainId] = useState<string>(ALL_CHAINS);

    const { data, isLoading, refetch } = useOne<StatsResponse>({
        resource: "stats",
        id: record?.address,
        meta: {
            address: record?.address,
            chain_ids: availableChains,
            limit: 1,
            operation: "getWalletStats",
        },
        queryOptions: {
            enabled: isEmbedded,
            refetchOnWindowFocus: false,
        },
    });

    const { data: chainActivity, isLoading: isLoadingActivity, refetch: refetchActivity } = useOne<IChainActivityResponse>({
        resource: "stats",
        id: record?.address,
        meta: {
            address: record?.address,
            operation: "getWalletActiveChains",
            chain_ids: availableChains,
        },
        queryOptions: {
            enabled: isEmbedded,
            refetchOnWindowFocus: false,
        },
    });

    useEffect(() => {
        if (!isEmbedded) {
            refetch();
            refetchActivity()
        }
    }, [availableChains]);

    // Aggregate stats across selected chains
    const aggregateStats = () => {
        if (!data?.data?.results) return null;

        // If a specific chain is selected, only show stats for that chain
        const relevantChains = selectedChainId === ALL_CHAINS 
            ? Object.keys(data?.data?.results)
            : [selectedChainId];

        return relevantChains.reduce((acc, chainId) => {
            const chainData = data?.data?.results[chainId];
            const stats = chainData?.data[0]; // Assuming first item is what we want
            if (!stats) return acc;

            return {
                transactions: {
                    total: Number(acc.transactions?.total || 0) + Number(stats.transactions?.total || 0),
                },
                token_transfers: {
                    total: Number(acc.token_transfers?.total || 0) + Number(stats.token_transfers?.total || 0),
                },
                nft_transfers: {
                    total: Number(acc.nft_transfers?.total || 0) + Number(stats.nft_transfers?.total || 0),
                },
                nfts: Number(acc.nfts || 0) + Number(stats.nfts || 0),
                collections: Number(acc.collections || 0) + Number(stats.collections || 0),
            };
        }, {
            transactions: { total: Number("0") },
            token_transfers: { total: Number("0") },
            nft_transfers: { total: Number("0") },
            nfts: Number("0"),
            collections: Number("0"),
        });
    };

    const stats = aggregateStats();

    // Separate function for getting all chains data
    const getAllChainsComparisonData = (): ChainComparisonData[] => {
        if (!data?.data?.results) return [];

        // Always use all available chains for the comparison table
        return Object.keys(data.data.results).map(chainId => {
            const chainData = data.data.results[chainId]?.data[0] || {};
            const activity = chainActivity?.data?.results[chainId]?.data?.[0]?.active_chains[0];
            
            const firstTxDate = activity?.first_transaction?.block_timestamp 
                ? new Date(activity.first_transaction.block_timestamp)
                : null;
            const lastTxDate = activity?.last_transaction?.block_timestamp
                ? new Date(activity.last_transaction.block_timestamp)
                : null;
            const now = new Date();

            return {
                chainId,
                chainName: CHAIN_MAPPING[chainId] || chainId,
                transactions: Number(chainData.transactions?.total || 0),
                tokenTransfers: Number(chainData.token_transfers?.total || 0),
                nftTransfers: Number(chainData.nft_transfers?.total || 0),
                nftsOwned: Number(chainData.nfts || 0),
                collections: Number(chainData.collections || 0),
                firstTransaction: firstTxDate?.toLocaleDateString(),
                lastTransaction: lastTxDate?.toLocaleDateString(),
                daysSinceFirstTx: firstTxDate 
                    ? Math.floor((now.getTime() - firstTxDate.getTime()) / (1000 * 60 * 60 * 24))
                    : undefined,
                daysSinceLastTx: lastTxDate
                    ? Math.floor((now.getTime() - lastTxDate.getTime()) / (1000 * 60 * 60 * 24))
                    : undefined,
            };
        });
    };

    const comparisonColumns: ColumnsType<ChainComparisonData> = [
        {
            title: 'Chain',
            dataIndex: 'chainName',
            key: 'chainName',
            render: (_, record) => (
                <Space>
                    <ChainAvatar chainId={record.chainId} size="small" />
                    <Text>{record.chainName}</Text>
                </Space>
            ),
            fixed: 'left',
        },
        {
            title: 'Transactions',
            dataIndex: 'transactions',
            key: 'transactions',
            sorter: (a, b) => a.transactions - b.transactions,
            render: value => Number(value).toLocaleString(),
        },
        {
            title: 'Token Transfers',
            dataIndex: 'tokenTransfers',
            key: 'tokenTransfers',
            sorter: (a, b) => a.tokenTransfers - b.tokenTransfers,
            render: value => Number(value).toLocaleString(),
        },
        {
            title: 'NFT Transfers',
            dataIndex: 'nftTransfers',
            key: 'nftTransfers',
            sorter: (a, b) => a.nftTransfers - b.nftTransfers,
            render: value => Number(value).toLocaleString(),
        },
        {
            title: 'NFTs Owned',
            dataIndex: 'nftsOwned',
            key: 'nftsOwned',
            sorter: (a, b) => a.nftsOwned - b.nftsOwned,
            render: value => Number(value).toLocaleString(),
        },
        {
            title: 'Collections',
            dataIndex: 'collections',
            key: 'collections',
            sorter: (a, b) => a.collections - b.collections,
            render: value => Number(value).toLocaleString(),
        },
        {
            title: 'First Transaction',
            dataIndex: 'firstTransaction',
            key: 'firstTransaction',
            render: (_, record) => {
                const activity = chainActivity?.data?.results[record.chainId]?.data?.[0]?.active_chains[0];
                const firstTx = activity?.first_transaction;
                
                if (!firstTx) return '-';

                const date = new Date(firstTx.block_timestamp);
                const daysAgo = Math.floor((new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

                return (
                    <Space direction="vertical" size="small">
                        <Text>{date.toLocaleDateString()} ({daysAgo} days ago)</Text>
                        <Space size="small">
                            <Text type="secondary">Block:</Text>
                            <Text>{firstTx.block_number}</Text>
                        </Space>
                        <Space size="small">
                            <Text type="secondary">Tx:</Text>
                            <Link 
                                href={getExplorerTransactionLink(record.chainId, firstTx.transaction_hash) || ""} 
                                target="_blank"
                            >
                                {shortenHash(firstTx.transaction_hash || "")}
                            </Link>
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: 'Last Transaction',
            dataIndex: 'lastTransaction',
            key: 'lastTransaction',
            render: (_, record) => {
                const activity = chainActivity?.data?.results[record.chainId]?.data?.[0]?.active_chains[0];
                const lastTx = activity?.last_transaction;
                
                if (!lastTx) return '-';

                const date = new Date(lastTx.block_timestamp);
                const daysAgo = Math.floor((new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

                return (
                    <Space direction="vertical" size="small">
                        <Text>{date.toLocaleDateString()} ({daysAgo} days ago)</Text>
                        <Space size="small">
                            <Text type="secondary">Block:</Text>
                            <Text>{lastTx.block_number}</Text>
                        </Space>
                        <Space size="small">
                            <Text type="secondary">Tx:</Text>
                            <Link 
                                href={getExplorerTransactionLink(record.chainId, lastTx.transaction_hash) || ""} 
                                target="_blank"
                            >
                                {shortenHash(lastTx.transaction_hash)}
                            </Link>
                        </Space>
                    </Space>
                );
            },
        },
    ];

    if (availableChains.length === 0) {
        return (
            <Card>
                <Empty 
                    description="No active chains found for this address" 
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            </Card>
        );
    }

    return (
        <Space 
            direction="vertical" 
            style={{ 
                width: '100%',
                ...(isEmbedded && {
                    height: 'calc(80vh - 180px)', // Match container height
                    minHeight: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '0 24px'
                })
            }} 
            size="large"
        >
            {isEmbedded && (
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Space align="center">
                        <Button 
                            type="link" 
                            icon={<ArrowLeftOutlined/>}
                            onClick={() => {
                                const overviewTab = document.querySelector('[data-node-key="overview"]');
                                if (overviewTab) {
                                    (overviewTab as HTMLElement).click();
                                }
                            }}
                        >
                            Back to Graph View
                        </Button>
                        <Divider type="vertical" />
                        <Tag color="warning">
                            <Space>
                                <Text type="secondary">Viewing Stats for</Text>
                                <Text strong copyable={{ text: record?.address }}>
                                    <Tooltip title={record?.address}>
                                        {shortenAddress(record?.address)}
                                    </Tooltip>
                                </Text>
                                <Text type="secondary">on</Text>
                                <Space>
                                    <ChainAvatar chainId={availableChains?.[0]} size="small" />
                                    <Text>{CHAIN_MAPPING[availableChains?.[0]] || availableChains?.[0]}</Text>
                                </Space>
                            </Space>
                        </Tag>
                    </Space>
                    <Button 
                        type="text" 
                        icon={<CloseOutlined/>}
                        onClick={() => {
                            const overviewTab = document.querySelector('[data-node-key="overview"]');
                            if (overviewTab) {
                                (overviewTab as HTMLElement).click();
                            }
                        }}
                    />
                </Space>
            )}

            <Card style={{
                ...(isEmbedded && {
                    height: 'calc(100% - 60px)', // Account for header
                    overflow: 'auto'
                })
            }}>
                <Space>
                    <Select
                        value={selectedChainId}
                        onChange={setSelectedChainId}
                        style={{ width: 250 }}
                    >
                        <Select.OptGroup label="All Active Chains">
                            <Select.Option key={ALL_CHAINS} value={ALL_CHAINS}>
                                <Space>
                                    <Avatar.Group 
                                        maxCount={3} 
                                        size="small"
                                        maxStyle={{ 
                                            color: '#f56a00', 
                                            backgroundColor: '#fde3cf',
                                        }}
                                    >
                                        {availableChains.map((chainId: string) => (
                                            <Tooltip 
                                                key={chainId} 
                                                title={CHAIN_MAPPING[chainId] || chainId}
                                                overlayStyle={{ zIndex: 1100 }}
                                            >
                                                <ChainAvatar chainId={chainId} size="small" />
                                            </Tooltip>
                                        ))}
                                    </Avatar.Group>
                                    <Text>All Active Chains</Text>
                                </Space>
                            </Select.Option>
                        </Select.OptGroup>

                        <Select.OptGroup label="Individual Chains">
                            {availableChains.map((chainId: string) => (
                                <Select.Option key={chainId} value={chainId}>
                                    <Space>
                                        <ChainAvatar chainId={chainId} size="small" />
                                        {CHAIN_MAPPING[chainId] || chainId}
                                    </Space>
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                    </Select>
                </Space>
            </Card>

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} lg={8}>
                    <Card loading={isLoading}>
                        <Statistic
                            title={
                                <Space>
                                    <BlockOutlined />
                                    <span>Total Transactions</span>
                                </Space>
                            }
                            value={stats?.transactions?.total || 0}
                            formatter={(value) => Number(value).toLocaleString()}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                    <Card loading={isLoading}>
                        <Statistic
                            title={
                                <Space>
                                    <SwapOutlined />
                                    <span>Token Transfers</span>
                                </Space>
                            }
                            value={stats?.token_transfers?.total || 0}
                            formatter={(value) => Number(value).toLocaleString()}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                    <Card loading={isLoading}>
                        <Statistic
                            title={
                                <Space>
                                    <PictureOutlined />
                                    <span>NFT Transfers</span>
                                </Space>
                            }
                            value={stats?.nft_transfers?.total || 0}
                            formatter={(value) => Number(value).toLocaleString()}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                    <Card loading={isLoading}>
                        <Statistic
                            title={
                                <Space>
                                    <PictureOutlined />
                                    <span>NFTs Owned</span>
                                </Space>
                            }
                            value={stats?.nfts || 0}
                            formatter={(value) => Number(value).toLocaleString()}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                    <Card loading={isLoading}>
                        <Statistic
                            title={
                                <Space>
                                    <AppstoreOutlined />
                                    <span>Collections</span>
                                </Space>
                            }
                            value={stats?.collections || 0}
                            formatter={(value) => Number(value).toLocaleString()}
                        />
                    </Card>
                </Col>
            </Row>

            {/* Chain Comparison Table */}
            {!isEmbedded && (
                <Card title="Chain Comparison">
                    <Table
                        columns={comparisonColumns}
                        dataSource={getAllChainsComparisonData()}
                        loading={isLoading || isLoadingActivity}
                        rowKey="chainId"
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                        size="middle"
                        summary={(pageData) => {
                            const totals = pageData.reduce(
                                (acc, curr) => ({
                                    transactions: acc.transactions + curr.transactions,
                                    tokenTransfers: acc.tokenTransfers + curr.tokenTransfers,
                                    nftTransfers: acc.nftTransfers + curr.nftTransfers,
                                    nftsOwned: acc.nftsOwned + curr.nftsOwned,
                                    collections: acc.collections + curr.collections,
                                }),
                                { transactions: 0, tokenTransfers: 0, nftTransfers: 0, nftsOwned: 0, collections: 0 }
                            );

                            return (
                                <Table.Summary fixed="bottom">
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>
                                            <Text strong>Total</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>
                                            <Text strong>{totals.transactions.toLocaleString()}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>
                                            <Text strong>{totals.tokenTransfers.toLocaleString()}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>
                                            <Text strong>{totals.nftTransfers.toLocaleString()}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>
                                            <Text strong>{totals.nftsOwned.toLocaleString()}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>
                                            <Text strong>{totals.collections.toLocaleString()}</Text>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                </Card>
            )}
        </Space>
    );
}; 
import { Space, Avatar, Tooltip, Button, Checkbox, Divider } from 'antd';
import type { TableColumnType } from 'antd';
import type { FilterDropdownProps } from 'antd/lib/table/interface';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Key } from 'react';
import { Transaction } from '../types';
import { CHAIN_NAMES, getChainName } from '../../../../constants/chains';

export const getChainColumn = (
    cdn_domain_name: string,
    transactions: Transaction[]
): TableColumnType<Transaction> => ({
    title: 'Chain',
    dataIndex: 'chain_id',
    key: 'chain_id',
    width: 120,
    filters: Object.entries(CHAIN_NAMES)
        .map(([chainId, name]) => ({
            text: (
                <Space>
                    <Tooltip title={`Chain ID: ${chainId}`}>
                        <Avatar 
                            size="small" 
                            src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                            style={{ backgroundColor: 'transparent' }}
                        />
                    </Tooltip>
                    <span>{name}</span>
                </Space>
            ),
            value: chainId,
        })),
    filterDropdown: (props: FilterDropdownProps) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
        
        const filterOptions = Object.entries(CHAIN_NAMES)
            .filter(([chainId]) => {
                return transactions?.some((item: Transaction) => 
                    item.chain_id?.toString() === chainId
                );
            })
            .map(([chainId, name]) => ({
                text: name,
                value: chainId,
                icon: `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`,
                chainId: chainId,
            }));

        const handleSelectAll = (e: CheckboxChangeEvent) => {
            if (e.target.checked) {
                setSelectedKeys(filterOptions.map(option => option.value));
            } else {
                setSelectedKeys([]);
            }
        };

        return (
            <div style={{ padding: 8 }}>
                <Checkbox
                    indeterminate={selectedKeys.length > 0 && selectedKeys.length < filterOptions.length}
                    checked={selectedKeys.length === filterOptions.length}
                    onChange={handleSelectAll}
                    style={{ marginBottom: 8 }}
                >
                    Select All
                </Checkbox>
                <Divider style={{ margin: '8px 0' }} />
                {filterOptions.map((option, index) => (
                    <div key={`${option.value}-${index}`} style={{ marginBottom: 8 }}>
                        <Checkbox
                            checked={selectedKeys.includes(option.value)}
                            onChange={(e: CheckboxChangeEvent) => {
                                const newSelectedKeys = e.target.checked
                                    ? [...selectedKeys, option.value]
                                    : selectedKeys.filter(key => key !== option.value);
                                setSelectedKeys(newSelectedKeys);
                            }}
                        >
                            <Space>
                                <Tooltip title={`Chain ID: ${option.chainId}`}>
                                    <Avatar 
                                        size="small" 
                                        src={option.icon}
                                        style={{ backgroundColor: 'transparent' }}
                                    />
                                </Tooltip>
                                <span>{option.text}</span>
                            </Space>
                        </Checkbox>
                    </div>
                ))}
                <Divider style={{ margin: '8px 0' }} />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        size="small"
                    >
                        Apply
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters?.();
                            confirm();
                        }}
                        size="small"
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        );
    },
    onFilter: (value: boolean | Key, record: Transaction) => 
        record.chain_id?.toString() === value.toString(),
    sorter: (a, b) => {
        const chainA = CHAIN_NAMES[a.chain_id] || a.chain_id;
        const chainB = CHAIN_NAMES[b.chain_id] || b.chain_id;
        return chainA.localeCompare(chainB);
    },
    render: (chainId: string) => (
        <Tooltip title={getChainName(chainId)}>
            <Space style={{ width: '100%', minWidth: 100 }}>
                <Avatar 
                    size="small" 
                    src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                    style={{ backgroundColor: 'transparent' }}
                />
                <span>{getChainName(chainId)}</span>
            </Space>
        </Tooltip>
    ),
}); 
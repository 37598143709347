import { Space, Avatar, Typography, Tag, Tooltip } from 'antd';
import type { TableColumnType } from 'antd';
import type { Transaction } from '../types';
import { Key } from 'react';
import { IGroup } from 'interfaces/group';

export const getGroupsColumn = (): TableColumnType<Transaction> => ({
    title: 'Groups',
    dataIndex: 'groups',
    key: 'groups',
    width: 200,
    filters: [
        { text: 'Native Transfer', value: 'native_transfer' },
        { text: 'Token Transfer', value: 'token_transfer' },
        { text: 'NFT Transfer', value: 'nft_transfer' },
        { text: 'Contract Interaction', value: 'contract_interaction' },
        { text: 'DeFi', value: 'defi' },
        { text: 'Governance', value: 'governance' },
    ],
    onFilter: (value: boolean | Key, record: Transaction) => {
        const groups = record.groups || record.group_names || [];
        return groups.some((group: IGroup | string) => {
            const groupId = typeof group === 'string' ? group : group.id;
            return groupId.toLowerCase().includes(value.toString().toLowerCase());
        });
    },
    render: (_: string, record: Transaction) => {
        const groups = record.groups || record.group_names || [];
        
        if (!groups || groups.length === 0) {
            return  <Typography.Text style={{ whiteSpace: 'nowrap', minWidth: 80,  display: 'inline-block'  }}></Typography.Text>
        }

        return (
            <div style={{ minWidth: 200, minHeight: 21 }}>
                <Space wrap style={{ display: 'flex', flexWrap: 'wrap', width: '100%', minWidth: 100 }}>
                    {groups.map((group: IGroup | string, index: number) => {
                        if (typeof group === 'string') {
                            // Handle string-based groups (group_names)
                            return (
                                <Tag 
                                    key={index}
                                    color="blue"
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    {group.replace(/_/g, ' ')}
                                </Tag>
                            );
                        } else {
                            // Handle object-based groups
                            return (
                                <Tooltip 
                                    key={group.id} 
                                    title={group.id}
                                >
                                    <Tag 
                                        color={`#${group.color}`}
                                        style={{ textTransform: 'capitalize' }}
                                    >
                                        {group.name}
                                    </Tag>
                                </Tooltip>
                            );
                        }
                    })}
                </Space>
            </div>
        );
    }
}); 
import React, { useCallback, useMemo } from 'react';
import { Card, Space, Alert, Tag, Typography, Avatar, Row, Col, Statistic, Divider, List, Progress } from 'antd';
import { 
  DollarOutlined, 
  SafetyOutlined, 
  WarningOutlined, 
  SwapOutlined,
  GlobalOutlined,
  LinkOutlined,
  BankOutlined,
  HistoryOutlined,
  SyncOutlined,
  TeamOutlined,
  LockOutlined,
  ShopFilled,
  KeyOutlined,
  ApiOutlined,
  CloudOutlined,
  DatabaseOutlined
} from '@ant-design/icons';
import { AddressItemsMap } from './ContractStateHighlighter';

const { Text, Title, Paragraph } = Typography;

interface ContractData {
  address: string;
  chainId: string;
  name?: string;
  states: {
    before?: Array<{ blockNumber: number | string; state: any }>;
    after?: Array<{ blockNumber: number | string; state: any }>;
    latest?: Array<{ blockNumber: number | string; state: any }>;
  };
  keyStateVariables?: Array<string | { key: string; params?: string[] }>;
}

interface ContractInsightsProps {
  contract: ContractData;
  formatAddress: (address: string, addressItemsMap?: AddressItemsMap) => React.ReactNode;
  addressItemsMap?: AddressItemsMap;
}

const ContractInsights: React.FC<ContractInsightsProps> = ({
  contract,
  formatAddress,
  addressItemsMap
}) => {
  // Helper function to render addresses with labels
  const renderAddress = useCallback((address: string) => {
    return formatAddress(address, addressItemsMap);
  }, [formatAddress, addressItemsMap]);

  // Get before, after and latest states
  const beforeState = contract.states.before?.[0]?.state;
  const afterState = contract.states.after?.[0]?.state;
  const latestState = contract.states.latest?.[0]?.state || afterState || beforeState;
  
  // Get metadata from address_items_map
  const contractMetadata = useMemo(() => {
    if (!addressItemsMap) return null;
    return addressItemsMap[contract.address.toLowerCase()];
  }, [addressItemsMap, contract.address]);
  
  // Extract entity information
  const entities = useMemo(() => {
    if (!contractMetadata?.entities) return [];
    return contractMetadata.entities;
  }, [contractMetadata]);
  
  // Extract label information
  const labels = useMemo(() => {
    if (!contractMetadata?.labels) return [];
    return contractMetadata.labels;
  }, [contractMetadata]);
  
  // Check for ownership changes
  const ownershipChanged = useMemo(() => {
    if (!beforeState?.owner && !afterState?.owner) return false;
    if (beforeState?.owner && afterState?.owner) {
      const beforeOwnerAddress = typeof beforeState.owner === 'object' 
        ? beforeState.owner.address 
        : beforeState.owner;
      
      const afterOwnerAddress = typeof afterState.owner === 'object'
        ? afterState.owner.address
        : afterState.owner;
      
      return beforeOwnerAddress !== afterOwnerAddress;
    }
    return false;
  }, [beforeState, afterState]);
  
  // Check for multi-sig ownership
  const isMultiSig = useMemo(() => {
    if (!latestState) return false;
    
    // Check for Gnosis Safe pattern
    const hasOwners = latestState.owner?.contractDetails?.getOwners || 
                     latestState.getOwners;
    
    const hasThreshold = latestState.owner?.contractDetails?.getThreshold !== undefined || 
                        latestState.getThreshold !== undefined;
    
    // Check for owner labels indicating multi-sig
    const ownerAddress = typeof latestState.owner === 'object' 
      ? latestState.owner.address 
      : latestState.owner;
    
    const ownerMetadata = ownerAddress ? addressItemsMap?.[ownerAddress.toLowerCase()] : null;
    const ownerLabels = ownerMetadata?.labels || [];
    
    const isGnosisSafe = ownerLabels.some(label => 
      label.name?.includes('Gnosis') || 
      label.name?.includes('Safe') || 
      label.name?.includes('Multisig')
    );
    
    return (hasOwners && hasThreshold) || isGnosisSafe;
  }, [latestState, addressItemsMap]);
  
// Calculate security level based on threshold and owner count
const calculateSecurityLevel = (ownerCount: number, threshold: number): string => {
    if (threshold === 1) return 'Low';
    if (threshold <= ownerCount / 3) return 'Medium-Low';
    if (threshold <= ownerCount / 2) return 'Medium';
    if (threshold <= (2 * ownerCount) / 3) return 'Medium-High';
    return 'High';
    };

  // Get multi-sig details
  const multiSigDetails = useMemo(() => {
    if (!isMultiSig || !latestState) return null;
    
    let owners: any[] = [];
    let threshold = 0;
    
    // Extract from owner contract details
    if (latestState.owner?.contractDetails?.getOwners) {
      owners = latestState.owner.contractDetails.getOwners;
      threshold = latestState.owner.contractDetails.getThreshold || 0;
    } 
    // Extract from direct contract state
    else if (latestState.getOwners) {
      owners = latestState.getOwners;
      threshold = latestState.getThreshold || 0;
    }
    
    return {
      owners,
      threshold,
      ownerCount: owners.length,
      securityLevel: calculateSecurityLevel(owners.length, threshold)
    };
  }, [isMultiSig, latestState]);
  

  
  // Check for proxy pattern
  const isProxy = useMemo(() => {
    if (!latestState) return false;
    
    return !!(
      latestState._proxy?.implementation ||
      latestState.implementation ||
      latestState.owner?.contractDetails?._proxy?.implementation
    );
  }, [latestState]);
  
  // Get proxy implementation
  const proxyImplementation = useMemo(() => {
    if (!isProxy || !latestState) return null;
    
    return latestState._proxy?.implementation || 
           latestState.implementation ||
           latestState.owner?.contractDetails?._proxy?.implementation;
  }, [isProxy, latestState]);
  
  // Check for access control pattern
  const hasAccessControl = useMemo(() => {
    if (!latestState) return false;
    
    // Check for OpenZeppelin AccessControl pattern
    const hasRoleFunction = contract.keyStateVariables?.some(variable => {
      if (typeof variable === 'object') {
        return variable.key === 'hasRole';
      }
      return variable === 'hasRole';
    });
    
    // Check for admin/owner/guardian pattern
    const hasAdminRoles = latestState.admin !== undefined || 
                         latestState.guardian !== undefined || 
                         latestState.manager !== undefined ||
                         latestState.controller !== undefined;
    
    return hasRoleFunction || hasAdminRoles;
  }, [latestState, contract.keyStateVariables]);
  
  // Check if this is an oracle contract
  const isOracle = useMemo(() => {
    if (!contractMetadata) return false;
    
    // Check entities for oracle providers
    const isChainlinkEntity = entities.some(entity => 
      entity.name?.includes('Chainlink')
    );
    
    // Check labels for oracle indicators
    const isOracleLabel = labels.some(label => 
      label.name?.includes('Oracle') || 
      label.name?.includes('Functions') ||
      label.name?.includes('Coordinator')
    );
    
    return isChainlinkEntity || isOracleLabel;
  }, [contractMetadata, entities, labels]);
  
  // Determine the main contract type
  const contractType = useMemo(() => {
    if (isOracle) return 'Oracle';
    if (isMultiSig) return 'Multi-Signature Wallet';
    if (isProxy) return 'Proxy Contract';
    if (hasAccessControl) return 'Access-Controlled Contract';
    return 'Standard Contract';
  }, [isOracle, isMultiSig, isProxy, hasAccessControl]);
  
  return (
    <Card title="Contract Insights" style={{ marginBottom: 16, width: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        {/* Contract Type Overview */}
        <Alert
          type="info"
          icon={
            isOracle ? <ApiOutlined /> : 
            isMultiSig ? <TeamOutlined /> : 
            isProxy ? <SyncOutlined /> : 
            hasAccessControl ? <LockOutlined /> : 
            <ShopFilled />
          }
          message={`${contractType}`}
          description={
            <Space direction="vertical" style={{ width: '100%' }}>
              {entities.length > 0 && (
                <div>
                  <Text strong>Associated with: </Text>
                  {entities.map((entity, index) => (
                    <Tag 
                      key={index}
                      color={entity.color ? `#${entity.color.replace(/^#/, '')}` : undefined}
                      style={{ marginRight: 8 }}
                    >
                      {entity.name}
                    </Tag>
                  ))}
                </div>
              )}
              
              {labels.length > 0 && (
                <div>
                  <Text strong>Contract Labels: </Text>
                  {labels.map((label, index) => (
                    <Tag 
                      key={index}
                      color={label.color ? `#${label.color.replace(/^#/, '')}` : undefined}
                      style={{ marginRight: 8 }}
                    >
                      {label.name}
                    </Tag>
                  ))}
                </div>
              )}
            </Space>
          }
          showIcon
          style={{ width: '100%' }}
        />
        
        {/* Oracle Contract */}
        {isOracle && (
          <Alert
            type="info"
            icon={<ApiOutlined />}
            message="Oracle Service"
            description={
              <Space direction="vertical" style={{ width: '100%' }}>
                <Text>This contract provides oracle services for on-chain data:</Text>
                
                {entities.some(entity => entity.name?.includes('Chainlink')) && (
                  <div>
                    <Text strong>Provider: </Text>
                    <Tag color="blue">Chainlink</Tag>
                    
                    {labels.some(label => label.name?.includes('Functions')) && (
                      <div style={{ marginTop: 8 }}>
                        <Text strong>Service Type: </Text>
                        <Tag color="green">Chainlink Functions</Tag>
                        <Text type="secondary" style={{ display: 'block', marginTop: 4 }}>
                          Chainlink Functions allows smart contracts to access off-chain computation and APIs.
                        </Text>
                      </div>
                    )}
                  </div>
                )}
                
                <Text type="secondary">
                  Oracle contracts provide external data to blockchain applications, enabling 
                  smart contracts to interact with real-world information.
                </Text>
              </Space>
            }
            showIcon
            style={{ width: '100%' }}
          />
        )}
        
        {/* Multi-Signature Wallet */}
        {isMultiSig && multiSigDetails && (
          <Alert
            type="success"
            icon={<TeamOutlined />}
            message="Multi-Signature Governance"
            description={
              <Space direction="vertical" style={{ width: '100%' }}>
                <Text>This contract is governed by a multi-signature wallet:</Text>
                
                <Row gutter={16} style={{ marginTop: 8 }}>
                  <Col span={12}>
                    <Statistic 
                      title="Total Signers" 
                      value={multiSigDetails.ownerCount} 
                      suffix="addresses"
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic 
                      title="Required Signatures" 
                      value={multiSigDetails.threshold} 
                      suffix={`of ${multiSigDetails.ownerCount}`}
                    />
                    <Progress 
                      percent={Math.round((multiSigDetails.threshold / multiSigDetails.ownerCount) * 100)} 
                      size="small" 
                      status="active"
                      style={{ marginTop: 8 }}
                    />
                  </Col>
                </Row>
                
                <div style={{ marginTop: 16 }}>
                  <Text strong>Security Assessment: </Text>
                  {multiSigDetails.securityLevel === 'Low' && <Tag color="red">Low Security (Single Signature)</Tag>}
                  {multiSigDetails.securityLevel === 'Medium-Low' && <Tag color="orange">Medium-Low Security</Tag>}
                  {multiSigDetails.securityLevel === 'Medium' && <Tag color="yellow">Medium Security</Tag>}
                  {multiSigDetails.securityLevel === 'Medium-High' && <Tag color="lime">Medium-High Security</Tag>}
                  {multiSigDetails.securityLevel === 'High' && <Tag color="green">High Security</Tag>}
                </div>
                
                <Divider orientation="left">Authorized Signers</Divider>
                
                <List
                  size="small"
                  bordered
                  dataSource={multiSigDetails.owners}
                  renderItem={(owner: any) => {
                    const ownerAddress = typeof owner === 'object' ? owner.address : owner;
                    const ownerMetadata = addressItemsMap?.[ownerAddress.toLowerCase()];
                    const entityNames = ownerMetadata?.entities?.map(e => e.name).join(', ');
                    
                    return (
                      <List.Item>
                        <List.Item.Meta
                          title={renderAddress(ownerAddress)}
                          description={entityNames && <Tag color="blue">{entityNames}</Tag>}
                        />
                      </List.Item>
                    );
                  }}
                  style={{ maxHeight: 200, overflow: 'auto' }}
                />
                
                <Text type="secondary" style={{ marginTop: 16 }}>
                  Multi-signature governance requires multiple parties to approve transactions,
                  providing enhanced security and decentralized control.
                </Text>
              </Space>
            }
            showIcon
            style={{ width: '100%' }}
          />
        )}
        
        {/* Proxy Contract */}
        {isProxy && (
          <Alert
            type="warning"
            icon={<SyncOutlined />}
            message="Upgradeable Contract (Proxy Pattern)"
            description={
              <Space direction="vertical" style={{ width: '100%' }}>
                <Text>This contract uses a proxy pattern, making it upgradeable:</Text>
                
                {proxyImplementation && (
                  <div>
                    <Text strong>Implementation Contract: </Text>
                    <Text>{renderAddress(proxyImplementation)}</Text>
                  </div>
                )}
                
                <Text type="warning">
                  Proxy contracts can be upgraded by their administrators, potentially changing
                  contract behavior. This provides flexibility but requires trust in the contract
                  governance.
                </Text>
              </Space>
            }
            showIcon
            style={{ width: '100%' }}
          />
        )}
        
        {/* Ownership Change */}
        {ownershipChanged && (
          <Alert
            type="warning"
            icon={<KeyOutlined />}
            message="Ownership Change Detected"
            description={
              <Space direction="vertical" style={{ width: '100%' }}>
                <Text>The contract ownership has changed:</Text>
                
                {beforeState?.owner && afterState?.owner && (
                  <div>
                    <Text strong>From: </Text>
                    <Text>
                      {renderAddress(
                        typeof beforeState.owner === 'object' 
                          ? beforeState.owner.address 
                          : beforeState.owner
                      )}
                    </Text>
                    <br />
                    <Text strong>To: </Text>
                    <Text>
                      {renderAddress(
                        typeof afterState.owner === 'object' 
                          ? afterState.owner.address 
                          : afterState.owner
                      )}
                    </Text>
                  </div>
                )}
                
                <Text type="warning">
                  Ownership changes transfer control of the contract to a new address,
                  which may affect how the contract is managed and upgraded.
                </Text>
              </Space>
            }
            showIcon
            style={{ width: '100%' }}
          />
        )}
        
        {/* Access Control */}
        {hasAccessControl && !isMultiSig && (
          <Alert
            type="info"
            icon={<LockOutlined />}
            message="Role-Based Access Control"
            description={
              <Space direction="vertical" style={{ width: '100%' }}>
                <Text>This contract implements role-based access control:</Text>
                
                {latestState?.admin && (
                  <div>
                    <Text strong>Admin: </Text>
                    <Text>{renderAddress(latestState.admin)}</Text>
                  </div>
                )}
                
                {latestState?.guardian && (
                  <div>
                    <Text strong>Guardian: </Text>
                    <Text>{renderAddress(latestState.guardian)}</Text>
                  </div>
                )}
                
                {latestState?.manager && (
                  <div>
                    <Text strong>Manager: </Text>
                    <Text>{renderAddress(latestState.manager)}</Text>
                  </div>
                )}
                
                {latestState?.controller && (
                  <div>
                    <Text strong>Controller: </Text>
                    <Text>{renderAddress(latestState.controller)}</Text>
                  </div>
                )}
                
                <Text type="secondary">
                  Role-based access control allows different addresses to have different
                  permissions within the contract, enabling fine-grained security.
                </Text>
              </Space>
            }
            showIcon
            style={{ width: '100%' }}
          />
        )}
      </Space>
    </Card>
  );
};

export default ContractInsights; 
import React from 'react';
import { Space, Button, Tooltip, Select, Alert, Avatar, Typography } from 'antd';
import { ReloadOutlined, CloudSyncOutlined, FilterOutlined } from '@ant-design/icons';
import { CHAIN_ID_TO_NAME } from 'constants/chains';
import { Title } from '@refinedev/antd';
import { useTransactionData } from './hooks/useTransactionData';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

interface TransactionControlPanelProps {
    identifier: string;
    activeChains: string[];
    selectedChains: string[];
    searchParams: URLSearchParams;
    onTransactionsChange: (transactions: any[]) => void;
    onPaginationCursorsChange: (paginationCursors: any) => void;
    onCurrentPageChange: (page: number) => void;
    onHasMoreChange: (hasMore: boolean) => void;
    onSearchParamsChange: (searchParams: URLSearchParams) => void;
    setIsLoading: (isLoading: boolean) => void;
}

export const TransactionControlPanel: React.FC<TransactionControlPanelProps> = ({
    identifier,
    searchParams,
    activeChains,
    selectedChains,
    onTransactionsChange,
    onPaginationCursorsChange,
    onCurrentPageChange,
    onHasMoreChange,
    onSearchParamsChange,
    setIsLoading,
}) => {
    const navigate = useNavigate();
    
    const { fetchSelectedChainData, isRefreshing, isRefreshingAllData } = useTransactionData({
        identifier,
        selectedChains,
        activeChains,
        searchParams,
        onTransactionsChange,
        onPaginationCursorsChange,
        onCurrentPageChange,
        onHasMoreChange,
        onSearchParamsChange,
    });

    const [tempSelectedChains, setTempSelectedChains] = React.useState<string[]>(selectedChains);
    
    const onChainSelection = (values: string[]) => {
        setTempSelectedChains(values);
        // If this was triggered by any clear action (individual or all)
        if (values.length < selectedChains.length) {
            const newSearchParams = new URLSearchParams(searchParams);
            if (values.length === 0) {
                newSearchParams.delete('chain_ids');
            } else {
                newSearchParams.set('chain_ids', values.join(','));
            }
            navigate({
                search: newSearchParams.toString()
            });
        }
    };
    
    const handleSelectionConfirm = () => {
        // Close the dropdown after confirming selection
        const selectRef = document.querySelector('.ant-select-dropdown');
        if (selectRef) {
            selectRef.classList.add('ant-select-dropdown-hidden');
        }

        if (tempSelectedChains.sort().join(',') === activeChains?.sort().join(',')) {
            navigate({ search: '' });
            return;
        }
       
        const newSearchParams = new URLSearchParams(searchParams);
        if (tempSelectedChains.length) {
            newSearchParams.set('chain_ids', tempSelectedChains.join(','));
        } else {
            newSearchParams.delete('chain_ids');
        }
        navigate({
            search: newSearchParams.toString()
        });
    };
    
    const handleRefreshData = async () => {
        setIsLoading(true);
        try {
            await fetchSelectedChainData(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetAllHistoricalData = async () => {
        setIsLoading(true);
        try {
            await fetchSelectedChainData(true);
        } finally {
            setIsLoading(false);
        }
    };
    
    const cdn_domain_name = process.env.REACT_APP_CDN_URL
    return (
        <>
            <Typography.Title level={5}>Transactions</Typography.Title>
            <Space style={{ marginBottom: 16 }} direction="vertical" size="middle">
                <Space>
                    <Tooltip title={
                        <div>
                            <p>This will refresh the transaction data for this address across the following chains:</p>
                            <ul style={{ paddingLeft: '20px', margin: '8px 0' }}>
                                {selectedChains?.map((chainId: string) => (
                                    <li key={chainId}>
                                        {CHAIN_ID_TO_NAME[chainId] || chainId}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }>                    
                        <Button 
                            onClick={handleRefreshData}
                            icon={<ReloadOutlined/>}
                            loading={isRefreshing}
                        >
                            Refresh Data
                        </Button>
                    </Tooltip>
                        <Tooltip title={
                        <div>
                            <p>This will fetch all historical transaction data for this address across the following chains:</p>
                            <ul style={{ paddingLeft: '20px', margin: '8px 0' }}>
                                {selectedChains?.map((chainId: string) => (
                                    <li key={chainId}>
                                        {CHAIN_ID_TO_NAME[chainId] || chainId}
                                    </li>
                                ))}
                            </ul>
                            <p>Note: This operation may take some time to complete depending on the number of transactions.</p>
                        </div>
                    }>
                        <Button
                            type="primary" 
                            onClick={handleGetAllHistoricalData}
                            loading={isRefreshingAllData}
                            icon={<CloudSyncOutlined/>}
                        >
                            {isRefreshing ? 'Retrieving All Data...' : 'Get All Historical Data'}
                        </Button>
                    </Tooltip>
                    <Select
                        mode="multiple"
                        style={{ minWidth: 200 }}
                        placeholder="Select specific chains"
                        onChange={onChainSelection}
                        value={tempSelectedChains}
                        allowClear
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <div style={{ padding: '8px', borderTop: '1px solid #e8e8e8' }}>
                                    <Button type="primary" onClick={handleSelectionConfirm} size="small" block>
                                        OK
                                    </Button>
                                </div>
                            </>
                        )}
                    >
                        {activeChains?.map((chainId: string) => (
                            <Select.Option key={chainId} value={chainId}>
                                <Space>
                                    <Tooltip title={CHAIN_ID_TO_NAME[chainId] || chainId}>
                                        <Avatar 
                                            size="small"
                                            src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                                            style={{ backgroundColor: 'transparent' }}
                                        />
                                    </Tooltip>
                                    <Typography.Text>
                                        {CHAIN_ID_TO_NAME[chainId] || chainId}
                                    </Typography.Text>
                                </Space>
                            </Select.Option>
                        ))}
                    </Select>
                </Space>
                {selectedChains.sort().join(',') !== activeChains?.sort().join(',')  && selectedChains.length > 0 && (
                    <Alert
                        message={
                            <Space>
                                <Typography.Text>Selected Chains:</Typography.Text>
                                {selectedChains.map(chainId => (
                                    <Tooltip key={chainId} title={CHAIN_ID_TO_NAME[chainId] || chainId}>
                                        <Avatar 
                                            size="small"
                                            src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                                            style={{ backgroundColor: 'transparent' }}
                                        />
                                    </Tooltip>
                                ))}
                            </Space>
                        }
                        description={
                            <Space direction="vertical">
                                <Typography.Text>
                                    You are viewing results based on the selected chains shown above.
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                    To view data for all active chains, please clear the selection and refresh.
                                </Typography.Text>
                            </Space>
                        }
                        type="warning"
                        showIcon
                    />
                )}
                {isRefreshingAllData && (
                    <Alert
                        message="Retrieving historical data on selected chains"
                        description={
                            <Space direction="vertical">
                                <Text>This operation fetches all historical transactions across the following chains:</Text>
                                <Space wrap>
                                    {selectedChains.length > 0 ? 
                                        selectedChains.map(chainId => (
                                            <Space key={chainId}>
                                                <Avatar 
                                                    size="small"
                                                    src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                                                />
                                                <Text>{CHAIN_ID_TO_NAME[chainId] || chainId}</Text>
                                            </Space>
                                        ))
                                        :
                                        activeChains?.map((chainId: string) => (
                                            <Space key={chainId}>
                                                <Avatar 
                                                    size="small"
                                                    src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                                                />
                                                <Text>{CHAIN_ID_TO_NAME[chainId] || chainId}</Text>
                                            </Space>
                                        ))
                                    }
                                </Space>
                                <Text>This may take some time to complete. Please keep this tab open.</Text>
                            </Space>
                        }
                        type="info"
                        showIcon
                        style={{ marginLeft: 8 }}
                    />
                )}
            </Space>
        </>            
    );
}; 
import { useModalForm } from "@refinedev/antd";

export const useColumnsModal = () => {
    const {
        modalProps,
        formProps,
        show,
        formLoading,
        onFinish,
        close,
    } = useModalForm({
        warnWhenUnsavedChanges: false,
        action: "create",
        syncWithLocation: false,
        resource: "addresses",
    });

    return {
        modalProps,
        formProps,
        show,
        formLoading,
        onFinish,
        close,
    };
}; 
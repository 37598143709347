export interface OffChainNodeConfig {
    x: number;
    y: number;
    size: number;
    type: 'DON' | 'BRIDGE' | 'ORACLE';
    name: string;
    chainLinks: string[]; // chain IDs this node connects
    status?: 'active' | 'pending' | 'inactive';
}

const OFF_CHAIN_STYLES = {
    DON: {
        primaryColor: '#375BD2',      // Chainlink blue
        secondaryColor: '#0A294B',    // Darker blue
        glowColor: 'rgba(55, 91, 210, 0.2)',
        symbol: '⚡'                   // Lightning symbol for DON
    },
    BRIDGE: {
        primaryColor: '#7B3FE4',      // Purple
        secondaryColor: '#452178',
        glowColor: 'rgba(123, 63, 228, 0.2)',
        symbol: '🌉'                   // Bridge symbol
    },
    ORACLE: {
        primaryColor: '#2FABDD',      // Light blue
        secondaryColor: '#1A6B8F',
        glowColor: 'rgba(47, 171, 221, 0.2)',
        symbol: '🔮'                   // Oracle symbol
    }
};

export const drawOffChainNode = (
    ctx: CanvasRenderingContext2D,
    config: OffChainNodeConfig,
    globalScale: number = 1
) => {
    const { x, y, size, type, name, status = 'active' } = config;
    const style = OFF_CHAIN_STYLES[type];
    
    // Save current context state
    ctx.save();
    
    // Calculate scaled dimensions
    const nodeSize = size * globalScale;
    const hexagonRadius = nodeSize * 1.2;  // Slightly larger than regular nodes
    
    // Draw outer glow for active nodes
    if (status === 'active') {
        const gradient = ctx.createRadialGradient(
            x, y, hexagonRadius * 0.8,
            x, y, hexagonRadius * 2
        );
        gradient.addColorStop(0, style.glowColor);
        gradient.addColorStop(1, 'rgba(0,0,0,0)');
        
        ctx.fillStyle = gradient;
        ctx.beginPath();
        drawHexagon(ctx, x, y, hexagonRadius * 1.5);
        ctx.fill();
    }

    // Draw hexagonal shape
    ctx.beginPath();
    drawHexagon(ctx, x, y, hexagonRadius);
    
    // Create gradient fill
    const gradient = ctx.createLinearGradient(
        x - hexagonRadius,
        y - hexagonRadius,
        x + hexagonRadius,
        y + hexagonRadius
    );
    gradient.addColorStop(0, style.primaryColor);
    gradient.addColorStop(1, style.secondaryColor);
    
    // Fill and stroke
    ctx.fillStyle = gradient;
    ctx.fill();
    ctx.strokeStyle = style.primaryColor;
    ctx.lineWidth = 2 * globalScale;
    ctx.stroke();

    // Draw status indicator
    const statusColors = {
        active: '#4CAF50',
        pending: '#FFC107',
        inactive: '#F44336'
    };
    
    ctx.beginPath();
    ctx.arc(
        x + hexagonRadius * 0.7,
        y - hexagonRadius * 0.7,
        nodeSize * 0.2,
        0,
        Math.PI * 2
    );
    ctx.fillStyle = statusColors[status];
    ctx.fill();

    // Draw type symbol
    ctx.font = `${nodeSize}px Arial`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(style.symbol, x, y);

    // Draw name label
    ctx.font = `${nodeSize * 0.4}px Arial`;
    ctx.fillStyle = '#FFFFFF';
    ctx.fillText(name, x, y + hexagonRadius + nodeSize * 0.5);

    // Draw connecting chains indicators
    if (config.chainLinks.length > 0) {
        const chainIndicatorRadius = nodeSize * 0.15;
        const startAngle = -Math.PI / 2;  // Start from top
        const angleStep = (Math.PI * 2) / Math.max(6, config.chainLinks.length);
        
        config.chainLinks.forEach((_, index) => {
            const angle = startAngle + angleStep * index;
            const indicatorX = x + Math.cos(angle) * hexagonRadius * 0.7;
            const indicatorY = y + Math.sin(angle) * hexagonRadius * 0.7;
            
            ctx.beginPath();
            ctx.arc(indicatorX, indicatorY, chainIndicatorRadius, 0, Math.PI * 2);
            ctx.fillStyle = '#FFFFFF';
            ctx.fill();
        });
    }

    // Restore context state
    ctx.restore();
};

// Helper function to draw hexagon path
const drawHexagon = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    radius: number
) => {
    const numberOfSides = 6;
    const angleStep = (Math.PI * 2) / numberOfSides;
    const rotationOffset = Math.PI / 6; // 30 degrees to point upward

    ctx.beginPath();
    for (let i = 0; i <= numberOfSides; i++) {
        const angle = rotationOffset + i * angleStep;
        const pointX = x + radius * Math.cos(angle);
        const pointY = y + radius * Math.sin(angle);
        
        if (i === 0) {
            ctx.moveTo(pointX, pointY);
        } else {
            ctx.lineTo(pointX, pointY);
        }
    }
    ctx.closePath();
};

// Usage example:
/*
drawOffChainNode(ctx, {
    x: 100,
    y: 100,
    size: 20,
    type: 'DON',
    name: 'Chainlink DON',
    chainLinks: ['0x1', '0x89'], // Ethereum and Polygon
    status: 'active'
});
*/ 
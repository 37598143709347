import React from 'react';
import { Button, Tooltip } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

interface ScrollButtonsProps {
    show: boolean;
}

export const ScrollButtons: React.FC<ScrollButtonsProps> = ({ show }) => {
    if (!show) return null;

    return (
        <div style={{
            position: 'absolute',
            bottom: 20,
            right: 35,
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            zIndex: 1000,
            pointerEvents: 'auto'
        }}>
            <Tooltip title="Scroll to top">
                <Button
                    type="primary"
                    size="small"
                    icon={<ArrowUpOutlined />}
                    onClick={() => {
                        const tableBody = document.querySelector('.ant-table-body');
                        if (tableBody) {
                            tableBody.scrollTo({ 
                                top: 0, 
                                behavior: 'smooth' 
                            });
                        }                                    
                    }}
                />
            </Tooltip>
            <Tooltip title="Scroll to bottom" placement="bottom">
                <Button
                    type="primary"
                    size="small"
                    icon={<ArrowDownOutlined />}
                    onClick={() => {
                        const tableBody = document.querySelector('.ant-table-body');
                        if (tableBody) {
                            tableBody.scrollTo({ 
                                top: tableBody.scrollHeight, 
                                behavior: 'smooth' 
                            });
                        }
                    }}
                />
            </Tooltip>
        </div>
    );
}; 
import { CHAIN_MAPPING } from "../interfaces/chain.d";
import { Avatar, Tooltip } from "antd";

// Avatar components (similar to EntityShow)
export const ChainAvatar = ({ chainId, size = 'small' }: { 
    chainId: string;
    size?: 'small' | 'default' | 'large';
}) => (
    <Tooltip 
        title={CHAIN_MAPPING[chainId] || chainId}
        overlayStyle={{ zIndex: 1100 }}
    >
        <Avatar 
            src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`} 
            size={size}
            style={{ backgroundColor: '#f0f2f5' }}
        />
    </Tooltip>
);
import React, { useState, useMemo, useEffect } from 'react';
import { Table, Typography, Button, Dropdown, Space, Modal, TableColumnType, Tag, Descriptions, List, Tooltip, Avatar, Select } from 'antd';
import { ScrollButtons } from './components/ScrollButtons';
import { 
    getChainColumn,
    getMethodColumn,
    getToColumn,
    getFromColumn,
    getValueColumn,
    getBlockTimeColumn,
    getFoundInColumn,
    getActionsColumn,
    getHashColumn,
    getSummaryColumn,
    getGroupsColumn,
    getEntitiesColumn
} from './columns';
import type { SelectedSection, Transaction, TransactionTableProps, PaginationCursor } from './types';
import { TransactionSummary } from './components/TransactionSummary';
import { ColumnSettings } from './components/ColumnSettings';
import { useColumnSettings } from './hooks/useColumnSettings';
import { useColumnsModal } from './hooks/useColumnsModal';
import { JsonModal } from './components/JsonModal';
import { ColumnVisibilityMenu } from './components/ColumnVisibilityMenu';
import { DownOutlined } from '@ant-design/icons';
import { renderModalContent } from './components/ModalContent';
import { CHAIN_ID_TO_NAME, CHAIN_IDS } from 'constants/chains';
import { useConfig } from 'contexts';
import { useTransactionFetch } from './hooks/useTransactionFetch';
import { useAuth0 } from '@auth0/auth0-react';
import { TransactionControlPanel } from './TransactionControlPanel';
import { useTransactionData } from './hooks/useTransactionData';
import { useSearchParams } from 'react-router-dom';
import { getSharedWithColumn } from './columns/SharedWithColumn';
import { getReceiptStatusColumn } from './columns/ReceiptStatusColumn';

export const TransactionTable: React.FC<TransactionTableProps> = ({
    tableId,
    identifier,
    resource,
    searchAddress,
    transactions,
    address_items_map,
    activeChains,
    selectedChains,
    initialPaginationCursors,
    isLoading,
    showTransactionControlPanel = true,
    setTransactions,
    onFilteredDataChange,
    onTransactionDataChange,
}) => {
        const { 
            user,
            token, 
            region, 
            continent, 
            domain, 
            qa_environment,
            cdn_domain_name 
        } = useConfig();

        // Add internal state for pagination
        const [searchParams, setSearchParams] = useSearchParams();
        const [currentPage, setCurrentPage] = useState(1);
        const [paginationCursors, setPaginationCursors] = useState<PaginationCursor>(initialPaginationCursors);
        const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>(transactions);
        const [graphKey, setGraphKey] = useState(0);
        const { visibleColumns, setVisibleColumns } = useColumnSettings(user, tableId);
        const [selectedSection, setSelectedSection] = useState<SelectedSection | null>(null);
        const [jsonModalData, setJsonModalData] = useState<any>(null);
        const [loadMoreSize, setLoadMoreSize] = useState(20);

        const {
            isLoadingInfiniteScroll: fetchedIsLoadingInfiniteScroll,
            hasMore: fetchedHasMore,
            currentPage: fetchedCurrentPage,
            fetchTransactions: fetchedFetchTransactions
        } = useTransactionFetch({
            identifier, // should be address if the user has not imported the address
            searchParams,
            initialPaginationCursors,
            fetchSize: loadMoreSize,
            resource,
            onTransactionsUpdate: (newTransactions: Transaction[]) => {
                // Create a map of existing transactions for quick lookup
                // Using composite key of chain_id + hash to handle cross-chain duplicates
                const existingTransactionsMap = new Map(
                    transactions.map(tx => {
                        // Ensure both chain_id and hash exist before creating the key
                        if (!tx.chain_id || !tx.hash) {
                            console.warn('Transaction missing chain_id or hash:', tx);
                            return [`missing_key_${Math.random()}`, tx]; // Use random key for transactions missing identifiers
                        }
                        return [`${tx.chain_id}_${tx.hash}`, tx];
                    })
                );
                
                // Filter out duplicates from new transactions
                const uniqueNewTransactions = newTransactions.filter(newTx => {
                    // Skip transactions missing chain_id or hash
                    if (!newTx.chain_id || !newTx.hash) {
                        console.warn('New transaction missing chain_id or hash:', newTx);
                        return true; // Keep transactions missing identifiers (can't deduplicate)
                    }
                    
                    const key = `${newTx.chain_id}_${newTx.hash}`;
                    const isDuplicate = existingTransactionsMap.has(key);
                    
                    if (isDuplicate) {
                        console.debug(`Filtered duplicate transaction: chain ${newTx.chain_id}, hash ${newTx.hash}`);
                    }
                    
                    return !isDuplicate;
                });
                
                // Only update state if we have unique transactions to add
                if (uniqueNewTransactions.length > 0) {
                    console.log(`Adding ${uniqueNewTransactions.length} unique transactions (filtered out ${newTransactions.length - uniqueNewTransactions.length} duplicates)`);
                    setTransactions([...transactions, ...uniqueNewTransactions]);
                } else if (newTransactions.length > 0) {
                    console.log(`All ${newTransactions.length} new transactions were duplicates, no update needed`);
                }
            },
            onPaginationCursorUpdate: (newCursor) => {
                setPaginationCursors((prev: any) => ({
                    ...prev,
                    database: { key: newCursor }
                }));
            }     
        });

        useEffect(() => {
            onFilteredDataChange?.(filteredTransactions);
        }, [filteredTransactions, onFilteredDataChange]);

        useEffect(() => {
            setHasMore(fetchedHasMore);
        }, [fetchedHasMore]);
    
        // Log the initialPaginationCursors when they change
        useEffect(() => {
        }, [initialPaginationCursors]);
        
        // Update paginationCursors when initialPaginationCursors changes
        useEffect(() => {
            if (initialPaginationCursors && 
                initialPaginationCursors.database && 
                Object.keys(initialPaginationCursors.database).length > 0) {
                setPaginationCursors(initialPaginationCursors);
            }
        }, [initialPaginationCursors]);
        
        // Check if we have valid pagination cursors
        const hasDatabaseCursor = initialPaginationCursors?.database && 
                                 Object.keys(initialPaginationCursors.database).length > 0;
        
        // Initialize hasMore based on whether we have valid cursors
        const [hasMore, setHasMore] = useState(hasDatabaseCursor);
        
        // Update hasMore when initialPaginationCursors changes
        useEffect(() => {
            const hasDatabaseCursor = initialPaginationCursors?.database && 
                                     Object.keys(initialPaginationCursors.database).length > 0;
            setHasMore(hasDatabaseCursor);
        }, [initialPaginationCursors]);
        
        // Modify your existing loadMore function
        const handleLoadMore = async (refresh = false) => {
            if (!fetchedIsLoadingInfiniteScroll && hasMore) {
                try {
                    console.log("Loading more with cursors:", paginationCursors, "load size:", loadMoreSize);
                    await fetchedFetchTransactions(refresh, paginationCursors);
                } catch (error) {
                    console.error('Error loading more transactions:', error);
                    setHasMore(false);
                }
            }
        };
        
    const {
        modalProps: createColumnsModalProps,
        formProps: createColumnsFormProps,
        show: createColumnsModalShow,
        formLoading: createColumnsFormLoading,
        onFinish,
        close,
    } = useColumnsModal();

    const columns = useMemo(() => [
        getSharedWithColumn(cdn_domain_name),
        getChainColumn(cdn_domain_name, transactions || []),
        getHashColumn({ setJsonModalData, data: transactions || [] }),
        getReceiptStatusColumn(),
        getSummaryColumn(),
        getMethodColumn({ transactions: transactions || [] }),
        getFromColumn(cdn_domain_name, transactions || [], address_items_map || {}),
        getToColumn(cdn_domain_name, transactions || [], address_items_map || {}),
        getValueColumn(cdn_domain_name),
        getGroupsColumn(),
        getEntitiesColumn(cdn_domain_name, address_items_map || {}),
        getBlockTimeColumn(),   
        getFoundInColumn({ 
            searchAddress,
            setSelectedSection,
            address_items_map
        }),
        getActionsColumn(setJsonModalData),
    ].filter(column => visibleColumns.includes(column.key as string)), [searchAddress, visibleColumns, transactions]);

    const [isControlPanelLoading, setIsControlPanelLoading] = useState(false);

    // Handle filtered data changes
    useEffect(() => {
        onTransactionDataChange?.(filteredTransactions);
    }, [filteredTransactions, onTransactionDataChange]);

    // Add this effect to handle URL changes
    useEffect(() => {
        // Reset transactions when chain_ids parameter changes
        setFilteredTransactions(transactions);
        onTransactionDataChange?.(transactions);
    }, [searchParams.get('chain_ids'), transactions]);

    return (
        <>
            <div style={{ position: 'relative' }}>
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    alignItems: 'center',
                    marginBottom: 16
                }}>
                    <ColumnSettings 
                        visibleColumns={visibleColumns}
                        setVisibleColumns={setVisibleColumns}
                        modalProps={createColumnsModalProps}
                        formProps={createColumnsFormProps}
                        show={createColumnsModalShow}
                        formLoading={createColumnsFormLoading}
                        onFinish={onFinish}
                        close={close}
                    />
                </div>
                {showTransactionControlPanel && (
                    <TransactionControlPanel 
                        activeChains={activeChains}
                        selectedChains={selectedChains}
                        identifier={identifier}
                        searchParams={searchParams}
                        onTransactionsChange={(newTransactions) => {
                            setTransactions(newTransactions);
                            setFilteredTransactions(newTransactions);
                            onTransactionDataChange?.(newTransactions);
                        }}
                        onPaginationCursorsChange={setPaginationCursors}
                        onCurrentPageChange={setCurrentPage}
                        onHasMoreChange={setHasMore}
                        onSearchParamsChange={setSearchParams}
                        setIsLoading={setIsControlPanelLoading}
                    />     
                )}
                <Table 
                    dataSource={transactions?.map(tx => ({
                        ...tx,
                        key: `${tx.chain_id}_${tx.hash}`
                    }))}
                    loading={fetchedIsLoadingInfiniteScroll || isControlPanelLoading || isLoading}
                    pagination={false}
                    columns={columns}
                    scroll={{ x: true, y: 400 }}
                    sticky={{ offsetHeader: 0 }}
                    onChange={(_, filters, sorter, extra) => {
                        setFilteredTransactions(extra?.currentDataSource);
                        setGraphKey(prev => prev + 1);
                    }}
                    summary={() => (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={columns.length}>
                                <TransactionSummary 
                                    totalTxs={transactions?.length || 0}
                                    transfers={transactions?.reduce((acc, tx) => ({
                                        native: acc.native + (tx.native_transfers?.length || 0),
                                        erc20: acc.erc20 + (tx.erc20_transfers?.length || 0),
                                        nft: acc.nft + (tx.nft_transfers?.length || 0),
                                        internal: acc.internal + (tx.internal_transactions?.length || 0),
                                        contract: acc.contract + (Object.values(tx.contract_interactions || {}).flat().length || 0)
                                    }), { native: 0, erc20: 0, nft: 0, internal: 0, contract: 0 })}
                                    chainCounts={transactions?.reduce((acc: Record<string, number>, tx) => ({
                                        ...acc,
                                        [tx.chain_id]: (acc[tx.chain_id] || 0) + 1
                                    }), {})}
                                    chainIdToName={CHAIN_ID_TO_NAME}
                                    cdn_domain_name={cdn_domain_name}
                                />
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    )}
                />
                
                <div style={{ textAlign: 'center', marginTop: 16 }}>
                    <Space direction="vertical" size="small">
                        <Typography.Text strong>
                            Total Transactions: {transactions?.length || 0}
                        </Typography.Text>
                    </Space>
                </div>

                {hasMore && (
                    <div style={{ textAlign: 'center', marginTop: 16 }}>
                        <Button 
                            onClick={() => handleLoadMore(false)} 
                            loading={fetchedIsLoadingInfiniteScroll}
                            type="primary"
                        >
                            Load More
                        </Button>
                    </div>
                )}
                
                <div style={{ 
                    position: 'absolute',
                    bottom: 16,
                    right: 100,
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <div className="ant-pagination-options">
                        <div className="ant-pagination-options-size-changer">
                            <Typography.Text style={{ marginRight: 8 }}>
                                Items per load:
                            </Typography.Text>
                            <Select
                                value={loadMoreSize}
                                onChange={(value) => setLoadMoreSize(value)}
                                style={{ width: 80 }}
                                options={[
                                    { value: 10, label: '10 / page' },
                                    { value: 20, label: '20 / page' },
                                    { value: 50, label: '50 / page' },
                                    { value: 100, label: '100 / page' }
                                ]}
                                dropdownMatchSelectWidth={false}
                            />
                        </div>
                    </div>
                </div>
                
                <ScrollButtons show={transactions && transactions.length > 0} />
                <JsonModal 
                    data={jsonModalData} 
                    onClose={() => setJsonModalData(null)} 
                />
            </div>
            <Modal
                open={!!selectedSection}
                onCancel={() => setSelectedSection(null)}
                title={selectedSection ? `${selectedSection.type.charAt(0).toUpperCase() + selectedSection.type.slice(1)} Details` : ''}
                width={800}
                footer={[
                    <Button key="close" onClick={() => setSelectedSection(null)}>
                        Close
                    </Button>
                ]}
            >
                {renderModalContent({ selectedSection })}
            </Modal>
        </>
    );
}; 
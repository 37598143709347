import { Card, Col, List, Statistic, Tooltip, Avatar, Typography } from 'antd';
import { ExpandAltOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { IGroup } from '../../../interfaces/group.d';
interface GroupAssignment {
    transaction_id: string;
    chain_id: string;
}

interface GroupAssignmentResult {
    group_id: string;
    group_name: string;
    count: number;
    assignments: GroupAssignment[];
}

interface GroupAssignmentsCardProps {
    record: GroupAssignmentResult;
    cdn_domain_name: string;
    groupNames?: IGroup[];  // Add this to get the group names from the transaction
}

const getGroupName = (groupId: string, groupNames?: IGroup[]): string => {
    // If no groupNames provided, return the groupId (backwards compatibility)
    if (!groupNames?.length) {
        return groupId;
    }

    // Find the matching group and return its name
    const group = groupNames.find(g => g.id === groupId);
    return group?.name || groupId;
};

export const GroupAssignmentsCard: React.FC<GroupAssignmentsCardProps> = ({ 
    record,
    cdn_domain_name,
    groupNames
}) => {
    const navigate = useNavigate();

    return (
        <Col span={12}>
            <Card
                style={{ marginTop: 16 }}
                hoverable={true}
                bordered={false}
                onClick={() => navigate("/groups/show/" + record.group_id)}
                actions={[
                    <Tooltip title="More">
                        <Link 
                            to={`https://dashboard.${process.env.REACT_APP_API_BASE_URL}/transactions?pageSize=10&current=1&group_name=${record.group_name}`} 
                            target="_blank"
                        >
                            <ExpandAltOutlined key="more" />
                        </Link>
                    </Tooltip>,
                ]}
            >
                <Tooltip title={record.group_id}>
                    <Statistic 
                        title={`Transactions in group: ${getGroupName(record?.group_id, groupNames)?.substring(0, 20)}...`} 
                        value={record.count} 
                    />
                </Tooltip>
                
                <Typography.Title level={5}>Transaction(s)</Typography.Title>
                
                <List
                    itemLayout="horizontal"
                    dataSource={record.assignments}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <Avatar 
                                        src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record.assignments[index].chain_id}.svg`} 
                                    />
                                }
                                title={
                                    <Link 
                                        to={`https://${process.env.REACT_APP_API_BASE_URL}/transactions?pageSize=10&current=1&group_name=${item}`} 
                                        target="_blank"
                                    >
                                        {record.assignments[index].transaction_id.split("-").at(-2)}
                                    </Link>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Card>
        </Col>
    );
}; 
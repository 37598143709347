interface AddressLabelOptions {
    requireLogo?: boolean;
    chainId?: string;
    defaultLogo?: string;
}

interface AddressInfo {
    label: string;
    logo?: string;
}

export interface AddressData {
    is_search_transaction?: boolean;
    from_address?: string;
    to_address?: string;
    from_address_entity?: string | string[];
    to_address_entity?: string | string[];
    from_address_entity_logo?: string;
    to_address_entity_logo?: string;
    address_items_map?: Record<string, any>;
}

export const getAddressInfo = (
    address: string | undefined,
    addressData: AddressData,
    options: AddressLabelOptions = {}
): AddressInfo => {
    if (!address) return { label: 'No address label' };

    const {
        requireLogo = false,
        chainId,
        defaultLogo = ''
    } = options;

    // Normalize addresses for comparison
    const normalizedAddress = address.toLowerCase();
    const normalizedFromAddress = addressData.from_address?.toLowerCase();
    const normalizedToAddress = addressData.to_address?.toLowerCase();

    // Handle search transaction with entity data
    if (addressData?.is_search_transaction) {
        // Check if address matches from_address or to_address
        if (normalizedAddress === normalizedFromAddress) {
            return {
                label: Array.isArray(addressData.from_address_entity)
                    ? addressData.from_address_entity.map((label: any) => label.name).join(', ')
                    : addressData.from_address_entity || 'No label',
                logo: addressData.from_address_entity_logo
            };
        }
        if (normalizedAddress === normalizedToAddress) {
            return {
                label: Array.isArray(addressData.to_address_entity)
                    ? addressData.to_address_entity.map((label: any) => label.name).join(', ')
                    : addressData.to_address_entity || 'No label',
                logo: addressData.to_address_entity_logo
            };
        }
    }

    // Handle address from address_items_map
    const addressItem = addressData?.address_items_map?.[normalizedAddress];
    if (addressItem) {
        const label = addressItem.main_labels?.chain_id?.name || 
                     addressItem.labels?.[0]?.name || 
                     'No label';

        if (!requireLogo) {
            return { label };
        }

        const logo = addressItem.main_labels?.chain_id?.source || 
                    addressItem.labels?.[0]?.source;
        
        if (logo) {
            return { label, logo };
        }
    }

    // Return default values
    return {
        label: 'No label',
        ...(requireLogo && chainId && {
            logo: defaultLogo || `https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`
        })
    };
}; 


// The login page for the sandbox / prd environments is managed by the authentication-loginpage repo
// This is just kept here for local testing the whole application and to have a login place
import { Layout as AntdLayout, Button, Row, Col } from "antd";
import { ConnectButton } from '@rainbow-me/rainbowkit';

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from 'react';

import axios from 'axios';

import { useSearchParams } from "react-router-dom";

export default function Login() {
    console.log("Dashboard - login component")

    const [searchParams, setSearchParams] = useSearchParams();

    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        const invite = searchParams.get("invite")
        const org_id = searchParams.get("org_id")
        const tenant_id = searchParams.get("tenant_id")
        const roles = searchParams.get("roles")
        
        console.log(invite)
        console.log(org_id)
        console.log(tenant_id)
        console.log(roles)
        if(invite){
            loginWithRedirect({authorizationParams:
                {
                    invite:invite,
                    org_id: org_id,
                    tenant_id: tenant_id,
                    roles: roles
                }
            })
        }
        else{
            console.log("Login with redirect")
            loginWithRedirect(
                {
                    authorizationParams: {
                        redirect_uri: window.location.origin + "/dashboard",
                    }
                }
            )
        }
    });

    return (
        <AntdLayout
            style={{
                background: `#649be3`,
                backgroundSize: "cover",
            }}
        >
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "28px",
                        }}
                    >
                        {/* <img src="./refine.svg" alt="Refine" /> */}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    <ConnectButton chainStatus="name" />
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
}

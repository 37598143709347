import React, { Key } from 'react';
import { Space, TableColumnType, Tag, Typography } from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import type { Transaction } from '../types';
import { IAddress } from 'interfaces/address';

interface SelectedSection {
    type: 'from' | 'to' | 'internal' | 'native' | 'erc20' | 'nft' | 'contract';
    subType: string | null;
    data: any;
    searchAddress: string;
    rowKey: string;
    address_items_map?: Record<string, IAddress>;
}

interface GetFoundInColumnProps {
    searchAddress: string;
    setSelectedSection: (section: SelectedSection | null) => void;
    address_items_map: Record<string, IAddress>;
}

export const getFoundInColumn = ({
    searchAddress,
    setSelectedSection,
    address_items_map
}: GetFoundInColumnProps): TableColumnType<Transaction> => ({
    title: () => (
        <Space direction="vertical" size={0} style={{ }}>
            <Typography.Text strong >Found Address In</Typography.Text>
            <Space direction="vertical" size={0}>
                <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                    Shows where address appears in each transaction:
                </Typography.Text>
                <Typography.Text code copyable style={{ fontSize: '12px' }}>
                    {searchAddress}
                </Typography.Text>
            </Space>
        </Space>
    ),
    dataIndex: 'address',
    key: 'found_in',
    width: 200,
    filters: [
        { 
            text: <Tag color="green">From Address</Tag>, 
            value: 'From Address' 
        },
        { 
            text: <Tag color="blue">To Address</Tag>, 
            value: 'To Address' 
        },
        { 
            text: <Tag color="purple">Internal Transaction</Tag>, 
            value: 'Internal Transaction'
        },
        { 
            text: <Tag color="orange">Native Transfer</Tag>, 
            value: 'Native Transfer'
        },
        { 
            text: <Tag color="cyan">ERC20 Transfer</Tag>, 
            value: 'ERC20 Transfer'
        },
        { 
            text: <Tag color="magenta">NFT Transfer</Tag>, 
            value: 'NFT Transfer'
        },
        {
            text: <Tag color="red">Contract Interaction</Tag>,
            value: 'Contract Interaction',
            children: [
                {
                    text: <Tag color="gold">Approval</Tag>,
                    value: 'Contract Approval'
                },
                {
                    text: <Tag color="gold">Method Call</Tag>,
                    value: 'Contract Method Call'
                }
            ]
        }
    ],
    onFilter: (value: boolean | Key, record: Transaction) => {
        const monitoredAddress = searchAddress.toLowerCase();
        
        switch(value as string) {
            case 'From Address':
                return record.from_address?.toLowerCase() === monitoredAddress;
            case 'To Address':
                return record.to_address?.toLowerCase() === monitoredAddress;
            case 'Internal Transaction':
                return Boolean(record.internal_transactions?.some((tx: { from?: string, to?: string }) => 
                    tx?.from?.toLowerCase() === monitoredAddress || 
                    tx?.to?.toLowerCase() === monitoredAddress
                ));
            case 'Native Transfer':
                return Boolean(record.native_transfers?.some((transfer: { from_address?: string, to_address?: string }) =>
                    transfer?.from_address?.toLowerCase() === monitoredAddress || 
                    transfer?.to_address?.toLowerCase() === monitoredAddress
                ));
            case 'ERC20 Transfer':
                return Boolean(record.erc20_transfers?.some((transfer: { from_address?: string, to_address?: string }) =>
                    transfer?.from_address?.toLowerCase() === monitoredAddress || 
                    transfer?.to_address?.toLowerCase() === monitoredAddress
                ));
            case 'NFT Transfer':
                return Boolean(record.nft_transfers?.some((transfer: { from_address?: string, to_address?: string }) =>
                    transfer?.from_address?.toLowerCase() === monitoredAddress || 
                    transfer?.to_address?.toLowerCase() === monitoredAddress
                ));
            case 'Contract Approval':
                return Boolean(record.contract_interactions?.approvals && record.contract_interactions.approvals.length > 0);
            case 'Contract Method Call':
                return Boolean(record.contract_interactions?.method_calls && record.contract_interactions.method_calls.length > 0);
            case 'Contract Interaction':                
                return Boolean(
                    record.contract_interactions && 
                    (record.contract_interactions.approvals?.length > 0 || 
                        record.contract_interactions.method_calls?.length > 0)
                );                 
            default:
                return false;
        }
    },
    render: (_: unknown, record: Transaction) => {
        if (!searchAddress) return <div style={{ width: '100%', minWidth: 200, display: 'inline-block' }}></div>;
        const normalizedSearchAddress = searchAddress.toLowerCase();

        const locations = [];
        
        if (record.from_address?.toLowerCase() === normalizedSearchAddress) {
            locations.push(
                <Tag 
                    key="from"
                    color="green" 
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSelectedSection({
                        type: 'from',
                        subType: null,
                        data: record,
                        searchAddress: normalizedSearchAddress,
                        rowKey: `${record.chain_id}_${record.hash}`,    
                        address_items_map: address_items_map
                    })}
                >
                    From Address
                </Tag>
            );
        }
        
        if (record.to_address?.toLowerCase() === normalizedSearchAddress) {
            locations.push(
                <Tag 
                    key="to"
                    color="blue" 
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSelectedSection({
                        type: 'to',
                        subType: null,
                        data: record,
                        searchAddress: normalizedSearchAddress,
                        rowKey: `${record.chain_id}_${record.hash}`,    
                        address_items_map: address_items_map
                    })}
                >
                    To Address
                </Tag>
            );
        }

        const matchingInternalTxs = record.internal_transactions?.filter((tx: { from?: string, to?: string }) => 
            tx?.from?.toLowerCase() === normalizedSearchAddress || 
            tx?.to?.toLowerCase() === normalizedSearchAddress
        ) || [];

        if (matchingInternalTxs.length > 0) {
            locations.push(
                <Tag 
                    key="internal"
                    color="purple" 
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSelectedSection({
                        type: 'internal',
                        subType: null,
                        data: matchingInternalTxs,
                        searchAddress: normalizedSearchAddress,
                        rowKey: `${record.chain_id}_${record.hash}`,    
                        address_items_map: address_items_map
                    })}
                >
                    Internal Transaction ({matchingInternalTxs.length})
                </Tag>
            );
        }

        const matchingNativeTransfers = record.native_transfers?.filter((transfer: { from_address?: string, to_address?: string }) =>
            transfer?.from_address?.toLowerCase() === normalizedSearchAddress || 
            transfer?.to_address?.toLowerCase() === normalizedSearchAddress
        ) || [];

        if (matchingNativeTransfers.length > 0) {
            locations.push(
                <Tag 
                    key="native"
                    color="orange" 
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSelectedSection({
                        type: 'native',
                        subType: null,
                        data: matchingNativeTransfers,
                        searchAddress: normalizedSearchAddress,
                        rowKey: `${record.chain_id}_${record.hash}`,    
                        address_items_map: address_items_map
                    })}
                >
                    Native Transfer ({matchingNativeTransfers.length})
                </Tag>
            );
        }

        const matchingERC20Transfers = record.erc20_transfers?.filter((transfer: { from_address?: string, to_address?: string }) =>
            transfer?.from_address?.toLowerCase() === normalizedSearchAddress || 
            transfer?.to_address?.toLowerCase() === normalizedSearchAddress
        ) || [];

        if (matchingERC20Transfers.length > 0) {
            locations.push(
                <Tag 
                    key="erc20"
                    color="cyan" 
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSelectedSection({
                        type: 'erc20',
                        subType: null,
                        data: matchingERC20Transfers,
                        searchAddress: normalizedSearchAddress,
                        rowKey: `${record.chain_id}_${record.hash}`,    
                        address_items_map: address_items_map
                    })}
                >
                    ERC20 Transfer ({matchingERC20Transfers.length})
                </Tag>
            );
        }

        const matchingNFTTransfers = record.nft_transfers?.filter((transfer: { from_address?: string, to_address?: string }) =>
            transfer?.from_address?.toLowerCase() === normalizedSearchAddress || 
            transfer?.to_address?.toLowerCase() === normalizedSearchAddress
        ) || [];

        if (matchingNFTTransfers.length > 0) {
            locations.push(
                <Tag 
                    key="nft"
                    color="magenta" 
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSelectedSection({
                        type: 'nft',
                        subType: null,
                        data: matchingNFTTransfers,
                        searchAddress: normalizedSearchAddress,
                        rowKey: `${record.chain_id}_${record.hash}`,    
                        address_items_map: address_items_map
                    })}
                >
                    NFT Transfer ({matchingNFTTransfers.length})
                </Tag>
            );
        }

        const matchingContractInteractions = (() => {
            if (!record.contract_interactions) return { data: [], type: null };
            
            const interactionType = Object.entries(record.contract_interactions)
                .find(([_, interactions]) => Array.isArray(interactions) && interactions.length > 0)?.[0] || null;
            
            return {
                data: interactionType ? record.contract_interactions[interactionType] : [],
                type: interactionType
            };
        })();

        const contractData = matchingContractInteractions.data;
        if (contractData.length > 0) {
            locations.push(
                <Tag 
                    key="contract"
                    color="red" 
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSelectedSection({
                        type: 'contract',
                        subType: matchingContractInteractions.type,
                        data: contractData,
                        searchAddress: normalizedSearchAddress,
                        rowKey: `${record.chain_id}_${record.hash}`,    
                        address_items_map: address_items_map
                    })}
                >
                    Contract Interaction ({contractData.length})
                </Tag>
            );
        }

        return <Space style={{ whiteSpace: 'nowrap', minWidth: 300,  display: 'inline-block'  }} wrap>{locations}</Space>;
    }
});

interface FoundInColumnProps {
    record: Transaction;
    searchAddress: string;
    setSelectedSection: (section: SelectedSection) => void;
    address_items_map?: Record<string, IAddress>;
}

export const FoundInColumn: React.FC<FoundInColumnProps> = ({
    record,
    searchAddress,
    setSelectedSection,
    address_items_map
}) => {
    if (!searchAddress) return null;
    const normalizedSearchAddress = searchAddress.toLowerCase();

    const locations = [];
    
    if (record.from_address?.toLowerCase() === normalizedSearchAddress) {
        locations.push(
            <Tag 
                key="from"
                color="green" 
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedSection({
                    type: 'from',
                    subType: null,
                    data: record,
                    searchAddress: normalizedSearchAddress,
                    rowKey: `${record.chain_id}_${record.hash}`,    
                    address_items_map: address_items_map
                })}
            >
                From Address
            </Tag>
        );
    }
    
    if (record.to_address?.toLowerCase() === normalizedSearchAddress) {
        locations.push(
            <Tag 
                key="to"
                color="blue" 
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedSection({
                    type: 'to',
                    subType: null,
                    data: record,
                    searchAddress: normalizedSearchAddress,
                    rowKey: `${record.chain_id}_${record.hash}`,    
                    address_items_map: address_items_map
                })}
            >
                To Address
            </Tag>
        );
    }

    const matchingInternalTxs = record.internal_transactions?.filter((tx: { from?: string, to?: string }) => 
        tx?.from?.toLowerCase() === normalizedSearchAddress || 
        tx?.to?.toLowerCase() === normalizedSearchAddress
    ) || [];

    if (matchingInternalTxs.length > 0) {
        locations.push(
            <Tag 
                key="internal"
                color="purple" 
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedSection({
                    type: 'internal',
                    subType: null,
                    data: matchingInternalTxs,
                    searchAddress: normalizedSearchAddress,
                    rowKey: `${record.chain_id}_${record.hash}`,    
                    address_items_map: address_items_map
                })}
            >
                Internal Transaction ({matchingInternalTxs.length})
            </Tag>
        );
    }

    const matchingNativeTransfers = record.native_transfers?.filter((transfer: { from_address?: string, to_address?: string }) =>
        transfer?.from_address?.toLowerCase() === normalizedSearchAddress || 
        transfer?.to_address?.toLowerCase() === normalizedSearchAddress
    ) || [];

    if (matchingNativeTransfers.length > 0) {
        locations.push(
            <Tag 
                key="native"
                color="orange" 
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedSection({
                    type: 'native',
                    subType: null,
                    data: matchingNativeTransfers,
                    searchAddress: normalizedSearchAddress,
                    rowKey: `${record.chain_id}_${record.hash}`,    
                    address_items_map: address_items_map
                })}
            >
                Native Transfer ({matchingNativeTransfers.length})
            </Tag>
        );
    }

    const matchingERC20Transfers = record.erc20_transfers?.filter((transfer: { from_address?: string, to_address?: string }) =>
        transfer?.from_address?.toLowerCase() === normalizedSearchAddress || 
        transfer?.to_address?.toLowerCase() === normalizedSearchAddress
    ) || [];

    if (matchingERC20Transfers.length > 0) {
        locations.push(
            <Tag 
                key="erc20"
                color="cyan" 
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedSection({
                    type: 'erc20',
                    subType: null,
                    data: matchingERC20Transfers,
                    searchAddress: normalizedSearchAddress,
                    rowKey: `${record.chain_id}_${record.hash}`,    
                    address_items_map: address_items_map
                })}
            >
                ERC20 Transfer ({matchingERC20Transfers.length})
            </Tag>
        );
    }

    const matchingNFTTransfers = record.nft_transfers?.filter((transfer: { from_address?: string, to_address?: string }) =>
        transfer?.from_address?.toLowerCase() === normalizedSearchAddress || 
        transfer?.to_address?.toLowerCase() === normalizedSearchAddress
    ) || [];

    if (matchingNFTTransfers.length > 0) {
        locations.push(
            <Tag 
                key="nft"
                color="magenta" 
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedSection({
                    type: 'nft',
                    subType: null,
                    data: matchingNFTTransfers,
                    searchAddress: normalizedSearchAddress,
                    rowKey: `${record.chain_id}_${record.hash}`,    
                    address_items_map: address_items_map
                })}
            >
                NFT Transfer ({matchingNFTTransfers.length})
            </Tag>
        );
    }

    const matchingContractInteractions = (() => {
        if (!record.contract_interactions) return { data: [], type: null };
        
        const interactionType = Object.entries(record.contract_interactions)
            .find(([_, interactions]) => Array.isArray(interactions) && interactions.length > 0)?.[0] || null;
        
        return {
            data: interactionType ? record.contract_interactions[interactionType] : [],
            type: interactionType
        };
    })();

    const contractData = matchingContractInteractions.data;
    if (contractData.length > 0) {
        locations.push(
            <Tag 
                key="contract"
                color="red" 
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedSection({
                    type: 'contract',
                    subType: matchingContractInteractions.type,
                    data: contractData,
                    searchAddress: normalizedSearchAddress,
                    rowKey: `${record.chain_id}_${record.hash}`,    
                    address_items_map: address_items_map
                })}
            >
                Contract Interaction ({contractData.length})
            </Tag>
        );
    }

    return <Space wrap>{locations}</Space>;
};
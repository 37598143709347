import React, { useState, useEffect } from 'react';
import { Button, Tooltip, Badge, message } from 'antd';
import { BellOutlined, BellFilled, EyeOutlined, EyeInvisibleOutlined, SyncOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface AddressWatcherProps {
    address: string;
    isWatching: boolean;
    watchStatus: WatchStatus;
    onWatchToggle: (watching: boolean) => void;
    isLoadingToggleUpdate: boolean;
}

type WatchStatus = 'unwatched' | 'processing' | 'watching' | 'error';

const AnimatedButton = styled(Button)`
    transition: all 0.3s ease;
    
    .anticon {
        transition: transform 0.3s ease;
    }
    
    &:hover .anticon {
        transform: scale(1.1);
    }
`;

const PulsingBadge = styled(Badge)`
    .ant-badge-status-dot {
        animation: ${props => props.status === 'processing' ? 'pulse 1.5s infinite' : 'none'};
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        50% {
            transform: scale(1.2);
            opacity: 0.8;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
`;

const ButtonWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const RippleEffect = styled.div<{ active: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background-color: ${props => props.active ? 'rgba(24, 144, 255, 0.1)' : 'transparent'};
    transition: background-color 0.3s ease;
    pointer-events: none;
`;

const ButtonContent = styled.span`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const AddressWatcher: React.FC<AddressWatcherProps> = ({
    address,
    isWatching: initialIsWatching,
    watchStatus: initialWatchStatus,
    onWatchToggle,
    isLoadingToggleUpdate
}) => {
    const [isWatching, setIsWatching] = useState(initialIsWatching);
    const [watchStatus, setWatchStatus] = useState(initialWatchStatus);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [ripple, setRipple] = useState(false);

    // Update local state when prop changes
    useEffect(() => {
        setIsWatching(initialIsWatching);
        setWatchStatus(initialWatchStatus);
    }, [initialIsWatching, initialWatchStatus]);

    const handleWatchToggle = async () => {
        setLoading(true);
        setError(null);
        setRipple(true);
        setTimeout(() => setRipple(false), 300);

        try {
            if (watchStatus === 'watching') {
                await onWatchToggle(false);
                setWatchStatus('unwatched');
                message.success('Successfully stopped watching');
            } else {
                await onWatchToggle(true);
                setWatchStatus('processing');
                message.info('Setting up transaction monitoring...');
            }
        } catch (error) {
            console.error('Watch action failed:', error);
            setWatchStatus('error');
            setError(error instanceof Error ? error.message : 'Failed to update watch status');
            message.error('Failed to update watch status');
        } finally {
            setLoading(false);
        }
    };

    const statusConfig = {
        watching: {
            type: 'primary' as const,
            icon: <EyeOutlined />,
            badgeStatus: 'processing' as const,
            badgeColor: '#52c41a', // green
            text: 'Watching',
            textColor: 'white'
        },
        processing: {
            type: 'default' as const,
            icon: <SyncOutlined spin />,
            badgeStatus: 'warning' as const,
            badgeColor: '#faad14', // yellow
            text: 'Processing',
            textColor: 'rgba(0, 0, 0, 0.85)'
        },
        unwatched: {
            type: 'default' as const,
            icon: <EyeInvisibleOutlined />,
            badgeStatus: 'default' as const,
            badgeColor: '#d9d9d9', // grey
            text: 'Watch',
            textColor: 'rgba(0, 0, 0, 0.85)'
        },
        error: {
            type: 'danger' as const,
            icon: <EyeInvisibleOutlined style={{ color: '#ff4d4f' }} />,
            badgeStatus: 'error' as const,
            badgeColor: '#ff4d4f', // red
            text: 'Retry',
            textColor: 'white'
        }
    };

    const { type, icon, badgeStatus, badgeColor, text, textColor } = statusConfig[watchStatus];

    return (
        <Tooltip 
            title={getWatchTooltip(watchStatus, error)}
            color={error ? 'red' : undefined}
        >
            <ButtonWrapper>
                <AnimatedButton 
                    onClick={handleWatchToggle} 
                    loading={loading || isLoadingToggleUpdate}
                    disabled={watchStatus === 'processing' || loading || isLoadingToggleUpdate}
                    type={type as 'default' | 'primary' | 'dashed' | 'link' | 'text' | undefined}
                    danger={watchStatus === 'error'}
                >
                    <ButtonContent>
                        {icon}
                        {status !== 'unwatched' ? (
                            <PulsingBadge 
                                status={getWatchBadgeStatus(watchStatus)}
                                text={text}
                                style={{ 
                                    color: textColor,
                                }}
                                color={badgeColor}
                            />
                        ) : (
                            text
                        )}
                    </ButtonContent>
                </AnimatedButton>
                <RippleEffect active={ripple} />
            </ButtonWrapper>
        </Tooltip>
    );
};

const getWatchBadgeStatus = (status: WatchStatus): "success" | "processing" | "default" | "error" | "warning" => {
    switch (status) {
        case 'watching':
            return 'processing';
        case 'processing':
            return 'warning';
        case 'error':
            return 'error';
        case 'unwatched':
        default:
            return 'default';
    }
};

const getWatchTooltip = (status: WatchStatus, error?: string | null) => {
    if (error) return error;
    
    switch (status) {
        case 'watching':
            return 'Actively monitoring this address for new transactions';
        case 'processing':
            return 'Setting up transaction monitoring...';
        case 'error':
            return 'Failed to update watch status';
        case 'unwatched':
        default:
            return 'Click to start monitoring';
    }
}; 
import { Create, getValueFromEvent, useForm, useSelect } from "@refinedev/antd";
import {
    Form,
    Input,
    Select,
    DatePicker,
    Switch,
    Card,
    Button,
    Space,
    Divider,
    Typography,
    Tag,
    Row,
    Col,
    Modal,
    InputNumber,
    notification,
    Avatar,
    Upload,
    Tooltip,
} from "antd";
import { PlusOutlined, MinusCircleOutlined, EditOutlined, InboxOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { IEntity, EntityType, EntityStatus} from "../../interfaces/entity.d";
import { ChainType, ChainName, ChainId, CHAIN_MAPPING } from "../../interfaces/chain.d";
import { useConfig } from "contexts";
import { useNavigate } from 'react-router-dom';
import { useCreate } from '@refinedev/core';

import dayjs from 'dayjs';

const { Text } = Typography;

// Custom types for form
interface EntityFormData extends Omit<IEntity, 'created_at' | 'created_by' | 'external_ids' | 'metadata'> {
    addresses: {
        address: string;
        chainId: ChainId;
        isMain: boolean;
    }[];
    metadata: {
        key: string;
        value: any;
    }[];
    // Override external_ids to use array format for the form
    external_ids: {
        platform: string;
        id: string;
    }[];
}

// Validation rules
const validationRules = {
    name: [
        { required: true, message: 'Name is required' },
        { min: 2, message: 'Name must be at least 2 characters' },
    ],
    website: [
        { type: 'url' as const, message: 'Must be a valid URL' },
    ],
    twitter: [
        { pattern: /^@?(\w){1,15}$/, message: 'Invalid Twitter handle' },
    ],
    risk_score: [
        { type: 'number' as const, min: 0, max: 100, message: 'Score must be between 0-100' },
    ],
    address: [
        { pattern: /^0x[a-fA-F0-9]{40}$/, message: 'Invalid Ethereum address' },
    ],
};

// Custom field components
const RiskScoreInput: React.FC<any> = ({ value, onChange, ...props }) => {
    const getColor = (score: number) => {
        if (score >= 75) return '#ff4d4f';
        if (score >= 50) return '#faad14';
        if (score >= 25) return '#52c41a';
        return '#1890ff';
    };

    return (
        <Space>
            <InputNumber
                {...props}
                value={value}
                onChange={onChange}
                min={0}
                max={100}
                style={{ width: 120 }}
            />
            {value !== undefined && (
                <Tag color={getColor(value)}>
                    {value >= 75 ? 'High Risk' :
                     value >= 50 ? 'Medium Risk' :
                     value >= 25 ? 'Low Risk' : 'Safe'}
                </Tag>
            )}
        </Space>
    );
};

// Create options with logo URLs
const CHAIN_OPTIONS = Object.entries(CHAIN_MAPPING)
    .filter(([_, name]) => !name.includes('_testnet'))
    .map(([chainId, name]) => ({
        label: (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Avatar 
                    size="small"
                    src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                    style={{ backgroundColor: 'transparent' }}
                />
                {name.charAt(0).toUpperCase() + name.slice(1)}
            </div>
        ),
        value: chainId as ChainId
    }));
        
export const EntityCreate: React.FC = () => {
    const { 
        user,
        token, 
        region, 
        continent, 
        domain, 
        qa_environment,
        cdn_domain_name 
    } = useConfig();
    
    const requestHeaders = {
        Authorization: `Bearer ${token.__raw}`,
        Accept: "application/json, text/plain, */*",
        "Source-Platform": "dashboard",
        "Source-Region": region.use_region,
        "Destination-Region": region.use_region,
        "filename": "testhere.png"
    };
       
    const [form] = Form.useForm<EntityFormData>();
    const { formProps, saveButtonProps, onFinish } = useForm<EntityFormData>({      
    });

    // Load related entities
    const { selectProps: parentEntitySelectProps } = useSelect({
        resource: "entities",
        optionLabel: "name",
        optionValue: "id",
        filters: [
            {
                field: "type",
                operator: "in",
                value: [EntityType.COMPANY, EntityType.DAO],
            },
        ],
    });

    const { selectProps: relatedEntitiesSelectProps } = useSelect({
        resource: "entities",
        optionLabel: "name",
        optionValue: "id",
        filters: [
            {
                field: "status",
                operator: "eq",
                value: EntityStatus.ACTIVE,
            },
        ],
    });

    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const { mutate: createEntity } = useCreate();

    // Transform form data before submission
    const handleSubmit = async (values: any) => {
        setSubmitting(true);
        console.log('Raw form values:', values); // Debug the raw form values
        try {
            // Get all unique chains where any address exists
            const chain_ids = Array.from(new Set(
                values.addresses.flatMap((addr: any) => addr.chainIds)
            ));
            
            // Get all unique chains where this entity is the main entity for any address
            const main_chain_ids = Array.from(new Set(
                values.addresses.flatMap((addr: any) => addr.mainChainIds || [])
            ));

            // Transform addresses to maintain array structure
            const addresses = values.addresses.map(({ address, chainIds, mainChainIds }: any) => ({
                address,
                chain_ids: chainIds || [],
                main_chain_ids: mainChainIds || []  // Always set, defaults to empty array
            }));

            const transformedData = {
                ...values,
                name: values.name,
                type: values.type,
                status: values.status,
                website: values.website,
                twitter: values.twitter,
                description: values.description,
                risk_score: values.risk_score,
                compliance_status: values.compliance_status,
                chain_ids,        // All chains where any address exists
                main_chain_ids,   // All chains where this entity is the main entity
                addresses, 
                metadata: values.metadata?.reduce((acc: any, { key, value }: any) => ({
                    ...acc,
                    [key]: value,
                }), {}) || {},
                external_ids: values.external_ids?.reduce((acc: any, { platform, id }: any) => ({
                    ...acc,
                    [platform]: id,
                }), {}) || {},
                first_seen: values.first_seen ? dayjs(values.first_seen).unix() : undefined,
                last_activity: values.last_activity ? dayjs(values.last_activity).unix() : undefined,
                last_risk_assessment: values.last_risk_assessment ? 
                    dayjs(values.last_risk_assessment).unix() : undefined,
            };

            // Create the entity
            await onFinish(transformedData);

            notification.success({
                message: 'Success',
                description: `Entity "${values.name}" has been created successfully.`,
                placement: 'topRight',
            });

            // Redirect to the entities list
            // navigate('/entities');
        } catch (error: any) {
            notification.error({
                message: 'Error',
                description: error.message || 'Something went wrong while creating the entity.',
                placement: 'topRight',
            });
        } finally {
            setSubmitting(false);
        }
    };

    // Add loading state for image upload
    const [imageLoading, setImageLoading] = useState(false);

    return (
        <Create saveButtonProps={saveButtonProps} isLoading={submitting}>
            <Form {...formProps} onFinish={handleSubmit}
                initialValues={{
                    type: EntityType.COMPANY,
                    status: EntityStatus.ACTIVE,
                    tags: [],
                    labels: [],
                    child_entity_ids: [],
                    related_entity_ids: [],
                    addresses: [],
                    metadata: [],
                    external_ids: [],
                }}>
                <Card title="Basic Information" bordered={false}>
                    <Row gutter={[24, 24]}>
                        <Col span={8}>
                            <Form.Item
                                name="logo"
                                label="Logo"
                                valuePropName="fileList"
                                getValueFromEvent={getValueFromEvent}
                            >
                                <Upload.Dragger 
                                    name="file" 
                                    action={qa_environment === "blue" ?
                                        `${process.env.REACT_APP_API_URL}/private/labels/blue/ANY/${continent.toUpperCase()}/v0/labels/upload-image`
                                        :
                                        `${process.env.REACT_APP_API_URL}/private/labels/ANY/${continent.toUpperCase()}/v0/labels/upload-image`}
                                    headers={requestHeaders}
                                    listType="picture"
                                    maxCount={1}
                                    multiple={false}
                                    showUploadList={{
                                        showPreviewIcon: true,
                                        showRemoveIcon: true,
                                        showDownloadIcon: false
                                    }}
                                    style={{ 
                                        height: 'auto',
                                        width: '100%',
                                        backgroundColor: '#fafafa',
                                        borderRadius: '8px'
                                    }}
                                    onChange={(info) => {
                                        setImageLoading(info.file.status === 'uploading');
                                        if (info.file.status === 'done') {
                                            notification.success({
                                                message: 'Logo uploaded successfully',
                                                description: info.file.name,
                                            });
                                        } else if (info.file.status === 'error') {
                                            notification.error({
                                                message: 'Logo upload failed',
                                                description: 'Please try again',
                                            });
                                        }
                                    }}
                                    beforeUpload={(file) => {
                                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                        const isLt5M = file.size / 1024 / 1024 < 5;
                                        
                                        if (!isJpgOrPng) {
                                            notification.error({ message: 'You can only upload JPG/PNG files!' });
                                        }
                                        if (!isLt5M) {
                                            notification.error({ message: 'Image must be smaller than 5MB!' });
                                        }
                                        
                                        return isJpgOrPng && isLt5M;
                                    }}
                                    progress={{
                                        strokeColor: {
                                            '0%': '#40a9ff',
                                            '100%': '#52c41a',
                                        },
                                        strokeWidth: 3,
                                        style: { top: 12 }
                                    }}
                                >
                                    {(form.getFieldValue('logo')?.length ?? 0) === 0 && (
                                        <div style={{ padding: '20px 0' }}>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined style={{ 
                                                    fontSize: '48px', 
                                                    color: '#40a9ff' 
                                                }} />
                                            </p>
                                            <p className="ant-upload-text" style={{ 
                                                margin: '8px 0',
                                                color: '#262626',
                                                fontSize: '16px',
                                                fontWeight: 500
                                            }}>
                                                Click or drag logo
                                            </p>
                                            <p className="ant-upload-hint" style={{ 
                                                fontSize: '12px', 
                                                color: '#666' 
                                            }}>
                                                Supports PNG, JPG up to 5MB
                                            </p>
                                        </div>
                                    )}
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={validationRules.name}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Type"
                                        name="type"
                                        rules={[{ required: true }]}
                                    >
                                        <Select options={Object.entries(EntityType).map(([key, value]) => ({
                                            label: key,
                                            value: value,
                                        }))} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Status"
                                        name="status"
                                        rules={[{ required: true }]}
                                    >
                                        <Select options={Object.entries(EntityStatus).map(([key, value]) => ({
                                            label: key,
                                            value: value,
                                        }))} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item 
                                        label="Website" 
                                        name="website"
                                        rules={validationRules.website}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item 
                                        label="Twitter" 
                                        name="twitter"
                                        rules={validationRules.twitter}
                                    >
                                        <Input addonBefore="@" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Description" name="description">
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card title="Blockchain Addresses" bordered={false}>
                    <Form.List name="addresses">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} align="baseline" wrap>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'address']}
                                            rules={[{ required: true, message: 'Address is required' }]}
                                        >
                                            <Input placeholder="Address (0x...)" style={{ width: '300px' }} />
                                        </Form.Item>

                                        <Form.Item
                                            {...restField}
                                            name={[name, 'chainIds']}
                                            rules={[{ required: true, message: 'Select at least one chain' }]}
                                        >
                                            <Select 
                                                mode="multiple"
                                                style={{ width: '300px' }}
                                                placeholder="Select chains"
                                                options={CHAIN_OPTIONS}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            {...restField}
                                            name={[name, 'mainChainIds']}
                                            label={
                                                <Tooltip title="Select chains where this entity is considered the main/primary entity for this address">
                                                    Main Entity for Chains
                                                </Tooltip>
                                            }
                                            dependencies={[['addresses', name, 'chainIds']]}
                                        >
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) => {
                                                    const selectedChains = getFieldValue(['addresses', name, 'chainIds']) || [];
                                                    return (
                                                        <Select 
                                                            {...restField}
                                                            mode="multiple"
                                                            style={{ width: '300px' }}
                                                            placeholder="Select chains where this entity is the main owner"
                                                            options={CHAIN_OPTIONS.filter(option => 
                                                                selectedChains.includes(option.value)
                                                            )}
                                                            disabled={!selectedChains.length}
                                                            value={getFieldValue(['addresses', name, 'mainChainIds'])}
                                                            onChange={(value) => {
                                                                formProps.form?.setFieldValue(['addresses', name, 'mainChainIds'], value);
                                                            }}
                                                        />
                                                    );
                                                }}
                                            </Form.Item>
                                        </Form.Item>

                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Address
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>

                <Card title="Risk Assessment" bordered={false}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item 
                                label="Risk Score" 
                                name="risk_score"
                                rules={validationRules.risk_score}
                            >
                                <RiskScoreInput />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item 
                                label="Last Risk Assessment" 
                                name="last_risk_assessment"
                            >
                                <DatePicker 
                                    showTime 
                                    style={{ width: '100%' }} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item 
                                label="Compliance Status" 
                                name="compliance_status"
                            >
                                <Select
                                    options={[
                                        { label: 'Compliant', value: 'compliant' },
                                        { label: 'Non-Compliant', value: 'non_compliant' },
                                        { label: 'Under Review', value: 'under_review' },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card title="Entity Relationships" bordered={false}>
                    <Form.Item 
                        label="Parent Entity" 
                        name="parent_entity_id"
                    >
                        <Select
                            {...parentEntitySelectProps}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>

                    <Form.Item 
                        label="Related Entities" 
                        name="related_entity_ids"
                    >
                        <Select
                            {...relatedEntitiesSelectProps}
                            mode="multiple"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>
                </Card>

                <Card title="Additional Information" bordered={false}>
                    <Form.List name="metadata">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row key={key} gutter={16} align="middle">
                                        <Col span={8}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'key']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="Key" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={14}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                            >
                                                <Input placeholder="Value" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Button 
                                                type="text" 
                                                danger 
                                                icon={<MinusCircleOutlined />} 
                                                onClick={() => remove(name)} 
                                            />
                                        </Col>
                                    </Row>
                                ))}
                                <Form.Item>
                                    <Button 
                                        type="dashed" 
                                        onClick={() => add()} 
                                        block 
                                        icon={<PlusOutlined />}
                                    >
                                        Add Metadata
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>
            </Form>
        </Create>
    );
};

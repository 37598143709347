import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { IResourceComponentsProps, useList, useShow } from "@refinedev/core";

import {
    List,
    Show,
    useModalForm,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Space, Button, Form, Input, Radio, Modal, Tooltip, Select, Card, Alert, Typography, Checkbox, Avatar, notification, Divider, Descriptions, Tag } from "antd";
import { ImportOutlined, FilterOutlined } from '@ant-design/icons';

import { TransactionTable } from '../../components/transactions/table';

import { CHAIN_MAPPING } from "../../interfaces/chain.d";
import { ChainAvatar } from "../../components/chain-avatar";
import { TransactionGraph } from '../../components/graph/TransactionGraph';
import { useConfig } from 'contexts/config/ConfigContext';
import { CHAIN_CONFIG } from "../../interfaces/chain.d"
import { createDebugger } from 'contexts/leader/debug';
import { useSubscriptions } from 'contexts/subscriptions/useSubscriptions';
import { IGroup, IGroupTransaction } from 'interfaces/group';

const { Title } = Typography;
export const GroupShow: React.FC<IResourceComponentsProps> = () => {
    const [transactions, setTransactions] = useState<any[]>([]);
    const [filteredTransactions, setFilteredTransactions] = useState<any[]>([]);
    const [addressItemsMap, setAddressItemsMap] = useState<any>({});
    const [graphKey, setGraphKey] = useState(0);    
    const [searchError, setSearchError] = useState<string | null>(null);
    const { org_id, tenant_id, user_id, user, token, cdn_domain_name } = useConfig();
    const [searchInputValue, setSearchInputValue] = useState<string>('');

    const decodeGroupData = (encodedData: string) => {
        try {
            return JSON.parse(decodeURIComponent(encodedData));
        } catch (e) {
            console.error('Failed to decode group data:', e);
            return null;
        }
    };

    // Helper function to get URL parameters
    const getUrlParam = (param: string) => {
        const lastPathSegment = window.location.pathname.split('/').pop() || '';
        
        // First try to parse as URL parameters
        try {
            const urlParams = new URLSearchParams(decodeURIComponent(lastPathSegment));
            const value = urlParams.get(param);
            if (value) return value;
        } catch (e) {
            // If parsing fails, it might be a direct path
        }

        // If param is 'id' and we couldn't parse URL params, return the last path segment
        if (param === 'id' && lastPathSegment.startsWith('group_')) {
            return lastPathSegment;
        }

        return null;
    };

    const { data, isLoading, isFetching, isRefetching, refetch } = useList({
        resource: "transactions",
        filters: [
            {
                field: "group",
                operator: "eq",
                value: getUrlParam('id'),
            },
        ],
        pagination: {
            pageSize: 100,
        },
        queryOptions: {
            enabled: true,
            onError: (error: any) => {
            },
            onSuccess: (data: any) => {
                const processedTransactions = data?.data?.transactions?.map((tx: any) => ({
                    ...tx,
                    // Ensure all required fields are present
                    address: tx.address,
                    // Add any computed fields needed for display
                    formattedTimestamp: dayjs(tx.block_timestamp).format('YYYY-MM-DD HH:mm:ss'),
                    // Add any address labels from address_items_map
                }));
                setTransactions(processedTransactions);
                setFilteredTransactions(processedTransactions); // Initially set filtered to all transactions
                setAddressItemsMap(data?.data?.address_items_map);
            },
        },
    });

    // Update filtered transactions when main transactions change
    useEffect(() => {
        // Ensure unique transactions by chain_id and hash
        const uniqueTransactions = filteredTransactions?.reduce((acc, tx) => {
            const key = `${tx.chain_id}_${tx.hash}`;
            acc[key] = tx;
            return acc;
        }, {} as Record<string, any>);

        setFilteredTransactions(Object.values(uniqueTransactions || []));
    }, [transactions]);

 
    const { status, isLeader, controls} = useSubscriptions({
        channels: [
            {
                name: `${org_id}-${tenant_id}-${user_id}-streamed-transactions`,
                onMessage: (message) => {
                    // Broadcast messages have a channel property
                    if (message.data?.channel) {
                        message = message.data;
                    }   
                                        
                    const transaction = message.data;
                    notification.open({
                        message: 'New transaction',
                        description: `New transaction: ${transaction.hash}`
                    });
                    
                    setTransactions(prev => {
                        // Create unique key for transaction
                        const txKey = `${transaction.chain_id}_${transaction.hash}`;
                        
                        // Find if transaction already exists
                        const existingIndex = prev.findIndex(tx => 
                            `${tx.chain_id}_${tx.hash}` === txKey
                        );

                        if (existingIndex >= 0) {
                            // Replace existing transaction
                            const updated = [...prev];
                            updated[existingIndex] = {
                                ...prev[existingIndex],
                                ...transaction,
                                updatedAt: new Date().toISOString()
                            };
                            return updated;
                        } else {
                            // Add new transaction
                            return [...prev, transaction];
                        }
                    });
                }
            },
            // You can add more channels here
            {
                name: `${org_id}-${tenant_id}-${user_id}-address-watch`,
                onMessage: (message) => {
                    // Handle address updates
                    console.log('Address update:', message.data);
                }
            }
        ],
        token: token.__raw,
        orgId: org_id,
        tenantId: tenant_id,
        userId: user_id,
        debug: createDebugger({
            prefix: 'Subscriptions',
            tabId: `Tab-${Math.random().toString(36).substr(2, 5)}`
        }),
        // Optional global message handler
        onMessage: (message: any) => {
            console.log(`Message from ${message.channel}:`, message.data);
        },
        // Optional error handler
        onError: (error: any) => {
            console.error('Subscription error:', error);
        }
    });   
    

    const { data: groupData, isLoading: groupLoading, isFetching: groupFetching, isRefetching: groupRefetching } = useList<IGroup>({
        resource: "groups",
    });
    const records = groupData?.data;
    

    return (
        <Show isLoading={false}>
            <Card>
                <Space align="center" style={{ marginBottom: 16 }}>
                    <Avatar 
                        size={64}
                        style={{ 
                            backgroundColor: `#${records?.filter((record: IGroup) => record.id === getUrlParam('id'))[0]?.color}`,
                            fontSize: '24px'
                        }}
                    >
                        {records?.filter((record: IGroup) => record.id === getUrlParam('id'))[0]?.name[0]?.toUpperCase()}
                    </Avatar>
                    <Space direction="vertical" size={0}>
                        <Title level={4} style={{ margin: 0 }}>
                            {records?.filter((record: IGroup) => record.id === getUrlParam('id'))[0]?.name}
                        </Title>
                        <Typography.Text type="secondary" copyable>
                            {records?.filter((record: IGroup) => record.id === getUrlParam('id'))[0]?.id}
                        </Typography.Text>
                    </Space>
                </Space>
                
                <Divider />

                <Descriptions bordered column={2}>
                    <Descriptions.Item label="Name" span={2}>
                        {records?.filter((record: IGroup) => record.id === getUrlParam('id'))[0]?.name}
                    </Descriptions.Item>
                    
                    <Descriptions.Item label="ID">
                        <Typography.Text copyable>
                            {records?.filter((record: IGroup) => record.id === getUrlParam('id'))[0]?.id}
                        </Typography.Text>
                    </Descriptions.Item>
                    
                    <Descriptions.Item label="Color">
                        <Space>
                            <div 
                                style={{ 
                                    width: 20, 
                                    height: 20, 
                                    backgroundColor: `#${records?.filter((record: IGroup) => record.id === getUrlParam('id'))[0]?.color}`,
                                    borderRadius: '4px'
                                }} 
                            />
                            <Tag>#{records?.filter((record: IGroup) => record.id === getUrlParam('id'))[0]?.color}</Tag>
                        </Space>
                    </Descriptions.Item>
                </Descriptions>
            </Card>
            
            {/* Transactions Table */}
            <Card>
                <TransactionTable
                    tableId="transactions"
                    searchAddress={''}
                    initialPaginationCursors={{
                        moralis: {},
                        database: {},
                        status: {}
                    }}
                    address_items_map={addressItemsMap}
                    activeChains={[]}
                    selectedChains={[]}
                    identifier={'transactions'}
                    transactions={transactions}
                    setTransactions={setTransactions}
                    setGraphKey={setGraphKey}
                    onTransactionDataChange={setFilteredTransactions}
                    isLoading={isLoading || isFetching || isRefetching}
                    showTransactionControlPanel={false}
                />             
            </Card>
            <Card>
                <TransactionGraph 
                    data={transactions}
                    filteredData={filteredTransactions}
                    chainIdToName={CHAIN_MAPPING}
                    graphKey={graphKey}
                    setGraphKey={setGraphKey}
                    cdn_domain_name={process.env.REACT_APP_CDN_DOMAIN_NAME || ''}
                    address_items_map={addressItemsMap}
                    subscriptionStatus={{
                        isEnabled: true,
                        isLeader: isLeader,
                        orgId: org_id,
                        tenantId: tenant_id,
                        userId: user_id,
                        addresses: [ /* your watched addresses */],
                        state: status[`${org_id}-${tenant_id}-${user_id}-streamed-transactions`]?.state || 'disconnected',
                    }}
                    options={{
                        showReplay: true,
                        showLive: true,
                        showTimeRange: true,
                        showSettings: true,
                        showShare: true
                    }}
                />
            </Card>          
        </Show>
    );
};

import React from 'react';
import { Modal, Button, Spin } from 'antd';
import AddressInfoCard from './AddressInfoCard';

interface AddressInfoModalProps {
  visible: boolean;
  onClose: () => void;
  addressData: any;
  loading: boolean;
  address_items_map: any;
  apiDomain: string;
  region: string;
  regionAlias: string;
  token: string;
  onViewContract?: (address: string, chainId: string) => void;
}

const AddressInfoModal: React.FC<AddressInfoModalProps> = ({
  visible,
  onClose,
  addressData,
  loading,
  onViewContract,
  address_items_map,
  apiDomain,
  region,
  regionAlias,
  token
}) => {
  return (
    <Modal
      title="Address Information"
      open={visible}
      onCancel={onClose}
      width={800}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>
      ]}
    >
      {loading ? (
        <div style={{ textAlign: 'center', padding: '40px 0' }}>
          <Spin size="large" />
        </div>
      ) : (
        <AddressInfoCard 
          addressData={addressData} 
          showDetailedView={true}
          onViewContract={onViewContract}
          address_items_map={addressData.address_items_map}
          apiDomain={apiDomain}
          region={region}
          regionAlias={regionAlias}
          token={token}
        />
      )}
    </Modal>
  );
};

export default AddressInfoModal; 
import { useShow, useNavigation, useMany, useExport, BaseKey } from "@refinedev/core";
import { 
    Show, 
    CreateButton,
    EditButton,
    DeleteButton,
    RefreshButton,
    ListButton,
    ShowButton
} from "@refinedev/antd";
import { 
    Typography, 
    Avatar, 
    Button, 
    Dropdown, 
    message, 
    Card, 
    Table, 
    Space, 
    Tag, 
    Tooltip, 
    Menu,
    Input,
    Descriptions,
    Badge,
    Divider
} from "antd";
import { 
    MoreOutlined, 
    LinkOutlined,
    HistoryOutlined,
    ExportOutlined,
    SyncOutlined,
    AlertOutlined,
    InfoCircleOutlined,
    SearchOutlined,
    DeleteOutlined,
    BarChartOutlined,
    StarFilled
} from "@ant-design/icons";
import { useState, useMemo } from "react";
import { CHAIN_MAPPING, ChainId } from "../../interfaces/chain.d";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { IEntity } from "../../interfaces/entity.d";
import { IAddress } from "../../interfaces/address";
import { ITag } from "../../interfaces/tag.d";
import { ILabel, IAddressLabel } from  "../../interfaces/label.d";

import dayjs from "dayjs";
import { TransactionTable } from 'components/transactions/table/TransactionTable';
import { TransactionGraph } from 'components/graph/TransactionGraph';
import { shortenAddress } from "utils/formatters";
import { ColumnType } from "antd/es/table";

const { Text } = Typography;

// Create options with logo URLs
const CHAIN_OPTIONS = Object.entries(CHAIN_MAPPING)
    .filter(([_, name]) => !name.includes('_testnet'))
    .map(([chainId, name]) => ({
        label: (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Avatar 
                    size="small"
                    src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                    style={{ backgroundColor: 'transparent' }}
                />
                {name.charAt(0).toUpperCase() + name.slice(1)}
            </div>
        ),
        value: chainId as ChainId
    }));

// Avatar components
const EntityAvatar = ({ name, type, address, size = 'default', style, src }: { 
    name?: string;
    type?: string;
    address?: string;
    size?: 'small' | 'default' | 'large';
    style?: React.CSSProperties;
    src?: string;
}) => {
    const getInitials = () => {
        if (name) return name[0].toUpperCase();
        if (address) return address.slice(2, 4);
        return '??';
    };

    return (
        <Tooltip title={`${type ? `${type} - ` : ''}${name || address}`}>
            <Avatar size={size} style={style} src={src}>
                {getInitials()}
            </Avatar>
        </Tooltip>
    );
};

const ChainAvatar = ({ chainId, size = 'small' }: { 
    chainId: string;
    size?: 'small' | 'default' | 'large';
}) => {
    const chain = CHAIN_OPTIONS.find((c: any) => c.value === chainId);
    return (
        <Avatar 
            src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`} 
            size={size}
            style={{ backgroundColor: '#f0f2f5' }}
        >
            {/* {chain?.label.substring(0, 2)} */}
        </Avatar>
    );
};

// Helper function to get URL parameters
const getUrlParam = (param: string) => {
    const lastPathSegment = window.location.pathname.split('/').pop() || '';
    
    // First try to parse as URL parameters
    try {
        const urlParams = new URLSearchParams(decodeURIComponent(lastPathSegment));
        const value = urlParams.get(param);
        if (value) return value;
    } catch (e) {
        // If parsing fails, it might be a direct path
    }

    // If param is 'id' and we couldn't parse URL params, return the last path segment
    if (param === 'id' && (lastPathSegment.startsWith('entity_') || lastPathSegment.startsWith('default_entity_'))) {
        return lastPathSegment;
    }

    return null;
};


export const EntityShow: React.FC = () => {
    const { queryResult } = useShow<IEntity>(
        {
            resource: "entities",
            id: getUrlParam('id') || getUrlParam('name') || undefined
        }
    );
    const { data, isLoading } = queryResult;
    const record = data?.data;

    // States for table
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [addressFilter, setAddressFilter] = useState('');
    const [sortedInfo, setSortedInfo] = useState<any>({});

    // Load/save column settings
    const [columnSettings, setColumnSettings] = useLocalStorage(
        `entityShow.${record?.id}.columnSettings`,
        {
            width: {},
            visible: {},
            order: {}
        }
    );

    // Use Refine's export hook
    const { triggerExport, isLoading: exportLoading } = useExport();

    // Fetch related entities data
    const { data: relatedEntitiesData } = useMany<IEntity>({
        resource: "entities",
        ids: record?.related_entity_ids || [],
        queryOptions: {
            enabled: record?.related_entity_ids !== undefined,
        },
    });

    // Render functions for different types of labels
    const renderLabels = (labels: IAddressLabel[]) => (
        <Space wrap size={[0, 4]} style={{ width: '100%' }}>
            {labels.map((label) => (
                <Tooltip
                    key={label.id}
                    title={
                        label.main_label_chains?.length ? 
                        `Main label for chains: ${label.main_label_chains.join(', ')}` : 
                        undefined
                    }
                    mouseEnterDelay={0.5}
                    placement="top"
                >
                    <Tag 
                        color={`#${label.color}`}
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 4 
                        }}
                    >
                        {label.source && (
                            <Avatar 
                                src={label.source} 
                                size="small"
                                style={{ flexShrink: 0 }}
                            >
                                {label.name?.substring(0, 2)}
                            </Avatar>
                        )}
                        {label.name}
                        {label.main_label_chains?.length || 0 > 0 && (
                            <StarFilled style={{ color: '#faad14' }} />
                        )}
                    </Tag>
                </Tooltip>
            ))}
        </Space>
    );

    const renderTags = (tags: ITag[]) => (
        <Space wrap size={[0, 4]} style={{ width: '100%' }}>
            {tags.map((tag) => (
                <Tag 
                    key={tag.id}
                    color={`#${tag.color}`}
                >
                    {tag.name}
                </Tag>
            ))}
        </Space>
    );
    
    // Table columns configuration
    const addressColumns = [
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: 300,
            filteredValue: addressFilter ? [addressFilter] : null,
            onFilter: (value: string, record: IAddress) => 
                record.address.toLowerCase().includes(value.toLowerCase()),
            render: (text: string, record: IAddress) => (
                <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <Space>
                        <EntityAvatar 
                            address={text}
                            size="small"
                        />
                        <Text copyable>{shortenAddress(text)}</Text>
                    </Space>
                    {record.labels && renderLabels(record.labels)}
                    {record.tags && renderTags(record.tags)}
                </Space>
            ),
        },
        {
            title: 'Chains',
            dataIndex: 'chain_ids',
            key: 'chain_ids',
            width: 200,
            filters: CHAIN_OPTIONS.map(chain => ({
                text: chain.label,
                value: chain.value,
            })),
            onFilter: (value: string, record: IAddress) => 
                record.chain_ids.includes(value),
            render: (chainIds: string[], record: IAddress) => (
                <Space wrap style={{ gap: 8 }}>
                    {chainIds.map((chainId, index) => {
                        const isMainChain = record.main_chain_ids?.includes(chainId);
                        return (
                            <Tooltip 
                                key={chainId} 
                                title={
                                    <>
                                        {record.chain_names?.[index] || record.friendly_names?.[index] || chainId}
                                        {isMainChain && (
                                            <div>
                                                <StarFilled style={{ color: '#faad14' }} /> Main entity on this chain
                                            </div>
                                        )}
                                    </>
                                }
                                mouseEnterDelay={0.5}
                                placement="top"
                            >
                                <Badge 
                                    dot={isMainChain}
                                    color="gold"
                                    offset={[-2, 0]}
                                >
                                    <ChainAvatar 
                                        chainId={chainId}
                                        size="small"
                                    />
                                </Badge>
                            </Tooltip>
                        );
                    })}
                </Space>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_: unknown, record: IAddress) => (
                <Space>
                    <Tooltip 
                        title="View in Explorer"
                        mouseEnterDelay={0.5}
                        placement="left"
                    >
                        <Button 
                            icon={<LinkOutlined />}
                            size="small"
                            onClick={() => window.open(`https://etherscan.io/address/${record.address}`)}
                        />
                    </Tooltip>
                    <ShowButton 
                        size="small"
                        recordItemId={record.address}
                        resource="addresses"
                    />
                </Space>
            ),
        },
    ];

    // More actions menu items
    const moreActions = [
        {
            key: 'export',
            label: 'Export Data',
            icon: <ExportOutlined />,
            onClick: () => {
                triggerExport();
            }
        }
    ];

    const [addressSearch, setAddressSearch] = useState("");

    // Memoize filtered addresses for better performance
    const filteredAddresses = useMemo(() => {
        if (!addressSearch.trim()) return record?.addresses;
        
        return record?.addresses?.filter(addr => 
            addr.address.toLowerCase().includes(addressSearch.toLowerCase()) ||
            addr.chain_names?.some((name: string) => 
                name.toLowerCase().includes(addressSearch.toLowerCase())
            )
        );
    }, [record?.addresses, addressSearch]);
    
    return (
        <Show
            isLoading={isLoading}
            headerButtons={({ refreshButtonProps }) => (
                <Space>
                    <EntityAvatar 
                        name={record?.name}
                        type={record?.type}
                        size="large"
                        style={{
                            backgroundColor: record?.color ? `#${record.color}` : undefined
                        }}
                        src={record?.logo}
                    />
                    <EditButton
                        recordItemId={record?.id}
                    />
                    <RefreshButton {...refreshButtonProps} />
                    <ListButton />
                    <DeleteButton />                    
                    <Dropdown menu={{ items: moreActions }}>
                        <Button icon={<MoreOutlined />}>More</Button>
                    </Dropdown>
                </Space>
            )}
        >
            <Card
                style={{
                    borderTop: record?.color ? `3px solid #${record.color}` : undefined
                }}
            >
                {/* Entity Details */}
                <Descriptions 
                    title={
                        <Space size="middle" align="center">
                            <Typography.Title level={4} style={{ margin: 0 }}>
                                {record?.name}
                            </Typography.Title>
                            <Space size="small">
                                {record?.type && (
                                    <Tag color="blue" style={{ margin: 0 }}>
                                        {record.type.toUpperCase()}
                                    </Tag>
                                )}
                                <Tag 
                                    color={record?.status === 'active' ? 'success' : 'error'}
                                    style={{ margin: 0 }}
                                >
                                    {record?.status?.toUpperCase()}
                                </Tag>
                                {record?.color && (
                                    <Tooltip title="Entity color">
                                        <div 
                                            style={{ 
                                                width: 20, 
                                                height: 20, 
                                                borderRadius: '50%', 
                                                backgroundColor: `#${record.color}`,
                                                display: 'inline-block',
                                                margin: 0
                                            }} 
                                        />
                                    </Tooltip>
                                )}
                            </Space>
                        </Space>
                    } 
                    bordered 
                    column={2}
                >
                    <Descriptions.Item label="Website">
                        {record?.website ? (
                            <a href={record.website} target="_blank" rel="noopener noreferrer">
                                {record.website}
                            </a>
                        ) : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Twitter">
                        {record?.twitter ? (
                            <a href={`https://twitter.com/${record.twitter}`} target="_blank" rel="noopener noreferrer">
                                @{record.twitter}
                            </a>
                        ) : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Risk Score">
                        <Tag color={
                            record?.risk_score && record.risk_score > 7 ? 'red' :
                            record?.risk_score && record.risk_score > 4 ? 'orange' : 'green'
                        }>
                            {record?.risk_score || 'N/A'}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Compliance Status">
                        <Tag color={record?.compliance_status === 'compliant' ? 'green' : 'red'}>
                            {record?.compliance_status || 'Unknown'}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Created At">
                        {record?.created_at ? 
                            dayjs.unix(Number(record.created_at)).format('YYYY-MM-DD HH:mm:ss') 
                            : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Last Activity">
                        {record?.last_activity ? 
                            dayjs.unix(Number(record.last_activity)).format('YYYY-MM-DD HH:mm:ss') 
                            : '-'}
                    </Descriptions.Item>
                    {record?.description && (
                        <Descriptions.Item label="Description" span={2}>
                            {record.description}
                        </Descriptions.Item>
                    )}
                    
                    {/* Add main labels section */}
                    {record?.main_labels && Object.keys(record.main_labels).length > 0 && (
                        <Descriptions.Item label="Main Labels" span={2}>
                            <Space wrap>
                                {Object.entries(record.main_labels).map(([chainId, label]) => (
                                    <Tooltip 
                                        key={chainId}
                                        title={`Main label on ${CHAIN_MAPPING[chainId] || chainId}`}
                                    >
                                        <Tag 
                                            color={`#${label.color || '1890ff'}`}
                                            style={{ 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                gap: 4 
                                            }}
                                        >
                                            <Space>
                                                <ChainAvatar chainId={chainId} size="small" />
                                                {label.name}
                                                <StarFilled style={{ color: '#faad14' }} />
                                            </Space>
                                        </Tag>
                                    </Tooltip>
                                ))}
                            </Space>
                        </Descriptions.Item>
                    )}
                    
                    {/* Add chains section */}
                    {record?.chain_ids && record.chain_ids.length > 0 && (
                        <Descriptions.Item label="Active Chains" span={2}>
                            <Space wrap>
                                {record.chain_ids.map((chainId) => {
                                    const isMainChain = record.main_chain_ids?.includes(chainId);
                                    return (
                                        <Tooltip 
                                            key={chainId}
                                            title={`${CHAIN_MAPPING[chainId] || chainId}${isMainChain ? ' (Main)' : ''}`}
                                        >
                                            <Badge 
                                                dot={isMainChain}
                                                color="gold"
                                                offset={[-2, 2]}
                                            >
                                                <Tag style={{ padding: '4px 8px' }}>
                                                    <Space>
                                                        <ChainAvatar chainId={chainId} />
                                                        {CHAIN_MAPPING[chainId] || chainId}
                                                    </Space>
                                                </Tag>
                                            </Badge>
                                        </Tooltip>
                                    );
                                })}
                            </Space>
                        </Descriptions.Item>
                    )}
                </Descriptions>

                {/* Metadata Section */}
                {record?.metadata && Object.keys(record.metadata).length > 0 && (
                    <Card title="Additional Information" style={{ marginTop: 16 }}>
                        <Descriptions bordered column={1}>
                            {Object.entries(record.metadata).map(([key, value]) => (
                                <Descriptions.Item key={key} label={key}>
                                    {typeof value === 'object' ? JSON.stringify(value) : String(value)}
                                </Descriptions.Item>
                            ))}
                        </Descriptions>
                    </Card>
                )}

                {/* Addresses Table */}
                <Card 
                    title={
                        <Space size="middle">
                            <Typography.Text strong>Associated Addresses</Typography.Text>
                            <Typography.Text type="secondary">
                                ({record?.addresses?.length || 0} total)
                            </Typography.Text>
                        </Space>
                    }
                    style={{ marginTop: 16 }}
                    extra={
                        <Space>
                            <Input.Search
                                placeholder="Search by address or chain"
                                allowClear
                                onChange={(e) => setAddressSearch(e.target.value)}
                                style={{ width: 300 }}
                                onSearch={(value) => setAddressSearch(value)}
                            />
                        </Space>
                    }
                >
                    <Table<IAddress>
                        dataSource={filteredAddresses}
                        columns={addressColumns as ColumnType<IAddress>[]}
                        rowKey="address"
                        pagination={{
                            showSizeChanger: true,
                            showTotal: (total) => `${total} ${total === 1 ? 'address' : 'addresses'}`,
                            pageSize: 10,
                            pageSizeOptions: ['10', '20', '50', '100']
                        }}
                        loading={isLoading}
                    />
                </Card>
            </Card>

            {relatedEntitiesData?.data && relatedEntitiesData.data.length > 0 && (
                <Card title="Related Entities" style={{ marginTop: 16 }}>
                    <Table
                        dataSource={relatedEntitiesData.data}
                        rowKey="id"
                        columns={[
                            {
                                title: 'Entity',
                                dataIndex: 'name',
                                render: (name: string, record: IEntity) => (
                                    <Space>
                                        <EntityAvatar 
                                            name={name}
                                            type={record.type}
                                        />
                                        {name}
                                    </Space>
                                ),
                            },
                            {
                                title: 'Actions',
                                dataIndex: 'actions',
                                render: (_, record: IEntity) => (
                                    <Space>
                                        <ShowButton 
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                        />
                                        <EditButton 
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                        />
                                    </Space>
                                ),
                            },
                        ]}
                    />
                </Card>
            )}

            {/* Transactions Card */}
            <Card 
                title={
                    <Space size="middle">
                        <Typography.Text strong>Transactions</Typography.Text>
                        <Tooltip title="Transactions from all associated addresses">
                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,0.45)' }} />
                        </Tooltip>
                    </Space>
                }
                style={{ marginTop: 16 }}
            >
                <TransactionTable
                    identifier={record?.id || ''}
                    transactions={[]}
                    initialPaginationCursors={{
                        moralis: {},
                        database: {},
                        status: {}
                    }}
                    setTransactions={() => {}}
                    address_items_map={record?.address_items_map || {}}
                    setGraphKey={() => {}}
                    tableId="show-entity"
                    searchAddress={record?.addresses?.[0]?.address || ''} 
                    onTransactionDataChange={() => {}}
                    activeChains={record?.addresses?.flatMap(addr => addr.chain_ids) || []}
                    selectedChains={record?.addresses?.flatMap(addr => addr.chain_ids) || []}
                />
            </Card>    

            {/* Transaction Graph */}
            <Card 
                title={
                    <Space direction="vertical" size="small">
                        <Typography.Title level={4} style={{ margin: 0 }}>Transaction Analytics Graph</Typography.Title>
                        <Typography.Text type="secondary">
                            This graph visualizes the transaction data from the table above. Use the table filters to refine the displayed connections.
                        </Typography.Text>
                        <Space>
                            <InfoCircleOutlined style={{ color: '#1890ff' }} />
                            <Typography.Text type="secondary">
                                Tip: Drag nodes to rearrange, use mouse wheel to zoom, and click the chain buttons above to focus on specific networks
                            </Typography.Text>
                        </Space>
                    </Space>
                }
                style={{ 
                    marginTop: 16,
                    width: '100%'
                }}
            >
                <TransactionGraph 
                    data={[]}
                    address_items_map={record?.address_items_map || {}}
                    filteredData={[]}  // Use filteredTransactions instead
                    chainIdToName={CHAIN_MAPPING}
                    cdn_domain_name={process.env.REACT_APP_CDN_URL || ''}
                    graphKey={0}
                    setGraphKey={() => {}}
                />
            </Card>     
        </Show>
    );
};
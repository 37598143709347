import { Card, List, Space, Avatar, Tag, Collapse, Statistic, Row, Col, Typography, Tooltip, Select, Empty, Skeleton, Divider, Button, Drawer } from 'antd';
import { useList, useOne } from '@refinedev/core';
import { DollarOutlined, PercentageOutlined, CheckCircleOutlined, CloseCircleOutlined, ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { IDeFiProtocol, IDeFiPosition, IDeFiToken, IToken, IAddress } from "interfaces/address.d";
import { useEffect, useState } from 'react';
import { DeFiProtocolDetail } from './DeFiProtocolDetail';
import { TokenApprovalModal } from './TokenApprovalModal';
import { CHAIN_MAPPING } from "../../../interfaces/chain.d";
import { ChainAvatar } from './StatsTab';
import { shortenAddress } from '../../../utils/formatters';
const { Text, Link } = Typography;
const { Panel } = Collapse;

interface DeFiTabProps {
    record: Pick<IAddress, 'address' | 'active_chain_ids'>;
    isEmbedded?: boolean;
}

// Update the response type to match the API structure
interface DeFiResponse {
    request_id: string;
    results: {
        [chainId: string]: {
            data: IDeFiProtocol[];
            cursor: string | null;
            has_more: boolean;
        };
    };
    errors: Record<string, any>;
}

export const DeFiTab: React.FC<DeFiTabProps> = ({ record, isEmbedded = false }) => {
    const availableChains = record?.active_chain_ids || [];
    
    // Add a constant for the "ALL" option value
    const ALL_CHAINS = 'all';

    // Update the initial state to use ALL_CHAINS
    const [selectedChainId, setSelectedChainId] = useState<string>(ALL_CHAINS);

    const { data, isLoading, refetch } = useOne<DeFiResponse>({
        resource: "defi",
        id: record?.address,
        meta: {
            address: record?.address,
            chain_ids: selectedChainId ? [selectedChainId] : availableChains,
            limit: 1,
            operation: "getDefiPositionsSummary",
        },
        queryOptions: {
            enabled: availableChains.length > 0,
            refetchOnWindowFocus: false,
        }
    });

    useEffect(() => {
        // It will loop if embedded is true
        if (!isEmbedded) {
            refetch();
        }
    }, [availableChains]);

    const allProtocols = Object.values(data?.data?.results || {}).flatMap((chainData) => 
        chainData.data || []
    );

    // Update the total value calculation since position is a single object, not an array
    const totalValue = allProtocols?.reduce((sum, protocol) => 
        sum + (protocol.position?.balance_usd || 0), 0) || 0;

    if (availableChains.length === 0) {
        return (
            <Card>
                <Empty
                    description="No chains available for this address"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            </Card>
        );
    }

    const protocols = allProtocols || [];

    const [selectedProtocol, setSelectedProtocol] = useState<IDeFiProtocol | undefined>();
    const [selectedToken, setSelectedToken] = useState<IDeFiToken | null>(null);
    const [selectedProtocols, setSelectedProtocols] = useState<string[]>([]);

    // Get unique protocols for the filter
    const uniqueProtocols = Array.from(new Set(protocols.map(p => p.protocol_id)));

    // Add sort state
    type SortOption = 'tvl_desc' | 'tvl_asc' | 'apy_desc' | 'apy_asc' | 'name_asc';
    const [sortOption, setSortOption] = useState<SortOption>('tvl_desc');

    // Sort protocols based on selected option
    const sortProtocols = (protocols: IDeFiProtocol[]) => {
        return [...protocols].sort((a, b) => {
            switch (sortOption) {
                case 'tvl_desc':
                    return (b.position?.balance_usd || 0) - (a.position?.balance_usd || 0);
                case 'tvl_asc':
                    return (a.position?.balance_usd || 0) - (b.position?.balance_usd || 0);
                case 'apy_desc':
                    return (b.position?.position_details?.apy || 0) - (a.position?.position_details?.apy || 0);
                case 'apy_asc':
                    return (a.position?.position_details?.apy || 0) - (b.position?.position_details?.apy || 0);
                case 'name_asc':
                    return (a.protocol_name || '').localeCompare(b.protocol_name || '');
                default:
                    return 0;
            }
        });
    };

    // First, filter protocols based on selected chain and protocols
    const filteredProtocols = sortProtocols(
        protocols.filter(protocol => {
            const chainMatch = selectedChainId === ALL_CHAINS || protocol.chain_id === selectedChainId;
            const protocolMatch = selectedProtocols.length === 0 || selectedProtocols.includes(protocol.protocol_id);
            return chainMatch && protocolMatch;
        })
    );

    // Then, group filtered protocols by chain
    const filteredProtocolsByChain = filteredProtocols.reduce((acc, protocol) => {
        const chainId = protocol.chain_id;
        if (!acc[chainId]) {
            acc[chainId] = [];
        }
        acc[chainId].push(protocol);
        return acc;
    }, {} as Record<string, IDeFiProtocol[]>);

    // Calculate TVL for filtered protocols
    const filteredTvlByChain = filteredProtocols.reduce((acc, protocol) => {
        const chainId = protocol.chain_id;
        if (!acc[chainId]) {
            acc[chainId] = 0;
        }
        acc[chainId] += protocol.position?.balance_usd || 0;
        return acc;
    }, {} as Record<string, number>);

    // Calculate total TVL from filtered protocols
    const filteredTotalValue = filteredProtocols.reduce((sum, protocol) => 
        sum + (protocol.position?.balance_usd || 0), 0);

    const renderPositionDetails = (details: any) => (
        <List
            size="small"
            dataSource={Object.entries(details).filter(([_, value]) => value !== undefined)}
            renderItem={([key, value]) => {
                // Handle boolean values including is_enabled_as_collateral
                if (typeof value === 'boolean' || 
                    key === 'is_in_range' || 
                    key === 'is_debt' || 
                    key === 'is_variable_debt' || 
                    key === 'is_stable_debt' ||
                    key === 'is_enabled_as_collateral') {
                    return (
                        <List.Item>
                            <Space>
                                <Text type="secondary">{key.replace(/_/g, ' ').toUpperCase()}:</Text>
                                {value ? 
                                    <CheckCircleOutlined style={{ color: '#52c41a' }} /> : 
                                    <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
                                }
                            </Space>
                        </List.Item>
                    );
                }
                // Handle projected earnings object
                if (key === 'projected_earnings_usd' && typeof value === 'object') {
                    return (
                        <>
                            <List.Item>
                                <Text type="secondary">PROJECTED EARNINGS:</Text>
                            </List.Item>
                            {Object.entries(value || {}).map(([period, amount]) => (
                                <List.Item key={period} style={{ paddingLeft: 24 }}>
                                    <Space>
                                        <Text type="secondary">{period.toUpperCase()}:</Text>
                                        <Text>${(amount || 0).toLocaleString()}</Text>
                                    </Space>
                                </List.Item>
                            ))}
                        </>
                    );
                }
                return (
                    <List.Item>
                        <Space>
                            <Text type="secondary">{key.replace(/_/g, ' ').toUpperCase()}:</Text>
                            <Text>
                                {typeof value === 'number' ? (
                                    key.includes('price') || key.includes('value') ? 
                                        `$${value.toLocaleString()}` :
                                    key.includes('apy') || key.includes('share') ? 
                                        `${value.toLocaleString()}%` :
                                        value.toLocaleString()
                                ) : (
                                    typeof value === 'string' ? value : 'Invalid Value'
                                )}
                            </Text>
                        </Space>
                    </List.Item>
                );
            }}
        />
    );

    return (
        <Space 
            direction="vertical" 
            style={{ 
                width: '100%',
                ...(isEmbedded && {
                    height: 'calc(80vh - 180px)', // Match container height
                    minHeight: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '0 24px'
                })
            }} 
            size="large"
        >
            {isEmbedded && (
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Space align="center">
                        <Button 
                            type="link" 
                            icon={<ArrowLeftOutlined/>}
                            onClick={() => {
                                const overviewTab = document.querySelector('[data-node-key="overview"]');
                                if (overviewTab) {
                                    (overviewTab as HTMLElement).click();
                                }
                            }}
                        >
                            Back to Graph View
                        </Button>
                        <Divider type="vertical" />
                        <Tag color="warning">
                            <Space>
                                <Text type="secondary">Viewing DeFi positions for</Text>
                                <Text strong copyable={{ text: record?.address }}>
                                    <Tooltip title={record?.address}>
                                        {shortenAddress(record?.address)}
                                    </Tooltip>
                                </Text>
                                <Text type="secondary">on</Text>
                                <Space>
                                    <ChainAvatar chainId={availableChains?.[0]} size="small" />
                                    <Text>{CHAIN_MAPPING[availableChains?.[0]] || availableChains?.[0]}</Text>
                                </Space>
                            </Space>
                        </Tag>
                    </Space>
                    <Button 
                        type="text"
                        icon={<CloseOutlined/>}
                        onClick={() => {
                            const overviewTab = document.querySelector('[data-node-key="overview"]');
                            if (overviewTab) {
                                (overviewTab as HTMLElement).click();
                            }
                        }}
                    />
                </Space>
            )}

            <Card style={{
                ...(isEmbedded && {
                    height: 'calc(100% - 60px)', // Account for header
                    overflow: 'auto'
                })
            }}>
                <Space wrap>
                    <Select 
                        value={selectedChainId}
                        onChange={setSelectedChainId}
                        style={{ width: 250 }}
                    >
                        <Select.OptGroup label="All Active Chains">
                            <Select.Option key={ALL_CHAINS} value={ALL_CHAINS}>
                                <Space>
                                    <Avatar.Group 
                                        maxCount={3} 
                                        size="small"
                                        maxStyle={{ 
                                            color: '#f56a00', 
                                            backgroundColor: '#fde3cf',
                                        }}
                                    >
                                        {availableChains.map((chainId: string) => (
                                            <Tooltip 
                                                key={chainId} 
                                                title={CHAIN_MAPPING[chainId] || chainId}
                                                overlayStyle={{ zIndex: 1100 }}
                                            >
                                                <ChainAvatar chainId={chainId} size="small" />
                                            </Tooltip>
                                        ))}
                                    </Avatar.Group>
                                    <Text>All Active Chains</Text>
                                </Space>
                            </Select.Option>
                        </Select.OptGroup>

                        <Select.OptGroup label="Individual Chains">
                            {availableChains.map((chainId: string) => (
                                <Select.Option key={chainId} value={chainId}>
                                    <Space>
                                        <Tooltip 
                                            title={CHAIN_MAPPING[chainId] || chainId}
                                            overlayStyle={{ zIndex: 1100 }}
                                        >
                                            <ChainAvatar chainId={chainId} size="small" />
                                        </Tooltip>
                                        {CHAIN_MAPPING[chainId] || chainId}
                                    </Space>
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                    </Select>

                    <Select
                        mode="multiple"
                        placeholder="Filter by Protocol"
                        value={selectedProtocols}
                        onChange={setSelectedProtocols}
                        style={{ width: 300 }}
                        allowClear
                        maxTagCount={2}
                    >
                        {uniqueProtocols.map(protocolId => {
                            const protocol = protocols.find(p => p.protocol_id === protocolId);
                            return (
                                <Select.Option key={protocolId} value={protocolId}>
                                    <Space>
                                        <Avatar 
                                            src={protocol?.protocol_logo} 
                                            size="small" 
                                        />
                                        {protocol?.protocol_name}
                                    </Space>
                                </Select.Option>
                            );
                        })}
                    </Select>

                    <Select
                        value={sortOption}
                        onChange={setSortOption}
                        style={{ width: 200 }}
                    >
                        <Select.Option value="tvl_desc">TVL (High to Low)</Select.Option>
                        <Select.Option value="tvl_asc">TVL (Low to High)</Select.Option>
                        <Select.Option value="apy_desc">APY (High to Low)</Select.Option>
                        <Select.Option value="apy_asc">APY (Low to High)</Select.Option>
                        <Select.Option value="name_asc">Protocol Name (A-Z)</Select.Option>
                    </Select>

                    <Text type="secondary">Select chain and protocols to filter positions</Text>
                </Space>
            </Card>

            {isLoading ? (
                <>
                    {/* Skeleton for stats */}
                    <Row gutter={16}>
                        <Col span={12}>
                            <Card>
                                <Skeleton.Input style={{ width: 200 }} active />
                                <Skeleton.Input style={{ width: 100 }} active />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card>
                                <Skeleton.Input style={{ width: 200 }} active />
                                <Skeleton.Avatar active size="small" shape="circle" />
                                <Skeleton.Avatar active size="small" shape="circle" />
                                <Skeleton.Avatar active size="small" shape="circle" />
                            </Card>
                        </Col>
                    </Row>

                    {/* Skeleton for protocol cards */}
                    {[1, 2].map((key) => (
                        <Card key={key}>
                            <Skeleton active avatar paragraph={{ rows: 2 }} />
                            <Divider />
                            <Row gutter={[16, 16]}>
                                <Col span={6}>
                                    <Skeleton.Input style={{ width: '100%' }} active />
                                </Col>
                                <Col span={6}>
                                    <Skeleton.Input style={{ width: '100%' }} active />
                                </Col>
                                <Col span={6}>
                                    <Skeleton.Input style={{ width: '100%' }} active />
                                </Col>
                                <Col span={6}>
                                    <Skeleton.Input style={{ width: '100%' }} active />
                                </Col>
                            </Row>
                            <Skeleton.Input style={{ width: '100%', marginTop: 16 }} active />
                        </Card>
                    ))}
                </>
            ) : (
                <>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Card>
                                <Statistic
                                    title="Total Value Locked"
                                    value={filteredTotalValue < 0.01 ? "< 0.01" : filteredTotalValue}
                                    precision={2}
                                    prefix={<DollarOutlined/>}
                                    suffix="USD"
                                    formatter={(value) => {
                                        if (typeof value === 'number') {
                                            return value.toLocaleString();
                                        }
                                        return value;
                                    }}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card>
                                <Space direction="vertical" align="center" style={{ width: '100%' }}>
                                    <Text strong style={{ fontSize: 16 }}>
                                        Active Protocols ({filteredProtocols.length})
                                    </Text>
                                    <Avatar.Group
                                        maxCount={5}
                                        size="large"
                                        maxStyle={{
                                            color: '#f56a00',
                                            backgroundColor: '#fde3cf',
                                        }}
                                    >
                                        {filteredProtocols.map((protocol) => (
                                            <Tooltip 
                                                key={protocol.protocol_id} 
                                                title={protocol.protocol_name}
                                                overlayStyle={{ zIndex: 1100 }}
                                            >
                                                <Avatar src={protocol.protocol_logo} />
                                            </Tooltip>
                                        ))}
                                    </Avatar.Group>
                                </Space>
                            </Card>
                        </Col>
                    </Row>

                    {/* Replace protocolsByChain with filteredProtocolsByChain */}
                    {Object.entries(filteredProtocolsByChain).map(([chainId, chainProtocols]) => (
                        <Card 
                            key={chainId}
                            title={
                                <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                                    <Col>
                                        <Space>
                                            <ChainAvatar chainId={chainId} size="small" />
                                            <Text strong>{CHAIN_MAPPING[chainId] || chainId}</Text>
                                            <Tag color="blue">
                                                {chainProtocols.length} Protocol{chainProtocols.length !== 1 ? 's' : ''}
                                            </Tag>
                                        </Space>
                                    </Col>
                                    <Col>
                                        <Space>
                                            <Text type="secondary">TVL:</Text>
                                            <Text strong>
                                                {filteredTvlByChain[chainId] < 0.01 
                                                    ? "< $0.01" 
                                                    : `$${filteredTvlByChain[chainId].toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}`
                                                }
                                            </Text>
                                        </Space>
                                    </Col>
                                </Row>
                            }
                        >
                            <List
                                loading={isLoading}
                                dataSource={chainProtocols}
                                renderItem={(protocol) => (
                                    <Card 
                                        title={
                                            <Space>
                                                <Avatar src={protocol.protocol_logo} />
                                                <Text strong>{protocol.protocol_name}</Text>
                                                <Link href={protocol.protocol_url} target="_blank">
                                                    Visit Protocol
                                                </Link>
                                            </Space>
                                        }
                                        style={{ marginBottom: 16, cursor: 'pointer' }}
                                        onClick={() => setSelectedProtocol(protocol)}
                                    >
                                        <Collapse>
                                            {protocol.position && (
                                                <Panel 
                                                    key={protocol.position.label}
                                                    header={
                                                        <Space>
                                                            <Text strong>{protocol.position.label?.toUpperCase()}</Text>
                                                            <Tag color="green">
                                                                ${protocol.position.balance_usd?.toLocaleString()}
                                                            </Tag>
                                                        </Space>
                                                    }
                                                >
                                                    <Space direction="vertical" style={{ width: '100%' }}>
                                                        <Card title="Tokens" size="small">
                                                            <List
                                                                dataSource={protocol.position.tokens || []}
                                                                renderItem={(token: IDeFiToken) => (
                                                                    <List.Item>
                                                                        <Space>
                                                                            <Avatar size="small" src={token.logo} />
                                                                            <Text>{token.name}</Text>
                                                                            <Tag color="blue">{token.symbol}</Tag>
                                                                            {token.contract_address && (
                                                                                <Link href={`https://etherscan.io/address/${token.contract_address}`} target="_blank">
                                                                                    {shortenAddress(token.contract_address)}
                                                                                </Link>
                                                                            )}
                                                                            <Text>Balance: {
                                                                                Number(token.balance_formatted) < 0.000001 
                                                                                    ? "< 0.000001"
                                                                                    : Number(token.balance_formatted).toLocaleString(undefined, {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 6
                                                                                    })
                                                                            }</Text>
                                                                            <Text type="secondary">
                                                                                {token.usd_value && token.usd_value > 0.01
                                                                                    ? `($${token.usd_value.toLocaleString(undefined, {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })})`
                                                                                    : '(< $0.01)'
                                                                                }
                                                                            </Text>
                                                                        </Space>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        </Card>

                                                        {protocol.position.position_details && (
                                                            <Card title="Position Details" size="small">
                                                                {renderPositionDetails(protocol.position.position_details)}
                                                            </Card>
                                                        )}
                                                    </Space>
                                                </Panel>
                                            )}
                                        </Collapse>
                                    </Card>
                                )}
                            />
                        </Card>
                    ))}
                </>
            )}

            <DeFiProtocolDetail
                address={record?.address}
                protocol={selectedProtocol}
                visible={!!selectedProtocol}
                onClose={() => setSelectedProtocol(undefined)}
            />

            {selectedToken && selectedChainId && (
                <TokenApprovalModal
                    visible={!!selectedToken}
                    onClose={() => setSelectedToken(null)}
                    token={selectedToken}
                    walletAddress={record?.address}
                    chainId={selectedChainId}
                />
            )}
        </Space>
    );
}; 
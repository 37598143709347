import React, { useState } from 'react';
import { Button, Checkbox, Divider, Space, Avatar, Tooltip, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { Transaction } from '../types';

interface FilterOption {
    text: React.ReactNode;
    value: string;
    icon?: string;
}

interface AddressFilterDropdownProps extends FilterDropdownProps {
    transactions: Transaction[];
    addressField: string;
    labelField: string;
    entityField: string;
    logoField: string;
    visibleDataIndices?: number[];
    formatDisplayText?: (value: string) => string;
    searchFullText?: boolean;
}

export const AddressFilterDropdown: React.FC<AddressFilterDropdownProps> = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    transactions,
    addressField,
    labelField,
    entityField,
    logoField,
    visibleDataIndices,
    formatDisplayText,
    searchFullText,
}) => {
    const [searchText, setSearchText] = useState('');

    // Generate unique filter options from visible transactions only
    const filterOptions: FilterOption[] = React.useMemo(() => {
        const uniqueOptions = new Map<string, FilterOption>();

        transactions
            // Filter to only visible transactions if visibleDataIndices is provided
            .filter((_, index) => !visibleDataIndices || visibleDataIndices.includes(index))
            .forEach((tx) => {
                const address = tx[addressField];
                const label = tx[labelField];
                const entity = tx[entityField];
                const logo = tx[logoField];

                // Format the display text using the provided formatter or default format
                const displayText = formatDisplayText ? 
                    formatDisplayText(address) : 
                    label || entity || `${address?.slice(0, 6)}...${address?.slice(-6)}`;

                if (!uniqueOptions.has(address)) {
                    uniqueOptions.set(address, {
                        text: displayText,  // Use formatted display text
                        value: address,     // Keep full value for filtering
                        icon: logo
                    });
                }
            });

        return Array.from(uniqueOptions.values());
    }, [transactions, addressField, labelField, entityField, logoField, visibleDataIndices, formatDisplayText]);

    const filteredOptions = filterOptions.filter(option => {
        const searchValue = searchText.toLowerCase();
        if (searchFullText) {
            // Search in the full value
            return option.value.toLowerCase().includes(searchValue) ||
                   (option.text?.toString()?.toLowerCase() || '').includes(searchValue);
        }
        // Regular search in displayed text
        return (option.text?.toString()?.toLowerCase() || '').includes(searchValue);
    });

    const handleSelectAll = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setSelectedKeys(filteredOptions.map(option => option.value));
        } else {
            setSelectedKeys([]);
        }
    };

    return (
        <div style={{ padding: 8 }}>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Input
                    placeholder="Search addresses"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    style={{ marginBottom: 8 }}
                    prefix={<SearchOutlined />}
                />
                
                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                    <Checkbox
                        indeterminate={selectedKeys.length > 0 && selectedKeys.length < filteredOptions.length}
                        checked={selectedKeys.length === filteredOptions.length}
                        onChange={handleSelectAll}
                        style={{ marginBottom: 8 }}
                    >
                        Select All
                    </Checkbox>
                    <Divider style={{ margin: '8px 0' }} />
                    
                    {filteredOptions.map((option, index) => (
                        <div key={`${option.value}-${index}`} style={{ marginBottom: 8 }}>
                            <Checkbox
                                checked={selectedKeys.includes(option.value)}
                                onChange={(e: CheckboxChangeEvent) => {
                                    const newSelectedKeys = e.target.checked
                                        ? [...selectedKeys, option.value]
                                        : selectedKeys.filter(key => key !== option.value);
                                    setSelectedKeys(newSelectedKeys);
                                }}
                            >
                                <Space>
                                    {option.icon && (
                                        <Tooltip title={option.text}>
                                            <Avatar size="small" src={option.icon} />
                                        </Tooltip>
                                    )}
                                    <span>{option.text}</span>
                                </Space>
                            </Checkbox>
                        </div>
                    ))}
                </div>

                <Divider style={{ margin: '8px 0' }} />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        size="small"
                    >
                        Apply
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters?.();
                            setSearchText('');
                            confirm();
                        }}
                        size="small"
                    >
                        Reset
                    </Button>
                </Space>
            </Space>
        </div>
    );
}; 
import { CHAIN_MAPPING } from "../interfaces/chain.d";

// Map of chain IDs to explorer base URLs
const EXPLORER_BASE_URLS: Record<string, string> = {
  // Mainnets
  "0x1": "https://etherscan.io",
  "0x89": "https://polygonscan.com",
  "0x38": "https://bscscan.com",
  "0xa86a": "https://snowtrace.io",
  "0xfa": "https://ftmscan.com",
  "0x19": "https://cronoscan.com",
  "0x2a15c308d": "https://explorer.palm.io",
  "0xa4b1": "https://arbiscan.io",
  "0x64": "https://gnosisscan.io",
  "0x15b38": "https://chiliscan.com",
  "0x2105": "https://basescan.org",
  "0xa": "https://optimistic.etherscan.io",
  "0x2019": "https://scope.klaytn.com",
  "0xa4ec": "https://celoscan.io",
  "0x8ae": "https://explorer.kava.io",
  "0x440": "https://andromeda-explorer.metis.io",
  "0x63564c40": "https://explorer.harmony.one",
  "0x2b6653dc": "https://explorer.zksync.io",
  "0x171": "https://aurorascan.dev",
  "0x1388": "https://polygonscan.com",
  "0x505": "https://moonriver.moonscan.io",
  "0x504": "https://moonbeam.moonscan.io",
  "0x144": "https://explorer.zksync.io",
  "0x118": "https://explorer.fuse.io",
  "0x405": "https://lineascan.build",
  "0x44d": "https://scrollscan.com",
  "0xa0c71fd": "https://explorer.mantle.xyz",
  
  // Testnets
  "0x5": "https://goerli.etherscan.io",
  "0xaa36a7": "https://sepolia.etherscan.io",
  "0x13881": "https://mumbai.polygonscan.com",
  "0x61": "https://testnet.bscscan.com",
  "0x27d8": "https://gnosis-chiado.blockscout.com",
  "0x15b32": "https://spicy-explorer.chiliz.com",
  "0x14a33": "https://goerli.basescan.org",
  "0x1a4": "https://goerli-optimism.etherscan.io",
  "0x82750": "https://baobab.scope.klaytn.com",
  "0xaef3": "https://alfajores.celoscan.io",
  "0x8ad": "https://explorer.testnet.kava.io",
  "0x24c": "https://goerli.explorer.metisdevops.link",
  "0x6357d2e0": "https://explorer.testnet.harmony.one",
  "0x66eee": "https://testnet.aurorascan.dev",
  "0x507": "https://moonbase.moonscan.io",
  "0x145": "https://goerli.explorer.zksync.io",
  "0x7a": "https://explorer.fusespark.io"
};

// Get transaction explorer link
export const getExplorerTransactionLink = (chainId: string, transactionHash: string): string | null => {
  const baseUrl = EXPLORER_BASE_URLS[chainId];
  if (!baseUrl) return null;
  return `${baseUrl}/tx/${transactionHash}`;
};

// Get address explorer link
export const getExplorerAddressLink = (chainId: string, address: string): string | null => {
  const baseUrl = EXPLORER_BASE_URLS[chainId];
  if (!baseUrl) return null;
  return `${baseUrl}/address/${address}`;
};

// Get token explorer link
export const getExplorerTokenLink = (chainId: string, tokenAddress: string): string | null => {
  const baseUrl = EXPLORER_BASE_URLS[chainId];
  if (!baseUrl) return null;
  return `${baseUrl}/token/${tokenAddress}`;
};

// Get block explorer link
export const getExplorerBlockLink = (chainId: string, blockNumber: number | string): string | null => {
  const baseUrl = EXPLORER_BASE_URLS[chainId];
  if (!baseUrl) return null;
  return `${baseUrl}/block/${blockNumber}`;
};

// Get explorer name
export const getExplorerName = (chainId: string): string => {
  const chainName = CHAIN_MAPPING[chainId];
  if (!chainName) return "Block Explorer";
  
  if (chainId === "0x1" || chainId === "0x5" || chainId === "0xaa36a7") return "Etherscan";
  if (chainId === "0x89" || chainId === "0x13881") return "Polygonscan";
  if (chainId === "0x38" || chainId === "0x61") return "BscScan";
  if (chainId === "0xa86a") return "Snowtrace";
  if (chainId === "0xfa") return "FTMScan";
  if (chainId === "0xa4b1") return "Arbiscan";
  if (chainId === "0xa") return "Optimism Explorer";
  
  // Default to generic name
  return `${chainName} Explorer`;
};
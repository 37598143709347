import { Card, Space, Select, List, Avatar, Tag, Typography, Row, Col, Button, Empty, Tooltip, Divider } from 'antd';
import { useList, useOne } from '@refinedev/core';
import { useEffect, useState } from 'react';
import { ChainAvatar } from './StatsTab';
import { LinkOutlined, GithubOutlined, TwitterOutlined, InstagramOutlined, GlobalOutlined, CloseOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { INFTCollection, INFT, IAddress } from "interfaces/address.d";
import { NFTCollectionDetailModal } from './NFTCollectionDetailModal';
import { CHAIN_MAPPING } from 'interfaces/chain.d';
import { shortenAddress } from '../../../utils/formatters';
import { getNFTImage } from 'utils/imageUtils';
import { getBackgroundStyle, getNFTCollectionImage } from '../../../utils/imageUtils';

const { Text, Link } = Typography;

interface NFTsTabProps {
    isEmbedded?: boolean;
    record: {
        address: string;
        active_chain_ids?: string[];
    };
}

// Update the response type to match the API structure
interface NFTsResponse {
    request_id: string;
    results: {
        [chainId: string]: {
            data: INFTCollection[];
            cursor: string | null;
            has_more: boolean;
        };
    };
    errors: Record<string, any>;
}

const NFTsTab: React.FC<NFTsTabProps> = ({ record, isEmbedded = false }) => {
    const availableChains = record?.active_chain_ids || [];
    const [selectedChainIds, setSelectedChainIds] = useState<string[]>([]);
    const [selectedCollection, setSelectedCollection] = useState<(INFTCollection & { chainId: string }) | null>(null);
    const [nftCollectionImages, setNftCollectionImages] = useState<Record<string, string>>({});
    const [isLoadingImages, setIsLoadingImages] = useState(true);

    const { data, isLoading, refetch } = useOne<NFTsResponse>({
        resource: "nfts",
        id: record?.address,
        meta: {
            address: record?.address,
            chain_ids: availableChains,
            limit: 1,
            operation: "getWalletNFTCollections",
            token_counts: true,
            exclude_spam: true,
        },
        queryOptions: {
            enabled: isEmbedded,
            refetchOnWindowFocus: false,
        }
    });
        
    useEffect(() => {
        if(!isEmbedded){
            refetch();
        }
    }, [availableChains]);

    // Get all collections with their chain IDs
    const getAllCollections = () => {
        if (!data?.data?.results) return [];

        return Object.entries(data.data.results).flatMap(([chainId, chainData]) => 
            chainData.data.map(collection => ({
                ...collection,
                chainId,
            }))
        );
    };

    // Filter collections based on selected chains
    const getFilteredCollections = () => {
        const allCollections = getAllCollections();
        if (selectedChainIds.length === 0) return allCollections;
        let filteredCollections = allCollections.filter(collection => 
            selectedChainIds.includes(collection.chainId)
        );
        setFilteredCollections(filteredCollections);
        return filteredCollections;
    };    
    const [filteredCollections, setFilteredCollections] = useState<INFTCollection[]>(getFilteredCollections());

    useEffect(() => {
        console.log("ddeewdede")
        let mounted = true; // For cleanup
        const filteredCollections = getFilteredCollections();
        // Load imgetBackgroundStyleages for all NFTs in the list
        const loadImages = async () => {
            console.log("1111")
            console.log(filteredCollections)

            if (!filteredCollections?.length) return; // Guard against empty collections
            console.log("22222")

            setIsLoadingImages(true);
            try {
                const imagePromises = filteredCollections.map(async (collection) => {
                    try {
                        const imageUrl = await getNFTCollectionImage(collection);
                        return [collection.token_address, imageUrl] as [string, string];
                    } catch {
                        return [collection.token_address, '/images/nft-placeholder.png'] as [string, string];
                    }
                });

                const loadedImages = await Promise.all(imagePromises);
                
                // Only update state if component is still mounted
                if (mounted) {
                    setNftCollectionImages(Object.fromEntries(loadedImages));
                    setIsLoadingImages(false);
                }
            } catch (error) {
                console.error('Failed to load NFT images:', error);
                if (mounted) {
                    setIsLoadingImages(false);
                }
            }
        };

        loadImages();

        // Cleanup function
        return () => {
            mounted = false;
        };
    }, [filteredCollections, data]); // Only depend on length and collection address

    return (
        <Space 
            direction="vertical" 
            style={{ 
                width: '100%',
                ...(isEmbedded && {
                    height: 'calc(80vh - 180px)', // Match container height
                    minHeight: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '0 24px'
                })
            }} 
            size="large"
        >
            {isEmbedded && (
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Space align="center">
                        <Button 
                            type="link" 
                            icon={<ArrowLeftOutlined/>}
                            onClick={() => {
                                const overviewTab = document.querySelector('[data-node-key="overview"]');
                                if (overviewTab) {
                                    (overviewTab as HTMLElement).click();
                                }
                            }}
                        >
                            Back to Graph View
                        </Button>
                        <Divider type="vertical" />

                        <Tag color="warning">
                            <Space>
                                <Text type="secondary">Viewing NFTs for</Text>
                                <Text strong copyable={{ text: record?.address }}>
                                    <Tooltip title={record?.address}>
                                        {shortenAddress(record?.address)}
                                    </Tooltip>
                                </Text>
                                <Text type="secondary">on</Text>
                                <Space>
                                    <ChainAvatar chainId={availableChains?.[0]} size="small" />
                                    <Text>{CHAIN_MAPPING[availableChains?.[0]] || availableChains?.[0]}</Text>
                                </Space>
                            </Space>
                        </Tag>
                    </Space>
                    <Button 
                        type="text" 
                        icon={<CloseOutlined/>}
                        onClick={() => {
                            const overviewTab = document.querySelector('[data-node-key="overview"]');
                            if (overviewTab) {
                                (overviewTab as HTMLElement).click();
                            }
                        }}
                    />
                </Space>
            )}
            <Card style={{
                ...(isEmbedded && {
                    height: 'calc(100% - 60px)', // Account for header
                    overflow: 'auto'
                })
            }}>
                <Space wrap>
                    <Select
                        mode="multiple"
                        value={selectedChainIds}
                        onChange={setSelectedChainIds}
                        style={{ width: 350 }}
                        placeholder="Select chains (empty = all chains)"
                        allowClear
                        maxTagCount={3}
                    >
                        {availableChains.map((chainId: string) => (
                            <Select.Option key={chainId} value={chainId}>
                                <Space>
                                    <ChainAvatar chainId={chainId} size="small" />
                                    {CHAIN_MAPPING[chainId]}
                                </Space>
                            </Select.Option>
                        ))}
                    </Select>
                </Space>
            </Card>

            <List
                loading={isLoading}
                grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 }}
                dataSource={getFilteredCollections()}
                renderItem={(collection) => (
                    <List.Item>
                        <Card
                            hoverable
                            cover={
                                
                                <div
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        borderRadius: '8px',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        ...getBackgroundStyle(nftCollectionImages[collection.token_address] || '/images/nft-placeholder.png')
                                    }}
                                >    
                                    <div style={{
                                        position: 'absolute',
                                        top: 16,
                                        right: 16,
                                    }}>
                                        <Tooltip title={CHAIN_MAPPING[collection.chainId]}>
                                            <ChainAvatar chainId={collection.chainId} size="large" />
                                        </Tooltip>
                                    </div>
                                </div>
                            }
                            onClick={() => setSelectedCollection(collection)}
                        >
                            <Card.Meta
                                title={
                                    <Space>
                                        {collection.name}
                                        {collection.verified_collection && (
                                            <Tag color="blue">Verified</Tag>
                                        )}
                                        <Tag color="purple">{collection.count} NFTs</Tag>
                                    </Space>
                                }
                                description={
                                    <Space direction="vertical" size="small">
                                        <Space wrap>
                                            <Tag>{collection.contract_type}</Tag>
                                            {collection.collection_category && (
                                                <Tag color="purple">{collection.collection_category}</Tag>
                                            )}
                                            {collection.possible_spam && (
                                                <Tag color="red">Possible Spam</Tag>
                                            )}
                                        </Space>
                                        <Text type="secondary">{collection.symbol}</Text>
                                        <Space wrap>
                                            {collection.project_url && (
                                                <Tooltip title="Project Website">
                                                    <Link href={collection.project_url} target="_blank">
                                                        <GlobalOutlined/>
                                                    </Link>
                                                </Tooltip>
                                            )}
                                            {collection.twitter_username && (
                                                <Tooltip title={`@${collection.twitter_username}`}>
                                                    <Link href={`https://twitter.com/${collection.twitter_username}`} target="_blank">
                                                        <TwitterOutlined/>
                                                    </Link>
                                                </Tooltip>
                                            )}
                                            {collection.instagram_username && (
                                                <Tooltip title={`@${collection.instagram_username}`}>
                                                    <Link href={`https://instagram.com/${collection.instagram_username}`} target="_blank">
                                                        <InstagramOutlined/>
                                                    </Link>
                                                </Tooltip>
                                            )}
                                            {collection.github_username && (
                                                <Tooltip title={collection.github_username}>
                                                    <Link href={`https://github.com/${collection.github_username}`} target="_blank">
                                                        <GithubOutlined/>
                                                    </Link>
                                                </Tooltip>
                                            )}
                                        </Space>
                                    </Space>
                                }
                            />
                        </Card>
                    </List.Item>
                )}
            />

            {selectedCollection && (
                <NFTCollectionDetailModal
                    visible={!!selectedCollection}
                    onClose={() => setSelectedCollection(null)}
                    collection={selectedCollection}
                    walletAddress={record?.address}
                />
            )}
        </Space>
    );
};

export { NFTsTab }; 
import React, { useState } from 'react';
import { Modal, Space, Button, Tag, Typography, Divider, Row, Col, Statistic, Tooltip, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { 
    CodeOutlined, 
    CloseOutlined, 
    EyeOutlined, 
    CheckCircleOutlined, 
    WarningOutlined,
    ApiOutlined,
    WalletOutlined,
    KeyOutlined,
    PictureOutlined,
    SwapOutlined,
    UserOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import { GraphLink } from '../../../interfaces/graph';
import { formatWeiToNative } from '../../../utils/formatters';
import { IAddress } from 'interfaces/address';
import { renderAddressAvatar } from '../../../components/address-avatar';

interface LinkStatsProps {
    link: GraphLink;
    chainIdToName: Record<string, string>;
    onClose: () => void;
    address_items_map: Record<string, IAddress>;
}

export const LinkStats: React.FC<LinkStatsProps> = ({ link, chainIdToName, onClose, address_items_map }) => {
    const [isRawDataVisible, setIsRawDataVisible] = useState(false);

    const formatValue = (link: GraphLink) => {
        if (link.type === 'internal') {
            return `${formatWeiToNative(link.value)} ${chainIdToName[link.chain_id]?.split(' ')[0] || 'Native'}`;
        }
        return `${link.value_formatted || '0'} ${link.token_symbol || ''}`;
    };
    
    // Helper function to get transaction hash from link ID
    const getTransactionHash = (linkId: string) => {
        if (!linkId) return '';
        const parts = linkId.split('_');
        return parts[1] || '';
    };

    // Helper function to get addresses from nodes
    const getAddressFromNode = (node: any): string => {
        if (!node) return '';
        return typeof node === 'string' ? node : node.friendly_name || node.id || '';
    };

    const sourceAddress = getAddressFromNode(link?.source);
    const targetAddress = getAddressFromNode(link?.target);

    return (
        <>
            <Modal
                title="Raw Link Data"
                open={isRawDataVisible}
                onCancel={onClose}
                footer={[
                    <Button key="close" onClick={onClose}>
                        Close
                    </Button>
                ]}
                width={800}
            >
                <pre style={{ 
                    backgroundColor: '#f5f5f5', 
                    padding: '16px', 
                    borderRadius: '4px',
                    overflow: 'auto',
                    maxHeight: '600px'
                }}>
                    {JSON.stringify(link, null, 2)}
                </pre>
            </Modal>

            <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                background: 'white',
                padding: '16px 24px',
                boxShadow: '0 -2px 8px rgba(0,0,0,0.15)',
                zIndex: 1000,
            }}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Space>
                            <Tag color={link?.linkColor || '#999'}>
                                {link?.type?.toUpperCase() || 'TRANSFER'}
                            </Tag>
                            <Space direction="vertical" size={0}>
                                <Space>
                                    <Typography.Title level={5} style={{ margin: 0 }}>
                                        {link?.linkLabel?.split('\n')[0] != "undefined" ? link?.linkLabel?.split('\n')[0] || 'Transaction' : 'Transaction'}
                                    </Typography.Title>
                                    <Link 
                                        to={link?.transaction_id ? `/transactions/show/${link?.transaction_id}` : `/transactions/show/${link?.chain_id}_${getTransactionHash(link?.id)}`}
                                        style={{ fontSize: '12px' }}
                                    >
                                        <Button 
                                            type="link" 
                                            size="small" 
                                            icon={<EyeOutlined />}
                                        >
                                            View Transaction
                                        </Button>
                                    </Link>
                                </Space>
                                <Space>
                                    <Space size={2}>
                                        <Typography.Text type="secondary" copyable={{ text: sourceAddress }}>
                                            {`${sourceAddress.slice(0, 6)}...${sourceAddress.slice(-4)}`}
                                        </Typography.Text>
                                        {renderAddressAvatar(
                                            {
                                                chain_id: link?.chain_id,
                                                address_items_map: { [sourceAddress]: address_items_map[sourceAddress] }
                                            },
                                            'from',
                                            sourceAddress,
                                            {
                                                maxGlobalLabels: 3,
                                                maxUserLabels: 2,
                                                maxTotalLabels: 5,
                                                maxGlobalEntities: 3,
                                                maxUserEntities: 2,
                                                maxTotalEntities: 5,
                                                displayStyle: 'hover',
                                                hideAddress: true
                                            }
                                        )}
                                    </Space>
                                    <Typography.Text type="secondary">→</Typography.Text>
                                    <Space size={2}>
                                        <Typography.Text type="secondary" copyable={{ text: targetAddress }}>
                                            {`${targetAddress.slice(0, 6)}...${targetAddress.slice(-4)}`}
                                        </Typography.Text>
                                        {renderAddressAvatar(
                                            {
                                                chain_id: link?.chain_id,
                                                address_items_map: { [targetAddress]: address_items_map[targetAddress] }
                                            },
                                            'to',
                                            targetAddress,
                                            {
                                                maxGlobalLabels: 3,
                                                maxUserLabels: 2,
                                                maxTotalLabels: 5,
                                                maxGlobalEntities: 3,
                                                maxUserEntities: 2,
                                                maxTotalEntities: 5,
                                                displayStyle: 'hover',
                                                hideAddress: true
                                            }
                                        )}
                                    </Space>
                                </Space>
                            </Space>
                        </Space>
                        <Space>
                            <Button 
                                icon={<CodeOutlined />}
                                onClick={() => setIsRawDataVisible(true)}
                                type="text"
                            >
                                View Raw Data
                            </Button>
                            <Button 
                                icon={<CloseOutlined />} 
                                type="text"
                                onClick={onClose}
                            />
                        </Space>
                    </Space>

                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Statistic 
                                title="Type" 
                                value={link?.type || 'transfer'}
                                prefix={
                                    link?.type === 'internal' ? <ApiOutlined /> :
                                    link?.type === 'native' ? <WalletOutlined /> :
                                    link?.type === 'erc20' ? <KeyOutlined /> :
                                    link?.type === 'nft' ? <PictureOutlined /> :
                                    link?.type === 'contract' ? <CodeOutlined /> :
                                    <SwapOutlined />
                                }
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="Value" 
                                value={
                                    link?.type === 'internal' 
                                        ? formatWeiToNative(link?.value || '0')
                                        : link?.value_formatted || '0'
                                }
                                prefix={
                                    link?.type === 'internal'
                                        ? chainIdToName[link?.chain_id]?.split(' ')[0] || 'Native'
                                        : link?.token_symbol || 'Ξ'
                                }
                                formatter={(value) => (
                                    <Tooltip title={value}>
                                        {typeof value === 'string' && value.length > 10 
                                            ? `${value.slice(0, 10)}...` 
                                            : value}
                                    </Tooltip>
                                )}
                            />
                        </Col>
                        {link?.type === 'nft' && (
                            <>
                                <Col span={6}>
                                    <Statistic 
                                        title="Token ID" 
                                        value={link?.token_id || 'N/A'}
                                        formatter={(value) => (
                                            <Tooltip title={value}>
                                                {typeof value === 'string' && value.length > 10 
                                                    ? `${value.slice(0, 10)}...` 
                                                    : value}
                                            </Tooltip>
                                        )}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Statistic 
                                        title="Contract Type" 
                                        value={link?.contract_type || 'Unknown'}
                                    />
                                </Col>
                            </>
                        )}
                        {link?.type === 'erc20' && (
                            <>
                                <Col span={6}>
                                    <Statistic 
                                        title="Token Name" 
                                        value={link?.token_name || 'Unknown'}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Statistic 
                                        title="Security Score" 
                                        value={link?.security_score || 'N/A'}
                                        valueStyle={{ 
                                            color: link?.security_score && link?.security_score >= 80 ? '#3f8600' : '#cf1322'
                                        }}
                                    />
                                </Col>
                            </>
                        )}
                        <Col span={6}>
                            <Statistic 
                                title="Status" 
                                value={link?.receipt_status === '1' ? 'Success' : link?.receipt_status === '0' ? 'Failed' : 'Pending'}
                                valueStyle={{ 
                                    color: link?.receipt_status === '1' ? '#3f8600' : 
                                           link?.receipt_status === '0' ? '#cf1322' : '#faad14'
                                }}
                                prefix={
                                    link?.receipt_status === '1' ? <CheckCircleOutlined /> :
                                    link?.receipt_status === '0' ? <CloseOutlined /> :
                                    <ClockCircleOutlined />
                                }
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="Block" 
                                value={link?.block_number}
                                formatter={(value) => (
                                    <Tooltip title={value}>
                                        {value ? `#${value}` : 'Pending'}
                                    </Tooltip>
                                )}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="Transaction Fee" 
                                value={link?.transaction_fee}
                                prefix="Ξ"
                                formatter={(value) => (
                                    <Tooltip title={`${value} ETH`}>
                                        {typeof value === 'string' && value.length > 10 
                                            ? `${value.slice(0, 10)}...` 
                                            : value}
                                    </Tooltip>
                                )}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="Timestamp" 
                                value={link?.block_timestamp}
                                formatter={(value) => (
                                    <Tooltip title={value}>
                                        {value ? new Date(value).toLocaleString() : 'Pending'}
                                    </Tooltip>
                                )}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="Gas Used" 
                                value={link?.gas}
                                formatter={(value) => (
                                    <Tooltip title={`${value} gas units`}>
                                        {value ? Number(value).toLocaleString() : 'N/A'}
                                    </Tooltip>
                                )}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="Gas Price" 
                                value={link?.gas_price}
                                formatter={(value) => (
                                    <Tooltip title={`${value} wei`}>
                                        {value ? `${(Number(value) / 1e9).toFixed(2)} Gwei` : 'N/A'}
                                    </Tooltip>
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic 
                                title="Chain" 
                                value={chainIdToName[link?.chain_id || ''] || link?.chain_id}
                                prefix={
                                    <Avatar 
                                        size="large"
                                        src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/blockchain/logos/svg/${link?.chain_id}.svg`}
                                        icon={<ApiOutlined />}
                                        style={{ marginRight: 8 }}
                                    />
                                }
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic 
                                title="Hash" 
                                value={link?.hash}
                                formatter={(value) => (
                                    <Typography.Text copyable={{ text: String(value) }}>
                                        {`${String(value).slice(0, 10)}...${String(value).slice(-10)}`}
                                    </Typography.Text>
                                )}
                            />
                        </Col>                        
                    </Row>

                    {link?.linkLabel && link?.linkLabel.split('\n').length > 1 && (
                        <>
                            <Divider style={{ margin: '12px 0' }} />
                            <Typography.Text type="secondary">
                                {link?.linkLabel.split('\n').slice(1).join('\n')}
                            </Typography.Text>
                        </>
                    )}

                    <Space style={{ marginTop: 12 }}>
                        {link?.receipt_status === '1' && (
                            <Tag color="success" icon={<CheckCircleOutlined />}>
                                Transaction Successful
                            </Tag>
                        )}
                        {link?.receipt_status === '0' && (
                            <Tag color="error" icon={<CloseOutlined />}>
                                Transaction Failed
                            </Tag>
                        )}
                        {link?.verified_contract && (
                            <Tag color="success" icon={<CheckCircleOutlined />}>
                                Verified Contract
                            </Tag>
                        )}
                        {link?.possible_spam && (
                            <Tag color="error" icon={<WarningOutlined />}>
                                Possible Spam
                            </Tag>
                        )}
                        {link?.verified_collection && (
                            <Tag color="success" icon={<CheckCircleOutlined />}>
                                Verified Collection
                            </Tag>
                        )}
                    </Space>
                </Space>
            </div>
        </>
    );
}; 
import React, { useState } from 'react';
import { Card, Typography, Tabs, Tag, Space, Descriptions, Collapse, Button, Tooltip, Badge, Empty, Timeline, Divider, List, Modal, Avatar } from 'antd';
import { 
  ContactsOutlined, 
  WalletOutlined, 
  ClockCircleOutlined, 
  InfoCircleOutlined, 
  CodeOutlined,
  UserOutlined,
  GlobalOutlined,
  LinkOutlined,
  HistoryOutlined,
  TagsOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  TeamOutlined,
  ArrowRightOutlined,
  ApartmentOutlined
} from '@ant-design/icons';
import { CHAIN_MAPPING, CHAIN_CONFIG } from '../../../interfaces/chain.d';
import { shortenAddress } from '../../../utils/formatters';
import dayjs from 'dayjs';
import ContractStateViewerWithABI from '../../../components/ContractStateViewer/ContractStateViewerWithABI';

const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;

interface AddressInfoCardProps {
  addressData: any;
  showDetailedView?: boolean;
  onViewContract?: (address: string, chainId: string) => void;
  isAddressPage?: boolean;
  address_items_map?: any;
  apiDomain: string;
  region: string;
  regionAlias: string;
  token: string;
}

const AddressInfoCard: React.FC<AddressInfoCardProps> = ({ 
  addressData, 
  showDetailedView = false,
  onViewContract,
  isAddressPage = false,
  address_items_map = {},
  apiDomain,
  region,
  regionAlias,
  token
}) => {
  const [activeTab, setActiveTab] = useState('overview');
  const [isContractModalVisible, setIsContractModalVisible] = useState(false);
  const [selectedChainId, setSelectedChainId] = useState<string | null>(null);
  
  if (!addressData) {
    return <Empty description="No address information available" />;
  }
  
  const address = addressData.address;
  const isContract = Object.values(addressData.chain_contract_status || {}).some(status => status === true);
  const contractChains = Object.entries(addressData.chain_contract_status || {})
    .filter(([_, status]) => status === true)
    .map(([chainId]) => chainId);
  
  // Format the description with proper line breaks if needed
  const formattedDescription = addressData.description 
    ? addressData.description.length > 100 
      ? <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>{addressData.description}</Paragraph>
      : addressData.description
    : null;
  
  // Get contract deployer info for the first available chain
  const deployerInfo = contractChains.length > 0 
    ? addressData.contract_deployer_data?.[contractChains[0]]
    : null;
  
  // Format deployment date
  const deploymentDate = deployerInfo?.block_timestamp 
    ? dayjs(deployerInfo.block_timestamp).format('MMM D, YYYY')
    : null;
  
  // Get token info if available
  const isToken = addressData.contract_type === 'token';
  const tokenInfo = isToken ? {
    name: addressData.contract_name || 'Unknown Token',
    symbol: addressData.contract_symbol || '???',
  } : null;
  
  // Get detailed label information from address_items_map
  const getDetailedLabels = () => {
    if (!addressData.labels || addressData.labels.length === 0) {
      return [];
    }
    
    // Find this address in the address_items_map
    const addressItemsMapData = address_items_map[address.toLowerCase()] || {};
    const detailedLabels = addressItemsMapData?.labels || [];
    
    if (detailedLabels.length > 0) {
      return detailedLabels;
    }
    
    // Fallback to just showing the label IDs if no detailed info is available
    return addressData.labels.map((labelId: string) => ({
      id: labelId,
      name: labelId.replace('default_address_label_', ''),
      color: '',
      verified: false
    }));
  };
  
  // Get detailed entity information from address_items_map
  const getDetailedEntities = () => {
    if (!addressData.entities || addressData.entities.length === 0) {
      return [];
    }
    
    // Find this address in the address_items_map
    const addressItemsMapData = address_items_map[address.toLowerCase()] || {};
    const detailedEntities = addressItemsMapData?.entities || [];
    
    if (detailedEntities.length > 0) {
      return detailedEntities;
    }
    
    // Fallback to just showing the entity IDs if no detailed info is available
    return addressData.entities.map((entityId: string) => ({
      id: entityId,
      name: entityId.replace('default_entity_', ''),
      description: '',
      verified: false
    }));
  };
  
  const detailedLabels = getDetailedLabels();
  const detailedEntities = getDetailedEntities();
  
  // Check if we have first funder addresses
  const hasFirstFunders = addressData.first_funder_addresses && 
    Object.keys(addressData.first_funder_addresses).length > 0;
  
  // Function to render first funders that handles both simple and nested structures
  const renderFirstFunders = () => {
    if (!hasFirstFunders) return null;
    
    const firstFunderEntries = Object.entries(addressData.first_funder_addresses);
    
    return (
      <List
        itemLayout="horizontal"
        dataSource={firstFunderEntries}
        renderItem={([chainId, funderData]: [string, any]) => {
          const chainName = CHAIN_MAPPING[chainId] || chainId;
          const chainColor = CHAIN_CONFIG[chainId]?.color || "blue";
          
          // Handle nested structure
          if (typeof funderData === 'object' && !Array.isArray(funderData) && funderData !== null) {
            return (
              <List.Item>
                <List.Item.Meta
                  title={<><Tag color={chainColor}>{chainName}</Tag> First Funders</>}
                  description={
                    <List
                      size="small"
                      dataSource={Object.entries(funderData)}
                      renderItem={([nestedAddress, nestedFunder]: [string, any]) => (
                        <List.Item>
                          <Space>
                            <Text copyable={{ text: nestedAddress }}>{shortenAddress(nestedAddress)}</Text>
                            <ArrowRightOutlined />
                            <Text copyable={{ text: nestedFunder as string }}>{shortenAddress(nestedFunder as string)}</Text>
                          </Space>
                        </List.Item>
                      )}
                    />
                  }
                />
              </List.Item>
            );
          }
          
          // Handle simple structure
          return (
            <List.Item>
              <List.Item.Meta
                title={<><Tag color={chainColor}>{chainName}</Tag> First Funder</>}
                description={
                  <Text copyable={{ text: funderData as string }}>
                    {shortenAddress(funderData as string)}
                  </Text>
                }
              />
            </List.Item>
          );
        }}
      />
    );
  };
  
  // Function to handle opening the contract state viewer modal
  const handleOpenContractViewer = (address: string, chainId: string) => {
    setSelectedChainId(chainId);
    setIsContractModalVisible(true);
  };
  
  // Function to handle the "View Details" button click
  const handleViewDetails = () => {
    if (onViewContract) {
      onViewContract(address, contractChains[0]);
    }
  };
  
  // Function to properly format Unix timestamp or ISO date string
  const formatDate = (dateValue: string | number) => {
    if (!dateValue) return 'Unknown';
    
    // Check if it's a Unix timestamp (seconds since epoch)
    if (typeof dateValue === 'number' || !isNaN(Number(dateValue))) {
      const timestamp = Number(dateValue);
      // Check if it's in seconds (Unix timestamp) or milliseconds
      const date = timestamp > 1000000000000 ? 
        dayjs(timestamp) : // already in milliseconds
        dayjs.unix(timestamp); // in seconds, convert to milliseconds
      
      return date.format('MMM D, YYYY');
    }
    
    // Otherwise treat as ISO string
    return dayjs(dateValue).format('MMM D, YYYY');
  };
  
  // Add this helper function to get the logo URL from address_items_map
  const getAddressLogo = (address: string, address_items_map: any) => {
    if (!address || !address_items_map) return null;
    
    const addressData = address_items_map[address.toLowerCase()];
    if (!addressData) return null;
    
    // Check entities first for logo
    if (addressData.entities && addressData.entities.length > 0) {
      for (const entity of addressData.entities) {
        if (entity.logo) {
          return entity.logo;
        }
      }
    }
    
    // Then check labels for logo
    if (addressData.labels && addressData.labels.length > 0) {
      for (const label of addressData.labels) {
        if (label.logo) {
          return label.logo;
        }
      }
    }
    
    return null;
  };

  // Add this helper function to get the primary entity name
  const getPrimaryEntityName = () => {
    if (!address || !address_items_map) return null;
    
    const addressItemsMapData = address_items_map[address.toLowerCase()];
    if (!addressItemsMapData || !addressItemsMapData.entities || addressItemsMapData.entities.length === 0) {
      return null;
    }
    
    // Return the name of the first entity
    return addressItemsMapData.entities[0].name;
  };

  return (
    <Card
      title={
        <Space align="center">
          {/* Logo avatar */}
          {getAddressLogo(address, address_items_map) ? (
            <Avatar 
              size={40} 
              src={getAddressLogo(address, address_items_map)}
              style={{ marginRight: 8 }}
            />
          ) : (
            <Avatar 
              size={40} 
              icon={isContract ? <CodeOutlined /> : <WalletOutlined />}
              style={{ 
                backgroundColor: isContract ? '#722ed1' : '#1890ff',
                marginRight: 8 
              }}
            />
          )}
          
          {/* Address info with entity name */}
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            {getPrimaryEntityName() && (
              <Typography.Text strong style={{ fontSize: '14px', lineHeight: '1.2' }}>
                {getPrimaryEntityName()}
              </Typography.Text>
            )}
            <span>
              {isContract ? 'Contract ' : 'Address '}
              {shortenAddress(address)}
            </span>
          </span>
          
          {isToken && (
            <Tag color="green">
              {tokenInfo?.name} ({tokenInfo?.symbol})
            </Tag>
          )}
          {contractChains.length > 0 && (
            <Tag color={CHAIN_CONFIG[contractChains[0]]?.color || "blue"}>
              {CHAIN_MAPPING[contractChains[0]] || contractChains[0]}
            </Tag>
          )}
          {detailedLabels.length > 0 && (
            <Tag 
              color={detailedLabels[0].color ? `#${detailedLabels[0].color}` : 'default'}
              icon={detailedLabels[0].verified ? <CheckCircleOutlined /> : null}
            >
              {detailedLabels[0].name || detailedLabels[0].address_name || detailedLabels[0].id.replace('default_address_label_', '')}
            </Tag>
          )}
        </Space>
      }
      extra={
        isContract && !isAddressPage ? (
          <Button 
            type="primary" 
            icon={<ApartmentOutlined />}
            onClick={() => handleOpenContractViewer(address, contractChains[0])}
          >
            View Contract
          </Button>
        ) : null
      }
    >
      {showDetailedView ? (
        <>
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane 
              tab={<span><InfoCircleOutlined />Overview</span>} 
              key="overview"
            >
              {/* Quick Overview Section */}
              <div className="address-quick-info">
                {formattedDescription && (
                  <div className="address-description" style={{ marginBottom: 16 }}>
                    {formattedDescription}
                  </div>
                )}
                
                {isContract && (
                  <Space wrap style={{ marginBottom: 16 }}>
                    {contractChains.map(chainId => (
                      <Tooltip 
                        key={chainId} 
                        title={`Contract on ${CHAIN_MAPPING[chainId] || chainId}`}
                      >
                        <Tag 
                          color={CHAIN_CONFIG[chainId]?.color || "blue"}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleOpenContractViewer(address, chainId)}
                        >
                          {CHAIN_MAPPING[chainId] || chainId}
                        </Tag>
                      </Tooltip>
                    ))}
                  </Space>
                )}
                
                {detailedLabels.length > 0 && (
                  <div style={{ marginBottom: 16 }}>
                    <Space wrap>
                      {detailedLabels.map((label: any) => (
                        <Tag 
                          key={label.id} 
                          color={label.color ? `#${label.color}` : 'default'}
                          icon={label.verified ? <CheckCircleOutlined /> : null}
                        >
                          {label.name || label.address_name || label.id.replace('default_address_label_', '')}
                        </Tag>
                      ))}
                    </Space>
                  </div>
                )}
              </div>
              
              {/* Detailed Information */}
              <Descriptions column={1} bordered>
                {/* Show deployer information for each chain */}
                {isContract && contractChains.length > 0 && (
                  <>
                    {contractChains.map(chainId => {
                      const deployerData = addressData.contract_deployer_data?.[chainId];
                      if (!deployerData) return null;
                      
                      return (
                        <Descriptions.Item 
                          key={`deployer-${chainId}`}
                          label={
                            <Space>
                              <UserOutlined />
                              <span>Deployed on {CHAIN_MAPPING[chainId] || chainId}</span>
                            </Space>
                          }
                        >
                          <Space direction="vertical" size="small">
                            {deployerData.deployer?.deployer_address && (
                              <Space>
                                <Text>By: </Text>
                                <Text copyable={{ text: deployerData.deployer.deployer_address }}>
                                  {shortenAddress(deployerData.deployer.deployer_address)}
                                </Text>
                                {deployerData.deployer.is_contract && <Tag color="purple">Contract</Tag>}
                              </Space>
                            )}
                            
                            {deployerData.block_timestamp && (
                              <Text type="secondary">
                                {formatDate(deployerData.block_timestamp)}
                              </Text>
                            )}
                            
                            {deployerData.is_proxy && (
                              <Tag color="orange">Proxy Contract</Tag>
                            )}
                          </Space>
                        </Descriptions.Item>
                      );
                    })}
                  </>
                )}
                
                {isToken && (
                  <Descriptions.Item label="Token Info">
                    <Space direction="vertical">
                      <Text>Name: {tokenInfo?.name}</Text>
                      <Text>Symbol: {tokenInfo?.symbol}</Text>
                    </Space>
                  </Descriptions.Item>
                )}
              </Descriptions>
              
              {/* Related Wallets Section */}
              {hasFirstFunders && (
                <div style={{ marginTop: 16 }}>
                  <Collapse defaultActiveKey={['first-funders']}>
                    <Panel 
                      header={
                        <Space>
                          <TeamOutlined />
                          <span>Related Wallets</span>
                        </Space>
                      } 
                      key="first-funders"
                    >
                      {renderFirstFunders()}
                    </Panel>
                  </Collapse>
                </div>
              )}
            </TabPane>
            
            <TabPane 
              tab={<span><GlobalOutlined />Chain Data</span>} 
              key="chains"
            >
              <Collapse defaultActiveKey={['active']}>
                <Panel header="Active Chains" key="active">
                  {contractChains.length > 0 ? (
                    <Space wrap>
                      {contractChains.map(chainId => (
                        <Tooltip 
                          key={chainId} 
                          title={`${isContract ? 'Contract' : 'Active'} on ${CHAIN_MAPPING[chainId] || chainId}`}
                        >
                          <Tag 
                            color={isContract ? CHAIN_CONFIG[chainId]?.color || "green" : 'default'}
                            style={{ 
                              opacity: isContract ? 1 : 0.6,
                              cursor: isContract ? 'pointer' : 'default'
                            }}
                            onClick={isContract ? () => handleOpenContractViewer(address, chainId) : undefined}
                          >
                            {CHAIN_MAPPING[chainId] || chainId}
                          </Tag>
                        </Tooltip>
                      ))}
                    </Space>
                  ) : (
                    <Empty description="No chain data available" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </Panel>
                
                {hasFirstFunders && (
                  <Panel header="First Funders by Chain" key="first-funders">
                    {renderFirstFunders()}
                  </Panel>
                )}
                
                {Object.keys(addressData.chain_active_since || {}).length > 0 && (
                  <Panel header="Active Since" key="active-since">
                    <Timeline>
                      {Object.entries(addressData.chain_active_since)
                        .sort((a, b) => {
                          // Parse dates properly
                          const dateA = typeof a[1] === 'number' || !isNaN(Number(a[1])) ? 
                            (Number(a[1]) > 1000000000000 ? Number(a[1]) : Number(a[1]) * 1000) : 
                            new Date(a[1] as string).getTime();
                          
                          const dateB = typeof b[1] === 'number' || !isNaN(Number(b[1])) ? 
                            (Number(b[1]) > 1000000000000 ? Number(b[1]) : Number(b[1]) * 1000) : 
                            new Date(b[1] as string).getTime();
                          
                          return dateA - dateB;
                        })
                        .map(([chainId, date]) => (
                          <Timeline.Item key={chainId}>
                            <Space>
                              <Tag color={CHAIN_CONFIG[chainId]?.color || "blue"}>
                                {CHAIN_MAPPING[chainId] || chainId}
                              </Tag>
                              <Text>{formatDate(date as string | number)}</Text>
                            </Space>
                          </Timeline.Item>
                        ))
                      }
                    </Timeline>
                  </Panel>
                )}
              </Collapse>
            </TabPane>
            
            {addressData.entities && addressData.entities.length > 0 && (
              <TabPane 
                tab={<span><ContactsOutlined />Entities</span>} 
                key="entities"
              >
                <List
                  itemLayout="horizontal"
                  dataSource={detailedEntities}
                  renderItem={(entity: any) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <Space>
                            <Text strong>{entity.name || entity.id}</Text>
                            {entity.verified && <CheckCircleOutlined style={{ color: '#52c41a' }} />}
                          </Space>
                        }
                        description={entity.description || null}
                      />
                    </List.Item>
                  )}
                />
              </TabPane>
            )}
          </Tabs>
        </>
      ) : (
        // Simple view - just show the most important information without duplication
        <div>
          {/* Only show description in simple view if not already shown in the header */}
          {formattedDescription && (
            <div style={{ marginBottom: 16 }}>
              {formattedDescription}
            </div>
          )}
          
          {/* Only show "View Details" button in simple view */}
          {!isAddressPage && (
            <div style={{ marginTop: 16, textAlign: 'center' }}>
              <Button type="link" onClick={handleViewDetails}>
                View Details
              </Button>
            </div>
          )}
        </div>
      )}
      
      {/* Contract State Viewer Modal */}
      <Modal
        title={
          <Space>
            <ApartmentOutlined />
            <span>Contract State: {<Text copyable={{ text: address }}>{shortenAddress(address)}</Text>}</span>
            {selectedChainId && (
              <Tag color={CHAIN_CONFIG[selectedChainId]?.color || "blue"}>
                {CHAIN_MAPPING[selectedChainId] || selectedChainId}
              </Tag>
            )}
          </Space>
        }
        open={isContractModalVisible}
        onCancel={() => setIsContractModalVisible(false)}
        footer={null}
        width={1000}
        style={{ top: 20 }}
        bodyStyle={{ padding: 0 }}
      >
        {isContractModalVisible && selectedChainId && (
          <ContractStateViewerWithABI
            contractAddress={address}
            chainId={selectedChainId}
            title="Contract State"
            apiDomain={apiDomain}
            region={region}
            regionAlias={regionAlias}
            token={token}
            transactionContext={{
              selectedAddress: address,
              chain_id: selectedChainId
            }}
            key={`address_info_card_contract_viewer_${address}_${selectedChainId}`}
          />
        )}                
      </Modal>
    </Card>
  );
};

export default AddressInfoCard; 
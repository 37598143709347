import React from 'react';
import { Space, Typography, Form, Slider, Switch, Divider, Checkbox } from 'antd';
import { 
    DashboardOutlined, 
    NodeIndexOutlined,
    LinkOutlined,
    EyeOutlined 
} from '@ant-design/icons';
import { GraphSettings as GraphSettingsType } from '../../../interfaces/graph';

interface GraphSettingsProps {
    settings: GraphSettingsType;
    onSettingChange: (key: keyof GraphSettingsType, value: any) => void;
    nodesPerRow: number;
    verticalSpacing: number;
    onNodesPerRowChange: (value: number) => void;
    onVerticalSpacingChange: (value: number) => void;
}

export const GraphSettings: React.FC<GraphSettingsProps> = ({
    settings,
    onSettingChange,
    nodesPerRow,
    verticalSpacing,
    onNodesPerRowChange,
    onVerticalSpacingChange
}) => {
    return (
        <Space direction="vertical" style={{ 
            padding: '16px 24px', 
            minWidth: '280px',
            background: 'white',
            boxShadow: '0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05)',
            borderRadius: '8px'
        }}>
            <Typography.Title level={5} style={{ margin: '0 0 16px 0' }}>Graph Layout Settings</Typography.Title>
            
            <Form layout="vertical" style={{ width: '100%' }}>
                {/* Layout Settings */}
                <Form.Item 
                    label={<Typography.Text strong>Nodes Per Row</Typography.Text>}
                    help="Adjust how many nodes appear in each row of the graph"
                >
                    <Slider
                        min={1}
                        max={100}
                        value={nodesPerRow}
                        onChange={onNodesPerRowChange}
                        marks={{
                            1: '1',
                            10: '10',
                            100: '100'
                        }}
                        tooltip={{ formatter: (value) => `${value} nodes` }}
                    />
                </Form.Item>
                
                <Form.Item 
                    label={<Typography.Text strong>Vertical Spacing</Typography.Text>}
                    help="Adjust the vertical distance between rows of nodes"
                >
                    <Slider
                        min={50}
                        max={500}
                        step={10}
                        value={verticalSpacing}
                        onChange={onVerticalSpacingChange}
                        marks={{
                            50: '50px',
                            150: '150px',
                            500: '500px'
                        }}
                        tooltip={{ formatter: (value) => `${value}px` }}
                    />
                </Form.Item>

                <Divider />

                {/* Physics Settings */}
                <Space align="center" style={{ marginBottom: 8 }}>
                    <DashboardOutlined />
                    <Typography.Text strong>Physics Settings</Typography.Text>
                </Space>

                <Form.Item
                    label="Enable Physics"
                    style={{ marginBottom: 12 }}
                >
                    <Switch 
                        checked={settings.physics.enabled}
                        onChange={(checked) => 
                            onSettingChange('physics', { ...settings.physics, enabled: checked })}
                    />
                </Form.Item>

                <Form.Item
                    label="Force Strength"
                    style={{ marginBottom: 16 }}
                >
                    <Slider
                        min={0}
                        max={1}
                        step={0.1}
                        value={settings.physics.strength}
                        onChange={(value) => 
                            onSettingChange('physics', { ...settings.physics, strength: value })}
                    />
                </Form.Item>

                <Divider />

                {/* Node Settings */}
                <Space align="center" style={{ marginBottom: 8 }}>
                    <NodeIndexOutlined />
                    <Typography.Text strong>Node Settings</Typography.Text>
                </Space>

                <Form.Item
                    label="Node Size"
                    style={{ marginBottom: 12 }}
                >
                    <Slider
                        min={4}
                        max={20}
                        value={settings.nodes?.size || 8}
                        onChange={(value) => 
                            onSettingChange('nodes', { ...settings.nodes, size: value })}
                    />
                </Form.Item>

                <Form.Item
                    label="Show Labels"
                    style={{ marginBottom: 16 }}
                >
                    <Switch 
                        checked={settings.nodes?.showLabels}
                        onChange={(checked) => 
                            onSettingChange('nodes', { ...settings.nodes, showLabels: checked })}
                    />
                </Form.Item>

                <Divider />

                {/* Link Settings */}
                <Space align="center" style={{ marginBottom: 8 }}>
                    <LinkOutlined />
                    <Typography.Text strong>Link Settings</Typography.Text>
                </Space>

                <Form.Item
                    label="Link Width"
                    style={{ marginBottom: 12 }}
                >
                    <Slider
                        min={1}
                        max={5}
                        value={settings.links?.width || 2}
                        onChange={(value) => 
                            onSettingChange('links', { ...settings.links, width: value })}
                    />
                </Form.Item>

                <Form.Item
                    label="Show Arrows"
                    style={{ marginBottom: 16 }}
                >
                    <Switch 
                        checked={settings.links?.showArrows}
                        onChange={(checked) => 
                            onSettingChange('links', { ...settings.links, showArrows: checked })}
                    />
                </Form.Item>

                <Form.Item
                    label="Curved Links"
                    style={{ marginBottom: 16 }}
                >
                    <Switch 
                        checked={settings.links?.curved}
                        onChange={(checked) => 
                            onSettingChange('links', { ...settings.links, curved: checked })}
                    />
                </Form.Item>

                <Divider />

                {/* Visual Settings */}
                <Space align="center" style={{ marginBottom: 8 }}>
                    <EyeOutlined />
                    <Typography.Text strong>Visual Settings</Typography.Text>
                </Space>

                <Form.Item
                    label="Dark Mode"
                    style={{ marginBottom: 12 }}
                >
                    <Switch 
                        checked={settings.visual?.darkMode}
                        onChange={(checked) => 
                            onSettingChange('visual', { ...settings.visual, darkMode: checked })}
                    />
                </Form.Item>
                <Form.Item
                    label="Show Time Range Filter"
                    style={{ marginBottom: 16 }}
                >
                    <Switch 
                        checked={settings.visual?.showTimeRange}
                        onChange={(checked) => 
                            onSettingChange('visual', { ...settings.visual, showTimeRange: checked })}
                    />
                </Form.Item>
                <Form.Item
                    label="Show Event Logs"
                    style={{ marginBottom: 0 }}
                    help="Display transaction event logs in the graph"
                >
                    <Switch 
                        checked={settings.visual?.enableEventLogs}
                        onChange={(checked) => 
                            onSettingChange('visual', { ...settings.visual, enableEventLogs: checked })}
                    />
                </Form.Item>
                <Form.Item
                    label="Enable Bridge Nodes"
                    style={{ marginBottom: 0 }}
                    help="Display bridge nodes in the graph"
                >
                    <Switch  
                        checked={settings.visual?.enableBridgeNodes}
                        onChange={(checked) => 
                            onSettingChange('visual', { ...settings.visual, enableBridgeNodes: checked })}
                    />
                </Form.Item>
            </Form>
        </Space>
    );
}; 
import { ChainType, ChainName, ChainId } from './chain';

// Entity-related enums
export enum EntityType {
    COMPANY = "company",
    INDIVIDUAL = "individual",
    DAO = "dao",
    CONTRACT = "contract",
    LABEL = "label",
    EXCHANGE = "exchange",
    WALLET = "wallet"
}

export const ENTITY_TYPE_COLORS: Record<EntityType, string> = {
    [EntityType.COMPANY]: '#2f54eb',     // Geekblue
    [EntityType.INDIVIDUAL]: '#faad14',  // Gold
    [EntityType.DAO]: '#eb2f96',         // Pink
    [EntityType.CONTRACT]: '#1890ff',    // Blue
    [EntityType.LABEL]: '#13c2c2',       // Cyan
    [EntityType.EXCHANGE]: '#fa8c16',    // Orange
    [EntityType.WALLET]: '#722ed1',      // Purple
};

// For the select options
export interface IEntitySelectOption {
    label: string;
    value: string;
    name?: string;
    color?: string;
    status?: IEntity['status'];
    type?: IEntity['type'];
}

export enum EntityStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
    PENDING = "pending",
    DELETED = "deleted",
    BLOCKED = "blocked"
}

// Base interface
export interface IEntityBase {
    created_at: number;
    created_by: string;
}

// Main entity interface
export interface IEntity extends IEntityBase {
    org_tenant_id: string;     // PK (format: "org_id-tenant_id")
    id: string;                // SK (format: "entity_id")
    SK?: string;             
    PK?: string;
    name: string;
    type: EntityType;
    status: EntityStatus;
    website?: string;
    twitter?: string;
    logo?: string;
    bio?: string;
    description?: string;
    color?: string;
    source?: string;
    updated_at?: number;
    updated_by?: string;
    updated_by_service?: string;
    tags: string[];
    metadata: Record<string, any>;
    labels: string[];
    main_labels: Record<string, any>;
    risk_score?: number;
    risk_level?: string;
    compliance_status?: string;
    last_risk_assessment?: number;
    parent_entity_id?: string;
    child_entity_ids: string[];
    related_entity_ids: string[];
    addresses?: IAddress[];
    // Additional fields
    country?: string;         // Country of registration/operation
    founded_year?: number;    // Year the entity was founded
    category?: string;        // Business/entity category
    industry?: string;        // Industry sector
    size?: string;           // Organization size (small, medium, large)
    verified?: boolean;       // Verification status
    last_activity?: number;  // Timestamp of last known activity
    first_seen?: number;     // Timestamp when entity was first observed
    aliases?: string[];      // Alternative names/identifiers
    external_ids?: Record<string, string>;  // IDs from external systems (e.g., { "linkedin": "company/123" })
    address_items_map: Record<string, IAddress>;
    chain_ids?: ChainId[];
    main_chain_ids?: ChainId[];
}

// Address to Entity mapping
export interface IAddressEntityMapping extends IEntityBase {
    address: string;           // PK (format: "0x...")
    entity_id: string;         // SK (format: "entity_id")
    name?: string;            // Optional display name
    type?: EntityType;        // Entity type reference
    status?: EntityStatus;    // Entity status reference
    metadata: Record<string, any>;
    updated_at?: number;
    updated_by?: string;
    updated_by_service?: string;
}

// Entity to Address mapping
export interface IEntityAddressMapping extends IEntityBase {
    entity_id: string;         // PK (format: "entity_id")
    address: string;           // SK (format: "0x...")
    chain_ids: ChainId[];     // Array of chain IDs (e.g., ["0x1", "0x89"])
    main_chain_ids: ChainId[]; // Primary chains for this address
    chain_type?: ChainType;   // Type of chain (eth, btc, etc.)
    chain_name?: ChainName;   // Friendly chain name
} 

import React, { useEffect, useState, useRef, useMemo } from "react";
import { useShow, useNavigation, useMany, useExport, BaseKey, useNotification, useUpdate } from "@refinedev/core";
import { 
    Show, 
    CreateButton,
    EditButton,
    DeleteButton,
    RefreshButton,
    ListButton,
    ShowButton,
    useModalForm
} from "@refinedev/antd";
import { 
    Typography, 
    Avatar, 
    Button, 
    Dropdown, 
    Card, 
    Space, 
    Tag, 
    Tooltip, 
    Form,
    Input,
    Descriptions,
    Badge,
    Divider,
    Alert,
    Upload,
    Modal,
    Select,
    Radio,
    Checkbox,
    SelectProps,
    Spin,
    Tabs,
    message,
    notification,
    Empty
} from "antd";
import { 
    MoreOutlined, 
    LinkOutlined,
    ExportOutlined,
    SyncOutlined,
    InfoCircleOutlined,
    EditOutlined,
    UploadOutlined,
    StarFilled,
    DeleteOutlined,
    PlusOutlined,
    AlertOutlined,
    WarningOutlined,
    SafetyOutlined,
    PictureOutlined,
    DollarOutlined,
    BankOutlined,
    CodeOutlined,
    TeamOutlined,
    WalletOutlined,
    FilterOutlined,
    CheckCircleFilled,
    UserOutlined
} from "@ant-design/icons";
// import { JsonEditor } from "@refinedev/react-json-editor";

import dayjs from "dayjs";
import { ADDRESS_TYPE_COLORS, AddressType, IAddress } from "../../interfaces/address";
import { ENTITY_TYPE_COLORS, EntityType, IEntity } from "../../interfaces/entity.d";
import { CHAIN_MAPPING } from "../../interfaces/chain.d";
import { useConfig } from "contexts/config";
import { IAddressLabel, ITag, ITransaction, IUser } from "interfaces";
import { IUserSelectOption } from "../../interfaces/user.d";
import { Transaction, TransactionTable } from "components/transactions/table";
import { TransactionGraph } from 'components/graph/TransactionGraph';
import { useSearchParams } from "react-router-dom";
import debounce from 'lodash/debounce';
import { AddressTypeTag } from "./components/AddressTypeTag";
import { ContractFields } from "./components/types/ContractFields";
import { PortfolioTab } from "./components/PortfolioTab";
import { DeFiTab } from "./components/DeFiTab";
import { StatsTab } from "./components/StatsTab";
import { NFTsTab } from "./components/NFTsTab";
import { TokensTab } from "./components/TokensTab";
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { CHAIN_IDS, CHAIN_NAMES } from "../../constants/chains";
import { AddressWatcher } from "./components/AddressWatcher";
import { useSubscriptions } from "contexts/subscriptions/useSubscriptions";
import { createDebugger } from "contexts/leader/debug";
import { renderAddressAvatar } from 'components/address-avatar';
import { CHAIN_ID_TO_NAME } from 'constants/chains';
import { isNumber } from "lodash";
import AddressInfoCard from './components/AddressInfoCard';
import AddressInfoButton from './components/AddressInfoButton';
import AddressInfoModal from './components/AddressInfoModal';
const { Title, Text } = Typography;
const { TextArea } = Input;

export interface DebounceSelectPropsAddressShow<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
}

function DebounceSelect<
    ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectPropsAddressShow<ValueType>) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState<ValueType[]>([]);
    const fetchRef = useRef(0);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
}

// Avatar components (similar to EntityShow)
const ChainAvatar = ({ chainId, size = 'small' }: { 
    chainId: string;
    size?: 'small' | 'default' | 'large';
}) => (
    <Tooltip 
        title={CHAIN_MAPPING[chainId] || chainId}
        overlayStyle={{ zIndex: 1100 }}
    >
        <Avatar 
            src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`} 
            size={size}
            style={{ backgroundColor: '#f0f2f5' }}
        />
    </Tooltip>
);

export const AddressShow: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeChains, setActiveChains] = useState<string[]>([]);  
    const { org_id, tenant_id, user_id, user, token, cdn_domain_name, region, continent, domain, qa_environment } = useConfig();

    const { queryResult, } = useShow<IAddress>(
        {
            resource: "addresses",
            id: window.location.pathname.split('/').pop() || '',
            meta: {
                idName: "address",
                query: {
                    chain_ids: searchParams.get('chain_ids'),
                    limit: searchParams.get('limit')
                },
            },
                      
        }
    );
    const { data, isLoading } = queryResult;
    const record = data?.data;
    // Add a ref to track latest record
    const recordRef = useRef<IAddress | undefined>();    
    // Update ref whenever record changes
    useEffect(() => {
        recordRef.current = record;
    }, [record]);

    const [isLoadingActiveChains, setIsLoadingActiveChains] = useState(false);
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);
    const [graphKey, setGraphKey] = useState(0);
    const [isReprocessModalVisible, setIsReprocessModalVisible] = useState(false);
    const [reprocessForm] = Form.useForm();
    const { open } = useNotification();
    // Modal forms for editing
    const {
        modalProps: editLabelsModalProps,
        formProps: editLabelsFormProps,
        show: showEditLabelsModal
    } = useModalForm({
        action: "edit",
        resource: "addresses",
        meta: {
            idName: "address"
        },
        warnWhenUnsavedChanges: true,
        queryOptions: {
            enabled: false // Prevent automatic refetching
        },
        onMutationSuccess: () => {
            queryResult.refetch(); // Only refetch after successful update
        },
        invalidates: ['all']
    });

    const {
        modalProps: editTagsModalProps,
        formProps: editTagsFormProps,
        show: showEditTagsModal
    } = useModalForm({
        action: "edit",
        resource: "addresses",
        id: record?.address,    
        meta: {
            idName: "address"
        },
        warnWhenUnsavedChanges: true,
        queryOptions: {
            enabled: false // Prevent automatic refetching
        },
        onMutationSuccess: () => {
            queryResult.refetch(); // Only refetch after successful update
        },
        invalidates: ['all']
    });

    const {
        modalProps: editSharedWithModalProps,
        formProps: editSharedWithFormProps,
        show: showEditSharedWithModal
    } = useModalForm({
        action: "edit",
        resource: "addresses",
        id: record?.address,    
        meta: {
            idName: "address"
        },
        warnWhenUnsavedChanges: true,
        queryOptions: {
            enabled: false // Prevent automatic refetching
        },
        onMutationSuccess: () => {
            queryResult.refetch(); // Only refetch after successful update
        },
        invalidates: ['all']
    });

    const {
        modalProps: uploadAbiModalProps,
        formProps: uploadAbiFormProps,
        show: showUploadAbiModal,
    } = useModalForm({
        action: record?.abis ? "edit" : "create",
        resource: "abis",
        id: record?.address,    
        meta: {
            idName: "address"
        },
        warnWhenUnsavedChanges: true,
        queryOptions: {
            enabled: false // Changed to false to prevent automatic fetching
        },
        onMutationSuccess: () => {
            queryResult.refetch();
        },
        invalidates: ['all'],
    });

    const {
        modalProps: editNotesModalProps,
        formProps: editNotesFormProps,
        show: showEditNotesModal
    } = useModalForm({
        action: "edit",
        resource: "addresses",
        id: record?.address,    
        meta: {
            idName: "address"
        },
        warnWhenUnsavedChanges: true,
        queryOptions: {
            enabled: false // Prevent automatic refetching
        },
        onMutationSuccess: () => {
            queryResult.refetch(); // Only refetch after successful update
        },
        invalidates: ['all']
    });

    // Fetch related entities
    const { data: entitiesData } = useMany<IEntity>({
        resource: "entities",
        ids: record?.entities?.map(e => e.id) || [],
        queryOptions: {
            enabled: record?.entities !== undefined,
        },
    });

    // Add ABI show query
    const { query: abiQueryResult } = useShow<any>({
        resource: "abis",
        id: Object.values(record?.abis || {})
        .map(chainAbi => (chainAbi as any).abi_id)
        .filter(Boolean)
        .join(","),
        queryOptions: {
            enabled: uploadAbiModalProps.open && Object.values(record?.abis || {}).length > 0,
        },
    });

    // Update form when ABI data is retrieved
    useEffect(() => {
        const abiData = abiQueryResult?.data?.data as any;
        
        if (abiData && abiData.length > 0 && uploadAbiModalProps.open) {
            // Only set form values if the form is empty or being initialized
            const currentValues = uploadAbiFormProps.form?.getFieldsValue() as any;
            if (!currentValues?.name && !currentValues?.description) {
                // Take the first ABI entry as our base
                const firstAbi = abiData[0];
                uploadAbiFormProps.form?.setFieldsValue({
                    address: record?.address,
                    name: firstAbi.name || '',
                    description: firstAbi.description || '',
                    abi_entries: abiData.map((abiEntry: any) => ({
                        chainIds: abiEntry.chain_ids || [],
                        abi: abiEntry.abi || '',
                        id: abiEntry.id
                    })) || [{ chainIds: [], abi: '', id: '' }]
                });
            }
        }
    }, [abiQueryResult?.data, uploadAbiModalProps.open]);

    const refreshActiveChains = async () => {
        setIsLoadingActiveChains(true);
        try {
            // Your existing refresh logic here
            // ...
        } catch (error) {
            console.error("Error refreshing active chains:", error);
        } finally {
            setIsLoadingActiveChains(false);
        }
    };

    // Helper functions that prioritize main object and use address_items_map as fallback
    const getAllLabels = useMemo(() => {
        if (!record?.address_items_map) return [];
        
        const allLabels: any[] = [];
        
        // Go through each address in address_items_map
        Object.entries(record.address_items_map).forEach(([address, item]: [string, any]) => {
            // Go through each label for this address
            item.labels?.forEach((label: any) => {
                allLabels.push({
                    ...label,
                    address
                });
            });
        });
        
        return allLabels;
    }, [record]);

    const getAllTags = () => {
        // If main object has tags, use those
        if (record?.tags && record.tags.length > 0) {
            return record.tags;
        }
        
        // Otherwise, get tags for the specific address from address_items_map
        const addressItem = record?.address_items_map?.[record?.address];
        return addressItem?.tags || [];
    };

    const getAllEntitiesForAddress = (address: string) => {
        // If main object has entities, use those
        if (record?.entities && record.entities.length > 0) {
            const entities = [...record.entities];
            
            // Look for temp entities with logos in transactions
            if (record?.transactions) {
                record.transactions.forEach(tx => {
                    const metadata = tx.from_address === address ? tx.from_metadata : tx.to_metadata;
                    metadata?.entities?.forEach((tempEntity: any) => {
                        if (tempEntity.id?.startsWith('temp_entity_')) {
                            // Find matching entity by name
                            const matchingEntity = entities.find(e => e.name === tempEntity.name);
                            if (matchingEntity && !matchingEntity.logo && tempEntity.logo) {
                                // Update the entity with the logo from temp entity
                                matchingEntity.logo = tempEntity.logo;
                                // Also update color if not present
                                if (!matchingEntity.color && tempEntity.color) {
                                    matchingEntity.color = tempEntity.color;
                                }
                            }
                        }
                    });
                });
            }
            
            // only return entities that are not string (ids) else go through address_items_map
            const hasEntities = entities.some((entity: any) => typeof entity === 'object');
            if (!hasEntities) {
                const addressItem = record?.address_items_map?.[address];
                return addressItem?.entities || [];
            } 
        }
        
        // Otherwise, get the address from address_items_map
        const addressItem = record?.address_items_map?.[address];
        const entities = addressItem?.entities || [];
        
        // Look for temp entities with logos in transactions
        if (record?.transactions) {
            record.transactions.forEach(tx => {
                const metadata = tx.from_address === address ? tx.from_metadata : tx.to_metadata;
                metadata?.entities?.forEach((tempEntity: any) => {
                    if (tempEntity.id?.startsWith('temp_entity_')) {
                        // Find matching entity by name
                        const matchingEntity = entities.find(e => e.name === tempEntity.name);
                        if (matchingEntity && !matchingEntity.logo && tempEntity.logo) {
                            // Update the entity with the logo from temp entity
                            matchingEntity.logo = tempEntity.logo;
                            // Also update color if not present
                            if (!matchingEntity.color && tempEntity.color) {
                                matchingEntity.color = tempEntity.color;
                            }
                        }
                    }
                });
            });
        }
        
        return entities;
    };

    const getAllSharedWith = () => {
        // If main object has shared_with, use those
        if (record?.shared_with && record.shared_with.length > 0) {
            return record.shared_with;
        }
        
        // Otherwise, get from address_items_map
        return Object.values(record?.address_items_map || {})
            .flatMap(item => item.shared_with || [])
    };

    const getMainLabels = () => {
        // If main object has main_labels, use those
        if (record?.main_labels && Object.keys(record.main_labels).length > 0) {
            return record.main_labels;
        }
        
        // Otherwise, get from address_items_map
        const firstItemWithMainLabels = Object.values(record?.address_items_map || {})
            .find(item => item.main_labels && Object.keys(item.main_labels).length > 0);
            
        return firstItemWithMainLabels?.main_labels || {};
    };

    const reprocessAddressTransactions = async (values: any) => {
        // Implementation of reprocessAddressTransactions
    };

    const fetchUserList = async (search: string): Promise<IUserSelectOption[]> => {
        try {
            const requestHeaders = {
                Authorization: `Bearer ${token.__raw}`,
                Accept: "application/json, text/plain, */*",
                "Source-Platform": "dashboard",
                "Source-Region": region.use_region,
                "Destination-Region": region.use_region,
            };

            const apiUrl = `https://${region.use_region_alias}.${continent?.toLowerCase()}.api.${domain}`;
            let url;
            
            if (qa_environment === "blue") {
                url = `${apiUrl}/management/permissions/blue/GET/${continent?.toUpperCase()}/v0/users`;
            } else {
                url = `${apiUrl}/management/permissions/GET/${continent?.toUpperCase()}/v0/users`;
            }

            const response = await fetch(url, { headers: requestHeaders });
            const data = await response.json();
            
            return data.result
                .filter((user: IUser) => user.status !== "disabled" && user.status !== "pending_invitation")
                .map((user: IUser): IUserSelectOption => ({
                    label: user.name ? `${user.name} (${user.email})` : user.email,
                    value: user.id,
                    status: user.status,
                    roles: user.roles
                }));
        } catch (error) {
            console.error('Error fetching users:', error);
            return [];
        }
    };

    // Update transactions when record changes
    useEffect(() => {
        if (record?.transactions) {
            const processedTransactions = record.transactions.map(tx => ({
                ...tx,
                // Ensure all required fields are present
                address: record.address,
                // Add any computed fields needed for display
                formattedTimestamp: dayjs(tx.block_timestamp).format('YYYY-MM-DD HH:mm:ss'),
                // Add any address labels from address_items_map
                address_items_map: record.address_items_map
            }));
            setTransactions(processedTransactions);
            setFilteredTransactions(processedTransactions); // Initially set filtered to all transactions
        }
    }, [record]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    // Helper function to check for duplicate chain selections
    const validateChains = async (_: any, abiEntries: any[]) => {
        const chainMap = new Map<string, number>();
        
        abiEntries?.forEach((entry: any, index: number) => {
            entry.chainIds?.forEach((chainId: string) => {
                if (chainMap.has(chainId)) {
                    open?.({
                        message: `Chain ${CHAIN_MAPPING[chainId] || chainId} is already selected in ABI #${chainMap.get(chainId)! + 1}`,
                        type: 'error'
                    })
                    throw new Error(
                        `Chain ${CHAIN_MAPPING[chainId] || chainId} is already selected in ABI #${chainMap.get(chainId)! + 1}`
                    );
                }
                chainMap.set(chainId, index);
            });
        });
    };

    const { data: watchData, mutate: updateWatch, isLoading: loadingWatch } = useUpdate();
    
    const handleWatchToggle = async (watching: boolean) => {
        setIsWatching(watching);
        setWatchStatus(watching ? 'watching' : 'unwatched');
        try {
            await updateWatch({
                resource: "watches",
                id: window.location.pathname.split('/').pop() || '',
                values: [{
                    webhookUrl: `https://${region.use_region_alias}.${continent?.toLowerCase()}.internal.${domain}/internal/moralis/ANY/${continent?.toUpperCase()}/v0/streams/tracers`,
                    chain_ids: ["0x1"],
                    address: record?.address || '',
                    address_id: record?.id || '',
                    name: record?.address || 'Address Watch',
                    description: '',
                    region: region.use_region,
                    mempool: {
                        include_blocknative: false
                    },
                    include_moralis: true,
                    status: watching ? "watching" : "unwatched"
                }],
                meta: {
                    operation: watching ? "create" : "delete" // Use create for watching, delete for unwatching
                }
            }, {
                onSuccess: (response: any) => {
                    if (response?.data?.data == "success") {
                        // Keep the watching state as is
                        setWatchStatus(watching ? 'watching' : 'unwatched');
                    } else {
                        // Revert the states if the operation failed
                        setIsWatching(!watching);
                        setWatchStatus(!watching ? 'watching' : 'unwatched');
                        throw new Error('Watch operation failed');
                    }
                },
                onError: (error) => {
                    console.error('Error updating watch:', error);
                    setIsWatching(!watching);
                    setWatchStatus(!watching ? 'watching' : 'unwatched');
                    open?.({
                        type: 'error',
                        message: 'Failed to update watch status',
                        description: error instanceof Error ? error.message : 'Unknown error occurred'
                    });
                }
            });
        } catch (error) {
            console.error('Error updating watch:', error);
            setIsWatching(!watching);
            setWatchStatus(!watching ? 'watching' : 'unwatched');
        }
    };

    const [isWatching, setIsWatching] = useState(record?.watch_status == "watching" || false);
    const [watchStatus, setWatchStatus] = useState(record?.watch_status ? record?.watch_status : 'unwatched');
    useEffect(() => {
        setIsWatching(record?.watch_status == "watching" || false);
        setWatchStatus(record?.watch_status ? record?.watch_status : 'unwatched');
    }, [record?.watch_status]);
    
    const { status, isLeader, controls} = useSubscriptions({
        channels: [
            {
                name: `${org_id}-${tenant_id}-${user_id}-streamed-transactions`,
                onMessage: (message) => {
                    // Broadcast messages have a channel property
                    if (message.data?.channel) {
                        message = message.data;
                    }   
                                        
                    const transaction = message.data;
                    notification.open({
                        message: 'New transaction',
                        description: `New transaction: ${transaction.hash}`
                    });
                }
            },
            // You can add more channels here
            {
                name: `${org_id}-${tenant_id}-${user_id}-address-watch`,
                onMessage: (message) => {
                    // Skip if record is not loaded yet
                    console.log("rewweeeweecord", recordRef.current);

                    // Handle address updates
                    // Broadcast messages have a channel property
                    if (message.data?.channel) {
                        message = message.data;
                    }  

                    console.log('Address update:', message.data);
                    console.log("message.data?.address", message.data?.address);
                    console.log("recordRef.current?.address", recordRef.current?.address);
                    
                    if (message.data?.address === recordRef.current?.address) {
                        notification.open({
                            message: 'Address update',
                            description: `Address update: ${message.data?.address} to ${message.data?.watch_status}`
                        });
                        
                        // Update the watching state based on the message status
                        const newWatchStatus = message.data?.watch_status;
                        if (newWatchStatus) {
                            setIsWatching(newWatchStatus === 'watching');   
                            setWatchStatus(newWatchStatus);
                        }
                    }
                }
            }
        ],
        token: token.__raw,
        orgId: org_id,
        tenantId: tenant_id,
        userId: user_id,
        debug: createDebugger({
            prefix: 'Subscriptions',
            tabId: `Tab-${Math.random().toString(36).substr(2, 5)}`
        }),
        // Optional global message handler
        onMessage: (message: any) => {
            console.log(`Message from ${message.channel}:`, message.data);
        },
        // Optional error handler
        onError: (error: any) => {
            console.error('Subscription error:', error);
        }
    });

    const [selectedChains, setSelectedChains] = useState<string[]>([]);
    
    // Extract main labels from address_items_map
    const mainLabelsGroupedByChain = useMemo(() => {
        if (!record?.address_items_map) return [];
        
        const mainLabels: any[] = [];
        
        // Go through each address in address_items_map
        Object.entries(record.address_items_map).forEach(([address, item]: [string, any]) => {
            // Go through each label for this address
            item.labels?.forEach((label: any) => {
                // Check if this label has main_label_chain_ids
                if (label.main_label_chain_ids && label.main_label_chain_ids.length > 0) {
                    // Create a main label entry with chains
                    mainLabels.push({
                        ...label,
                        address,
                        chains: label.main_label_chain_ids
                    });
                }
            });
        });
        
        return mainLabels;
    }, [record]);
    
    // Get unique chains from all labels
    const availableChains = useMemo(() => {
        const chains = new Set<string>();
        
        // Add chains from all labels
        getAllLabels.forEach((label: any) => {
            (label.chain_ids || []).forEach((chainId: string) => chains.add(chainId));
        });
        
        return Array.from(chains);
    }, [getAllLabels]);
    
    // Filter main labels by selected chains
    const filteredMainLabels = useMemo(() => {
        if (!selectedChains.length) {
            return mainLabelsGroupedByChain;
        }
        
        return mainLabelsGroupedByChain.filter((label: any) => 
            label.chains.some((chainId: string) => selectedChains.includes(chainId))
        );
    }, [mainLabelsGroupedByChain, selectedChains]);
    
    // Filter all labels by selected chains
    const filteredAllLabels = useMemo(() => {
        if (!selectedChains.length) {
            return getAllLabels;
        }
        
        return getAllLabels.filter((label: any) => 
            !label.chain_ids || 
            label.chain_ids.length === 0 || 
            label.chain_ids.some((chainId: string) => selectedChains.includes(chainId))
        );
    }, [getAllLabels, selectedChains]);

    // Add this state for the modal
    const [addressInfoModalVisible, setAddressInfoModalVisible] = useState(false);
    
    // Add this function to handle viewing a contract
    const handleViewContract = (address: string, chainId: string) => {
        // Navigate to contract viewer or open in a new tab
        window.open(`/contracts/${chainId}/${address}`, '_blank');
    };

    return (
        <Show
            isLoading={isLoading}
            headerButtons={({ refreshButtonProps }) => (
                <Space>
                    <AddressWatcher
                        address={record?.address || ''}
                        isWatching={isWatching}
                        watchStatus={watchStatus as 'unwatched' | 'processing' | 'watching' | 'error'}
                        onWatchToggle={handleWatchToggle}
                        isLoadingToggleUpdate={loadingWatch || isLoading}
                    />                
                    <EditButton />
                    <RefreshButton {...refreshButtonProps} />
                    <ListButton />
                    <DeleteButton />
                    <Button 
                        icon={<UploadOutlined />}
                        onClick={() => {
                            showUploadAbiModal(record?.address);
                        }}
                        resource="abis"
                        id={record?.address}
                        disabled={queryResult.isLoading}
                    >
                        Upload ABI
                    </Button>
                    
                    {/* Add this button to show the address info modal */}
                    <Button
                        icon={<InfoCircleOutlined />}
                        onClick={() => setAddressInfoModalVisible(true)}
                        disabled={queryResult.isLoading}
                    >
                        Address Info
                    </Button>
                </Space>
            )}
        >
            <Tabs
                items={[
                    {
                        label: "Details",
                        key: "details",
                        children: (
                            <>
                                {/* Add the AddressInfoCard at the top of the details tab */}
                                <Card style={{ marginBottom: 16 }}>
                                    <AddressInfoCard 
                                        addressData={record} 
                                        showDetailedView={false}
                                        onViewContract={() => setAddressInfoModalVisible(true)}
                                        address_items_map={record?.address_items_map || {}}
                                        apiDomain={domain}
                                        region={region.use_region}
                                        regionAlias={region.use_region_alias}
                                        token={token.__raw}
                                    />
                                </Card>
                                
                                {/* Keep your existing card */}
                                <Card>
                                    <Descriptions
                                        title={
                                            <Space size="middle" align="center">
                                                <Typography.Title level={4} style={{ margin: 0 }}>
                                                    Address Details
                                                </Typography.Title>
                                                <Tag color={record?.status === 'active' ? 'success' : 'warning'}>
                                                    {record?.status?.toUpperCase()}
                                                </Tag>
                                            </Space>
                                        }     
                                        bordered 
                                        column={2}                        
                                    >
                                        <Descriptions.Item label="Address">
                                            <Text copyable>{record?.address}</Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Type">
                                            <AddressTypeTag type={record?.type as AddressType} record={record as IAddress} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Notes" span={2}>
                                            {record?.notes || '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Shared With" span={2}>
                                            <Space wrap>
                                                {getAllSharedWith().map((user: any) => (
                                                    <Tag key={user?.id}>
                                                        {user?.email}
                                                    </Tag>
                                                ))}
                                                <Button 
                                                    type="link" 
                                                    icon={<EditOutlined />}
                                                    onClick={() => showEditSharedWithModal(record?.address)}
                                                >
                                                    Edit Sharing
                                                </Button>
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Risk Score">
                                            <Space>
                                                    <Badge 
                                                        count={
                                                            <Tag style={{ borderRadius: '10px' }} color={
                                                                record?.risk_score && record.risk_score >= 8 ? 'red' :
                                                                record?.risk_score && record.risk_score >= 5 ? 'orange' :
                                                                record?.risk_score && record.risk_score >= 1 ? 'green' : 
                                                                'default'
                                                            }>
                                                                {isNumber(record?.risk_score) ? record?.risk_score?.toFixed(1) : 'N/A'}
                                                            </Tag>
                                                        }
                                                    />
                                                    <Tooltip title={
                                                        <div>
                                                            <div>Risk Level: {
                                                                record?.risk_score && record.risk_score >= 8 ? 'High Risk' :
                                                                record?.risk_score && record.risk_score >= 5 ? 'Medium Risk' :
                                                                record?.risk_score && record.risk_score >= 1 ? 'Low Risk' :
                                                                'Unknown'
                                                            }</div>
                                                            <div>Score: {isNumber(record?.risk_score) ? record?.risk_score?.toFixed(1) : 'N/A'}/10</div>
                                                        </div>
                                                    }>
                                                        <Tag icon={
                                                            record?.risk_score && record.risk_score >= 8 ? <AlertOutlined style={{ color: '#ff4d4f' }} /> :
                                                            record?.risk_score && record.risk_score >= 5 ? <WarningOutlined style={{ color: '#faad14' }} /> :
                                                            <SafetyOutlined style={{ color: '#52c41a' }} />
                                                        } color={
                                                    record?.risk_score && record.risk_score >= 8 ? 'error' :
                                                    record?.risk_score && record.risk_score >= 5 ? 'warning' :
                                                    'success'
                                                }>
                                                    {   
                                                        record?.risk_score && record.risk_score >= 8 ? 'High Risk' :
                                                        record?.risk_score && record.risk_score >= 5 ? 'Medium Risk' :
                                                        record?.risk_score && record.risk_score >= 1 ? 'Low Risk' :
                                                        'Unknown'
                                                    }
                                                </Tag>
                                                </Tooltip>
                                                <InfoCircleOutlined 
                                                    style={{ color: '#1890ff', cursor: 'pointer' }} 
                                                />
                                            </Space>
                                        </Descriptions.Item>
                                        {/* Render appropriate fields based on type */}
                                        {record?.type?.includes('contract') && <ContractFields/>}
                                        {record?.contract_symbol && (
                                            <Descriptions.Item label="Symbol">
                                                <Tag color="blue">{record.contract_symbol}</Tag>
                                            </Descriptions.Item>
                                        )}                                        
                                        {/* {record?.type === 'nft_contract' && <NFTContractFields record={record} />} */}
                                        {/* ... other conditional renders ... */}                    
                                    </Descriptions>
                                </Card>
                            </>
                        ),
                    },
                    {
                        label: "Portfolio",
                        key: "portfolio",
                        children: <PortfolioTab record={record as Pick<IAddress, "type" | "holdings" | "active_chain_ids" | "address">} />,
                    },
                    {
                        label: "DeFi",
                        key: "defi",
                        children: <DeFiTab record={record as IAddress} />,
                    },
                    {
                        label: "Stats",
                        key: "stats",
                        children: <StatsTab record={record as IAddress} />,
                    },  
                    {
                        label: "NFTs",
                        key: "nfts",
                        children: <NFTsTab record={record as IAddress} />,
                    },
                    {
                        label: "Tokens",
                        key: "tokens",
                        children: <TokensTab record={record as IAddress} />,
                    },
                ]}
            />

            {/* Add Edit Notes Button */}
            <Button 
                icon={<EditOutlined />}
                onClick={() => showEditNotesModal(record?.address)}
                style={{ marginTop: 16 }}
            >
                Edit Notes
            </Button>

            {/* Active Chains Card */}
            <Card 
                title="Active Chains"
                style={{ marginTop: 16 }}
                extra={
                    <Button 
                        icon={<SyncOutlined spin={isLoadingActiveChains} />}
                        onClick={refreshActiveChains}
                        loading={isLoadingActiveChains}
                    >
                        Refresh Active Chains
                    </Button>
                }
            >
                <Space wrap>
                    {record?.active_chain_ids?.map((chainId) => (
                        <Tooltip 
                            key={chainId}
                            title={CHAIN_MAPPING[chainId] || chainId}
                        >
                            <ChainAvatar chainId={chainId} size="large" />
                        </Tooltip>
                    ))}
                </Space>
                {isLoadingActiveChains && (
                    <Alert
                        message="Checking chains for activity"
                        description="This operation checks all available chains to detect where this address has been active. This may take a few moments..."
                        type="info"
                        showIcon
                        style={{ marginTop: 16 }}
                    />
                )}
            </Card>

            {/* Labels Card */}
            <Card 
                title={
                    <Space>
                        Labels
                        <Button 
                            icon={<EditOutlined />} 
                            type="link" 
                            onClick={() => showEditLabelsModal(record?.address)}
                        />
                    </Space>
                } 
                style={{ marginTop: 16 }}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    {/* Chain Filter */}
                    <Space align="center" style={{ marginBottom: 16 }}>
                        <FilterOutlined />
                        <span>Filter by chain:</span>
                        <Select
                            mode="multiple"
                            placeholder="Select chains"
                            value={selectedChains}
                            onChange={setSelectedChains}
                            style={{ minWidth: 200 }}
                            optionLabelProp="label"
                            allowClear
                        >
                            {availableChains.map(chainId => (
                                <Select.Option key={chainId} value={chainId} label={CHAIN_ID_TO_NAME[chainId] || chainId}>
                                    <Space>
                                        <ChainAvatar chainId={chainId} size="small" />
                                        <span>{CHAIN_ID_TO_NAME[chainId] || chainId}</span>
                                    </Space>
                                </Select.Option>
                            ))}
                        </Select>
                        {selectedChains.length > 0 && (
                            <Button type="link" onClick={() => setSelectedChains([])}>
                                Clear filters
                            </Button>
                        )}
                    </Space>
                    
                    {/* Main Labels by Chain */}
                    <Title level={5}>Main Labels by Chain</Title>
                    {filteredMainLabels.length > 0 ? (
                        <Space wrap>
                            {filteredMainLabels.map((label: any) => (
                                <Space key={`${label.id}_${label.address}`}>
                                    {label.chains.map((chainId: string) => (
                                        <Tooltip 
                                            key={chainId}
                                            title={`Main label on ${CHAIN_ID_TO_NAME[chainId] || chainId}`}
                                        >
                                            <ChainAvatar chainId={chainId} size="small" />
                                        </Tooltip>
                                    ))}
                                    <Tooltip
                                        title={
                                            <div>
                                                <div>Address: {label.address}</div>
                                                <div>Source: {label.source === 'user' ? 'User created' : 'Verified by 3rd party'}</div>
                                                {label.chains && label.chains.length > 0 && (
                                                    <div>
                                                        Main label on: {label.chains
                                                            .map((chainId: string) => CHAIN_ID_TO_NAME[chainId] || chainId)
                                                            .join(', ')}
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    >
                                        <Tag 
                                            color={`#${label.color}`}
                                            style={{ 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                gap: 4 
                                            }}
                                        >
                                            {label.logo && (
                                                <Avatar 
                                                    src={label.logo} 
                                                    size="small"
                                                    style={{ width: 16, height: 16, flexShrink: 0 }}
                                                />
                                            )}
                                            {label.name || label.address_name}
                                            <StarFilled style={{ color: '#faad14' }} />
                                        </Tag>
                                    </Tooltip>
                                </Space>
                            ))}
                        </Space>
                    ) : (
                        <Empty 
                            image={Empty.PRESENTED_IMAGE_SIMPLE} 
                            description={selectedChains.length > 0 ? "No main labels for selected chains" : "No main labels found"} 
                        />
                    )}

                    <Divider />

                    {/* All Labels */}
                    <Title level={5}>All Labels</Title>
                    {filteredAllLabels.length > 0 ? (
                        <Space wrap>
                            {filteredAllLabels
                                .sort((a: any, b: any) => (a.address_name || a.name)?.localeCompare(b.address_name || b.name))
                                .map((label: any) => {
                                    const isMainLabel = label.main_label_chain_ids && label.main_label_chain_ids.length > 0;
                                    
                                    return (
                                        <Tooltip
                                            key={label.id}
                                            title={
                                                <div>
                                                    <div>Address: {label.address}</div>
                                                    <div>Source: {label.source === 'user' ? 'User created' : 'Verified by 3rd party'}</div>
                                                    {label.chain_ids && label.chain_ids.length > 0 && (
                                                        <div>
                                                            Active on: {label.chain_ids
                                                                .map((chainId: string) => CHAIN_ID_TO_NAME[chainId] || chainId)
                                                                .join(', ')}
                                                        </div>
                                                    )}
                                                    {isMainLabel && (
                                                        <div>
                                                            Main label on: {label.main_label_chain_ids
                                                                .map((chainId: string) => CHAIN_ID_TO_NAME[chainId] || chainId)
                                                                .join(', ')}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        >
                                            <Tag 
                                                color={`#${label.color}`}
                                                style={{ 
                                                    display: 'flex', 
                                                    alignItems: 'center', 
                                                    gap: 4 
                                                }}
                                            >
                                                {label.logo && (
                                                    <Avatar 
                                                        src={label.logo} 
                                                        size="small"
                                                        style={{ width: 16, height: 16, flexShrink: 0 }}
                                                    />
                                                )}
                                                {label.name || label.address_name}
                                                {isMainLabel && <StarFilled style={{ color: '#faad14', marginLeft: 4 }} />}
                                            </Tag>
                                        </Tooltip>
                                    );
                                })}
                        </Space>
                    ) : (
                        <Empty 
                            image={Empty.PRESENTED_IMAGE_SIMPLE} 
                            description={selectedChains.length > 0 ? "No labels for selected chains" : "No labels found"} 
                        />
                    )}
                </Space>
            </Card>

            {/* Tags Card */}
            <Card 
                title={
                    <Space>
                        Tags
                        <Button 
                            icon={<EditOutlined />} 
                            type="link" 
                            onClick={() => showEditTagsModal(record?.address)}
                        />
                    </Space>
                } 
                style={{ marginTop: 16 }}
            >
                <Space wrap>
                    {getAllTags()?.map((tag: ITag) => (
                        <Tag 
                            key={tag.name}
                            color={`#${tag.color}`}
                        >
                            {tag.name}
                        </Tag>
                    ))}
                </Space>
            </Card>

            {/* Entities Card */}
            <Card 
                title={
                    <Space>
                        Associated Entities
                        <Button 
                            icon={<EditOutlined />} 
                            type="link" 
                            onClick={() => console.log(record?.address)}
                        />
                    </Space>
                }
                style={{ marginTop: 16 }}
            >
                {getAllEntitiesForAddress(record?.address || '')?.map((entity: IEntity) => {
                    // Filter by selected chains if any are selected
                    const isVisible = !selectedChains.length || 
                        !entity.chain_ids || 
                        entity.chain_ids.length === 0 || 
                        entity.chain_ids.some((chainId: string) => selectedChains.includes(chainId));
                    
                    if (!isVisible) return null;
                    
                    return (
                        <Card 
                            key={entity.id}
                            type="inner" 
                            title={
                                <Space>
                                    <Avatar 
                                        size="small"
                                        style={{ 
                                            backgroundColor: entity.color ? `#${entity.color}` : undefined 
                                        }}
                                        src={entity?.logo}
                                    >
                                        {entity?.name?.[0]?.toUpperCase()}
                                    </Avatar>
                                    <Tag color={entity?.color ? `#${entity?.color}` : undefined}>
                                        {entity?.name}
                                    </Tag>
                                </Space>
                            }
                            extra={
                                <Space>
                                    <ShowButton 
                                        hideText
                                        size="small"
                                        recordItemId={entity.id}
                                        resource="entities"
                                    />
                                    <DeleteButton
                                        hideText
                                        size="small"
                                        recordItemId={entity.id}
                                        resource="entities"
                                        meta={{
                                            action: "removeAddresses",
                                            operation: "removeAddresses",
                                            values: {
                                                entity_id: entity.id,
                                                addresses: [record?.address]
                                            }
                                        }}
                                        successNotification={{
                                            message: "Address removed from entity successfully",
                                            type: "success"
                                        }}
                                        errorNotification={{
                                            message: "Failed to remove address from entity",
                                            type: "error"
                                        }}
                                    />
                                </Space>
                            }
                            style={{ marginBottom: 16 }}
                        >
                            <Descriptions column={2}>
                                <Descriptions.Item label="Type">
                                    <Tag color={ENTITY_TYPE_COLORS[entity.type as EntityType]}>
                                        {entity.type || 'Unknown'}
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="Status">
                                    <Tag color={entity.status === 'active' ? 'success' : 'warning'}>
                                        {entity.status || 'Unknown'}
                                    </Tag>
                                </Descriptions.Item>
                            </Descriptions>
                            
                            <Divider style={{ margin: '12px 0' }} />
                            
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <Title level={5} style={{ margin: '0 0 8px 0' }}>Active on Chains</Title>
                                <Space wrap>
                                    {entity.chain_ids?.map((chainId: string) => {
                                        const isMainChain = entity.main_chain_ids?.includes(chainId);
                                        
                                        return (
                                            <Tooltip 
                                                key={chainId}
                                                title={
                                                    <>
                                                        {CHAIN_ID_TO_NAME[chainId] || chainId}
                                                        {isMainChain && (
                                                            <div style={{ color: '#52c41a', marginTop: 4 }}>
                                                                Main entity on this chain
                                                            </div>
                                                        )}
                                                    </>
                                                }
                                            >
                                                <Tag 
                                                    style={{ 
                                                        display: 'flex', 
                                                        alignItems: 'center', 
                                                        gap: 4,
                                                        margin: '0 8px 8px 0',
                                                        border: isMainChain ? '1px solid #52c41a' : undefined,
                                                        background: isMainChain ? '#f6ffed' : undefined
                                                    }}
                                                >
                                                    <ChainAvatar chainId={chainId} size="small" />
                                                    <span>{CHAIN_ID_TO_NAME[chainId] || chainId}</span>
                                                    {isMainChain && <StarFilled style={{ color: '#52c41a' }} />}
                                                </Tag>
                                            </Tooltip>
                                        );
                                    })}
                                    {(!entity.chain_ids || entity.chain_ids.length === 0) && (
                                        <Empty 
                                            image={Empty.PRESENTED_IMAGE_SIMPLE} 
                                            description="No chains specified" 
                                            style={{ margin: 0 }}
                                        />
                                    )}
                                </Space>
                            </Space>
                        </Card>
                    );
                })}
                {!getAllEntitiesForAddress(record?.address || '')?.length && (
                    <Empty 
                        image={Empty.PRESENTED_IMAGE_SIMPLE} 
                        description="No entities associated with this address" 
                    />
                )}
            </Card>

            {/* Edit Labels Modal */}
            <Modal
                {...editLabelsModalProps}
                title="Edit Labels"
                width={800}
                centered
                afterOpenChange={() => {
                    // Reset form with current record values when modal opens
                    editLabelsFormProps.form?.setFieldsValue({
                        labels: record?.labels?.map((label: IAddressLabel) => ({
                            label: label.address_name,
                            value: label.id,
                            chain_ids: label.chain_ids || [],
                            main_label_chain_ids: label.main_label_chain_ids || [],
                            logo: label.logo ? [
                                {
                                    uid: '-1',
                                    name: 'logo',
                                    status: 'done',
                                    url: label.logo,
                                },
                            ] : [],
                        })) || []
                    });
                }}                
            >
                <Alert
                    message="Label Management"
                    description="Labels help categorize this address. First select the chains where the label applies, then mark which labels are main identifiers for specific chains."
                    type="info"
                    showIcon
                    style={{ marginBottom: 16 }}
                />
                <Form 
                    {...editLabelsFormProps}
                    initialValues={{
                        labels: getAllLabels?.map((label: any) => ({
                            label: label.address_name,
                            value: label.id,
                            chain_ids: label.chain_ids || [],
                            main_label_chain_ids: label.main_label_chain_ids || [],
                            logo: label.logo ? [
                                {
                                    uid: '-1',
                                    name: 'logo',
                                    status: 'done',
                                    url: label.logo,
                                },
                            ] : [],
                        })).sort((a: any, b: any) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase())) || []
                    }}
                >
                    <Form.List name="labels">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div key={key} style={{ marginBottom: 16, border: '1px solid #f0f0f0', padding: 16, borderRadius: 8 }}>
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                label="Label"
                                            >
                                                <Select
                                                    disabled={!!editLabelsFormProps.form?.getFieldValue(['labels', name, 'value'])}
                                                    mode="tags"
                                                    showSearch
                                                    style={{ width: '100%' }}
                                                    placeholder="Select or enter a label"
                                                    options={editLabelsFormProps.form?.getFieldValue(['labels', name, 'value']) 
                                                            ? getAllLabels?.map((label: any) => ({
                                                                label: label.address_name,
                                                                value: label.id,
                                                                icon: label.logo,
                                                                color: label.color
                                                        }))
                                                        : []
                                                    }                                                    
                                                    filterOption={(input, option) =>
                                                        (option?.label?.toString() || '').toLowerCase().includes(input?.toLowerCase() || '')
                                                    }
                                                    tagRender={({ label, value, closable, onClose }) => {
                                                        // If it's a new label (no options shown), only keep the last value
                                                        if (!editLabelsFormProps.form?.getFieldValue(['labels', name, 'value'])) {
                                                            const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
                                                            editLabelsFormProps.form?.setFieldValue(['labels', name, 'value'], [lastValue]);
                                                        } else {
                                                            // For existing labels, allow normal behavior
                                                            editLabelsFormProps.form?.setFieldValue(['labels', name, 'value'], value);
                                                        }                                                        
                                                        const labelObj = getAllLabels?.find((l: any) => l.id === value);
                                                        return (
                                                            <Tag 
                                                                closable={closable} 
                                                                onClose={onClose} 
                                                                style={{ marginRight: 3 }}
                                                                color={labelObj?.color ? `#${labelObj.color}` : undefined}
                                                            >
                                                                {label}
                                                            </Tag>
                                                        );
                                                    }}                                                
                                                />
                                                                                
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'logo']}
                                                label="Label Image"
                                            >
                                                <Upload.Dragger
                                                    name="file"
                                                    action="https://us.api.sandbox.authe.io/private/labels/blue/ANY/US/v0/labels/upload-image"
                                                    headers={{
                                                        Authorization: `Bearer ${token.__raw}`,
                                                        Accept: "application/json, text/plain, */*",
                                                        "Source-Platform": "dashboard",
                                                        "Source-Region": region.use_region,
                                                        "Destination-Region": region.use_region,
                                                        "filename": "testhere.png"
                                                    }}
                                                    listType="picture"
                                                    maxCount={1}
                                                    onChange={(info) => {
                                                        if (info.file.status === 'done') {
                                                            // Get the URL from the response
                                                            const imageUrl = info.file.response.url;
                                                            // Set it to the logo field
                                                            editLabelsFormProps.form?.setFieldValue(
                                                                ['labels', name, 'logo'],
                                                                imageUrl
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <p className="ant-upload-text">
                                                        Drag & drop a label image here
                                                    </p>
                                                </Upload.Dragger>
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'chain_ids']}
                                                label="Applied on Chains"
                                            >
                                                <Select
                                                    mode="multiple"
                                                    style={{ width: '100%' }}
                                                    placeholder="Select chains where this label applies"
                                                    options={Object.entries(CHAIN_MAPPING).map(([chain_id, name]) => ({
                                                        label: <Space><ChainAvatar chainId={chain_id} size="small" /> {name}</Space>,
                                                        value: chain_id
                                                    }))}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'main_label_chain_ids']}
                                                label="Main Label on Chains"
                                                dependencies={['chain_ids']}
                                                rules={[
                                                    {
                                                        validator: async (_, value) => {
                                                            if (!value) return Promise.resolve();
                                                            
                                                            // Get all form values with proper typing
                                                            const allValues = editLabelsFormProps.form?.getFieldsValue() as {
                                                                labels: Array<{
                                                                    address_name: string;
                                                                    chain_ids: string[];
                                                                    main_label_chain_ids: string[];
                                                                }>;
                                                            };

                                                            if (!allValues?.labels) return Promise.resolve();

                                                            // Get main_label_chain_ids from other labels
                                                            const otherLabelsChainIds = allValues.labels
                                                                .filter((_: any, index: number) => index !== name)
                                                                .flatMap((label) => label.main_label_chain_ids || []);

                                                            // Check for duplicates
                                                            const duplicateChains = value.filter((chainId: string) => 
                                                                otherLabelsChainIds.includes(chainId)
                                                            );

                                                            if (duplicateChains.length > 0) {
                                                                const chainNames = duplicateChains
                                                                    .map((chainId: string) => CHAIN_MAPPING[chainId])
                                                                    .join(', ');
                                                                throw new Error(
                                                                    `Chain(s) ${chainNames} already selected as main label in another label`
                                                                );
                                                            }

                                                            // Get current label's chain_ids
                                                            const currentLabel = allValues.labels[name];
                                                            const selectedChains = currentLabel.chain_ids || [];

                                                            // Check if all main label chains are included in chain_ids
                                                            const missingChains = value.filter(
                                                                (chainId: string) => !selectedChains.includes(chainId)
                                                            );

                                                            if (missingChains.length > 0) {
                                                                const chainNames = missingChains
                                                                    .map((chainId: string) => CHAIN_MAPPING[chainId])
                                                                    .join(', ');
                                                                throw new Error(
                                                                    `Main chain(s) ${chainNames} must also be selected as chains where this label applies`
                                                                );
                                                            }

                                                            return Promise.resolve();
                                                        }
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    style={{ width: '100%' }}
                                                    placeholder="Select chains where this is a main label"
                                                    options={Object.entries(CHAIN_MAPPING).map(([chain_id, name]) => ({
                                                        label: <Space><ChainAvatar chainId={chain_id} size="small" /> {name}</Space>,
                                                        value: chain_id
                                                    }))}
                                                />
                                            </Form.Item>

                                            <Button type="link" onClick={() => remove(name)} icon={<DeleteOutlined />}>
                                                Remove Label
                                            </Button>
                                        </Space>
                                    </div>
                                ))}
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Label
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>

            {/* Edit Tags Modal */}
            <Modal
                {...editTagsModalProps}
                title="Edit Tags"
                width={800}
                centered
                afterOpenChange={() => {
                    // Reset form with current record values when modal opens
                    editTagsFormProps.form?.setFieldsValue({
                        tags: getAllTags()
                            ?.map((tag: ITag) => ({
                                label: tag.name,
                                value: tag.id,
                                color: tag.color
                            }))
                            .sort((a: any, b: any) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase())) || []
                    });
                }}
                >
                <Alert
                    message="Tag Management"
                    description="Tags are additional markers that can help categorize and filter addresses."
                    type="info"
                    showIcon
                    style={{ marginBottom: 16 }}
                />
                <Form {...editTagsFormProps}>
                    <Form.Item
                        label="Tags"
                        name="tags"
                        initialValue={getAllTags()
                            ?.map((tag: ITag) => ({
                                label: tag.name,
                                value: tag.id,
                                color: tag.color
                            }))
                            .sort((a: any, b: any) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase())) || []
                        }
                    >
                        <Select
                            mode="tags"
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select or enter tags"
                            tokenSeparators={[',']}
                            options={getAllTags()
                                .map((tag: ITag) => ({
                                    label: tag.name,
                                    value: tag.id,
                                    color: tag.color
                                }))
                                .sort((a: any, b: any) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()))
                            }
                            filterOption={(input, option) =>
                                (option?.label?.toString() || '').toLowerCase().includes(input?.toLowerCase() || '')
                            }
                            tagRender={({ label, value, closable, onClose }) => {
                                const tagObj = getAllTags().find((tag: ITag) => tag.id === value);
                                return (
                                    <Tag 
                                        closable={closable} 
                                        onClose={onClose} 
                                        style={{ marginRight: 3 }}
                                        color={tagObj?.color ? `#${tagObj.color}` : undefined}
                                    >
                                        {label}
                                    </Tag>
                                );
                            }}
                            onChange={(values) => {
                                const currentValues = editTagsFormProps.form?.getFieldsValue();
                                
                                const processedTags = values.map((value: any) => {
                                    // If it's an ID of an existing tag
                                    const existingTag = getAllTags().find((tag: ITag) => tag.id === value);
                                    if (existingTag) {
                                        return {
                                            label: existingTag.name,
                                            value: existingTag.id,
                                            color: existingTag.color
                                        };
                                    }
                                    
                                    // If it's a new tag (string that's not an ID)
                                    if (typeof value === 'string') {
                                        return {
                                            label: value,
                                            value: value,
                                            color: undefined 
                                        };
                                    }
                                    
                                    // Fallback case
                                    return {
                                        label: String(value),
                                        value: String(value),
                                        color: undefined
                                    };
                                }).sort((a: any, b: any) => 
                                    a.label?.toLowerCase().localeCompare(b.label?.toLowerCase())
                                );
                                
                                editTagsFormProps.form?.setFieldsValue({
                                    ...currentValues,
                                    tags: processedTags
                                });
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            {/* Edit Shared With Modal */}
            <Modal
                {...editSharedWithModalProps}
                title="Manage Sharing"
                width={800}
                centered
                afterOpenChange={() => {
                    editSharedWithFormProps.form?.setFieldsValue({
                        shared_with: record?.shared_with?.map(user => ({
                            label: user.email,
                            value: user.id
                        })) || []
                    });
                }}
            >
                <Alert
                    message="Access Management"
                    description="Control who has access to view and manage this address. Users with access will be able to see all details and transactions associated with this address."
                    type="info"
                    showIcon
                    style={{ marginBottom: 16 }}
                />
                <Form 
                    {...editSharedWithFormProps}
                    initialValues={{
                        shared_with: getAllSharedWith()?.map(user => ({
                            label: user.email,
                            value: user.id
                        })).sort((a: any, b: any) => a.label.localeCompare(b.label)) || []
                    }}
                >
                    <Form.Item
                        label="Shared With"
                        name="shared_with"
                        help="Select users to share this address with"
                    >
                        <DebounceSelect
                            mode="multiple"
                            placeholder="Select users"
                            fetchOptions={async (search: string) => {
                                const options = await fetchUserList(search);
                                return options.sort((a, b) => 
                                    a.label?.toLowerCase().localeCompare(b.label?.toLowerCase())
                                );
                            }}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            {/* Upload ABI Modal */}
            <Modal
                {...uploadAbiModalProps}
                title="Upload ABI"
                width={1000}
                centered
                footer={null}
            >
                {abiQueryResult.isLoading ? (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <Spin />
                        <div style={{ marginTop: '10px' }}>Loading ABIs...</div>
                    </div>
                ) : (
                    <>
                        <Alert
                            message="ABI Upload"
                            description="Upload or paste the ABI (Application Binary Interface) for this smart contract. The ABI will be used to decode transaction data."
                            type="info"
                            showIcon
                            style={{ marginBottom: 16 }}
                        />
                        <Form 
                            {...uploadAbiFormProps}
                            layout="vertical"
                        >
                            <Form.Item
                                name="address"
                                label="Contract Address"
                                rules={[{ required: true, message: 'Please input the contract address' }]}
                                initialValue={record?.address || ''}
                            >
                                <Input disabled placeholder="0x..." />
                            </Form.Item>
                            <Form.Item
                                name="address_id"
                                label="Contract Address ID"
                                initialValue={record?.id || ''}
                                hidden
                            >
                                <Input value={record?.id} disabled/>
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label="Contract Name"
                                rules={[{ required: true, message: 'Please input the contract name' }]}
                            >
                                <Input placeholder="My Contract" />
                            </Form.Item>

                            <Form.Item
                                name="description"
                                label="Description"
                            >
                                <Input.TextArea 
                                    placeholder="Contract description..." 
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                />
                            </Form.Item>

                            <Divider>Contract ABIs</Divider>

                            {/* Dynamic ABI Form List */}
                            <Form.List 
                                name="abi_entries"
                                rules={[
                                    {
                                        validator: validateChains
                                    }
                                ]}
                            >                        
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Card 
                                                key={key} 
                                                style={{ marginBottom: 16 }}
                                                title={`ABI #${name + 1}`}
                                                extra={
                                                    fields.length > 1 && (
                                                        <Button 
                                                            type="text" 
                                                            danger 
                                                            icon={<DeleteOutlined />}
                                                            onClick={() => remove(name)}
                                                        />
                                                    )
                                                }
                                            >
                                                <Space direction="vertical" style={{ width: '100%' }}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'chainIds']}
                                                        label="Supported Chains"
                                                        rules={[
                                                            { required: true, message: 'Please select at least one chain' }
                                                        ]}
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            placeholder="Select chains"
                                                            style={{ width: '100%' }}
                                                            disabled={isSubmitting}
                                                        >
                                                            {Object.entries(CHAIN_IDS).map(([chainName, chainId]) => (
                                                                <Select.Option key={chainId} value={chainId}>
                                                                    <Space>
                                                                        <Tooltip title={chainId}>
                                                                        <Avatar 
                                                                            src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`} 
                                                                            size="small"
                                                                            style={{ backgroundColor: '#f0f2f5' }}
                                                                        />
                                                                    </Tooltip>
                                                                    <Typography.Text>
                                                                        {CHAIN_MAPPING[chainName] || chainName}
                                                                    </Typography.Text>
                                                                </Space>
                                                            </Select.Option>
                                                        ))}
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'abi']}
                                                        label="ABI JSON"
                                                        rules={[
                                                            { required: true, message: 'Please provide the ABI' },
                                                            {
                                                                validator: async (_, value) => {
                                                                    if (value) {
                                                                        try {
                                                                            // Remove trailing whitespace and newlines
                                                                            const trimmedValue = value.trim();
                                                                            
                                                                            // Parse JSON to validate format
                                                                            const parsedAbi = JSON.parse(trimmedValue);
                                                                                                                                    
                                                                            // Update the form with cleaned value
                                                                            uploadAbiFormProps.form?.setFieldValue(
                                                                                ['abi_entries', name, 'abi'], 
                                                                                JSON.stringify(parsedAbi, null, 2)
                                                                            );
                                                                        } catch (error) {
                                                                            throw new Error(
                                                                                error instanceof Error 
                                                                                    ? error.message 
                                                                                    : 'Invalid JSON format'
                                                                            );
                                                                        }
                                                                    }
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea 
                                                            placeholder="Paste your ABI JSON here..."
                                                            autoSize={{ minRows: 4, maxRows: 10 }}
                                                        />
                                                    </Form.Item>

                                                    <Upload
                                                        accept=".json"
                                                        showUploadList={false}
                                                        beforeUpload={(file) => {
                                                            const reader = new FileReader();
                                                            reader.onload = (e) => {
                                                                if (e.target?.result) {
                                                                    const jsonText = e.target.result.toString();
                                                                    uploadAbiFormProps.form?.setFieldValue(['abi_entries', name, 'abi'], jsonText);
                                                                }
                                                            };
                                                            reader.readAsText(file);
                                                            return false;
                                                        }}
                                                    >
                                                        <Button icon={<UploadOutlined />}>
                                                            Upload ABI File
                                                        </Button>
                                                    </Upload>
                                                </Space>
                                            </Card>
                                        ))}

                                        <Form.Item>
                                            <Button 
                                                type="dashed" 
                                                onClick={() => add()} 
                                                block 
                                                icon={<PlusOutlined />}
                                                disabled={isSubmitting}
                                            >
                                                Add Another ABI
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <Form.Item>
                                <Button 
                                    type="primary" 
                                    htmlType="submit"
                                    loading={isSubmitting}
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )}
                
                {/* Reprocess Transactions Section */}
                <Card 
                    title="Transaction Processing"
                    style={{ marginTop: 16 }}
                >
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Alert
                            message="Reprocess Transactions for this address"
                            description="After adding a new ABI you can reprocess all transactions for this address to update their data or import missing transactions. Select specific chains and processing options."
                            type="info"
                            showIcon
                        />
                        <Button 
                            type="primary"
                            onClick={() => setIsReprocessModalVisible(true)}
                            icon={<SyncOutlined />}
                        >
                            Reprocess Transactions
                        </Button>
                    </Space>

                    <Modal
                        title="Reprocess Address Transactions"
                        open={isReprocessModalVisible}
                        onCancel={() => {
                            setIsReprocessModalVisible(false);
                            reprocessForm.resetFields();
                        }}
                        onOk={() => {
                            reprocessForm.validateFields().then((values) => {
                                const { importType, chains, ...otherValues } = values;
                                reprocessAddressTransactions({
                                    chains: chains,
                                    ...otherValues,
                                    simple_import: importType === 'simple'
                                });
                                setIsReprocessModalVisible(false);
                                reprocessForm.resetFields();
                            });
                        }}
                    >
                        <Form 
                            form={reprocessForm} 
                            layout="vertical"
                            initialValues={{
                                importType: 'simple',
                                force_update: false,
                                create_address_labels: true,
                                chains: record?.active_chain_ids || []
                            }}
                        >
                            <Alert
                                message="Processing Options"
                                description="Choose how to process the transactions. Simple processing is faster but may miss some details."
                                type="info"
                                showIcon
                                style={{ marginBottom: 16 }}
                            />
                            
                            <Form.Item
                                name="chains"
                                label="Select Chains"
                                rules={[{ required: true, message: 'Please select at least one chain' }]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select chains to process"
                                    style={{ width: '100%' }}
                                    options={Object.entries(CHAIN_MAPPING).map(([chain_id, name]) => ({
                                        label: <Space>
                                            <Tooltip title={`Chain ID: ${chain_id}`}>
                                                <Avatar 
                                                    size="small" 
                                                    src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/blockchain/logos/svg/${chain_id}.svg`}
                                                    style={{ backgroundColor: 'transparent' }}
                                                />
                                            </Tooltip>
                                                <span>{name}</span>
                                            </Space>  ,
                                        value: chain_id,
                                    }))}
                                />
                            </Form.Item>

                            <Form.Item
                                name="importType"
                                label="Import Type"
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        <Radio value="simple">
                                            Simple Import
                                            <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                                                Quick import with basic processing (recommended for simple transactions)
                                            </Typography.Text>
                                        </Radio>
                                        <Radio value="advanced">
                                            Advanced Import
                                            <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                                                Detailed import with full data processing and validation
                                            </Typography.Text>
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                name="force_update"
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    Force Update
                                    <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                                        Overwrite existing transaction data
                                    </Typography.Text>
                                </Checkbox>
                            </Form.Item>

                            <Form.Item
                                name="create_address_labels"
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    Create Address Labels
                                    <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                                        Automatically generate labels for addresses
                                    </Typography.Text>
                                </Checkbox>
                            </Form.Item>
                        </Form>
                    </Modal>
                </Card>                
            </Modal>

            {/* Edit Notes Modal */}
            <Modal
                {...editNotesModalProps}
                title="Edit Notes"
                width={800}
                centered
                afterOpenChange={() => {
                    editNotesFormProps.form?.setFieldsValue({
                        notes: record?.notes || ''
                    });
                }}
            >
                <Alert
                    message="Notes Management"
                    description="Add any relevant notes or comments about this address."
                    type="info"
                    showIcon
                    style={{ marginBottom: 16 }}
                />
                <Form 
                    {...editNotesFormProps}
                    initialValues={{
                        notes: record?.notes || ''
                    }}
                >
                    <Form.Item
                        label="Notes"
                        name="notes"
                    >
                        <Input.TextArea 
                            rows={6}
                            placeholder="Enter notes about this address..."
                        />
                    </Form.Item>
                </Form>
            </Modal>

            {/* Transactions Card */}
            <Card 
                title={
                    <Space size="middle">
                        <Typography.Text strong>Transactions</Typography.Text>
                        <Tooltip title="All transactions for this address across chains">
                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,0.45)' }} />
                        </Tooltip>
                    </Space>
                }
                style={{ marginTop: 16 }}
            >
                <TransactionTable
                    identifier={record?.status === 'active' ? record?.id || '' : record?.address || ''} // If the address is not imported yet then dont use the identifier. Because we can not link an address id to an address when it is not imported
                    transactions={transactions}
                    initialPaginationCursors={record?.pagination_cursors}
                    setTransactions={setTransactions}
                    address_items_map={record?.address_items_map || {}}
                    setGraphKey={setGraphKey}
                    tableId="show-address"
                    searchAddress={record?.address || ''} 
                    onTransactionDataChange={setFilteredTransactions}
                    activeChains={record?.active_chain_ids || []}
                    selectedChains={record?.active_chain_ids || []}
                />
            </Card>    

            {/* Transaction Graph */}
            <Card 
                title={
                    <Space direction="vertical" size="small">
                        <Typography.Title level={4} style={{ margin: 0 }}>Transaction Flow Graph</Typography.Title>
                        <Typography.Text type="secondary">
                            Visual representation of transaction flows. Use the table filters above to refine the displayed connections.
                        </Typography.Text>
                        <Space>
                            <InfoCircleOutlined style={{ color: '#1890ff' }} />
                            <Typography.Text type="secondary">
                                Tip: Drag nodes to rearrange, scroll to zoom, and use the chain filters to focus on specific networks
                            </Typography.Text>
                        </Space>
                    </Space>
                }
                style={{ 
                    marginTop: 16,
                    width: '100%'
                }}
            >
                <TransactionGraph 
                    data={transactions}
                    address_items_map={record?.address_items_map || {}}
                    filteredData={filteredTransactions}
                    chainIdToName={CHAIN_MAPPING}
                    cdn_domain_name={process.env.REACT_APP_CDN_URL || ''}
                    graphKey={graphKey}
                    setGraphKey={setGraphKey}
                    options={{
                        showReplay: true,
                        showLive: false,
                        showTimeRange: true,
                        showSettings: true,
                        showShare: false
                    }}
                />
            </Card>     

            {/* Address Info Modal */}
            <AddressInfoModal
                visible={addressInfoModalVisible}
                onClose={() => setAddressInfoModalVisible(false)}
                addressData={record}
                loading={isLoading}
                onViewContract={handleViewContract}
                address_items_map={record?.address_items_map || {}}
                apiDomain={domain}
                region={region.use_region}
                regionAlias={region.use_region_alias}
                token={token.__raw}
            />
        </Show>
    );
};

import { Modal, Table, Tag, Space, Avatar, Alert, Typography, Tooltip } from 'antd';
import { useList } from '@refinedev/core';
import { WarningOutlined, CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ITokenApproval } from "interfaces/address.d";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { CHAIN_MAPPING } from "../../../interfaces/chain.d";

dayjs.extend(relativeTime);

const { Text, Link } = Typography;

interface TokenApprovalModalProps {
    visible: boolean;
    onClose: () => void;
    token: {
        token_address: string;
        symbol: string;
        name: string;
        logo?: string;
    };
    walletAddress: string;
    chainId: string;
}

export const TokenApprovalModal: React.FC<TokenApprovalModalProps> = ({
    visible,
    onClose,
    token,
    walletAddress,
    chainId,
}) => {
    const { data, isLoading } = useList<ITokenApproval>({
        resource: `tokens/${token.token_address}/approvals`,
        filters: [
            {
                field: "address",
                operator: "eq",
                value: walletAddress,
            },
            {
                field: "chain_id",
                operator: "eq",
                value: chainId,
            }
        ],
    });

    const approvals = data?.data || [];

    const columns = [
        {
            title: 'Spender',
            key: 'spender',
            render: (_: any, record: ITokenApproval) => (
                <Space>
                    {record.spender.entity_logo && (
                        <Avatar size="small" src={record.spender.entity_logo} />
                    )}
                    <Space direction="vertical" size={0}>
                        <Text>{record.spender.entity || 'Unknown Protocol'}</Text>
                        <Text type="secondary" style={{ fontSize: '12px' }}>
                            {record.spender.address_label || record.spender.address}
                        </Text>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Allowance',
            dataIndex: 'value_formatted',
            key: 'value',
            render: (value: string) => (
                <Text>{value} {token.symbol}</Text>
            ),
        },
        {
            title: 'USD at Risk',
            key: 'risk',
            render: (_: any, record: ITokenApproval) => (
                <Space>
                    {record.token.usd_at_risk && (
                        <Tag color={
                            Number(record.token.usd_at_risk) > 10000 ? 'red' :
                            Number(record.token.usd_at_risk) > 1000 ? 'orange' :
                            'green'
                        }>
                            ${Number(record.token.usd_at_risk).toLocaleString()}
                        </Tag>
                    )}
                </Space>
            ),
        },
        {
            title: 'Approved',
            key: 'date',
            render: (_: any, record: ITokenApproval) => (
                <Tooltip title={dayjs(record.block_timestamp).format('YYYY-MM-DD HH:mm:ss')}>
                    <Text type="secondary">
                        {dayjs(record.block_timestamp).fromNow()}
                    </Text>
                </Tooltip>
            ),
        },
    ];

    return (
        <Modal
            title={
                <Space>
                    <Avatar src={token.logo} />
                    <Text strong>{token.name} Approvals</Text>
                    <Tag color="blue">{token.symbol}</Tag>
                    <Tag color="purple">{CHAIN_MAPPING[chainId]}</Tag>
                </Space>
            }
            open={visible}
            onCancel={onClose}
            width={800}
            footer={null}
        >
            {approvals.length > 0 && (
                <Alert
                    message="Token Approvals"
                    description="These are the protocols and contracts that have permission to spend your tokens. Consider revoking unused approvals to improve security."
                    type="warning"
                    showIcon
                    style={{ marginBottom: 16 }}
                />
            )}
            
            <Table
                dataSource={approvals}
                columns={columns}
                rowKey="transaction_hash"
                loading={isLoading}
                pagination={false}
                locale={{
                    emptyText: 'No active approvals found for this token'
                }}
            />
        </Modal>
    );
}; 
import React from 'react';
import { Space, Tag, Typography, Descriptions, List, Tooltip, Avatar } from 'antd';
import { SelectedSection } from '../types';
import { HighlightedAddress } from './TransactionModal/components/HighlightedAddress';
interface ModalContentProps {
    selectedSection: SelectedSection | null;
}

export const renderModalContent = ({ selectedSection }: ModalContentProps) => {
    if (!selectedSection) return null;

    const { type, data, searchAddress, address_items_map } = selectedSection;

    switch (type) {
        case 'from':
            return (
                <Descriptions column={1} bordered>
                    <Descriptions.Item label="Transaction Hash">
                        <Typography.Text copyable style={{ whiteSpace: 'nowrap' }}>{data.hash}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="From Address">
                        <Space>
                            {data.from_address_entity_logo && (
                                <Tooltip title={data.from_address_entity || data.from_address_label}>
                                    <Avatar size="small" src={data.from_address_entity_logo} />
                                </Tooltip>
                            )}
                            <HighlightedAddress address={data.from_address} searchAddress={searchAddress} address_items_map={address_items_map} />
                        </Space>
                    </Descriptions.Item>
                    {(data.from_address_label || data.from_address_entity) && (
                        <Descriptions.Item label="Labels">
                            <Space size={4}>
                                {data.from_address_label && (
                                    <Tag color="blue">{data.from_address_label}</Tag>
                                )}
                                {data.from_address_entity && (
                                    <Tag color="purple">{data.from_address_entity}</Tag>
                                )}
                            </Space>
                        </Descriptions.Item>
                    )}                        
                    <Descriptions.Item label="To Address">
                        <Space>
                            {data.to_address_entity_logo && (
                                <Tooltip title={data.to_address_entity || data.to_address_label}>
                                    <Avatar size="small" src={data.to_address_entity_logo} />
                                </Tooltip>
                            )}
                            <HighlightedAddress address={data.to_address} searchAddress={searchAddress} address_items_map={address_items_map} />
                        </Space>
                    </Descriptions.Item>
                    {(data.to_address_label || data.to_address_entity) && (
                        <Descriptions.Item label="Labels">
                            <Space size={4}>
                                {data.to_address_label && (
                                    <Tag color="blue">{data.to_address_label}</Tag>
                                )}
                                {data.to_address_entity && (
                                    <Tag color="purple">{data.to_address_entity}</Tag>
                                )}
                            </Space>
                        </Descriptions.Item>
                    )}
                </Descriptions>
            );

        case 'to':
            return (
                <Descriptions column={1} bordered>
                    <Descriptions.Item label="Transaction Hash">
                        <Typography.Text copyable style={{ whiteSpace: 'nowrap' }}>{data.hash}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="From Address">
                        <Space>
                            {data.from_address_entity_logo && (
                                <Tooltip title={data.from_address_entity || data.from_address_label}>
                                    <Avatar size="small" src={data.from_address_entity_logo} />
                                </Tooltip>
                            )}
                            <HighlightedAddress address={data.from_address} searchAddress={searchAddress} address_items_map={address_items_map} />
                        </Space>
                    </Descriptions.Item>
                    {(data.from_address_label || data.from_address_entity) && (
                        <Descriptions.Item label="Labels">
                            <Space size={4}>
                                {data.from_address_label && (
                                    <Tag color="blue">{data.from_address_label}</Tag>
                                )}
                                {data.from_address_entity && (
                                    <Tag color="purple">{data.from_address_entity}</Tag>
                                )}
                            </Space>
                        </Descriptions.Item>
                    )}
                    <Descriptions.Item label="To Address">
                        <Space>
                            {data.to_address_entity_logo && (
                                <Tooltip title={data.to_address_entity || data.to_address_label}>
                                    <Avatar size="small" src={data.to_address_entity_logo} />
                                </Tooltip>
                            )}
                            <HighlightedAddress address={data.to_address} searchAddress={searchAddress} address_items_map={address_items_map} />
                        </Space>
                    </Descriptions.Item>
                    {(data.to_address_label || data.to_address_entity) && (
                        <Descriptions.Item label="Labels">
                            <Space size={4}>
                                {data.to_address_label && (
                                    <Tag color="blue">{data.to_address_label}</Tag>
                                )}
                                {data.to_address_entity && (
                                    <Tag color="purple">{data.to_address_entity}</Tag>
                                )}
                            </Space>
                        </Descriptions.Item>
                    )}
                </Descriptions>
            );

        case 'internal':
            return (
                <>
                    <Typography.Text type="secondary" style={{ marginBottom: '16px', display: 'block' }}>
                        Showing {data.length} internal transaction{data.length !== 1 ? 's' : ''}
                    </Typography.Text>
                    <List
                        dataSource={data}
                        renderItem={(tx: any, index: number) => (
                            <List.Item>
                                <Descriptions 
                                    column={1} 
                                    bordered
                                    title={
                                        <Space>
                                            <Tag color="purple">Internal Transaction #{index + 1} of {data.length}</Tag>
                                        </Space>
                                    }
                                >
                                    <Descriptions.Item label="From">
                                        <HighlightedAddress address={tx.from} searchAddress={searchAddress} address_items_map={address_items_map} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="To">
                                        <HighlightedAddress address={tx.to} searchAddress={searchAddress} address_items_map={address_items_map} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Value">
                                        {tx.value}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Type">
                                        <Tag color="blue">{tx.type}</Tag>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Gas">
                                        <Space>
                                            <Tag color="orange">Gas Limit: {tx.gas}</Tag>
                                            <Tag color="green">Gas Used: {tx.gas_used}</Tag>
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Block">
                                        <Space direction="vertical" size="small">
                                            <Typography.Text>Number: {tx.block_number}</Typography.Text>
                                            <Typography.Text copyable={{ text: tx.block_hash }}>
                                                Hash: {tx.block_hash?.substring(0, 10)}...{tx.block_hash?.substring(58)}
                                            </Typography.Text>
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Transaction Hash">
                                        <Typography.Text copyable={{ text: tx.transaction_hash }}>
                                            {tx.transaction_hash?.substring(0, 10)}...{tx.transaction_hash?.substring(58)}
                                        </Typography.Text>
                                    </Descriptions.Item>
                                </Descriptions>
                            </List.Item>
                        )}
                    />
                </>
            );

        case 'native':
            return (
                <>
                    <Typography.Text type="secondary" style={{ marginBottom: '16px', display: 'block' }}>
                        Showing {data.length} native transfer{data.length !== 1 ? 's' : ''}
                    </Typography.Text>
                    <List
                        dataSource={data}
                        renderItem={(transfer: any, index: number) => (
                            <List.Item>
                                <Descriptions 
                                    column={1} 
                                    bordered
                                    title={
                                        <Space>
                                            <Tag color="orange">Native Transfer #{index + 1} of {data.length}</Tag>
                                        </Space>
                                    }
                                >
                                    <Descriptions.Item label="From">
                                        <Space>
                                            {transfer.from_address_entity_logo && (
                                                <Tooltip title={transfer.from_address_entity || transfer.from_address_label}>
                                                    <Avatar size="small" src={transfer.from_address_entity_logo} />
                                                </Tooltip>
                                            )}
                                            <HighlightedAddress address={transfer.from_address} searchAddress={searchAddress} address_items_map={address_items_map} />
                                            {(transfer.from_address_label || transfer.from_address_entity) && (
                                                <Space size={4}>
                                                    {transfer.from_address_label && (
                                                        <Tag color="blue">{transfer.from_address_label}</Tag>
                                                    )}
                                                    {transfer.from_address_entity && (
                                                        <Tag color="purple">{transfer.from_address_entity}</Tag>
                                                    )}
                                                </Space>
                                            )}
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="To">
                                        <Space>
                                            {transfer.to_address_entity_logo && (
                                                <Tooltip title={transfer.to_address_entity || transfer.to_address_label}>
                                                    <Avatar size="small" src={transfer.to_address_entity_logo} />
                                                </Tooltip>
                                            )}
                                            <HighlightedAddress address={transfer.to_address} searchAddress={searchAddress} address_items_map={address_items_map} />
                                            {(transfer.to_address_label || transfer.to_address_entity) && (
                                                <Space size={4}>
                                                    {transfer.to_address_label && (
                                                        <Tag color="blue">{transfer.to_address_label}</Tag>
                                                    )}
                                                    {transfer.to_address_entity && (
                                                        <Tag color="purple">{transfer.to_address_entity}</Tag>
                                                    )}
                                                </Space>
                                            )}
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Value">
                                        <Space>
                                            {transfer.token_logo && (
                                                <Tooltip title={transfer.token_symbol}>
                                                    <Avatar 
                                                        size="small" 
                                                        src={transfer.token_logo}
                                                        style={{ backgroundColor: 'transparent' }}
                                                    />
                                                </Tooltip>
                                            )}
                                            <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                                                {transfer.value_formatted} {transfer.token_symbol}
                                            </Typography.Text>
                                            <Tag 
                                                color={transfer.direction === 'send' ? 'red' : 'green'}
                                                style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                                            >
                                                {transfer.direction}
                                            </Tag>
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Direction">
                                        <Tag color={transfer.direction === 'send' ? 'red' : 'green'}>
                                            {transfer.direction}
                                        </Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                            </List.Item>
                        )}
                    />
                </>
            );

        case 'erc20':
            return (
                <>
                    <Typography.Text type="secondary" style={{ marginBottom: '16px', display: 'block' }}>
                        Showing {data.length} ERC20 transfer{data.length !== 1 ? 's' : ''}
                    </Typography.Text>
                    <List
                        dataSource={data}
                        renderItem={(transfer: any, index: number) => (
                            <List.Item>
                                <Descriptions 
                                    column={1} 
                                    bordered
                                    title={
                                        <Space>
                                            <Tag color="cyan">ERC20 Transfer #{index + 1} of {data.length}</Tag>
                                        </Space>
                                    }
                                >
                                    <Descriptions.Item label="From">
                                        <Space>
                                            {transfer.from_address_entity_logo && (
                                                <Tooltip title={transfer.from_address_entity || transfer.from_address_label}>
                                                    <Avatar size="small" src={transfer.from_address_entity_logo} />
                                                </Tooltip>
                                            )}
                                            <HighlightedAddress address={transfer.from_address} searchAddress={searchAddress} address_items_map={address_items_map} />
                                            {(transfer.from_address_label || transfer.from_address_entity) && (
                                                <Space size={4}>
                                                    {transfer.from_address_label && (
                                                        <Tag color="blue">{transfer.from_address_label}</Tag>
                                                    )}
                                                    {transfer.from_address_entity && (
                                                        <Tag color="purple">{transfer.from_address_entity}</Tag>
                                                    )}
                                                </Space>
                                            )}
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="To">
                                        <Space>
                                            {transfer.to_address_entity_logo && (
                                                <Tooltip title={transfer.to_address_entity || transfer.to_address_label}>
                                                    <Avatar size="small" src={transfer.to_address_entity_logo} />
                                                </Tooltip>
                                            )}
                                            <HighlightedAddress address={transfer.to_address} searchAddress={searchAddress} address_items_map={address_items_map} />
                                            {(transfer.to_address_label || transfer.to_address_entity) && (
                                                <Space size={4}>
                                                    {transfer.to_address_label && (
                                                        <Tag color="blue">{transfer.to_address_label}</Tag>
                                                    )}
                                                    {transfer.to_address_entity && (
                                                        <Tag color="purple">{transfer.to_address_entity}</Tag>
                                                    )}
                                                </Space>
                                            )}
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Token Contract">
                                        <Space>
                                            <HighlightedAddress address={transfer.token_address || transfer.address} searchAddress={searchAddress} address_items_map={address_items_map} />
                                            {transfer.verified_contract && (
                                                <Tag color="green">Verified</Tag>
                                            )}
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Token">
                                        <Space>
                                            {transfer.token_logo && (
                                                <Tooltip title={transfer.token_symbol}>
                                                    <Avatar size="small" src={transfer.token_logo} />
                                                </Tooltip>
                                            )}
                                            <Typography.Text strong>{transfer.token_name}</Typography.Text>
                                            <Typography.Text type="secondary">({transfer.token_symbol})</Typography.Text>
                                            {transfer.security_score && (
                                                <Tooltip title="Security Score">
                                                    <Tag color={Number(transfer.security_score) >= 80 ? 'green' : 'orange'}>
                                                        Score: {transfer.security_score}
                                                    </Tag>
                                                </Tooltip>
                                            )}
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Amount">
                                        <Space>
                                            <Typography.Text strong>{transfer.value_formatted}</Typography.Text>
                                            <Typography.Text type="secondary">{transfer.token_symbol}</Typography.Text>
                                            <Tag 
                                                color={transfer.direction === 'send' ? 'red' : 'green'}
                                                style={{ textTransform: 'capitalize' }}
                                            >
                                                {transfer.direction}
                                            </Tag>
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Direction">
                                        <Tag color={transfer.direction === 'send' ? 'red' : 'green'}>
                                            {transfer.direction}
                                        </Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                            </List.Item>
                        )}
                    />
                </>
            );

        case 'nft':
            return (
                <>
                    <Typography.Text type="secondary" style={{ marginBottom: '16px', display: 'block' }}>
                        Showing {data.length} NFT transfer{data.length !== 1 ? 's' : ''}
                    </Typography.Text>
                    <List
                        dataSource={data}
                        renderItem={(transfer: any, index: number) => (
                            <List.Item>
                                <Descriptions 
                                    column={1} 
                                    bordered
                                    title={
                                        <Space>
                                            <Tag color="magenta">NFT Transfer #{index + 1} of {data.length}</Tag>
                                        </Space>
                                    }
                                >
                                    <Descriptions.Item label="From">
                                        <Space>
                                            {transfer.from_address_entity_logo && (
                                                <Tooltip title={transfer.from_address_entity || transfer.from_address_label}>
                                                    <Avatar size="small" src={transfer.from_address_entity_logo} />
                                                </Tooltip>
                                            )}
                                            <HighlightedAddress address={transfer.from_address} searchAddress={searchAddress} address_items_map={address_items_map} />
                                            {(transfer.from_address_label || transfer.from_address_entity) && (
                                                <Space size={4}>
                                                    {transfer.from_address_label && (
                                                        <Tag color="blue">{transfer.from_address_label}</Tag>
                                                    )}
                                                    {transfer.from_address_entity && (
                                                        <Tag color="purple">{transfer.from_address_entity}</Tag>
                                                    )}
                                                </Space>
                                            )}
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="To">
                                        <Space>
                                            {transfer.to_address_entity_logo && (
                                                <Tooltip title={transfer.to_address_entity || transfer.to_address_label}>
                                                    <Avatar size="small" src={transfer.to_address_entity_logo} />
                                                </Tooltip>
                                            )}
                                            <HighlightedAddress address={transfer.to_address} searchAddress={searchAddress} address_items_map={address_items_map} />
                                            {(transfer.to_address_label || transfer.to_address_entity) && (
                                                <Space size={4}>
                                                    {transfer.to_address_label && (
                                                        <Tag color="blue">{transfer.to_address_label}</Tag>
                                                    )}
                                                    {transfer.to_address_entity && (
                                                        <Tag color="purple">{transfer.to_address_entity}</Tag>
                                                    )}
                                                </Space>
                                            )}
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Token Details">
                                        <Space direction="vertical">
                                            <Space>
                                                <Tag color="purple">{transfer.contract_type}</Tag>
                                                <Typography.Text>ID: {transfer.token_id}</Typography.Text>
                                                {transfer.amount && transfer.amount !== "1" && (
                                                    <Typography.Text>Amount: {transfer.amount}</Typography.Text>
                                                )}
                                            </Space>
                                            <Space>
                                                <Typography.Text type="secondary">Contract:</Typography.Text>
                                                <Tooltip title={transfer.token_address}>
                                                    <Typography.Text copyable={{ text: transfer.token_address }}>
                                                        {transfer.token_address?.substring(0, 6)}...{transfer.token_address?.substring(transfer.token_address.length - 6)}
                                                    </Typography.Text>
                                                </Tooltip>
                                            </Space>
                                            {transfer.verified_collection && (
                                                <Tag color="green">Verified Collection</Tag>
                                            )}
                                            {transfer.possible_spam && (
                                                <Tag color="red">Possible Spam</Tag>
                                            )}
                                        </Space>
                                    </Descriptions.Item>
                                </Descriptions>
                            </List.Item>
                        )}
                    />
                </>
            );

        case 'contract':
            return (
                <>
                    <Typography.Text type="secondary" style={{ marginBottom: '16px', display: 'block' }}>
                        Showing {data.length} contract interaction{data.length !== 1 ? 's' : ''}
                    </Typography.Text>
                    <List
                        dataSource={data}
                        renderItem={(interaction: any, index: number) => (
                            <List.Item>
                                <Descriptions 
                                    column={1} 
                                    bordered
                                    title={
                                        <Space>
                                            <Tag color="red">
                                                Contract {selectedSection.subType === 'approvals' ? 'Approval' : 'Method Call'} 
                                                #{index + 1} of {data.length}
                                            </Tag>
                                        </Space>
                                    }
                                >
                                    <Descriptions.Item label="From">
                                        <Space>
                                            <HighlightedAddress address={interaction.spender?.address} searchAddress={searchAddress} address_items_map={address_items_map} />
                                            {interaction.spender?.address_label && (
                                                <Tag color="blue">{interaction.spender?.address_label}</Tag>
                                            )}
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Contract">
                                        <Space>
                                            {interaction.token_logo && (
                                                <Tooltip title={interaction.token?.address_label || interaction.token?.name}>
                                                    <Avatar size="small" src={interaction.token_logo} />
                                                </Tooltip>
                                            )}
                                            <HighlightedAddress address={interaction.token?.address} searchAddress={searchAddress} address_items_map={address_items_map} />
                                            {(interaction.token?.address_label || interaction.token?.name) && (
                                                <Space size={4}>
                                                    {interaction.token?.address_label && (
                                                        <Tag color="blue">{interaction.token?.address_label}</Tag>
                                                    )}
                                                    {interaction.token?.name && (
                                                        <Tag color="purple">{interaction.token?.name}</Tag>
                                                    )}
                                                </Space>
                                            )}
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Interaction Details">
                                        <Space direction="vertical">
                                            <Space>
                                                <Tag color={selectedSection.subType === 'approvals' ? 'orange' : 'cyan'}>
                                                    {(selectedSection.subType || '').charAt(0).toUpperCase() + (selectedSection.subType || '').slice(1).replace(/s$/, '')}
                                                </Tag>
                                                {interaction.method_name && (
                                                    <Typography.Text>Method: {interaction.method_name}</Typography.Text>
                                                )}
                                            </Space>
                                            {selectedSection.subType === 'approvals' && interaction.spender && (
                                                <Space direction="vertical" size={2}>
                                                    <Space>
                                                        <Typography.Text type="secondary">Spender:</Typography.Text>
                                                        {interaction.spender.entity_logo && (
                                                            <Tooltip title={interaction.spender.entity || interaction.spender.address_label}>
                                                                <Avatar 
                                                                    size="small" 
                                                                    src={interaction.spender.entity_logo}
                                                                    style={{ backgroundColor: 'transparent' }}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                        <HighlightedAddress address={interaction.spender.address} searchAddress={searchAddress} address_items_map={address_items_map} />
                                                    </Space>
                                                    {(interaction.spender.address_label || interaction.spender.entity) && (
                                                        <Space size={4}>
                                                            {interaction.spender.address_label && (
                                                                <Tag color="blue">{interaction.spender.address_label}</Tag>
                                                            )}
                                                            {interaction.spender.entity && (
                                                                <Tag color="purple">{interaction.spender.entity}</Tag>
                                                            )}
                                                        </Space>
                                                    )}
                                                </Space>
                                            )}
                                            {interaction.value && (
                                                <Space>
                                                    <Typography.Text type="secondary">Value:</Typography.Text>
                                                    <Typography.Text>{interaction.value_formatted || interaction.value}</Typography.Text>
                                                    {interaction.token_symbol && (
                                                        <Typography.Text type="secondary">{interaction.token_symbol}</Typography.Text>
                                                    )}
                                                </Space>
                                            )}
                                            {interaction.verified_contract && (
                                                <Tag color="green">Verified Contract</Tag>
                                            )}
                                        </Space>
                                    </Descriptions.Item>
                                </Descriptions>
                            </List.Item>
                        )}
                    />
                </>
            );

        default:
            return null;
    }
}; 
import { useNotification } from "@refinedev/core";
import { useAuth0 } from "@auth0/auth0-react";
import { useConfig } from 'contexts';
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

interface UseTransactionDataProps {
    identifier: string;
    selectedChains: string[];
    activeChains: string[];
    searchParams: URLSearchParams;
    onTransactionsChange: (transactions: any[]) => void;
    onPaginationCursorsChange: (cursors: {
        moralis: any;
        database: any;
        status: any;
    }) => void;
    onCurrentPageChange: (page: number) => void
    onHasMoreChange: (hasMore: boolean) => void;
    onSearchParamsChange: (searchParams: URLSearchParams) => void;
}

export const useTransactionData = ({
    identifier,
    selectedChains,
    activeChains,
    searchParams,   
    onTransactionsChange,
    onPaginationCursorsChange,
    onCurrentPageChange,
    onHasMoreChange,
    onSearchParamsChange
}: UseTransactionDataProps) => {
    const { open } = useNotification();
    const { token, region, continent, domain, qa_environment } = useConfig();
    const [ isRefreshing, setIsRefreshing] = useState(false);
    const [ isRefreshingAllData, setIsRefreshingAllData] = useState(false);
    const fetchSelectedChainData = async (refresh = false) => {
        const use_region = region.use_region;
        const use_region_alias = region.use_region_alias;
        if (refresh === true) {
            setIsRefreshingAllData(true);
        } else {
            setIsRefreshing(true);
        }
        onTransactionsChange([]);
        onPaginationCursorsChange({
            moralis: {},
            database: {},
            status: {}
        });
        onCurrentPageChange(1);        
        try {
            const requestHeaders = {
                Authorization: `Bearer ${token.__raw}`,
                Accept: "application/json, text/plain, */*",
                "Source-Platform": "dashboard",
                "Source-Region": use_region,
                "Destination-Region": use_region,
            };

            const apiUrl = `https://${use_region_alias}.${continent.toLowerCase()}.private.${domain}`;
            const url = `${apiUrl}/addresses/${qa_environment === "blue" ? "blue/" : ""}GET/${continent.toUpperCase()}/v0/addresses/${identifier}`;

            const queryParams = new URLSearchParams({
                limit: searchParams.get('limit') || '100',
            });

            const chainIds = selectedChains.join(',');
            if (chainIds) {
                queryParams.set('chain_ids', chainIds);
            }

            const response = await fetch(`${url}?${queryParams}`, {
                headers: requestHeaders
            });

            const data = await response.json();

            if (data?.ok) {
                onTransactionsChange(
                    refresh 
                        ? (data.result.transactions || [])
                        : [...(data.result.transactions || [])]
                );
                    
                if (data.result.pagination_cursors) {
                    onPaginationCursorsChange(data.result.pagination_cursors);
                }
                const use_region = region.use_region;
                const use_region_alias = region.use_region_alias;
                const paginationCursors = data?.result?.pagination_cursors;
                const hasMoreData = paginationCursors?.status 
                    ? Object.values(paginationCursors.status).some(
                        (status: any) => !status.is_complete || status.has_more_data
                    )
                    : false;

                onHasMoreChange(hasMoreData);
                // onCurrentPageChange((prev: number) => prev + 1);

                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set('chain_ids', selectedChains.join(','));
                onSearchParamsChange(newSearchParams);
                
                open?.({
                    type: "success",
                    message: "Retrieved transaction data for selected chains",
                    description: "Retrieved transaction data for selected chains",
                    key: "active-chains",
                });                           
            } else {
                const errorMessage = data.error?.reason || 
                    data.error?.message || 
                    data.error?.detailed_reason || 
                    'Unknown error occurred';
                
                open?.({
                    type: "error",
                    message: `Error (${data.statusCode})`,
                    description: errorMessage,
                    key: "address",
                });
            }

        } catch (error) {
            open?.({
                type: "error",
                message: "Failed to retrieve transaction data for selected chains",
                description: error instanceof Error ? error.message : "Unknown error",
                key: "active-chains",
            });
            console.error("Error retrieving transaction data for selected chains:", error);
        } finally {
            setIsRefreshing(false);
            setIsRefreshingAllData(false);
        }
    };


    return {
        isRefreshing,
        isRefreshingAllData,
        fetchSelectedChainData
    };
}; 
import React from 'react';
import { Modal, Form, Input, Select, Tooltip, Space, Typography } from 'antd';
import { ShareAltOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface ShareGraphModalProps {
    open: boolean;
    onClose: () => void;
    onFinish: (values: any) => void;
    loading: boolean;
}

export const ShareGraphModal: React.FC<ShareGraphModalProps> = ({
    open,
    onClose,
    onFinish,
    loading
}) => {
    const [form] = Form.useForm();

    return (
        <Modal
            open={open}
            onCancel={onClose}
            title={
                <Space>
                    <ShareAltOutlined />
                    <Text strong>Share Graph</Text>
                </Space>
            }
            width={1000}
            centered
            okText="Share"
            confirmLoading={loading}
            onOk={() => form.submit()}
            destroyOnClose
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    share_type: "public",
                    update_type: "static",
                    include_streamed_transactions: true,
                    expiration: "never",
                    allow_comments: true,
                    allow_download: false,
                    graph_layout: "force",
                    theme: "light"
                }}
            >
                <Space direction="vertical" style={{ width: '100%' }} size="large">
                    {/* Basic Settings */}
                    <div>
                        <Text strong>Basic Settings</Text>
                        <Form.Item
                            label="Graph Name"
                            name="name"
                            rules={[{ required: true, message: 'Please enter a name for your graph' }]}
                        >
                            <Input placeholder="Enter a descriptive name" />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                        >
                            <Input.TextArea placeholder="Add details about this graph (optional)" />
                        </Form.Item>
                    </div>

                    {/* Sharing Options */}
                    <div>
                        <Text strong>Sharing Options</Text>
                        <Tooltip title="Control who can access your shared graph">
                            <Form.Item
                                label="Visibility"
                                name="share_type"
                            >
                                <Select
                                    options={[
                                        { value: "private", label: '🔒 Private - Only people with the link' },
                                        { value: "public", label: '🌐 Public - Anyone with the link' },
                                        { value: "password", label: '🔑 Password Protected' }
                                    ]}
                                />
                            </Form.Item>
                        </Tooltip>

                        <Tooltip title="Set when the shared link should expire">
                            <Form.Item
                                label="Link Expiration"
                                name="expiration"
                            >
                                <Select
                                    options={[
                                        { value: "never", label: '♾️ Never' },
                                        { value: "24h", label: '⏰ 24 Hours' },
                                        { value: "7d", label: '📅 7 Days' },
                                        { value: "30d", label: '📅 30 Days' }
                                    ]}
                                />
                            </Form.Item>
                        </Tooltip>
                    </div>

                    {/* Data Options */}
                    <div>
                        <Text strong>Data Options</Text>
                        <Tooltip title="Choose between static snapshot or live updating data">
                            <Form.Item
                                label="Update Type"
                                name="update_type"
                            >
                                <Select
                                    options={[
                                        { value: "static", label: '📸 Static Snapshot' },
                                        { value: "live", label: '🔄 Live Updates' }
                                    ]}
                                />
                            </Form.Item>
                        </Tooltip>

                        <Form.Item
                            label="Include Streamed Transactions"
                            name="include_streamed_transactions"
                        >
                            <Select
                                options={[
                                    { value: true, label: '✅ Include New Transactions' },
                                    { value: false, label: '❌ Exclude New Transactions' }
                                ]}
                            />
                        </Form.Item>
                    </div>
                </Space>
            </Form>
        </Modal>
    );
}; 
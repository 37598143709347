import { WarningOutlined, CloseCircleOutlined, InfoCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';

interface CriticalityConfig {
    color: string;
    backgroundColor: string;
    icon: ReactNode;
    text: string;
}

export const getCriticalityConfig = (level: string): CriticalityConfig => {
    switch (level) {
        case '1':
            return {
                color: '#cf1322',
                backgroundColor: '#fff1f0',
                icon: <ExclamationCircleOutlined style={{ color: '#cf1322' }}/>,
                text: 'Critical',
            };
        case '2':
            return {
                color: '#d4380d',
                backgroundColor: '#fff2e8',
                icon: <CloseCircleOutlined style={{ color: '#d4380d' }}/>,
                text: 'Error',
            };
        case '3':
            return {
                color: '#d48806',
                backgroundColor: '#fffbe6',
                icon: <WarningOutlined style={{ color: '#d48806' }}/>,
                text: 'Warning',
            };
        case '4':
            return {
                color: '#1677ff',
                backgroundColor: '#e6f4ff',
                icon: <InfoCircleOutlined style={{ color: '#1677ff' }}/>,
                text: 'Info',
            };
        case '5':
            return {
                color: '#389e0d',
                backgroundColor: '#f6ffed',
                icon: <CheckCircleOutlined style={{ color: '#389e0d' }}/>,
                text: 'Success',
            };
        default:
            return {
                color: '#8c8c8c',
                backgroundColor: '#fafafa',
                icon: <InfoCircleOutlined style={{ color: '#8c8c8c' }}/>,
                text: 'Unknown',
            };
    }
}; 
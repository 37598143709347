import { INFT, INFTCollection } from "../interfaces/address.d";

export const isBase64SVG = (url: string): boolean => {
    return url.startsWith('data:image/svg+xml;base64,');
};

export const getBackgroundStyle = (imageUrl: string) => {
    if (isBase64SVG(imageUrl)) {
        return {
            backgroundImage: `url("${imageUrl}")`,
            backgroundColor: '#f0f2f5',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        };
    }
    
    return {
        background: `url(${imageUrl}) center center / contain no-repeat`,
        backgroundColor: '#f0f2f5',
    };
}; 

// Helper function to check if a URL is accessible
const isValidImageUrl = async (url: string): Promise<boolean> => {
    try {
        // For data URLs, return true immediately
        if (url.startsWith('data:image')) {
            return true;
        }

        // For IPFS URLs, convert to HTTP gateway URL
        if (url.startsWith('ipfs://')) {
            url = url.replace('ipfs://', 'https://ipfs.io/ipfs/');
        }

        if (url === "Invalid uri") {
            return false;
        }

        // Try to fetch the URL with a HEAD request
        const response = await fetch(url, { 
            method: 'HEAD',
            mode: 'no-cors',
            credentials: 'omit',
            // Short timeout to avoid long waits
            signal: AbortSignal.timeout(3000)
        });
        
        // With no-cors, we can't check content-type
        // We'll assume the URL is valid if we get here without errors
        return true;
    } catch {
        return false;
    }
};

export const getNFTImage = async (nft: INFT, collection: INFTCollection): Promise<string> => {
    // Parse metadata if it exists
    let metadata = null;
    try {
        metadata = nft.metadata ? JSON.parse(nft.metadata) : null;
    } catch {
        metadata = null;
    }

    let tokenUri = null;
    try {
        tokenUri = nft?.token_uri ? JSON.parse(nft.token_uri) : null;
    } catch {
        tokenUri = null;
    }
    
    // Check each potential source in order of preference
    const possibleSources = [
        nft?.media,
        nft?.normalized_metadata?.image,
        tokenUri?.image,
        metadata?.image,
        metadata?.image_url,
        metadata?.iframe_url,
        metadata?.animation_url,        
        collection?.collection_logo,
        collection?.collection_banner_image
    ];

    // Try each source and validate it
    for (const source of possibleSources) {
        if (source && (
            source.startsWith('http') || 
            source.startsWith('data:image') || 
            source.startsWith('ipfs://')
        )) {
            console.log('source', source);
            if (await isValidImageUrl(source)) {
                console.log('Valid image URL:', source);
                return source;
            }
        }
    }

    // Final fallback
    return '/images/nft-placeholder.png';
};

export const getNFTCollectionImage = async (collection: INFTCollection): Promise<string> => {
    // Parse metadata if it exists
    let metadata = null;
    try {
        metadata = collection.metadata ? JSON.parse(collection.metadata) : null;
    } catch {
        metadata = null;
    }

    let tokenUri = null;
    try {
        tokenUri = collection?.token_uri ? JSON.parse(collection.token_uri) : null;
    } catch {
        tokenUri = null;
    }
    // Check each potential source in order of preference
    const possibleSources = [
        collection?.collection_banner_image,
        collection?.collection_logo,
        tokenUri?.image,
        metadata?.image,
        metadata?.image_url,
        metadata?.iframe_url,
        metadata?.animation_url
    ];

    // Try each source and validate it
    for (const source of possibleSources) {
        if (source && (
            source.startsWith('http') || 
            source.startsWith('data:image') || 
            source.startsWith('ipfs://')
        )) {
            console.log('source', source);
            if (await isValidImageUrl(source)) {
                console.log('Valid image URL:', source);
                return source;
            }
        }
    }

    // Final fallback
    return '/images/nft-placeholder.png';
};
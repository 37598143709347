import React from 'react';
import { Space, Typography, Tag, Avatar } from 'antd';

interface TransferCounts {
    native: number;
    erc20: number;
    nft: number;
    internal: number;
    contract: number;
}

interface ChainCounts {
    [key: string]: number;
}

interface TransactionSummaryProps {
    totalTxs: number;
    transfers: TransferCounts;
    chainCounts: ChainCounts;
    chainIdToName: Record<string, string>;
    cdn_domain_name: string;
}

export const TransactionSummary: React.FC<TransactionSummaryProps> = ({
    totalTxs,
    transfers,
    chainCounts,
    chainIdToName,
    cdn_domain_name,
}) => {
    return (
        <div style={{
            fontWeight: 'bold',
            backgroundColor: '#fafafa',
            position: 'sticky',
            left: 0,
            zIndex: 2,
            padding: '8px 16px'
        }}>
            <Space direction="vertical" size="small">
                <Typography.Text strong>
                    Total Transactions: {totalTxs}
                </Typography.Text>
                <Space wrap>
                    {transfers?.native > 0 && (
                        <Tag color="orange">
                            Native Transfers: {transfers.native}
                        </Tag>
                    )}
                    {transfers?.erc20 > 0 && (
                        <Tag color="blue">
                            ERC20 Transfers: {transfers.erc20}
                        </Tag>
                    )}
                    {transfers?.nft > 0 && (
                        <Tag color="purple">
                            NFT Transfers: {transfers.nft}
                        </Tag>
                    )}
                    {transfers?.internal > 0 && (
                        <Tag color="cyan">
                            Internal Txs: {transfers.internal}
                        </Tag>
                    )}
                    {transfers?.contract > 0 && (
                        <Tag color="red">
                            Contract Interactions: {transfers.contract}
                        </Tag>
                    )}
                </Space>
                <Space wrap>
                    {Object.entries(chainCounts || {}).map(([chainId, count]) => (
                        <Space key={chainId}>
                            <Avatar 
                                size="small"
                                src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                            />
                            <Typography.Text>
                                {String(chainIdToName[chainId] ?? chainId)}: {count}
                            </Typography.Text>
                        </Space>
                    ))}
                </Space>
            </Space>
        </div>
    );
}; 
/**
 * Utility for caching ABIs across the application
 */

interface CachedABI {
  abi: any[];
  timestamp: number;
  source: 'manual' | 'fetched' | 'proxy' | 'custom';
}

// Global ABI cache
const abiCacheStore: Record<string, CachedABI> = {};

/**
 * ABI cache utility
 */
export const abiCache = {
  /**
   * Get an ABI from the cache
   * @param chainId The chain ID
   * @param address The contract address
   * @returns The cached ABI or undefined if not found
   */
  get: (chainId: string, address: string): CachedABI | undefined => {
    const cacheKey = `${chainId}:${address.toLowerCase()}`;
    return abiCacheStore[cacheKey];
  },

  /**
   * Store an ABI in the cache
   * @param chainId The chain ID
   * @param address The contract address
   * @param abi The ABI to cache
   * @param source The source of the ABI
   */
  set: (
    chainId: string, 
    address: string, 
    abi: any[], 
    source: 'manual' | 'fetched' | 'proxy' | 'custom'
  ): void => {
    const cacheKey = `${chainId}:${address.toLowerCase()}`;
    abiCacheStore[cacheKey] = {
      abi,
      timestamp: Date.now(),
      source
    };
  },

  /**
   * Check if an ABI exists in the cache
   * @param chainId The chain ID
   * @param address The contract address
   * @returns True if the ABI is cached
   */
  has: (chainId: string, address: string): boolean => {
    const cacheKey = `${chainId}:${address.toLowerCase()}`;
    return !!abiCacheStore[cacheKey];
  },

  /**
   * Remove an ABI from the cache
   * @param chainId The chain ID
   * @param address The contract address
   */
  remove: (chainId: string, address: string): void => {
    const cacheKey = `${chainId}:${address.toLowerCase()}`;
    delete abiCacheStore[cacheKey];
  },

  /**
   * Clear all ABIs from the cache
   */
  clear: (): void => {
    Object.keys(abiCacheStore).forEach(key => {
      delete abiCacheStore[key];
    });
  },

  /**
   * Get all cached ABIs
   * @returns An object with all cached ABIs
   */
  getAll: (): Record<string, CachedABI> => {
    return { ...abiCacheStore };
  },

  /**
   * Get the number of cached ABIs
   * @returns The number of cached ABIs
   */
  size: (): number => {
    return Object.keys(abiCacheStore).length;
  }
}; 
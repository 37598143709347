import { Space, Avatar, Typography, Tooltip, Tag, Descriptions } from 'antd';
import type { TableColumnType } from 'antd';
import type { Transaction } from '../types';
import { AddressFilterDropdown } from '../components/AddressFilterDropdown';
import { Key } from 'react';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { IAddress } from 'interfaces/address';
import { IAddressLabel } from 'interfaces/label';
import { StarFilled } from '@ant-design/icons';
import { renderAddressAvatar } from '../../../address-avatar';

export const getFromColumn = (cdn_domain_name: string, transactions: Transaction[], address_items_map: Record<string, IAddress>): TableColumnType<Transaction> => ({
    title: 'From',
    dataIndex: 'from_address',
    key: 'from_address',
    width: 300,
    filters: transactions
        .map(tx => ({
            text: tx.from_address_label || tx.from_address_entity || 
                 (address_items_map[tx.from_address]?.labels?.[0]?.name) || 
                 `${tx.from_address?.slice(0, 6)}...${tx.from_address?.slice(-6)}`,
            value: tx.from_address
        }))
        .filter((filter, index, self) => 
            index === self.findIndex(f => f.value === filter.value)
        ),
    filterDropdown: (props: FilterDropdownProps) => (
        <AddressFilterDropdown
            {...props}
            transactions={transactions}
            addressField="from_address"
            labelField="from_address_label"
            entityField="from_address_entity"
            logoField="from_address_entity_logo"
        />
    ),
    onFilter: (value: boolean | Key, record: Transaction) => {
        return record.from_address === value;
    },
    sorter: (a: Transaction, b: Transaction) => {
        const labelA = a.from_address_label || a.from_address_entity || a.from_address || '';
        const labelB = b.from_address_label || b.from_address_entity || b.from_address || '';
        return labelA.localeCompare(labelB);
    },
    render: (address: string, record: Transaction) => {
        const truncatedAddress = address ? `${address.slice(0, 6)}...${address.slice(-6)}` : '';
        return (
            <Space direction="vertical" size={2}>
                <Tooltip title={address}>
                    <Typography.Text 
                        copyable={{ text: address }} 
                        style={{ whiteSpace: 'nowrap' }}
                        data-full-address={address}
                    >
                        {truncatedAddress}
                    </Typography.Text>
                </Tooltip>
                {renderAddressAvatar(
                    record,
                    'from',
                    address,
                    {
                        maxGlobalLabels: 3,
                        maxUserLabels: 3,
                        maxTotalLabels: 5,
                        maxGlobalEntities: 3,
                        maxUserEntities: 3,
                        maxTotalEntities: 5,
                        displayStyle: 'avatars',
                        hideAddress: true // Hide the address from renderAddressAvatar since we're showing it separately
                    },
                    address_items_map
                )}
            </Space>
        );
    },
}); 
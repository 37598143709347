import React, { useMemo, useState } from 'react';
import { 
  Space, 
  Typography, 
  Tag, 
  Empty, 
  List, 
  Avatar, 
  Card, 
  Tooltip, 
  Collapse,
  Divider,
  Tabs,
  Select,
  Alert,
  Badge,
  Button
} from 'antd';
import { 
  TeamOutlined, 
  LinkOutlined, 
  SafetyOutlined, 
  KeyOutlined,
  LockOutlined,
  ApiOutlined,
  BankOutlined,
  DollarOutlined,
  HistoryOutlined,
  GlobalOutlined,
  BlockOutlined,
  SyncOutlined
} from '@ant-design/icons';

const { Text, Title, Paragraph } = Typography;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;

// Import the same types from the parent component
import { ContractStateData, AddressItemsMap } from './ContractStateHighlighter';

interface ContractRelationshipAnalyzerProps {
  contractStateData: ContractStateData;
  formatAddress: (address: string, addressItemsMap?: AddressItemsMap) => any;
  getChainName?: (chainId: string) => string;
}

const ContractRelationshipAnalyzer: React.FC<ContractRelationshipAnalyzerProps> = ({
  contractStateData,
  formatAddress,
  getChainName = (chainId) => chainId // Default implementation if not provided
}) => {
  // State for selected block type and chain
  const [selectedBlockType, setSelectedBlockType] = useState<'before' | 'after' | 'latest'>('latest');
  const [selectedChain, setSelectedChain] = useState<string | 'all'>('all');
  
  // Get all available chains
  const availableChains = useMemo(() => {
    const chains = new Set<string>();
    
    Object.values(contractStateData?.contracts || {}).forEach((contract: any) => {
      if (contract.chainId) {
        chains.add(contract.chainId);
      }
    });
    
    return Array.from(chains);
  }, [contractStateData?.contracts]);
  
  // Safe access to address_items_map
  const addressItemsMap = useMemo(() => {
    return contractStateData?.address_items_map || {};
  }, [contractStateData]);

  // Extract all addresses
  const addresses = useMemo(() => {
    return Object.keys(addressItemsMap);
  }, [addressItemsMap]);

  // Find contracts in the address map
  const contractAddresses = useMemo(() => {
    return addresses.filter(addr => addressItemsMap[addr]?.is_contract);
  }, [addresses, addressItemsMap]);

  // Get filtered contracts based on selected chain
  const filteredContracts = useMemo(() => {
    const contracts = Object.values(contractStateData?.contracts || {});
    
    if (selectedChain === 'all') {
      return contracts;
    }
    
    return contracts.filter((contract: any) => contract.chainId === selectedChain);
  }, [contractStateData?.contracts, selectedChain]);

  // Find multi-sig wallets by analyzing contract data for the selected block type and chain
  const multiSigWallets = useMemo(() => {
    const wallets: Record<string, {
      address: string;
      owners: string[];
      threshold: number;
      implementation?: string;
      chainId: string;
      blockNumber: string | number;
      blockType: string;
    }> = {};

    // Look through filtered contracts
    filteredContracts.forEach((contract: any) => {
      // Get state based on selected block type
      const state = contract.states?.[selectedBlockType]?.[0]?.state;
      if (!state) return;
      
      const blockNumber = contract.states?.[selectedBlockType]?.[0]?.blockNumber || 'unknown';
      
      // Check if the contract has an owner that is a multi-sig
      if (state.owner?.isContract && 
          state.owner?.contractDetails?.getOwners && 
          Array.isArray(state.owner.contractDetails.getOwners)) {
        
        const ownerAddress = state.owner.address.toLowerCase();
        const owners = state.owner.contractDetails.getOwners.map((o: any) => o.address.toLowerCase());
        const threshold = state.owner.contractDetails.getThreshold || 0;
        const implementation = state.owner.contractDetails._proxy?.implementation;
        
        wallets[`${ownerAddress}-${contract.chainId}`] = {
          address: ownerAddress,
          owners,
          threshold,
          implementation,
          chainId: contract.chainId,
          blockNumber,
          blockType: selectedBlockType
        };
      }
      
      // Also check if the contract itself is a multi-sig
      if (state.getOwners && Array.isArray(state.getOwners)) {
        const contractAddress = contract.address.toLowerCase();
        const owners = state.getOwners.map((o: any) => 
          typeof o === 'object' ? o.address.toLowerCase() : o.toLowerCase()
        );
        const threshold = state.getThreshold || 0;
        const implementation = state._proxy?.implementation;
        
        wallets[`${contractAddress}-${contract.chainId}`] = {
          address: contractAddress,
          owners,
          threshold,
          implementation,
          chainId: contract.chainId,
          blockNumber,
          blockType: selectedBlockType
        };
      }
    });

    return wallets;
  }, [filteredContracts, selectedBlockType]);

  // Identify key relationships for the selected block type and chain
  const relationships = useMemo(() => {
    const result: {
      type: string;
      from: string;
      to: string;
      description: string;
      chainId: string;
      blockNumber: string | number;
      blockType: string;
      details?: any;
    }[] = [];

    // Add multi-sig relationships
    Object.values(multiSigWallets).forEach(wallet => {
      wallet.owners.forEach(owner => {
        result.push({
          type: 'ownership',
          from: owner,
          to: wallet.address,
          description: `Owner of multi-sig wallet (${wallet.threshold} of ${wallet.owners.length} required)`,
          chainId: wallet.chainId,
          blockNumber: wallet.blockNumber,
          blockType: wallet.blockType,
          details: {
            threshold: wallet.threshold,
            totalOwners: wallet.owners.length
          }
        });
      });

      // Add implementation relationship if it's a proxy
      if (wallet.implementation) {
        result.push({
          type: 'implementation',
          from: wallet.address,
          to: wallet.implementation.toLowerCase(),
          description: 'Proxy implementation',
          chainId: wallet.chainId,
          blockNumber: wallet.blockNumber,
          blockType: wallet.blockType,
          details: {
            isProxy: true
          }
        });
      }
    });

    // Add owner relationships from contract states
    filteredContracts.forEach((contract: any) => {
      const state = contract.states?.[selectedBlockType]?.[0]?.state;
      if (!state) return;
      
      const blockNumber = contract.states?.[selectedBlockType]?.[0]?.blockNumber || 'unknown';
      
      // Check for owner relationship
      if (state.owner && typeof state.owner === 'object' && state.owner.address) {
        result.push({
          type: 'ownership',
          from: state.owner.address.toLowerCase(),
          to: contract.address.toLowerCase(),
          description: 'Owner of contract',
          chainId: contract.chainId,
          blockNumber,
          blockType: selectedBlockType,
          details: {
            isContract: state.owner.isContract
          }
        });
      }
      
      // Check for admin relationship
      if (state.admin && typeof state.admin === 'object' && state.admin.address) {
        result.push({
          type: 'admin',
          from: state.admin.address.toLowerCase(),
          to: contract.address.toLowerCase(),
          description: 'Admin of contract',
          chainId: contract.chainId,
          blockNumber,
          blockType: selectedBlockType
        });
      }
      
      // Check for guardian relationship
      if (state.guardian && typeof state.guardian === 'object' && state.guardian.address) {
        result.push({
          type: 'guardian',
          from: state.guardian.address.toLowerCase(),
          to: contract.address.toLowerCase(),
          description: 'Guardian of contract',
          chainId: contract.chainId,
          blockNumber,
          blockType: selectedBlockType
        });
      }
      
      // Check for implementation relationship
      if (state.implementation && typeof state.implementation === 'string') {
        result.push({
          type: 'implementation',
          from: contract.address.toLowerCase(),
          to: state.implementation.toLowerCase(),
          description: 'Proxy implementation',
          chainId: contract.chainId,
          blockNumber,
          blockType: selectedBlockType,
          details: {
            isProxy: true
          }
        });
      }
      
      // Check for _proxy.implementation relationship
      if (state._proxy && state._proxy.implementation && typeof state._proxy.implementation === 'string') {
        result.push({
          type: 'implementation',
          from: contract.address.toLowerCase(),
          to: state._proxy.implementation.toLowerCase(),
          description: 'Proxy implementation',
          chainId: contract.chainId,
          blockNumber,
          blockType: selectedBlockType,
          details: {
            isProxy: true
          }
        });
      }
    });

    return result;
  }, [filteredContracts, multiSigWallets, selectedBlockType]);

  // Group addresses by entity
  const addressesByEntity = useMemo(() => {
    const grouped: Record<string, string[]> = {
      'Unlabeled': []
    };
    
    addresses.forEach(address => {
      const metadata = addressItemsMap[address];
      let assigned = false;
      
      // Group by entity if available
      if (metadata?.entities && Array.isArray(metadata.entities) && metadata.entities.length > 0) {
        metadata.entities.forEach((entity: any) => {
          const entityName = typeof entity === 'object' ? (entity.name || entity.id || 'Unknown Entity') : String(entity);
          
          if (!grouped[entityName]) {
            grouped[entityName] = [];
          }
          
          grouped[entityName].push(address);
          assigned = true;
        });
      }
      
      // Group by label if no entity
      if (!assigned && metadata?.labels && Array.isArray(metadata.labels) && metadata.labels.length > 0) {
        metadata.labels.forEach((label: any) => {
          const labelName = typeof label === 'object' ? (label.name || label.address_name || label.id || 'Unknown Label') : String(label);
          
          if (!grouped[labelName]) {
            grouped[labelName] = [];
          }
          
          grouped[labelName].push(address);
          assigned = true;
        });
      }
      
      // Add to unlabeled if no entity or label
      if (!assigned) {
        grouped['Unlabeled'].push(address);
      }
    });
    
    // Remove empty groups
    Object.keys(grouped).forEach(key => {
      if (grouped[key].length === 0) {
        delete grouped[key];
      }
    });
    
    return grouped;
  }, [addresses, addressItemsMap]);

  // Helper function to render an address
  const renderAddress = (address: string) => {
    const metadata = addressItemsMap[address.toLowerCase()] || addressItemsMap[address];
    
    // Get labels if available
    const labels = metadata?.labels || [];
    const labelName = labels.length > 0 && typeof labels[0] === 'object' ? 
      (labels[0].name || labels[0].address_name || '') : '';
    
    return (
      <Tooltip title={address}>
        <Space>
          <Text copyable={{ text: address }} style={{ cursor: 'pointer' }}>
            {labelName || formatAddress(address, addressItemsMap)}
          </Text>
          {metadata?.verified && <Badge status="success" text="Verified" />}
        </Space>
      </Tooltip>
    );
  };

  // Helper function to render multi-sig wallet details
  const renderMultiSigWalletDetails = (walletKey: string) => {
    const wallet = multiSigWallets[walletKey.toLowerCase()] || 
                  multiSigWallets[`${walletKey.toLowerCase()}-${selectedChain}`];
    
    if (!wallet) return <Text type="secondary">No details available</Text>;
    
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <Text strong>Chain: </Text>
          <Tag color="blue">{getChainName(wallet.chainId)}</Tag>
        </div>
        <div>
          <Text strong>Block: </Text>
          <Tag color="purple">{wallet.blockNumber} ({wallet.blockType})</Tag>
        </div>
        <div>
          <Text strong>Threshold: </Text>
          <Text>{wallet.threshold} of {wallet.owners.length} required</Text>
        </div>
        <div>
          <Text strong>Owners: </Text>
          <List
            size="small"
            dataSource={wallet.owners}
            renderItem={(owner) => (
              <List.Item>
                {renderAddress(owner)}
              </List.Item>
            )}
          />
        </div>
        {wallet.implementation && (
          <div>
            <Text strong>Implementation: </Text>
            <div>{renderAddress(wallet.implementation)}</div>
          </div>
        )}
      </Space>
    );
  };

  // Check if we have any data to display
  if (addresses.length === 0 && Object.keys(contractStateData?.contracts || {}).length === 0) {
    return <Empty description="No relationship data available" />;
  }

  return (
    <div style={{ width: '100%' }}>
      <Space direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
        <Alert
          message="Relationship Analysis"
          description={
            <Paragraph>
              This view shows relationships between addresses based on contract state data. 
              You can filter by block type and chain to see how relationships evolve over time and across chains.
            </Paragraph>
          }
          type="info"
          showIcon
        />
        
        <Card>
          <Space style={{ marginBottom: 16 }}>
            <Text strong>Block Type:</Text>
            <Select 
              value={selectedBlockType} 
              onChange={setSelectedBlockType}
              style={{ width: 150 }}
            >
              <Option value="before">Before Transaction</Option>
              <Option value="after">After Transaction</Option>
              <Option value="latest">Latest Block</Option>
            </Select>
            
            <Text strong>Chain:</Text>
            <Select 
              value={selectedChain} 
              onChange={setSelectedChain}
              style={{ width: 200 }}
            >
              <Option value="all">All Chains</Option>
              {availableChains.map(chain => (
                <Option key={chain} value={chain}>
                  {getChainName(chain)} ({chain})
                </Option>
              ))}
            </Select>
          </Space>
          
          <Divider />
          
          <Tabs defaultActiveKey="relationships">
            <TabPane 
              tab={<Space><LinkOutlined /> <span>Relationships</span></Space>} 
              key="relationships"
            >
              {relationships.length > 0 ? (
                <List
                  itemLayout="horizontal"
                  dataSource={relationships}
                  renderItem={(rel) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar icon={
                            rel.type === 'ownership' ? <KeyOutlined /> :
                            rel.type === 'implementation' ? <ApiOutlined /> :
                            rel.type === 'admin' ? <LockOutlined /> :
                            <SafetyOutlined />
                          } />
                        }
                        title={
                          <Space>
                            <Tag color={
                              rel.type === 'ownership' ? 'green' :
                              rel.type === 'implementation' ? 'purple' :
                              rel.type === 'admin' ? 'orange' :
                              'blue'
                            }>
                              {rel.type.charAt(0).toUpperCase() + rel.type.slice(1)}
                            </Tag>
                            <Text>{rel.description}</Text>
                            <Tag color="blue">{getChainName(rel.chainId)}</Tag>
                            <Tag color="purple">Block {rel.blockNumber} ({rel.blockType})</Tag>
                          </Space>
                        }
                        description={
                          <Space direction="vertical" style={{ width: '100%' }}>
                            <div>
                              <Text strong>From: </Text>
                              {renderAddress(rel.from)}
                            </div>
                            <div>
                              <Text strong>To: </Text>
                              {renderAddress(rel.to)}
                            </div>
                          </Space>
                        }
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <Empty description={`No relationships found for the selected block type${selectedChain !== 'all' ? ' and chain' : ''}`} />
              )}
            </TabPane>
            
            <TabPane 
              tab={<Space><SafetyOutlined /> <span>Multi-Sig Wallets</span></Space>} 
              key="multisig"
            >
              {Object.keys(multiSigWallets).length > 0 ? (
                <List
                  itemLayout="horizontal"
                  dataSource={Object.values(multiSigWallets)}
                  renderItem={(wallet) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar icon={<SafetyOutlined />} />}
                        title={
                          <Space>
                            {renderAddress(wallet.address)}
                            <Tag color="green">Multi-Signature Wallet</Tag>
                            <Tag color="blue">{getChainName(wallet.chainId)}</Tag>
                            <Tag color="purple">Block {wallet.blockNumber} ({wallet.blockType})</Tag>
                          </Space>
                        }
                        description={
                          <Space direction="vertical" style={{ width: '100%' }}>
                            <Text>
                              {wallet.threshold} of {wallet.owners.length} signatures required
                            </Text>
                            <Collapse ghost>
                              <Panel header="View Details" key="1">
                                {renderMultiSigWalletDetails(`${wallet.address}-${wallet.chainId}`)}
                              </Panel>
                            </Collapse>
                          </Space>
                        }
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <Empty description={`No multi-signature wallets found for the selected block type${selectedChain !== 'all' ? ' and chain' : ''}`} />
              )}
            </TabPane>
            
            <TabPane 
              tab={<Space><TeamOutlined /> <span>Address Groups</span></Space>} 
              key="groups"
            >
              <Card>
                <Collapse>
                  {Object.entries(addressesByEntity).map(([entity, addrs]) => (
                    <Panel 
                      header={
                        <Space>
                          <Text strong>{entity}</Text>
                          <Tag color="blue">{addrs.length} addresses</Tag>
                        </Space>
                      } 
                      key={entity}
                    >
                      <List
                        size="small"
                        dataSource={addrs}
                        renderItem={(addr) => {
                          const isMultiSig = Object.values(multiSigWallets).some(
                            wallet => wallet.address.toLowerCase() === addr.toLowerCase()
                          );
                          
                          return (
                            <List.Item>
                              {renderAddress(addr)}
                              {isMultiSig && (
                                <Tag color="green">Multi-Sig Wallet</Tag>
                              )}
                              {contractAddresses.includes(addr) && !isMultiSig && (
                                <Tag color="purple">Contract</Tag>
                              )}
                            </List.Item>
                          );
                        }}
                      />
                    </Panel>
                  ))}
                </Collapse>
              </Card>
            </TabPane>
            
            <TabPane 
              tab={<Space><BankOutlined /> <span>All Addresses</span></Space>} 
              key="addresses"
            >
              <List
                itemLayout="horizontal"
                dataSource={addresses}
                renderItem={(address) => {
                  const metadata = addressItemsMap[address];
                  const isMultiSig = Object.values(multiSigWallets).some(
                    wallet => wallet.address.toLowerCase() === address.toLowerCase()
                  );
                  
                  return (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar icon={
                            isMultiSig ? <SafetyOutlined /> :
                            metadata?.is_contract ? <ApiOutlined /> :
                            <TeamOutlined />
                          } />
                        }
                        title={renderAddress(address)}
                        description={
                          <Space direction="vertical" style={{ width: '100%' }}>
                            {isMultiSig && (
                              <div>
                                <Tag color="green">Multi-Signature Wallet</Tag>
                                <Collapse ghost>
                                  <Panel header="View Details" key="1">
                                    {Object.keys(multiSigWallets).filter(key => 
                                      key.startsWith(address.toLowerCase())
                                    ).map(key => (
                                      <Card key={key} size="small" style={{ marginBottom: 8 }}>
                                        {renderMultiSigWalletDetails(key)}
                                      </Card>
                                    ))}
                                  </Panel>
                                </Collapse>
                              </div>
                            )}
                            
                            {metadata?.tags && Array.isArray(metadata.tags) && metadata.tags.length > 0 && (
                              <div>
                                <Text strong>Tags: </Text>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', marginTop: '4px' }}>
                                  {metadata.tags.map((tag: any, index: number) => {
                                    if (typeof tag === 'object' && tag !== null) {
                                      return (
                                        <Tag 
                                          key={`tag-${index}`} 
                                          color={tag.color ? `#${tag.color.replace(/^#/, '')}` : 'cyan'}
                                        >
                                          {tag.name || tag.id || 'Tag'}
                                        </Tag>
                                      );
                                    }
                                    return <Tag key={`tag-${index}`} color="cyan">{String(tag)}</Tag>;
                                  })}
                                </div>
                              </div>
                            )}
                            
                            {metadata?.entities && Array.isArray(metadata.entities) && metadata.entities.length > 0 && (
                              <div>
                                <Text strong>Entities: </Text>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', marginTop: '4px' }}>
                                  {metadata.entities.map((entity: any, index: number) => {
                                    if (typeof entity === 'object' && entity !== null) {
                                      return (
                                        <Tag 
                                          key={`entity-${index}`} 
                                          color={entity.color ? `#${entity.color.replace(/^#/, '')}` : 'purple'}
                                        >
                                          {entity.name || entity.id || 'Entity'}
                                        </Tag>
                                      );
                                    }
                                    return <Tag key={`entity-${index}`} color="purple">{String(entity)}</Tag>;
                                  })}
                                </div>
                              </div>
                            )}
                          </Space>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            </TabPane>
          </Tabs>
        </Card>
      </Space>
    </div>
  );
};

export default ContractRelationshipAnalyzer; 
import { Edit, useForm, useSelect } from "@refinedev/antd";
import {
    Form,
    Input,
    Select,
    DatePicker,
    Card,
    Space,
    Typography,
    Tag,
    Row,
    Col,
    Button,
    notification,
    Avatar,
    Upload,
    Tooltip,
    InputNumber,
    Divider,
    ColorPicker,
} from "antd";
import { PlusOutlined, MinusCircleOutlined, InboxOutlined, UploadOutlined, DeleteOutlined, EditOutlined, PictureOutlined } from "@ant-design/icons";
import { useState } from "react";
import { IEntity, EntityType, EntityStatus } from "../../interfaces/entity.d";
import { useConfig } from "contexts";
import dayjs from 'dayjs';
import { ChainType, ChainName, ChainId, CHAIN_MAPPING } from "../../interfaces/chain.d";
import { message } from "antd";
import React from "react";

const { Text, Title } = Typography;

// Create options with logo URLs
const CHAIN_OPTIONS = Object.entries(CHAIN_MAPPING)
    .filter(([_, name]) => !name.includes('_testnet'))
    .map(([chainId, name]) => ({
        label: (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Avatar 
                    size="small"
                    src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                    style={{ backgroundColor: 'transparent' }}
                />
                {name.charAt(0).toUpperCase() + name.slice(1)}
            </div>
        ),
        value: chainId as ChainId
    }));
        

export const EntityEdit: React.FC = () => {
    const { token, region } = useConfig();
    const [logoUrl, setLogoUrl] = useState<string | null>(null);
    const [uploading, setUploading] = useState(false);
    const [entityColor, setEntityColor] = useState<string | null>(null);

    const { formProps, saveButtonProps, queryResult } = useForm<IEntity>({
    });

    // Set initial values when data is loaded
    React.useEffect(() => {
        if (queryResult?.data?.data) {
            const entity = queryResult.data.data;
            
            // Set logo URL and color
            if (entity.logo) {
                setLogoUrl(entity.logo);
            }
            if (entity.color) {
                setEntityColor(entity.color);
            }
            
            // Log the addresses from the API
            console.log("Entity addresses from API:", entity.addresses);
            
            // Transform addresses to form format
            const formattedAddresses = entity.addresses?.map(addr => ({
                address: addr.address,
                chainIds: addr.chain_ids || [],
                mainChainIds: addr.main_chain_ids || [],
            })) || [];
            
            console.log("Transformed addresses for form:", formattedAddresses);
            
            // Set form values with proper transformations
            formProps.form?.setFieldsValue({
                ...entity,
                // Transform timestamps to dayjs objects
                first_seen: entity.first_seen ? dayjs.unix(entity.first_seen) : undefined,
                last_activity: entity.last_activity ? dayjs.unix(entity.last_activity) : undefined,
                last_risk_assessment: entity.last_risk_assessment ? 
                    dayjs.unix(entity.last_risk_assessment) : undefined,
                
                // Transform metadata to array format
                metadata: Object.entries(entity.metadata || {}).map(([key, value]) => ({
                    key,
                    value,
                })),
                
                // Transform external_ids to array format
                external_ids: Object.entries(entity.external_ids || {}).map(([platform, id]) => ({
                    platform,
                    id,
                })),
                
                // Set the transformed addresses
                addresses: formattedAddresses,
            });
        }
    }, [queryResult?.data?.data, formProps.form]);

    // Load related entities
    const { selectProps: parentEntitySelectProps } = useSelect({
        resource: "entities",
        optionLabel: "name",
        optionValue: "id",
        filters: [
            {
                field: "type",
                operator: "in",
                value: [EntityType.COMPANY, EntityType.DAO],
            },
        ],
    });

    const { selectProps: relatedEntitiesSelectProps } = useSelect({
        resource: "entities",
        optionLabel: "name",
        optionValue: "id",
        filters: [
            {
                field: "status",
                operator: "eq",
                value: EntityStatus.ACTIVE,
            },
        ],
    });

    // Transform form data before submission
    const handleSubmit = async (values: any) => {
        try {
            // Get all unique chains where any address exists
            const chain_ids = Array.from(new Set(
                values.addresses.flatMap((addr: any) => addr.chainIds)
            ));
            
            // Get all unique chains where this entity is the main entity
            const main_chain_ids = Array.from(new Set(
                values.addresses.flatMap((addr: any) => 
                    addr.mainChainIds || []
                )
            ));

            const transformedData = {
                ...values,
                chain_ids,
                main_chain_ids,
                addresses: values.addresses.map(({ address, chainIds, mainChainIds }: any) => ({
                    address,
                    chain_ids: chainIds || [],
                    main_chain_ids: mainChainIds || []
                })),
                metadata: values.metadata?.reduce((acc: any, { key, value }: any) => ({
                    ...acc,
                    [key]: value,
                }), {}) || {},
                external_ids: values.external_ids?.reduce((acc: any, { platform, id }: any) => ({
                    ...acc,
                    [platform]: id,
                }), {}) || {},
                first_seen: values.first_seen ? dayjs(values.first_seen).unix() : undefined,
                last_activity: values.last_activity ? dayjs(values.last_activity).unix() : undefined,
                last_risk_assessment: values.last_risk_assessment ? 
                    dayjs(values.last_risk_assessment).unix() : undefined,
            };

            await formProps.onFinish?.(transformedData);

            notification.success({
                message: 'Success',
                description: 'Entity updated successfully',
            });
        } catch (error: any) {
            notification.error({
                message: 'Error',
                description: error.message || 'Error updating entity',
            });
        }
    };

    // Handle logo removal
    const handleRemoveLogo = () => {
        setLogoUrl(null);
        formProps.form?.setFieldValue('logo', null);
        message.success('Logo removed');
    };

    // Handle color change
    const handleColorChange = (color: any) => {
        // Remove the # from the hex color
        const hexColor = color.toHex().replace('#', '');
        setEntityColor(hexColor);
        formProps.form?.setFieldValue('color', hexColor);
    };

    return (
        <Edit saveButtonProps={saveButtonProps} isLoading={queryResult?.isLoading}>
            <Form 
                {...formProps}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <Row gutter={[24, 24]}>
                    {/* Logo Column */}
                    <Col xs={24} sm={24} md={8} lg={6}>
                        <Card 
                            title={
                                <Space>
                                    <PictureOutlined />
                                    <span>Entity Logo</span>
                                </Space>
                            }
                            bordered
                            style={{ height: '100%' }}
                        >
                            <Form.Item
                                name="logo"
                                style={{ marginBottom: 0 }}
                            >
                                <div style={{ 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '16px',
                                    padding: '16px'
                                }}>
                                    {logoUrl ? (
                                        <>
                                            <div style={{ 
                                                position: 'relative',
                                                width: '150px',
                                                height: '150px',
                                                borderRadius: '8px',
                                                overflow: 'hidden',
                                                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                                                border: '1px solid #f0f0f0'
                                            }}>
                                                <Avatar 
                                                    src={logoUrl} 
                                                    size={150}
                                                    shape="square"
                                                    style={{ 
                                                        width: '100%', 
                                                        height: '100%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    background: 'rgba(0,0,0,0.6)',
                                                    padding: '4px',
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Space>
                                                        <Tooltip title="Change logo">
                                                            <Button 
                                                                type="text" 
                                                                icon={<EditOutlined />} 
                                                                size="small"
                                                                style={{ color: 'white' }}
                                                                onClick={() => document.getElementById('logo-upload')?.click()}
                                                            />
                                                        </Tooltip>
                                                        <Tooltip title="Remove logo">
                                                            <Button 
                                                                type="text" 
                                                                danger 
                                                                icon={<DeleteOutlined />} 
                                                                size="small"
                                                                style={{ color: 'white' }}
                                                                onClick={handleRemoveLogo}
                                                            />
                                                        </Tooltip>
                                                    </Space>
                                                </div>
                                            </div>
                                            <Text type="secondary">
                                                Current logo
                                            </Text>
                                        </>
                                    ) : (
                                        <Upload.Dragger
                                            id="logo-upload"
                                            name="file"
                                            action="https://us.api.sandbox.authe.io/private/labels/blue/ANY/US/v0/labels/upload-image"
                                            headers={{
                                                Authorization: token ? `Bearer ${token.__raw}` : "",
                                                Accept: "application/json, text/plain, */*",
                                                "Source-Platform": "dashboard",
                                                "Source-Region": region.use_region,
                                                "Destination-Region": region.use_region,
                                                "filename": `entity_logo_${Date.now()}.png`
                                            }}
                                            listType="picture"
                                            maxCount={1}
                                            showUploadList={false}
                                            beforeUpload={() => {
                                                setUploading(true);
                                                return true;
                                            }}
                                            onChange={(info) => {
                                                if (info.file.status === 'uploading') {
                                                    setUploading(true);
                                                }
                                                if (info.file.status === 'done') {
                                                    setUploading(false);
                                                    message.success(`Logo uploaded successfully`);
                                                    // Get the URL from the response
                                                    const imageUrl = info.file.response.url;
                                                    // Set it to the logo field
                                                    formProps.form?.setFieldValue('logo', imageUrl);
                                                    setLogoUrl(imageUrl);
                                                } else if (info.file.status === 'error') {
                                                    setUploading(false);
                                                    message.error(`Logo upload failed`);
                                                }
                                            }}
                                            style={{
                                                background: '#fafafa',
                                                border: '1px dashed #d9d9d9',
                                                borderRadius: '8px',
                                                padding: '24px',
                                                transition: 'border-color 0.3s',
                                                cursor: 'pointer',
                                                height: '180px',
                                                width: '100%'
                                            }}
                                        >
                                            <div style={{ padding: '8px' }}>
                                                <p className="ant-upload-drag-icon" style={{ marginBottom: '8px' }}>
                                                    <InboxOutlined style={{ fontSize: '32px', color: '#40a9ff' }} />
                                                </p>
                                                <p className="ant-upload-text" style={{ margin: '8px 0' }}>
                                                    Click or drag logo to upload
                                                </p>
                                                <p className="ant-upload-hint" style={{ fontSize: '12px', color: '#888' }}>
                                                    Recommended: 256×256px PNG or SVG
                                                </p>
                                            </div>
                                        </Upload.Dragger>
                                    )}
                                    
                                    {logoUrl && (
                                        <Upload
                                            id="logo-upload"
                                            name="file"
                                            action="https://us.api.sandbox.authe.io/private/labels/blue/ANY/US/v0/labels/upload-image"
                                            headers={{
                                                Authorization: token ? `Bearer ${token.__raw}` : "",
                                                Accept: "application/json, text/plain, */*",
                                                "Source-Platform": "dashboard",
                                                "Source-Region": region.use_region,
                                                "Destination-Region": region.use_region,
                                                "filename": `entity_logo_${Date.now()}.png`
                                            }}
                                            showUploadList={false}
                                            beforeUpload={() => {
                                                setUploading(true);
                                                return true;
                                            }}
                                            onChange={(info) => {
                                                if (info.file.status === 'uploading') {
                                                    setUploading(true);
                                                }
                                                if (info.file.status === 'done') {
                                                    setUploading(false);
                                                    message.success(`Logo uploaded successfully`);
                                                    // Get the URL from the response
                                                    const imageUrl = info.file.response.url;
                                                    // Set it to the logo field
                                                    formProps.form?.setFieldValue('logo', imageUrl);
                                                    setLogoUrl(imageUrl);
                                                } else if (info.file.status === 'error') {
                                                    setUploading(false);
                                                    message.error(`Logo upload failed`);
                                                }
                                            }}
                                        >
                                            <Button 
                                                icon={<UploadOutlined />} 
                                                loading={uploading}
                                                style={{ width: '100%' }}
                                            >
                                                Upload New Logo
                                            </Button>
                                        </Upload>
                                    )}
                                </div>
                            </Form.Item>
                        </Card>
                    </Col>

                    {/* Entity Details Column */}
                    <Col xs={24} sm={24} md={16} lg={18}>
                        <Card 
                            title={
                                <Space>
                                    <span>Entity Details</span>
                                </Space>
                            }
                            bordered
                        >
                            <Form 
                                {...formProps}
                                onFinish={handleSubmit}
                                layout="vertical"
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Name"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter the entity name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Type"
                                            name="type"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select the entity type',
                                                },
                                            ]}
                                        >
                                            <Select
                                                options={[
                                                    { label: "Exchange", value: "exchange" },
                                                    { label: "DeFi", value: "defi" },
                                                    { label: "NFT", value: "nft" },
                                                    { label: "DAO", value: "dao" },
                                                    { label: "CEX", value: "cex" },
                                                    { label: "DEX", value: "dex" },
                                                    { label: "Bridge", value: "bridge" },
                                                    { label: "Wallet", value: "wallet" },
                                                    { label: "Other", value: "other" },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Description"
                                            name="description"
                                        >
                                            <Input.TextArea rows={4} />
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col span={12}>
                                        <Form.Item
                                            label="Website"
                                            name="website"
                                        >
                                            <Input addonBefore="https://" placeholder="example.com" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Twitter"
                                            name="twitter"
                                        >
                                            <Input addonBefore="@" placeholder="username" />
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col span={12}>
                                        <Form.Item
                                            label="Color"
                                            name="color"
                                            tooltip="Entity color used for visual identification"
                                        >
                                            <ColorPicker
                                                format="hex"
                                                value={entityColor ? `#${entityColor}` : undefined}
                                                onChange={handleColorChange}
                                                styles={{
                                                    popupOverlayInner: {
                                                        width: 468 + 24,
                                                    },
                                                }}
                                                presets={[
                                                    {
                                                        label: 'Recommended',
                                                        colors: [
                                                            '#000000',
                                                            '#000000E0',
                                                            '#000000A6',
                                                            '#00000073',
                                                            '#00000040',
                                                            '#00000026',
                                                            '#0000001A',
                                                            '#00000012',
                                                            '#0000000A',
                                                            '#00000005',
                                                            '#F5222D',
                                                            '#FA8C16',
                                                            '#FADB14',
                                                            '#8BBB11',
                                                            '#52C41A',
                                                            '#13A8A8',
                                                            '#1677FF',
                                                            '#2F54EB',
                                                            '#722ED1',
                                                            '#EB2F96',
                                                            '#F5222D4D',
                                                            '#FA8C164D',
                                                            '#FADB144D',
                                                            '#8BBB114D',
                                                            '#52C41A4D',
                                                            '#13A8A84D',
                                                            '#1677FF4D',
                                                            '#2F54EB4D',
                                                            '#722ED14D',
                                                            '#EB2F964D',
                                                        ],
                                                    },
                                                    {
                                                        label: 'Recent',
                                                        colors: [
                                                            '#F5222D4D',
                                                            '#FA8C164D',
                                                            '#FADB144D',
                                                            '#8BBB114D',
                                                            '#52C41A4D',
                                                            '#13A8A84D',
                                                        ],
                                                    },
                                                ]}
                                                panelRender={(_, { components: { Picker, Presets } }) => (
                                                    <div
                                                        className="custom-panel"
                                                        style={{
                                                            display: 'flex',
                                                            width: 468,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                flex: 1,
                                                            }}
                                                        >
                                                            <Presets />
                                                        </div>
                                                        <Divider
                                                            type="vertical"
                                                            style={{
                                                                height: 'auto',
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                width: 234,
                                                            }}
                                                        >
                                                            <Picker />
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col span={12}>
                                        <Form.Item
                                            label="Active Chains"
                                            name="chain_ids"
                                            tooltip="Chains where this entity is active"
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Select chains"
                                                options={CHAIN_OPTIONS}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col span={12}>
                                        <Form.Item
                                            label="Main Chains"
                                            name="main_chain_ids"
                                            tooltip="Chains where this entity is primarily active"
                                            dependencies={['chain_ids']}
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Select main chains"
                                                options={CHAIN_OPTIONS.filter(option => 
                                                    formProps.form?.getFieldValue('chain_ids')?.includes(option.value)
                                                )}
                                                style={{ width: '100%' }}
                                                disabled={!formProps.form?.getFieldValue('chain_ids')?.length}
                                            />
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col span={8}>
                                        <Form.Item
                                            label="Status"
                                            name="status"
                                        >
                                            <Select
                                                options={[
                                                    { label: "Active", value: "active" },
                                                    { label: "Inactive", value: "inactive" },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Risk Score"
                                            name="risk_score"
                                            extra="0-10 scale (10 = highest risk)"
                                        >
                                            <Input type="number" min={0} max={10} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Compliance Status"
                                            name="compliance_status"
                                        >
                                            <Select
                                                options={[
                                                    { label: "Compliant", value: "compliant" },
                                                    { label: "Non-compliant", value: "non-compliant" },
                                                    { label: "Unknown", value: "unknown" },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                        {/* Blockchain Addresses */}
                        <Card title="Blockchain Addresses" style={{ marginTop: 16 }}>
                            <Form.List name="addresses">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Row key={key} gutter={[16, 16]} align="middle">
                                                <Col span={8}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'address']}
                                                        rules={[{ required: true, message: 'Address required' }]}
                                                        label={`Address ${name + 1}`}
                                                    >
                                                        <Input placeholder="0x..." />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'chainIds']}
                                                        rules={[{ required: true, message: 'Select chains' }]}
                                                        label="Chain IDs"
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            placeholder="Select chains"
                                                            options={CHAIN_OPTIONS}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'mainChainIds']}
                                                        dependencies={[['addresses', name, 'chainIds']]}
                                                        label="Main Chain IDs"
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            placeholder="Main chains"
                                                            options={CHAIN_OPTIONS}
                                                            disabled={!formProps.form?.getFieldValue(['addresses', name, 'chainIds'])?.length}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2} style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                                                    <Button 
                                                        type="text" 
                                                        danger
                                                        icon={<MinusCircleOutlined/>} 
                                                        onClick={() => remove(name)}
                                                    />
                                                </Col>
                                            </Row>
                                        ))}
                                        <Form.Item>
                                            <Button 
                                                type="dashed" 
                                                onClick={() => {
                                                    add({
                                                        address: '',
                                                        chainIds: [],
                                                        mainChainIds: []
                                                    });
                                                }} 
                                                block 
                                                icon={<PlusOutlined/>}
                                            >
                                                Add Address
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>

                        {/* Additional Information */}
                        <Card title="Additional Information" style={{ marginTop: 16 }}>
                            <Form.List name="metadata">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Row key={key} gutter={16} align="middle">
                                                <Col span={8}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'key']}
                                                        rules={[{ required: true }]}
                                                        label="Key"
                                                    >
                                                        <Input placeholder="Key" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={14}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'value']}
                                                        label="Value"
                                                    >
                                                        <Input placeholder="Value" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2} style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                                                    <Button 
                                                        type="text" 
                                                        danger
                                                        icon={<MinusCircleOutlined/>} 
                                                        onClick={() => remove(name)}
                                                    />
                                                </Col>
                                            </Row>
                                        ))}
                                        <Form.Item>
                                            <Button 
                                                type="dashed" 
                                                onClick={() => {
                                                    add({
                                                        key: '',
                                                        value: ''
                                                    });
                                                }} 
                                                block 
                                                icon={<PlusOutlined/>}
                                            >
                                                Add Metadata
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};

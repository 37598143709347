import React, { useState, useEffect, useRef } from 'react';
import { Card, Typography, Table, Button, Space, Empty, Tabs, Input, Form, message, Select, Modal, Tooltip, Row, Col, Tag, Descriptions, Collapse, Divider, Alert, Drawer, Badge, Spin } from 'antd';
import { useAccount, usePublicClient, useChains, useWalletClient, useConnect, useSwitchChain } from 'wagmi';
import { formatEther, formatUnits } from 'viem';
import { ReloadOutlined, ApiOutlined, HistoryOutlined, CopyOutlined, ExpandAltOutlined, InfoCircleOutlined, GlobalOutlined, WalletOutlined, LinkOutlined, FileAddOutlined, ArrowRightOutlined, ApartmentOutlined, WarningOutlined, PlayCircleOutlined, SendOutlined, SwapOutlined, CodeOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { CHAIN_CONFIG, CHAIN_MAPPING, WALLET_CHAIN_MAPPING } from '../../interfaces/chain.d';
import { getExplorerAddressLink, getExplorerTransactionLink, getExplorerName } from  '../../utils/chainExplorer';
import RelatedContractExplorer from './RelatedContractExplorer';
import { abiCache } from '../../utils/abiCache';
import { shortenAddress } from '../../utils/formatters';
import * as ethers from 'ethers';
import { ConnectButton, connectorsForWallets } from '@rainbow-me/rainbowkit';

const { Text, Paragraph, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;

// Custom JSON serializer that handles BigInt
const safeJsonStringify = (obj: any, indent = 2) => {
  return JSON.stringify(obj, (key, value) => 
    typeof value === 'bigint' 
      ? value.toString() 
      : value
  , indent);
};

// Format complex values for display
const formatDisplayValue = (value: any): { displayValue: React.ReactNode, isComplex: boolean, fullValue: string } => {
  let displayValue: React.ReactNode;
  let fullValue: string;
  let isComplex = false;
  
  // Handle null/undefined
  if (value === null || value === undefined) {
    return { displayValue: <Text type="secondary">null</Text>, isComplex: false, fullValue: 'null' };
  }
  
  // Handle BigInt
  if (typeof value === 'bigint') {
    fullValue = value.toString();
    return { 
      displayValue: <Text code>{fullValue}</Text>, 
      isComplex: fullValue.length > 15, 
      fullValue 
    };
  }
  
  // Handle objects and arrays
  if (typeof value === 'object') {
    try {
      fullValue = safeJsonStringify(value);
      isComplex = true;
      
      if (Array.isArray(value)) {
        // Format arrays
        displayValue = (
          <Text>
            Array ({value.length} {value.length === 1 ? 'item' : 'items'})
          </Text>
        );
      } else {
        // Format objects
        const keys = Object.keys(value);
        displayValue = (
          <Text>
            Object ({keys.length} {keys.length === 1 ? 'property' : 'properties'})
          </Text>
        );
      }
    } catch (e) {
      fullValue = '[Complex Object]';
      displayValue = <Text type="secondary">[Complex Object]</Text>;
      isComplex = true;
    }
    return { displayValue, isComplex, fullValue };
  }
  
  // Handle strings, booleans, and numbers
  fullValue = String(value);
  
  if (typeof value === 'boolean') {
    displayValue = <Text code>{fullValue}</Text>;
  } else if (typeof value === 'number') {
    displayValue = <Text code>{fullValue}</Text>;
  } else {
    // String or other types
    displayValue = fullValue.length > 30 
      ? <Text>{fullValue.substring(0, 30)}...</Text>
      : <Text>{fullValue}</Text>;
    isComplex = fullValue.length > 30;
  }
  
  return { displayValue, isComplex, fullValue };
};

// Format function result with parameter names from ABI
const formatFunctionResult = (result: any, functionAbi: any): any => {
  if (!result || !functionAbi || !functionAbi.outputs || functionAbi.outputs.length === 0) {
    return result;
  }
  
  // If result is not an array or object, return as is
  if (typeof result !== 'object' && !Array.isArray(result)) {
    return result;
  }
  
  // If result is an array and we have output definitions
  if (Array.isArray(result) && functionAbi.outputs.length > 0) {
    // If there's only one output parameter, return it directly
    if (functionAbi.outputs.length === 1) {
      return {
        [functionAbi.outputs[0].name || 'result']: result[0]
      };
    }
    
    // Map array results to named parameters
    const namedResult: Record<string, any> = {};
    functionAbi.outputs.forEach((output: any, index: number) => {
      if (index < result.length) {
        const paramName = output.name || `result_${index}`;
        namedResult[paramName] = result[index];
      }
    });
    return namedResult;
  }
  
  return result;
};

// Get read functions from ABI
const getReadFunctions = (abi: any[]): any[] => {
  return abi.filter((item: any) => 
    (item.type === 'function' && 
    (item.stateMutability === 'view' || item.stateMutability === 'pure'))
  );
};

interface ContractStateViewerProps {
  contractAddress: string;
  chainId: string;
  abi?: any[];
  title?: string;
  fetchAbi?: (address: string, chainId: string) => Promise<any>;
  transactionContext?: {
    selectedAddress?: string;
    blockNumber?: number;
    timestamp?: number;
    chain_id?: string;
    [key: string]: any;
  };
  contractMetadata?: {
    name?: string;
    description?: string;
    is_default_abi?: boolean;
    id?: string;
    status?: string;
    address?: string;
  };
}

const ContractStateViewer = ({ 
  contractAddress: propContractAddress,
  chainId: propChainId,
  title: propTitle = "Contract State",
  abi: providedAbi,
  fetchAbi,
  transactionContext,
  contractMetadata,
}: ContractStateViewerProps) => {
  // State for contract address
  const [contractAddress, setContractAddress] = useState<string>(propContractAddress || '');
  // Chain state
  const [chainId, setChainId] = useState<string>(
    transactionContext?.chain_id || propChainId || '0x1'
  );
  
  // Wallet connection
  const { address, isConnected } = useAccount();
  const { connect, connectors } = useConnect();
  const { data: walletClient } = useWalletClient();
  const { switchChain } = useSwitchChain()
  const chains = useChains();
  const publicClient = usePublicClient();
    
  // ABI state
  const [abi, setAbi] = useState<any[]>(providedAbi || []);
  const [readFunctions, setReadFunctions] = useState<any[]>([]);
  const [isAbiModalVisible, setIsAbiModalVisible] = useState(false);
  const [manualAbiInput, setManualAbiInput] = useState('');
  const [abiInputError, setAbiInputError] = useState<string | null>(null);
  
  // State data
  const [stateData, setStateData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // Function call state
  const [selectedFunction, setSelectedFunction] = useState<string | null>(null);
  const [functionInputs, setFunctionInputs] = useState<Record<string, string>>({});
  const [functionResult, setFunctionResult] = useState<any>(null);
  const [functionLoading, setFunctionLoading] = useState(false);
  
  // Historical mode
  const [isHistoricalMode, setIsHistoricalMode] = useState(false);
  const [blockNumber, setBlockNumber] = useState<number | null>(null);
  const [isBlockNumberModalVisible, setIsBlockNumberModalVisible] = useState(false);
  const [tempBlockNumber, setTempBlockNumber] = useState<number>(
    transactionContext?.blockNumber || 0
  );
  
  // Modal for large values
  const [valueModalVisible, setValueModalVisible] = useState(false);
  const [modalValueContent, setModalValueContent] = useState<{
    title: string;
    content: string;
  }>({ title: '', content: '' });
  
  // Add state for related contract exploration
  const [relatedContractStack, setRelatedContractStack] = useState<Array<{address: string, name?: string}>>([]);
  const [isRelatedContractDrawerOpen, setIsRelatedContractDrawerOpen] = useState(false);
  const [currentRelatedContract, setCurrentRelatedContract] = useState<{address: string, name?: string} | null>(null);
  
  // Add a ref for the current ABI
  const currentAbiRef = useRef<any[]>([]);
  
  // Add a state variable to track whether we've already tried to fetch the ABI
  const [abiAttempted, setAbiAttempted] = useState(false);
  
  // Add refs to track previous contract address and chain ID
  const prevContractAddressRef = useRef(contractAddress);
  const prevChainIdRef = useRef(chainId);
  
  // Write function state
  const [selectedWriteFunction, setSelectedWriteFunction] = useState<string>('');
  const [writeFunctionInputs, setWriteFunctionInputs] = useState<Record<string, any>>({});
  const [writeFunctionResult, setWriteFunctionResult] = useState<any>(null);
  const [writeFunctionLoading, setWriteFunctionLoading] = useState<boolean>(false);
  const [showWriteWarning, setShowWriteWarning] = useState<boolean>(true);
  
  // Add this state variable to track network mismatch
  const [networkMismatch, setNetworkMismatch] = useState<boolean>(false);
  
  // Add a loading ref to prevent multiple simultaneous calls
  const loadingRef = useRef(false);
  
  // Add a function loading ref to prevent multiple simultaneous calls
  const functionLoadingRef = useRef(false);
  
  // Switch network function
  const handleSwitchNetwork = async (newChainId: string) => {
    if (!walletClient || !isConnected) {
      console.log('Wallet not connected, just changing UI chain');
      setChainId(newChainId);
      return;
    }
    
    const numericChainId = parseInt(newChainId, 16);
    const chainToSwitch = chains.find((c) => c.id === numericChainId);
    
    if (!chainToSwitch) {
      console.log(`Chain ID ${newChainId} not found in configured chains, just changing UI chain`);
      setChainId(newChainId);
      return;
    }

    try {
      await switchChain({ chainId: numericChainId });
      console.log(`Switched to ${chainToSwitch.name}`);
      setChainId(newChainId);
 
    } catch (error: any) {      console.error('Failed to switch network:', error);
      
      // If the error is about unrecognized chain, try to add it first
      if (error.message && error.message.includes('Unrecognized chain ID')) {
        try {
          // Try to add the chain to the wallet if ethereum provider is available
          if (window.ethereum) {
            // Get chain config from our mapping
            const chainConfig = WALLET_CHAIN_MAPPING[newChainId];
            
            if (!chainConfig) {
              throw new Error(`Chain configuration not found for chain ID ${newChainId}`);
            }
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: chainConfig.chainId,
                  chainName: chainConfig.chainName,
                  nativeCurrency: chainConfig.nativeCurrency,
                  rpcUrls: chainConfig.rpcUrls,
                  blockExplorerUrls: chainConfig.blockExplorerUrls,
                  iconUrls: chainConfig.iconUrls,
                },
              ],
            });
            
            // Try switching again after adding
            handleSwitchNetwork(chainConfig.chainId);
            console.log(`Added and switched to ${chainConfig.chainName}`);
          } else {
            message.error('Ethereum provider not available');
          }
        } catch (addError: any) {
          console.error('Failed to add chain to wallet:', addError);
          message.error(`Failed to add chain to wallet: ${addError?.message}`);
        }
      } else {
        message.error(`Failed to switch network: ${error.message || error}`);
      }
      
      // Still update the UI chain
      setChainId(newChainId);
    }
  };
  
  // Handle chain change
  const handleChainChange = (value: string) => {
    handleSwitchNetwork(value);
  };
  
  // Show value in modal with improved formatting
  const showValueInModal = (name: string, value: any) => {
    setModalValueContent({
      title: `Value: ${name}`,
      content: value
    });
    
    setValueModalVisible(true);
  };
  
  // Copy value to clipboard
  const copyValueToClipboard = (value: string) => {
    copy(value);
    message.success('Copied to clipboard');
  };
  
  // Toggle historical mode
  const toggleHistoricalMode = () => {
    if (isHistoricalMode) {
      // Switch back to current state
      setIsHistoricalMode(false);
      setBlockNumber(null);
      // Reload state
      loadContractState();
    } else {
      // Show block number input modal
      setIsBlockNumberModalVisible(true);
    }
  };
  
  // Handle block number submit
  const handleBlockNumberSubmit = () => {
    if (tempBlockNumber > 0) {
      setBlockNumber(tempBlockNumber);
      setIsHistoricalMode(true);
      setIsBlockNumberModalVisible(false);
      // Reload state with block number
      loadContractState(tempBlockNumber);
    } else {
      message.error('Please enter a valid block number');
    }
  };
  
  // Show ABI modal
  const showAbiModal = () => {
    setIsAbiModalVisible(true);
  };
  
  // Close ABI modal
  const closeAbiModal = () => {
    setIsAbiModalVisible(false);
    setAbiInputError(null);
  };
  
  // Handle manual ABI submit
  const handleManualAbiSubmit = () => {
    try {
      const parsedAbi = JSON.parse(manualAbiInput);
      
      if (!Array.isArray(parsedAbi)) {
        setAbiInputError('ABI must be an array of function/event definitions');
        return;
      }
      
      setAbi(parsedAbi);
      setReadFunctions(getReadFunctions(parsedAbi));
      setError(null);
      closeAbiModal();
      message.success('ABI set successfully');
    } catch (err) {
      setAbiInputError('Invalid JSON format. Please check your input.');
    }
  };
  
  // Load contract state
  const loadContractState = async (blockTag?: number) => {
    if (!contractAddress || !chainId) {
      setError('Contract address and chain ID are required');
      return;
    }
    
    // Prevent multiple simultaneous calls
    if (loadingRef.current) {
      console.log('Already loading contract state, skipping duplicate call');
      return;
    }
    
    loadingRef.current = true;
    setLoading(true);
    setError(null);
    
    try {
      let abiToUse;
      
      // Check if we have a manually entered ABI (from the modal)
      if (manualAbiInput) {
        try {
          abiToUse = JSON.parse(manualAbiInput);
          console.log('Using manually entered ABI');
        } catch (err) {
          setError('Invalid ABI format');
          setLoading(false);
          loadingRef.current = false;
          return;
        }
      } 
      // Use provided ABI if available
      else if (providedAbi) {
        abiToUse = providedAbi;
        console.log('Using provided ABI');
      } 
      // Check if we have a manually set ABI in the cache
      else if (abiCache.has(chainId, contractAddress)) {
        const cachedAbi = abiCache.get(chainId, contractAddress)!;
        
        // Only use cached ABI if it's marked as manual or if we haven't attempted to fetch yet
        if (cachedAbi.source === 'manual' || !abiAttempted) {
          abiToUse = cachedAbi.abi;
          console.log(`Using cached ABI (${cachedAbi.source}) for ${contractAddress}`);
        }
      }
      
      // If we still don't have an ABI and haven't attempted to fetch one yet, try fetching
      if (!abiToUse && !abiAttempted && fetchAbi) {
        try {
          abiToUse = await fetchAbi(contractAddress, chainId);
          if (abiToUse && Array.isArray(abiToUse)) {
            // Cache the fetched ABI
            abiCache.set(chainId, contractAddress, abiToUse, 'fetched');
            console.log('Using fetched ABI');
          }
        } catch (err) {
          console.error('Error fetching ABI:', err);
        }
        
        // Mark that we've attempted to fetch the ABI
        setAbiAttempted(true);
      }
      
      if (!abiToUse || !Array.isArray(abiToUse)) {
        setError('Failed to load ABI. Please provide it manually.');
        setLoading(false);
        loadingRef.current = false;
        return;
      }
      
      // Store the current ABI
      currentAbiRef.current = abiToUse;
      
      // Update the ABI state
      setAbi(abiToUse);
      
      const readFns = getReadFunctions(abiToUse);
      setReadFunctions(readFns);
      
      // IMPORTANT: Only process a limited number of functions to avoid rate limits
      const MAX_FUNCTIONS = 15; // Limit to a reasonable number
      
      // Filter to only functions with no inputs and limit the total number
      const functionsToProcess = readFns
        .filter((fn) => fn.inputs.length === 0)
        .slice(0, MAX_FUNCTIONS);
      
      console.log(`Processing ${functionsToProcess.length} out of ${readFns.filter(fn => fn.inputs.length === 0).length} available read functions`);
      
      // Track errors to avoid continuous retries
      const erroredFunctions = new Set();
      
      // Process functions sequentially with delay to avoid rate limits
      const results = [];
      for (let i = 0; i < functionsToProcess.length; i++) {
        const fn = functionsToProcess[i];
        
        // Add a small delay between calls
        if (i > 0) {
          await new Promise(resolve => setTimeout(resolve, 200));
        }
        
        try {
          // Skip functions that have errored before
          if (erroredFunctions.has(fn.name)) {
            console.log(`Skipping previously errored function: ${fn.name}`);
            results.push({
              name: fn.name,
              value: 'Error: Function previously failed',
              error: true,
              errorDetails: 'Skipped to prevent continuous retries'
            });
            continue;
          }
          
          // Set a timeout to prevent hanging calls
          const result = await Promise.race([
            publicClient?.readContract({
              address: contractAddress as `0x${string}`,
              abi: abiToUse,
              functionName: fn.name,
              args: [],
              ...(blockTag ? { blockNumber: BigInt(blockTag) } : {})
            }),
            new Promise((_, reject) => 
              setTimeout(() => reject(new Error('Request timed out after 10 seconds')), 10000)
            )
          ]);
          
          // Format the result using the function's output definitions
          const formattedResult = formatFunctionResult(result, fn);
          
          results.push({
            name: fn.name,
            value: formattedResult,
            type: fn.outputs.map((o: any) => o.type).join(', ')
          });
        } catch (err: any) {
          console.error(`Error calling ${fn.name}:`, err);
          
          // Add to errored functions set to prevent retries
          erroredFunctions.add(fn.name);
          
          // Check for rate limit errors
          if (err.message && (
              err.message.includes('429') || 
              err.message.includes('rate limit') || 
              err.message.includes('too many requests')
          )) {
            console.warn('Rate limit detected, stopping further requests');
            
            results.push({
              name: fn.name,
              value: 'Rate limit exceeded. Try again later.',
              error: true,
              errorDetails: 'Rate limit (429) error'
            });
            
            // Stop processing more functions
            break;
          } else {
            // For other errors, add to results but continue with other functions
            results.push({
              name: fn.name,
              value: 'Error: ' + (err.message || 'Unknown error'),
              error: true,
              errorDetails: err.message
            });
          }
        }
      }
      
      setStateData(results);
      
      // If we limited the number of functions, add a note about it
      if (readFns.filter(fn => fn.inputs.length === 0).length > MAX_FUNCTIONS) {
        const hiddenFunctions = readFns.filter(fn => fn.inputs.length === 0).length - MAX_FUNCTIONS;
        
        setStateData(prevData => [
          ...prevData,
          {
            name: `... ${hiddenFunctions} more functions available`,
            value: 'To avoid rate limits, not all functions are automatically called. Use the function selector below to call specific functions.',
            isNote: true
          }
        ]);
      }
      
    } catch (err: any) {
      console.error('Error loading contract state:', err);
      setError('Failed to load contract state: ' + (err.message || 'Unknown error'));
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  };
  
  // Call a function with inputs
  const handleFunctionCall = async () => {
    if (!selectedFunction || !contractAddress) return;

    // Prevent multiple simultaneous calls
    if (functionLoadingRef.current) {
      console.log('Already calling function, skipping duplicate call');
      return;
    }
    
    functionLoadingRef.current = true;
    setFunctionLoading(true);
    setFunctionResult(null);
    
    try {
      const fn = readFunctions.find((f) => f.name === selectedFunction);
      
      if (!fn) {
        throw new Error('Function not found in ABI');
      }
      
      // Prepare inputs
      const inputs = fn.inputs.map((input: any) => {
        const value = functionInputs[input.name] || '';
        
        // Basic type conversion
        if (input.type.includes('int') && !input.type.includes('[]')) {
          return value ? BigInt(value) : 0n;
        }
        if (input.type === 'bool') {
          return value.toLowerCase() === 'true';
        }
        return value;
      });
      
      // Call the function with timeout
      const result = await Promise.race([
        publicClient?.readContract({
          address: contractAddress as `0x${string}`,
          abi,
          functionName: selectedFunction,
          args: inputs,
          ...(isHistoricalMode && blockNumber ? { blockNumber: BigInt(blockNumber) } : {})
        }),
        new Promise((_, reject) => 
          setTimeout(() => reject(new Error('Request timed out after 15 seconds')), 15000)
        )
      ]);
      
      // Format the result using the function's output definitions
      const formattedResult = formatFunctionResult(result, fn);
      
      setFunctionResult({
        raw: formattedResult,
        error: false
      });
    } catch (err: any) {
      console.error('Error calling function:', err);
      
      // Check for rate limit errors
      if (err.message && (
          err.message.includes('429') || 
          err.message.includes('rate limit') || 
          err.message.includes('too many requests')
      )) {
        setFunctionResult({
          error: true,
          message: 'Rate limit exceeded. Please try again later.',
          details: 'The RPC provider is rate limiting requests. Consider using a different provider or waiting a few minutes before trying again.'
        });
      } else {
        setFunctionResult({
          error: true,
          message: 'Error: ' + (err.message || 'Unknown error'),
          details: err.message
        });
      }
    } finally {
      setFunctionLoading(false);
      functionLoadingRef.current = false;
    }
  };
  
  // Handle write function call simulation
  const handleWriteFunctionCall = async () => {
    if (!selectedWriteFunction) return;
    
    setWriteFunctionLoading(true);
    setWriteFunctionResult(null);
    
    try {
      const fn = abi.filter(f => f.type === 'function' && f.stateMutability !== 'view' && f.stateMutability !== 'pure').find(f => f.name === selectedWriteFunction);
      if (!fn) throw new Error('Function not found');
      
      // Create a provider
      const provider = new ethers.JsonRpcProvider(`https://eth-mainnet.alchemyapi.io/v2/demo`);
      
      // Create a contract instance (read-only since we're just simulating)
      const contract = new ethers.Contract(contractAddress, [fn], provider);
      
      // Prepare inputs
      const inputValues = [];
      const formattedInputs = [];
      
      for (const input of fn.inputs) {
        const value = writeFunctionInputs[input.name];
        let formattedValue = value;
        
        // Handle array inputs
        if (input.type.includes('[]')) {
          try {
            formattedValue = JSON.parse(value || '[]');
            inputValues.push(formattedValue);
          } catch (e) {
            throw new Error(`Invalid array format for ${input.name}`);
          }
        } 
        // Handle address inputs
        else if (input.type === 'address') {
          if (value && !ethers.isAddress(value)) {
            throw new Error(`Invalid address format for ${input.name}`);
          }
          inputValues.push(value || ethers.ZeroAddress);
        }
        // Handle boolean inputs
        else if (input.type === 'bool') {
          const boolValue = value === 'true' || value === '1' || value === true;
          inputValues.push(boolValue);
          formattedValue = boolValue.toString();
        }
        // Handle numeric inputs
        else if (input.type.includes('int')) {
          try {
            // Check if it's a valid number
            if (value === '' || value === undefined) {
              inputValues.push(0);
              formattedValue = '0';
            } else if (input.type.includes('uint')) {
              // For uint types, ensure non-negative
              const bigIntValue = BigInt(value);
              if (bigIntValue < 0n) throw new Error(`Value for ${input.name} must be non-negative`);
              inputValues.push(bigIntValue);
              formattedValue = bigIntValue.toString();
            } else {
              // For int types
              const bigIntValue = BigInt(value);
              inputValues.push(bigIntValue);
              formattedValue = bigIntValue.toString();
            }
          } catch (e: any) {
            throw new Error(`Invalid number format for ${input.name}: ${e.message}`);
          }
        }
        // Handle bytes inputs
        else if (input.type.includes('bytes')) {
          if (value && !value.startsWith('0x')) {
            throw new Error(`Bytes value for ${input.name} must start with 0x`);
          }
          inputValues.push(value || '0x');
        }
        // Handle string inputs
        else if (input.type === 'string') {
          inputValues.push(value || '');
        }
        // Handle other types
        else {
          inputValues.push(value || null);
        }
        
        formattedInputs.push({
          name: input.name || `param${formattedInputs.length}`,
          type: input.type,
          value: formattedValue
        });
      }
      
      // Get the function fragment
      const functionFragment = fn;
      
      // Encode the function data
      const functionData = contract.interface.encodeFunctionData(
        functionFragment.name,
        inputValues
      );
      
      // Calculate gas estimate (this would normally be done with a real provider)
      // For simulation, we'll just use a placeholder
      const gasEstimate = '100000';
      
      // Get current gas price
      const gasPrice = await provider.getFeeData();
      const gasPriceGwei = ethers.formatUnits(gasPrice.gasPrice || 0n, 'gwei');
      
      // Calculate the total transaction cost
      const txCost = BigInt(gasEstimate) * (gasPrice.gasPrice || 0n);
      const txCostEth = ethers.formatEther(txCost);
      
      // Check if the function is payable
      const isPayable = fn.stateMutability === 'payable';
      const valueEth = isPayable ? (writeFunctionInputs['__value'] || '0') : '0';
      const valueWei = ethers.parseEther(valueEth);
      
      // Prepare the transaction object
      const txObject = {
        to: contractAddress,
        data: functionData,
        value: valueWei,
        gasLimit: BigInt(gasEstimate),
        gasPrice: gasPrice.gasPrice
      };
      
      // Simulate success
      setWriteFunctionResult({
        success: true,
        message: 'Transaction would be sent with these parameters:',
        inputs: formattedInputs,
        function: fn.name,
        transaction: {
          to: contractAddress,
          data: functionData,
          value: valueEth,
          gasEstimate,
          gasPrice: gasPriceGwei,
          totalCost: txCostEth
        },
        note: 'This is a simulation only. In a real application, you would need to connect a wallet and sign the transaction.'
      });
    } catch (err: any) {
      console.error('Write function error:', err);
      setWriteFunctionResult({
        error: true,
        message: err.message || 'Error preparing transaction',
        details: err.stack
      });
    } finally {
      setWriteFunctionLoading(false);
    }
  };
  
  // Component to render complex values
  const RenderComplexValue = ({ value, name }: { value: any, name: string }) => {
    const { displayValue, isComplex, fullValue } = formatDisplayValue(value);
    
    if (/^0x[a-fA-F0-9]{40}$/.test(value)) {
      const explorerLink = getExplorerAddressLink(chainId, value);
      const explorerName = getExplorerName(chainId);
      
      return (
        <Space>
          <Text code>{value.substring(0, 6)}...{value.substring(38)}</Text>
          {explorerLink && (
            <Button 
              type="link" 
              size="small" 
              icon={<LinkOutlined />} 
              onClick={() => window.open(explorerLink, '_blank')}
              title={`View on ${explorerName}`}
            />
          )}
          <Button 
            type="link"
            size="small"
            icon={<ApartmentOutlined />}
            onClick={() => onExploreAddress(value, name)}
            title="Explore this contract"
          />
          <Button 
            type="text" 
            size="small" 
            icon={<CopyOutlined />} 
            onClick={() => {
              copy(value);
              message.success('Address copied to clipboard');
            }}
            title="Copy address"
          />
        </Space>
      );
    }
    
    if (/^0x[a-fA-F0-9]{64}$/.test(value)) {
      const explorerLink = getExplorerTransactionLink(chainId, value);
      const explorerName = getExplorerName(chainId);
      
      return (
        <Space>
          <Text code>{value.substring(0, 10)}...{value.substring(58)}</Text>
          {explorerLink && (
            <Button 
              type="link" 
              size="small" 
              icon={<LinkOutlined />} 
              onClick={() => window.open(explorerLink, '_blank')}
              title={`View on ${explorerName}`}
            />
          )}
          <Button 
            type="text" 
            size="small" 
            icon={<CopyOutlined />} 
            onClick={() => {
              copy(value);
              message.success('Transaction hash copied to clipboard');
            }}
            title="Copy hash"
          />
        </Space>
      );
    }
    
    return (
      <Space>
        {displayValue}
        {isComplex && (
          <Button 
            type="text" 
            size="small" 
            icon={<ExpandAltOutlined />} 
            onClick={() => showValueInModal(name, value)}
            title="View full value"
          />
        )}
        <Button 
          type="text" 
          size="small" 
          icon={<CopyOutlined />} 
          onClick={() => copyValueToClipboard(fullValue)}
          title="Copy value"
        />
      </Space>
    );
  };
  
  // Render ABI error with manual upload option
  const renderAbiError = () => (
    <div style={{ padding: '24px', textAlign: 'center' }}>
      <Alert
        closeIcon
        message="Contract ABI Not Available"
        description={
          <div>
            <p>{error}</p>
            <p>Without an ABI, we can't display the contract state or functions.</p>
            <p>You can manually provide the ABI for this contract:</p>
            <Button 
              type="primary" 
              onClick={showAbiModal} 
              style={{ marginTop: 16 }}
              icon={<ApiOutlined />}
            >
              Set ABI Manually
            </Button>
          </div>
        }
        type="warning"
        showIcon
        style={{ marginBottom: 16, textAlign: 'left' }}
      />
      
      {getExplorerAddressLink(chainId, contractAddress) && (
        <div style={{ marginTop: 16 }}>
          <Button 
            type="link" 
            icon={<LinkOutlined />} 
            onClick={() => window.open(getExplorerAddressLink(chainId, contractAddress)!, '_blank')}
          >
            View on {getExplorerName(chainId)}
          </Button>
          <p style={{ marginTop: 8, fontSize: '0.9em', color: '#888' }}>
            You may be able to find the ABI on the blockchain explorer if the contract is verified.
          </p>
        </div>
      )}
    </div>
  );
  
  // Render wallet connection prompt if not connected
  const renderWalletPrompt = () => {
    return (
      <div style={{ textAlign: 'center', padding: '24px' }}>
        <Alert
          message="Wallet Not Connected"
          description="Connect your wallet to interact with this contract and view its state."
          type="info"
          showIcon
          style={{ marginBottom: '16px' }}
        />
        <ConnectButton />
      </div>
    );
  };
  
  // Function to explore a related contract address
  const onExploreAddress = (address: string, name?: string) => {
    setCurrentRelatedContract({ address, name });
    setRelatedContractStack(prev => [...prev, { address, name }]);
    setIsRelatedContractDrawerOpen(true);
  };
  
  // Function to go back in the exploration stack
  const goBackInExplorationStack = () => {
    if (relatedContractStack.length <= 1) {
      setIsRelatedContractDrawerOpen(false);
      setRelatedContractStack([]);
      return;
    }
    
    const newStack = [...relatedContractStack];
    newStack.pop(); // Remove current
    setRelatedContractStack(newStack);
    setCurrentRelatedContract(newStack[newStack.length - 1]);
  };
  
  // Initialize from props
  useEffect(() => {
    console.log("Props changed:", { propContractAddress, propChainId, transactionContext });
    
    if (propContractAddress) {
      console.log("Setting contract address from props:", propContractAddress);
      setContractAddress(propContractAddress);
    } else if (transactionContext?.selectedAddress) {
      console.log("Setting contract address from transaction context:", transactionContext.selectedAddress);
      setContractAddress(transactionContext.selectedAddress);
    }
    
    if (transactionContext?.chain_id) {
      setChainId(transactionContext.chain_id);
    } else if (propChainId) {
      setChainId(propChainId);
    }
    
    if (transactionContext?.blockNumber) {
      setTempBlockNumber(transactionContext.blockNumber);
    }
  }, [propContractAddress, propChainId, transactionContext]);

  // Update the useEffect that resets state when contract address changes
  useEffect(() => {
    // Reset state when contract address changes
    setAbi([]);
    setReadFunctions([]);
    setError(null);
    setLoading(true);
    setAbiAttempted(false);
    
    // Reset other state variables that actually exist
    setStateData([]);
    setSelectedFunction(null);
    setFunctionInputs({});
    setFunctionResult(null);
    
    console.log('Props changed:', {
      propContractAddress: contractAddress,
      propChainId: chainId,
      transactionContext
    });
    
    // Update refs immediately to prevent race conditions
    prevContractAddressRef.current = contractAddress;
    prevChainIdRef.current = chainId;
    
  }, [contractAddress, chainId]);

  // Separate useEffect for loading the ABI
  useEffect(() => {
    const loadAbi = async () => {
      if (!contractAddress) return;
      
      // Skip if we've already attempted to fetch the ABI for this contract
      if (abiAttempted) return;
      
      // Store the current contract address for this fetch operation
      const currentFetchAddress = contractAddress;
      const currentFetchChainId = chainId;
      
      if (providedAbi) {
        console.log('Using provided ABI:', providedAbi);
        setAbi(providedAbi);
        setReadFunctions(getReadFunctions(providedAbi));
        setLoading(false);
        return;
      }
      
      if (fetchAbi) {
        try {
          setLoading(true);
          setAbiAttempted(true);
          
          console.log('Fetching ABI for:', currentFetchAddress);
          const fetchedAbi = await fetchAbi(currentFetchAddress, currentFetchChainId);
          console.log('Fetched ABI result:', fetchedAbi);
          
          // Check if the component is still mounted and interested in this address
          if (currentFetchAddress !== contractAddress || currentFetchChainId !== chainId) {
            console.log('Contract address or chain ID changed during ABI fetch, ignoring results');
            return;
          }
          
          if (fetchedAbi && Array.isArray(fetchedAbi) && fetchedAbi.length > 0) {
            console.log('Setting valid ABI with', fetchedAbi.length, 'entries');
            setAbi(fetchedAbi);
            setReadFunctions(getReadFunctions(fetchedAbi));
            setError(null); // Clear any previous errors
          } else {
            console.log('No valid ABI found');
            setError('No ABI available for this contract. The contract may not be verified.');
            // Ensure ABI and read functions are cleared
            setAbi([]);
            setReadFunctions([]);
          }
        } catch (err: any) {
          // Check if the component is still interested in this address
          if (currentFetchAddress !== contractAddress || currentFetchChainId !== chainId) {
            console.log('Contract address or chain ID changed during ABI fetch error, ignoring error');
            return;
          }
          
          console.error('Error fetching ABI:', err);
          setError('Failed to load ABI: ' + (err.message || 'Unknown error'));
          // Ensure ABI and read functions are cleared
          setAbi([]);
          setReadFunctions([]);
        } finally {
          // Only update loading state if we're still on the same contract
          if (currentFetchAddress === contractAddress && currentFetchChainId === chainId) {
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    };
    
    loadAbi();
  }, [contractAddress, chainId, providedAbi, fetchAbi, abiAttempted]);
  
  // Add a debug log to help track the current contract address
  useEffect(() => {
    console.log('Setting contract address from transaction context:', contractAddress);
  }, [contractAddress]);
  
  // Load contract state when ABI is loaded
  useEffect(() => {
    if (abi && abi.length > 0 && contractAddress) {
      loadContractState(isHistoricalMode && blockNumber ? blockNumber : undefined);
    }
  }, [abi, contractAddress, isHistoricalMode, blockNumber]);
  
  // Use contract metadata for display purposes
  const displayContractName = contractMetadata?.name || contractAddress;
  
  // Modal for displaying complex values in a user-friendly way
  const ValueModal = () => {
    const value = modalValueContent.content;
    
    // Format the modal content based on the value type
    const renderModalContent = () => {
      // Handle null/undefined
      if (value === null || value === undefined) {
        return <Text type="secondary">null</Text>;
      }
      
      // Handle BigInt
      if (typeof value === 'bigint') {
        return (
          <div>
            <Paragraph>
              <Text strong>Raw Value:</Text>
              <pre style={{ 
                backgroundColor: '#f5f5f5', 
                padding: '12px',
                borderRadius: '4px',
                marginTop: '8px',
                overflowX: 'auto'
              }}>
                {String(value)}
              </pre>
            </Paragraph>
            
            <Divider />
            
            <Paragraph>
              <Text strong>Formatted as ETH:</Text>
              <div style={{ marginTop: '8px' }}>
                <Text code style={{ fontSize: '16px' }}>
                  {formatEther(value)} ETH
                </Text>
              </div>
            </Paragraph>
          </div>
        );
      }
      
      // Handle arrays
      if (Array.isArray(value)) {
        return (
          <div>
            <Paragraph>
              <Text strong>Array with {value.length} items:</Text>
            </Paragraph>
            
            <Table
              dataSource={value.map((item, index) => ({ key: index, index, value: item }))}
              columns={[
                {
                  title: 'Index',
                  dataIndex: 'index',
                  key: 'index',
                  width: '80px'
                },
                {
                  title: 'Value',
                  dataIndex: 'value',
                  key: 'value',
                  render: (val) => <RenderComplexValue value={val} name={`item_${val}`} />
                }
              ]}
              size="small"
              pagination={{ pageSize: 10 }}
            />
            
            <Divider />
            
            <Paragraph>
              <Text strong>Raw JSON:</Text>
              <pre style={{ 
                backgroundColor: '#f5f5f5', 
                padding: '12px',
                borderRadius: '4px',
                marginTop: '8px',
                overflowX: 'auto'
              }}>
                {safeJsonStringify(value, 2)}
              </pre>
            </Paragraph>
          </div>
        );
      }
      
      // Handle objects
      if (typeof value === 'object') {
        return (
          <div>
            <Paragraph>
              <Text strong>Object Properties:</Text>
            </Paragraph>
            
            <Descriptions 
              bordered 
              column={1}
              size="small"
              style={{ marginBottom: '16px' }}
            >
              {Object.entries(value).map(([key, val]) => (
                <Descriptions.Item key={key} label={key}>
                  <RenderComplexValue value={val} name={key} />
                </Descriptions.Item>
              ))}
            </Descriptions>
            
            <Divider />
            
            <Paragraph>
              <Text strong>Raw JSON:</Text>
              <pre style={{ 
                backgroundColor: '#f5f5f5', 
                padding: '12px',
                borderRadius: '4px',
                marginTop: '8px',
                overflowX: 'auto'
              }}>
                {safeJsonStringify(value, 2)}
              </pre>
            </Paragraph>
          </div>
        );
      }
      
      // Handle strings
      if (typeof value === 'string') {
        // Check if it's an address
        if (/^0x[a-fA-F0-9]{40}$/.test(value)) {
          const explorerLink = getExplorerAddressLink(chainId, value);
          const explorerName = getExplorerName(chainId);
          
          return (
            <div>
              <Paragraph>
                <Text strong>Ethereum Address:</Text>
                <div style={{ marginTop: '8px' }}>
                  <Text code style={{ fontSize: '16px' }}>{value.substring(0, 6)}...{value.substring(38)}</Text>
                  {explorerLink && (
                    <Button 
                      type="link" 
                      size="small" 
                      icon={<LinkOutlined />} 
                      onClick={() => window.open(explorerLink, '_blank')}
                      title={`View on ${explorerName}`}
                    />
                  )}
                </div>
              </Paragraph>
            </div>
          );
        }
        
        // Check if it's a transaction hash
        if (/^0x[a-fA-F0-9]{64}$/.test(value)) {
          const explorerLink = getExplorerTransactionLink(chainId, value);
          const explorerName = getExplorerName(chainId);
          
          return (
            <div>
              <Paragraph>
                <Text strong>Transaction Hash:</Text>
                <div style={{ marginTop: '8px' }}>
                  <Text code style={{ fontSize: '16px' }}>{value.substring(0, 10)}...{value.substring(58)}</Text>
                  {explorerLink && (
                    <Button 
                      type="link" 
                      size="small" 
                      icon={<LinkOutlined />} 
                      onClick={() => window.open(explorerLink, '_blank')}
                      title={`View on ${explorerName}`}
                    />
                  )}
                </div>
              </Paragraph>
            </div>
          );
        }
        
        // Regular string
        return (
          <div>
            <Paragraph>
              <Text strong>String Value:</Text>
              <pre style={{ 
                backgroundColor: '#f5f5f5', 
                padding: '12px',
                borderRadius: '4px',
                marginTop: '8px',
                overflowX: 'auto',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word'
              }}>
                {value}
              </pre>
            </Paragraph>
          </div>
        );
      }
      
      // Default for other types
      return (
        <div>
          <pre style={{ 
            backgroundColor: '#f5f5f5', 
            padding: '12px',
            borderRadius: '4px',
            overflowX: 'auto',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word'
          }}>
            {safeJsonStringify(value, 2)}
          </pre>
        </div>
      );
    };
    
    return renderModalContent();
  };
  
  // Add this useEffect to check for network mismatch
  useEffect(() => {
    if (isConnected && walletClient) {
      // Check if the current wallet chain matches the selected chain
      const walletChainId = `0x${walletClient.chain.id.toString(16)}`;
      setNetworkMismatch(walletChainId !== chainId);
    }
  }, [isConnected, walletClient, chainId]);
  
  return (
    <>
      {/* Network status alert at the top */}
      {networkMismatch && isConnected && (
        <Alert
          message={
            <Space>
              <WarningOutlined />
              <span>Network Mismatch: Your wallet is connected to {walletClient ? CHAIN_MAPPING[`0x${walletClient.chain.id.toString(16)}`] || walletClient.chain.name : 'Unknown'}</span>
              <Button 
                size="small" 
                type="primary" 
                onClick={() => handleSwitchNetwork(chainId)}
                icon={<SwapOutlined />}
              >
                Switch to {CHAIN_MAPPING[chainId] || chainId}
              </Button>
            </Space>
          }
          type="warning"
          showIcon={false}
          style={{ marginBottom: 16 }}
          banner
        />
      )}
      
      {!isConnected && (
        <Alert
          message={
            <Space>
              <WalletOutlined />
              <span>Wallet not connected</span>
              <ConnectButton />
            </Space>
          }
          type="info"
          showIcon={false}
          style={{ marginBottom: 16 }}
          banner
        />
      )}
      
      <Card
        title={
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: '12px'
          }}>
            {/* Title row - dedicated row for the title */}
            <div style={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <span style={{ marginRight: 8 }}>{propTitle}</span>
              {contractMetadata?.name && (
                <Tooltip 
                  title={
                    <div>
                      <p><strong>Contract:</strong> {contractMetadata.name}</p>
                      {contractMetadata.description && (
                        <p><strong>Description:</strong> {contractMetadata.description}</p>
                      )}
                      {contractMetadata.is_default_abi !== undefined && (
                        <p><strong>Default ABI:</strong> {contractMetadata.is_default_abi ? 'Yes' : 'No'}</p>
                      )}
                      {contractMetadata.status && (
                        <p><strong>Status:</strong> {contractMetadata.status}</p>
                      )}
                      {contractMetadata.address && (
                        <p><strong>Address:</strong> {contractMetadata.address}</p>
                      )}                      
                    </div>
                  }
                >
                  <Tag color="blue" style={{ marginRight: 8, cursor: 'pointer' }}>
                    {contractMetadata.name} <InfoCircleOutlined style={{ marginLeft: 4 }} />
                  </Tag>
                </Tooltip>
              )}
            </div>
            
            {/* Contract info and buttons row */}
            <div style={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '8px'
            }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flexWrap: 'wrap' }}>
                {chainId && CHAIN_MAPPING[chainId] && (
                  <Tag color="green">
                    {CHAIN_MAPPING[chainId]}
                  </Tag>
                )}
                
                <Select
                  value={chainId}
                  onChange={handleChainChange}
                  style={{ width: 140 }}
                  dropdownMatchSelectWidth={false}
                  size="small"
                >
                  {Object.entries(CHAIN_MAPPING).map(([id, name]) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </div>
              
              <Space>
                {isHistoricalMode && (
                  <Tag color="purple">
                    Block #{blockNumber}
                  </Tag>
                )}
                <Tooltip title="Set ABI Manually">
                  <Button 
                    icon={<ApiOutlined />} 
                    onClick={showAbiModal}
                    size="small"
                  />
                </Tooltip>
                <Tooltip title={isHistoricalMode ? "View Current State" : "View Historical State"}>
                  <Button 
                    icon={isHistoricalMode ? <GlobalOutlined /> : <HistoryOutlined />} 
                    onClick={toggleHistoricalMode}
                    size="small"
                  />
                </Tooltip>
                <Tooltip title="Refresh Contract State">
                  <Button 
                    icon={<ReloadOutlined />} 
                    onClick={() => loadContractState(isHistoricalMode && blockNumber ? blockNumber : undefined)}
                    size="small"
                  />
                </Tooltip>
              </Space>
            </div>
          </div>
        }
        style={{ width: '100%' }}
      >
        {loading ? (
          <div style={{ padding: 24, textAlign: 'center' }}>
            <Spin size="large" />
            <div style={{ marginTop: 16 }}>Loading contract data...</div>
          </div>
        ) : error ? (
          renderAbiError()
        ) : !isConnected ? (
          renderWalletPrompt()
        ) : !abi || !Array.isArray(abi) || abi.length === 0 ? (
          <div style={{ padding: 24, textAlign: 'center' }}>
            <Alert
              message="No ABI Available"
              description="No ABI is available for this contract. You can manually provide one using the button below."
              type="warning"
              showIcon
              style={{ marginBottom: 16, textAlign: 'left' }}
            />
            <Button 
              type="primary" 
              onClick={showAbiModal} 
              icon={<ApiOutlined />}
              style={{ marginTop: 16 }}
            >
              Set ABI Manually
            </Button>
          </div>
        ) : (
          <Tabs defaultActiveKey="state">
            <TabPane tab="Basic State" key="state">
              <Collapse defaultActiveKey={['stateData']} ghost>
                <Panel header="Contract State Data" key="stateData">
                  {stateData.length > 0 ? (
                    <Table
                      dataSource={stateData}
                      columns={[
                        {
                          title: 'Name',
                          dataIndex: 'name',
                          key: 'name',
                          width: '40%',
                          render: (name) => (
                            <Text strong style={{ wordBreak: 'break-word' }}>
                              {name}
                            </Text>
                          )
                        },
                        {
                          title: 'Value',
                          dataIndex: 'value',
                          key: 'value',
                          render: (value, record) => {
                            if (record.error) {
                              return (
                                <Space>
                                  <Text type="danger">{value}</Text>
                                  {record.errorDetails && (
                                    <Tooltip title={record.errorDetails}>
                                      <InfoCircleOutlined />
                                    </Tooltip>
                                  )}
                                </Space>
                              );
                            }
                            
                            return <RenderComplexValue value={value} name={record.name} />;
                          }
                        }
                      ]}
                      pagination={false}
                      size="small"
                      rowKey="name"
                    />
                  ) : (
                    <Empty description="No state variables found or all require input parameters" />
                  )}
                </Panel>
              </Collapse>
            </TabPane>
            
            <TabPane tab="Query Functions" key="functions">
              <Collapse defaultActiveKey={['functionQuery']} ghost>
                <Panel header="Function Query" key="functionQuery">
                  <Space direction="vertical" style={{ width: '100%' }} size="large">
                    <Form layout="vertical">
                      <Form.Item label="Select Function">
                        <Select
                          placeholder="Select a function to call"
                          style={{ width: '100%' }}
                          onChange={(value) => {
                            setSelectedFunction(value);
                            setFunctionInputs({});
                            setFunctionResult(null);
                          }}
                          value={selectedFunction}
                        >
                          {readFunctions.map((fn) => (
                            <Option key={fn.name} value={fn.name}>
                              {fn.name}
                              {fn.inputs && fn.inputs.length > 0 && (
                                <Text type="secondary" style={{ marginLeft: 8 }}>
                                  ({fn.inputs.length} {fn.inputs.length === 1 ? 'parameter' : 'parameters'})
                                </Text>
                              )}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      {selectedFunction && (
                        <>
                          {/* Function Inputs */}
                          {readFunctions
                            .find((fn) => fn.name === selectedFunction)
                            ?.inputs.map((input: any) => (
                              <Form.Item 
                                key={input.name} 
                                label={`${input.name || `param${input.name}`} (${input.type})`}
                              >
                                <Input
                                  value={functionInputs[input.name]}
                                  onChange={(e) => 
                                    setFunctionInputs({
                                      ...functionInputs,
                                      [input.name]: e.target.value
                                    })
                                  }
                                  placeholder={`Enter ${input.type}`}
                                />
                              </Form.Item>
                          ))}

                          <Form.Item>
                            <Button
                              type="primary"
                              onClick={handleFunctionCall}
                              loading={functionLoading}
                              disabled={!selectedFunction}
                            >
                              Call Function
                            </Button>
                          </Form.Item>

                          {functionResult && (
                            <Card title="Result" size="small">
                              {functionResult.error ? (
                                <>
                                  <Text type="danger">{functionResult.message}</Text>
                                  {functionResult.details && (
                                    <Tooltip title={functionResult.details}>
                                      <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                    </Tooltip>
                                  )}
                                  <Paragraph type="secondary" style={{ marginTop: 8, fontSize: '0.9em' }}>
                                    This function may require specific permissions or conditions to be met.
                                    It could also be disabled or the contract might be a proxy.
                                  </Paragraph>
                                </>
                              ) : (
                                <div>
                                  {typeof functionResult.raw === 'object' && functionResult.raw !== null ? (
                                    <Descriptions bordered size="small" column={1}>
                                      {Object.entries(functionResult.raw).map(([key, value]: [string, any]) => (
                                        <Descriptions.Item key={key} label={key}>
                                          <RenderComplexValue 
                                            value={value} 
                                            name={`${selectedFunction}.${key}`} 
                                          />
                                        </Descriptions.Item>
                                      ))}
                                    </Descriptions>
                                  ) : (
                                    <RenderComplexValue 
                                      value={functionResult.raw} 
                                      name={selectedFunction || 'result'} 
                                    />
                                  )}
                                </div>
                              )}
                            </Card>
                          )}
                        </>
                      )}
                    </Form>
                  </Space>
                </Panel>
              </Collapse>
            </TabPane>
            
            <TabPane tab="Write Functions" key="writeFunctions">
              <Collapse defaultActiveKey={['writeFunction']} ghost>
                <Panel header="Write Function" key="writeFunction">
                  {showWriteWarning && (
                    <Alert
                      message="Write Function Simulation"
                      description="This is a simulation only. In a real application, you would need to connect a wallet and sign transactions to execute write functions. No actual transactions will be sent."
                      type="warning"
                      showIcon
                      closable
                      onClose={() => setShowWriteWarning(false)}
                      style={{ marginBottom: 16 }}
                    />
                  )}
                  
                  <Space direction="vertical" style={{ width: '100%' }} size="large">
                    <Form layout="vertical">
                      <Form.Item label="Select Write Function">
                        <Select
                          placeholder="Select a function to call"
                          style={{ width: '100%' }}
                          onChange={(value) => {
                            setSelectedWriteFunction(value);
                            setWriteFunctionInputs({});
                            setWriteFunctionResult(null);
                          }}
                          value={selectedWriteFunction}
                        >
                          {abi.filter(fn => fn.type === 'function' && fn.stateMutability !== 'view' && fn.stateMutability !== 'pure').map((fn) => (
                            <Option key={fn.name} value={fn.name}>
                              {fn.name}
                              {fn.inputs && fn.inputs.length > 0 && (
                                <Text type="secondary" style={{ marginLeft: 8 }}>
                                  ({fn.inputs.length} {fn.inputs.length === 1 ? 'parameter' : 'parameters'})
                                </Text>
                              )}
                              <Tag 
                                color={fn.stateMutability === 'payable' ? 'gold' : 'blue'} 
                                style={{ marginLeft: 8 }}
                              >
                                {fn.stateMutability}
                              </Tag>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      {selectedWriteFunction && (
                        <>
                          {/* Function Inputs */}
                          {abi.filter(fn => fn.type === 'function' && fn.stateMutability !== 'view' && fn.stateMutability !== 'pure').find((fn) => fn.name === selectedWriteFunction)?.inputs.map((input: any) => (
                            <Form.Item 
                              key={input.name} 
                              label={`${input.name || `param${input.name}`} (${input.type})`}
                            >
                              <Input
                                value={writeFunctionInputs[input.name]}
                                onChange={(e) => 
                                  setWriteFunctionInputs({
                                    ...writeFunctionInputs,
                                    [input.name]: e.target.value
                                  })
                                }
                                placeholder={`Enter ${input.type}`}
                              />
                            </Form.Item>
                          ))}
                          
                          {/* Value input for payable functions */}
                          {abi.filter(fn => fn.type === 'function' && fn.stateMutability === 'payable').find((fn) => fn.name === selectedWriteFunction) && (
                            <Form.Item label="Value (ETH)">
                              <Input
                                value={writeFunctionInputs['__value']}
                                onChange={(e) => 
                                  setWriteFunctionInputs({
                                    ...writeFunctionInputs,
                                    '__value': e.target.value
                                  })
                                }
                                placeholder="Amount of ETH to send"
                                suffix="ETH"
                              />
                            </Form.Item>
                          )}

                          <Form.Item>
                            <Button
                              type="primary"
                              icon={<SendOutlined />}
                              onClick={handleWriteFunctionCall}
                              loading={writeFunctionLoading}
                              disabled={!selectedWriteFunction}
                              danger
                            >
                              Simulate Transaction
                            </Button>
                          </Form.Item>

                          {writeFunctionResult && (
                            <Card 
                              title={
                                <Space>
                                  {writeFunctionResult.error ? 
                                  <WarningOutlined style={{ color: '#ff4d4f' }} /> : 
                                  <PlayCircleOutlined style={{ color: '#52c41a' }} />
                                }
                                <span>Transaction Simulation</span>
                              </Space>
                              } 
                              size="small"
                            >
                              {writeFunctionResult.error ? (
                                <>
                                  <Text type="danger">{writeFunctionResult.message}</Text>
                                  {writeFunctionResult.details && (
                                    <Tooltip title={writeFunctionResult.details}>
                                      <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                    </Tooltip>
                                  )}
                                </>
                              ) : (
                                <div>
                                  <Paragraph>{writeFunctionResult.message}</Paragraph>
                                  
                                  <Descriptions bordered size="small" column={1}>
                                    <Descriptions.Item label="Function">
                                      <Text code>{writeFunctionResult.function}</Text>
                                    </Descriptions.Item>
                                    
                                    {writeFunctionResult.inputs.map((input: any) => (
                                      <Descriptions.Item key={input.name} label={`${input.name} (${input.type})`}>
                                        <Text code>{String(input.value) || '<empty>'}</Text>
                                      </Descriptions.Item>
                                    ))}
                                    
                                    {writeFunctionResult.transaction && (
                                      <>
                                        <Descriptions.Item label="Contract Address">
                                          <Text code copyable>{contractAddress}</Text>
                                        </Descriptions.Item>
                                        
                                        {writeFunctionResult.transaction.value !== '0' && (
                                          <Descriptions.Item label="Value">
                                            <Text code>{writeFunctionResult.transaction.value} ETH</Text>
                                          </Descriptions.Item>
                                        )}
                                        
                                        <Descriptions.Item label="Gas Estimate">
                                          <Text code>{writeFunctionResult.transaction.gasEstimate}</Text>
                                        </Descriptions.Item>
                                        
                                        <Descriptions.Item label="Gas Price">
                                          <Text code>{writeFunctionResult.transaction.gasPrice} Gwei</Text>
                                        </Descriptions.Item>
                                        
                                        <Descriptions.Item label="Estimated Cost">
                                          <Text code>{writeFunctionResult.transaction.totalCost} ETH</Text>
                                        </Descriptions.Item>
                                        
                                        <Descriptions.Item label="Transaction Data">
                                          <Text code copyable style={{ wordBreak: 'break-all' }}>
                                            {writeFunctionResult.transaction.data}
                                          </Text>
                                        </Descriptions.Item>
                                      </>
                                    )}
                                  </Descriptions>
                                  
                                  <Alert
                                    message="Simulation Only"
                                    description={writeFunctionResult.note}
                                    type="info"
                                    showIcon
                                    style={{ marginTop: 16 }}
                                  />
                                </div>
                              )}
                            </Card>
                          )}
                        </>
                      )}
                    </Form>
                  </Space>
                </Panel>
              </Collapse>
            </TabPane>
          </Tabs>
        )}
      </Card>

      {/* ABI Input Modal */}
      <Modal
        open={isAbiModalVisible}
        title="Set Contract ABI Manually"
        width={800}
        onCancel={closeAbiModal}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button onClick={closeAbiModal}>Cancel</Button>
              <Button type="primary" onClick={handleManualAbiSubmit}>
                Apply ABI
              </Button>
            </Space>
          </div>
        }
      >
        <Paragraph>
          Paste the contract ABI in JSON format:
        </Paragraph>
        <TextArea
          rows={15}
          value={manualAbiInput}
          onChange={(e) => setManualAbiInput(e.target.value)}
          placeholder='[{"inputs":[],"name":"name","outputs":[{"name":"","type":"string"}],"stateMutability":"view","type":"function"}]'
          style={{ fontFamily: 'monospace' }}
        />
        {abiInputError && (
          <div style={{ marginTop: '12px', padding: '8px', backgroundColor: '#fff2f0', borderRadius: '4px' }}>
            <Text type="danger">
              <InfoCircleOutlined style={{ marginRight: '8px' }} />
              {abiInputError}
            </Text>
          </div>
        )}
        <Paragraph style={{ marginTop: '16px' }} type="secondary">
          <InfoCircleOutlined style={{ marginRight: '8px' }} />
          You can find ABIs on {getExplorerName(chainId)}, contract verification sites, or from the contract developers.
        </Paragraph>
      </Modal>

      {/* Block Number Modal */}
      <Modal
        title="Query Historical State"
        open={isBlockNumberModalVisible}
        onCancel={() => setIsBlockNumberModalVisible(false)}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button onClick={() => setIsBlockNumberModalVisible(false)}>
                Cancel
              </Button>
              <Button type="primary" onClick={handleBlockNumberSubmit}>
                Query
              </Button>
            </Space>
          </div>
        }
      >
        <Paragraph>
          Enter the block number to query the contract state at that point in time:
        </Paragraph>
        <Form layout="vertical">
          <Form.Item label="Block Number">
            <Input
              type="number"
              style={{ width: '100%' }}
              value={tempBlockNumber}
              onChange={(e) => setTempBlockNumber(parseInt(e.target.value))}
              min={1}
              placeholder="Enter block number"
            />
          </Form.Item>
        </Form>
        {transactionContext?.blockNumber && (
          <Paragraph type="secondary">
            <InfoCircleOutlined style={{ marginRight: '8px' }} />
            The transaction was mined at block #{transactionContext.blockNumber}.
          </Paragraph>
        )}
      </Modal>

      {/* Large Value Modal */}
      <Modal
        title={modalValueContent.title}
        open={valueModalVisible}
        onCancel={() => setValueModalVisible(false)}
        width={800}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button 
                icon={<CopyOutlined />} 
                onClick={() => copyValueToClipboard(
                  typeof modalValueContent.content === 'object' || typeof modalValueContent.content === 'bigint'
                    ? safeJsonStringify(modalValueContent.content)
                    : String(modalValueContent.content)
                )}
              >
                Copy Value
              </Button>
              <Button onClick={() => setValueModalVisible(false)}>
                Close
              </Button>
            </Space>
          </div>
        }
      >
        <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
          <ValueModal />
        </div>
      </Modal>

      {/* Related Contract Exploration Drawer */}
      <Drawer
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Space>
              <ApartmentOutlined />
              <span>Contract Explorer</span>
              {relatedContractStack.length > 0 && (
                <Badge count={relatedContractStack.length} style={{ backgroundColor: '#1890ff' }} />
              )}
            </Space>
            {relatedContractStack.length > 1 && (
              <Button 
                onClick={goBackInExplorationStack}
                icon={<ArrowRightOutlined rotate={180} />}
              >
                Back
              </Button>
            )}
          </div>
        }
        open={isRelatedContractDrawerOpen}
        onClose={() => setIsRelatedContractDrawerOpen(false)}
        width={600}
        destroyOnClose={false}
        extra={
          <Button onClick={() => setIsRelatedContractDrawerOpen(false)}>
            Close
          </Button>
        }
      >
        {currentRelatedContract && (
          <RelatedContractExplorer
            address={currentRelatedContract.address}
            chainId={chainId}
            fetchAbi={fetchAbi || (() => Promise.resolve([]))}
            publicClient={publicClient}
            onClose={() => setIsRelatedContractDrawerOpen(false)}
            parentContractName={
              relatedContractStack.length > 1 
                ? relatedContractStack[relatedContractStack.length - 2].name 
                : contractAddress === currentRelatedContract.address 
                  ? undefined 
                  : 'Main Contract'
            }
            onExploreAddress={onExploreAddress}
          />
        )}
      </Drawer>
    </>
  );
};

export default ContractStateViewer; 
// Helper functions to handle CDN attributes
export const S3_CANDIDATE_ATTRIBUTES = [
    'input', 'output', 'internal_transactions',
    'nft_transfers', 'erc20_transfers', 'native_transfers', 
    'data', 'decoded_transaction', 'decoded_call', 'logs'
];

export const fetchCdnAttribute = async (
    orgId: string,
    tenantId: string,
    userId: string,
    blockTimestamp: string,
    chainId: string,
    hash: string,
    type: string
): Promise<any> => {
    try {
        const url = `https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/transactions/${orgId}-${tenantId}-${userId}-${blockTimestamp}-${chainId}-${hash}-${type}/large_attributes.json`;
        
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Origin': window.location.origin
            },
            credentials: 'omit' // Don't send credentials for CDN requests
        });

        if (!response.ok) throw new Error('Failed to fetch large attributes from CDN');
        
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching large attributes from CDN:', error);
        return null;
    }
};
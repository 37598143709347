export const CHAIN_IDS = {
    // Mainnets
    ETHEREUM: '0x1',
    POLYGON: '0x89',
    BSC: '0x38',
    AVALANCHE: '0xa86a',
    FANTOM: '0xfa',
    CRONOS: '0x19',
    PALM: '0x2a15c308d',
    ARBITRUM: '0xa4b1',
    GNOSIS: '0x64',
    CHILIZ: '0x15b38',
    BASE: '0x2105',
    OPTIMISM: '0xa',
    KLAYTN: '0x2019',
    CELO: '0xa4ec',
    KAVA: '0x8ae',
    METIS: '0x440',
    HARMONY: '0x63564c40',
    ZKSYNC: '0x2b6653dc',
    AURORA: '0x171',
    POLYGON_LEGACY: '0x1388',
    MOONRIVER: '0x505',
    MOONBEAM: '0x504',
    ZKSYNC_ERA: '0x144',
    FUSE: '0x118',
    LINEA: '0x405',
    SCROLL: '0x44d',
    MANTLE: '0xa0c71fd',
    
    // Testnets
    GOERLI: '0x5',
    SEPOLIA: '0xaa36a7',
    MUMBAI: '0x13881',
    BSC_TESTNET: '0x61',
    GNOSIS_CHIADO: '0x27d8',
    CHILIZ_SPICY: '0x15b32',
    BASE_GOERLI: '0x14a33',
    OPTIMISM_GOERLI: '0x1a4',
    KLAYTN_BAOBAB: '0x82750',
    CELO_ALFAJORES: '0xaef3',
    KAVA_TESTNET: '0x8ad',
    METIS_GOERLI: '0x24c',
    HARMONY_TESTNET: '0x6357d2e0',
    ZKSYNC_TESTNET: '0x118',
    AURORA_TESTNET: '0x66eee',
    MOONBASE_ALPHA: '0x507',
    ZKSYNC_ERA_TESTNET: '0x145',
    FUSE_TESTNET: '0x7a',
    LINEA_TESTNET: '0xe704',
    SCROLL_TESTNET: '0x8274f',
    MANTLE_TESTNET: '0xa0c71fd'
} as const;

export const CHAIN_NAMES: Record<string, string> = {
    // Mainnets
    [CHAIN_IDS.ETHEREUM]: 'Ethereum',
    [CHAIN_IDS.POLYGON]: 'Polygon',
    [CHAIN_IDS.BSC]: 'BNB Chain',
    [CHAIN_IDS.AVALANCHE]: 'Avalanche',
    [CHAIN_IDS.FANTOM]: 'Fantom',
    [CHAIN_IDS.CRONOS]: 'Cronos',
    [CHAIN_IDS.PALM]: 'Palm',
    [CHAIN_IDS.ARBITRUM]: 'Arbitrum One',
    [CHAIN_IDS.GNOSIS]: 'Gnosis Chain',
    [CHAIN_IDS.CHILIZ]: 'Chiliz',
    [CHAIN_IDS.BASE]: 'Base',
    [CHAIN_IDS.OPTIMISM]: 'Optimism',
    [CHAIN_IDS.KLAYTN]: 'Klaytn',
    [CHAIN_IDS.CELO]: 'Celo',
    [CHAIN_IDS.KAVA]: 'Kava',
    [CHAIN_IDS.METIS]: 'Metis',
    [CHAIN_IDS.HARMONY]: 'Harmony',
    [CHAIN_IDS.ZKSYNC]: 'zkSync',
    [CHAIN_IDS.AURORA]: 'Aurora',
    [CHAIN_IDS.POLYGON_LEGACY]: 'Polygon (Legacy)',
    [CHAIN_IDS.MOONRIVER]: 'Moonriver',
    [CHAIN_IDS.MOONBEAM]: 'Moonbeam',
    [CHAIN_IDS.ZKSYNC_ERA]: 'zkSync Era',
    [CHAIN_IDS.FUSE]: 'Fuse',
    [CHAIN_IDS.LINEA]: 'Linea',
    [CHAIN_IDS.SCROLL]: 'Scroll',
    [CHAIN_IDS.MANTLE]: 'Mantle',
    
    // Testnets
    [CHAIN_IDS.GOERLI]: 'Ethereum Goerli',
    [CHAIN_IDS.SEPOLIA]: 'Ethereum Sepolia',
    [CHAIN_IDS.MUMBAI]: 'Polygon Mumbai',
    [CHAIN_IDS.BSC_TESTNET]: 'BNB Chain Testnet',
    [CHAIN_IDS.GNOSIS_CHIADO]: 'Gnosis Chiado',
    [CHAIN_IDS.CHILIZ_SPICY]: 'Chiliz Spicy',
    [CHAIN_IDS.BASE_GOERLI]: 'Base Goerli',
    [CHAIN_IDS.OPTIMISM_GOERLI]: 'Optimism Goerli',
    [CHAIN_IDS.KLAYTN_BAOBAB]: 'Klaytn Baobab',
    [CHAIN_IDS.CELO_ALFAJORES]: 'Celo Alfajores',
    [CHAIN_IDS.KAVA_TESTNET]: 'Kava Testnet',
    [CHAIN_IDS.METIS_GOERLI]: 'Metis Goerli',
    [CHAIN_IDS.HARMONY_TESTNET]: 'Harmony Testnet',
    [CHAIN_IDS.AURORA_TESTNET]: 'Aurora Testnet',
    [CHAIN_IDS.MOONBASE_ALPHA]: 'Moonbase Alpha',
    [CHAIN_IDS.ZKSYNC_ERA_TESTNET]: 'zkSync Era Testnet',
    [CHAIN_IDS.FUSE_TESTNET]: 'Fuse Testnet',
    [CHAIN_IDS.LINEA_TESTNET]: 'Linea Testnet',
    [CHAIN_IDS.SCROLL_TESTNET]: 'Scroll Testnet',
} as const;

// Explorer URLs for each chain
export const CHAIN_EXPLORERS: Record<string, string> = {
    // Mainnets
    [CHAIN_IDS.ETHEREUM]: 'https://etherscan.io',
    [CHAIN_IDS.POLYGON]: 'https://polygonscan.com',
    [CHAIN_IDS.BSC]: 'https://bscscan.com',
    [CHAIN_IDS.AVALANCHE]: 'https://snowtrace.io',
    [CHAIN_IDS.FANTOM]: 'https://ftmscan.com',
    [CHAIN_IDS.CRONOS]: 'https://cronoscan.com',
    [CHAIN_IDS.PALM]: 'https://explorer.palm.io',
    [CHAIN_IDS.ARBITRUM]: 'https://arbiscan.io',
    [CHAIN_IDS.GNOSIS]: 'https://gnosisscan.io',
    [CHAIN_IDS.CHILIZ]: 'https://chiliscan.com',
    [CHAIN_IDS.BASE]: 'https://basescan.org',
    [CHAIN_IDS.OPTIMISM]: 'https://optimistic.etherscan.io',
    [CHAIN_IDS.KLAYTN]: 'https://scope.klaytn.com',
    [CHAIN_IDS.CELO]: 'https://explorer.celo.org',
    [CHAIN_IDS.KAVA]: 'https://explorer.kava.io',
    [CHAIN_IDS.METIS]: 'https://andromeda-explorer.metis.io',
    [CHAIN_IDS.HARMONY]: 'https://explorer.harmony.one',
    [CHAIN_IDS.ZKSYNC]: 'https://explorer.zksync.io',
    [CHAIN_IDS.AURORA]: 'https://aurorascan.dev',
    [CHAIN_IDS.POLYGON_LEGACY]: 'https://polygonscan.com',
    [CHAIN_IDS.MOONRIVER]: 'https://moonriver.moonscan.io',
    [CHAIN_IDS.MOONBEAM]: 'https://moonbeam.moonscan.io',
    [CHAIN_IDS.ZKSYNC_ERA]: 'https://explorer.zksync.io',
    [CHAIN_IDS.FUSE]: 'https://explorer.fuse.io',
    [CHAIN_IDS.LINEA]: 'https://lineascan.build',
    [CHAIN_IDS.SCROLL]: 'https://scrollscan.com',
    [CHAIN_IDS.MANTLE]: 'https://explorer.mantle.xyz',
    
    // Testnets
    [CHAIN_IDS.GOERLI]: 'https://goerli.etherscan.io',
    [CHAIN_IDS.SEPOLIA]: 'https://sepolia.etherscan.io',
    [CHAIN_IDS.MUMBAI]: 'https://mumbai.polygonscan.com',
    [CHAIN_IDS.BSC_TESTNET]: 'https://testnet.bscscan.com',
    [CHAIN_IDS.GNOSIS_CHIADO]: 'https://blockscout.chiadochain.net',
    [CHAIN_IDS.CHILIZ_SPICY]: 'https://spicy-explorer.chiliz.com',
    [CHAIN_IDS.BASE_GOERLI]: 'https://goerli.basescan.org',
    [CHAIN_IDS.OPTIMISM_GOERLI]: 'https://goerli-optimism.etherscan.io',
    [CHAIN_IDS.KLAYTN_BAOBAB]: 'https://baobab.scope.klaytn.com',
    [CHAIN_IDS.CELO_ALFAJORES]: 'https://alfajores-blockscout.celo-testnet.org',
    [CHAIN_IDS.KAVA_TESTNET]: 'https://explorer.testnet.kava.io',
    [CHAIN_IDS.METIS_GOERLI]: 'https://goerli.explorer.metisdevops.link',
    [CHAIN_IDS.HARMONY_TESTNET]: 'https://explorer.testnet.harmony.one',
    [CHAIN_IDS.AURORA_TESTNET]: 'https://testnet.aurorascan.dev',
    [CHAIN_IDS.MOONBASE_ALPHA]: 'https://moonbase.moonscan.io',
    [CHAIN_IDS.ZKSYNC_ERA_TESTNET]: 'https://goerli.explorer.zksync.io',
    [CHAIN_IDS.FUSE_TESTNET]: 'https://explorer.fusespark.io',
    [CHAIN_IDS.LINEA_TESTNET]: 'https://goerli.lineascan.build',
    [CHAIN_IDS.SCROLL_TESTNET]: 'https://sepolia.scrollscan.com',
} as const;

// Direct mapping for easier lookup
export const CHAIN_ID_TO_NAME: { [key: string]: string } = {
    // Mainnets
    '0x1': 'Ethereum',
    '0x89': 'Polygon',
    '0x38': 'BNB Chain',
    '0xa86a': 'Avalanche',
    '0xfa': 'Fantom',
    '0x19': 'Cronos',
    '0x2a15c308d': 'Palm',
    '0xa4b1': 'Arbitrum One',
    '0x64': 'Gnosis Chain',
    '0x15b38': 'Chiliz',
    '0x2105': 'Base',
    '0xa': 'Optimism',
    '0x2019': 'Klaytn',
    '0xa4ec': 'Celo',
    '0x8ae': 'Kava',
    '0x440': 'Metis',
    '0x63564c40': 'Harmony',
    '0x2b6653dc': 'zkSync',
    '0x171': 'Aurora',
    '0x1388': 'Polygon (Legacy)',
    '0x505': 'Moonriver',
    '0x504': 'Moonbeam',
    '0x144': 'zkSync Era',
    '0x118': 'Fuse',
    '0x405': 'Linea',
    '0x44d': 'Scroll',
    '0xa0c71fd': 'Mantle',
    
    // Testnets
    '0x5': 'Ethereum Goerli',
    '0xaa36a7': 'Ethereum Sepolia',
    '0x13881': 'Polygon Mumbai',
    '0x61': 'BNB Chain Testnet',
    '0x27d8': 'Gnosis Chiado',
    '0x15b32': 'Chiliz Spicy',
    '0x14a33': 'Base Goerli',
    '0x1a4': 'Optimism Goerli',
    '0x82750': 'Klaytn Baobab',
    '0xaef3': 'Celo Alfajores',
    '0x8ad': 'Kava Testnet',
    '0x24c': 'Metis Goerli',
    '0x6357d2e0': 'Harmony Testnet',
    '0x66eee': 'Aurora Testnet',
    '0x507': 'Moonbase Alpha',
    '0x145': 'zkSync Era Testnet',
    '0x7a': 'Fuse Testnet',
    '0xe704': 'Linea Testnet',
    '0x8274f': 'Scroll Testnet',
} as const;

// Helper function to get chain name
export const getChainName = (chainId: string): string => {
    return CHAIN_NAMES[chainId] || chainId;
};

// Helper function to get explorer URL
export const getExplorerUrl = (chainId: string): string => {
    return CHAIN_EXPLORERS[chainId] || '';
};
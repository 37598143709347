import React from 'react';
import { Modal, Divider, Alert, Space, Button, Typography, List, Tag } from 'antd';
import { DecodedCallView } from './DecodedCallView';
import { AddressData } from 'utils/addressUtils';
import { LogEventView } from './LogEventView';
import { InternalTransactionView } from './InternalTransactionView';
import { UploadOutlined, TagOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { shortenAddress } from 'utils/formatters';
const { Text } = Typography;

interface DecodedInputModalProps {
    modalProps: any,
    close: any
    decoded_transaction: {
        decoded_call?: any;
        decoded_internal_transactions?: any[];
        decoded_events?: any[]; 
    };
    addressData: AddressData;
    chainId?: string;
}


export const DecodedInputModal: React.FC<DecodedInputModalProps> = ({
    modalProps,
    close,
    decoded_transaction,
    addressData,
    chainId,
}) => {
    const isAddressKnown = (address: string) => {
        const addressStr = address.toLowerCase();
        return (
            (addressData.is_search_transaction && addressStr === addressData.from_address?.toLowerCase() && addressData.from_address_entity) ||
            (addressData.is_search_transaction && addressStr === addressData.to_address?.toLowerCase() && addressData.to_address_entity) ||
            addressData.address_items_map?.[addressStr]?.labels?.[0]?.name ||
            addressData.address_items_map?.[addressStr]?.main_labels?.chain_id?.name
        );
    };

    // Collect all unique addresses from the transaction
    const collectUnknownAddresses = () => {
        const addresses = new Set<string>();
        
        // Helper function to recursively extract addresses from parameters
        const extractAddressesFromParams = (params: any[]) => {
            if (!params || !Array.isArray(params)) return;
            
            params.forEach((param: any) => {
                // Check for direct address parameters
                if (param.type === 'address' && param.value && !isAddressKnown(param.value)) {
                    addresses.add(param.value);
                }
                
                // Check for address arrays
                if (param.type === 'address[]' && Array.isArray(param.value)) {
                    param.value.forEach((addr: string) => {
                        if (addr && !isAddressKnown(addr)) {
                            addresses.add(addr);
                        }
                    });
                }
                
                // Check for proxy call data
                if (param.type === 'bytes' && typeof param.value === 'object' && param.value.is_proxy_call) {
                    // Add the proxy target address
                    if (param.value.address && !isAddressKnown(param.value.address)) {
                        addresses.add(param.value.address);
                    }
                    
                    // Recursively process proxy call parameters
                    if (param.value.params && Array.isArray(param.value.params)) {
                        extractAddressesFromParams(param.value.params);
                    }
                }
                
                // Check for nested parameters (like in structs)
                if (param.params && Array.isArray(param.params)) {
                    extractAddressesFromParams(param.params);
                }
            });
        };
        
        try {        
            // From main call
            if (decoded_transaction?.decoded_call?.to) {
                const addr = decoded_transaction.decoded_call.to;
                if (!isAddressKnown(addr)) {
                    addresses.add(addr);
                }
            }
            
            // Add the contract address from the decoded call
            if (decoded_transaction?.decoded_call?.address && !isAddressKnown(decoded_transaction.decoded_call.address)) {
                addresses.add(decoded_transaction.decoded_call.address);
            }
            
            // From decoded call parameters (including proxy data)
            if (decoded_transaction?.decoded_call?.params) {
                extractAddressesFromParams(decoded_transaction.decoded_call.params);
            }
            
            // From internal transactions
            decoded_transaction?.decoded_internal_transactions?.forEach(tx => {
                if (tx.to && !isAddressKnown(tx.to)) {
                    addresses.add(tx.to);
                }
                if (tx.from && !isAddressKnown(tx.from)) {
                    addresses.add(tx.from);
                }
                
                // Also check parameters of internal transactions
                if (tx.params) {
                    extractAddressesFromParams(tx.params);
                }
            });
            
            // From events
            decoded_transaction?.decoded_events?.forEach(event => {
                if (event.address && !isAddressKnown(event.address)) {
                    addresses.add(event.address);
                }
                
                // Also check event parameters for addresses
                if (event.decoded_event?.params) {
                    extractAddressesFromParams(event.decoded_event.params);
                }
            });
        } catch (error) {
            console.error('Error collecting unknown addresses:', error);
        }

        return Array.from(addresses);
    };

    const unknownAddresses = collectUnknownAddresses();

    return (
        <Modal 
            {...modalProps} 
            width={800}
            centered 
            title="Decoded Transaction Info"
            onOk={close}
            bodyStyle={{ maxHeight: '80vh', overflow: 'auto' }}
        >
            {/* Show Decoded call if they exist */}
            {decoded_transaction?.decoded_call && Object.keys(decoded_transaction?.decoded_call).length > 0 && (
                <DecodedCallView
                    decodedCall={decoded_transaction?.decoded_call}
                    addressData={addressData}
                    chainId={chainId}
                />
            )}


            {/* Show internal transactions if they exist */}
            {decoded_transaction?.decoded_internal_transactions && decoded_transaction?.decoded_internal_transactions?.length > 0 && (
                <>
                    <Divider plain>Internal Transactions</Divider>
                    {decoded_transaction.decoded_internal_transactions.map((internalTx, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <Divider dashed />}
                            <InternalTransactionView
                                transaction={internalTx}
                                addressData={addressData}
                                chainId={chainId}
                            />
                        </React.Fragment>
                    ))}
                </>
            )}

            {/* Show log events if they exist */}
            {decoded_transaction?.decoded_events && decoded_transaction?.decoded_events?.length > 0 && (
                <>
                    <Divider plain>Log Events</Divider>
                    {decoded_transaction?.decoded_events?.map((log, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <Divider dashed />}
                            <LogEventView
                                log={log}
                                addressData={addressData}
                                chainId={chainId}
                            />
                        </React.Fragment>
                    ))}
                </>
            )}         

            <Divider />
            {/* Show unknown addresses section if any exist */}
            {unknownAddresses.length > 0 && (
                <>
                    <Alert
                        type="info"
                        message="Unknown Contracts Detected"
                        description={
                            <div>
                                <Text>The following addresses could benefit from ABI or label information:</Text>
                                {unknownAddresses.map((address: string) => (
                                    <div key={address} style={{ marginTop: 8 }}>
                                        <Space>
                                            <Text code>{address}</Text>
                                            <Link to={`/addresses/show/${address}`}>
                                                <Button 
                                                    size="small" 
                                                    icon={<UploadOutlined />}
                                                >
                                                    Add ABI
                                                </Button>
                                            </Link>
                                            <Link to={`/addresses/show/${address}`}>
                                                <Button 
                                                    size="small" 
                                                    icon={<TagOutlined />}
                                                >
                                                    Add Label
                                                </Button>
                                            </Link>
                                        </Space>
                                    </div>
                                ))}
                            </div>
                        }
                        style={{ marginBottom: 16 }}
                    />
                    <Divider />
                </>
            )}            
        </Modal>
    );
}; 
import { DatePicker, Button, Space, Tooltip } from 'antd';
import type { TableColumnType } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import type { Transaction } from '../types';
import { Key } from 'react';
import { formatDate } from '../../../../utils/formatters';


export const getBlockTimeColumn = (): TableColumnType<Transaction> => ({
    title: 'Block Time',
    dataIndex: 'block_timestamp',
    key: 'block_timestamp',
    width: 200,
    filterDropdown: ({ 
        setSelectedKeys, 
        selectedKeys, 
        confirm, 
        clearFilters 
    }: {
        setSelectedKeys: (keys: React.Key[]) => void;
        selectedKeys: React.Key[];
        confirm: () => void;
        clearFilters?: () => void;
    }) => (
        <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
                value={selectedKeys.length ? [
                    dayjs((selectedKeys as unknown as [number, number][])[0]?.[0]),
                    dayjs((selectedKeys as unknown as [number, number][])[0]?.[1])
                ] : null}
                onChange={(dates) => {
                    if (dates) {
                        const startDate = dates[0]?.startOf('day');
                        const endDate = dates[1]?.endOf('day');
                        setSelectedKeys([[startDate?.valueOf(), endDate?.valueOf()]] as unknown as React.Key[]);
                    } else {
                        setSelectedKeys([]);
                    }
                }}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => confirm()}
                    size="small"
                    style={{ width: 90 }}
                >
                    Filter
                </Button>
                <Button
                    onClick={() => {
                        clearFilters?.();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered: boolean) => (
        <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: boolean | Key, record: Transaction) => {
        if (!value || !record.block_timestamp) return false;
        const timestamp = new Date(record.block_timestamp).getTime();
        const [startTime, endTime] = (value as string).split(',').map(Number);
        return timestamp >= startTime && timestamp <= endTime;
    },
    sorter: (a: Transaction, b: Transaction) => {
        const timeA = new Date(a.block_timestamp || 0).getTime();
        const timeB = new Date(b.block_timestamp || 0).getTime();
        return timeA - timeB;
    },
    render: (timestamp: string) => {
        if (!timestamp) return null;
        
        const formattedDate = formatDate(timestamp);
        
        return (
            <Space style={{ minWidth: 200 }}>
                <Tooltip title={new Date(timestamp).toLocaleString()}>
                    {formattedDate}
                </Tooltip>
            </Space>
        );
    }
}); 
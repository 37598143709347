import { IEntity } from "./entity";

// Entity-related enums
export enum AddressType {
    DEFAULT = "default_address",
    ADDRESS = "address", 
    CONTRACT = "contract",
    TOKEN = "token",
    WALLET = "wallet",
    DAO = "dao",
    EXCHANGE = "exchange",
    LABEL = "label",
    COMPANY = "company",
    INDIVIDUAL = "individual",
    UNKNOWN = "unknown",
    NFT_CONTRACT = "nft_contract",
    TOKEN_CONTRACT = "token_contract",
    DEFI_CONTRACT = "defi_contract",
    MULTISIG = "multisig",
    EOA = "eoa",
    ORACLE = "oracle"
}
    
export const ADDRESS_TYPE_COLORS: Record<AddressType, string> = {
    [AddressType.DEFAULT]: '#52c41a',     // Green
    [AddressType.ADDRESS]: '#8c8c8c',     // Grey
    [AddressType.CONTRACT]: '#1890ff',    // Blue
    [AddressType.TOKEN]: '#52c41a',       // Green
    [AddressType.WALLET]: '#722ed1',      // Purple
    [AddressType.DAO]: '#eb2f96',         // Pink
    [AddressType.EXCHANGE]: '#fa8c16',    // Orange
    [AddressType.LABEL]: '#13c2c2',       // Cyan
    [AddressType.COMPANY]: '#2f54eb',     // Geekblue
    [AddressType.INDIVIDUAL]: '#faad14',  // Gold
    [AddressType.UNKNOWN]: '#d9d9d9',     // Light grey
    [AddressType.NFT_CONTRACT]: '#13c2c2', // Cyan
    [AddressType.TOKEN_CONTRACT]: '#52c41a', // Green
    [AddressType.DEFI_CONTRACT]: '#722ed1', // Purple
    [AddressType.MULTISIG]: '#eb2f96', // Pink
    [AddressType.EOA]: '#fa8c16', // Orange
    [AddressType.NFT_CONTRACT]: '#13c2c2', // Cyan
    [AddressType.TOKEN_CONTRACT]: '#52c41a', // Green
    [AddressType.DEFI_CONTRACT]: '#722ed1', // Purple
    [AddressType.MULTISIG]: '#eb2f96', // Pink
    [AddressType.ORACLE]: '#1890ff', // Blue
};

export enum AddressStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
    PENDING = "pending",
    DELETED = "deleted",
    BLOCKED = "blocked",
    DEPRECATED = "deprecated"
}

interface IWalletStats {
    nfts: string;
    collections: string;
    transactions: {
        total: string;
    };
    nft_transfers: {
        total: string;
    };
    token_transfers: {
        total: string;
    };
}

export interface IToken {
    token_address: string;
    name: string;
    symbol: string;
    decimals?: number;
    balance: string;
    balance_formatted?: string;
    value_usd?: number;
    type?: 'token' | 'nft';
    logo_url?: string;
    logo?: string;
    thumbnail?: string;
    usd_at_risk?: number;
    usd_at_risk_formatted?: string;
    chain_id?: string;
    // Moralis specific fields
    possible_spam?: boolean;
    verified_contract?: boolean;
    usd_price?: number;
    usd_price_24hr_percent_change?: number;
    usd_price_24hr_usd_change?: number;
    usd_value?: number;
    usd_value_24hr_usd_change?: number;
    native_token?: boolean;
    portfolio_percentage?: number;
    contract_type?: string;
    security_score?: number;
    verified_contract?: boolean;
    percentage_relative_to_total_supply?: number;
}

export interface IAddress {
    address_labels: any;
    address_id: string;
    id: string;
    entity_id: string;
    entities: Array<IEntity>;
    main_entity: IEntity;
    abis: any;
    abi_entries?: Array<{chain_id: string, abi: Object}>;
    address: string;
    labels: array[IAddressLabel];
    default_labels: array[IAddressLabel];
    tags: array[ITag];
    created_at: number;
    type: AddressType;
    created_by: string;
    org_id: string;
    tenant_id: string;
    user_id: string;
    status: AddressStatus;
    shared_with: Array<IUser>;
    main_labels: map[IAddressLabel];
    main_labels_grouped_by_chain: array[IAddressLabel];
    address_items_map: Record<string, IAddress>;
    transaction_assignments: Array<any>
    notes: string;
    is_contract: boolean;
    transactions: Array<ITransaction>;
    pagination_cursors: PaginationCursor;
    search_addresses: {
        items: any[];
        last: string;
    };
    active_chain_ids: Array<string>
    chain_ids: Array<string>
    main_chain_ids: Array<string>
    friendly_names: Array<string>
    chain_names: Array<string>
    // Contract fields
    risk_score?: number;
    risk_level?: string;
    compliance_status?: string;
    verified?: boolean;
    // NFT Contract fields
    collection_name?: string;
    token_standard?: string;
    total_supply?: number;
    floor_price?: number;
    holders?: number;
    // Token Contract fields
    token_name?: string;
    token_symbol?: string;
    decimals?: number;
    // DeFi Contract fields
    protocol_name?: string;
    protocol_type?: string;
    tvl?: number;
    // Wallet fields
    balance?: number;
    total_transactions?: number;
    first_transaction_at?: string;
    last_transaction_at?: string;
    // Multisig fields
    required_signatures?: number;
    total_signers?: number;
    signers?: string[];
    holdings?: IToken[];
    // DeFi related fields
    defi_positions?: {
        protocols: IDeFiProtocol[];
        total_value?: number;
        total_positions?: number;
    };
    // Address Watcher fields
    is_watching?: boolean;
    processing_mode?: 'simple' | 'advanced';
    last_processed_at?: string;
    transactions_today?: number;
    watch_status?: 'watching' | 'processing' | 'unwatched' | 'error';
    contract_symbol?: string;
    contract_name?: string;
    name?: string;
}

export interface IDeFiToken {
    token_address: string;
    token_type: string;
    name: string;
    symbol: string;
    contract_address: string;
    decimals: number;
    logo: string;
    thumbnail: string;
    balance: string;
    balance_formatted: string;
    usd_price: number;
    usd_value: number;
}

export interface IDeFiPositionDetails {
    market?: string;
    fee_tier?: number;
    range_tnd?: number;
    reserves?: string[];
    current_price?: number;
    is_in_range?: boolean;
    price_upper?: number;
    price_lower?: number;
    price_label?: string;
    liquidity?: number;
    range_start?: number;
    pool_address?: string;
    position_key?: string;
    asset_standard?: string;
    apy?: number;
    is_debt?: boolean;
    is_variable_debt?: boolean;
    is_stable_debt?: boolean;
    shares?: string;
    reserve0?: string;
    reserve1?: string;
    factory?: string;
    pair?: string;
    share_of_pool?: number;
    projected_earnings_usd?: {
        daily: number;
        weekly: number;
        monthly: number;
        yearly: number;
    }
    is_enabled_as_collateral?: boolean;
}

export interface IDeFiPosition {
    label: string;
    tokens: IDeFiToken[];
    address: string;
    balance_usd: number;
    total_unclaimed_usd_value: number;
    position_details: IDeFiPositionDetails;
}

export interface IDeFiProtocol {
    protocol_name: string;
    protocol_id: string;
    protocol_url: string;
    protocol_logo: string;
    total_usd_value: number;
    total_unclaimed_usd_value: string | number;
    position: IDeFiPosition;
    chain_id: string;
    account_data: {
        net_apy: number;
        health_factor: number;
    };
    total_projected_earnings_usd: {
        daily: number;
        weekly: number;
        monthly: number;
        yearly: number;
    }
}

export interface ITokenApproval {
    block_number: string;
    block_timestamp: string;
    transaction_hash: string;
    value: string;
    value_formatted: string;
    token: IToken;
    spender: {
        address: string;
        address_label: string;
        entity: string;
        entity_logo: string;
    };
}

export interface ITokenPnL {
    token_address: string;
    avg_buy_price_usd: string;
    avg_sell_price_usd: string;
    total_usd_invested: string;
    total_tokens_sold: string;
    total_tokens_bought: string;
    total_sold_usd: string;
    avg_cost_of_quantity_sold: string;
    count_of_trades: number;
    realized_profit_usd: string;
    realized_profit_percentage: number;
    total_buys: number;
    total_sells: number;
    name: string;
    symbol: string;
    decimals: string;
    logo: string;
    logo_hash: string;
    thumbnail: string;
    possible_spam: boolean;
}

export interface INFTCollection {
    token_address: string;
    name: string;
    synced_at: string;
    symbol: string;
    contract_type: string;
    possible_spam: boolean;
    verified_collection: boolean;
    collection_logo: string;
    collection_banner_image: string;
    collection_category: string;
    project_url?: string;
    wiki_url?: string;
    discord_url?: string;
    telegram_url?: string;
    twitter_username?: string;
    instagram_username?: string;
    count?: number;
    chain_id?: string;
    github_username?: string;
    metadata?: string;
    token_uri?: string;
}

export interface INFTSale {
    transaction_hash: string;
    block_timestamp: string;
    buyer_address: string;
    seller_address: string;
    price: string;
    price_formatted: string;
    usd_price_at_sale: string;
    current_usd_value: string;
    token_id: string;
    payment_token: {
        token_name: string;
        token_symbol: string;
        token_logo: string;
        token_decimals: string;
        token_address: string;
    };
}

export interface INFT {
    token_address: string;
    token_id: string;
    owner_of: string;
    token_hash: string;
    block_number: string;
    block_number_minted: string;
    contract_type: string;
    token_uri: string;
    metadata: string;
    normalized_metadata: string | any;
    media: string;
    minter_address: string;
    last_token_uri_sync: string;
    last_metadata_sync: string;
    amount: string;
    name: string;
    symbol: string;
    possible_spam: boolean;
    verified_collection: boolean;
    rarity_rank?: number;
    rarity_label?: string;
    rarity_percentage?: number;
    last_sale?: INFTSale;
    chain_id?: string;
    floor_price?: number;
    floor_price_usd?: number;
    floor_price_currency?: string;
}

export interface IProfitability {
    token_address: string;
    contract_type: string;
    avg_buy_price_usd: string;
    avg_sell_price_usd: string;
    total_usd_invested: string;
    total_tokens_bought: string;
    total_tokens_sold: string;
    total_sold_usd: string;
    avg_cost_of_quantity_sold: string;
    count_of_trades: number;
    realized_profit_usd: string;
    realized_profit_percentage: number;
    unrealized_profit_usd?: string;
    unrealized_profit_percentage?: number;
    total_buys: number;
    total_sells: number;
    name: string;
    symbol: string;
    decimals: string;
    logo: string;
    possible_spam: boolean;
    chain_id?: string;
}

// For the overall wallet profitability
export interface IWalletProfitability {
    total_profit_loss_usd: number;
    total_profit_loss_percentage: number;
    total_realized_profit_loss_usd: number;
    total_unrealized_profit_loss_usd: number;
}

interface IAddressItem {
    labels?: Array<{
        id: string;
        name: string;
        address_name: string;
        color: string;
        source: string;
        logo: string | null;
        chain_ids: string[];
        main_label_chain_ids: string[];
        verified: boolean;
    }>;
    tags?: Array<{
        name: string;
        color: string;
    }>;
    entities?: Array<{
        id: string;
        name: string;
        color: string;
        chain_ids: string[] | null;
        main_label_chain_ids: string[];
    }>;
    is_contract: boolean;
    verified: boolean;
}

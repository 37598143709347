import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ContractStateViewer from './ContractStateViewer';
import { message, Alert } from 'antd';

interface ContractStateViewerWithABIProps {
  contractAddress: string;
  chainId: string;
  abi?: any;
  title?: string;
  apiDomain: string;
  region: string;
  regionAlias: string;
  transactionContext?: {
    selectedAddress?: string;
    blockNumber?: number;
    timestamp?: number;
    chain_id?: string;
    [key: string]: any;
  };  
  token: string;
  style?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  titleRowStyle?: React.CSSProperties;
  contractInfoStyle?: React.CSSProperties;
}

interface ABIResponseItem {
  abi: string;
  chain_ids: string[];
  address: string;
  id: string;
  description: string;
  is_default_abi: boolean;
  name: string;
  status: string;
}

interface ABIResponse {
  result: ABIResponseItem[];
}

interface CachedABI {
  abi: any[];
  metadata: {
    name: string;
    description: string;
    is_default_abi: boolean;
    id: string;
    status: string;
  };
  timestamp: number;
  chainId: string;
}

// Create a global cache for ABIs
const abiCache = new Map<string, CachedABI>();

const ContractStateViewerWithABI: React.FC<ContractStateViewerWithABIProps> = ({
  contractAddress,
  chainId,
  abi,
  title,
  apiDomain,
  region,
  regionAlias,
  transactionContext,
  token,
}) => {
  const [contractMetadata, setContractMetadata] = useState<any>(null);
  const [cachedAbi, setCachedAbi] = useState<any[] | null>(null);
  
  // Cache expiration time (30 minutes)
  const CACHE_EXPIRATION = 30 * 60 * 1000;
  
  // Check cache on component mount
  useEffect(() => {
    const cacheKey = `${chainId}_${contractAddress.toLowerCase()}`;
    const cached = abiCache.get(cacheKey);
    
    if (cached && Date.now() - cached.timestamp < CACHE_EXPIRATION) {
      console.log(`Using cached ABI for ${transactionContext?.selectedAddress}`);
      setContractMetadata({...cached.metadata, address: contractAddress});
      setCachedAbi(cached.abi);
    }
  }, [transactionContext?.selectedAddress, chainId]);

  const fetchAbi = async (address: string, chainId: string) => {
    console.log("fetchAbi", address, chainId);
    
    // Normalize the address
    const normalizedAddress = address.toLowerCase();
    
    // Check cache first
    const cacheKey = `${chainId}_${normalizedAddress}`;
    const cached = abiCache.get(cacheKey);
    
    if (cached && Date.now() - cached.timestamp < CACHE_EXPIRATION) {
      console.log(`Using cached ABI for ${address}`);
      
      // If this is for the main contract, update the metadata state
      if (normalizedAddress === transactionContext?.selectedAddress?.toLowerCase()) {
        setContractMetadata({...cached.metadata, address: contractAddress});
      }
      
      return cached.abi;
    }
    
    try {
      const requestHeaders = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json, text/plain, */*",
        "Source-Platform": "dashboard",
        "Source-Region": region,
        "Destination-Region": region,
      };

      const continent = region.split('-')[0].toUpperCase();
      const apiUrl = `https://${regionAlias}.${continent.toLowerCase()}.api.${apiDomain}`;

      const url = `${apiUrl}/private/abis/blue/GET/${continent.toUpperCase()}/v0/abis/${chainId}_${address}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: requestHeaders
      });
      
      if (!response.ok) {
        throw new Error(`Failed to fetch ABI: ${response.statusText}`);
      }
      
      const data: ABIResponse = await response.json();
      
      // Check if result is an array and has items
      if (data.result && Array.isArray(data.result) && data.result.length > 0) {
        // Use the first item in the array (or the default ABI if available)
        const defaultAbi = data.result.find(item => item.is_default_abi) || data.result[0];
        
        // Create metadata object
        const metadata = {
          name: defaultAbi.name,
          description: defaultAbi.description,
          is_default_abi: defaultAbi.is_default_abi,
          id: defaultAbi.id,
          status: defaultAbi.status
        };

        // If this is for the main contract, update the metadata state
        if (normalizedAddress === transactionContext?.selectedAddress?.toLowerCase()) {
          setContractMetadata({...metadata, address: contractAddress});
        }
        
        // Check if abi is a string and not undefined before parsing
        if (defaultAbi.abi && typeof defaultAbi.abi === 'string') {
          try {
            const parsedAbi = JSON.parse(defaultAbi.abi);
            
            // Cache the result
            abiCache.set(cacheKey, {
              abi: parsedAbi,
              metadata,
              timestamp: Date.now(),
              chainId: chainId
            });
            
            // If this is for the main contract, update the cached ABI state
            if (normalizedAddress === transactionContext?.selectedAddress?.toLowerCase()) {
              setCachedAbi(parsedAbi);
            }
            
            return parsedAbi;
          } catch (parseError) {
            console.error('Error parsing ABI JSON:', parseError);
            message.error('Failed to parse ABI JSON');
            return [];
          }
        } else {
          console.warn('ABI is undefined or not a string:', defaultAbi.abi);
          return [];
        }
      } else {
        console.warn('No ABI results found in the response');
        return [];
      }
    } catch (error) {
      console.error('Error fetching ABI:', error);
      message.error('Failed to fetch ABI for this contract');
      return [];
    }
  };

  return (
    <ContractStateViewer
      contractAddress={contractAddress}
      chainId={chainId}
      transactionContext={{
        selectedAddress: transactionContext?.selectedAddress
      }}
      fetchAbi={fetchAbi}
      title={title}
      contractMetadata={contractMetadata}
      abi={cachedAbi || undefined} // Pass the cached ABI if available
    />
  );
};

export default ContractStateViewerWithABI; 
import React, { useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import { Create, useForm, SaveButton, } from "@refinedev/antd";
import { IRoles } from "interfaces";

import { 
    Tag,
    Layout as AntdLayout,
    Typography,
    Form,
    Input,
    Checkbox,
    Tooltip,
  } from 'antd';

const { Text, Title } = Typography;

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const RoleCreate: React.FC<IResourceComponentsProps> = () => {
    const { user } = useAuth0();

    const { formProps, saveButtonProps, onFinish } = useForm<IRoles>({
        resource: "roles"
    });

    const onFinishHandler = (values : any ) => {
        console.log(values)
        console.log(allAppPermissionsCopy)

        onFinish?.({
            role_name: values.role_name,
            description: values.role_description,
            scopes: allAppPermissionsCopy
        });

        console.log("Finished")
        // onFinish(values);
        // close();
    };

    const all_app_permissions = [{"description":"Ability to create transactions","name":"tranactions:create"},{"description":"Ability to decode input","name":"decode:invoke"},{"description":"Ability to encode input","name":"encode:invoke"},{"description":"Ability to list many events","name":"events:list"},{"description":"Ability to list many transactions","name":"transactions:list"},{"description":"Ability to process transactions","name":"transactions:process"},{"description":"Ability to read events","name":"events:show"},{"description":"Ability to read smart contracts","name":"smart-contract:read"},{"description":"Ability to read statistics","name":"stats:show"},{"description":"Ability to read transactions","name":"transactions:show"},{"description":"Ability to run the use case runner","name":"evaluate-permission-request:invoke"},{"description":"Ability to see many statistics","name":"stats:list"},{"description":"Ability to see the activity-feed tab","name":"activity-feed:list"},{"description":"Ability to see the settings tab","name":"settings:list"},{"description":"Ability to validate a transaction","name":"transaction:validate"},{"description":"Ability to validate permission document signatures","name":"signature:validate"}]
    let [allAppPermissionsCopy, setAllAppPermissionsCopy] = React.useState(all_app_permissions)

    // Sort alphabetically
    all_app_permissions.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
  
    const handleSelectedScopeChange = (value:any) => {
      if(value.target.checked){
        console.log("To add")
        setAllAppPermissionsCopy(prevState => [...prevState, value.target.id])
      }
      else{
        console.log("To remove")
        var index = allAppPermissionsCopy.indexOf(value.target.id)
        setAllAppPermissionsCopy(allAppPermissionsCopy.splice(index, 1))
      }
    };


    return (
        <Create title="Create New Role" saveButtonProps={{ ...saveButtonProps }} footerButtons={({ saveButtonProps }) => (
            <>
                <SaveButton {...saveButtonProps} />
            </>
        )}
        >
            <Form {...formProps} onFinish={onFinishHandler} layout="vertical">
                <Form.Item
                    label="New Role Name"
                    name="role_name"                    
                    rules={[
                        {
                            required: true,
                        },
                        {
                            min: 5
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                try{
                                    let default_roles = JSON.parse(process.env.REACT_APP_DEFAULT_APP_ROLES || "")["roles"]
                                    default_roles.forEach((role: { label: any; }) => {
                                        if(role.label.toLowerCase() == value.toLowerCase()){
                                            throw new Error("default_role_name");
                                        }
                                    })
                                    return Promise.resolve();
                                }
                                catch (error){
                                    console.log(error)
                                    return Promise.reject(
                                        new Error("'The role name can not be the same as the default roles"),
                                    );
                                }
                            },
                        },
                    ]}
                >
                    
                    <Input id="new_role_name" style={{ width: 250 }} placeholder="Please input the role name" defaultValue=""/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="role_description"                    
                    rules={[
                        {
                            required: false,
                        },
                        {
                            min: 5
                        },
                    ]}
                >
                    
                    <Input id="new_role_description" style={{ width: 250 }} placeholder="Please input the role description" defaultValue=""/>
                </Form.Item>
                <Title level={5}>All possible app permissions</Title>
            <Text> 
              <Tag  color={"geekblue"} >
                    {
                        all_app_permissions.map(scope=>{
                          return <Tooltip title={scope?.description}><Form>
                          <Form.Item label="" name={scope?.name} >
                              <Checkbox onChange={handleSelectedScopeChange} defaultChecked={true}>{scope?.name}</Checkbox>
                          </Form.Item>
                        </Form>
                        </Tooltip>
                        })
                    }
                </Tag>
            </Text>
            </Form>
        </Create>
    );
};

import { Refine,    AuthBindings,
    Authenticated, } from "@refinedev/core";
import {
    notificationProvider,
    ThemedLayoutV2,
    ThemedSiderV2,
    ThemedTitleV2,
    ErrorComponent,
    RefineThemes,
} from "@refinedev/antd";
import dataProvider from "../src/utility";
import routerProvider, {
    NavigateToResource,
    CatchAllNavigate,
    UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import {
    FieldTimeOutlined,
    SendOutlined,
    AuditOutlined,
    BarChartOutlined, 
    DashboardOutlined,
    AlignLeftOutlined,
    ClusterOutlined,
    ExperimentOutlined,
    InteractionOutlined,
    PlusSquareOutlined,
    UserAddOutlined,
    SettingOutlined,
    BlockOutlined
} from "@ant-design/icons";
import Login from "../src/pages/login/login";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from "axios";

import { DashboardConfigProvider } from "contexts";
import "@refinedev/antd/dist/reset.css";

import { TransactionsList, TransactionShow } from "pages/transactions";
import { EntityList, EntityShow, EntityEdit, EntityCreate } from "pages/entities";
// import User from './pages/settings/user.jsx';
import Settings from './pages/settings/settings.jsx';
import { AddressShow, AddressEdit} from "pages/addresses";
import { ActionsList, ActionsShow, ActionsEdit} from "pages/actions";
import { IntegrationsList, IntegrationShow, IntegrationCreate, IntegrationEdit} from "pages/integrations";
import { UsersList, UserShow, UserCreate, UserEdit} from "pages/users";
import { WalletShow} from "pages/wallets";
import { GroupList, GroupShow} from "pages/groups";

import { DashboardList } from "pages/dashboard";

import { RoleCreate } from "pages/settings/roles";

import Stats from './pages/stats/stats.jsx';

import { ActivityFeed } from './pages/activity-feed/ActivityFeed';
import { TracersList, TracersCreate,  TracersEdit, TracersShow } from "pages/tracers";
import { SharedGraphEdit, SharedGraphShow } from "pages/shared-graphs";

import '@rainbow-me/rainbowkit/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { http, WagmiProvider } from 'wagmi'
import {
    mainnet,
    goerli,
    sepolia,
    holesky,
    polygon,
    polygonMumbai,
    bsc,
    bscTestnet,
    avalanche,
    fantom,
    cronos,
    palm,
    arbitrum,
    gnosis,
    gnosisChiado,
    base,
    baseGoerli,
    baseSepolia,
    optimism,
  } from 'wagmi/chains';

import {
  getDefaultConfig,    
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';

import { model, adapter } from "accessControl";
import { newEnforcer } from "casbin.js";

import { newModel, MemoryAdapter } from "casbin.js";

let API_URL = process.env.REACT_APP_API_URL || ""
console.log(process.env.REACT_APP_BUILD_TIMESTAMP)
console.log(process.env.REACT_APP_ENV)
console.log(process.env.REACT_APP_API_URL)
console.log("Dashboard")

if (process.env.REACT_APP_ENV === 'prd') console.log = function () {}; // Remove log statements

const config = getDefaultConfig({
    appName: process.env.REACT_APP_WALLET_CONNECT_APP_NAME || "AuthE - Sandbox",
    projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || "f5941214a396ccdfb6c5f50c6dfdf8a9",
    chains: [
        mainnet,
        goerli,
        sepolia,
        holesky,
        polygon,
        polygonMumbai,
        {
            ...bsc,
            rpcUrls: {
                ...bsc.rpcUrls,
                default: {
                    http: [`https://rpc.ankr.com/bsc/${process.env.REACT_APP_ANKR_BSC_API_KEY || 'your_ankr_api_key_here'}`],
                },
                public: {
                    http: [`https://rpc.ankr.com/bsc/${process.env.REACT_APP_ANKR_BSC_API_KEY || 'your_ankr_api_key_here'}`],
                }
            }
        },
        bscTestnet,
        avalanche,
        fantom,
        cronos,
        palm,
        arbitrum,
        gnosis,
        gnosisChiado,
        base,
        baseGoerli,
        baseSepolia,
        optimism,
    ],
    ssr: false, // If your dApp uses server side rendering (SSR)
    transports: {
        [mainnet.id]: http(),
        [goerli.id]: http(),
        [sepolia.id]: http(),
        [holesky.id]: http(),
        [polygon.id]: http(),
        [polygonMumbai.id]: http(),
        [bsc.id]: http(`https://rpc.ankr.com/bsc/${process.env.REACT_APP_ANKR_BSC_API_KEY || 'your_ankr_api_key_here'}`),
        [bscTestnet.id]: http(),
        [avalanche.id]: http(),
        [fantom.id]: http(),
        [cronos.id]: http(),
        [palm.id]: http(),
        [arbitrum.id]: http(),
        [gnosis.id]: http(),
        [gnosisChiado.id]: http(),
        [base.id]: http(),
        [baseGoerli.id]: http(),
        [baseSepolia.id]: http(),
        [optimism.id]: http(),
    },
});
const queryClient = new QueryClient() 

import { Header } from "../src/components/header/index";
import { ConfigProvider } from "antd";


const App: React.FC = () => {
    const { isLoading, user, logout, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    if (isLoading) {
        return <span>loading...</span>;
    }

    const authProvider: AuthBindings = {
        login: async () => {
            return {
                success: true,
            };
        },
        logout: async () => {
            logout();
            return {
                success: true,
            };
        },
        onError: async (error) => {
            console.error(error);
            return { error };
        },
        check: async () => {
            console.log("Check Auth0 token")
            try {
                const setup_recently_migrated_to_new_continent_config = localStorage.getItem('setup_recently_migrated_to_new_continent_config') ? localStorage.getItem('setup_recently_migrated_to_new_continent_config') : ""
                const tenant_id = localStorage.getItem('tenant_id') ? localStorage.getItem('tenant_id') : "root_tenant"

                // Redirect to setup if token has been set and flag to do setup flow is enabled. The token however can be cached so can contain outdated information.
                // if(user?.[process.env.REACT_APP_BASE_URL + '/custom_app_config']?.['needs_first_login_setup'] && !setup_recently_migrated_to_new_continent_config){
                //     const redirect_to_url = process.env.REACT_APP_ENV == "dev" ? "http://" + process.env.REACT_APP_API_BASE_URL + "/setup/index.html" : "https://" + process.env.REACT_APP_API_BASE_URL + "/setup/index.html";
                //     console.log("Redirecting to: " + redirect_to_url)
                //     window.location.href = redirect_to_url
                // }

                const authorizationParamsMap = new Map<string, any>();
                authorizationParamsMap.set('tenant_id', tenant_id);
                authorizationParamsMap.set('setup_recently_migrated_to_new_continent_config', setup_recently_migrated_to_new_continent_config);

                // Gets a new token with the updated continent if recently migrated
                if(setup_recently_migrated_to_new_continent_config && new Date(JSON.parse(setup_recently_migrated_to_new_continent_config)['date']) > new Date(Date.now() - 20000) ){
                    console.log("Recently migrated so requesting a new token with cacheMode off")
                    const access_token = await getAccessTokenSilently( { cacheMode: 'off', authorizationParams: authorizationParamsMap});
                } else{
                    const access_token = await getAccessTokenSilently( { cacheMode: 'on', authorizationParams: authorizationParamsMap});
                    if(setup_recently_migrated_to_new_continent_config){
                        localStorage.removeItem('setup_recently_migrated_to_new_continent_config') // Clean up old item
                    }
                }
                
                const token = await getIdTokenClaims();  

                if (token) {
                    console.log(token)
                    localStorage.setItem('token', JSON.stringify(token));
                    axios.defaults.headers.common = {
                        Authorization: `Bearer ${token.__raw}`,
                        "Source-Platform": "dashboard",
                        "Source-Region": "us-west-2",
                        "Destination-Region": "us-west-2",
                    };
                    
                    return {
                        authenticated: true,
                    };
                } else {
                    console.log("No token present")
                    return {
                        authenticated: false,
                        error: {
                            message: "Check failed",
                            name: "Token not found",
                        },
                        redirectTo: "/",
                        logout: true,
                    };
                }
            } catch (error: any) {
                console.log(error)
                return {
                    authenticated: false,
                    error: new Error(error),
                    redirectTo: "/login/index.html",
                    logout: true,
                };
            }
        },
        getPermissions: async () => null,
        getIdentity: async () => {
            console.log("hello user")
            
            if (user) {
                console.log(user)
                return {
                    ...user,
                    avatar: user.picture,
                };
            }
            return null;
        },
    };

    return (
        <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
        <BrowserRouter>
            <DashboardConfigProvider>
                <ConfigProvider theme={RefineThemes.Blue}>
                    <Refine
                        accessControlProvider={{
                            can: async ({ resource, action, params}) => {
                                // const enforcer = await newEnforcer(model, adapter)
                                const enforcer = await newEnforcer(model, new MemoryAdapter(process.env.REACT_APP_CASBIN_PERMISSION_FROM_SSM || ""));
                                ;
                                
                                const roles = user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']
                                
                                var can = false
                                for (let role of roles) {
                                    
                                    const result:any = await enforcer.enforce(
                                        role.name,
                                        resource,
                                        action,
                                    );
                                    
                                    if(result){
                                        const reason = null
                                        can = result
                                    }
                                }
                                const reason = can? null : params?.reason
                                return { can, reason: reason , };
                            },
                        }}
                        authProvider={authProvider}
                        dataProvider={dataProvider(API_URL)}
                        routerProvider={routerProvider}
                        resources={[
                            // IMPORTANT NEED PERMISSIONS BEFORE BEING ABLE TO SEE THE ROUTES
                            {
                                name: "dashboard",
                                list: "/dashboard",
                                meta: {
                                    label: "Dashboard",
                                    icon: <AuditOutlined />,
                                },
                            },
                            {
                                name: "transactions",
                                list: "/transactions",
                                show: "/transactions/show/:id",
                                meta: {
                                    label: "Transactions",
                                    icon: <AlignLeftOutlined />,
                                },
                            },
                            {
                                name: "entities",
                                list: "/entities",
                                show: "/entities/show/:id",
                                create: "/entities/create",
                                edit: "/entities/edit/:id",
                                meta: {
                                    label: "Entities",
                                    icon: <ClusterOutlined />,
                                },
                            },
                            {
                                name: "groups",
                                list: "/groups",
                                show: "/groups/show/:id",
                                meta: {
                                    label: "Groups",
                                    icon: <BlockOutlined />,
                                },
                            },                              
                            // {
                            //     name: "tasks",
                            //     list: "/tasks",
                            //     create: "/tasks/create",
                            //     edit: "/tasks/edit/:id",
                            //     show: "/tasks/show/:id",
                            // },
                            // {
                            //     name: "notifications",
                            //     list: "/notifications",
                            //     create: "/notifications/create",
                            //     edit: "/notifications/edit/:id",
                            //     show: "/notifications/show/:id",
                            // },
                            {
                                name: "tracers",
                                list: "/tracers",
                                create: "/tracers/create",
                                edit: "/tracers/edit/:id",
                                show: "/tracers/show/:id",
                                meta: {
                                    label: "Tracers",
                                    icon: <SendOutlined />,
                                },
                            },
                            {
                                name: "addresses",
                                // list: "/addresses",
                                // create: "/addresses/create",
                                edit: "/addresses/edit/:id",
                                show: "/addresses/show/:id",
                                // meta: {
                                //     label: "Addresses",
                                //     icon: <SendOutlined />,
                                // },

                            },
                            {
                                name: "wallets",
                                show: "/wallets/show/:id",
                                // meta: {
                                //     label: "Addresses",
                                //     icon: <SendOutlined />,
                                // },

                            },                                              
                            {
                                name: "stats",
                                list: "/stats",
                                meta: {
                                    label: "Stats",
                                    icon: <BarChartOutlined />,
                                },
                            },
                            {
                                name: "activity-feed",
                                list: "/activity-feed",
                                meta: {
                                    label: "Activity Feed",
                                    icon: <FieldTimeOutlined />,
                                },
                            },
                            {
                                name: "actions",
                                list: "/actions",
                                create: "/actions/create",
                                edit: "/actions/edit/:id",
                                show: "/actions/show/:id",
                                meta: {
                                    label: "Actions",
                                    icon: <InteractionOutlined />,
                                },
                            },
                            {
                                name: "integrations",
                                list: "/integrations",
                                create: "/integrations/create",
                                edit: "/integrations/edit/:id",
                                show: "/integrations/show/:id",
                                meta: {
                                    label: "Integrations",
                                    icon: <PlusSquareOutlined />,
                                },
                            },
                            {
                                name: "users",
                                list: "/users",
                                create: "/users/create",
                                edit: "/users/edit/:id",
                                show: "/users/show/:id",
                                meta: {
                                    label: "Users",
                                    icon: <UserAddOutlined />,
                                },
                            },
                            {
                                name: "shared-graphs",
                                edit: "/shared-graphs/edit/:id",
                                show: "/shared-graphs/show/:id",                            
                                meta: {
                                    label: "Shared Graphs",
                                    icon: <SettingOutlined />,
                                },
                            },                        
                            {
                                name: "settings",
                                list: "/settings",
                                meta: {
                                    label: "Settings",
                                    icon: <SettingOutlined />,
                                },
                            },
                            // {
                            //     name: "categories",
                            //     list: "/categories",
                            //     create: "/categories/create",
                            //     edit: "/categories/edit/:id",
                            // },
                        ]}
                        notificationProvider={notificationProvider}
                        options={{
                            syncWithLocation: true,
                            warnWhenUnsavedChanges: true,
                        }}
                    >
                        <Routes>
                        <Route
                                element={
                                    <Authenticated 
                                        key="login1"
                                        fallback={<CatchAllNavigate to="/login/index.html" />}
                                    >
                                        <ThemedLayoutV2 Sider={() => <ThemedSiderV2 fixed Title={({ collapsed }) => (
                                <ThemedTitleV2 collapsed={false} text="Authe"/>
                            )}/>} initialSiderCollapsed={false} Header={() => <Header sticky />}
                                    Title={({ collapsed }) => (
                                        <ThemedTitleV2
                                            // collapsed is a boolean value that indicates whether the <Sidebar> is collapsed or not
                                            collapsed={collapsed}
                                            text="Authe"
                                        />
                                    )}
                                    
                                    >
                                            <Outlet />
                                        </ThemedLayoutV2>
                                    </Authenticated>
                                }
                            >
                                <Route
                                    index
                                    element={
                                        <NavigateToResource resource="dashboard" />
                                    }
                                />
                                <Route path="/dashboard">
                                    <Route index element={<DashboardList/>} />
                                </Route>
                                <Route path="/transactions">
                                    <Route index element={<TransactionsList />} />
                                    <Route path="show/:id" element={<TransactionShow />} /> 
                                </Route>
                                <Route path="/entities">
                                    <Route index element={<EntityList />} />
                                    <Route path="show/:id" element={<EntityShow />} /> 
                                    <Route path="create" element={<EntityCreate />} />
                                    <Route path="edit/:id" element={<EntityEdit />} />
                                </Route>
                                <Route path="/wallets">
                                    <Route index element={<WalletShow />} />
                                    <Route path="show/:id" element={<WalletShow />} /> 
                                </Route>      
                                <Route path="/groups">
                                    <Route index element={<GroupList />}/>
                                    <Route path="show/:id" element={<GroupShow />} /> 
                                </Route>                                                    
                                <Route path="/tracers">
                                    <Route index element={<TracersList />} />
                                    <Route path="create" element={<TracersCreate />} />
                                    <Route path="edit/:id" element={<TracersEdit />} />
                                    <Route path="show/:id" element={<TracersShow />} /> 
                                </Route>
                                <Route path="/addresses">
                                    <Route path="edit/:id" element={<AddressEdit />} />
                                    <Route path="show/:id" element={<AddressShow />} /> 
                                </Route>
                                <Route path="/settings">
                                    <Route index element={<Settings />} />
                                    <Route path="roles/create" element={<RoleCreate />} />
                                </Route>
                                <Route path='/stats' element={<Stats /> } /> 
                                <Route path='/activity-feed' element={<ActivityFeed orgId={user?.org_id} tenantId={user?.tenant_id} address={user?.address} /> } /> 
                                <Route path='/actions'> 
                                    <Route index element={<ActionsList />} />
                                    <Route path="edit/:id" element={<ActionsEdit />} />
                                    <Route path="show/:id" element={<ActionsShow />} /> 
                                </Route>
                                <Route path='/integrations'> 
                                    <Route index element={<IntegrationsList />} />
                                    <Route path="edit/:id" element={<IntegrationEdit />} />
                                    <Route path="show/:id" element={<IntegrationShow />} /> 
                                    <Route path="create/" element={<IntegrationCreate />} /> 
                                </Route>
                                <Route path='/users'> 
                                    <Route index element={<UsersList />} />
                                    <Route path="create" element={<UserCreate />} />
                                    <Route path="edit/:id" element={<UserEdit />} />
                                    <Route path="show/:id" element={<UserShow />} /> 
                                </Route>
                                <Route path='/shared-graphs'> 
                                    <Route path="edit/:id" element={<SharedGraphEdit />} />
                                    <Route path="show/:id" element={<SharedGraphShow />} /> 
                                </Route>                            
                            </Route>

                        <Route
                                element={
                                    <Authenticated key="login2" fallback={<Outlet />}>
                                        <NavigateToResource resource="dashboard" />
                                    </Authenticated>
                                }
                            >
                                
                                <Route path="/login/index.html" element={<Login />} />
                            </Route>
                            <Route path="*" element={<ErrorComponent />} />
                        </Routes>
                        <UnsavedChangesNotifier />
                    </Refine>
                </ConfigProvider>
            </DashboardConfigProvider>
        </BrowserRouter>
        </RainbowKitProvider>
        </QueryClientProvider>
        </WagmiProvider>
    );
};

export default App;

import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { setupLeaderElection } from '../leader/LeaderElection';
import { useBroadcastChannel } from '../broadcast/BroadcastContext';
import { setupHeartbeat } from '../heartbeat/HeartbeatMonitor';
import { client, createSubscription } from '../../graphql/client';
import { 
    SubscriptionStatuses, 
    ChannelMessage, 
    SubscriptionOptions,
    DebugWindowInfo,
    SubscriptionResult
} from '../../graphql/types';
import { Client } from 'aws-amplify/api';

interface SubscriptionRef {
    unsubscribe: () => void;
    subscription?: any; // or proper type from your client
}

export const useSubscriptions = (options: SubscriptionOptions): SubscriptionResult => {
    const subscriptionsRef = useRef<{ [key: string]: SubscriptionRef | null }>({});
    const isLeader = useRef(false);
    const isEnabled = useRef(() => {
        const saved = localStorage.getItem('subscriptionEnabled');
        return saved !== null ? saved === 'true' : true;
    });
    const processedMessageIds = options.processedMessageIds || new Set<string>();
    const tabId = useRef(`Tab-${Math.random().toString(36).substr(2, 5)}`);
    const reconnectingRef = useRef(false);

    const [status, setStatus] = useState<SubscriptionStatuses>({});
    
    // Initialize broadcast channel
    const broadcastChannel = useBroadcastChannel('subscriptions');

    // Initialize debugger
    const debug = options.debug;

    // Setup subscription for a single channel
    const setupSubscription = useCallback(async (channelName: string, attempt = 0) => {
        const channelConfig = options.channels.find(c => c.name === channelName);
        if (!channelConfig) {
            debug.error(`Channel configuration not found: ${channelName}`);
            return null;
        }

        debug.log(`Setting up subscription for channel: ${channelName}`, { attempt });
        
        // Set connecting state - useful for UI feedback
        setStatus(prev => ({
            ...prev,
            [channelName]: {
                ...prev[channelName],
                state: 'connecting',
                lastMessageAt: new Date(),
                isEnabled: isEnabled.current(),
                isLeader: isLeader.current
            }
        }));
        
        try {
            const subscription = await createSubscription(channelName, {
                ...options,
                onMessage: (message: ChannelMessage) => {
                    debug.log(`✅ Received message on channel ${channelName}:`, message);
                    channelConfig.onMessage?.(message);
                    options.onMessage?.(message);
                    
                    setStatus(prev => ({
                        ...prev,
                        [channelName]: {
                            ...prev[channelName],
                            state: 'connected',
                            lastMessageAt: new Date(),
                            reconnectAttempts: 0,
                            isEnabled: isEnabled.current(),
                            isLeader: isLeader.current
                        }
                    }));

                    if (isLeader.current) {
                        let message_type = 'new-transaction';
                        if (message.channel.includes('streamed-transactions')) {
                            message_type = 'new-transaction';
                        } else if (message.channel.includes('address-watch')) {
                            message_type = 'address-watch';
                        } else{
                            message_type = 'new-transaction';
                        }
                        // Broadcast to other tabs
                        broadcastChannel.postMessage({
                            type: message_type as 'new-transaction' | 'address-watch',
                            data: message,
                            messageId: message.messageId,
                            fromSubscription: true,
                            timestamp: Date.now(),
                            tabId: tabId.current
                        });
                    }
                },
                onError: (error: any) => {
                    debug.error(`Subscription error on channel ${channelName}:`, error);
                    
                    const maxRetries = channelConfig.maxRetries ?? 3;
                    const retryDelay = channelConfig.retryDelay ?? 1000 * Math.pow(2, attempt);
                    
                    setStatus(prev => ({
                        ...prev,
                        [channelName]: {
                            ...prev[channelName],
                            state: 'error',
                            error: error.message,
                            reconnectAttempts: attempt,
                            isEnabled: isEnabled.current(),
                            isLeader: isLeader.current
                        }
                    }));

                    options.onError?.(error);

                    if (attempt < maxRetries) {
                        debug.log(`Retrying subscription in ${retryDelay}ms`, { attempt, maxRetries });
                        setTimeout(() => {
                            if (isLeader.current) {
                                setupSubscription(channelName, attempt + 1);
                            }
                        }, retryDelay);
                    }
                },
                onComplete: () => {
                    debug.log(`Subscription completed for channel: ${channelName}`);
                    options.onComplete?.();
                }
            });

            if (subscription) {
                // Update status immediately upon successful subscription
                setStatus(prev => ({
                    ...prev,
                    [channelName]: {
                        ...prev[channelName],
                        state: 'connected',
                        lastMessageAt: new Date(),
                        isEnabled: isEnabled.current(),
                        isLeader: isLeader.current
                    }
                }));

                subscriptionsRef.current[channelName] = {
                    unsubscribe: () => {
                        subscription.unsubscribe();
                        debug.log(`Unsubscribed from channel: ${channelName}`);
                        // Update status when unsubscribing
                        setStatus(prev => ({
                            ...prev,
                            [channelName]: {
                                ...prev[channelName],
                                state: 'disconnected',
                                lastMessageAt: new Date(),
                                isEnabled: isEnabled.current(),
                                isLeader: isLeader.current
                            }
                        }));
                    },
                    subscription
                };
            }
            
            return subscription;
        } catch (error) {
            debug.error(`Failed to setup subscription for channel: ${channelName}`, error);
            setStatus(prev => ({
                ...prev,
                [channelName]: {
                    ...prev[channelName],
                    state: 'error',
                    error: error instanceof Error ? error.message : 'Unknown error',
                    isEnabled: isEnabled.current(),
                    isLeader: isLeader.current
                }
            }));
            return null;
        }
    }, [options, debug, processedMessageIds, broadcastChannel]);

    // Sync subscription state across tabs
    useEffect(() => {
        const channel = new BroadcastChannel('subscriptions');
        
        const handleMessage = (event: MessageEvent) => {
            if (event.data.type === 'subscription-toggle' && event.data.tabId !== tabId.current) {
                isEnabled.current = () => event.data.enabled;
                debug.log(`Subscription state synced from other tab: ${event.data.enabled}`);
                
                if (!event.data.enabled) {
                    Object.entries(subscriptionsRef.current).forEach(([channel, subscription]) => {
                        subscription?.unsubscribe?.();
                        subscriptionsRef.current[channel] = null;
                    });
                } else if (isLeader.current) {
                    options.channels.forEach(channel => {
                        setupSubscription(channel.name, 0);
                    });
                }
            }
        };

        channel.addEventListener('message', handleMessage);
        return () => {
            channel.removeEventListener('message', handleMessage);
            channel.close();
        };
    }, []);

    // Toggle subscriptions on/off
    const toggleSubscription = useCallback(() => {
        const currentEnabled = isEnabled.current();
        const newEnabled = !currentEnabled;
        
        isEnabled.current = () => newEnabled;
        localStorage.setItem('subscriptionEnabled', String(newEnabled));
        debug.log(`Subscriptions ${newEnabled ? 'enabled' : 'disabled'}`);
        
        if (!newEnabled) {
            // Cleanup all subscriptions
            Object.entries(subscriptionsRef.current).forEach(([channel, subscription]) => {
                if (subscription) {
                    debug.log(`Unsubscribing from channel: ${channel}`);
                    // Ensure we call unsubscribe on the subscription object itself
                    if (subscription.subscription) {
                        subscription.subscription.unsubscribe();
                    }
                    if (subscription.unsubscribe) {
                        subscription.unsubscribe();
                    }
                    // Clear the reference
                    subscriptionsRef.current[channel] = null;
                }
                
                setStatus(prev => ({
                    ...prev,
                    [channel]: {
                        ...prev[channel],
                        state: 'disconnected',
                        lastMessageAt: new Date(),
                        isEnabled: newEnabled,
                        isLeader: isLeader.current
                    }
                }));
            });

            // Double-check cleanup
            subscriptionsRef.current = {};
        } else if (isLeader.current) {
            // Restart subscriptions if we're the leader
            options.channels.forEach(channel => {
                setupSubscription(channel.name, 0);
            });
        }

        // Use broadcast context to send message
        broadcastChannel.postMessage({
            type: 'subscription-toggle',
            enabled: newEnabled,
            timestamp: Date.now(),
            tabId: tabId.current
        });
    }, [options.channels, broadcastChannel]);

    // Manual reconnect function
    const reconnect = useCallback((channelName?: string) => {
        if (!isLeader.current) {
            debug.log('Cannot reconnect - not leader');
            return;
        }
        if (!isEnabled.current()) {
            debug.log('Cannot reconnect - subscriptions disabled');
            return;
        }
        
        if (channelName) {
            debug.log(`Manually reconnecting channel: ${channelName}`);
            setupSubscription(channelName, 0);
        } else {
            debug.log('Manually reconnecting all channels');
            options.channels.forEach(channel => {
                setupSubscription(channel.name, 0);
            });
        }
    }, []);

    // Update window debug info
    useEffect(() => {
        const debugInfo: DebugWindowInfo = {
            isEnabled: isEnabled.current(),
            isLeader: isLeader.current,
            hasActiveSubscription: Object.keys(subscriptionsRef.current).length > 0,
            connectionStatus: status,
            forceReconnect: reconnect
        };
        
        debug.updateWindowDebug(debugInfo);
    }, [isLeader.current, isEnabled.current, status]);

    // Modified leader election to respect enabled state
    useEffect(() => {
        debug.log('Setting up leader election');
        const cleanup = setupLeaderElection(
            (newIsLeader: boolean) => {
                debug.log(`Leader status changed: ${newIsLeader}`);
                isLeader.current = newIsLeader;
                
                if (newIsLeader && isEnabled.current()) {
                    options.channels.forEach(channel => {
                        setupSubscription(channel.name, 0);
                    });
                } else {
                    Object.values(subscriptionsRef.current).forEach(sub => sub?.unsubscribe());
                    subscriptionsRef.current = {};
                }
            },
            debug.log.bind(debug),
            tabId.current
        );

        return () => {
            debug.log('Cleaning up leader election from subscription hook');
            cleanup();
        };
    }, []); // Empty dependency array to prevent re-runs

    // Memoize the setup function to prevent recreating it
    const memoizedSetupSubscription = useCallback(setupSubscription, []);

    // Memoize options to prevent unnecessary re-renders
    const memoizedOptions = useMemo(() => ({
        ...options,
        channels: options.channels
    }), [options.token, JSON.stringify(options.channels)]);

    // Setup heartbeat monitoring only when leader status changes
    useEffect(() => {
        if (!isLeader.current) return;
        
        debug.log('Setting up heartbeat check');
        const cleanup = setupHeartbeat(status, {
            token: options.token,
            client: client as Client,
            debug,
            isLeaderRef: isLeader,
            subscriptionsRef,
            setupSubscription,
            reconnectingRef: reconnectingRef
        });

        return () => {
            debug.log('Cleaning up heartbeat check');
            cleanup();
        };
    }, [isLeader.current]); // Only depend on leader status

    // Add broadcast channel listener
    useEffect(() => {
        const broadcast = new BroadcastChannel('subscriptions');
        
        const handleBroadcastMessage = (event: MessageEvent) => {
            const message = event.data;
            if (message.type === 'subscription-message' && message.tabId !== tabId.current) {
                debug.log('Received broadcast message:', message);
                
                // Find channel specific handler and call it
                const channelConfig = options.channels.find((c: any) => c.name === message.channel);       
            }
            
            // Handle new transaction broadcasts
            if (message.type === 'new-transaction' && message.tabId !== tabId.current) {
                debug.log('Received broadcast transaction:', message);
                
                // Find the transaction channel
                const channelConfig = options.channels.find(c => 
                    c.name.includes('streamed-transactions')
                );

                if (channelConfig?.onMessage) {
                    channelConfig.onMessage({ 
                        channel: message.channel, 
                        data: message.data,
                        messageId: message.messageId,
                        timestamp: message.timestamp
                    });
                }

                // Call global handler if provided
                if (options.onMessage) {
                    options.onMessage({ 
                        channel: message.channel, 
                        data: message.data,
                        messageId: message.messageId,
                        timestamp: message.timestamp
                    });
                }
            }

            // Handle address watch broadcasts
            if (message.type === 'address-watch' && message.tabId !== tabId.current) {
                debug.log('Received broadcast address watch:', message);
                
                // Find the transaction channel
                const channelConfig = options.channels.find(c => 
                    c.name.includes('address-watch')
                );

                if (channelConfig?.onMessage) {
                    channelConfig.onMessage({ 
                        channel: message.channel, 
                        data: message.data,
                        messageId: message.messageId,
                        timestamp: message.timestamp
                    });
                }

                // Call global handler if provided
                if (options.onMessage) {
                    options.onMessage({ 
                        channel: message.channel, 
                        data: message.data,
                        messageId: message.messageId,
                        timestamp: message.timestamp
                    });
                }
            }            
        };

        broadcast.addEventListener('message', handleBroadcastMessage);
        return () => {
            broadcast.removeEventListener('message', handleBroadcastMessage);
            broadcast.close();
        };
    }, [options.channels, options.onMessage, broadcastChannel]);

    return {
        status,
        isLeader: isLeader.current,
        isEnabled: isEnabled.current(),
        controls: {
            reconnect,
            toggleSubscription
        }
    };
}; 
import React from 'react';
import { Modal, Button } from 'antd';

interface JsonModalProps {
    data: any;
    onClose: () => void;
}

export const JsonModal: React.FC<JsonModalProps> = ({ data, onClose }) => (
    <Modal
        title="Full Transaction Details"
        open={!!data}
        onCancel={onClose}
        width={800}
        footer={[
            <Button key="close" onClick={onClose}>
                Close
            </Button>
        ]}
    >
        <pre style={{ 
            maxHeight: '70vh', 
            overflow: 'auto', 
            padding: '12px',
            backgroundColor: '#f5f5f5',
            borderRadius: '4px'
        }}>
            {JSON.stringify(data, null, 2)}
        </pre>
    </Modal>
); 
import React, { useState } from 'react';
import { Button, Dropdown, Checkbox, Modal, Space, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useColumnsModal } from '../hooks/useColumnsModal';
import { ColumnVisibilityMenu } from './ColumnVisibilityMenu';
import { ALL_COLUMNS } from '../constants';

interface ColumnSettingsProps {
    visibleColumns: string[];
    setVisibleColumns: (columns: string[]) => void;
    modalProps: any;
    formProps: any;
    show: () => void;
    formLoading: boolean;
    onFinish: (values: any) => void;
    close: () => void;
}

export const ColumnSettings: React.FC<ColumnSettingsProps> = ({
    visibleColumns,
    setVisibleColumns,
    modalProps,
    formProps,
    show,
    formLoading,
    onFinish,
    close,
}) => {
    const handleColumnToggle = (columnKey: string, checked: boolean) => {
        if (checked) {
            setVisibleColumns([...visibleColumns, columnKey]);
        } else {
            setVisibleColumns(visibleColumns.filter(key => key !== columnKey));
        }
    };

    const handleSelectAll = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setVisibleColumns(ALL_COLUMNS.map(col => col.key));
        } else {
            setVisibleColumns([]);
        }
    };

    const menuItems = (
        <Menu>
            <Menu.Item key="select-all">
                <Checkbox
                    checked={visibleColumns.length === ALL_COLUMNS.length}
                    indeterminate={visibleColumns.length > 0 && visibleColumns.length < ALL_COLUMNS.length}
                    onChange={handleSelectAll}
                >
                    Select All
                </Checkbox>
            </Menu.Item>
            <Menu.Divider />
            {ALL_COLUMNS.map(column => (
                <Menu.Item key={column.key}>
                    <Checkbox
                        checked={visibleColumns.includes(column.key)}
                        onChange={(e) => handleColumnToggle(column.key, e.target.checked)}
                    >
                        {column.label}
                    </Checkbox>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <>
            <Dropdown 
                overlay={menuItems}
                trigger={['click']}
            >
                <Button>
                    <Space>
                        Columns
                        <SettingOutlined />
                    </Space>
                </Button>
            </Dropdown>

            <Modal {...modalProps} title="Column Settings" okButtonProps={{ loading: formLoading }}>
                {/* Add your modal content here */}
            </Modal>
        </>
    );
}; 
import { Space, Typography, Tag, Tooltip } from 'antd';
import type { TableColumnType } from 'antd';
import type { Transaction } from '../types';
import { 
    CheckCircleOutlined, 
    CloseCircleOutlined, 
    LoadingOutlined,
    QuestionCircleOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import { Key } from 'react';

const { Text } = Typography;

type ReceiptStatus = {
    status: string;
    label: string;
    color: string;
    icon: React.ReactNode;
    description: string;
};

const getTransactionStatus = (transaction: Transaction): ReceiptStatus => {
    // Handle mempool transactions
    if (transaction.receipt_status === 'mempool') {
        return {
            status: 'mempool',
            label: 'In Mempool',
            color: 'processing',
            icon: <LoadingOutlined />,
            description: 'Transaction is waiting in the mempool'
        };
    }

    // Handle predicted transactions
    if (transaction.receipt_status === 'predicted') {
        return {
            status: 'predicted',
            label: 'Predicted',
            color: 'cyan',
            icon: <QuestionCircleOutlined />,
            description: 'Transaction outcome has been predicted but not confirmed'
        };
    }

    // Handle pending transactions with different states
    if (transaction.receipt_status === 'pending_confirmation') {
        return {
            status: 'pending_confirmation',
            label: 'Pending Confirmation',
            color: 'processing',
            icon: <LoadingOutlined />,
            description: 'Transaction is pending blockchain confirmation'
        };
    }

    if (transaction.receipt_status === 'pending_signature') {
        return {
            status: 'pending_signature',
            label: 'Awaiting Signature',
            color: 'warning',
            icon: <ClockCircleOutlined />,
            description: 'Transaction is waiting for signature'
        };
    }

    if (transaction.receipt_status === 'pending') {
        // Default pending state
        return {
            status: 'pending',
            label: 'Pending',
            color: 'processing',
            icon: <LoadingOutlined />,
            description: 'Transaction is being processed'
        };
    }

    // Handle cases where receipt_status is not available
    if (!transaction.receipt_status) {
        return {
            status: 'unknown',
            label: 'Unknown Status',
            color: 'default',
            icon: <QuestionCircleOutlined/>,
            description: 'Transaction status could not be determined'
        };
    }

    // Check if transaction is too recent (within last 20 blocks)
    const isRecentTransaction = transaction.block_timestamp && 
        new Date(transaction.block_timestamp).getTime() > Date.now() - 5 * 60 * 1000; // 5 minutes

    if (isRecentTransaction) {
        return {
            status: 'processing',
            label: 'Processing',
            color: 'warning',
            icon: <ClockCircleOutlined/>,
            description: 'Transaction is recent and may be subject to chain reorganization'
        };
    }

    // Handle success/failure based on receipt_status
    switch (transaction.receipt_status) {
        case "1":
            return {
                status: 'success',
                label: 'Success',
                color: 'success',
                icon: <CheckCircleOutlined/>,
                description: 'Transaction was successful'
            };
        case "0":
            return {
                status: 'failed',
                label: 'Failed',
                color: 'error',
                icon: <CloseCircleOutlined/>,
                description: 'Transaction failed during execution'
            };
        default:
            return {
                status: 'unknown',
                label: 'Unknown',
                color: 'default',
                icon: <QuestionCircleOutlined/>,
                description: 'Transaction status is unknown'
            };
    }
};

export const getReceiptStatusColumn = (): TableColumnType<Transaction> => ({
    title: 'Status',
    dataIndex: 'receipt_status',
    key: 'receipt_status',
    width: 150,
    filters: [
        { text: 'Success', value: 'success' },
        { text: 'Failed', value: 'failed' },
        { text: 'In Mempool', value: 'mempool' },
        { text: 'Predicted', value: 'predicted' },
        { text: 'Pending Confirmation', value: 'pending_confirmation' },
        { text: 'Awaiting Signature', value: 'pending_signature' },
        { text: 'Pending', value: 'pending' },
        { text: 'Processing', value: 'processing' },
        { text: 'Unknown', value: 'unknown' },
    ],
    onFilter: (value: boolean | Key, record: Transaction) => {
        const status = getTransactionStatus(record);
        return status.status === value.toString();
    },
    sorter: (a: Transaction, b: Transaction) => {
        const statusA = getTransactionStatus(a);
        const statusB = getTransactionStatus(b);
        return statusA.status.localeCompare(statusB.status);
    },
    render: (_: any, record: Transaction) => {
        const status = getTransactionStatus(record);

        return (
            <Tooltip title={status.description}>
                <Tag
                    icon={status.icon}
                    color={status.color}
                    style={{ 
                        minWidth: '100px',
                        textAlign: 'center',
                        padding: '0 8px'
                    }}
                >
                    {status.label}
                </Tag>
            </Tooltip>
        );
    }
}); 
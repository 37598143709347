import React, { useState } from 'react';
import { Modal, Space, Button, Avatar, Typography, Divider, Row, Col, Statistic, Tooltip } from 'antd';
import { CodeOutlined, CloseOutlined } from '@ant-design/icons';

interface ChainStatsProps {
    chainId: string;
    chainName: string;
    chainData: {
        address_count?: number;
        transaction_count?: number;
        total_value?: number;
        first_seen?: string;
    };
    onClose: () => void;
}

export const ChainStats: React.FC<ChainStatsProps> = ({ 
    chainId,
    chainName,
    chainData,
    onClose 
}) => {
    const [isRawDataVisible, setIsRawDataVisible] = useState(false);

    return (
        <>
            <Modal
                title="Raw Chain Data"
                open={isRawDataVisible}
                onCancel={() => setIsRawDataVisible(false)}
                footer={[
                    <Button key="close" onClick={() => setIsRawDataVisible(false)}>
                        Close
                    </Button>
                ]}
                width={800}
            >
                <pre style={{ 
                    backgroundColor: '#f5f5f5', 
                    padding: '16px', 
                    borderRadius: '4px',
                    overflow: 'auto',
                    maxHeight: '600px'
                }}>
                    {JSON.stringify({ chainId, chainName, ...chainData }, null, 2)}
                </pre>
            </Modal>

            <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                background: 'white',
                padding: '16px 24px',
                boxShadow: '0 -2px 8px rgba(0,0,0,0.15)',
                zIndex: 1000,
                transition: 'transform 0.3s ease',
                transform: chainId ? 'translateY(0)' : 'translateY(100%)',
            }}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Space>
                            <Space direction="vertical" size={0}>
                                <Typography.Title level={5} style={{ margin: 0 }}>
                                    {chainName || 'Unknown Chain'}
                                </Typography.Title>
                                <Typography.Text type="secondary">
                                    Chain ID: {chainId}
                                </Typography.Text>
                            </Space>
                        </Space>
                        <Space>
                            <Button 
                                icon={<CodeOutlined />}
                                onClick={() => setIsRawDataVisible(true)}
                                type="text"
                            >
                                View Raw Data
                            </Button>
                            <Button 
                                icon={<CloseOutlined />} 
                                type="text"
                                onClick={onClose}
                            />
                        </Space>
                    </Space>

                    <Divider style={{ margin: '12px 0' }} />

                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Statistic 
                                title="Total Addresses" 
                                value={chainData.address_count || 0}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="Total Transactions" 
                                value={chainData.transaction_count || 0}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="Total Value Processed" 
                                value={chainData.total_value || 0}
                                prefix="Ξ"
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="First Seen" 
                                value={chainData.first_seen ? new Date(chainData.first_seen).toLocaleDateString() : 'N/A'}
                            />
                        </Col>
                    </Row>
                </Space>
            </div>
        </>
    );
}; 
import { Space, Typography, Tooltip, Tag } from 'antd';
import type { TableColumnType } from 'antd';
import type { Transaction } from '../types';
import { AddressFilterDropdown } from '../components/AddressFilterDropdown';
import { Key } from 'react';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { IAddress } from "interfaces/address";
import { renderAddressAvatar } from '../../../address-avatar';

export const getToColumn = (cdn_domain_name: string, transactions: Transaction[], address_items_map: Record<string, IAddress>): TableColumnType<Transaction> => ({
    title: 'To',
    dataIndex: 'to_address',
    key: 'to_address',
    width: 300,
    filters: transactions
        .map(tx => {
            // Use receipt_contract_address if it exists (contract creation)
            const address = tx.receipt_contract_address || tx.to_address;
            return {
                text: tx.to_address_label || tx.to_address_entity || 
                     (address_items_map[address]?.labels?.[0]?.name) || 
                     `${address?.slice(0, 6)}...${address?.slice(-6)}`,
                value: address
            };
        })
        .filter((filter, index, self) => 
            index === self.findIndex(f => f.value === filter.value)
        ),
    filterDropdown: (props: FilterDropdownProps) => (
        <AddressFilterDropdown
            {...props}
            transactions={transactions}
            addressField="to_address"
            labelField="to_address_label"
            entityField="to_address_entity"
            logoField="to_address_entity_logo"
        />
    ),
    onFilter: (value: boolean | Key, record: Transaction) => {
        // Check both to_address and receipt_contract_address
        return record.to_address === value || record.receipt_contract_address === value;
    },
    sorter: (a: Transaction, b: Transaction) => {
        const labelA = a.to_address_label || a.to_address_entity || a.to_address || a.receipt_contract_address || '';
        const labelB = b.to_address_label || b.to_address_entity || b.to_address || b.receipt_contract_address || '';
        return labelA.localeCompare(labelB);
    },
    render: (address: string, record: Transaction) => {
        // If this is a contract creation transaction
        if (record.receipt_contract_address) {
            const contractAddress = record.receipt_contract_address;
            const truncatedAddress = contractAddress ? `${contractAddress.slice(0, 6)}...${contractAddress.slice(-6)}` : '';
            
            return (
                <Space direction="vertical" size={2}>
                    <Space>
                        <Tooltip title={contractAddress}>
                            <Typography.Text 
                                copyable={{ text: contractAddress }} 
                                style={{ whiteSpace: 'nowrap' }}
                                data-full-address={contractAddress}
                            >
                                {truncatedAddress}
                            </Typography.Text>
                        </Tooltip>
                        <Tag color="green">New contract created </Tag>
                    </Space>
                    {renderAddressAvatar(
                        {
                            chain_id: record.chain_id,
                            address_items_map: { [contractAddress]: address_items_map[contractAddress] }
                        },
                        'to',
                        contractAddress,
                        {
                            maxGlobalLabels: 3,
                            maxUserLabels: 3,
                            maxTotalLabels: 5,
                            maxGlobalEntities: 3,
                            maxUserEntities: 3,
                            maxTotalEntities: 5,
                            displayStyle: 'avatars',
                            hideAddress: true
                        },
                        address_items_map
                    )}
                </Space>
            );
        }
        
        // Regular transaction
        const truncatedAddress = address ? `${address.slice(0, 6)}...${address.slice(-6)}` : '';
        
        // Handle case where there's no to_address (contract creation without receipt_contract_address)
        if (!address && !record.receipt_contract_address) {
            return (
                <Space>
                    <Typography.Text type="secondary">Contract Creation</Typography.Text>
                </Space>
            );
        }
        
        return (
            <Space direction="vertical" size={2}>
                <Tooltip title={address}>
                    <Typography.Text 
                        copyable={{ text: address }} 
                        style={{ whiteSpace: 'nowrap' }}
                        data-full-address={address}
                    >
                        {truncatedAddress}
                    </Typography.Text>
                </Tooltip>
                {renderAddressAvatar(
                    record,
                    'to',
                    address,
                    {
                        maxGlobalLabels: 3,
                        maxUserLabels: 3,
                        maxTotalLabels: 5,
                        maxGlobalEntities: 3,
                        maxUserEntities: 3,
                        maxTotalEntities: 5,
                        displayStyle: 'avatars',
                        hideAddress: true
                    },
                    address_items_map
                )}
            </Space>
        );
    },
}); 
import { useState } from 'react';
import { Row, Col, Card, Typography, Space, Select, Button, Tooltip, DatePicker, Radio } from 'antd';
import { ReloadOutlined, UnorderedListOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { EventTimeline } from './EventTimeline';
import dayjs from 'dayjs';
import { CHAIN_IDS, CHAIN_NAMES } from "../../constants/chains";

const { Title, Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface ActivityFeedProps {
    orgId?: string;
    tenantId?: string;
    address?: string;
}

const RESOURCE_OPTIONS = [
    { value: 'contracts', label: '📝 Contracts' },
    { value: 'transactions', label: '💸 Transactions' },
    { value: 'wallets', label: '👛 Wallets' },
    { value: 'users', label: '👤 Users' },
    { value: 'security', label: '🔒 Security' },
];

export const ActivityFeed: React.FC<ActivityFeedProps> = ({ 
    orgId, 
    tenantId, 
    address 
}) => {
    const [filters, setFilters] = useState({
        eventTypes: [] as string[],
        criticalityLevels: [] as string[],
        chains: [] as string[],
        dateRange: null as [dayjs.Dayjs, dayjs.Dayjs] | null,
        searchQuery: '',
        resources: [] as string[],
    });
    const [view, setView] = useState<'timeline' | 'list'>('timeline');
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(0);

    const handleRefresh = async () => {
        setIsRefreshing(true);
        setRefreshTrigger(prev => prev + 1);
        // The loading state will be turned off by the EventTimeline
        // component once it finishes loading
    };

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Space direction="vertical" style={{ width: '100%' }} size="large">
                    <Card>
                        <Space style={{ width: '100%' }} wrap>
                            <Space align="center">
                                <Title level={5} style={{ margin: 0 }}>Activity Feed</Title>
                                <Text type="secondary">Track and monitor activities</Text>
                            </Space>
                            <Space>
                                <Radio.Group 
                                    value={view} 
                                    onChange={(e) => setView(e.target.value)}
                                    buttonStyle="solid"
                                >
                                    <Radio.Button value="timeline">
                                        <Space>
                                            <FieldTimeOutlined/>
                                            Timeline
                                        </Space>
                                    </Radio.Button>
                                    <Radio.Button value="list">
                                        <Space>
                                            <UnorderedListOutlined/>
                                            List
                                        </Space>
                                    </Radio.Button>
                                </Radio.Group>
                                <Tooltip title="Refresh">
                                    <Button
                                        icon={<ReloadOutlined/>}
                                        loading={isRefreshing}
                                        onClick={handleRefresh}
                                    />
                                </Tooltip>
                            </Space>
                        </Space>
                    </Card>
                    <Card>
                        <Space direction="vertical" style={{ width: '100%' }} size="middle">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={12} lg={6}>
                                    <Select
                                        mode="multiple"
                                        placeholder="🏷️ Filter by event type"
                                        style={{ width: '100%' }}
                                        value={filters.eventTypes}
                                        onChange={(values) => setFilters(prev => ({ 
                                            ...prev, 
                                            eventTypes: values 
                                        }))}
                                        allowClear
                                    >
                                        <Option value="contract">📄 Contract Events</Option>
                                        <Option value="security">🔒 Security Events</Option>
                                        <Option value="system">⚙️ System Events</Option>
                                    </Select>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6}>
                                    <Select
                                        mode="multiple"
                                        placeholder="🎯 Filter by criticality"
                                        style={{ width: '100%' }}
                                        value={filters.criticalityLevels}
                                        onChange={(values) => setFilters(prev => ({ 
                                            ...prev, 
                                            criticalityLevels: values 
                                        }))}
                                        allowClear
                                    >
                                        <Option value="1">🔴 Critical</Option>
                                        <Option value="2">🟠 Error</Option>
                                        <Option value="3">🟡 Warning</Option>
                                        <Option value="4">🔵 Info</Option>
                                        <Option value="5">🟢 Success</Option>
                                    </Select>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6}>
                                    <Select
                                        mode="multiple"
                                        placeholder="⛓️ Filter by chain"
                                        style={{ width: '100%' }}
                                        value={filters.chains}
                                        onChange={(values) => setFilters(prev => ({ 
                                            ...prev, 
                                            chains: values 
                                        }))}
                                        allowClear
                                    >
                                        {Object.entries(CHAIN_IDS).map(([chainId, chainName]) => (
                                            <Option key={chainId} value={chainId}>
                                                {chainName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6}>
                                    <Select
                                        mode="multiple"
                                        placeholder="📁 Filter by resource"
                                        style={{ width: '100%' }}
                                        value={filters.resources}
                                        onChange={(values) => setFilters(prev => ({ 
                                            ...prev, 
                                            resources: values 
                                        }))}
                                        allowClear
                                        optionLabelProp="label"
                                    >
                                        {RESOURCE_OPTIONS.map(option => (
                                            <Option 
                                                key={option.value} 
                                                value={option.value}
                                                label={option.label}
                                            >
                                                <Space>
                                                    {option.label}
                                                </Space>
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <RangePicker
                                        style={{ width: '100%' }}
                                        value={filters.dateRange}
                                        onChange={(dates) => setFilters(prev => ({
                                            ...prev,
                                            dateRange: dates as [dayjs.Dayjs, dayjs.Dayjs] | null
                                        }))}
                                        allowClear
                                        showTime
                                        placeholder={['Start Time', 'End Time']}
                                    />
                                </Col>
                            </Row>
                        </Space>
                    </Card>
                    <Card>
                        <EventTimeline 
                            orgId={orgId}
                            tenantId={tenantId}
                            address={address}
                            filters={filters}
                            view={view}
                            refreshTrigger={refreshTrigger}
                            setIsRefreshing={setIsRefreshing}
                        />
                    </Card>
                </Space>
            </Col>
        </Row>
    );
}; 
import React, { useState } from 'react';
import { Typography, Space, Card, Divider, Tooltip, Tag, Button, Modal, List, Badge } from 'antd';
import { AddressData, getAddressInfo } from '../../../utils/addressUtils';
import { shortenAddress, getTagColorByParamType, getTooltipTitleForParamType } from "../../../utils/formatters";
import { CopyOutlined, CodeOutlined, LinkOutlined, InfoCircleOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { renderAddressAvatar } from 'components/address-avatar';
import renderSpecializedDataType from './SpecializedDataRenderers';

const { Text, Title } = Typography;

interface DecodedCallViewProps {
    decodedCall: {
        summary?: string;
        label?: string;
        type?: string;
        params?: any[];
        signature?: string;
        input?: string;
        output?: string;
    };
    addressData: AddressData;
    chainId?: string;
}

export const DecodedCallView: React.FC<DecodedCallViewProps> = ({
    decodedCall,
    addressData,
    chainId
}) => {
    const [showRawData, setShowRawData] = useState(false);
    const [showAllParams, setShowAllParams] = useState(false);
    const [showProxyDetails, setShowProxyDetails] = useState(true);
    const maxVisible = 10;

    if (!decodedCall) {
        return (
            <Card>
                <Text type="secondary">
                    Could not decode the transaction. Please try to add an ABI so next time we can decode it.
                </Text>
            </Card>
        );
    }

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const openDataDecoder = (data: string) => {
        window.open(`https://cleanunicorn.github.io/santoku/#/decode/${data}`, '_blank');
    };

    const renderDataField = (label: string, value: string) => {
        if (!value || value === '0x') return null;

        const isLongData = value.length > 66;
        const displayData = isLongData ? `${value.slice(0, 66)}...` : value;

        return (
            <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
                <Space>
                    <Text type="secondary">{label}: </Text>
                    <Tooltip title="Click to copy">
                        <Text 
                            style={{ 
                                fontSize: '12px',
                                cursor: 'pointer',
                                fontFamily: 'monospace'
                            }}
                            onClick={() => copyToClipboard(value)}
                        >
                            {displayData} <CopyOutlined style={{ fontSize: '12px' }} />
                        </Text>
                    </Tooltip>
                </Space>
                {isLongData && (
                    <Space size={4}>
                        <Button 
                            type="link" 
                            size="small"
                            icon={<LinkOutlined />}
                            onClick={() => openDataDecoder(value)}
                        >
                            Decode
                        </Button>
                    </Space>
                )}
            </Space>
        );
    };

    const renderAddressArray = (addresses: string[]) => {
        const knownAddressCount = addresses.filter(addr => 
            addressData?.address_items_map?.[addr.toLowerCase()]
        ).length;
    
        return (
            <div style={{ marginTop: 8 }}>
                <Badge 
                    count={addresses.length} 
                    overflowCount={999}
                    style={{ backgroundColor: '#52c41a', marginTop: 10 }}
                >
                    <Typography.Text type="secondary" style={{ marginRight: 24 }}>
                        Addresses
                    </Typography.Text>
                </Badge>
                {knownAddressCount > 0 && (
                    <Tag color="blue">
                        {knownAddressCount} known
                    </Tag>
                )}
                <List
                    size="small"
                    dataSource={addresses}
                    renderItem={(address, index) => (
                        <List.Item>
                            <Space>
                                <Typography.Text type="secondary" style={{ minWidth: 24 }}>
                                    {index + 1}.
                                </Typography.Text>
                                <Typography.Text 
                                    copyable={{ text: address }}
                                    data-full-address={address}
                                >
                                    {shortenAddress(address)}
                                </Typography.Text>
                                {renderAddressAvatar(addressData, 'from', address)}
                            </Space>
                        </List.Item>
                    )}
                    style={{ 
                        maxHeight: '200px', 
                        overflow: 'auto',
                        border: '1px solid #f0f0f0',
                        borderRadius: '4px',
                        padding: '8px'
                    }}
                />
            </div>
        );
    };
    
    const renderParamValue = (param: any, depth = 0): React.ReactNode => {
        const indent = depth * 16;

        // Handle null or undefined values
        if (param === null || param === undefined || param.value === null || param.value === undefined) {
            return <Text type="secondary">null</Text>;
        }

        // Use the specialized renderer for tuple arrays and custom data types
        if (param.type === 'tuple[]' || 
            (typeof param.value === 'object' && param.value !== null && param.value.type)) {
            return renderSpecializedDataType(param, addressData);
        }

        // Handle bytes32[n] arrays with structured data (like Chainlink reportContext)
        if (param.type && param.type.match(/^bytes32\[\d+\]$/) && 
            typeof param.value === 'object' && param.value !== null && !Array.isArray(param.value)) {
            // Check if it has named fields, which indicates a structured object
            const hasNamedFields = Object.values(param.value).some(
                (v: any) => v && typeof v === 'object' && v !== null && 'name' in v && 'type' in v
            );
            
            if (hasNamedFields) {
                return renderSpecializedDataType(param, addressData);
            }
        }

        // Handle fixed-size array types (like bytes32[3])
        if (param?.type?.match(/\[\d+\]$/)) {
            if (typeof param.value === 'string') {
                // Split by comma, but handle the case where the values themselves contain commas
                // This regex splits by commas that are followed by "0x" (indicating the start of a new hex value)
                const values = param.value.split(/,(?=0x)/);
                
                return (
                    <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                        {values.map((value: string, index: number) => (
                            <Space key={index} style={{ width: '100%' }}>
                                <Text type="secondary">[{index}]:</Text>
                                <Text code copyable style={{ fontSize: '14px' }}>
                                    {value.trim()}
                                </Text>
                            </Space>
                        ))}
                    </Space>
                );
            } else if (Array.isArray(param.value)) {
                // Handle case where value is already an array
                return (
                    <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                        {param.value.map((value: any, index: number) => (
                            <Space key={index} style={{ width: '100%' }}>
                                <Text type="secondary">[{index}]:</Text>
                                <Text code copyable style={{ fontSize: '14px' }}>
                                    {typeof value === 'object' ? JSON.stringify(value) : String(value)}
                                </Text>
                            </Space>
                        ))}
                    </Space>
                );
            } else if (typeof param.value === 'object') {
                // Handle case where value is a structured object (like reportContext)
                return renderSpecializedDataType(param, addressData);
            }
        }

        // Handle dynamic array types
        if (param?.type?.endsWith('[]')) {
            // Handle simple array types (like bytes32[])
            if (typeof param.value === 'string') {
                const values = param.value.split(',').map((v: string) => v.trim());
                return (
                    <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                        {values.map((value: string, index: number) => (
                            <Space key={index} style={{ width: '100%' }}>
                                <Text type="secondary">[{index}]:</Text>
                                <Text code copyable style={{ fontSize: '14px' }}>
                                    {value}
                                </Text>
                                {renderAddressAvatar(addressData, 'from', value, {
                                    displayStyle: 'avatars',
                                    maxGlobalLabels: 1,
                                    maxUserLabels: 1,
                                    maxTotalLabels: 1,
                                    maxGlobalEntities: 1,
                                    maxUserEntities: 1,
                                    maxTotalEntities: 1,
                                    hideAddress: true
                                })}
                            </Space>
                        ))}
                    </Space>
                );
            }

            // Handle complex array types
            const valueArray = Array.isArray(param.value) ? param.value : [param.value];
            
            return (
                <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                    {valueArray.map((item: any, index: number) => (
                        <Space direction="vertical" key={index}>
                            <Text type="secondary">[{index}]:</Text>
                            <Space direction="vertical" style={{ marginLeft: 16 }}>
                                {Array.isArray(item) ? (
                                    // Handle array of values
                                    item.map((subItem: any, subIndex: number) => (
                                        <Space key={subIndex}>
                                            <Text type="secondary">{subIndex}:</Text>
                                            <Text code copyable style={{ fontSize: '14px' }}>
                                                {subItem?.toString()}
                                            </Text>
                                        </Space>
                                    ))
                                ) : (
                                    // Handle object value
                                    renderParamValue({ value: item, type: param.type.slice(0, -2) }, depth + 1)
                                )}
                            </Space>
                        </Space>
                    ))}
                </Space>
            );
        }

        // Handle tuple types that start with '('
        if (param?.type?.startsWith('(')) {
            const isFieldFormat = Object.keys(param.value || {}).some(k => k.startsWith('field_'));
            
            if (isFieldFormat) {
                // Handle field_X format
                return (
                    <Space direction="vertical">
                        <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                            {Object.entries(param.value)
                                .sort(([keyA], [keyB]) => {
                                    const numA = parseInt(keyA.split('field_')[1] || '0');
                                    const numB = parseInt(keyB.split('field_')[1] || '0');
                                    return numA - numB;
                                })
                                .map(([key, value]: [string, any]) => {
                                    // Handle nested objects and arrays
                                    const isNestedObject = typeof value === 'object' && !Array.isArray(value);
                                    const isArray = Array.isArray(value);

                                    return (
                                        <Space direction="vertical" key={key} size={2}>
                                            <Text type="secondary">{key}:</Text>
                                            {isNestedObject ? (
                                                <Space direction="vertical" style={{ marginLeft: 16 }}>
                                                    {Object.entries(value).map(([nestedKey, nestedValue]: [string, any]) => (
                                                        <Space direction="vertical" key={nestedKey}>
                                                            <Text type="secondary">{nestedKey}:</Text>
                                                            <Text code copyable style={{ fontSize: '14px' }}>
                                                                {typeof nestedValue === 'object' ? 
                                                                    JSON.stringify(nestedValue, null, 2) : 
                                                                    nestedValue?.toString()}
                                                            </Text>
                                                        </Space>
                                                    ))}
                                                </Space>
                                            ) : isArray ? (
                                                <Space direction="vertical" style={{ marginLeft: 16 }}>
                                                    {value.map((item: any, index: number) => (
                                                        <Space direction="vertical" key={index}>
                                                            <Text type="secondary">[{index}]:</Text>
                                                            {Object.entries(item).map(([itemKey, itemValue]: [string, any]) => (
                                                                <Space direction="vertical" key={itemKey} style={{ marginLeft: 16 }}>
                                                                    <Text type="secondary">{itemKey}:</Text>
                                                                    <Text code copyable style={{ fontSize: '14px' }}>
                                                                        {itemValue?.toString()}
                                                                    </Text>
                                                                </Space>
                                                            ))}
                                                        </Space>
                                                    ))}
                                                </Space>
                                            ) : (
                                                <Text code copyable style={{ fontSize: '14px' }}>
                                                    {value?.toString()}
                                                </Text>
                                            )}
                                        </Space>
                                    );
                                })}
                        </Space>
                    </Space>
                );
            }

            // Handle tuple types with components
            if (param.components && Array.isArray(param.components)) {
                const tupleValues = param.components.map((component: any, index: number) => ({
                    name: component.name || `field_${index}`,
                    type: component.type,
                    value: param.value[index] || param.value[component.name]
                }));

                return (
                    <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                        {tupleValues.map((item: any, index: number) => (
                            <Space direction="vertical" key={index} size={2}>
                                <Space>
                                    <Text type="secondary">{item.name}:</Text>
                                    <Tag color={getTagColorByParamType(item.type)}>{item.type}</Tag>
                                </Space>
                                {renderParamValue(item, depth + 1)}
                            </Space>
                        ))}
                    </Space>
                );
            }

            // Handle tuple types with value as object
            if (typeof param.value === 'object' && !Array.isArray(param.value)) {
                const tupleValues = Object.entries(param.value).map(([key, value]: [string, any]) => ({
                    name: key,
                    type: typeof value === 'object' && value !== null && 'type' in value ? value.type : typeof value,
                    value: value
                }));

                return (
                    <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                        {tupleValues.map((item: any, index: number) => (
                            <Space direction="vertical" key={index} size={2}>
                                <Space>
                                    <Text type="secondary">{item.name}:</Text>
                                    <Tag color={getTagColorByParamType(item.type)}>{item.type}</Tag>
                                </Space>
                                {renderParamValue(item, depth + 1)}
                            </Space>
                        ))}
                    </Space>
                );
            }
        }

        if (param.type === 'address[]') {
            return renderAddressArray(param.value.split(','));
        }
        
        if (param.type === 'tuple') {
            // Handle both array and object formats
            const tupleValues = Array.isArray(param?.value) 
                ? param.value 
                : Object.entries(param?.value || {}).map(([key, value]: [string, any]) => ({
                    name: key,
                    type: value.type,
                    value: value.value
                }));

            return (
                <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                    {tupleValues.map((item: any, index: number) => (
                        <Space direction="vertical" key={index} size={2}>
                            <Space>
                                <Text type="secondary">{item.name}:</Text>
                                <Tag color={getTagColorByParamType(item.type)}>{item.type}</Tag>
                            </Space>
                            {renderParamValue(item, depth + 1)}
                        </Space>
                    ))}
                </Space>
            );
        }

        if (param.type === 'tuple[]') {
            return (
                <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                    {Array.isArray(param?.value) && param?.value?.map((tupleItem: any, index: number) => (
                        <Space direction="vertical" key={index} size={2}>
                            <Text type="secondary">[{index}]:</Text>
                            <Space direction="vertical" style={{ marginLeft: 16 }}>
                                {Array.isArray(tupleItem) ? (
                                    // Handle array case
                                    tupleItem.map((item: any, subIndex: number) => (
                                        <Space direction="vertical" key={subIndex}>
                                            <Space>
                                                <Text type="secondary">{item.name || `[${subIndex}]`}:</Text>
                                                <Tag color={getTagColorByParamType(item.type)}>
                                                    {item.type}
                                                </Tag>
                                            </Space>
                                            {renderParamValue(item, depth + 2)}
                                        </Space>
                                    ))
                                ) : (
                                    // Handle object case with item_X keys
                                    Object.entries(tupleItem)
                                        .sort(([keyA], [keyB]) => {
                                            const numA = parseInt(keyA.split('_')[1]);
                                            const numB = parseInt(keyB.split('_')[1]);
                                            return numA - numB;
                                        })
                                        .map(([key, field]: [string, any]) => (
                                            <Space direction="vertical" key={key}>
                                                <Space>
                                                    <Text type="secondary">{key}:</Text>
                                                    <Tag color={getTagColorByParamType(field.type)}>
                                                        {field.type}
                                                    </Tag>
                                                </Space>
                                                {renderParamValue(field, depth + 2)}
                                            </Space>
                                        ))
                                )}
                            </Space>
                        </Space>
                    ))}
                </Space>
            );
        }

        if (param.type === 'bytes[]') {
            return (
                <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                    {param?.value?.map((item: any, index: number) => (
                        <Space direction="vertical" key={index} size={2}>
                            <Text type="secondary">[{index}]:</Text>
                            {typeof item === 'object' && item?.value && typeof item?.value === 'object' ? (
                                <Space direction="vertical" style={{ marginLeft: 16 }}>
                                    {Object.entries(item?.value)
                                        .sort(([keyA], [keyB]) => {
                                            const numA = parseInt(keyA.split('_')[1]);
                                            const numB = parseInt(keyB.split('_')[1]);
                                            return numA - numB;
                                        })
                                        .map(([key, fieldValue]: [string, any]) => (
                                            <Space key={key}>
                                                <Text type="secondary">{key}:</Text>
                                                <Tag color={getTagColorByParamType(fieldValue.type)}>
                                                    {fieldValue.type}
                                                </Tag>
                                                <Text code>{fieldValue.value.toString()}</Text>
                                            </Space>
                                        ))}
                                </Space>
                            ) : (
                                <Text code copyable style={{ fontSize: '14px' }}>
                                    {item?.value || item}
                                </Text>
                            )}
                        </Space>
                    ))}
                </Space>
            );
        }

        // Handle bytes with nested structure
        if (param.type === 'bytes' && typeof param.value === 'object' && param.value.type === 'bytes') {
            return (
                <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                    {typeof param.value.value === 'object' ? (
                        Object.entries(param.value.value)
                            .sort(([keyA], [keyB]) => {
                                const numA = parseInt(keyA.split('_')[1]);
                                const numB = parseInt(keyB.split('_')[1]);
                                return numA - numB;
                            })
                            .map(([key, fieldValue]: [string, any]) => (
                                <Space key={key}>
                                    <Text type="secondary">{key}:</Text>
                                    <Tag color={getTagColorByParamType(fieldValue.type)}>
                                        {fieldValue.type}
                                    </Tag>
                                    <Text code>{fieldValue.value.toString()}</Text>
                                </Space>
                            ))
                    ) : (
                        <Text code copyable style={{ fontSize: '14px' }}>
                            {param?.value?.value}
                        </Text>
                    )}
                </Space>
            );
        }

        if (param.type === 'address') {
            return (
                <Space>
                    <Tooltip title={getTooltipTitleForParamType(addressData, param)}>
                        <Text code copyable style={{ fontSize: '14px' }}>
                            {param?.value}
                        </Text>
                    </Tooltip>
                    {renderAddressAvatar(addressData, 'from', param?.value)}
                </Space>
            );
        }

        if (param?.type === 'bytes' || param?.type === 'string') {
            return renderSpecializedDataType(param, addressData);
        }

        if (param?.type === 'bool') {
            return (
                <Tag color={param?.value ? 'green' : 'red'}>
                    {param?.value ? 'True' : 'False'}
                </Tag>
            );
        }

        // Handle any other object type
        if (typeof param.value === 'object' && param.value !== null) {
            return renderSpecializedDataType(param, addressData);
        }

        return <Text code>{param?.value?.toString()}</Text>;
    };

    // Check if this is a proxy call
    const isProxyCall = decodedCall?.params?.some(param => 
        param.type === 'bytes' && 
        typeof param.value === 'object' && 
        param.value.is_proxy_call === true
    );

    // Get the proxy call details if available
    const proxyCallParam = decodedCall?.params?.find(param => 
        param.type === 'bytes' && 
        typeof param.value === 'object' && 
        param.value.is_proxy_call === true
    );

    const proxyCallDetails = proxyCallParam?.value;

    // Add a new function to render proxy call details
    const renderProxyCallDetails = () => {
        if (!proxyCallDetails) return null;

        return (
            <Card 
                size="small" 
                title={
                    <Space>
                        <InfoCircleOutlined />
                        <Text strong>Proxy Call Details</Text>
                    </Space>
                }
                style={{ marginBottom: 16 }}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Space size="large">
                        {proxyCallDetails?.label && (
                            <Space direction="vertical" size={0}>
                                <Text type="secondary">Function</Text>
                                <Text strong style={{ textTransform: 'capitalize' }}>
                                    {proxyCallDetails?.label}
                                </Text>
                            </Space>
                        )}
                        {proxyCallDetails?.type && (
                            <Space direction="vertical" size={0}>
                                <Text type="secondary">Type</Text>
                                <Text strong style={{ textTransform: 'capitalize' }}>
                                    {proxyCallDetails?.type}
                                </Text>
                            </Space>
                        )}
                    </Space>

                    {proxyCallDetails?.address && (
                        <>
                            <Divider style={{ margin: '12px 0' }} />
                            <Space direction="vertical" size={0}>
                                <Text type="secondary">Target Contract</Text>
                                <Space>
                                    <Text code copyable>
                                        {proxyCallDetails?.address}
                                    </Text>
                                    {renderAddressAvatar(addressData, 'to', proxyCallDetails?.address)}
                                </Space>
                            </Space>
                        </>
                    )}

                    {proxyCallDetails?.signature && (
                        <>
                            <Divider style={{ margin: '12px 0' }} />
                            <Space direction="vertical" size={0}>
                                <Text type="secondary">Signature</Text>
                                <Text code copyable>
                                    {proxyCallDetails?.signature}
                                </Text>
                            </Space>
                        </>
                    )}

                    {/* Parameters Section for Proxy Call */}
                    {proxyCallDetails.params && proxyCallDetails.params.length > 0 && (
                        <>
                            <Divider style={{ margin: '12px 0' }} />
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <Space align="center" style={{ justifyContent: 'space-between', width: '100%' }}>
                                    <Text type="secondary">Parameters</Text>
                                </Space>
                                <Space direction="vertical" size={8} style={{ width: '100%' }}>
                                    {proxyCallDetails.params.map((param: any, index: number) => (
                                        <Card 
                                            key={index} 
                                            size="small" 
                                            bordered={false}
                                            style={{ background: '#fafafa' }}
                                        >
                                            <Space direction="vertical" size={2}>
                                                <Space>
                                                    <Text type="secondary">{param.name || `param_${index}`}:</Text>
                                                    <Tag color={getTagColorByParamType(param.type)}>
                                                        {param.type}
                                                    </Tag>
                                                </Space>
                                                {renderParamValue(param)}
                                            </Space>
                                        </Card>
                                    ))}
                                </Space>
                            </Space>
                        </>
                    )}
                </Space>
            </Card>
        );
    };

    return (
        <>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                {/* Summary Section */}
                {decodedCall.summary && (
                    <Card size="small">
                        <Space direction="vertical">
                            <Text type="secondary">Summary</Text>
                            <Text strong>{decodedCall.summary}</Text>
                        </Space>
                    </Card>
                )}

                {/* Function Details */}
                <Card size="small">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Space size="large">
                            {decodedCall?.label && (
                                <Space direction="vertical" size={0}>
                                    <Text type="secondary">Function</Text>
                                    <Text strong style={{ textTransform: 'capitalize' }}>
                                        {decodedCall?.label}
                                        {isProxyCall && (
                                            <Tag color="blue" style={{ marginLeft: 8 }}>
                                                Proxy Call
                                            </Tag>
                                        )}
                                    </Text>
                                </Space>
                            )}
                            {decodedCall?.type && (
                                <Space direction="vertical" size={0}>
                                    <Text type="secondary">Type</Text>
                                    <Text strong style={{ textTransform: 'capitalize' }}>
                                        {decodedCall?.type}
                                    </Text>
                                </Space>
                            )}
                        </Space>

                        {decodedCall?.signature && (
                            <>
                                <Divider style={{ margin: '12px 0' }} />
                                <Space direction="vertical" size={0}>
                                    <Text type="secondary">Signature</Text>
                                    <Text code copyable>
                                        {decodedCall?.signature}
                                    </Text>
                                </Space>
                            </>
                        )}
                    </Space>
                </Card>

                {/* Parameters Section */}
                {decodedCall.params && decodedCall.params.length > 0 && (
                    <Card size="small">
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Space align="center" style={{ justifyContent: 'space-between', width: '100%' }}>
                                <Text type="secondary">Parameters</Text>
                                {decodedCall.params.length > 10 && (
                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() => setShowAllParams(!showAllParams)}
                                    >
                                        {showAllParams ? 'Show Less' : 'Show All'}
                                    </Button>
                                )}
                            </Space>
                            <Space direction="vertical" size={8} style={{ width: '100%' }}>
                                {(showAllParams ? decodedCall.params : decodedCall.params.slice(0, maxVisible)).map((param, index) => (
                                    <Card 
                                        key={index} 
                                        size="small" 
                                        bordered={false}
                                        style={{ background: '#fafafa' }}
                                    >
                                        <Space direction="vertical" size={2} style={{ width: '100%' }}>
                                            <Space>
                                                <Text type="secondary">{param.name}:</Text>
                                                <Tag color={getTagColorByParamType(param.type)}>{param.type}</Tag>
                                                {isProxyCall && param.type === 'bytes' && param.value.is_proxy_call && (
                                                    <Tag color="blue">Proxy Data</Tag>
                                                )}
                                            </Space>
                                            
                                            {/* For proxy calls, make the bytes parameter collapsible */}
                                            {isProxyCall && param.type === 'bytes' && param.value.is_proxy_call ? (
                                                <div>
                                                    <Button 
                                                        type="link" 
                                                        icon={showProxyDetails ? <DownOutlined /> : <RightOutlined />}
                                                        onClick={() => setShowProxyDetails(!showProxyDetails)}
                                                        style={{ paddingLeft: 0 }}
                                                    >
                                                        {showProxyDetails ? 'Hide' : 'Show'} Proxy Call Details
                                                    </Button>
                                                    
                                                    {showProxyDetails && renderProxyCallDetails()}
                                                </div>
                                            ) : (
                                                renderParamValue(param)
                                            )}
                                        </Space>
                                    </Card>
                                ))}
                            </Space>
                        </Space>
                    </Card>
                )}

                {/* Raw Transaction Data */}
                {(decodedCall.input || decodedCall.output) && (
                    <Card size="small">
                        <Space direction="vertical" size={0} style={{ width: '100%' }}>
                            <Space align="center" style={{ justifyContent: 'space-between', width: '100%' }}>
                                <Text type="secondary">Raw Transaction Data</Text>
                                <Button
                                    type="link"
                                    size="small"
                                    icon={<CodeOutlined/>}
                                    onClick={() => setShowRawData(true)}
                                >
                                    View Raw Transaction
                                </Button>
                            </Space>
                            <Space direction="vertical" size={4} style={{ width: '100%' }}>
                                {renderDataField('Input', decodedCall?.input || "")}
                                {renderDataField('Output', decodedCall?.output || "")}
                            </Space>
                        </Space>
                    </Card>
                )}
            </Space>

            {/* Raw Data Modal */}
            <Modal
                title="Raw Transaction Data"
                open={showRawData}
                onCancel={() => setShowRawData(false)}
                footer={null}
                width={800}
            >
                <pre style={{ 
                    maxHeight: '70vh', 
                    overflow: 'auto',
                    backgroundColor: '#f5f5f5',
                    padding: '16px',
                    borderRadius: '4px'
                }}>
                    {JSON.stringify(decodedCall, null, 2)}
                </pre>
            </Modal>
        </>
    );
}; 
import React, { useState } from 'react';
import { Modal, Space, Button, Avatar, Typography, Divider, Row, Col, Statistic, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { CodeOutlined, CloseOutlined, EyeOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { GraphEntity } from '../../../interfaces/graph';

interface EntityStatsProps {
    entity: GraphEntity;
    chainIdToName: Record<string, string>;
    cdn_domain_name: string;
    onClose: () => void;
}

export const EntityStats: React.FC<EntityStatsProps> = ({ 
    entity, 
    chainIdToName, 
    cdn_domain_name, 
    onClose 
}) => {
    const [isRawDataVisible, setIsRawDataVisible] = useState(false);

    return (
        <>
            <Modal
                title="Raw Entity Data"
                open={isRawDataVisible}
                onCancel={() => setIsRawDataVisible(false)}
                footer={[
                    <Button key="close" onClick={() => setIsRawDataVisible(false)}>
                        Close
                    </Button>
                ]}
                width={800}
            >
                <pre style={{ 
                    backgroundColor: '#f5f5f5', 
                    padding: '16px', 
                    borderRadius: '4px',
                    overflow: 'auto',
                    maxHeight: '600px'
                }}>
                    {JSON.stringify(entity, null, 2)}
                </pre>
            </Modal>

            <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                background: 'white',
                padding: '16px 24px',
                boxShadow: '0 -2px 8px rgba(0,0,0,0.15)',
                zIndex: 1000,
                transition: 'transform 0.3s ease',
                transform: entity ? 'translateY(0)' : 'translateY(100%)',
            }}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Space>
                            {entity?.logo && (
                                <Avatar src={entity.logo} size="large" />
                            )}
                            <Space direction="vertical" size={0}>
                                <Typography.Title level={5} style={{ margin: 0 }}>
                                    {entity.name || 'Unknown Entity'}
                                </Typography.Title>
                                <Typography.Text type="secondary">
                                    {entity.type || 'Unknown Type'}
                                </Typography.Text>
                            </Space>
                        </Space>
                        <Space>
                            <Button 
                                icon={<CodeOutlined />}
                                onClick={() => setIsRawDataVisible(true)}
                                type="text"
                            >
                                View Raw Data
                            </Button>
                            <Button 
                                icon={<CloseOutlined />} 
                                type="text"
                                onClick={onClose}
                            />
                        </Space>
                    </Space>

                    <Divider style={{ margin: '12px 0' }} />

                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Statistic 
                                title="Total Addresses" 
                                value={entity.address_count || 0}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="Total Transactions" 
                                value={entity.transaction_count || 0}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="Total Value Processed" 
                                value={entity.total_value || 0}
                                prefix="Ξ"
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="First Seen" 
                                value={new Date(entity.first_seen).toLocaleDateString()}
                            />
                        </Col>
                    </Row>
                </Space>
            </div>
        </>
    );
}; 
import React, { useState, useEffect } from 'react';
import { List, Card, Space, Select, Typography, Button, Avatar, Tag, Empty, Checkbox, Tooltip, Dropdown, Divider, DatePicker } from 'antd';
import { IAddress, IToken, ITokenPnL } from 'interfaces/address.d';
import { TokenApprovalModal } from './TokenApprovalModal';
import { useOne } from '@refinedev/core';
import { CHAIN_MAPPING } from "interfaces/chain.d";
import { DownOutlined, CloseOutlined, ArrowLeftOutlined, LeftOutlined, RightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ChainAvatar } from './StatsTab';
import { formatUSD } from '../../../utils/formatters';
const { Text, Link } = Typography;
import { formatTokenBalance, shortenAddress } from '../../../utils/formatters';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

interface TokensTabProps {
    isEmbedded?: boolean;
    record: Pick<IAddress, 'address' | 'active_chain_ids'>;
    blockTimestamps?: string[];
}

interface TokensResponse {
    request_id: string;
    results: {
        [chainId: string]: {
            data: IToken[];
            cursor: string | null;
            has_more: boolean;
        };
    };
    errors: Record<string, any>;
}

export const TokensTab: React.FC<TokensTabProps> = ({ record, isEmbedded = false, blockTimestamps = [] }) => {
    const availableChains = record?.active_chain_ids || [];
    const ALL_CHAINS = 'all';
    const [selectedChainIds, setSelectedChainIds] = useState<string[]>([ALL_CHAINS]);
    const [tokenTypeFilter, setTokenTypeFilter] = useState<string[]>([]);
    const [hideSpam, setHideSpam] = useState(true);
    const [selectedToken, setSelectedToken] = useState<(IToken & { chainId: string }) | null>(null);
    const [activeModal, setActiveModal] = useState<
        'traders' | 'liquidity' | 'owners' | 'swapsByPair' | 
        'swapsByToken' | 'swapsByWallet' | 'approvals' | null
    >(null);
    const [selectedDate, setSelectedDate] = useState<Dayjs>(() => {
        if (isEmbedded && blockTimestamps.length === 1) {
            return dayjs(blockTimestamps[0]);
        }
        return dayjs();
    });
    const [isBlockNavigation, setIsBlockNavigation] = useState("latest");


    const { data, isLoading, refetch } = useOne<TokensResponse>({
            resource: "stats",
            id: record?.address,
            meta: {
                address: record?.address,
                chain_ids: availableChains,
                limit: 1,
                operation: "getWalletTokenBalancesPrice",
                to_block: selectedDate.unix(),
                exclude_spam: true,
                exclude_unverified_contracts: true,
                exclude_native: false,
                is_block_navigation: isBlockNavigation,
            },
            queryOptions: {
                enabled: isEmbedded,
                refetchOnWindowFocus: false,
            },
        });

    useEffect(() => {
        if (!isEmbedded) {
            refetch();
        }
    }, [availableChains]);

    // Filter tokens based on selected chains and other filters
    const filteredTokens = Object.entries(data?.data?.results || {}).reduce((acc, [chainId, chainData]) => {
        // Skip if specific chains are selected and this chain isn't one of them
        if (!selectedChainIds.includes(ALL_CHAINS) && selectedChainIds.length > 0) {
            if (!selectedChainIds.includes(chainId)) {
                return acc;
            }
        }

        return [...acc, ...chainData.data.filter(token => {
            // Spam filter
            if (hideSpam && token.possible_spam) {
                return false;
            }

            // Token type filter
            if (tokenTypeFilter.length > 0) {
                const tokenType = token.contract_type?.toLowerCase() || 'unknown';
                return tokenTypeFilter.includes(tokenType);
            }

            return true;
        }).map(token => ({
            ...token,
            chainId // Add chainId to token data for display
        }))];
    }, [] as (IToken & { chainId: string })[]);

    const renderTokenActions = (token: IToken & { chainId: string }) => (
        <Space>
            <Button 
                type="link" 
                onClick={() => {
                    setSelectedToken(token);
                    setActiveModal('traders');
                }}
            >
                Top Traders
            </Button>
            <Button 
                type="link" 
                onClick={() => {
                    setSelectedToken(token);
                    setActiveModal('liquidity');
                }}
            >
                Pairs & Liquidity
            </Button>
            <Button 
                type="link" 
                onClick={() => {
                    setSelectedToken(token);
                    setActiveModal('owners');
                }}
            >
                Token Owners
            </Button>
            <Dropdown
                menu={{
                    items: [
                        {
                            key: 'swapsByPair',
                            label: 'Swaps by Pair',
                            onClick: () => {
                                setSelectedToken(token);
                                setActiveModal('swapsByPair');
                            }
                        },
                        {
                            key: 'swapsByToken',
                            label: 'Swaps by Token',
                            onClick: () => {
                                setSelectedToken(token);
                                setActiveModal('swapsByToken');
                            }
                        },
                        {
                            key: 'swapsByWallet',
                            label: 'Swaps by Wallet',
                            onClick: () => {
                                setSelectedToken(token);
                                setActiveModal('swapsByWallet');
                            }
                        },
                    ]
                }}
            >
                <Button type="link">
                    Swaps <DownOutlined />
                </Button>
            </Dropdown>
            <Button 
                type="link" 
                onClick={() => {
                    setSelectedToken(token);
                    setActiveModal('approvals');
                }}
            >
                Approvals
            </Button>
        </Space>
    );

    const handleDateChange = (newDate: Dayjs | null, blockNav: "true" | "previous" | "next" | "latest" = "latest") => {
        if (!newDate) return;

        // Just update the states, useEffect will handle the refetch
        setIsBlockNavigation(blockNav);
        setSelectedDate(newDate);
    };

    useEffect(() => {
        if (!isEmbedded && selectedDate) {
            refetch();
        }
    }, [selectedDate, isBlockNavigation, isEmbedded, refetch]);

    const dateControls = (
        <Space direction="vertical" size="small">
            <Space>
                <Text type="secondary">
                    Select a date to view historical token data:
                </Text>
                <Tooltip title="View portfolio data for any past date. Future dates are not available.">
                    <InfoCircleOutlined/>
                </Tooltip>
            </Space>
            <Space wrap>
                {blockTimestamps.length > 0 && (
                    <>
                        <Text type="secondary">Transaction timeline:</Text>
                        {blockTimestamps
                            .sort((a, b) => dayjs(b).unix() - dayjs(a).unix())
                            .slice(0, 3)
                            .map((timestamp) => {
                                const date = dayjs(timestamp);
                                return (
                                    <Space key={timestamp}>
                                        <Tooltip title="View portfolio 1 block before this transaction">
                                            <Button
                                                size="small"
                                                onClick={() => handleDateChange(date.subtract(1, 'second'), "previous")}
                                                icon={<LeftOutlined/>}                                            >
                                                Block -1
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={date.format('MMMM D, YYYY HH:mm:ss')}>
                                            <Button
                                                onClick={() => handleDateChange(date)}
                                                type={selectedDate.isSame(date, 'second') ? 'primary' : 'default'}
                                            >
                                                {date.format('MMM D')}
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="View portfolio 1 block after this transaction">
                                            <Button
                                                size="small"
                                                onClick={() => handleDateChange(date.add(1, 'second'), "next")}
                                                icon={<RightOutlined/>}
                                                disabled={date.add(1, 'second').isAfter(dayjs(), 'second')}
                                            >
                                                Block +1
                                            </Button>
                                        </Tooltip>
                                    </Space>
                                );
                            })}
                        {blockTimestamps.length > 3 && (
                            <Tooltip title="More timestamps available in calendar">
                                <Button size="small" type="dashed">+{blockTimestamps.length - 3}</Button>
                            </Tooltip>
                        )}
                        <Divider type="vertical" />
                    </>
                )}

                <Button 
                    onClick={() => handleDateChange(dayjs().subtract(1, 'day'), "previous")}
                    icon={<LeftOutlined/>}
                >
                    Previous Day
                </Button>
                <Button 
                    onClick={() => handleDateChange(dayjs())}
                    type={selectedDate.isSame(dayjs(), 'day') ? 'primary' : 'default'}
                >
                    Today
                </Button>
                <Button 
                    onClick={() => handleDateChange(dayjs().add(1, 'day'), "next")}
                    disabled={selectedDate.isAfter(dayjs(), 'day')}
                    icon={<RightOutlined/>}
                >
                    Next Day
                </Button>

                <DatePicker
                    value={selectedDate}
                    onChange={(date) => date && handleDateChange(date, 'true')}
                    allowClear={false}
                    disabledDate={(current) => current && current.isAfter(dayjs(), 'second')}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder="Select date"
                    showTime
                />
                <Text type="secondary">
                    Currently viewing: <Text strong>{selectedDate.format('MMMM D, YYYY HH:mm:ss')}</Text>
                </Text>
            </Space>
        </Space>
    );

    return (
        <Space 
            direction="vertical" 
            style={{ 
                width: '100%',
                ...(isEmbedded && {
                    height: 'calc(80vh - 180px)', // Match container height
                    minHeight: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '0 24px'
                })
            }} 
            size="large"
        >
            {isEmbedded && (
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Space align="center">
                        <Button 
                            type="link" 
                            icon={<ArrowLeftOutlined/>}
                            onClick={() => {
                                const overviewTab = document.querySelector('[data-node-key="overview"]');
                                if (overviewTab) {
                                    (overviewTab as HTMLElement).click();
                                }
                            }}
                        >
                            Back to Graph View
                        </Button>
                        <Divider type="vertical" />
                        <Tag color="warning">
                            <Space>
                                <Text type="secondary">Viewing Tokens for</Text>
                                <Text strong copyable={{ text: record?.address }}>
                                    <Tooltip title={record?.address}>
                                        {shortenAddress(record?.address)}
                                    </Tooltip>
                                </Text>
                                <Text type="secondary">on</Text>
                                <Space>
                                    <ChainAvatar chainId={availableChains?.[0]} size="small" />
                                    <Text>{CHAIN_MAPPING[availableChains?.[0]] || availableChains?.[0]}</Text>
                                </Space>
                            </Space>
                        </Tag>
                    </Space>
                    <Button 
                        type="text" 
                        icon={<CloseOutlined/>}
                        onClick={() => {
                            const overviewTab = document.querySelector('[data-node-key="overview"]');
                            if (overviewTab) {
                                (overviewTab as HTMLElement).click();
                            }
                        }}
                    />
                </Space>
            )}
            <Card style={{
                ...(isEmbedded && {
                    height: 'calc(100% - 60px)', // Account for header
                    overflow: 'auto'
                })
            }}>
                <Space wrap>
                    <Select
                        mode="multiple"
                        value={selectedChainIds}
                        onChange={(values) => {
                            if (values.includes(ALL_CHAINS)) {
                                setSelectedChainIds([ALL_CHAINS]);
                                return;
                            }
                            
                            if (selectedChainIds.includes(ALL_CHAINS)) {
                                setSelectedChainIds(values.filter(v => v !== ALL_CHAINS));
                                return;
                            }

                            setSelectedChainIds(values);
                        }}
                        style={{ width: 350 }}
                        placeholder="Select chains (empty = all chains)"
                        allowClear
                        maxTagCount={3}
                        onClear={() => setSelectedChainIds([ALL_CHAINS])}
                    >
                        <Select.OptGroup label="All Active Chains">
                            <Select.Option key={ALL_CHAINS} value={ALL_CHAINS}>
                                <Space>
                                    <Avatar.Group 
                                        maxCount={3} 
                                        size="small"
                                        maxStyle={{ 
                                            color: '#f56a00', 
                                            backgroundColor: '#fde3cf',
                                        }}
                                    >
                                        {availableChains.map((chainId: string) => (
                                            <Tooltip 
                                                key={chainId} 
                                                title={CHAIN_MAPPING[chainId] || chainId}
                                                overlayStyle={{ zIndex: 1100 }}
                                            >
                                                <ChainAvatar chainId={chainId} size="small" />
                                            </Tooltip>
                                        ))}
                                    </Avatar.Group>
                                    <Text>All Active Chains ({availableChains.length})</Text>
                                </Space>
                            </Select.Option>
                        </Select.OptGroup>
                        <Select.OptGroup label="Individual Chains">
                            {availableChains.map((chainId: string) => (
                                <Select.Option key={chainId} value={chainId}>
                                    <Space>
                                        <ChainAvatar chainId={chainId} size="small" />
                                        {CHAIN_MAPPING[chainId] || chainId}
                                    </Space>
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                    </Select>

                    <Select
                        mode="multiple"
                        value={tokenTypeFilter}
                        onChange={setTokenTypeFilter}
                        style={{ width: 200 }}
                        placeholder="Token type"
                        allowClear
                    >
                        <Select.Option value="erc20">ERC20</Select.Option>
                        <Select.Option value="native">Native</Select.Option>
                        <Select.Option value="unknown">Unknown</Select.Option>
                    </Select>

                    <Checkbox 
                        checked={hideSpam} 
                        onChange={(e) => setHideSpam(e.target.checked)}
                    >
                        Hide Spam Tokens
                    </Checkbox>
                    <Divider style={{ margin: '12px 0' }} />
                    {dateControls}                    
                </Space>
            </Card>

            <Card>
                <List
                    loading={isLoading}
                    dataSource={filteredTokens}
                    renderItem={(token) => (
                        <List.Item
                            actions={[renderTokenActions(token)]}
                        >
                            <List.Item.Meta
                                avatar={<Avatar src={token.logo} />}
                                title={
                                    <Space>
                                        <Text>{token.name}</Text>
                                        <Tag color="blue">{token.symbol}</Tag>
                                        <Tooltip title={CHAIN_MAPPING[token.chainId] || token.chainId}>
                                            <span>
                                                <ChainAvatar chainId={token.chainId} size="small" />
                                            </span>
                                        </Tooltip>
                                    </Space>
                                }
                                description={
                                    <Space direction="vertical" size={0}>
                                        <Text>
                                            {`${formatTokenBalance(token.balance, token.decimals || 0)} ${token.symbol}`}
                                        </Text>
                                        {token.usd_price !== undefined && token.usd_price !== null && (
                                            <Tooltip title="Token Price">
                                                <Text type="secondary">
                                                    ${Number(token.usd_price).toLocaleString(undefined, { 
                                                        minimumFractionDigits: 2, 
                                                        maximumFractionDigits: 6 
                                                    })}
                                                </Text>
                                            </Tooltip>
                                        )}
                                    </Space>
                                }
                            />
                            <div>
                                {formatUSD(Number(token.value_usd || 0))}
                            </div>
                        </List.Item>
                    )}
                />
            </Card>

            {/* Modals */}
            {/* <TokenTopTradersModal
                open={activeModal === 'traders'}
                onClose={() => setActiveModal(null)}
                token={selectedToken}
            />
            <TokenLiquidityModal
                open={activeModal === 'liquidity'}
                onClose={() => setActiveModal(null)}
                token={selectedToken}
            />
            <TokenOwnersModal
                open={activeModal === 'owners'}
                onClose={() => setActiveModal(null)}
                token={selectedToken}
            />
            <TokenSwapsModal
                open={activeModal === 'swapsByPair' || activeModal === 'swapsByToken' || activeModal === 'swapsByWallet'}
                onClose={() => setActiveModal(null)}
                token={selectedToken}
                type={activeModal as 'swapsByPair' | 'swapsByToken' | 'swapsByWallet'}
            />
            <TokenApprovalsModal
                open={activeModal === 'approvals'}
                onClose={() => setActiveModal(null)}
                token={selectedToken}
            /> */}
        </Space>
    );
};

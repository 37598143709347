import React, { useState } from 'react';
import { Typography, Space, Card, Divider, Tag, Tooltip, Button, Modal } from 'antd';
import { AddressData, getAddressInfo } from '../../../utils/addressUtils';
import { CopyOutlined, CodeOutlined, LinkOutlined } from '@ant-design/icons';
import { shortenAddress, getTagColorByParamType, getTooltipTitleForParamType } from "../../../utils/formatters"
import { renderAddressAvatar } from 'components/address-avatar';
import renderSpecializedDataType from './SpecializedDataRenderers';

const { Text, Paragraph } = Typography;

interface LogEventViewProps {
    log: {
        summary?: string;
        address?: string;
        topic0?: string;
        topic1?: string;
        topic2?: string;
        topic3?: string;
        data?: string;
        decoded_event?: {
            summary?: string;
            label?: string;
            type?: string;
            params?: any[];
            signature?: string;
        };
        block_number?: string;
        log_index?: string;
    };
    addressData: AddressData;
    chainId?: string;
}

export const LogEventView: React.FC<LogEventViewProps> = ({
    log,
    addressData,
    chainId
}) => {
    const [showRawData, setShowRawData] = useState(false);
    const [showAllParams, setShowAllParams] = useState(false);

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const renderParamValue = (param: any, depth = 0): React.ReactNode => {
        const indent = depth * 16;

        // Use specialized renderer for complex types like CBOR
        if (typeof param?.value === 'object' && param?.value !== null && param?.value?.type) {
            return renderSpecializedDataType(param, addressData);
        }

        if (param.type === 'tuple') {
            return (
                <Space direction="vertical" style={{ marginLeft: indent, width: '100%' }}>
                    {param?.value?.map((item: any, index: number) => (
                        <Card 
                            key={index} 
                            size="small" 
                            style={{ 
                                marginBottom: 8, 
                                background: '#fafafa', 
                                borderLeft: '3px solid #1890ff',
                                width: '100%'
                            }}
                        >
                            <Space direction="vertical" size={4} style={{ width: '100%' }}>
                                <Space>
                                    <Text strong>{item.name || `param_${index}`}:</Text>
                                    <Tag color={getTagColorByParamType(item.type)}>{item.type}</Tag>
                                </Space>
                                <div style={{ paddingLeft: 8 }}>
                                    {renderParamValue(item, depth + 1)}
                                </div>
                            </Space>
                        </Card>
                    ))}
                </Space>
            );
        }

        if (param.type === 'tuple[]') {
            if (!param.value || param.value.length === 0) {
                return <Text italic>Empty array</Text>;
            }
            
            return (
                <Space direction="vertical" style={{ marginLeft: indent, width: '100%' }}>
                    {param?.value?.map((tupleItem: any[], index: number) => (
                        <Card 
                            key={index} 
                            size="small" 
                            style={{ 
                                marginBottom: 8, 
                                background: '#f0f5ff', 
                                borderLeft: '3px solid #2f54eb'
                            }}
                        >
                            <Space direction="vertical" size={4} style={{ width: '100%' }}>
                                <Text strong>[{index}]</Text>
                                <div style={{ paddingLeft: 8 }}>
                                    {tupleItem.map((item: any, subIndex: number) => (
                                        <Card 
                                            key={subIndex} 
                                            size="small" 
                                            style={{ 
                                                marginBottom: 4, 
                                                background: '#f9f9f9'
                                            }}
                                        >
                                            <Space direction="vertical" size={2}>
                                                <Space>
                                                    <Text strong>{item.name || `param_${subIndex}`}:</Text>
                                                    <Tag color={getTagColorByParamType(item.type)}>{item.type}</Tag>
                                                </Space>
                                                <div style={{ paddingLeft: 8 }}>
                                                    {renderParamValue(item, depth + 2)}
                                                </div>
                                            </Space>
                                        </Card>
                                    ))}
                                </div>
                            </Space>
                        </Card>
                    ))}
                </Space>
            );
        }

        // Handle bytes[] with nested objects
        if (param.type === 'bytes[]') {
            if (!param.value || param.value.length === 0) {
                return <Text italic>Empty array</Text>;
            }
            
            return (
                <Space direction="vertical" style={{ marginLeft: indent, width: '100%' }}>
                    {param?.value?.map((item: any, index: number) => (
                        <Card 
                            key={index} 
                            size="small" 
                            style={{ 
                                marginBottom: 8, 
                                background: '#f6ffed', 
                                borderLeft: '3px solid #52c41a'
                            }}
                        >
                            <Space direction="vertical" size={4} style={{ width: '100%' }}>
                                <Text strong>[{index}]</Text>
                                <div style={{ paddingLeft: 8 }}>
                                    {typeof item === 'object' && item !== null ? (
                                        // Render object as a structured view
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            {Object.entries(item).map(([key, value], objIndex) => (
                                                <div key={objIndex}>
                                                    <Text strong>{key}:</Text>
                                                    <div style={{ paddingLeft: 8 }}>
                                                        {typeof value === 'object' && value !== null ? (
                                                            // Recursively render nested objects
                                                            <Space direction="vertical" style={{ width: '100%' }}>
                                                                {Object.entries(value as object).map(([nestedKey, nestedValue], nestedIndex) => (
                                                                    <div key={nestedIndex}>
                                                                        <Text strong>{nestedKey}:</Text>
                                                                        <div style={{ paddingLeft: 8 }}>
                                                                            <Text code>{String(nestedValue)}</Text>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Space>
                                                        ) : (
                                                            <Text code>{String(value)}</Text>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </Space>
                                    ) : (
                                        <Text code copyable>{String(item)}</Text>
                                    )}
                                </div>
                            </Space>
                        </Card>
                    ))}
                </Space>
            );
        }

        // Handle fixed-size array types (like bytes32[3])
        if (param?.type?.match(/\[\d+\]$/)) {
            if (typeof param.value === 'string') {
                // Split by comma, but handle the case where the values themselves contain commas
                const values = param.value.split(/,(?=0x)/);
                
                return (
                    <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                        {values.map((value: string, index: number) => (
                            <Space key={index} style={{ width: '100%' }}>
                                <Text type="secondary">[{index}]:</Text>
                                <Text code copyable style={{ fontSize: '14px' }}>
                                    {value.trim()}
                                </Text>
                            </Space>
                        ))}
                    </Space>
                );
            } else if (Array.isArray(param.value)) {
                // Handle case where value is already an array
                return (
                    <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                        {param.value.map((value: any, index: number) => (
                            <Space key={index} style={{ width: '100%' }}>
                                <Text type="secondary">[{index}]:</Text>
                                {typeof value === 'object' && value !== null ? (
                                    // Render object as a structured view
                                    <Card 
                                        size="small" 
                                        style={{ 
                                            marginBottom: 4, 
                                            background: '#f9f9f9',
                                            width: '100%'
                                        }}
                                    >
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            {Object.entries(value).map(([key, objValue], objIndex) => (
                                                <div key={objIndex}>
                                                    <Text strong>{key}:</Text>
                                                    <div style={{ paddingLeft: 8 }}>
                                                        {typeof objValue === 'object' && objValue !== null ? (
                                                            // Recursively render nested objects
                                                            <Space direction="vertical" style={{ width: '100%' }}>
                                                                {Object.entries(objValue as object).map(([nestedKey, nestedValue], nestedIndex) => (
                                                                    <div key={nestedIndex}>
                                                                        <Text strong>{nestedKey}:</Text>
                                                                        <div style={{ paddingLeft: 8 }}>
                                                                            <Text code>{String(nestedValue)}</Text>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Space>
                                                        ) : (
                                                            <Text code>{String(objValue)}</Text>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </Space>
                                    </Card>
                                ) : (
                                    <Text code copyable style={{ fontSize: '14px' }}>
                                        {String(value)}
                                    </Text>
                                )}
                            </Space>
                        ))}
                    </Space>
                );
            } else if (typeof param.value === 'object' && param.value !== null) {
                // Handle case where value is a structured object (like reportContext)
                return (
                    <Card 
                        size="small" 
                        style={{ 
                            marginBottom: 8, 
                            background: '#f0f5ff', 
                            borderLeft: '3px solid #2f54eb',
                            width: '100%'
                        }}
                    >
                        <Space direction="vertical" style={{ width: '100%' }}>
                            {Object.entries(param.value).map(([key, value], index) => (
                                <div key={index}>
                                    <Text strong>{key}:</Text>
                                    <div style={{ paddingLeft: 8 }}>
                                        {typeof value === 'object' && value !== null ? (
                                            // Handle nested objects with name, type, value structure
                                            <Card 
                                                size="small" 
                                                style={{ 
                                                    marginBottom: 4, 
                                                    background: '#f9f9f9'
                                                }}
                                            >
                                                <Space direction="vertical" size={2}>
                                                    <Space>
                                                        <Text strong>{(value as any).name || key}:</Text>
                                                        {(value as any).type && (
                                                            <Tag color={getTagColorByParamType((value as any).type)}>
                                                                {(value as any).type}
                                                            </Tag>
                                                        )}
                                                    </Space>
                                                    <div style={{ paddingLeft: 8 }}>
                                                        {(value as any).value !== undefined ? (
                                                            typeof (value as any).value === 'object' && (value as any).value !== null ? (
                                                                // Recursively render nested objects
                                                                <Space direction="vertical" style={{ width: '100%' }}>
                                                                    {Object.entries((value as any).value as object).map(([nestedKey, nestedValue], nestedIndex) => (
                                                                        <div key={nestedIndex}>
                                                                            <Text strong>{nestedKey}:</Text>
                                                                            <div style={{ paddingLeft: 8 }}>
                                                                                <Text code>{String(nestedValue)}</Text>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </Space>
                                                            ) : (
                                                                <Text code>{String((value as any).value)}</Text>
                                                            )
                                                        ) : (
                                                            <Text code>{String(value)}</Text>
                                                        )}
                                                    </div>
                                                </Space>
                                            </Card>
                                        ) : (
                                            <Text code>{String(value)}</Text>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </Space>
                    </Card>
                );
            }
        }

        // Handle dynamic array types
        if (param?.type?.endsWith('[]')) {
            if (Array.isArray(param.value)) {
                return (
                    <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                        {param.value.map((value: any, index: number) => (
                            <Space key={index} style={{ width: '100%' }}>
                                <Text type="secondary">[{index}]:</Text>
                                {typeof value === 'object' && value !== null ? (
                                    // Render object as a structured view
                                    <Card 
                                        size="small" 
                                        style={{ 
                                            marginBottom: 4, 
                                            background: '#f9f9f9',
                                            width: '100%'
                                        }}
                                    >
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            {Object.entries(value).map(([key, objValue], objIndex) => (
                                                <div key={objIndex}>
                                                    <Text strong>{key}:</Text>
                                                    <div style={{ paddingLeft: 8 }}>
                                                        <Text code>{typeof objValue === 'object' ? JSON.stringify(objValue) : String(objValue)}</Text>
                                                    </div>
                                                </div>
                                            ))}
                                        </Space>
                                    </Card>
                                ) : (
                                    <Text code copyable style={{ fontSize: '14px' }}>
                                        {String(value)}
                                    </Text>
                                )}
                                {param.type === 'address[]' && renderAddressAvatar(addressData, 'from', value, {
                                    displayStyle: 'avatars',
                                    hideAddress: true,
                                    maxGlobalLabels: 3,
                                    maxUserLabels: 3,
                                    maxTotalLabels: 5,
                                    maxGlobalEntities: 3,
                                    maxUserEntities: 3,
                                    maxTotalEntities: 5
                                })}
                            </Space>
                        ))}
                    </Space>
                );
            } else if (typeof param.value === 'string') {
                // Handle string representation of arrays
                const values = param.value.split(',').map((v: string) => v.trim());
                return (
                    <Space direction="vertical" style={{ marginLeft: indent + 16 }}>
                        {values.map((value: string, index: number) => (
                            <Space key={index} style={{ width: '100%' }}>
                                <Text type="secondary">[{index}]:</Text>
                                <Text code copyable style={{ fontSize: '14px' }}>
                                    {value}
                                </Text>
                                {param.type === 'address[]' && renderAddressAvatar(addressData, 'from', value, {
                                    displayStyle: 'avatars',
                                    hideAddress: true,
                                    maxGlobalLabels: 3,
                                    maxUserLabels: 3,
                                    maxTotalLabels: 5,
                                    maxGlobalEntities: 3,
                                    maxUserEntities: 3,
                                    maxTotalEntities: 5
                                })}
                            </Space>
                        ))}
                    </Space>
                );
            }
        }

        // Handle bytes or string with long content
        if ((param.type === 'bytes' || param.type === 'string') && 
            typeof param.value === 'string' && 
            param.value.length > 100) {
            return (
                <Space direction="vertical" style={{ marginLeft: indent }}>
                    <Text code copyable style={{ fontSize: '14px' }}>
                        {param.value.substring(0, 100)}...
                    </Text>
                    <Button 
                        type="link" 
                        size="small" 
                        onClick={() => {
                            Modal.info({
                                title: `${param.name} (${param.type})`,
                                content: (
                                    <div style={{ 
                                        maxHeight: '60vh', 
                                        overflow: 'auto',
                                        marginTop: '16px',
                                        padding: '16px',
                                        background: '#f5f5f5',
                                        borderRadius: '4px'
                                    }}>
                                        <Text code copyable>
                                            {param.value}
                                        </Text>
                                    </div>
                                ),
                                width: 800,
                            });
                        }}
                    >
                        View full content
                    </Button>
                </Space>
            );
        }

        // Handle bytes or string with object content (try to parse JSON)
        if ((param.type === 'bytes' || param.type === 'string') && 
            typeof param.value === 'string' && 
            (param.value.startsWith('{') || param.value.startsWith('['))) {
            try {
                const parsedJson = JSON.parse(param.value);
                return (
                    <Space direction="vertical" style={{ marginLeft: indent }}>
                        <Text code copyable style={{ fontSize: '14px' }}>
                            {JSON.stringify(parsedJson, null, 2).substring(0, 100)}...
                        </Text>
                        <Button 
                            type="link" 
                            size="small" 
                            onClick={() => {
                                Modal.info({
                                    title: `${param.name} (${param.type})`,
                                    content: (
                                        <div style={{ 
                                            maxHeight: '60vh', 
                                            overflow: 'auto',
                                            marginTop: '16px',
                                            padding: '16px',
                                            background: '#f5f5f5',
                                            borderRadius: '4px'
                                        }}>
                                            <pre>
                                                {JSON.stringify(parsedJson, null, 2)}
                                            </pre>
                                        </div>
                                    ),
                                    width: 800,
                                });
                            }}
                        >
                            View formatted JSON
                        </Button>
                    </Space>
                );
            } catch (e) {
                // Not valid JSON, fall through to default handling
            }
        }

        // Handle bytes or string with object content (already an object)
        if ((param.type === 'bytes' || param.type === 'string') && 
            typeof param.value === 'object' && 
            param.value !== null) {
            return (
                <Space direction="vertical" style={{ marginLeft: indent }}>
                    <Text code copyable style={{ fontSize: '14px' }}>
                        {JSON.stringify(param.value, null, 2).substring(0, 100)}...
                    </Text>
                    <Button 
                        type="link" 
                        size="small" 
                        onClick={() => {
                            Modal.info({
                                title: `${param.name} (${param.type})`,
                                content: (
                                    <div style={{ 
                                        maxHeight: '60vh', 
                                        overflow: 'auto',
                                        marginTop: '16px',
                                        padding: '16px',
                                        background: '#f5f5f5',
                                        borderRadius: '4px'
                                    }}>
                                        <pre>
                                            {JSON.stringify(param.value, null, 2)}
                                        </pre>
                                    </div>
                                ),
                                width: 800,
                            });
                        }}
                    >
                        View formatted JSON
                    </Button>
                </Space>
            );
        }

        if (param.type === 'address') {
            return (
                <Space>
                    <Tooltip title={getTooltipTitleForParamType(addressData, param)}>
                        <Text code copyable style={{ fontSize: '14px' }}>
                            {param?.value}
                        </Text>
                    </Tooltip>
                    {renderAddressAvatar(addressData, 'from', param?.value)}
                </Space>
            );
        }

        if (param?.type === 'bytes' || param?.type === 'string') {
            return renderSpecializedDataType(param, addressData);
        }

        if (param?.type === 'bool') {
            return (
                <Tag color={param?.value ? 'green' : 'red'}>
                    {param?.value ? 'True' : 'False'}
                </Tag>
            );
        }

        // Handle any other object type
        if (typeof param?.value === 'object' && param?.value !== null) {
            try {
                return <Text code>{JSON.stringify(param.value)}</Text>;
            } catch (e) {
                return <Text code>[Complex Object]</Text>;
            }
        }

        return <Text code>{param?.value?.toString()}</Text>;
    };

    const openTopicDecoder = (topic: string) => {
        window.open(`https://cleanunicorn.github.io/santoku/#/decode/${topic}`, '_blank');
    };

    const renderDataField = (label: string, value: string) => {
        if (!value) return null;

        const isLongData = value.length > 66;
        const displayData = isLongData ? `${value.slice(0, 66)}...` : value;

        return (
            <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
                <Space>
                    <Text type="secondary">{label}: </Text>
                    <Tooltip title="Click to copy">
                        <Text 
                            style={{ 
                                fontSize: '12px',
                                cursor: 'pointer',
                                fontFamily: 'monospace'
                            }}
                            onClick={() => copyToClipboard(value)}
                        >
                            {displayData} <CopyOutlined style={{ fontSize: '12px' }} />
                        </Text>
                    </Tooltip>
                </Space>
                <Space size={4}>
                    <Button 
                        type="link" 
                        size="small"
                        icon={<LinkOutlined />}
                        onClick={() => openTopicDecoder(value)}
                    >
                        Decode
                    </Button>
                </Space>
            </Space>
        );
    };

    return (
        <>
            <Card size="small" bordered>
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    {/* Event Summary */}
                    {(log.decoded_event?.summary || log.summary) && (
                        <Space direction="vertical" size={0}>
                            <Text type="secondary">Summary</Text>
                            <Text strong>{log.decoded_event?.summary || log.summary}</Text>
                        </Space>
                    )}

                    {/* Contract & Event Details */}
                    <Space wrap>
                        {log.address && (
                            <Space direction="vertical" size={0}>
                                <Text type="secondary">Contract</Text>
                                <Space>
                                    <Text code copyable>{log.address}</Text>
                                    {renderAddressAvatar(addressData, 'from', log.address, 
                                        {
                                            maxGlobalLabels: 3,
                                            maxUserLabels: 3,
                                            maxTotalLabels: 5,
                                            maxGlobalEntities: 3,
                                            maxUserEntities: 3,
                                            maxTotalEntities: 5,
                                            displayStyle: 'tags' as 'avatars' | 'tags' | 'hover',
                                            hideAddress: true
                                        }
                                    )}
                                </Space>
                            </Space>
                        )}
                        {log.decoded_event?.label && (
                            <Space direction="vertical" size={0}>
                                <Text type="secondary">Event</Text>
                                <Tag color="purple">{log.decoded_event.label}</Tag>
                            </Space>
                        )}
                        {log.block_number && (
                            <Space direction="vertical" size={0}>
                                <Text type="secondary">Log Index</Text>
                                <Text>{`#${log.block_number}-${log.log_index}`}</Text>
                            </Space>
                        )}
                    </Space>

                    {/* Event Signature */}
                    {log.decoded_event?.signature && (
                        <>
                            <Divider style={{ margin: '8px 0' }} />
                            <Space direction="vertical" size={0}>
                                <Text type="secondary">Signature</Text>
                                <Text code copyable>{log.decoded_event.signature}</Text>
                            </Space>
                        </>
                    )}

                    {/* Event Parameters */}
                    {log.decoded_event?.params && log.decoded_event.params.length > 0 && (
                        <>
                            <Divider style={{ margin: '8px 0' }} />
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <Space align="center" style={{ justifyContent: 'space-between', width: '100%' }}>
                                    <Text type="secondary">Parameters</Text>
                                    {log.decoded_event.params.length > 15 && (
                                        <Button
                                            type="link"
                                            size="small"
                                            onClick={() => setShowAllParams(!showAllParams)}
                                        >
                                            {showAllParams ? 'Show Less' : 'Show All'}
                                        </Button>
                                    )}
                                </Space>
                                <Space direction="vertical" size={8} style={{ width: '100%' }}>
                                    {(showAllParams ? log.decoded_event.params : log.decoded_event.params.slice(0, 15))
                                        .map((param, index) => (
                                            <Card 
                                                key={index} 
                                                size="small" 
                                                bordered={false}
                                                style={{ background: '#fafafa' }}
                                            >
                                                <Space direction="vertical" size={2}>
                                                    <Space>
                                                        <Text type="secondary">{param.name}:</Text>
                                                        <Tag color={getTagColorByParamType(param.type)}>
                                                            {param.type}
                                                        </Tag>
                                                        {param.indexed && (
                                                            <Tag color="blue">indexed</Tag>
                                                        )}
                                                    </Space>
                                                    {renderParamValue(param)}
                                                </Space>
                                            </Card>
                                        ))}
                                </Space>
                            </Space>
                        </>
                    )}

                    {/* Raw Topics with Decode buttons */}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space direction="vertical" size={0} style={{ width: '100%' }}>
                        <Space align="center" style={{ justifyContent: 'space-between', width: '100%' }}>
                            <Text type="secondary">Raw Data</Text>
                            <Button
                                type="link"
                                size="small"
                                icon={<CodeOutlined />}
                                onClick={() => setShowRawData(true)}
                            >
                                View Raw Log
                            </Button>
                        </Space>
                        <Space direction="vertical" size={4} style={{ width: '100%' }}>
                            {renderDataField('Topic 0', log.topic0 || "")}
                            {renderDataField('Topic 1', log.topic1 || "")}
                            {renderDataField('Topic 2', log.topic2 || "")}
                            {renderDataField('Topic 3', log.topic3 || "")}
                            {renderDataField('Data', log.data || "")}
                        </Space>
                    </Space>
                </Space>
            </Card>

            {/* Raw Data Modal */}
            <Modal
                title="Raw Event Log Data"
                open={showRawData}
                onCancel={() => setShowRawData(false)}
                footer={null}
                width={800}
            >
                <pre style={{ 
                    maxHeight: '70vh', 
                    overflow: 'auto',
                    backgroundColor: '#f5f5f5',
                    padding: '16px',
                    borderRadius: '4px'
                }}>
                    {JSON.stringify(log, null, 2)}
                </pre>
            </Modal>
        </>
    );
}; 
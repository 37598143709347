import { GraphNode, GraphLink } from '../../../interfaces/graph';
import { addNodeIfNotExists } from './addNodeIfNotExists';

interface OffChainNode extends GraphNode {
    type: 'DON' | 'BRIDGE' | 'ORACLE';
    name: string;
    connected_chains: string[];
    status: 'active' | 'pending' | 'inactive';
    is_offchain: boolean;
    protocol?: string;
    description?: string;
}

interface OffChainLink extends GraphLink {
    type: 'cross_chain' | 'protocol_call' | 'message';
    protocol?: string;
    direction: 'inbound' | 'outbound';
}

// Known off-chain protocols and their configurations
const KNOWN_PROTOCOLS = {
    'CHAINLINK_CCIP': {
        addresses: ['0x89_0x447527956f3caf15d2d45029724a36508ef45b81', '0xa86a_0x2d750145c4045402b3006845813cd18593584cd8'],
        type: 'BRIDGE',
        name: 'Chainlink CCIP',
        description: 'Cross-Chain Interoperability Protocol'
    },
    'LAYERZERO': {
        addresses: ['0x66A71Dcef29A0fFBDBE3c6a460a3B5BC225Cd675'],
        type: 'BRIDGE',
        name: 'LayerZero',
        description: 'Omnichain Interoperability Protocol'
    },
};

export function generateOffChainElements(transactionData: any, graphData: { nodes: GraphNode[], links: GraphLink[] }) {
    const offChainNodes: OffChainNode[] = [];
    const offChainLinks: OffChainLink[] = [];
    
    // Track which chains are connected by each protocol
    const protocolConnections = new Map<string, Set<string>>();
    
    // Track processed transactions to avoid duplicates
    const processedTransactions = new Set<string>();
    
    // Track block timestamps for each protocol
    const protocolTimestamps = new Map<string, Set<string>>();
    
    // Process nodes and links to identify off-chain protocols and their connections
    graphData.links.forEach((link: any) => {
        // Skip if we've already processed this transaction
        if (processedTransactions.has(link.transaction_hash)) {
            return;
        }
        
        for (const [protocolKey, protocol] of Object.entries(KNOWN_PROTOCOLS)) {
            const sourceAddress = link.source.chain_id ? `${link.source.chain_id}_${link.source.address}` : link.source;
            
            // Only check the source address for protocol matching
            if (protocol.addresses.includes(sourceAddress)) {
                // Track connected chains for this protocol
                if (!protocolConnections.has(protocolKey)) {
                    protocolConnections.set(protocolKey, new Set());
                }
                
                // Track block timestamps for this protocol
                if (!protocolTimestamps.has(protocolKey)) {
                    protocolTimestamps.set(protocolKey, new Set());
                }
                if (link.block_timestamp) {
                    protocolTimestamps.get(protocolKey)?.add(link.block_timestamp);
                }
                
                if (link.source.chain_id) protocolConnections.get(protocolKey)?.add(link.source.chain_id);
                if (link.target.chain_id) protocolConnections.get(protocolKey)?.add(link.target.chain_id);
                
                // Create off-chain node for this protocol if it doesn't exist yet
                const offChainNodeId = `offchain_${protocolKey.toLowerCase()}`;
                let offChainNode = offChainNodes.find(n => n.id === offChainNodeId);
                
                if (!offChainNode) {
                    offChainNode = {
                        id: offChainNodeId,
                        type: protocol.type as 'DON' | 'BRIDGE' | 'ORACLE',
                        name: protocol.name,
                        description: protocol.description,
                        protocol: protocolKey,
                        connected_chains: Array.from(protocolConnections.get(protocolKey) || []),
                        status: 'active',
                        is_offchain: true,
                        chain_id: 'offchain',
                        address: protocol.addresses[0].split('_')[1],
                        x: 0,
                        y: 0,
                        friendly_name: protocol.name,
                        block_timestamps: Array.from(protocolTimestamps.get(protocolKey) || []),
                        outgoing_count: 0,
                        incoming_count: 0,
                        total_value: 0
                    };
                    offChainNodes.push(offChainNode);
                } else {
                    // Update block_timestamps for existing node
                    offChainNode.block_timestamps = Array.from(protocolTimestamps.get(protocolKey) || []);
                }

                // Create single off-chain link from source to off-chain node
                const sourceToOffChainLink: OffChainLink = {
                    // Copy other relevant properties from original link
                    ...link,                    
                    id: `${link.transaction_hash}_to_offchain`,
                    source: link.source,
                    target: offChainNode,
                    type: 'cross_chain',
                    protocol: protocolKey,
                    direction: 'outbound',
                };

                offChainLinks.push(sourceToOffChainLink);


                // First process CCIP transactions
                transactionData.forEach((tx: any) => {
                    if (tx.decoded_transaction.decoded_events) {
                        const eventsArray = Object.values(tx.decoded_transaction.decoded_events);
            
                        const ccipEvent: any= eventsArray.find((event: any) => 
                            event?.decoded_event?.label === 'CCIPSendRequested'
                        );

                        if (ccipEvent?.decoded_event?.params?.[0]?.value) {
                            const messageParams = ccipEvent.decoded_event.params[0].value;
                            const fromAddress = messageParams.find((p: any) => p.name === 'param_1')?.value;
                            const toAddress = messageParams.find((p: any) => p.name === 'param_2')?.value;
                            const toChainId = messageParams.find((p: any) => p.name === 'param_3')?.value;
        
                            const matchingLink = graphData.links.find(link => 
                                link.hash === tx.hash && 
                                link.chain_id === tx.chain_id
                            );

                            if (matchingLink && fromAddress && toAddress && toChainId) {
                                // Create off-chain node for CCIP if it doesn't exist yet
                                const protocolKey = 'CHAINLINK_CCIP';
                                const protocol = KNOWN_PROTOCOLS[protocolKey];
                                const offChainNodeId = `offchain_${protocolKey.toLowerCase()}`;
                                let offChainNode = offChainNodes.find(n => n.id === offChainNodeId);
                                   
                                if (!offChainNode) {
                                    offChainNode = {
                                        id: offChainNodeId,
                                        type: protocol.type as 'DON' | 'BRIDGE' | 'ORACLE',
                                        name: protocol.name,
                                        description: protocol.description,
                                        protocol: protocolKey,
                                        connected_chains: [],
                                        status: 'active',
                                        is_offchain: true,
                                        chain_id: 'offchain',
                                        address: protocol.addresses[0].split('_')[1],
                                        x: 0,
                                        y: 0,
                                        friendly_name: protocol.name,
                                        block_timestamps: [],
                                        outgoing_count: 0,
                                        incoming_count: 0,
                                        total_value: 0
                                    };
                                    offChainNodes.push(offChainNode);
                                }

                                const fromNodeId = addNodeIfNotExists(tx, graphData.nodes, tx.chain_id, fromAddress);
                                const toNodeId = addNodeIfNotExists(tx, graphData.nodes, toChainId, toAddress);
                                
                                // map CCIP chain selector to chain id
                                const chainIdMap = {
                                    "0x2966": "0x1",
                                    "0x580": "0xcc",
                                }
                                const moralisChainId = chainIdMap["0x" + toChainId as keyof typeof chainIdMap];
                                if(moralisChainId){
                                    const newNode = {
                                        ...graphData.nodes[toNodeId as any],
                                        id: moralisChainId + "_" + toAddress,
                                        chain_id: moralisChainId,
                                        address: toAddress,
                                        type: 'BRIDGE' as 'BRIDGE' | 'DON' | 'ORACLE',
                                        name: toAddress,
                                        description: 'CCIP Target',
                                        protocol: toAddress,
                                        connected_chains: [],
                                        status: 'active' as 'active' | 'pending' | 'inactive',
                                        is_offchain: false,
                                        x: 0,
                                        y: 0,
                                        friendly_name: toAddress,
                                        block_timestamps: [],
                                        outgoing_count: 0,
                                        incoming_count: 0,
                                    }
                                    offChainNodes.push(newNode);

                                    // // Create source to off-chain link
                                    // const sourceToOffChainLink: OffChainLink = {
                                    //     ...matchingLink,
                                    //     id: `${tx.transaction_hash}_to_offchain`,
                                    //     source: matchingLink.source,
                                    //     target: offChainNode,
                                    //     type: 'cross_chain',
                                    //     protocol: protocolKey,
                                    //     direction: 'outbound',
                                    // };

                                    // Create off-chain to target link
                                    const offChainToTargetLink: OffChainLink = {
                                        ...matchingLink,
                                        id: `${tx.transaction_hash}_from_offchain`,
                                        source: offChainNode,
                                        target: newNode.id,
                                        type: 'cross_chain',
                                        protocol: protocolKey,
                                        direction: 'inbound',
                                    };

                                    offChainLinks.push(sourceToOffChainLink, offChainToTargetLink);
                                    processedTransactions.add(tx.transaction_hash);
                                } else{
                                    console.log("No moralis chain id found for", toChainId);
                                }
                            }
                        }
                    }
                });

                // Mark this transaction as processed
                processedTransactions.add(link.transaction_hash);
                break; // Exit the protocol loop once we've found a match
            }
        }
    });
    
    // Create off-chain nodes for each identified protocol
    protocolConnections.forEach((connectedChains, protocolKey) => {
        if(KNOWN_PROTOCOLS[protocolKey as keyof typeof KNOWN_PROTOCOLS].addresses[0].split('_').length > 1) return;

        const protocol = KNOWN_PROTOCOLS[protocolKey as keyof typeof KNOWN_PROTOCOLS];
        
        const offChainNode: OffChainNode = {
            id: `offchain_${protocolKey.toLowerCase()}`,
            type: protocol.type as 'DON' | 'BRIDGE' | 'ORACLE',
            name: protocol.name,
            description: protocol.description,
            protocol: protocolKey,
            connected_chains: Array.from(connectedChains),
            status: 'active',
            is_offchain: true,
            // Add any other necessary GraphNode fields
            chain_id: 'offchain',
            address: protocol.addresses[0], // Use first address as identifier
            x: 0, // Will be positioned by force graph
            y: 0,
            friendly_name: protocol.name,
            block_timestamps: Array.from(protocolTimestamps.get(protocolKey) || []),
            outgoing_count: 0,
            incoming_count: 0,
            total_value: 0
        };
        
        offChainNodes.push(offChainNode);
    });

    return {
        nodes: offChainNodes,
        links: offChainLinks
    };
}

// Helper function to check if a node is an off-chain node
export function isOffChainNode(node: GraphNode): node is OffChainNode {
    return 'is_offchain' in node && node.is_offchain === true;
}

// Helper function to check if a link is an off-chain link
export function isOffChainLink(link: GraphLink): link is OffChainLink {
    return 'protocol' in link;
}
import { Modal, Space, Avatar, Typography, List, Card, Tag, Empty, Spin } from 'antd';
import { INFT, INFTCollection } from "../../../interfaces/address.d";
import { ChainAvatar } from './StatsTab';
import { NFTDetailModal } from './NFTDetailModal';
import { useEffect, useState } from 'react';
import { useOne } from '@refinedev/core';
import { getBackgroundStyle, getNFTImage } from '../../../utils/imageUtils';

const { Text, Link } = Typography;

interface NFTCollectionDetailModalProps {
    visible: boolean;
    onClose: () => void;
    collection: INFTCollection & { chainId: string };
    walletAddress: string;
}

interface NFTsResponse {
    request_id: string;
    results: {
        [chainId: string]: {
            data: INFT[];
            cursor: string | null;
            has_more: boolean;
        };
    };
    errors: Record<string, any>;
}

export const NFTCollectionDetailModal: React.FC<NFTCollectionDetailModalProps> = ({
    visible,
    onClose,
    collection,
    walletAddress,
}) => {
    const [selectedNFT, setSelectedNFT] = useState<INFT | null>(null);
    const [nftImages, setNftImages] = useState<Record<string, string>>({});
    const [isLoadingImages, setIsLoadingImages] = useState(true);

    // Fetch NFTs for this collection
    const { data: nftsData, isLoading, refetch } = useOne<NFTsResponse>({
        resource: "nfts",
        id: walletAddress,
        meta: {
            address: walletAddress,
            chain_ids: [collection.chainId],
            limit: 100,
            operation: "getNFTs",
        },
        queryOptions: {
            enabled: true,
            queryKey: ["nfts", walletAddress, collection.chainId],
        }
    });

    // Extract NFTs from the response
    const getNFTs = () => {
        const data = nftsData?.data?.results?.[collection.chainId]?.data;
        if (!data) return [];
        return Array.isArray(data) ? data : [data];
    };

    const nfts = getNFTs();

    useEffect(() => {
        let mounted = true; // For cleanup

        // Load imgetBackgroundStyleages for all NFTs in the list
        const loadImages = async () => {
            if (!nfts?.length) return; // Guard against empty nfts
            
            setIsLoadingImages(true);
            try {
                const imagePromises = nfts.map(async (nft) => {
                    try {
                        const imageUrl = await getNFTImage(nft, collection);
                        return [nft.token_id, imageUrl] as [string, string];
                    } catch {
                        return [nft.token_id, '/images/nft-placeholder.png'] as [string, string];
                    }
                });

                const loadedImages = await Promise.all(imagePromises);
                
                // Only update state if component is still mounted
                if (mounted) {
                    setNftImages(Object.fromEntries(loadedImages));
                    setIsLoadingImages(false);
                }
            } catch (error) {
                console.error('Failed to load NFT images:', error);
                if (mounted) {
                    setIsLoadingImages(false);
                }
            }
        };

        loadImages();

        // Cleanup function
        return () => {
            mounted = false;
        };
    }, [nfts?.length, collection?.token_address]); // Only depend on length and collection address

    return (
        <>
            <Modal
                title={
                    <Space>
                        <Avatar src={collection.collection_logo} size="large" />
                        <Space direction="vertical" size={0}>
                            <Text strong>{collection.name}</Text>
                            <Space>
                                <ChainAvatar chainId={collection.chainId} size="small" />
                                <Tag color="blue">{collection.symbol}</Tag>
                                {collection.verified_collection && (
                                    <Tag color="green">Verified</Tag>
                                )}
                            </Space>
                        </Space>
                    </Space>
                }
                open={visible}
                onCancel={onClose}
                width={1000}
                footer={null}
            >
                <Space direction="vertical" style={{ width: '100%' }} size="large">
                    {isLoading ? (
                        <div style={{ textAlign: 'center', padding: '40px' }}>
                            <Spin size="large" />
                        </div>
                    ) : nfts && nfts.length > 0 ? (
                        <List
                            grid={{ 
                                gutter: 16,
                                xs: 1,
                                sm: 2,
                                md: 3,
                                lg: 3,
                                xl: 4,
                            }}
                            dataSource={nfts}
                            renderItem={(nft: INFT) => (
                                <List.Item>
                                    <Card
                                        hoverable
                                        cover={
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    borderRadius: '8px',
                                                    ...getBackgroundStyle(nftImages[nft.token_id] || '/images/nft-placeholder.png')
                                                }}
                                            />
                                        }
                                        onClick={() => setSelectedNFT(nft)}
                                    >
                                        <Card.Meta
                                            title={
                                                <Space>
                                                    <Text strong>{nft.name}</Text>
                                                    <Text type="secondary">#{nft.token_id}</Text>
                                                </Space>
                                            }
                                            description={
                                                <Space direction="vertical" size={0}>
                                                    {nft.rarity_rank && (
                                                        <Space>
                                                            <Tag color="blue">Rank #{nft.rarity_rank}</Tag>
                                                            {nft.rarity_label && (
                                                                <Tag color="purple">{nft.rarity_label}</Tag>
                                                            )}
                                                        </Space>
                                                    )}
                                                    <Space>
                                                        <Tag>{nft.contract_type}</Tag>
                                                        {nft.amount && nft.amount !== "1" && (
                                                            <Tag color="green">x{nft.amount}</Tag>
                                                        )}
                                                    </Space>
                                                    {nft.last_token_uri_sync && (
                                                        <Text type="secondary" style={{ fontSize: '12px' }}>
                                                            Last updated: {new Date(nft.last_token_uri_sync).toLocaleDateString()}
                                                        </Text>
                                                    )}
                                                </Space>
                                            }
                                        />
                                    </Card>
                                </List.Item>
                            )}
                        />
                    ) : (
                        <Empty 
                            description={
                                <Space direction="vertical">
                                    <Text>No NFTs found in this collection</Text>
                                    <Text type="secondary">
                                        Collection Address: {collection.token_address}
                                    </Text>
                                </Space>
                            } 
                        />
                    )}
                </Space>
            </Modal>

            {selectedNFT && (
                <NFTDetailModal
                    visible={!!selectedNFT}
                    onClose={() => setSelectedNFT(null)}
                    nft={selectedNFT}
                    chainId={collection.chainId}
                />
            )}
        </>
    );
}; 
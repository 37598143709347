import React, { useEffect, useState } from 'react';


import { IResourceComponentsProps, useInvalidate, useOne, useUpdate } from "@refinedev/core";

import {
    List,
    useTable,
    useModalForm,
    RefreshButton,
    CreateButton,
    DeleteButton,
    ShowButton,
} from "@refinedev/antd";

import { Form, Input, Modal, Avatar, Card, Skeleton, Switch, Col, Row, Spin, Space, Tooltip, Select, Tag, Pagination, Alert, message } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined, SettingOutlined, CloseOutlined, CheckOutlined, SearchOutlined, ApiOutlined, CodeOutlined, ShopOutlined, ArrowRightOutlined, ThunderboltOutlined, AppstoreOutlined, RocketOutlined, SecurityScanOutlined, BlockOutlined } from '@ant-design/icons';

import { IIntegration } from "interfaces";
import Typography from 'antd/lib/typography';
import { Link } from 'react-router-dom';

import { List as ListAntd } from "antd";
import Meta from 'antd/es/card/Meta';

const { Title, Paragraph, Text } = Typography;

export const IntegrationsList: React.FC<IResourceComponentsProps> = () => {
    const cdn_domain_name = process.env.REACT_APP_CDN_URL
    
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = React.useState<Array<boolean>>([]);
    const [integrations, setIntegrations] = React.useState<Array<IIntegration>>();
    const [filteredIntegrations, setFilteredIntegrations] = useState<IIntegration[]>([]);
    const pageSize = 9; // 3x3 grid

    const {
        tableQueryResult,
    } = useTable<IIntegration>({
        meta: { 
            "last_evaluated_key": "null",
            "limit": "null",
            "search": "null"
        },
    });
    
    let [editIntegration, setEditIntegration] = React.useState<any>() 
    let [editIntegrationChecked, setEditIntegrationChecked] = React.useState<boolean>(false) 
    let [enableDetailedEdit, setEnableDetailedEdit] = React.useState<boolean>(false) 

    // Show integrations task settings Modal
    const {
        modalProps: showIntegrationSettingsModalProps,
        formProps: showIntegrationSettingsFormProps,
        show: showIntegrationSettingsModalShow,
        setId,
        onFinish,
        close: closeUpdateIntegrationModal,
    } = useModalForm({
        id: editIntegration?.id,
        action: "edit",
        resource: "integrations",
        warnWhenUnsavedChanges: false,
        syncWithLocation: false,
        disableServerSideValidation: true,
        onMutationSuccess: (data, variables, context, isAutoSave) => {
            closeUpdateIntegrationModal()
            refreshTableResults()
            let items = [...isLoading];
            for (let i = 0; i < items.length; i++) {
                items[i] = false
              }
            
            setIsLoading(items) 
            setEnableDetailedEdit(false)
        },
        onMutationError: (data, variables, context, isAutoSave) => {
            let items = [...isLoading];
            for (let i = 0; i < items.length; i++) {
                items[i] = false
              }
            
            setIsLoading(items) 
        },
    });

    const onFinishUpdateIntegration = (values : any ) => {
        console.log("hello finish")
        console.log(values)
        console.log("hello Integration")
        console.log(editIntegration)
        let new_obj = {}
        if(values.updateCheckedOnly) {
            console.log("Only updating checked value")

            new_obj = {
                id: `${values.record.id}`,
                enabled: values.enabled,
            }
        } else{
            let new_type = values.type != 'undefined' && typeof(values.type) !== 'undefined' && values.type !== undefined ? values.type : editIntegration.type
            let new_stage = values.stage != 'undefined' && typeof(values.stage) !== 'undefined' && values.stage !== undefined  ? values.stage : editIntegration.stage
            let new_name = values.name != 'undefined' && typeof(values.name) !== 'undefined' && values.name !== undefined  ? values.name : editIntegration.name
            let new_description = values.description != 'undefined' && typeof(values.description) !== 'undefined' && values.description !== undefined  ? values.description : editIntegration.description
            let new_timeout_seconds = values.timeout_seconds != 'undefined' && typeof(values.timeout_seconds) !== 'undefined' && values.timeout_seconds !== undefined  ? values.timeout_seconds : editIntegration.timeout_seconds
            let new_heart_beat_seconds = values.heart_beat_seconds != 'undefined' && typeof(values.heart_beat_seconds) !== 'undefined' && values.heart_beat_seconds !== undefined  ? values.heart_beat_seconds : editIntegration.heart_beat_seconds
            let new_context = values.context != 'undefined' && typeof(values.context) !== 'undefined' && values.context !== undefined  ? values.context : editIntegration.context
            let new_arn = values.arn != 'undefined' && typeof(values.arn) !== 'undefined' && values.arn !== undefined  ? values.arn : editIntegration.arn
            let new_field_name = values.field_name != 'undefined' && typeof(values.field_name) !== 'undefined' && values.field_name !== undefined  ? values.field_name : editIntegration.field_name
            let new_enabled = Boolean(editIntegrationChecked) // should always use this one
    
            if (typeof new_context === 'object' || new_context instanceof Object){
                new_context = JSON.stringify(new_context)
            } 
    
            new_obj = {
                id: `${editIntegration.id}`,
                type: `${new_type}`,
                stage: `${new_stage}`,
                name: `${new_name}`,
                description: `${new_description}`,
                timeout_seconds: `${new_timeout_seconds}`,
                heart_beat_seconds: `${new_heart_beat_seconds}`,
                context: `${new_context}`,
                arn: `${new_arn}`,
                field_name: `${new_field_name}`,
                enabled: `${new_enabled}`,
            }
        }


        console.log("hello obj")
        console.log(new_obj)
        onFinish?.(new_obj);

        close();
    };


    

    const editIntegrationSettings = async (record: IIntegration) => {
        console.log("Edit task settings")
        if(record){
            console.log(record)
            setEnableDetailedEdit(false)
            setId(record.id);
            setEditIntegration(record)
            setEditIntegrationChecked(record.enabled)
            showIntegrationSettingsModalShow(record.id)
        }

      };

    const handleDetailedScreenChangeIntegrationEnabled = (checked: boolean) => {
        editIntegration.enabled = checked
        setEditIntegrationChecked(checked)
        setEnableDetailedEdit(checked)
    };

    const onChangeMainScreenEnabledIntegration = (checked: boolean, index: any, record: IIntegration) => {
        // 1. Make a shallow copy of the items
        let items = [...isLoading];
        items[index] = true
        setIsLoading(items) 

        onFinishUpdateIntegration({
            "updateCheckedOnly": "updateCheckedOnly",
            "enabled": checked,
            "record": record
        })
    };
    
    const [isLoadingTable, setIsLoadingTable] = React.useState<boolean>(false);
    const refreshTableResults = () => {
        if(tableQueryResult?.data){
            setIntegrations([])
        }
        
        tableQueryResult.refetch()
    };
    
    
    useEffect(() => {
        if(tableQueryResult){
            if(tableQueryResult.data){
                setIntegrations(tableQueryResult?.data.data)
            }
            setIsLoadingTable(tableQueryResult.isRefetching)
        }
       
      }, [editIntegration, tableQueryResult, integrations, enableDetailedEdit]);

    useEffect(() => {
        if (tableQueryResult?.data?.data) {
            const integrations = tableQueryResult.data.data;
            const filtered = integrations.filter(integration => 
                integration.name.toLowerCase().includes(searchText.toLowerCase()) ||
                integration.description.toLowerCase().includes(searchText.toLowerCase()) ||
                integration.integration_type.toLowerCase().includes(searchText.toLowerCase()) ||
                integration.trigger_on.some(trigger => 
                    trigger.chain.toLowerCase().includes(searchText.toLowerCase()) ||
                    trigger.address.toLowerCase().includes(searchText.toLowerCase())
                )
            );
            setFilteredIntegrations(filtered);
        }
    }, [tableQueryResult?.data?.data, searchText]);

    const paginatedIntegrations = filteredIntegrations.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    const { mutate: update } = useUpdate();
    const [updatingIds, setUpdatingIds] = useState<string[]>([]);

    const handleToggleEnabled = (checked: boolean, integration: IIntegration) => {
        setUpdatingIds(prev => [...prev, integration.id]);
        
        update(
            {
                resource: "integrations",
                id: integration.id,
                values: {
                    enabled: checked,
                    updateCheckedOnly: true
                },
            },
            {
                onSuccess: () => {
                    message.success(`Integration ${checked ? 'enabled' : 'disabled'} successfully`);
                    tableQueryResult.refetch();
                    setUpdatingIds(prev => prev.filter(id => id !== integration.id));
                },
                onError: (error) => {
                    message.error(`Failed to ${checked ? 'enable' : 'disable'} integration`);
                    setUpdatingIds(prev => prev.filter(id => id !== integration.id));
                },
            },
        );
    };

    return (
        <div style={{ padding: '24px' }}>
            {/* Header Section */}
            <Space direction="vertical" size="large" style={{ width: '100%', marginBottom: '32px' }}>
                <Title level={2} style={{ margin: 0 }}>Integrations</Title>
                <Row gutter={[16, 16]}>
                    {/* Main Explanation Card */}
                    <Col span={16}>
                        <Card style={{ height: '100%' }}>
                            <Space direction="vertical" size="large">
                                <div>
                                    <Title level={4} style={{ margin: 0 }}>
                                        <ThunderboltOutlined style={{ color: '#1890ff', marginRight: 8 }} />
                                        What are Integrations?
                                    </Title>
                                    <Paragraph style={{ margin: '16px 0' }}>
                                        Integrations are powerful automated workflows that enhance your authentication pipeline. 
                                        They execute custom logic at specific stages of user authentication, allowing you to extend 
                                        and customize the default behavior.
                                    </Paragraph>
                                </div>

                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <Card size="small" bordered={false} style={{ background: '#f0f5ff', height: '100%' }}>
                                            <Space direction="vertical" size="small">
                                                <Space>
                                                    <BlockOutlined style={{ fontSize: '20px', color: '#1890ff' }}/>
                                                    <Text strong>Blockchain Triggers</Text>
                                                </Space>
                                                <Text type="secondary">
                                                    Respond to specific chain events and addresses
                                                </Text>
                                            </Space>
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card size="small" bordered={false} style={{ background: '#f6ffed', height: '100%' }}>
                                            <Space direction="vertical" size="small">
                                                <Space>
                                                    <SettingOutlined style={{ fontSize: '20px', color: '#52c41a' }}/>
                                                    <Text strong>Multi-stage Pipeline</Text>
                                                </Space>
                                                <Text type="secondary">
                                                    Pre-process → Process → Post-process
                                                </Text>
                                            </Space>
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card size="small" bordered={false} style={{ background: '#f9f0ff', height: '100%' }}>
                                            <Space direction="vertical" size="small">
                                                <Space>
                                                    <AppstoreOutlined style={{ fontSize: '20px', color: '#722ed1' }}/>
                                                    <Text strong>Flexible Sources</Text>
                                                </Space>
                                                <Text type="secondary">
                                                    Marketplace or custom development
                                                </Text>
                                            </Space>
                                        </Card>
                                    </Col>
                                </Row>
                            </Space>
                        </Card>
                    </Col>

                    {/* Quick Start Guide Card */}
                    <Col span={8}>
                        <Card style={{ height: '100%' }}>
                            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                                <Title level={4} style={{ margin: 0 }}>
                                    <RocketOutlined style={{ color: '#722ed1', marginRight: 8 }} />
                                    Quick Start Guide
                                </Title>
                                <Space direction="vertical" style={{ width: '100%' }} size="middle">
                                    {[
                                        {
                                            title: 'Choose Integration',
                                            description: 'Browse marketplace or create custom'
                                        },
                                        {
                                            title: 'Configure Triggers',
                                            description: 'Set chain and address triggers'
                                        },
                                        {
                                            title: 'Activate',
                                            description: 'Enable and monitor your integration'
                                        }
                                    ].map((step, index) => (
                                        <Card key={index} size="small" bordered={false} style={{ background: '#f5f5f5' }}>
                                            <Space direction="vertical" size="small" style={{ width: '100%' }}>
                                                <Space>
                                                    <Tag color="blue">{index + 1}</Tag>
                                                    <Text strong>{step.title}</Text>
                                                </Space>
                                                <Text type="secondary" style={{ marginLeft: 24 }}>
                                                    {step.description}
                                                </Text>
                                            </Space>
                                        </Card>
                                    ))}
                                </Space>
                            </Space>
                        </Card>
                    </Col>
                </Row>
            </Space>

            {/* Integrations List Section */}
            <Card
                title={
                    <Space size="middle" style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Title level={4} style={{ margin: 0 }}>Available Integrations</Title>
                        <Space>
                            <Input
                                placeholder="Search integrations..."
                                prefix={<SearchOutlined />}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    setCurrentPage(1);
                                }}
                                style={{ width: 300 }}
                            />
                            <RefreshButton onClick={() => tableQueryResult.refetch()} />
                            <CreateButton />
                        </Space>
                    </Space>
                }
                style={{ marginBottom: '24px' }}
            >
                <Row gutter={[24, 24]}>
                    {tableQueryResult.isLoading || tableQueryResult.isRefetching || tableQueryResult.isFetching ? (
                        // Loading skeleton cards with fixed height
                        [...Array(pageSize)].map((_, index) => (
                            <Col xs={24} sm={24} md={12} lg={8} key={`skeleton-${index}`}>
                                <Card style={{ height: '400px' }}>  {/* Fixed height for skeleton */}
                                    <Skeleton loading={true} avatar active />
                                </Card>
                            </Col>
                        ))
                    ) : paginatedIntegrations.map((record: IIntegration) => (
                        <Col xs={24} sm={24} md={12} lg={8} key={record.id}>
                            <Card
                                hoverable
                                style={{
                                    height: '400px',  // Fixed height for all cards
                                    display: 'flex',
                                    flexDirection: 'column',
                                    backgroundColor: '#fff',
                                    borderRadius: '8px',
                                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.03)',
                                    border: '1px solid #f0f0f0'
                                }}
                                bodyStyle={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflow: 'hidden'  // Prevent content from expanding card
                                }}
                                actions={[
                                    <Switch
                                        key="toggle"
                                        checked={record.enabled}
                                        loading={updatingIds.includes(record.id)}
                                        onChange={(checked) => handleToggleEnabled(checked, record)}
                                        checkedChildren="Enabled"
                                        unCheckedChildren="Disabled"
                                    />,
                                    <ShowButton hideText size="small" recordItemId={record.id} />
                                ]}
                            >
                                <Skeleton loading={false} avatar active>
                                    <div style={{ marginBottom: 16 }}>  {/* Header section */}
                                        <Meta
                                            avatar={
                                                <Avatar 
                                                    size={64}
                                                    src={`https://${cdn_domain_name}/public/GET/cdn/integrations/logos/png/${record.id}.png`}
                                                />
                                            }
                                            title={
                                                <Space align="center" style={{ marginBottom: 8 }}>
                                                    <Text strong style={{ 
                                                        fontSize: '16px',
                                                        display: 'inline-block',
                                                        maxWidth: '150px',  // Limit title width
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }}>
                                                        {record.name}
                                                    </Text>
                                                    <Tag color={record.enabled ? "success" : "default"}>
                                                        {record.enabled ? "Active" : "Inactive"}
                                                    </Tag>
                                                </Space>
                                            }
                                            description={
                                                <Text type="secondary" style={{ 
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 2,
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    height: '40px'  // Fixed height for description
                                                }}>
                                                    {record.description}
                                                </Text>
                                            }
                                        />
                                    </div>

                                    <div style={{ 
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        overflow: 'hidden'
                                    }}>
                                        <Card 
                                            size="small" 
                                            title="Execution Flow"
                                            style={{ 
                                                backgroundColor: '#fafafa',
                                                marginBottom: 12,
                                                height: '80px'  // Fixed height for execution flow
                                            }}
                                            bodyStyle={{
                                                overflow: 'auto'
                                            }}
                                        >
                                            <Space wrap>
                                                {record.integration_stages.map((stage, idx) => (
                                                    <Tag 
                                                        key={stage} 
                                                        color={
                                                            stage === 'pre-process' ? 'blue' :
                                                            stage === 'process' ? 'purple' :
                                                            'green'
                                                        }
                                                    >
                                                        {idx > 0 && <ArrowRightOutlined style={{ marginRight: 8 }} />}
                                                        {stage}
                                                    </Tag>
                                                ))}
                                            </Space>
                                        </Card>
                                        
                                        <Card 
                                            size="small" 
                                            title="Triggers"
                                            style={{ 
                                                backgroundColor: '#fafafa',
                                                marginBottom: 12,
                                                flex: 1,  // Take remaining space
                                                maxHeight: '160px'  // Maximum height for triggers
                                            }}
                                            bodyStyle={{
                                                padding: '0 12px',
                                                overflow: 'auto'  // Scrollable if content exceeds
                                            }}
                                        >
                                            <ListAntd
                                                itemLayout="horizontal"
                                                dataSource={record.trigger_on}
                                                renderItem={(trigger) => (
                                                    <ListAntd.Item style={{ padding: '8px 0' }}>
                                                        <ListAntd.Item.Meta
                                                            avatar={
                                                                <Avatar 
                                                                    size="small"
                                                                    src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${trigger.chain_id}.svg`}
                                                                />
                                                            }
                                                            title={
                                                                <Space>
                                                                    <Text strong style={{ fontSize: '12px' }}>{trigger.chain}</Text>
                                                                    <Tag color="blue" style={{ fontSize: '11px' }}>{trigger.chain_id}</Tag>
                                                                </Space>
                                                            }
                                                            description={
                                                                <Text copyable style={{ fontSize: '11px' }}>
                                                                    {trigger.address}
                                                                </Text>
                                                            }
                                                        />
                                                    </ListAntd.Item>
                                                )}
                                            />
                                        </Card>

                                        <Space wrap>
                                            <Tag color="cyan">{record.integration_type}</Tag>
                                            <Tag color="orange">{record.environment}</Tag>
                                        </Space>
                                    </div>
                                </Skeleton>
                            </Card>
                        </Col>
                    ))}
                </Row>
                
                <Row justify="end" style={{ marginTop: 24 }}>
                    <Pagination
                        current={currentPage}
                        total={filteredIntegrations.length}
                        pageSize={pageSize}
                        onChange={setCurrentPage}
                        showSizeChanger={false}
                        showTotal={(total) => `Total ${total} integrations`}
                    />
                </Row>
            </Card>
        </div>
    );
};

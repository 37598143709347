import { Avatar } from 'antd';

interface EntityIconProps {
    name: string;
    color?: string;
    logo?: string;
}

export const EntityIcon: React.FC<EntityIconProps> = ({ name, color, logo }) => {
    if (logo) {
        return <Avatar src={logo} />;
    }

    return (
        <Avatar 
            style={{ 
                backgroundColor: color ? `#${color}` : '#666666',
            }}
        >
            {name.charAt(0).toUpperCase()}
        </Avatar>
    );
}; 
import { Space, Avatar, Typography, Tag, Tooltip } from 'antd';
import type { TableColumnType } from 'antd';
import type { Transaction } from '../types';
import { UserOutlined, CrownOutlined } from '@ant-design/icons';
import { Key } from 'react';

const { Text } = Typography;

export const getSharedWithColumn = (cdn_domain_name: string): TableColumnType<Transaction> => ({
    title: 'Shared With',
    dataIndex: 'shared_with',
    key: 'shared_with',
    width: 300,
    filters: [
        { text: 'Owner Only', value: 'owner_only' },
        { text: 'Shared', value: 'shared' },
    ],
    onFilter: (value: boolean | Key, record: Transaction) => {
        const filterValue = value.toString();
                
        if (filterValue === 'owner_only') {
            return !record.shared_with || record.shared_with.length <= 1;
        }
        if (filterValue === 'shared') {
            if (record.shared_with && record.shared_with.length > 1) {
                return true;
            }
            return false;
        }
        return true;
    },    
    sorter: (a: Transaction, b: Transaction) => {
        // Sort by number of people it's shared with
        const aCount = a.shared_with?.length || 0;
        const bCount = b.shared_with?.length || 0;
        return aCount - bCount;
    },
    render: (_: any, record: Transaction) => {
        const isOwner = (email: string) => record.owner === email;

        return (
            <Space direction="vertical" style={{ width: '100%', minWidth: 300 }}>
                {/* Owner */}
                {record.owner && (
                    <Space>
                        <Avatar 
                            icon={<CrownOutlined/>} 
                            style={{ backgroundColor: '#ffd700' }}
                        />
                        <Space direction="vertical" size={0}>
                            <Text strong>Owner</Text>
                            <Text type="secondary">{record.owner}</Text>
                        </Space>
                    </Space>
                )}

                {/* Shared Users */}
                {record.shared_with && record.shared_with.length > 0 && (
                    <Space wrap>
                        {record.shared_with
                            .filter((user: any) => !isOwner(user.email)) // Don't show owner twice
                            .map((user: any, index: number) => (
                                <Tooltip 
                                    key={user.id} 
                                    title={`Shared with: ${user.email}`}
                                >
                                    <Tag 
                                        icon={<UserOutlined/>}
                                        color="blue"
                                    >
                                        {user.email}
                                    </Tag>
                                </Tooltip>
                            ))
                        }
                    </Space>
                )}
            </Space>
        );
    }
}); 
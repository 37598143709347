import { Tag, Tooltip, Badge } from 'antd';
import { PictureOutlined, DollarOutlined, BankOutlined, CodeOutlined, TeamOutlined, WalletOutlined } from '@ant-design/icons';
import { ADDRESS_TYPE_COLORS, AddressType, IAddress } from "../../../interfaces/address.d";

interface AddressTypeTagProps {
    type: AddressType;
    record: IAddress;
}

export const AddressTypeTag: React.FC<AddressTypeTagProps> = ({ type, record }) => {
    return (
        <>
            <Tag 
                icon={
                    type === 'nft_contract' ? <PictureOutlined /> :
                    type === 'token_contract' ? <DollarOutlined /> :
                    type === 'defi_contract' ? <BankOutlined /> :
                    type === 'contract' ? <CodeOutlined /> :
                    type === 'multisig' ? <TeamOutlined /> :
                    type === 'eoa' ? <WalletOutlined /> :
                    type === 'oracle' ? <BankOutlined /> :
                    <WalletOutlined />
                }
                color={ADDRESS_TYPE_COLORS[type]}
            >
                {type === 'nft_contract' ? 'NFT Contract' :
                 type === 'token_contract' ? 'Token Contract' :
                 type === 'defi_contract' ? 'DeFi Contract' :
                 type === 'contract' ? 'Smart Contract' :
                 type === 'multisig' ? 'Multi-Signature Wallet' :
                 type === 'eoa' ? 'Wallet' : 
                 type === 'oracle' ? 'Oracle' :
                 'Unknown'}
            </Tag>
            <Tooltip title={
                <div>
                    <div>Type: {record?.type}</div>
                    <div>Description: {
                        record?.type === 'nft_contract' ? 'Smart contract for NFT operations' :
                        record?.type === 'token_contract' ? 'Token smart contract' :
                        record?.type === 'defi_contract' ? 'DeFi protocol contract' :
                        record?.type === 'contract' ? 'Generic smart contract' :
                        record?.type === 'multisig' ? 'Multi-signature wallet' :
                        record?.type === 'eoa' ? 'Regular wallet address' :
                        'Unknown type'
                    }</div>
                </div>
            }>
                <Badge 
                    status={
                        record?.type?.includes('contract') ? 'processing' :
                        record?.type === 'multisig' ? 'warning' :
                        'success'
                    }
                    text={record?.type}
                />
            </Tooltip>
        </>
    );
}; 
import React from 'react';
import { IResourceComponentsProps, useShow, useUpdate } from "@refinedev/core";
import { 
    Card, Avatar, Space, Tag, Typography, Descriptions, 
    Row, Col, Alert, Steps, Divider, Tabs, Badge, Switch, message 
} from 'antd';
import { Show } from '@refinedev/antd';
import { IIntegration } from 'interfaces';
import {
    CloudServerOutlined, GlobalOutlined, TeamOutlined,
    ApiOutlined, CodeOutlined, SettingOutlined
} from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

// import AceEditor from 'react-ace';

// import 'ace-builds/src-noconflict/mode-json';
// import 'ace-builds/src-noconflict/theme-monokai';

export const IntegrationShow: React.FC<IResourceComponentsProps> = () => {
    const cdn_domain_name = process.env.REACT_APP_CDN_URL;
    
    const { queryResult } = useShow<IIntegration>();
    const { data, isLoading } = queryResult;
    const record = data?.data?.get_integration_result;

    const regionColors = {
        "us-west-2": "green",
        "us-east-1": "magenta",
        "eu-central-1": "blue",
        "eu-west-1": "cyan"
    };

    let pythonCode = `
    ### Example Payload
    {
        "TaskToken": "task_token",
        "Payload": {
            "transaction": {
                "hash": "0x57b1ab0ce2084d87f6f03a7911871ecbf179020564e3dff486ea48f4da27f6f3",
                "nonce": "7",
                "transaction_index": "109",
                "from_address": "0x3098b6665589959711a48a6bae5b7f2908f6a3be",
                "from_address_label": null,
                "to_address": "0x1043eee3e635827c466f68d434b8e474fd64a25b",
                "to_address_label": null,
                "value": "0",
                "gas": "757217",
                "gas_price": "15468279806",
                "input": "0x954491c1000000000000000000000000000000000000000000000000000000000000000500000000000000000000000000000",
                "receipt_cumulative_gas_used": "12164711",
                "receipt_gas_used": "630303",
                "receipt_contract_address": null,
                "receipt_root": null,
                "receipt_status": "0",
                "block_timestamp": "2023-09-27T18:35:11.000Z",
                "block_number": "18228915",
                "block_hash": "0x19cfca79520229f60a18bc4fcd2e9445a0b9a59b28be7c9ddacd77c8d1ba703d",
                "logs": [],
                "decoded_call": null
            }
        }
    }
    
    #### Example Integration code
    import os
    import json    
    import boto3
    
    sfn_client = boto3.client('stepfunctions')
    s3_client = boto3.client('s3')
    
    def lambda_handler(event, context):
        # Get the Payload
        task_token = event['TaskToken']
        transaction = event['Payload']["transaction"]

        # Add tag to the transaction
        result["tags"] = ["Add tag 1"] 

        # Add address labels
        result["from_address_labels"] = ["Label 1"] 
        result["to_address_labels"] = ["Label 1"] 

        # Add to group
        result["groups"] = ["Group 1"] 

        # Return results
        sfn_client.send_task_success(
            taskToken=task_token,
            output=json.dumps(result)
        )
    `;

    const { mutate: update, isLoading: isUpdating } = useUpdate();

    const handleToggleEnabled = (checked: boolean) => {
        update(
            {
                resource: "integrations",
                id: record?.id,
                values: {
                    enabled: checked,
                    updateCheckedOnly: true
                },
            },
            {
                onSuccess: () => {
                    message.success(`Integration ${checked ? 'enabled' : 'disabled'} successfully`);
                    queryResult.refetch();
                },
                onError: (error) => {
                    message.error(`Failed to ${checked ? 'enable' : 'disable'} integration`);
                },
            },
        );
    };

    return (
        <Show isLoading={isLoading}>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                {/* Header Section */}
                <Card>
                    <Row gutter={[16, 16]} align="middle">
                        <Col span={16}>
                            <Space align="start">
                                <Avatar 
                                    size={64}
                                    src={`https://${cdn_domain_name}/public/GET/cdn/integrations/logos/png/${record?.id}.png`}
                                />
                                <Space direction="vertical">
                                    <Title level={3} style={{ margin: 0 }}>{record?.name}</Title>
                                    <Space>
                                        <Switch
                                            checked={record?.enabled}
                                            loading={isUpdating}
                                            onChange={handleToggleEnabled}
                                            checkedChildren="Enabled"
                                            unCheckedChildren="Disabled"
                                        />
                                        <Tag color={record?.status === "active" ? "green" : "red"}>
                                            {record?.status}
                                        </Tag>
                                        <Tag color={regionColors[record?.region as keyof typeof regionColors]}>
                                            {record?.region}
                                        </Tag>
                                    </Space>
                                </Space>
                            </Space>
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }}>
                            <Space>
                                {/* Add any additional action buttons here */}
                            </Space>
                        </Col>
                    </Row>
                </Card>

                <Row gutter={16}>
                    {/* Left Column */}
                    <Col span={16}>
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            {/* Integration Details */}
                            <Card title={<Title level={5}>Integration Details</Title>}>
                                <Descriptions column={2}>
                                    <Descriptions.Item label="Environment">
                                        <Tag>{record?.environment}</Tag>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Organization">
                                        <Space>
                                            <TeamOutlined />
                                            {record?.org_id}
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Tenant ID">
                                        {record?.tenant_id}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Integration ID">
                                        <Text copyable>{record?.id}</Text>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Created By">
                                        {record?.created_by}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>

                            {/* Pipeline Stages */}
                            <Card title={<Title level={5}>Pipeline Stages</Title>}>
                                <Steps
                                    progressDot
                                    current={record?.integration_stages?.length}
                                    items={record?.integration_stages?.map((stage: string) => ({
                                        title: stage,
                                        description: stage.charAt(0).toUpperCase() + stage.slice(1)
                                    }))}
                                />
                            </Card>

                            {/* Trigger Configuration */}
                            <Card 
                                title={
                                    <Space>
                                        <ApiOutlined />
                                        <Title level={5} style={{ margin: 0 }}>Trigger Configuration</Title>
                                    </Space>
                                }
                            >
                                {record?.trigger_on?.map((trigger: any, index: number) => (
                                    <Card 
                                        key={index} 
                                        size="small" 
                                        style={{ marginBottom: 8 }}
                                    >
                                        <Space align="start">
                                            <Avatar 
                                                src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${trigger.chain_id}.svg`}
                                            />
                                            <Space direction="vertical">
                                                <Text strong>{trigger.chain}</Text>
                                                <Text copyable type="secondary">{trigger.address}</Text>
                                            </Space>
                                        </Space>
                                    </Card>
                                ))}
                            </Card>
                        </Space>
                    </Col>

                    {/* Right Column */}
                    <Col span={8}>
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            {/* Example Code */}
                            <Card 
                                title={
                                    <Space>
                                        <CodeOutlined />
                                        <Title level={5} style={{ margin: 0 }}>Implementation Guide</Title>
                                    </Space>
                                }
                            >
                                <Tabs defaultActiveKey="1">
                                    <TabPane tab="Example Payload" key="1">
                                        <pre style={{ 
                                            background: '#f5f5f5', 
                                            padding: 16,
                                            borderRadius: 4,
                                            maxHeight: 400,
                                            overflow: 'auto'
                                        }}>
                                            {JSON.stringify({
                                                TaskToken: "task_token",
                                                Payload: {
                                                    transaction: {
                                                        hash: "0x57b1...",
                                                        // ... other fields
                                                    }
                                                }
                                            }, null, 2)}
                                        </pre>
                                    </TabPane>
                                    <TabPane tab="Example Code" key="2">
                                        <pre style={{ 
                                            background: '#f5f5f5', 
                                            padding: 16,
                                            borderRadius: 4,
                                            maxHeight: 400,
                                            overflow: 'auto'
                                        }}>
                                            {pythonCode}
                                        </pre>
                                    </TabPane>
                                </Tabs>
                            </Card>

                            {/* Quick Help */}
                            <Alert
                                message="Need Help?"
                                description="Check our documentation for detailed integration guides and best practices."
                                type="info"
                                showIcon
                                action={
                                    <a href="/docs" target="_blank">
                                        View Docs
                                    </a>
                                }
                            />
                        </Space>
                    </Col>
                </Row>
            </Space>
        </Show>
    );
};


import { useState, useEffect } from 'react';
import { useConfig } from 'contexts';
import { useNotification } from '@refinedev/core';
import { PaginationCursor, Transaction } from '../types';

interface UseTransactionFetchProps {
    identifier: string;
    searchParams: URLSearchParams;
    initialPaginationCursors: PaginationCursor;
    resource?: string;
    fetchSize?: number;
    onTransactionsUpdate: (transactions: Transaction[], refresh: boolean) => void;
    onPaginationCursorUpdate?: (cursor: string) => void;
}

interface PaginationCursors {
    moralis: Record<string, any>;
    database: Record<string, any>;
    status: Record<string, any>;
}

export const useTransactionFetch = ({ 
    identifier, 
    searchParams, 
    initialPaginationCursors,
    resource = "addresses",
    fetchSize = 20,
    onTransactionsUpdate,
    onPaginationCursorUpdate
}: UseTransactionFetchProps) => {
    const { token, region, continent, domain, qa_environment } = useConfig();
    const { open } = useNotification();
    
    const [transactions, setTransactions] = useState<any[]>([]);
    const [isLoadingInfiniteScroll, setIsLoadingInfiniteScroll] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationCursors, setPaginationCursors] = useState<PaginationCursors | null>(initialPaginationCursors);

    const fetchTransactions = async (refresh = false, paginationCursors: any = {}) => {
        try {
            setIsLoadingInfiniteScroll(true);
            
            if (refresh) {
                console.log("refresh is true. Resetting transactions and pagination cursors")
                setTransactions([]);
                setPaginationCursors({
                    moralis: {},
                    database: {},
                    status: {}
                });
                setCurrentPage(1);
            }

            const requestHeaders = {
                Authorization: `Bearer ${token.__raw}`,
                Accept: "application/json, text/plain, */*",
                "Source-Platform": "dashboard",
                "Source-Region": region.use_region,
                "Destination-Region": region.use_region,
            };

            let apiUrl = `https://${region.use_region_alias}.${continent?.toLowerCase()}.private.${domain}`;
            let url = "";
            if (resource == "addresses") {
                url = qa_environment === "blue"
                    ? `${apiUrl}/addresses/blue/GET/${continent?.toUpperCase()}/v0/addresses/${identifier}`
                    : `${apiUrl}/addresses/GET/${continent?.toUpperCase()}/v0/addresses/${identifier}`;
            } else {
                apiUrl = `https://${region.use_region_alias}.${continent?.toLowerCase()}.private.${domain}`;
                url = qa_environment === "blue"
                    ? `${apiUrl}/transactions/blue/GET/${continent?.toUpperCase()}/v0/transactions`
                    : `${apiUrl}/transactions/GET/${continent?.toUpperCase()}/v0/transactions`;
            }

            const params = new URLSearchParams(searchParams);
            params.set('limit', fetchSize.toString());

            const chainIds = searchParams.get('chain_ids');
            if (chainIds) {
                params.set('chain_ids', chainIds);
            }
      
            if (paginationCursors && !refresh) {
                params.set('pagination_cursors', JSON.stringify(paginationCursors));
            } else {
                params.set('pagination_cursors', JSON.stringify(initialPaginationCursors));
            }

            const response = await fetch(`${url}?${params}`, {
                headers: requestHeaders
            });

            const responseData = await response.json();
            
            if (responseData.result && responseData.ok) {
                if (responseData.result && responseData.ok) {
                    onTransactionsUpdate(responseData.result.transactions, refresh);
                }
                
                if (responseData.result.pagination_cursors) {
                    setPaginationCursors(responseData.result.pagination_cursors);
                }

                const cursors = refresh ? initialPaginationCursors : paginationCursors;
                const hasMoreData = Object.values(cursors?.status || {}).some(
                    (chainStatus: any) => chainStatus.has_more_data 
                ) || responseData?.result?.last_evaluated_key;

                if (responseData?.result?.last_evaluated_key) {
                    const newCursor = responseData?.result?.last_evaluated_key || '';
                    onPaginationCursorUpdate?.(newCursor);
                }
                
                setHasMore(hasMoreData);
                setCurrentPage(prev => prev + 1);

                open?.({
                    type: "success",
                    message: "Loaded new transactions",
                    description: "Successfully loaded new transactions",
                    key: "transactions-fetch",
                });
            } else {
                const errorMessage = responseData.error?.reason || 
                                   responseData.error?.message || 
                                   responseData.error?.detailed_reason || 
                                   'Unknown error occurred';
                
                open?.({
                    type: "error",
                    message: `Error (${responseData.statusCode})`,
                    description: errorMessage,
                    key: "transactions-fetch",
                });
            }
           
        } catch (error) {
            open?.({
                type: "error",
                message: "Error fetching transactions",
                description: error instanceof Error ? error.message : "Unknown error",
                key: "transactions-fetch",
            });
            console.error('Error fetching transactions:', error);
        } finally {
            setIsLoadingInfiniteScroll(false);
        }
    };

    return {
        transactions,
        isLoadingInfiniteScroll,
        hasMore,
        currentPage,
        fetchTransactions
    };
}; 
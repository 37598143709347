import { CHAIN_MAPPING } from "../interfaces/chain.d";
import { Avatar, Tag, Space, Tooltip, Descriptions } from "antd";
import { Typography } from "antd";
import { StarFilled, InfoCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

interface LabelDisplay {
    id: string;
    name: string;
    color: string;
    source?: string;
    logo?: string;
    verified?: boolean;
    isGlobal?: boolean;
    chain_ids?: string[];
    main_label_chain_ids?: string[];
}

const getSourceDisplay = (id: string, source: string) => {
    if (id?.startsWith("default_address_label_")) {
        return 'Verified by 3rd party';
    }
    if (source === 'user') {
        return 'User created';
    }
    return 'Verified by 3rd party';
};

// Avatar components (similar to EntityShow)
export const renderAddressAvatar = (
    record: any,
    addressType?: 'from' | 'to',
    address?: string,
    options = {
        maxGlobalLabels: 3,
        maxUserLabels: 3,
        maxTotalLabels: 5,
        maxGlobalEntities: 3,
        maxUserEntities: 3,
        maxTotalEntities: 5,
        displayStyle: 'avatars' as 'avatars' | 'tags' | 'hover',
        hideAddress: true
    },
    addressItemsMap?: any
) => {
    const targetAddress = address?.toLowerCase() || record?.[`${addressType}_address`]?.toLowerCase();
    const currentChainId = record?.chain_id;
    if (!targetAddress) return null;

    // Collect and deduplicate labels
    const labels = new Map<string, LabelDisplay>();
    const entities = new Map<string, any>();
    
    addressItemsMap = addressItemsMap || record?.address_items_map;

    // Add transaction-level labels from addressType_address_label
    const transactionLabels = record?.[`${addressType}_address_label`] || [];
    (Array.isArray(transactionLabels) ? transactionLabels : [transactionLabels])
        .filter(Boolean)
        .forEach((label: any) => {
            if (label.id?.startsWith("label_")) {
                labels.set(label.id, {
                    ...label,
                    isGlobal: false
                });
            }
    });

    // Add address_items_map labels
    addressItemsMap?.[targetAddress]?.labels?.forEach((label: any) => {
        // For temporary labels, try to find the logo in metadata
        if (label.id?.startsWith("address_label_temp_")) {
            const metadataKey = `${addressType}_metadata`;
            const entityWithLogo = record?.[metadataKey]?.entities?.find(
                (entity: any) => entity.id.startsWith("temp_") || 
                               (entity.id?.startsWith("temp_") && entity.name === label.name)
            );
            
            if (entityWithLogo?.logo) {
                label.logo = entityWithLogo.logo;
            }
        }

        // Add labels that are either:
        // 1. Regular labels (starting with "label_")
        // 2. Address labels active on the current chain or with no chain_ids
        // 3. Temporary address labels or provider labels (with empty chain_ids)
        // 4. Default address labels (regardless of chain_ids)
        if (
            label.id?.startsWith("label_") || 
            !currentChainId || // If no chain ID is specified, show all labels
            !label.chain_ids || 
            label.chain_ids.length === 0 ||
            label.chain_ids.includes(currentChainId) ||
            (label.id?.startsWith("address_label_") && (!label.chain_ids || label.chain_ids.length === 0)) ||

            label.id?.startsWith("default_address_label_") // Always show default address labels
        ) {
            // Use ID as key instead of name to prevent overwriting
            labels.set(label.id, {
                ...label,
                isGlobal: label?.id?.startsWith("address_label_") || label?.id?.startsWith("default_address_label_")
            });
        }
    });

    // Add global address labels
    addressItemsMap?.[`${addressType}_address_label`]?.forEach((label: any) => {
        const isGlobal = label?.id?.startsWith("address_label_") || label?.id?.startsWith("default_address_label_");
        if (!currentChainId || !label.chain_ids || label.chain_ids.length === 0 || label.chain_ids.includes(currentChainId) || label.id?.startsWith("default_address_label_")) {
            labels.set(label.id, {
                ...label,
                isGlobal
            });
        }
    });


    // Collect entities from address_items_map
    addressItemsMap?.[targetAddress]?.entities?.forEach((entity: any) => {
        if (
            !currentChainId || // If no chain ID is specified, show all entities
            !entity.chain_ids || 
            entity.chain_ids.length === 0 ||
            entity.chain_ids.includes(currentChainId) ||
            entity.chain_ids.includes(String(currentChainId)) // Also check string version of chain ID
        ) {
            entities.set(entity.id, {
                ...entity,
                isGlobal: entity.id?.startsWith("entity_") || entity.id?.startsWith("default_entity_")
            });
        }
    });

    // Add transaction-level entities
    const transactionEntities = record?.[`${addressType}_metadata`]?.entities || [];
    transactionEntities.forEach((entity: any) => {
        // Skip temporary entities as they might be duplicates
        if (entity.id?.startsWith('temp_entity_')) {
            // Only add if we don't already have an entity with this name
            const existingEntity = Array.from(entities.values()).find(e => e.name === entity.name);
            if (!existingEntity) {
                entities.set(entity.id, {
                    ...entity,
                    isGlobal: entity.id?.startsWith("entity_") || entity.id?.startsWith("default_entity_")
                });
            }
        } else if (!entities.has(entity.id)) {
            // Add non-temporary entities if they don't exist
            entities.set(entity.id, {
                ...entity,
                isGlobal: entity.id?.startsWith("entity_") || entity.id?.startsWith("default_entity_")
            });
        }
    });

    // Look for logos in metadata for entities that don't have one
    const metadataEntities = record?.[`${addressType}_metadata`]?.entities || [];
    entities.forEach((entity, entityId) => {
        if (!entity.logo) {
            const tempEntity = metadataEntities.find(
                (e: any) => 
                    e.id?.startsWith('temp_entity_') && 
                    e.name === entity.name
            );
            if (tempEntity?.logo) {
                entities.set(entityId, {
                    ...entity,
                    logo: tempEntity.logo
                });
            }
        }
    });

    // Check all addresses in the map for labels
    Object.entries(addressItemsMap || {}).forEach(([address, data]: [string, any]) => {
        // Only process labels for the target address
        if (address.toLowerCase() === targetAddress && data?.labels && data?.labels?.length > 0) {            
            // Add these labels if they match our criteria
            data.labels.forEach((label: any) => {
                if (
                    label.id?.startsWith("label_") || 
                    !currentChainId || 
                    !label.chain_ids || 
                    label.chain_ids.length === 0 ||
                    label.chain_ids.includes(currentChainId) ||
                    label.id?.startsWith("default_address_label_")
                ) {
                    labels.set(label.id, {
                        ...label,
                        isGlobal: label?.id?.startsWith("address_label_") || label?.id?.startsWith("default_address_label_")
                    });
                }
            });
        }
    });


    // Convert to array and separate by type
    const allLabels = Array.from(labels.values());
    const allEntities = Array.from(entities.values());
    
    // Prioritize main labels for the current chain
    const sortedLabels = allLabels.sort((a, b) => {
        const aIsMain = a.main_label_chain_ids?.includes(currentChainId) ? 1 : 0;
        const bIsMain = b.main_label_chain_ids?.includes(currentChainId) ? 1 : 0;
        return bIsMain - aIsMain;
    });

    const globalLabels = sortedLabels.filter(label => label.isGlobal);
    const userLabels = sortedLabels.filter(label => !label.isGlobal);

    // If all maximums are 0, don't show any labels
    if (options.maxGlobalLabels === 0 && options.maxUserLabels === 0 && options.maxTotalLabels === 0 && options.maxGlobalEntities === 0 && options.maxUserEntities === 0 && options.maxTotalEntities === 0) {
        return null;
    }

    // Convert entities to array and separate by type
    const globalEntities = allEntities.filter(entity => entity.isGlobal);
    const userEntities = allEntities.filter(entity => !entity.isGlobal);

    // Calculate how many of each type to show
    const effectiveMaxTotal = options.maxTotalLabels || (options.maxGlobalLabels + options.maxUserLabels);
    const totalLabels = Math.min(effectiveMaxTotal, sortedLabels.length);
    const maxGlobalToShow = Math.min(options.maxGlobalLabels, globalLabels.length);
    const maxUserToShow = Math.min(
        options.maxUserLabels,
        Math.min(
            userLabels.length,
            totalLabels - maxGlobalToShow
        )
    );

    // Calculate how many entities to show
    const effectiveMaxTotalEntities = options.maxTotalEntities || (options.maxGlobalEntities + options.maxUserEntities);
    const totalEntities = Math.min(effectiveMaxTotalEntities, allEntities.length);
    const maxGlobalEntitiesToShow = Math.min(options.maxGlobalEntities, globalEntities.length);
    const maxUserEntitiesToShow = Math.min(
        options.maxUserEntities,
        Math.min(
            userEntities.length,
            totalEntities - maxGlobalEntitiesToShow
        )
    );

    // If we have space for more globals after user labels, show them
    const extraGlobalSlots = totalLabels - maxUserToShow - maxGlobalToShow;
    const finalGlobalToShow = extraGlobalSlots > 0 
        ? Math.min(globalLabels.length, maxGlobalToShow + extraGlobalSlots)
        : maxGlobalToShow;

    const selectedGlobalLabels = globalLabels.slice(0, finalGlobalToShow);
    const selectedUserLabels = userLabels.slice(0, maxUserToShow);
    const displayedLabels = [...selectedGlobalLabels, ...selectedUserLabels];

    const hiddenCount = sortedLabels.length - displayedLabels.length;

    // Get all labels with logos and those without
    const labelsWithLogos = displayedLabels.filter(label => label.logo);
    const labelsWithoutLogos = displayedLabels.filter(label => !label.logo);

    const hiddenLabels = sortedLabels.filter(label => !displayedLabels.includes(label));

    // If we have space for more globals after user entities, show them
    const extraGlobalEntitySlots = totalEntities - maxUserEntitiesToShow - maxGlobalEntitiesToShow;
    const finalGlobalEntitiesToShow = extraGlobalEntitySlots > 0 
        ? Math.min(globalEntities.length, maxGlobalEntitiesToShow + extraGlobalEntitySlots)
        : maxGlobalEntitiesToShow;

    const selectedGlobalEntities = globalEntities.slice(0, finalGlobalEntitiesToShow);
    const selectedUserEntities = userEntities.slice(0, maxUserEntitiesToShow);
    const displayedEntities = [...selectedGlobalEntities, ...selectedUserEntities];
    const hiddenEntityCount = allEntities.length - displayedEntities.length;
    const hiddenEntities = allEntities.filter(entity => !displayedEntities.includes(entity));

    if (options.displayStyle === 'hover') {
        return (
            <Tooltip
                title={
                    <Space direction="vertical" size={4}>
                        {/* Entities Section */}
                        {allEntities.length > 0 && (
                            <>
                                <Text strong>Entities:</Text>
                                {allEntities.map(entity => (
                                    <Space key={entity.id}>
                                        {entity.logo && (
                                            <Avatar 
                                                src={entity.logo} 
                                                size="small" 
                                                style={{ width: 16, height: 16 }}
                                            />
                                        )}
                                        <Text>{entity.name}</Text>
                                    </Space>
                                ))}
                            </>
                        )}
                        {/* Existing Labels Section */}
                        {sortedLabels.length > 0 && (
                            <>
                                <Text strong>Labels:</Text>
                                {sortedLabels.map(label => {
                                    const isAddressLabel = label.id?.startsWith("address_label_") || label.id?.startsWith("default_address_label_");
                                    return (
                                        <Space direction="vertical" size={1} key={label.id}>
                                            <Tag 
                                                color={`#${label.color}`}
                                                style={{ 
                                                    display: 'flex', 
                                                    alignItems: 'center', 
                                                    gap: 4 
                                                }}
                                            >
                                                {label.logo && (
                                                    <Avatar 
                                                        src={label.logo} 
                                                        size="small" 
                                                        style={{ 
                                                            width: 16, 
                                                            height: 16,
                                                            marginRight: 4 
                                                        }}
                                                    />
                                                )}
                                                {label.name}
                                                {isAddressLabel && label.main_label_chain_ids?.includes(currentChainId) && (
                                                    <StarFilled style={{ color: '#faad14', marginLeft: 4 }}/>
                                                )}
                                                {label.verified && ' ✓'}
                                            </Tag>
                                            {isAddressLabel && (
                                                <>
                                                    <div style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.85)' }}>
                                                        Source: {getSourceDisplay(label.id, label.source || '')}
                                                    </div>
                                                    {label.chain_ids && label.chain_ids.length > 0 && (
                                                        <div style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.85)' }}>
                                                            Active on: {label.chain_ids
                                                                .map((chainId: string) => CHAIN_MAPPING[chainId] || chainId)
                                                                .join(', ')}
                                                        </div>
                                                    )}
                                                    {label.main_label_chain_ids?.includes(currentChainId) && (
                                                        <div style={{ fontSize: '12px', color: '#faad14' }}>
                                                            Main label for current chain
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Space>
                                    );
                                })}
                            </>
                        )}
                    </Space>
                }
                overlayStyle={{ 
                    maxWidth: '300px',
                    color: 'rgba(255, 255, 255, 0.85)'
                }}
            >
                <InfoCircleOutlined style={{ 
                    color: (sortedLabels.length > 0 || allEntities.length > 0) ? '#1890ff' : '#d9d9d9',
                    cursor: 'pointer',
                    fontSize: '14px'
                }} />
            </Tooltip>
        );
    }

    if (options.displayStyle === 'tags') {
        return (
            <Space wrap>
                {!options.hideAddress && targetAddress && (
                    <Tooltip title={targetAddress}>
                        <Typography.Text 
                            copyable={{ text: targetAddress }} 
                            style={{ whiteSpace: 'nowrap' }}
                            data-full-address={targetAddress}
                        >
                            {`${targetAddress.slice(0, 6)}...${targetAddress.slice(-6)}`}
                        </Typography.Text>
                    </Tooltip>
                )}
                
                {/* Entities Section */}
                {displayedEntities.length > 0 && (
                    <Text type="secondary" style={{ fontSize: '12px' }}>
                        Entities:
                    </Text>
                )}
                {displayedEntities.map((entity) => (
                    <Tooltip 
                        key={entity.id}
                        title={
                            <Space direction="vertical" size={1}>
                                {entity.chain_ids && entity.chain_ids.length > 0 && (
                                    <div style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.85)' }}>
                                        Active on: {entity.chain_ids
                                            .map((chainId: string) => CHAIN_MAPPING[chainId] || chainId)
                                            .join(', ')}
                                    </div>
                                )}
                                {entity.main_label_chain_ids?.includes(currentChainId) && (
                                    <div style={{ fontSize: '12px', color: '#faad14' }}>
                                        Main entity for current chain
                                    </div>
                                )}
                            </Space>
                        }
                    >
                        <Tag 
                            color={entity.color ? `#${entity.color}` : undefined}
                            style={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 4 
                            }}
                        >
                            {entity.logo && (
                                <Avatar 
                                    src={entity.logo} 
                                    size="small" 
                                    style={{ 
                                        width: 16, 
                                        height: 16,
                                        marginRight: 4 
                                    }}
                                />
                            )}
                            {entity.name}
                            {entity.main_label_chain_ids?.includes(currentChainId) && (
                                <StarFilled style={{ color: '#faad14', marginLeft: 4 }}/>
                            )}
                        </Tag>
                    </Tooltip>
                ))}
                {hiddenEntityCount > 0 && (
                    <Tooltip 
                        title={
                            <Space direction="vertical" size={1}>
                                {hiddenEntities.map(entity => (
                                    <Tag 
                                        key={entity.id}
                                        color={entity.color ? `#${entity.color}` : undefined}
                                        style={{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            gap: 4 
                                        }}
                                    >
                                        {entity.logo && (
                                            <Avatar 
                                                src={entity.logo} 
                                                size="small" 
                                                style={{ 
                                                    width: 16, 
                                                    height: 16,
                                                    marginRight: 4 
                                                }}
                                            />
                                        )}
                                        {entity.name}
                                        {entity.main_label_chain_ids?.includes(currentChainId) && (
                                            <StarFilled style={{ color: '#faad14', marginLeft: 4 }}/>
                                        )}
                                    </Tag>
                                ))}
                            </Space>
                        }
                    >
                        <Tag style={{ fontSize: '12px' }}>
                            +{hiddenEntityCount} more entities
                        </Tag>
                    </Tooltip>
                )}

                {/* Labels Section */}
                {sortedLabels.length > 0 && (
                    <Text type="secondary" style={{ fontSize: '12px' }}>
                        Labels:
                    </Text>
                )}
                {displayedLabels.map((label) => {
                    const isAddressLabel = label.id?.startsWith("address_label_") || label.id?.startsWith("default_address_label_");
                    
                    // For regular labels, just render the tag without a tooltip
                    if (!isAddressLabel) {
                        return (
                            <Tag 
                                key={label.id} 
                                color={`#${label.color}`}
                                style={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: 4 
                                }}
                            >
                                {label.logo && (
                                    <Avatar 
                                        src={label.logo} 
                                        size="small" 
                                        style={{ 
                                            width: 16, 
                                            height: 16,
                                            marginRight: 4 
                                        }}
                                    />
                                )}
                                {label.name}
                                {label.verified && ' ✓'}
                            </Tag>
                        );
                    }

                    // For address labels, show chain information
                    const uniqueChains = Array.from(new Set(label.chain_ids || []));
                    const mainChains = new Set(label.main_label_chain_ids || []);

                    return (
                        <Tooltip 
                            key={label.id}
                            title={
                                <Space direction="vertical" size={1}>
                                    <div>Source: {getSourceDisplay(label.id, label.source || '')}</div>
                                    {uniqueChains.length > 0 && (
                                        <div style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.85)' }}>
                                            Active on: {uniqueChains.map((chainId, index) => (
                                                <span key={chainId}>
                                                    {CHAIN_MAPPING[chainId] || chainId}
                                                    {mainChains.has(chainId) && (
                                                        <StarFilled 
                                                            style={{ 
                                                                color: '#faad14', 
                                                                marginLeft: 2,
                                                                marginRight: 4,
                                                                fontSize: '10px'
                                                            }}
                                                        />
                                                    )}
                                                    {index < uniqueChains.length - 1 ? ', ' : ''}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                    {label.main_label_chain_ids?.includes(currentChainId) && (
                                        <div style={{ fontSize: '12px', color: '#faad14' }}>
                                            Main label for current chain
                                        </div>
                                    )}
                                </Space>
                            }
                            overlayStyle={{ 
                                maxWidth: '300px',
                                color: 'rgba(255, 255, 255, 0.85)'
                            }}
                        >
                            <Tag 
                                color={`#${label.color}`}
                                style={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: 4 
                                }}
                            >
                                {label.logo && (
                                    <Avatar 
                                        src={label.logo} 
                                        size="small" 
                                        style={{ 
                                            width: 16, 
                                            height: 16,
                                            marginRight: 4 
                                        }}
                                    />
                                )}
                                {label.name}
                                {label.main_label_chain_ids?.includes(currentChainId) && (
                                    <StarFilled style={{ color: '#faad14', marginLeft: 4 }}/>
                                )}
                                {label.verified && ' ✓'}
                            </Tag>
                        </Tooltip>
                    );
                })}
                {hiddenCount > 0 && (
                    <Tooltip 
                        title={
                            <Space direction="vertical" size={1}>
                                {hiddenLabels.map(label => (
                                    <Tag 
                                        key={label.id} 
                                        color={`#${label.color}`}
                                        style={{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            gap: 4 
                                        }}
                                    >
                                        {label.logo && (
                                            <Avatar 
                                                src={label.logo} 
                                                size="small" 
                                                style={{ 
                                                    width: 16, 
                                                    height: 16,
                                                    marginRight: 4 
                                                }}
                                            />
                                        )}
                                        {label.name}
                                        {label.verified && ' ✓'}
                                    </Tag>
                                ))}
                            </Space>
                        }
                        overlayStyle={{ 
                            maxWidth: '300px',
                            color: 'rgba(255, 255, 255, 0.85)'
                        }}
                    >
                        <Tag style={{ fontSize: '12px' }}>
                            +{hiddenCount} more
                        </Tag>
                    </Tooltip>
                )}
            </Space>
        );
    }

    // Original avatar-style display
    if (labelsWithLogos.length > 0 || displayedEntities.length > 0 || addressItemsMap?.[`${addressType}_address_entity_logo`] || labelsWithoutLogos.length > 0) {
        return (
            <Space size={4}>
                {/* Entity logos */}
                {displayedEntities.map(entity => (
                    <Tooltip 
                        key={entity.id}
                        title={
                            <Space direction="vertical" size={1}>
                                <Tag color={entity.color ? `#${entity.color}` : undefined}>
                                    {entity.name}
                                    {entity.main_label_chain_ids?.includes(currentChainId) && (
                                        <StarFilled style={{ color: '#faad14', marginLeft: 4 }}/>
                                    )}
                                </Tag>
                                {entity.chain_ids && entity.chain_ids.length > 0 && (
                                    <div style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.85)' }}>
                                        Active on: {entity.chain_ids
                                            .map((chainId: string) => CHAIN_MAPPING[chainId] || chainId)
                                            .join(', ')}
                                    </div>
                                )}
                                {entity.main_label_chain_ids?.includes(currentChainId) && (
                                    <div style={{ fontSize: '12px', color: '#faad14' }}>
                                        Main entity for current chain
                                    </div>
                                )}
                            </Space>
                        }
                        overlayStyle={{ color: 'rgba(255, 255, 255, 0.85)' }}
                    >
                        <Avatar src={entity.logo} />
                    </Tooltip>
                ))}
                {hiddenEntityCount > 0 && (
                    <Tooltip 
                        title={
                            <Space direction="vertical" size={1}>
                                {hiddenEntities.map(entity => (
                                    <Space key={entity.id}>
                                        {entity.logo && (
                                            <Avatar 
                                                src={entity.logo} 
                                                size="small" 
                                                style={{ width: 16, height: 16 }}
                                            />
                                        )}
                                        <Text>{entity.name}</Text>
                                    </Space>
                                ))}
                            </Space>
                        }
                    >
                        <Tag style={{ fontSize: '12px' }}>
                            +{hiddenEntityCount} more entities
                        </Tag>
                    </Tooltip>
                )}
                
                {!options.hideAddress && targetAddress && (
                    <Tooltip title={targetAddress}>
                        <Typography.Text 
                            copyable={{ text: targetAddress }} 
                            style={{ whiteSpace: 'nowrap' }}
                            data-full-address={targetAddress}
                        >
                            {`${targetAddress.slice(0, 6)}...${targetAddress.slice(-6)}`}
                        </Typography.Text>
                    </Tooltip>
                )}
                
                {/* Labels with logos */}
                {labelsWithLogos.map(label => {
                    const isAddressLabel = label.id?.startsWith("address_label_") || label.id?.startsWith("default_address_label_");
                    const uniqueChains = isAddressLabel ? Array.from(new Set(label.chain_ids || [])) : [];
                    const mainChains = isAddressLabel ? new Set(label.main_label_chain_ids || []) : new Set();
                    
                    return (
                        <Tooltip 
                            key={label.id}
                            title={
                                <Space direction="vertical" size={1}>
                                    <Tag color={`#${label.color}`}>
                                        {label.name}
                                        {isAddressLabel && label.main_label_chain_ids?.includes(currentChainId) && (
                                            <StarFilled style={{ color: '#faad14', marginLeft: 4 }}/>
                                        )}
                                        {label.verified && ' ✓'}
                                    </Tag>
                                    <div>Source: {getSourceDisplay(label.id, label.source || '')}</div>
                                    {isAddressLabel && uniqueChains.length > 0 && (
                                        <div style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.85)' }}>
                                            Active on: {uniqueChains.map((chainId, index) => (
                                                <span key={chainId}>
                                                    {CHAIN_MAPPING[chainId] || chainId}
                                                    {mainChains.has(chainId) && (
                                                        <StarFilled 
                                                            style={{ 
                                                                color: '#faad14', 
                                                                marginLeft: 2,
                                                                marginRight: 4,
                                                                fontSize: '10px'
                                                            }}
                                                        />
                                                    )}
                                                    {index < uniqueChains.length - 1 ? ', ' : ''}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                    {isAddressLabel && label.main_label_chain_ids?.includes(currentChainId) && (
                                        <div style={{ fontSize: '12px', color: '#faad14' }}>
                                            Main label for current chain
                                        </div>
                                    )}
                                </Space>
                            }
                            overlayStyle={{ 
                                maxWidth: '300px',
                                color: 'rgba(255, 255, 255, 0.85)'
                            }}
                        >
                            <Avatar src={label.logo} />
                        </Tooltip>
                    );
                })}

                {/* Labels without logos */}
                {labelsWithoutLogos.map(label => {
                    const isAddressLabel = label.id?.startsWith("address_label_") || label.id?.startsWith("default_address_label_");
                    
                    // For regular labels, just render the tag without a tooltip
                    if (!isAddressLabel) {
                        return (
                            <Tag 
                                key={label.id} 
                                color={`#${label.color}`}
                            >
                                {label.name}
                                {label.verified && ' ✓'}
                            </Tag>
                        );
                    }

                    // For address labels, keep the tooltip with chain information
                    return (
                        <Tooltip 
                            key={label.id}
                            title={
                                <Space direction="vertical" size={1}>
                                    {label.chain_ids && label.chain_ids.length > 0 && (
                                        <div style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.85)' }}>
                                            Active on: {label.chain_ids
                                                .map((chainId: string) => CHAIN_MAPPING[chainId] || chainId)
                                                .join(', ')}
                                        </div>
                                    )}
                                </Space>
                            }
                            overlayStyle={{ 
                                maxWidth: '300px',
                                color: 'rgba(255, 255, 255, 0.85)'
                            }}
                        >
                            <Tag color={`#${label.color}`}>
                                {label.name}
                                {label.main_label_chain_ids?.includes(currentChainId) && (
                                    <StarFilled style={{ color: '#faad14', marginLeft: 4 }}/>
                                )}
                                {label.verified && ' ✓'}
                            </Tag>
                        </Tooltip>
                    );
                })}

                {hiddenCount > 0 && (
                    <Tooltip 
                        title={
                            <Space direction="vertical" size={1}>
                                {hiddenLabels.map(label => (
                                    <Tag 
                                        key={label.id} 
                                        color={`#${label.color}`}
                                        style={{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            gap: 4 
                                        }}
                                    >
                                        {label.logo && (
                                            <Avatar 
                                                src={label.logo} 
                                                size="small" 
                                                style={{ 
                                                    width: 16, 
                                                    height: 16,
                                                    marginRight: 4 
                                                }}
                                            />
                                        )}
                                        {label.name}
                                        {label.verified && ' ✓'}
                                    </Tag>
                                ))}
                            </Space>
                        }
                        overlayStyle={{ 
                            maxWidth: '300px',
                            color: 'rgba(255, 255, 255, 0.85)'
                        }}
                    >
                        <Tag style={{ fontSize: '12px' }}>
                            +{hiddenCount} more
                        </Tag>
                    </Tooltip>
                )}
            </Space>
        );
    }

    return null;
};

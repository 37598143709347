import { Timeline, Table, Tooltip, Empty, Tag, Space, Typography } from 'antd';
import { useList } from '@refinedev/core';
import { LinkOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { getCriticalityConfig } from './utils';
import { StyledTimelineItem, EventContent, TimeStamp, EventLink } from './styled';
import { IEvent } from 'interfaces/event';
import { useEffect } from 'react';

const { Text, Link } = Typography;

interface EventTimelineProps {
    orgId?: string;
    tenantId?: string;
    address?: string;
    filters: {
        eventTypes: string[];
        criticalityLevels: string[];
        chains: string[];
        dateRange: [dayjs.Dayjs, dayjs.Dayjs] | null;
        searchQuery: string;
        resources: string[];
    };
    view: 'timeline' | 'list';
    refreshTrigger: number;
    setIsRefreshing: (isRefreshing: boolean) => void;
}

export const EventTimeline: React.FC<EventTimelineProps> = ({
    orgId,
    tenantId,
    address,
    filters,
    view,
    refreshTrigger,
    setIsRefreshing
}) => {
    const navigate = useNavigate();
    const { data, isLoading, refetch } = useList<IEvent>({
        resource: "events",
        meta: {
            orgId,
            tenantId,
            address,
        },
        queryOptions: {
            // This will make the query refetch when refreshTrigger changes
            cacheTime: 0,
            staleTime: 0,
            refetchOnWindowFocus: false,
        },
    });

    // Update parent's loading state
    useEffect(() => {
        setIsRefreshing(isLoading);
    }, [isLoading, setIsRefreshing]);

    const events = data?.data || [];

    const renderEventContent = (event: IEvent) => {
        const commonContent = (
            <Space direction="vertical" size={1}>
                <TimeStamp>
                    <Tooltip title={dayjs.unix(event.created_at).format('LLLL')}>
                        {dayjs.unix(event.created_at).fromNow()}
                    </Tooltip>
                </TimeStamp>
                <Text strong>{event.message_summary}</Text>
                <Text type="secondary">{event.message}</Text>
                <Space size={[0, 8]} wrap>
                    {event.chain && (
                        <Tag color="blue">{event.chain}</Tag>
                    )}
                    <EventLink 
                        onClick={() => navigate(`/${event.resource}/${event.link_id}`)}
                    >
                        <LinkOutlined/> {event.resource} #{event.link_id}
                    </EventLink>
                </Space>
            </Space>
        );

        // Add specific content based on event type
        switch (event.event_type) {
            case 'contract':
                return (
                    <>
                        {commonContent}
                        <Space direction="vertical" size={1}>
                            <Text type="secondary">Contract: {event.contract_address}</Text>
                            <Text type="secondary">Method: {event.method_name}</Text>
                            <Link href={`/tx/${event.transaction_hash}`} target="_blank">
                                View Transaction
                            </Link>
                        </Space>
                    </>
                );
            case 'security':
                return (
                    <>
                        {commonContent}
                        <Space direction="vertical" size={1}>
                            <Text type="secondary">Risk Score: {event.risk_score}</Text>
                            <Text type="secondary">
                                Affected Addresses: {event.affected_addresses.join(', ')}
                            </Text>
                        </Space>
                    </>
                );
            default:
                return commonContent;
        }
    };

    // Filter events based on the filters prop
    const filteredEvents = events.filter((event: IEvent) => {
        if (filters.eventTypes.length > 0 && !filters.eventTypes.includes(event.event_type)) {
            return false;
        }

        if (filters.criticalityLevels.length > 0 && 
            !filters.criticalityLevels.includes(event.criticality_level)) {
            return false;
        }

        if (filters.chains.length > 0 && !filters.chains.includes(event.chain)) {
            return false;
        }

        if (filters.dateRange) {
            const eventDate = dayjs.unix(event.created_at);
            if (eventDate.isBefore(filters.dateRange[0]) || 
                eventDate.isAfter(filters.dateRange[1])) {
                return false;
            }
        }

        if (filters.resources.length > 0 && !filters.resources.includes(event.resource)) {
            return false;
        }

        return true;
    });

    const columns = [
        {
            title: 'Time',
            dataIndex: 'created_at',
            render: (timestamp: number) => (
                <Tooltip title={dayjs.unix(timestamp).format('LLLL')}>
                    {dayjs.unix(timestamp).fromNow()}
                </Tooltip>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'event_type',
            render: (type: string) => type?.charAt(0).toUpperCase() + type?.slice(1),
        },
        {
            title: 'Chain',
            dataIndex: 'chain',
            render: (chain: string) => <Tag color="blue">{chain}</Tag>,
        },
        {
            title: 'Criticality',
            dataIndex: 'criticality_level',
            render: (level: string) => {
                const config = getCriticalityConfig(level);
                return <Tag color={config.color}>{config.text}</Tag>;
            },
        },
        {
            title: 'Summary',
            dataIndex: 'message_summary',
        },
        {
            title: 'Details',
            render: (_: any, record: IEvent) => {
                switch (record.event_type) {
                    case 'contract':
                        return (
                            <Space direction="vertical" size={1}>
                                <Text type="secondary">Contract: {record.contract_address}</Text>
                                <Text type="secondary">Method: {record.method_name}</Text>
                                <Link href={`/tx/${record.transaction_hash}`} target="_blank">
                                    View Transaction
                                </Link>
                            </Space>
                        );
                    case 'security':
                        return (
                            <Space direction="vertical" size={1}>
                                <Text type="secondary">Risk Score: {record.risk_score}</Text>
                                <Text type="secondary">
                                    Affected Addresses: {record.affected_addresses.join(', ')}
                                </Text>
                            </Space>
                        );
                    default:
                        return null;
                }
            }
        },
        {
            title: 'Resource',
            dataIndex: 'resource',
            render: (resource: string, record: IEvent) => (
                <EventLink onClick={() => navigate(`/${resource}/${record.link_id}`)}>
                    {resource} #{record.link_id}
                </EventLink>
            ),
        },
    ];

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (filteredEvents.length === 0) {
        return <Empty description="No events found" />;
    }

    if (view === 'list') {
        return (
            <Table 
                dataSource={filteredEvents}
                columns={columns}
                rowKey={(record) => `${record.created_at}-${record.link_id}`}
                loading={isLoading}
            />
        );
    }

    return (
        <Timeline>
            {filteredEvents.map((event: IEvent) => {
                const config = getCriticalityConfig(event.criticality_level);
                return (
                    <StyledTimelineItem
                        key={`${event.created_at}-${event.link_id}`}
                        color={config.color}
                        dot={config.icon}
                    >
                        <EventContent backgroundColor={config.backgroundColor}>
                            {renderEventContent(event)}
                        </EventContent>
                    </StyledTimelineItem>
                );
            })}
        </Timeline>
    );
}; 
import { List, TagField, useTable } from "@refinedev/antd";
import {
    EditButton,
    ShowButton,
    CreateButton,
    DeleteButton,
} from "@refinedev/antd";
import {
    Table,
    Space,
    Input,
    Card,
    Row,
    Col,
    Typography,
    Tag,
    Avatar,
    Tooltip,
    Button,
    Badge,
} from "antd";
import { SearchOutlined, CopyOutlined } from "@ant-design/icons";
import type { IGroup } from "../../interfaces/group.d";
import { useState, useMemo } from "react";

const { Text } = Typography;

export const GroupList: React.FC = () => {
    const [searchText, setSearchText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const {
        tableProps,
        filters,
        sorters,
    } = useTable<IGroup>({
        resource: "groups",
        pagination: {
            pageSize,
        },
        sorters: {
            initial: [
                {
                    field: "created_at",
                    order: "desc",
                },
            ],
        },
    });

    // Filter data locally based on search text
    const filteredData = useMemo(() => {
        return (tableProps.dataSource || []).filter((item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
        );
    }, [tableProps.dataSource, searchText]);

    // Calculate pagination
    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize;
        return filteredData.slice(startIndex, startIndex + pageSize);
    }, [filteredData, currentPage]);

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: true,
            render: (name: string, record: IGroup) => (
                <Space>
                    <Avatar
                        style={{
                            backgroundColor: record.color ? `#${record.color}` : undefined,
                        }}
                    >
                        {name.charAt(0).toUpperCase()}
                    </Avatar>
                    <Text>{name}</Text>
                </Space>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <Card style={{ padding: 8 }}>
                    <Input
                        placeholder="Search name"
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                        >
                            Search
                        </Button>
                        <Button onClick={() => clearFilters()} size="small">
                            Reset
                        </Button>
                    </Space>
                </Card>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
        },
        {
            title: "Created By",
            dataIndex: "created_by_service",
            key: "created_by_service",
            render: (service: string) => (
                <Tag color="blue">{service}</Tag>
            ),
        },
        {
            title: "Organization",
            dataIndex: "org_id-tenant_id",
            key: "org_id-tenant_id",
            render: (orgTenant: string) => (
                <Tooltip title={orgTenant}>
                    <Tag color="purple">{orgTenant?.split('-')[0]}</Tag>
                </Tooltip>
            ),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (_: any, record: IGroup) => (
                <Space>
                    <ShowButton
                        size="small"
                        recordItemId={"id=" + record.id + "&name=" + record.name}
                    />
                    <EditButton
                        size="small"
                        recordItemId={record.id}
                    />
                    <DeleteButton
                        size="small"
                        recordItemId={record.id}
                    />
                </Space>
            ),
        },
    ];

    return (
        <List
            headerButtons={[
                <CreateButton key="create" />,
            ]}
        >
            <Card>
                <Row gutter={[16, 16]} align="middle" style={{ marginBottom: 16 }}>
                    <Col flex="1">
                        <Input.Search
                            placeholder="Search groups..."
                            onSearch={(value) => {
                                setSearchText(value);
                                setCurrentPage(1); // Reset to first page on search
                            }}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                setCurrentPage(1); // Reset to first page on search
                            }}
                            style={{ width: 300 }}
                            allowClear
                        />
                    </Col>
                    <Col>
                        <Badge count={filteredData.length}>
                            <Tag color="blue">Total Groups</Tag>
                        </Badge>
                    </Col>
                </Row>
                <Table
                    {...tableProps}
                    dataSource={paginatedData}
                    columns={columns}
                    rowKey="id"
                    pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        total: filteredData.length,
                        onChange: (page) => setCurrentPage(page),
                    }}
                />
            </Card>
        </List>
    );
};
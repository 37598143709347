import { AddressData, getAddressInfo } from './addressUtils';

// Create a new utility file for shared formatters
export const formatWeiToNative = (wei: string) => {
    if (!wei) return '0';
    const value = BigInt(wei);
    const nativeValue = Number(value) / 1e18;
    return nativeValue.toFixed(6).replace(/\.?0+$/, '');
}; 

export const shortenAddress = (address: string) => {
    if (!address) return '';
    return address.slice(0, 8) + '...' + address.slice(-8);
};

export const shortenHash = (hash: string) => {
    return hash.slice(0, 8) + '...' + hash.slice(-8);
};

// Add a utility function for consistent number formatting
export const formatUSD = (value: number) => {
    if (value === 0) return '$0.00';
    
    // For very small numbers (less than 0.01)
    if (value < 0.01) {
        return '< $0.01';
    }
        
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

export const formatTokenBalance = (balance: string, decimals: string | number) => {
    try {
        const decimalPlaces = Number(decimals);
        if (isNaN(decimalPlaces)) return '0';
        
        const balanceNum = Number(balance) / Math.pow(10, decimalPlaces);
        return balanceNum.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 6
        });
    } catch (error) {
        console.error('Error formatting balance:', error);
        return '0';
    }
};

export const getTagColorByParamType = (type: string) => {
    if (!type) return 'default';
    
    if (type.includes('int')) return 'blue';
    if (type.includes('uint')) return 'blue';
    if (type.includes('address')) return 'green';
    if (type.includes('bool')) return 'volcano';
    if (type.includes('bytes')) return 'purple';
    if (type.includes('string')) return 'cyan';
    if (type.includes('tuple')) return 'orange';
    if (type.includes('tuple[]')) return 'orange';
    return 'default';
};   

export const getTooltipTitleForParamType = (addressData: any, param: any) => {
    if (param.type.includes('address')) {
        const addressInfo = getAddressInfo(param.value, addressData);
        const shortAddress = shortenAddress(param.value)
        return addressInfo.label !== 'No label' 
            ? `${param.type}: ${addressInfo.label} (${shortAddress})`
            : `${param.type}: ${shortAddress}`;
    }

    if (param.type === 'tuple') {
        return `${param.type}: Nested structure with ${param.value.length} fields`;
    }

    if (param.type === 'tuple[]') {
        return `${param.type}: Array with ${param.value.length} items`;
    }

    if (param.type === 'bytes[]') {
        return `${param.type}: Array with ${param.value.length} items`;
    }

    if (param.type === 'bytes' || param.type === 'string') {
        const isLongValue = param.value.length > 66;
        return `${param.type}: ${isLongValue ? param.value.slice(0, 66) + '...' : param.value}`;
    }

    return `${param.type}: ${param.value}`;
};

export const formatDate = (timestamp: string) => {
    // Handle ISO date string format and validate date
    let date: Date;
    try {
        // First try direct parsing
        date = new Date(timestamp);
        
        // Check if date is valid
        if (isNaN(date.getTime())) {
            console.error('Invalid date:', timestamp);
            return 'Invalid Date';
        }
    } catch (error) {
        console.error('Error parsing date:', error);
        return 'Invalid Date';
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    
    const formattedDate = date.toLocaleString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC'
    });

    // Handle future dates
    if (diffInSeconds < 0) {
        return `in the future (${formattedDate} UTC)`;
    }

    // Calculate different time units
    const minutes = Math.floor(diffInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    let relativeTime;
    if (diffInSeconds < 5) {
        relativeTime = 'just now';
    } else if (diffInSeconds < 60) {
        relativeTime = `${diffInSeconds} seconds ago`;
    } else if (minutes < 60) {
        relativeTime = `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (hours < 24) {
        relativeTime = `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else if (days < 30) {
        relativeTime = `${days} ${days === 1 ? 'day' : 'days'} ago`;
    } else if (months < 12) {
        relativeTime = `${months} ${months === 1 ? 'month' : 'months'} ago`;
    } else {
        relativeTime = `${years} ${years === 1 ? 'year' : 'years'} ago`;
    }

    return `${relativeTime} (${formattedDate} UTC)`;
};
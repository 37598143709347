import React, { useEffect, useState } from 'react';


import { IResourceComponentsProps, useInvalidate, useOne, useUpdate } from "@refinedev/core";

import {
    List,
    useTable,
    useModalForm,
    RefreshButton,
    CreateButton,
    DeleteButton,
    ShowButton,
    EditButton,
} from "@refinedev/antd";

import { Form, Input, Modal, Avatar, Card, Skeleton, Switch, Col, Row, Spin, Space, Tooltip, Select, Tag  } from "antd";
const { Meta } = Card;
import { EyeOutlined, EditOutlined, DeleteOutlined, SettingOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';

import { IUser } from "interfaces";
import Typography from 'antd/lib/typography';
import { Link } from 'react-router-dom';

import { List as ListAntd } from "antd";

export const UsersList: React.FC<IResourceComponentsProps> = () => {
    const [isLoading, setIsLoading] = React.useState<Array<boolean>>([]);
    const [users, setUsers] = React.useState<Array<IUser>>();
    const cdn_domain_name = process.env.REACT_APP_CDN_URL
    
    const {
        tableQueryResult,
    } = useTable<IUser>({
        meta: { 
            "last_evaluated_key": "null",
            "limit": "null",
            "search": "null"
        },
    });

    let [editUser, setEditUser] = React.useState<any>() 
    let [editUserChecked, setEditUserChecked] = React.useState<boolean>(false) 
    let [enableDetailedEdit, setEnableDetailedEdit] = React.useState<boolean>(false) 

    // Show users task settings Modal
    const {
        modalProps: showUserSettingsModalProps,
        formProps: showUserSettingsFormProps,
        show: showUserSettingsModalShow,
        setId,
        onFinish,
        close: closeUpdateUserModal,
    } = useModalForm({
        id: editUser?.id,
        action: "edit",
        resource: "users",
        warnWhenUnsavedChanges: false,
        syncWithLocation: false,
        disableServerSideValidation: true,
        onMutationSuccess: (data, variables, context, isAutoSave) => {
            closeUpdateUserModal()
            refreshTableResults()
            let items = [...isLoading];
            for (let i = 0; i < items.length; i++) {
                items[i] = false
              }
            
            setIsLoading(items) 
            setEnableDetailedEdit(false)
        },
        onMutationError: (data, variables, context, isAutoSave) => {
            let items = [...isLoading];
            for (let i = 0; i < items.length; i++) {
                items[i] = false
              }
            
            setIsLoading(items) 
        },
    });

    const onFinishUpdateUser = (values : any ) => {
        console.log("hello finish")
        console.log(values)
        console.log("hello User")
        console.log(editUser)
        let new_obj = {}
        if(values.updateCheckedOnly) {
            console.log("Only updating checked value")

            new_obj = {
                id: `${values.record.id}`,
                status: values.enabled == true ? "active" : "inactive",
                roles: values.record.roles,
                org_id: values.record.org_id,
                user_id: values.record.id,
                name: values.record.name
            }
        } else{

    
            // if (typeof new_context === 'object' || new_context instanceof Object){
            //     new_context = JSON.stringify(new_context)
            // } 
    
            new_obj = {
                id: `${editUser.id}`,
            }
        }


        console.log("hello obj")
        console.log(new_obj)
        onFinish?.(new_obj);

        close();
    };


    

    const editUserSettings = async (record: IUser) => {
        console.log("Edit task settings")
        if(record){
            console.log(record)
            setEnableDetailedEdit(false)
            setId(record.id);
            setEditUser(record)
            // setEditUserChecked(record.enabled)
            showUserSettingsModalShow(record.id)
        }

      };

    const handleDetailedScreenChangeUserEnabled = (checked: boolean) => {
        editUser.enabled = checked
        setEditUserChecked(checked)
        setEnableDetailedEdit(checked)
    };

    const onChangeMainScreenEnabledUser = (checked: boolean, index: any, record: IUser) => {
        // 1. Make a shallow copy of the items
        let items = [...isLoading];
        items[index] = true
        setIsLoading(items) 

        onFinishUpdateUser({
            "updateCheckedOnly": "updateCheckedOnly",
            "enabled": checked,
            "record": record
        })
    };
    
    const [isLoadingTable, setIsLoadingTable] = React.useState<boolean>(false);
    const refreshTableResults = () => {
        if(tableQueryResult?.data){
            setUsers([])
        }
        
        tableQueryResult.refetch()
    };
    
    
    useEffect(() => {
        if(tableQueryResult){
            if(tableQueryResult.data){
                setUsers(tableQueryResult?.data.data)
            }
            setIsLoadingTable(tableQueryResult.isRefetching)
        }
       
      }, [editUser, tableQueryResult, users, enableDetailedEdit]);

    return (
        <>
    <List 
        key="id"
        title="users"      
        headerButtons={
            <><RefreshButton
            loading={isLoadingTable}
                resource="users" onClick={(e) => refreshTableResults()} />
            <CreateButton
                    resource="users" /></>
      }>
        </List>
        <Modal {...showUserSettingsModalProps} title="Task settings" centered>
            <Form 
                {...showUserSettingsFormProps}
                fields={[
                {
                    name: ["id"],
                    value: editUser?.id,
                },
                {
                    name: ["type"],
                    value: editUser?.type,
                },
                {
                    name: ["stage"],
                    value: editUser?.stage,
                },
                {
                    name: ["name"],
                    value: editUser?.task.name,
                },
                {
                    name: ["description"],
                    value: editUser?.task.description,
                },
                {
                    name: ["timeout_seconds"],
                    value: editUser?.task.timeout_seconds,
                },
                {
                    name: ["heart_beat_seconds"],
                    value: editUser?.task.heart_beat_seconds,
                },
                {
                    name: ["context"],
                    value: editUser?.task.context,
                },
                {
                    name: ["arn"],
                    value: editUser?.task.arn,
                },
                {
                    name: ["field_name"],
                    value: editUser?.task.field_name,
                },
                {
                    name: ["enabled"],
                    value: editUser?.task.enabled,
                },
                ]}
                layout="vertical"
                onFinish={onFinishUpdateUser}
                >
                <Form.Item
                    label="Id"
                    name="id"
                    style={{ width: 500 }}
                    hidden={true}
                >
                    <Input defaultValue={editUser?.id} disabled={false}/>
                </Form.Item>
               <Form.Item
                    label="Type"
                    name="type"
                    style={{ width: 500 }}
                    hidden={true}
                >
                    <Input defaultValue={editUser?.type} disabled={!enableDetailedEdit}/>
                </Form.Item>
                <Form.Item
                    label="stage"
                    name="stage"
                    style={{ width: 500 }}
                    hidden={true}
                >
                    <Input defaultValue={editUser?.stage} disabled={!enableDetailedEdit}/>
                </Form.Item>
                 <Form.Item
                    label="Name"
                    name="name"
                    style={{ width: 500 }}
                >
                    <Input defaultValue={editUser?.task.name} disabled={!enableDetailedEdit}/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    style={{ width: 500 }}
                >
                    <Input  defaultValue={editUser?.task.description} disabled={!enableDetailedEdit}/>
                </Form.Item>
                <Form.Item name="timeout_seconds" label="Timeout in Seconds">
                    <Input defaultValue={editUser?.task.timeout_seconds} type="textarea" disabled={!enableDetailedEdit} />
                </Form.Item>
                <Form.Item name="heart_beat_seconds" label="Heartbeat in Seconds">
                    <Input defaultValue={editUser?.task.heart_beat_seconds} type="textarea" disabled={!enableDetailedEdit} />
                </Form.Item>
                <Form.Item 
                    name="context" 
                    label="Context"
                    rules={[
                        {
                            required: false,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                try{
                                    if (typeof value === 'object' || value instanceof Object){
                                        JSON.stringify(value)
                                    } else{
                                        JSON.parse(value)
                                    }
                                    
                                    return Promise.resolve();
                                }
                                catch{
                                    return Promise.reject(
                                        new Error("'No valid JSON"),
                                    );
                                }
                            },
                        },
                    ]}             
                    getValueProps={(v) => {
                        if (typeof v === 'string' || v instanceof String){
                            return ({ name: 'context', value: v });
                        } else{
                            return ({ name: 'context', value: JSON.stringify(editUser?.task.context) });
                        }
                    }}>
                    <Input type="textarea"  disabled={!enableDetailedEdit}/>
                </Form.Item>
                <Form.Item name="arn" label="Lambda Arn">
                    <Input defaultValue={editUser?.task.arn} type="textarea" disabled={!enableDetailedEdit} />
                </Form.Item>
                <Form.Item name="field_name" label="Field Name">
                    <Input defaultValue={editUser?.task.field_name} type="textarea" disabled={!enableDetailedEdit} />
                </Form.Item>
                <Form.Item 
                    name="enabled" 
                    label={<Link to={''}>Edit User?</Link>       }
                    labelAlign="right"
                    // getValueProps={(v) => {
                    //     return ({ name: 'enabled', value: editUser?.task.enabled });
                    //     }}
                    >                        
                    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={handleDetailedScreenChangeUserEnabled} style={{marginTop: 20}} defaultChecked={false} />
                </Form.Item>
            </Form>
        </Modal>
        
        <Row gutter={16}>
        {
            users ? users.map((record: IUser, index) => 
            <Col span={8}>
                <Card
                    key={record.id}
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    actions={[
                    <ShowButton
                        hideText
                        size="small"
                        recordItemId={record?.id}
                    />,
                    <EditButton
                        hideText
                        size="small"
                        recordItemId={record?.id}
                    />,
                    // <Tooltip title="More"><EyeOutlined key="setting" onClick={() => editUserSettings(record)} /></Tooltip>,
                    // <Tooltip title="Edit"><Link to={'https://' + process.env.REACT_APP_API_BASE_URL + "/code-editor/index.html?id="+ record.id} target="_blank"><EditOutlined key="edit" /></Link></Tooltip>,
                    <Tooltip title="Delete">
                        <DeleteButton resource="users" recordItemId={record.id} hideText size='small'/>
                    </Tooltip>,
                    ]}
                >
                    <Skeleton loading={false} avatar active>
                    <Meta
                        avatar={<Avatar src={"https://xsgames.co/randomusers/avatar.php?g=pixel&key=" + index} />}
                        // avatar={<Avatar src={`https://${cdn_domain_name}/public/GET/cdn/users/logos/png/${record.id}.png`} />}
                        title={record.name}
                        // description={record.description}
                    />
                    <div className="additional" style={{marginTop: 20}}>
                        <p>Id: <Tag>{record.id}</Tag></p>
                        <p>Email: <Tag>{record.email}</Tag></p>
                        {/* <p>Stages: <span>{record.integration_stages}</span></p> */}
                    <p>Role(s): 
                    <Tooltip title="Role(s)">
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            disabled={true}
                            value={record?.roles}
                        />
                    </Tooltip>
                    </p>
        
                    </div>
                    <p>Enabled:
                    <Spin spinning={isLoading[index] ? isLoading[index]: false}>
                        <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} style={{marginTop: 20}} defaultChecked={record.status == "active" ? true : false} onChange={(e) => {onChangeMainScreenEnabledUser(e, index, record)}}  />
                    </Spin>
                    </p>
                    </Skeleton>
                    
                </Card>
            </Col>
            ) :             
            <Col span={8}>
                <Card
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    actions={[
                    <Tooltip title="More"><EyeOutlined key="setting_loading" /></Tooltip>,
                    // <Tooltip title="Edit"><EditOutlined key="edit_loading" /></Tooltip>,
                    // <Tooltip title="Delete"> <DeleteOutlined key="delete_loading" /></Tooltip>,
                    ]}
                >
                    <Skeleton loading={true} avatar active>
                    </Skeleton>
                </Card>
            </Col>
        }
                    
        </Row>
        
        </>
    );
    
};

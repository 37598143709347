import { List, Space, Avatar, Tag, Collapse, Statistic, Row, Col, Typography, Tooltip, Button, Skeleton, Divider } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { shortenAddress, getTooltipTitleForParamType, getTagColorByParamType } from '../../../utils/formatters';
import { EditButton } from '@refinedev/antd';
import { useState } from 'react';

const { Title, Text, Link } = Typography;

interface InternalTransactionsListProps {
    decodedTransactionData: any;
    record: any;
    displayCount: number;
    setDisplayCount: (count: number) => void;
    showInternalTransaction: (event: any) => void;
    cdn_domain_name: string;
}

export const InternalTransactionsList: React.FC<InternalTransactionsListProps> = ({
    decodedTransactionData,
    record,
    displayCount,
    setDisplayCount,
    showInternalTransaction,
    cdn_domain_name,
}) => {
    
    // Calculate container height based on number of internal transactions
    const internalTxCount = decodedTransactionData?.decoded_internal_transactions ? 
        Object.keys(decodedTransactionData.decoded_internal_transactions).length : 0;
    
    // Each internal transaction item is roughly 100px tall, add some padding
    // Min height of 120px for single transaction, max height of 400px
    const containerHeight = Math.min(
        Math.max(internalTxCount * 100 + 20, 120), 
        400
    );

    // Helper function to extract addresses from params
    const extractAddressesFromParams = (param: any) => {
        if (param.type === 'address') return [param.value.toLowerCase()];
        if (param.type === 'address[]') return param.value.map((addr: string) => addr.toLowerCase());
        return [];
    };

    // Helper function to get known addresses
    const getKnownAddresses = (addresses: Set<string>) => {
        return Array.from(addresses).filter(address => {
            if (record?.is_search_transaction) {
                return (
                    (address === record?.from_address?.toLowerCase() && record?.from_address_entity) ||
                    (address === record?.to_address?.toLowerCase() && record?.to_address_entity) ||
                    record?.address_items_map?.[address]?.labels?.length > 0
                );
            }
            return record?.address_items_map?.[address]?.labels?.length > 0;
        });
    };

    // Helper function to normalize internal transaction format
    const normalizeInternalTransaction = (transaction: any) => {
        // If it's already in the expected format, return as is
        if (transaction.type) return transaction;

        // Convert the new format to match expected structure
        return {
            type: transaction.input?.label || 'Transfer',
            from: transaction.from,
            to: transaction.to,
            value: transaction.value,
            input: transaction.input,
            output: transaction.output,
            gas_used: transaction.gas_used,
            address: transaction.address
        };
    };

    // Convert internal transactions to array if it's an object
    const internalTransactions = decodedTransactionData?.decoded_internal_transactions
    ? Array.isArray(decodedTransactionData.decoded_internal_transactions)
        ? decodedTransactionData.decoded_internal_transactions
        : Object.values(decodedTransactionData.decoded_internal_transactions)
    : [];

    // Normalize all transactions
    const normalizedTransactions = internalTransactions.map(normalizeInternalTransaction);

    if (!normalizedTransactions || normalizedTransactions.length === 0) {
        return (
            <>
                <Title level={5}>Internal Transactions:</Title>
                <div style={{ 
                    padding: '24px',
                    textAlign: 'center',
                    background: '#fafafa',
                    border: '1px solid #f0f0f0',
                    borderRadius: '4px'
                }}>
                    <Text type="secondary">No internal transactions found</Text>
                </div>
                <Divider></Divider>
            </>
        );
    }

    return  (
            <>
                <Title level={5} style={{ marginBottom: 16 }}>Internal Transactions:</Title>
                <div 
                    id="eventsScrollContainer"
                    style={{ 
                        height: `${containerHeight}px`,
                        overflow: 'auto',
                        border: '1px solid #f0f0f0',
                        borderRadius: '4px',
                        padding: '0 16px'
                    }}
                >
                    <InfiniteScroll
                        dataLength={displayCount}
                        next={function() { setDisplayCount(displayCount + 10) }}
                        hasMore={normalizedTransactions ? 
                            displayCount < normalizedTransactions?.length : 
                            false
                        }
                        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                        endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                        scrollableTarget="internalTxScrollContainer"
                        style={{ overflow: 'hidden' }}
                    >
                        <List
                            dataSource={Array.isArray(normalizedTransactions) ? 
                                normalizedTransactions.slice(0, displayCount) : 
                                []
                            }                
                            renderItem={(item: any, index: any) => (
                            <List.Item key={item}>
                            <List.Item.Meta
                                avatar={
                                    <Space size={-6}>
                                        {/* From Address Avatar */}
                                        <Tooltip title={
                                            record?.is_search_transaction && item.from === record?.from_address && record?.from_address_entity
                                                ? `${record?.from_address_entity} (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
                                                : record?.is_search_transaction && item.from === record?.to_address && record?.to_address_entity
                                                    ? `${record?.to_address_entity} (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
                                                    : record?.address_items_map?.[item.from]?.labels?.[0]?.name
                                                        ? `${record?.address_items_map?.[item.from]?.labels?.[0]?.name} (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
                                                        : `unknown address label (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
                                        }>
                                            <Avatar 
                                                src={
                                                    record?.is_search_transaction && item.from === record?.from_address && record?.from_address_entity_logo
                                                        ? record?.from_address_entity_logo
                                                        : record?.is_search_transaction && item.from === record?.to_address && record?.to_address_entity_logo
                                                            ? record?.to_address_entity_logo
                                                            : record?.address_items_map?.[item.from]?.main_labels?.[0]?.logo || record?.address_items_map?.[item.from]?.labels?.[0]?.logo || `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`
                                                }
                                                style={{ 
                                                    border: '1px solid #f0f0f0',
                                                    zIndex: 1
                                                }}
                                            />
                                        </Tooltip>
                                        {/* To Address Avatar */}
                                        <Tooltip title={
                                            record?.is_search_transaction && item.to === record?.from_address && record?.from_address_entity
                                                ? `${record?.from_address_entity} (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
                                                : record?.is_search_transaction && item.to === record?.to_address && record?.to_address_entity
                                                    ? `${record?.to_address_entity} (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
                                                    : record?.address_items_map?.[item.to]?.labels?.[0]?.name
                                                        ? `${record?.address_items_map?.[item.to]?.labels?.[0]?.name} (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
                                                        : `unknown address label (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
                                        }>
                                            <Avatar 
                                                src={
                                                    record?.is_search_transaction && item.to === record?.from_address && record?.from_address_entity_logo
                                                        ? record?.from_address_entity_logo
                                                        : record?.is_search_transaction && item.to === record?.to_address && record?.to_address_entity_logo
                                                            ? record?.to_address_entity_logo
                                                            : record?.address_items_map?.[item.to]?.main_labels?.[0]?.logo || record?.address_items_map?.[item.to]?.labels?.[0]?.logo || record?.address_items_map?.[item.to]?.labels?.[0]?.logo || `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`
                                                }
                                                style={{ 
                                                    border: '1px solid #f0f0f0',
                                                    marginLeft: '-8px',
                                                    zIndex: 0
                                                }}
                                            />
                                        </Tooltip>
                                    </Space>
                                }
                                title={<a onClick={() => showInternalTransaction(normalizedTransactions[index])}>{(`Type: ${item.type}` || "") + ((normalizedTransactions[index]?.input?.label || normalizedTransactions[index]?.output?.label) ? " - " : "") + (normalizedTransactions[index]?.input?.label || normalizedTransactions[index]?.output?.label || "")}</a>}
                                description={<Button onClick={(e) => showInternalTransaction(normalizedTransactions[index])} size="small">Show decoded internal transaction info</Button>}
                            />
                            <Space>
                                {normalizedTransactions[index]?.input?.params?.slice(0, 3).map((param: any) => (
                                    <Tooltip title={getTooltipTitleForParamType(record?.address_items_map, param)}>
                                        <Tag color={getTagColorByParamType(param.type)}>
                                            {param.name}
                                        </Tag>
                                    </Tooltip>
                                ))}
                                {normalizedTransactions[index]?.input?.params?.length > 3 && (
                                    <Tooltip title="Click 'Show decoded internal transaction info' to see all parameters">
                                        <Tag onClick={() => showInternalTransaction(normalizedTransactions[index])} color="magenta">
                                            +{normalizedTransactions[index]?.input?.params.length - 3} more
                                        </Tag>
                                    </Tooltip>
                                )}
                                <Tooltip title={
                                    record?.is_search_transaction && item.from === record?.from_address && record?.from_address_entity
                                    ? `${record?.from_address_entity} (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
                                    : record?.is_search_transaction && item.from === record?.to_address && record?.to_address_entity
                                        ? `${record?.to_address_entity} (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
                                        : record?.address_items_map?.[item.from]?.labels?.[0]?.name
                                            ? `${record?.address_items_map?.[item.from]?.labels?.[0]?.name} (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
                                            : "unknown address label"
                                }>
                                    <Button onClick={(e) => showInternalTransaction(normalizedTransactions[index])} size="small">
                                        {item.from ? `${item.from.slice(0, 6)}...${item.from.slice(-6)}` : ""}
                                    </Button>
                                </Tooltip>
                                <Text>{"->"}</Text>
                                <Tooltip title={
                                    record?.is_search_transaction && item.to === record?.from_address && record?.from_address_entity
                                    ? `${record?.from_address_entity} (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
                                    : record?.is_search_transaction && item.to === record?.to_address && record?.to_address_entity
                                        ? `${record?.to_address_entity} (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
                                        : record?.address_items_map?.[item.to]?.labels?.[0]?.name
                                            ? `${record?.address_items_map?.[item.to]?.labels?.[0]?.name} (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
                                            : "unknown address label"
                                }>
                                    <Button onClick={(e) => showInternalTransaction(normalizedTransactions[index])} size="small">
                                        {item.to ? `${item.to.slice(0, 6)}...${item.to.slice(-6)}` : ""}
                                    </Button>
                                </Tooltip>
                            </Space>
                            </List.Item>
                        )}
                        />
                    </InfiniteScroll> 
                </div>                
            </>
    )
};
export interface DebugOptions {
    enabled?: boolean;
    prefix?: string;
    tabId?: string;
}

export class Debug {
    private enabled: boolean;
    private prefix: string;
    private tabId: string;

    constructor(options: DebugOptions = {}) {
        this.enabled = options.enabled ?? process.env.NODE_ENV === 'development';
        this.prefix = options.prefix ?? '';
        this.tabId = options.tabId ?? `Tab-${Math.random().toString(36).substr(2, 5)}`;
    }

    getTabId(): string {
        return this.tabId;
    }

    log(message: string, data?: any) {
        if (!this.enabled) return;
        
        const timestamp = new Date().toISOString();
        const prefix = this.prefix ? `[${this.prefix}]` : '';
        const tabInfo = `[${this.tabId}]`;
        
        console.log(`${timestamp} ${tabInfo} ${prefix} ${message}`, data || '');
    }

    error(message: string, error?: any) {
        if (!this.enabled) return;
        
        const timestamp = new Date().toISOString();
        const prefix = this.prefix ? `[${this.prefix}]` : '';
        const tabInfo = `[${this.tabId}]`;
        
        console.error(`${timestamp} ${tabInfo} ${prefix} ${message}`, error || '');
    }

    group(label: string) {
        if (!this.enabled) return;
        console.group(`[${this.tabId}] ${label}`);
    }

    groupEnd() {
        if (!this.enabled) return;
        console.groupEnd();
    }

    // Add debug info to window for console access
    attachToWindow() {
        if (!this.enabled) return;

        (window as any).debugSubscription = {
            ...(window as any).debugSubscription,
            tabId: this.tabId,
            isLeader: null, // Will be set by the subscription system
            hasActiveSubscription: null, // Will be set by the subscription system
            connectionStatus: null, // Will be set by the subscription system
            checkAllTabs: () => {
                const channel = new BroadcastChannel('subscription-coordination');
                this.group('Checking AppSync connections across all tabs');
                this.log(`Current tab status will be reported by status check`);
                
                channel.postMessage({
                    type: 'status-check',
                    tabId: this.tabId,
                    timestamp: Date.now()
                });
                
                setTimeout(() => {
                    this.groupEnd();
                    channel.close();
                }, 1000);
            },
            forceReconnect: null, // Will be set by the subscription system
        };
    }

    // Update window debug info
    updateWindowDebug(info: {
        isLeader?: boolean;
        hasActiveSubscription?: boolean;
        connectionStatus?: any;
        forceReconnect?: () => void;
    }) {
        if (!this.enabled) return;

        (window as any).debugSubscription = {
            ...(window as any).debugSubscription,
            ...info
        };
    }
}

export const createDebugger = (options?: DebugOptions) => {
    const debug = new Debug(options);
    debug.attachToWindow();
    return debug;
};

// Helper to parse data safely
export const safeJSONParse = (data: any) => {
    if (typeof data === 'string') {
        try {
            return JSON.parse(data);
        } catch (error) {
            console.error('Failed to parse JSON:', error);
            return data;
        }
    }
    return data;
};

// Helper to format debug data
export const formatDebugData = (data: any) => {
    if (!data) return '';
    
    try {
        if (typeof data === 'object') {
            return JSON.stringify(data, null, 2);
        }
        return String(data);
    } catch (error) {
        return 'Error formatting debug data';
    }
}; 
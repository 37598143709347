// Helper to find parameter value by name patterns
export const findParamInEventValue = (event: any, patterns: string[]) => {
    // First try exact matches
    for (const pattern of patterns) {
        const param = event.params.find((p: any) => p.name.toLowerCase() === pattern.toLowerCase());
        if (param) return param.value;
    }
    
    return null;
};

export const findParamInDecodedCall = (decodedCall: any, patterns: string[]) => {
    if (!decodedCall) return null;
    // First try exact matches
    for (const pattern of patterns) {
        const param = decodedCall.params.find((p: any) => p.name.toLowerCase() === pattern.toLowerCase());
        if (param) return param.value;
    }
    
    return null;
};
import { useEffect, useRef, useCallback } from 'react';
import { createDebugger } from '../leader/debug';
import { BroadcastMessage } from '../../graphql/types';

interface BroadcastChannelHookOptions {
    onMessage?: (event: MessageEvent<BroadcastMessage>) => void;
    debug?: boolean;
    channelPrefix?: string;
}

export const useBroadcastChannel = (channelName: string, options: BroadcastChannelHookOptions = {}) => {
    const channelRef = useRef<BroadcastChannel | null>(null);
    const debug = createDebugger({ 
        prefix: options.channelPrefix || 'Broadcast',
        enabled: options.debug
    });

    const postMessage = useCallback((message: BroadcastMessage) => {
        if (channelRef.current) {
            debug.log(`Broadcasting message on ${channelName}:`, message);
            channelRef.current.postMessage(message);
        } else {
            debug.error(`Cannot post message - channel ${channelName} not initialized`);
        }
    }, [channelName]);

    useEffect(() => {
        debug.log(`Initializing broadcast channel: ${channelName}`);
        channelRef.current = new BroadcastChannel(channelName);

        const handleMessage = (event: MessageEvent<BroadcastMessage>) => {
            debug.log(`Received message on ${channelName}:`, event.data);
            options.onMessage?.(event);
        };

        channelRef.current.addEventListener('message', handleMessage);
        
        return () => {
            debug.log(`Cleaning up broadcast channel: ${channelName}`);
            channelRef.current?.removeEventListener('message', handleMessage);
            channelRef.current?.close();
            channelRef.current = null;
        };
    }, [channelName, options.onMessage]);

    const broadcastTransaction = useCallback((transaction: any, messageId: string) => {
        postMessage({
            type: 'new-transaction',
            data: transaction,
            messageId,
            tabId: debug.getTabId(),
            fromSubscription: true,
            timestamp: Date.now()
        });
    }, [postMessage]);

    const checkAllTabs = useCallback(() => {
        debug.group('Checking all tabs');
        postMessage({
            type: 'status-check',
            tabId: debug.getTabId(),
            timestamp: Date.now()
        });
        
        setTimeout(() => {
            debug.groupEnd();
        }, 1000);
    }, [postMessage]);

    const broadcastLeaderStatus = useCallback((isLeader: boolean) => {
        postMessage({
            type: isLeader ? 'leader-active' : 'leader-departed',
            tabId: debug.getTabId(),
            timestamp: Date.now()
        });
    }, [postMessage]);

    const requestLeaderCheck = useCallback(() => {
        postMessage({
            type: 'leader-check',
            tabId: debug.getTabId(),
            timestamp: Date.now()
        });
    }, [postMessage]);

    const respondToStatusCheck = useCallback((status: BroadcastMessage['status'], senderTabId: string) => {
        postMessage({
            type: 'status-response',
            status,
            tabId: debug.getTabId(),
            senderTabId,
            timestamp: Date.now()
        });
    }, [postMessage]);

    const broadcastSubscriptionToggle = useCallback((enabled: boolean) => {
        postMessage({
            type: 'subscription-toggle',
            enabled,
            tabId: debug.getTabId(),
            timestamp: Date.now()
        });
    }, [postMessage]);

    return {
        channel: channelRef.current,
        postMessage,
        broadcastTransaction,
        checkAllTabs,
        broadcastLeaderStatus,
        requestLeaderCheck,
        respondToStatusCheck,
        broadcastSubscriptionToggle
    };
};

// Helper to create channel names
export const createChannelName = (prefix: string, identifiers: Record<string, string | undefined>) => {
    return Object.entries(identifiers)
        .filter(([_, value]) => value !== undefined)
        .reduce((name, [key, value]) => `${name}-${key}-${value}`, prefix);
}; 
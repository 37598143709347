import React, { ReactNode, createContext, useContext, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { ConfigContextType, RegionConfig, AppSyncClient } from './types';

const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

// Create a broadcast channel for cross-tab communication
const transactionChannel = new BroadcastChannel('transaction-updates');

export const DashboardConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuth0();
  const token = JSON.parse(localStorage.getItem('token') || '{}');
  
  const local_storage_prefix = "DASHBOARD-" + 
    process.env.REACT_APP_ENV + "-" + 
    user?.sub + "-" + 
    user?.[process.env.REACT_APP_BASE_URL + "/org_id"] + "-" + 
    user?.[process.env.REACT_APP_BASE_URL + "/tenant_id"];

  const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"];
  
  // Determine which region to use based on storage or time
  let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"');
  
  let region: RegionConfig;
  if(storage_use_region_alias && storage_use_region_alias !== "default" && storage_use_region_alias !== undefined) {
    if(storage_use_region_alias === region_config?.region_alias) {
      region = {
        use_region: region_config?.region,
        use_region_alias: region_config?.region_alias,
        backup_region: region_config?.backup_region,
        backup_region_alias: region_config?.backup_region_alias
      };
    } else {
      // Switch
      region = {
        use_region: region_config?.backup_region,
        use_region_alias: region_config?.backup_region_alias,
        backup_region: region_config?.region,
        backup_region_alias: storage_use_region_alias
      };
    }
  } else {
    // Switch region based on time/minutes
    const now = new Date().getUTCMinutes() % 5;
    if(now > 2) {
      region = {
        use_region: region_config?.backup_region,
        use_region_alias: region_config?.backup_region_alias,
        backup_region: region_config?.region,
        backup_region_alias: region_config?.region_alias
      };
      console.log("Using back up region: " + region.use_region_alias);
    } else {
      region = {
        use_region: region_config?.region,
        use_region_alias: region_config?.region_alias,
        backup_region: region_config?.backup_region,
        backup_region_alias: region_config?.backup_region_alias
      };
      console.log("Using main region: " + region.use_region_alias);
    }
  }

  const [isSubscriptionEnabled, setIsSubscriptionEnabled] = useState<boolean>(true);
  const [appSyncClient, setAppSyncClient] = useState<AppSyncClient | null>(null);

  const toggleSubscription = (enabled: boolean) => {
    console.log("toggleSubscription", enabled);
    setIsSubscriptionEnabled(enabled);
    localStorage.setItem('transactionSubscriptionEnabled', String(enabled));
  };

  // Sync subscription state across tabs
  useEffect(() => {
    const savedState = localStorage.getItem('transactionSubscriptionEnabled');
    if (savedState !== null) {
      setIsSubscriptionEnabled(savedState === 'true');
    }
  }, []);

  const config: ConfigContextType = {
    user,
    token,
    cdn_domain_name: process.env.REACT_APP_CDN_URL || '',
    region,
    continent: token[process.env.REACT_APP_BASE_URL + "/continent"],
    domain: process.env.REACT_APP_API_BASE_URL || '',
    org_id: token[process.env.REACT_APP_BASE_URL + "/org_id"],
    tenant_id: token[process.env.REACT_APP_BASE_URL + "/tenant_id"],
    user_id: token["sub"],
    qa_environment: token[process.env.REACT_APP_BASE_URL + "/qa_config"]?.environment,
    local_storage_prefix,
    transactionSubscription: {
      isEnabled: isSubscriptionEnabled,
      client: appSyncClient || null,
      toggleSubscription,
    },
  };

  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const config = useContext(ConfigContext);
  if (!config) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return config;
};
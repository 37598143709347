import React, { useMemo } from 'react';
import { 
  Space, 
  Typography, 
  Tag, 
  Empty, 
  Card, 
  Table, 
  Tooltip, 
  Collapse,
  Badge,
  Alert
} from 'antd';
import { 
  DiffOutlined, 
  CheckCircleOutlined, 
  CloseCircleOutlined,
  QuestionCircleOutlined,
  BlockOutlined,
  SyncOutlined
} from '@ant-design/icons';

const { Text, Title, Paragraph } = Typography;
const { Panel } = Collapse;

// Import the same types from the parent component
import { AddressItemsMap, ContractStateData } from './ContractStateHighlighter';

interface ContractCrossChainComparisonProps {
  contractStateData: ContractStateData;
  formatAddress: (address: string, addressItemsMap?: AddressItemsMap) => any;
  addressItemsMap: AddressItemsMap;
  getChainName: (chainId: string) => string;
}

const ContractCrossChainComparison: React.FC<ContractCrossChainComparisonProps> = ({
  contractStateData,
  formatAddress,
  addressItemsMap,
  getChainName
}) => {
  // Get all contracts from the data
  const contracts = useMemo(() => {
    return Object.values(contractStateData?.contracts || {});
  }, [contractStateData?.contracts]);

  // Group contracts by address (across chains)
  const contractsByAddress = useMemo(() => {
    const grouped: Record<string, any[]> = {};
    
    contracts.forEach(contract => {
      const normalizedAddress = contract.address.toLowerCase();
      if (!grouped[normalizedAddress]) {
        grouped[normalizedAddress] = [];
      }
      grouped[normalizedAddress].push(contract);
    });
    
    return grouped;
  }, [contracts]);

  // Extract all unique state variable keys across all contracts
  const allStateKeys = useMemo(() => {
    const keys = new Set<string>();
    
    contracts.forEach(contract => {
      // Check all state types (before, after, latest)
      ['before', 'after', 'latest'].forEach(stateType => {
        const states = contract.states[stateType as keyof typeof contract.states];
        if (states && states.length > 0 && states[0]?.state) {
          Object.keys(states[0].state).forEach(key => {
            keys.add(key);
          });
        }
      });
    });
    
    return Array.from(keys);
  }, [contracts]);

  // Get block number information for each contract
  const blockInfo = useMemo(() => {
    const info: Record<string, Record<string, {
      before?: number | string;
      after?: number | string;
      latest?: number | string;
      blockDifference?: number;
    }>> = {};
    
    Object.entries(contractsByAddress).forEach(([address, contractsForAddress]) => {
      info[address] = {};
      
      contractsForAddress.forEach(contract => {
        const chainId = contract.chainId;
        info[address][chainId] = {};
        
        if (contract.states.before && contract.states.before.length > 0) {
          info[address][chainId].before = contract.states.before[0].blockNumber;
        }
        
        if (contract.states.after && contract.states.after.length > 0) {
          info[address][chainId].after = contract.states.after[0].blockNumber;
        }
        
        if (contract.states.latest && contract.states.latest.length > 0) {
          info[address][chainId].latest = contract.states.latest[0].blockNumber;
        }
        
        // Calculate block difference if both before and latest exist
        if (typeof info[address][chainId].before === 'number' && 
            typeof info[address][chainId].latest === 'number') {
          info[address][chainId].blockDifference = 
            (info[address][chainId].latest as number) - (info[address][chainId].before as number);
        }
      });
    });
    
    return info;
  }, [contractsByAddress]);

  // Calculate block height differences between chains
  const blockHeightDifferences = useMemo(() => {
    const differences: Record<string, {
      maxDifference: number;
      averageBlocksBehind: number;
      chainsInfo: Record<string, {
        blocksBehind: number;
        percentageBehind: number;
      }>;
    }> = {};
    
    Object.entries(blockInfo).forEach(([address, chainsBlockInfo]) => {
      const latestBlocks: Record<string, number> = {};
      let maxBlock = 0;
      
      // Find the latest block for each chain
      Object.entries(chainsBlockInfo).forEach(([chainId, blocks]) => {
        if (typeof blocks.latest === 'number') {
          latestBlocks[chainId] = blocks.latest;
          maxBlock = Math.max(maxBlock, blocks.latest);
        }
      });
      
      if (maxBlock > 0) {
        const chainsInfo: Record<string, {
          blocksBehind: number;
          percentageBehind: number;
        }> = {};
        
        let totalBlocksBehind = 0;
        let chainCount = 0;
        
        Object.entries(latestBlocks).forEach(([chainId, blockNumber]) => {
          const blocksBehind = maxBlock - blockNumber;
          const percentageBehind = blockNumber > 0 ? (blocksBehind / blockNumber) * 100 : 0;
          
          chainsInfo[chainId] = {
            blocksBehind,
            percentageBehind
          };
          
          totalBlocksBehind += blocksBehind;
          chainCount++;
        });
        
        differences[address] = {
          maxDifference: Object.values(chainsInfo).reduce((max, info) => 
            Math.max(max, info.blocksBehind), 0),
          averageBlocksBehind: chainCount > 0 ? totalBlocksBehind / chainCount : 0,
          chainsInfo
        };
      }
    });
    
    return differences;
  }, [blockInfo]);

  // Compare state values across chains for each contract address
  const crossChainComparisons = useMemo(() => {
    return Object.entries(contractsByAddress).map(([address, contractsForAddress]) => {
      // Skip if there's only one chain
      if (contractsForAddress.length <= 1) {
        return {
          address,
          contracts: contractsForAddress,
          stateComparisons: [],
          hasDifferences: false,
          blockInfo: blockInfo[address] || {},
          blockHeightDifference: blockHeightDifferences[address]
        };
      }
      
      // Compare state variables across chains
      const stateComparisons = allStateKeys.map(key => {
        const valuesByChain: Record<string, any> = {};
        let hasDifferences = false;
        let firstValue: any = undefined;
        let firstChain: string | null = null;
        
        contractsForAddress.forEach(contract => {
          // Get the latest state value for this key
          const latestState = contract.states.latest?.[0]?.state;
          const value = latestState?.[key];
          
          if (value !== undefined) {
            valuesByChain[contract.chainId] = value;
            
            // Check for differences
            if (firstValue === undefined) {
              firstValue = value;
              firstChain = contract.chainId;
            } else {
              // Compare with first value
              if (JSON.stringify(value) !== JSON.stringify(firstValue)) {
                hasDifferences = true;
              }
            }
          }
        });
        
        return {
          key,
          valuesByChain,
          hasDifferences,
          referenceChain: firstChain
        };
      }).filter(comparison => Object.keys(comparison.valuesByChain).length > 0);
      
      // Check if there are any differences
      const hasDifferences = stateComparisons.some(comparison => comparison.hasDifferences);
      
      return {
        address,
        contracts: contractsForAddress,
        stateComparisons,
        hasDifferences,
        blockInfo: blockInfo[address] || {},
        blockHeightDifference: blockHeightDifferences[address]
      };
    });
  }, [contractsByAddress, allStateKeys, blockInfo, blockHeightDifferences]);

  // Helper function to render a value
  const renderValue = (value: any): React.ReactNode => {
    if (value === undefined || value === null) {
      return <Text type="secondary">null</Text>;
    }
    
    if (typeof value === 'boolean') {
      return value ? 
        <Badge status="success" text="true" /> : 
        <Badge status="error" text="false" />;
    }
    
    if (typeof value === 'number') {
      return <Text>{value.toLocaleString()}</Text>;
    }
    
    if (typeof value === 'string') {
      // Check if it's an address
      if (/^0x[a-fA-F0-9]{40}$/.test(value)) {
        return (
          <Tooltip title={value}>
            <Text copyable={{ text: value }} style={{ cursor: 'pointer' }}>
              {formatAddress(value, addressItemsMap)}
            </Text>
          </Tooltip>
        );
      }
      
      return <Text>{value}</Text>;
    }
    
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return <Text>{`Array(${value.length})`}</Text>;
      }
      
      if (value.address && typeof value.address === 'string') {
        return (
          <Space>
            <Tooltip title={value.address}>
              <Text copyable={{ text: value.address }} style={{ cursor: 'pointer' }}>
                {formatAddress(value.address, addressItemsMap)}
              </Text>
            </Tooltip>
            {value.isContract && <Tag color="purple">Contract</Tag>}
          </Space>
        );
      }
      
      try {
        return <Text>{JSON.stringify(value)}</Text>;
      } catch (error) {
        return <Text type="danger">Complex Object</Text>;
      }
    }
    
    return <Text>{String(value)}</Text>;
  };

  // Helper function to render block information
  const renderBlockInfo = (comparison: any) => {
    if (!comparison.blockHeightDifference) {
      return null;
    }
    
    const { maxDifference, averageBlocksBehind, chainsInfo } = comparison.blockHeightDifference;
    
    // Determine severity based on block difference
    let severity: 'success' | 'warning' | 'error' = 'success';
    if (maxDifference > 1000) {
      severity = 'error';
    } else if (maxDifference > 100) {
      severity = 'warning';
    }
    
    return (
      <Alert
        type={severity}
        message="Block Height Differences"
        description={
          <Space direction="vertical" style={{ width: '100%' }}>
            <Paragraph>
              Maximum block difference: <Text strong>{maxDifference.toLocaleString()}</Text> blocks
            </Paragraph>
            <Paragraph>
              Average blocks behind: <Text strong>{Math.round(averageBlocksBehind).toLocaleString()}</Text> blocks
            </Paragraph>
            <Collapse ghost>
              <Panel header="Chain Details" key="1">
                <Table
                  dataSource={Object.entries(chainsInfo).map(([chainId, info]) => ({
                    chainId,
                    ...info as any
                  }))}
                  rowKey="chainId"
                  pagination={false}
                  size="small"
                >
                  <Table.Column
                    title="Chain"
                    dataIndex="chainId"
                    key="chainId"
                    render={(chainId) => (
                      <Space>
                        <Text>{getChainName(chainId)}</Text>
                        <Tag color="blue">{chainId}</Tag>
                      </Space>
                    )}
                  />
                  <Table.Column
                    title="Blocks Behind"
                    dataIndex="blocksBehind"
                    key="blocksBehind"
                    render={(blocksBehind) => (
                      <Text>{blocksBehind.toLocaleString()}</Text>
                    )}
                  />
                  <Table.Column
                    title="Percentage"
                    dataIndex="percentageBehind"
                    key="percentageBehind"
                    render={(percentage) => (
                      <Text>{percentage.toFixed(2)}%</Text>
                    )}
                  />
                </Table>
              </Panel>
            </Collapse>
            <Paragraph type="secondary">
              <Text strong>Note:</Text> Significant block height differences may explain state inconsistencies 
              between chains. Chains with lower block heights may not have processed the same transactions yet.
            </Paragraph>
          </Space>
        }
        showIcon
        style={{ marginBottom: 16 }}
      />
    );
  };

  // If there are no contracts or only single-chain contracts, show empty state
  if (crossChainComparisons.length === 0 || !crossChainComparisons.some(c => c.contracts.length > 1)) {
    return <Empty description="No cross-chain data available for comparison" />;
  }

  return (
    <div style={{ width: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Title level={4}>Cross-Chain State Comparison</Title>
        
        {crossChainComparisons
          .filter(comparison => comparison.contracts.length > 1)
          .map(comparison => (
            <Card 
              key={comparison.address}
              title={
                <Space>
                  <Text strong>Contract {formatAddress(comparison.address, addressItemsMap)}</Text>
                  {comparison.hasDifferences ? (
                    <Badge status="warning" text="Has differences across chains" />
                  ) : (
                    <Badge status="success" text="Consistent across chains" />
                  )}
                </Space>
              }
              extra={
                <Space>
                  <Tooltip title="Block numbers for each chain">
                    <Tag icon={<BlockOutlined />} color="blue">
                      Block Info
                    </Tag>
                  </Tooltip>
                </Space>
              }
              style={{ width: '100%', marginBottom: 16 }}
            >
              {/* Block height differences alert */}
              {renderBlockInfo(comparison)}
              
              {/* Block information table */}
              <Card 
                size="small" 
                title={<Space><BlockOutlined /> <span>Block Information</span></Space>}
                style={{ marginBottom: 16 }}
              >
                <Table
                  dataSource={comparison.contracts}
                  rowKey="chainId"
                  pagination={false}
                  size="small"
                >
                  <Table.Column
                    title="Chain"
                    dataIndex="chainId"
                    key="chainId"
                    render={(chainId) => (
                      <Space>
                        <Text>{getChainName(chainId)}</Text>
                        <Tag color="blue">{chainId}</Tag>
                      </Space>
                    )}
                  />
                  <Table.Column
                    title="Before Block"
                    dataIndex="chainId"
                    key="beforeBlock"
                    render={(chainId) => {
                      const before = comparison.blockInfo[chainId]?.before;
                      return before ? 
                        <Text>{String(before)}</Text> : 
                        <Text type="secondary">N/A</Text>;
                    }}
                  />
                  <Table.Column
                    title="Transaction Block"
                    dataIndex="chainId"
                    key="afterBlock"
                    render={(chainId) => {
                      const after = comparison.blockInfo[chainId]?.after;
                      return after ? 
                        <Text>{String(after)}</Text> : 
                        <Text type="secondary">N/A</Text>;
                    }}
                  />
                  <Table.Column
                    title="Latest Block"
                    dataIndex="chainId"
                    key="latestBlock"
                    render={(chainId) => {
                      const latest = comparison.blockInfo[chainId]?.latest;
                      return latest ? 
                        <Text>{String(latest)}</Text> : 
                        <Text type="secondary">N/A</Text>;
                    }}
                  />
                  <Table.Column
                    title="Block Difference"
                    dataIndex="chainId"
                    key="blockDiff"
                    render={(chainId) => {
                      const diff = comparison.blockInfo[chainId]?.blockDifference;
                      return diff !== undefined ? 
                        <Text>{diff.toLocaleString()} blocks</Text> : 
                        <Text type="secondary">N/A</Text>;
                    }}
                  />
                </Table>
              </Card>
              
              {/* State differences table */}
              <Table 
                dataSource={comparison.stateComparisons.filter(c => c.hasDifferences)}
                rowKey="key"
                pagination={false}
                style={{ width: '100%' }}
                size="small"
              >
                <Table.Column 
                  title="Variable" 
                  dataIndex="key" 
                  key="key"
                  render={(key) => <Text strong>{key}</Text>}
                />
                
                {comparison.contracts.map(contract => (
                  <Table.Column
                    title={
                      <Space>
                        <Text>{getChainName(contract.chainId)}</Text>
                        <Tag color="blue">{contract.chainId}</Tag>
                      </Space>
                    }
                    key={contract.chainId}
                    render={(_, record: any) => {
                      const value = record.valuesByChain[contract.chainId];
                      if (value === undefined) {
                        return <Text type="secondary">Not Available</Text>;
                      }
                      
                      // Highlight reference chain
                      const isReference = record.referenceChain === contract.chainId;
                      
                      return (
                        <div style={{ 
                          padding: '4px', 
                          background: isReference ? '#f0f5ff' : undefined,
                          borderLeft: isReference ? '2px solid #1890ff' : undefined
                        }}>
                          {renderValue(value)}
                          {isReference && <Tag color="blue" style={{ marginLeft: 8 }}>Reference</Tag>}
                        </div>
                      );
                    }}
                  />
                ))}
                
                <Table.Column
                  title="Status"
                  key="status"
                  render={(_, record: any) => {
                    if (record.hasDifferences) {
                      return <Tag color="warning" icon={<DiffOutlined />}>Different</Tag>;
                    }
                    return <Tag color="success" icon={<CheckCircleOutlined />}>Same</Tag>;
                  }}
                />
              </Table>
              
              {comparison.stateComparisons.filter(c => !c.hasDifferences).length > 0 && (
                <Collapse ghost style={{ marginTop: 16 }}>
                  <Panel header="View consistent variables" key="1">
                    <Table 
                      dataSource={comparison.stateComparisons.filter(c => !c.hasDifferences)}
                      rowKey="key"
                      pagination={false}
                      style={{ width: '100%' }}
                      size="small"
                    >
                      <Table.Column 
                        title="Variable" 
                        dataIndex="key" 
                        key="key"
                        render={(key) => <Text strong>{key}</Text>}
                      />
                      
                      {comparison.contracts.map(contract => (
                        <Table.Column
                          title={
                            <Space>
                              <Text>{getChainName(contract.chainId)}</Text>
                              <Tag color="blue">{contract.chainId}</Tag>
                            </Space>
                          }
                          key={contract.chainId}
                          render={(_, record: any) => {
                            const value = record.valuesByChain[contract.chainId];
                            if (value === undefined) {
                              return <Text type="secondary">Not Available</Text>;
                            }
                            return renderValue(value);
                          }}
                        />
                      ))}
                      
                      <Table.Column
                        title="Status"
                        key="status"
                        render={() => (
                          <Tag color="success" icon={<CheckCircleOutlined />}>Same</Tag>
                        )}
                      />
                    </Table>
                  </Panel>
                </Collapse>
              )}
            </Card>
          ))}
      </Space>
    </div>
  );
};

export default ContractCrossChainComparison; 
import React, { useState } from 'react';
import { 
  Card, 
  Space, 
  Badge, 
  Typography, 
  Button, 
  Collapse, 
  Descriptions, 
  Tooltip, 
  Tag, 
  Table,
  Divider,
  message,
  Tabs,
  List,
  Empty
} from 'antd';
import { 
  InfoCircleOutlined, 
  FileTextOutlined, 
  CopyOutlined, 
  LinkOutlined,
  DownloadOutlined,
  CodeOutlined,
  ClockCircleOutlined,
  KeyOutlined,
  NodeIndexOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { renderAddressAvatar } from "../../../components/address-avatar";

const { Text, Paragraph } = Typography;

// Update the safeRenderValue function to better handle objects
const safeRenderValue = (value: any): string => {
  if (value === null || value === undefined) {
    return 'null';
  }
  
  if (typeof value === 'object') {
    try {
      return JSON.stringify(value, null, 2);
    } catch (e) {
      return '[Complex Object]';
    }
  }
  
  return String(value);
};

/**
 * Renders specialized data types like Wormhole VAA, CBOR, etc.
 */
export const renderSpecializedDataType = (param: any, addressData?: any) => {
  // Check if this is a special data type with custom rendering
  if (typeof param.value === 'object' && param.value !== null && param.value.type) {
    // Handle Wormhole VAA
    if (param.value.type === 'wormhole_vaa') {
      return renderWormholeVAA(param.value);
    }
    
    // Handle CBOR data
    if (param.value.type === 'cbor') {
      return renderCBOR(param.value);
    }
    
    // Handle other custom types that might be added in the future
    if (param.value.name && param.value.value) {
      return renderGenericCustomDataType(param.value);
    }
  }
  
  // Handle tuple arrays (like Uniswap commands)
  if (param.type === 'tuple[]' && Array.isArray(param.value)) {
    return renderTupleArray(param, addressData);
  }
  
  // Handle bytes32[n] arrays with structured data (like Chainlink reportContext)
  if (param.type && param.type.match(/^bytes32\[\d+\]$/) && 
      typeof param.value === 'object' && param.value !== null && !Array.isArray(param.value)) {
    // Check if it has named fields, which indicates a structured object
    const hasNamedFields = Object.values(param.value).some(
      (v: any) => v && typeof v === 'object' && v !== null && 'name' in v && 'type' in v
    );
    
    if (hasNamedFields) {
      return renderStructuredObject(param.value, param.name || 'Structured Data', addressData);
    }
  }
  
  // Handle bytes with structured data (like Chainlink report)
  if (param.type === 'bytes' && typeof param.value === 'object' && param.value !== null) {
    // Check if it has structured fields
    const hasStructuredFields = Object.keys(param.value).length > 0 && 
      !Array.isArray(param.value) && 
      typeof param.value !== 'string';
    
    if (hasStructuredFields) {
      return renderStructuredObject(param.value, param.name || 'Structured Data', addressData);
    }
  }
  
  // Default rendering for regular bytes/string
  if (param.type === 'bytes' || param.type === 'string') {
    // Check if it's a complex object
    if (typeof param.value === 'object' && param.value !== null) {
      return renderStructuredObject(param.value, param.name || param.type, addressData);
    }
    
    // Ensure we're dealing with a string
    const stringValue = typeof param.value === 'object' ? 
      safeRenderValue(param.value) : 
      String(param.value || '');
      
    const isLongValue = stringValue.length > 66;
    const displayValue = isLongValue ? `${stringValue.slice(0, 66)}...` : stringValue;
    
    return (
      <Tooltip title={stringValue}>
        <Text code copyable={{ text: stringValue }} style={{ fontSize: '14px' }}>
          {displayValue}
        </Text>
      </Tooltip>
    );
  }
  
  // For other types, use the field value renderer
  return renderFieldValue(param, addressData);
};

/**
 * Renders a Wormhole VAA (Verified Action Approval)
 */
const renderWormholeVAA = (data: any) => {
  const { value } = data;
  const [activeTab, setActiveTab] = useState('overview');
  
  // Helper function to format chain names
  const formatChainName = (chainId: string, chainName?: string) => {
    if (chainName && chainName !== `Unknown Chain (${chainId})`) {
      return chainName;
    }
    
    // Known chain IDs in Wormhole
    const knownChains: Record<string, string> = {
      '1': 'Solana',
      '2': 'Ethereum',
      '3': 'Terra',
      '4': 'BSC',
      '5': 'Polygon',
      '6': 'Avalanche',
      '7': 'Oasis',
      '8': 'Algorand',
      '9': 'Aurora',
      '10': 'Fantom',
      '11': 'Karura',
      '12': 'Acala',
      '13': 'Klaytn',
      '14': 'Celo',
      '15': 'Near',
      '16': 'Moonbeam',
      '18': 'Terra 2',
      '19': 'Injective',
      '22': 'Aptos',
      '23': 'Arbitrum',
      '24': 'Optimism',
      '28': 'Xpla',
      '30': 'Base',
    };
    
    return knownChains[chainId] || `Chain ${chainId}`;
  };
  
  return (
    <Card 
      size="small" 
      title={
        <Space>
          <Badge status="processing" color="blue" />
          <Text strong>Wormhole VAA</Text>
          {value.header && (
            <Tag color="blue">v{value.header.version}</Tag>
          )}
        </Space>
      }
      style={{ marginTop: 8, width: '100%' }}
      extra={
        <Button 
          type="text" 
          icon={<CopyOutlined />} 
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(value, null, 2));
            message.success('VAA data copied to clipboard');
          }}
        >
          Copy Full Data
        </Button>
      }
    >
      <Tabs 
        activeKey={activeTab} 
        onChange={setActiveTab}
        items={[
          {
            key: 'overview',
            label: 'Overview',
            children: (
              <Space direction="vertical" style={{ width: '100%' }}>
                {/* Message Overview */}
                <Descriptions column={2} size="small" bordered>
                  {value.body && (
                    <>
                      <Descriptions.Item 
                        label={<Text strong>Source Chain</Text>}
                        span={1}
                      >
                        <Space>
                          <Tag color="green">
                            {formatChainName(value.body.emitter_chain, value.body.emitter_chain_name)}
                          </Tag>
                          <Text code copyable>{value.body.emitter_chain}</Text>
                        </Space>
                      </Descriptions.Item>
                      
                      <Descriptions.Item 
                        label={<Text strong>Sequence</Text>}
                        span={1}
                      >
                        <Text code copyable>{value.body.sequence}</Text>
                      </Descriptions.Item>
                      
                      <Descriptions.Item 
                        label={<Text strong>Emitter</Text>}
                        span={2}
                      >
                        <Text code copyable>{value.body.emitter_address}</Text>
                      </Descriptions.Item>
                      
                      {value.body.human_timestamp && (
                        <Descriptions.Item 
                          label={<Text strong>Timestamp</Text>}
                          span={2}
                        >
                          <Space>
                            <ClockCircleOutlined />
                            <Text>{value.body.human_timestamp}</Text>
                            <Text type="secondary">({value.body.timestamp})</Text>
                          </Space>
                        </Descriptions.Item>
                      )}
                    </>
                  )}
                  
                  {value.header && (
                    <Descriptions.Item 
                      label={<Text strong>Guardian Set</Text>}
                      span={1}
                    >
                      <Text code>{value.header.guardian_set_index}</Text>
                    </Descriptions.Item>
                  )}
                  
                  {value.header && (
                    <Descriptions.Item 
                      label={<Text strong>Signatures</Text>}
                      span={1}
                    >
                      <Text code>{value.signatures?.length || 0} / {value.header.signature_count}</Text>
                    </Descriptions.Item>
                  )}
                </Descriptions>
                
                {/* Token Information (if available) */}
                {value.decoded_payload && value.decoded_payload.token_address && (
                  <>
                    <Divider orientation="left">Token Information</Divider>
                    <Descriptions column={2} size="small" bordered>
                      {value.decoded_payload.name && (
                        <Descriptions.Item 
                          label={<Text strong>Name</Text>}
                          span={1}
                        >
                          <Text>{value.decoded_payload.name}</Text>
                        </Descriptions.Item>
                      )}
                      
                      {value.decoded_payload.symbol && (
                        <Descriptions.Item 
                          label={<Text strong>Symbol</Text>}
                          span={1}
                        >
                          <Text>{value.decoded_payload.symbol}</Text>
                        </Descriptions.Item>
                      )}
                      
                      <Descriptions.Item 
                        label={<Text strong>Token Chain</Text>}
                        span={1}
                      >
                        <Space>
                          <Tag color="purple">
                            {formatChainName(value.decoded_payload.token_chain, value.decoded_payload.token_chain_name)}
                          </Tag>
                          <Text code>{value.decoded_payload.token_chain}</Text>
                        </Space>
                      </Descriptions.Item>
                      
                      {value.decoded_payload.decimals && (
                        <Descriptions.Item 
                          label={<Text strong>Decimals</Text>}
                          span={1}
                        >
                          <Text code>{value.decoded_payload.decimals}</Text>
                        </Descriptions.Item>
                      )}
                      
                      <Descriptions.Item 
                        label={<Text strong>Token Address</Text>}
                        span={2}
                      >
                        <Text code copyable>{value.decoded_payload.token_address}</Text>
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                )}
              </Space>
            )
          },
          {
            key: 'payload',
            label: 'Payload',
            children: (
              <Space direction="vertical" style={{ width: '100%' }}>
                {/* Raw Payload */}
                {value.payload_hex && (
                  <Card size="small" title="Raw Payload">
                    <Text code copyable style={{ wordBreak: 'break-all' }}>
                      {value.payload_hex}
                    </Text>
                  </Card>
                )}
                
                {/* Decoded Payload */}
                {value.decoded_payload && (
                  <Card size="small" title="Decoded Payload" style={{ marginTop: 16 }}>
                    <Descriptions column={1} size="small" bordered>
                      {Object.entries(value.decoded_payload).map(([key, val]: [string, any]) => (
                        <Descriptions.Item 
                          key={key} 
                          label={<Text strong>{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</Text>}
                        >
                          <Text code copyable>{val}</Text>
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  </Card>
                )}
              </Space>
            )
          },
          {
            key: 'signatures',
            label: 'Signatures',
            children: (
              <Space direction="vertical" style={{ width: '100%' }}>
                {/* Guardian Signatures */}
                {value.signatures && value.signatures.length > 0 && (
                  <Table
                    dataSource={value.signatures}
                    columns={[
                      {
                        title: 'Guardian',
                        dataIndex: 'index',
                        key: 'index',
                        render: (index) => (
                          <Space>
                            <KeyOutlined />
                            <Text>Guardian {index}</Text>
                          </Space>
                        )
                      },
                      {
                        title: 'Signature',
                        dataIndex: 'signature',
                        key: 'signature',
                        render: (sig) => (
                          <Text code copyable style={{ fontSize: '12px' }}>
                            {sig.length > 30 ? `${sig.substring(0, 30)}...` : sig}
                          </Text>
                        )
                      },
                      {
                        title: 'Status',
                        key: 'status',
                        render: () => (
                          <Tag color="green" icon={<CheckCircleOutlined />}>
                            Valid
                          </Tag>
                        )
                      }
                    ]}
                    size="small"
                    pagination={false}
                  />
                )}
                
                {/* Header Information */}
                {value.header && (
                  <Card size="small" title="Guardian Set Info" style={{ marginTop: 16 }}>
                    <Descriptions column={2} size="small">
                      <Descriptions.Item label="Guardian Set Index">
                        <Text code>{value.header.guardian_set_index}</Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="Signature Count">
                        <Text code>{value.header.signature_count}</Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="Version">
                        <Text code>{value.header.version}</Text>
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                )}
              </Space>
            )
          },
          {
            key: 'raw',
            label: 'Raw Data',
            children: (
              <Space direction="vertical" style={{ width: '100%' }}>
                <pre style={{ 
                  backgroundColor: '#f5f5f5', 
                  padding: '16px', 
                  borderRadius: '4px',
                  overflow: 'auto',
                  maxHeight: '400px'
                }}>
                  {JSON.stringify(value, null, 2)}
                </pre>
                <Button 
                  icon={<CopyOutlined />} 
                  onClick={() => {
                    navigator.clipboard.writeText(JSON.stringify(value, null, 2));
                    message.success('VAA data copied to clipboard');
                  }}
                >
                  Copy to Clipboard
                </Button>
              </Space>
            )
          }
        ]}
      />
    </Card>
  );
};

/**
 * Renders CBOR data
 */
const renderCBOR = (data: any) => {
  const { value } = data;
  
  // Handle undefined or empty value
  if (!value || !value.readable_text) {
    return (
      <Card 
        size="small" 
        title={
          <Space>
            <Badge status="processing" color="orange" />
            <Text strong>CBOR Data</Text>
          </Space>
        }
        style={{ marginTop: 8, width: '100%' }}
      >
        <Text type="secondary">No decoded data available</Text>
      </Card>
    );
  }
  
  return (
    <Card 
      size="small" 
      title={
        <Space>
          <Badge status="processing" color="orange" />
          <Text strong>CBOR Data</Text>
        </Space>
      }
      style={{ marginTop: 8, width: '100%' }}
    >
      <Tabs 
        defaultActiveKey="decoded" 
        items={[
          {
            key: 'decoded',
            label: 'Decoded Data',
            children: (
              <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                {Array.isArray(value.readable_text) ? (
                  <List
                    size="small"
                    dataSource={value.readable_text}
                    renderItem={(item, index) => (
                      <List.Item>
                        <Text code copyable>{String(item)}</Text>
                      </List.Item>
                    )}
                    bordered
                  />
                ) : typeof value.readable_text === 'object' ? (
                  <pre style={{ margin: 0 }}>
                    {JSON.stringify(value.readable_text, null, 2)}
                  </pre>
                ) : (
                  <Text code copyable>{value.readable_text}</Text>
                )}
              </div>
            ),
          },
          {
            key: 'raw',
            label: 'Raw Data',
            children: (
              <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                <Text code copyable>{value.raw || 'No raw data available'}</Text>
              </div>
            ),
          },
        ]}
      />
    </Card>
  );
};

/**
 * Generic renderer for other custom data types
 */
const renderGenericCustomDataType = (data: any) => {
  const { name, type, value } = data;
  
  return (
    <Card 
      size="small" 
      title={
        <Space>
          <Badge status="processing" color="purple" />
          <Text strong>{name || type}</Text>
          {type && <Tag color="purple">{type}</Tag>}
        </Space>
      }
      style={{ marginTop: 8, width: '100%' }}
    >
      {typeof value === 'object' ? (
        <Collapse defaultActiveKey={['data']}>
          <Collapse.Panel 
            header={
              <Text strong>
                <InfoCircleOutlined style={{ marginRight: 8 }} />
                Data
              </Text>
            } 
            key="data"
          >
            <Descriptions column={1} size="small" bordered>
              {Object.entries(value).map(([key, val]: [string, any]) => (
                <Descriptions.Item 
                  key={key} 
                  label={<Text strong>{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</Text>}
                >
                  {typeof val === 'object' ? (
                    <pre style={{ margin: 0 }}>{JSON.stringify(val, null, 2)}</pre>
                  ) : (
                    <Text code copyable>{String(val)}</Text>
                  )}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Collapse.Panel>
        </Collapse>
      ) : (
        <Text code copyable>{String(value)}</Text>
      )}
    </Card>
  );
};

// Add a specialized renderer for tuple arrays like Uniswap commands
const renderTupleArray = (param: any, addressData?: any) => {
  const { name, type, value } = param;
  
  // Check if this is a tuple array
  if (type === 'tuple[]' && Array.isArray(value)) {
    return (
      <Card 
        size="small" 
        title={
          <Space>
            <Badge status="processing" color="cyan" />
            <Text strong>{name || 'Commands'}</Text>
            <Tag color="cyan">{type}</Tag>
          </Space>
        }
        style={{ marginTop: 8, width: '100%' }}
      >
        <Collapse defaultActiveKey={value.map((_, i) => `item-${i}`)}>
          {value.map((tupleItem, index) => (
            <Collapse.Panel 
              key={`item-${index}`}
              header={
                <Space>
                  <Text strong>Item {index + 1}</Text>
                  {/* Try to find a command name to display */}
                  {tupleItem.find((field: any) => 
                    field.name === 'command_name' || 
                    field.name === '_command' || 
                    field.name === 'name'
                  ) && (
                    <Tag color="blue">
                      {tupleItem.find((field: any) => 
                        field.name === 'command_name' || 
                        field.name === '_command' || 
                        field.name === 'name'
                      ).value}
                    </Tag>
                  )}
                </Space>
              }
            >
              <List
                size="small"
                dataSource={tupleItem}
                renderItem={(field: any) => (
                  <List.Item>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Space>
                        <Text strong>{field.name}:</Text>
                        <Tag color={getTagColorByParamType(field.type)}>{field.type}</Tag>
                      </Space>
                      {renderFieldValue(field, addressData)}
                    </Space>
                  </List.Item>
                )}
                bordered
                style={{ backgroundColor: '#fafafa' }}
              />
            </Collapse.Panel>
          ))}
        </Collapse>
      </Card>
    );
  }
  
  // Default rendering for other types
  return (
    <Text code copyable>
      {JSON.stringify(value, null, 2)}
    </Text>
  );
};

// Update the renderStructuredObject function to handle address fields
const renderStructuredObject = (obj: any, title: string = 'Object', addressData?: any) => {
  if (!obj || typeof obj !== 'object') {
    return <Text code>{safeRenderValue(obj)}</Text>;
  }

  // Check if it's an array
  if (Array.isArray(obj)) {
    return (
      <Card 
        size="small" 
        title={
          <Space>
            <Badge status="processing" color="blue" />
            <Text strong>{title}</Text>
            <Tag color="blue">{obj.length} items</Tag>
          </Space>
        }
        style={{ marginTop: 8, width: '100%' }}
      >
        {obj.length === 0 ? (
          <Empty description="Empty Array" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <List
            size="small"
            dataSource={obj}
            renderItem={(item, index) => (
              <List.Item>
                <div style={{ width: '100%' }}>
                  <Text strong>Item {index + 1}</Text>
                  {typeof item === 'object' && item !== null ? (
                    <div style={{ marginTop: 4 }}>
                      {renderStructuredObject(item, `Item ${index + 1}`, addressData)}
                    </div>
                  ) : (
                    <div style={{ marginTop: 4 }}>
                      <Text code copyable>{safeRenderValue(item)}</Text>
                    </div>
                  )}
                </div>
              </List.Item>
            )}
          />
        )}
      </Card>
    );
  }

  // Handle objects with name, type, value structure (common in decoded calls)
  if (obj.name && obj.type && 'value' in obj) {
    // Check if this is an address type
    if (obj.type === 'address') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Text code copyable style={{ marginRight: '8px' }}>
            {obj.value}
          </Text>
          {addressData && renderAddressAvatar(
            addressData, 
            'to', 
            obj.value, 
            {
              maxGlobalLabels: 2,
              maxUserLabels: 2,
              maxTotalLabels: 2,
              maxGlobalEntities: 1,
              maxUserEntities: 1,
              maxTotalEntities: 1,
              displayStyle: 'avatars',
              hideAddress: true,
            },
            addressData.address_items_map
          )}
        </div>
      );
    }
    
    return (
      <Card 
        size="small" 
        title={
          <Space>
            <Text strong>{obj.name}</Text>
            <Tag color={getTagColorByParamType(obj.type)}>{obj.type}</Tag>
          </Space>
        }
        style={{ marginTop: 8, width: '100%' }}
      >
        {typeof obj.value === 'object' && obj.value !== null ? 
          renderStructuredObject(obj.value, obj.name, addressData) : 
          <Text code copyable>{safeRenderValue(obj.value)}</Text>}
      </Card>
    );
  }

  // For regular objects, create a descriptive list
  const entries = Object.entries(obj);
  
  if (entries.length === 0) {
    return <Text code>{'{}'}</Text>;
  }
  
  return (
    <Card 
      size="small" 
      title={
        <Space>
          <Badge status="processing" color="purple" />
          <Text strong>{title}</Text>
          <Tag color="purple">{entries.length} fields</Tag>
        </Space>
      }
      style={{ marginTop: 8, width: '100%' }}
    >
      <Table
        size="small"
        pagination={false}
        showHeader={false}
        dataSource={entries.map(([key, value], index) => ({
          key: index,
          fieldName: key,
          fieldValue: value
        }))}
        columns={[
          {
            title: 'Field',
            dataIndex: 'fieldName',
            key: 'fieldName',
            width: '30%',
            render: (text, record) => {
              // Check if value is an object with type and value
              const value = record.fieldValue;
              const isTypedValue = value && 
                                  typeof value === 'object' && 
                                  value !== null &&
                                  'type' in value && 
                                  'value' in value;
              
              // Safely access type property with proper type checking
              const valueType = isTypedValue && typeof (value as any).type === 'string' 
                ? (value as any).type 
                : '';
              
              return (
                <Space>
                  <Text strong>{text}</Text>
                  {isTypedValue && valueType && (
                    <Tag color={getTagColorByParamType(valueType)}>
                      {valueType}
                    </Tag>
                  )}
                </Space>
              );
            }
          },
          {
            title: 'Value',
            dataIndex: 'fieldValue',
            key: 'fieldValue',
            render: (value) => {
              // Check if this is an address field
              const isAddress = value && 
                              typeof value === 'object' && 
                              value !== null &&
                              'type' in value && 
                              value.type === 'address' &&
                              'value' in value;
              
              if (isAddress) {
                return (
                  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Text code copyable style={{ marginRight: '8px' }}>
                      {value.value}
                    </Text>
                    {addressData && renderAddressAvatar(
                      addressData, 
                      'to', 
                      value.value, 
                      {
                        maxGlobalLabels: 2,
                        maxUserLabels: 2,
                        maxTotalLabels: 2,
                        maxGlobalEntities: 1,
                        maxUserEntities: 1,
                        maxTotalEntities: 1,
                        displayStyle: 'avatars',
                        hideAddress: true,
                      },
                      addressData.address_items_map
                    )}
                  </div>
                );
              }
              
              // Check if this is a plain address string
              const isAddressString = typeof value === 'string' && 
                                    value.match(/^0x[a-fA-F0-9]{40}$/);
              
              if (isAddressString) {
                return (
                  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Text code copyable style={{ marginRight: '8px' }}>
                      {value}
                    </Text>
                    {addressData && renderAddressAvatar(
                      addressData, 
                      'to', 
                      value, 
                      {
                        maxGlobalLabels: 2,
                        maxUserLabels: 2,
                        maxTotalLabels: 2,
                        maxGlobalEntities: 1,
                        maxUserEntities: 1,
                        maxTotalEntities: 1,
                        displayStyle: 'avatars',
                        hideAddress: true,
                      },
                      addressData.address_items_map
                    )}
                  </div>
                );
              }
              
              if (typeof value === 'object' && value !== null) {
                return renderStructuredObject(value, undefined, addressData);
              }
              
              return <Text code copyable>{safeRenderValue(value)}</Text>;
            }
          }
        ]}
        style={{ width: '100%' }}
      />
    </Card>
  );
};

// Update the renderFieldValue function to handle nested objects better
const renderFieldValue = (field: any, addressData?: any) => {
  const { type, value } = field;
  
  // Handle address type with labels
  if (type === 'address') {
    return (
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <Text code copyable style={{ marginRight: '8px' }}>
          {value}
        </Text>
        {addressData && renderAddressAvatar(
          addressData, 
          'to', 
          value, 
          {
            maxGlobalLabels: 2,
            maxUserLabels: 2,
            maxTotalLabels: 2,
            maxGlobalEntities: 1,
            maxUserEntities: 1,
            maxTotalEntities: 1,
            displayStyle: 'avatars',
            hideAddress: true,
          },
          addressData.address_items_map
        )}
      </div>
    );
  }
  
  // Handle address arrays with labels
  if (type === 'address[]' && Array.isArray(value)) {
    return (
      <List
        size="small"
        dataSource={value}
        renderItem={(address, index) => (
          <List.Item>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              <Text type="secondary" style={{ minWidth: '30px', flexShrink: 0 }}>
                [{index}]:
              </Text>
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', flex: 1, overflow: 'hidden' }}>
                <Text code copyable style={{ marginRight: '8px' }}>
                  {address}
                </Text>
                {addressData && renderAddressAvatar(
                  addressData, 
                  'to', 
                  address, 
                  {
                    maxGlobalLabels: 1,
                    maxUserLabels: 1,
                    maxTotalLabels: 1,
                    maxGlobalEntities: 1,
                    maxUserEntities: 1,
                    maxTotalEntities: 1,
                    displayStyle: 'avatars',
                    hideAddress: true,
                  },
                  addressData.address_items_map
                )}
              </div>
            </div>
          </List.Item>
        )}
        style={{ 
          backgroundColor: 'white', 
          padding: '8px', 
          maxHeight: '200px', 
          overflow: 'auto' 
        }}
      />
    );
  }
  
  // Handle boolean values
  if (type === 'bool') {
    return (
      <Tag color={value ? 'green' : 'red'}>
        {value ? 'True' : 'False'}
      </Tag>
    );
  }
  
  // Handle numeric values
  if (type && type.includes('int')) {
    // Format large numbers for better readability
    let displayValue = value;
    if (typeof value === 'string' && value.length > 10) {
      try {
        const bn = BigInt(value);
        // If it's likely to be wei, try to format as ETH
        if (bn > 1000000000000000n) {
          const ethValue = Number(bn) / 1e18;
          displayValue = `${ethValue.toLocaleString()} ETH (${value})`;
        } else {
          displayValue = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        }
      } catch (e) {
        // If parsing fails, use the original value
        displayValue = value;
      }
    }
    
    return (
      <Text code copyable={{ text: String(value) }}>
        {displayValue}
      </Text>
    );
  }
  
  // Handle bytes32[n] special case for structured data like reportContext in Chainlink
  if (type && type.match(/^bytes32\[\d+\]$/) && typeof value === 'object' && value !== null && !Array.isArray(value)) {
    // Check if it has named fields, which indicates a structured object
    const hasNamedFields = Object.values(value).some(
      (v: any) => v && typeof v === 'object' && v !== null && 'name' in v && 'type' in v
    );
    
    if (hasNamedFields) {
      return renderStructuredObject(value, field.name || 'Structured Data', addressData);
    }
  }
  
  // Handle bytes with structured data (like Chainlink reports)
  if (type === 'bytes' && typeof value === 'object' && value !== null) {
    // Check if it has structured fields
    const hasStructuredFields = Object.keys(value).length > 0 && 
      !Array.isArray(value) && 
      typeof value !== 'string';
    
    if (hasStructuredFields) {
      return renderStructuredObject(value, field.name || 'Structured Data', addressData);
    }
  }
  
  // Handle regular bytes or string
  if (type === 'bytes' || type === 'string') {
    // Ensure we're dealing with a string
    let stringValue: string;
    
    if (typeof value === 'object' && value !== null) {
      try {
        stringValue = JSON.stringify(value, null, 2);
      } catch (e) {
        stringValue = '[Complex Object]';
      }
    } else {
      stringValue = String(value || '');
    }
      
    const isLongValue = stringValue.length > 66;
    const displayValue = isLongValue ? `${stringValue.slice(0, 66)}...` : stringValue;
    
    return (
      <Tooltip title={stringValue}>
        <Text code copyable={{ text: stringValue }}>
          {displayValue}
        </Text>
      </Tooltip>
    );
  }
  
  // Handle arrays (like bytes32[])
  if (type && type.includes('[') && Array.isArray(value)) {
    return renderStructuredObject(value, `${type} Array`, addressData);
  }
  
  // Handle nested objects
  if (typeof value === 'object' && value !== null) {
    // Check if it's a simple object or a complex one
    const isComplexObject = Object.values(value).some(
      (v: any) => v && typeof v === 'object' && v !== null
    );
    
    if (isComplexObject) {
      return renderStructuredObject(value, field.name || 'Object', addressData);
    } else {
      // For simple objects, stringify them
      try {
        const jsonString = JSON.stringify(value, null, 2);
        return (
          <Text code copyable={{ text: jsonString }}>
            {jsonString.length > 100 ? `${jsonString.slice(0, 100)}...` : jsonString}
          </Text>
        );
      } catch (e) {
        return <Text code>{'[Object]'}</Text>;
      }
    }
  }
  
  // Default rendering
  return (
    <Text code copyable={{ text: String(value || '') }}>
      {String(value || '')}
    </Text>
  );
};

// Helper function to get tag color based on parameter type
const getTagColorByParamType = (type: string) => {
  if (type.includes('address')) return 'geekblue';
  if (type.includes('uint') || type.includes('int')) return 'purple';
  if (type.includes('bool')) return 'green';
  if (type.includes('bytes')) return 'volcano';
  if (type.includes('string')) return 'cyan';
  if (type.includes('tuple')) return 'orange';
  return 'default';
};

export default renderSpecializedDataType; 
import React, { useState, memo } from 'react';
import { Modal, Space, Button, Avatar, Typography, Divider, Row, Col, Statistic, Tooltip, Tag, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { CodeOutlined, CloseOutlined, EyeOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { GraphNode } from '../../../interfaces/graph';
import { IAddress } from 'interfaces/address';
import { IAddressLabel } from 'interfaces/label';
import { StarFilled } from '@ant-design/icons';
import { DeFiTab } from '../../../pages/addresses/components/DeFiTab';
import { StatsTab } from '../../../pages/addresses/components/StatsTab';
import { PortfolioTab } from '../../../pages/addresses/components/PortfolioTab';
import { NFTsTab } from '../../../pages/addresses/components/NFTsTab';
import { TokensTab } from '../../../pages/addresses/components/TokensTab';
import { renderAddressAvatar } from '../../address-avatar';

interface NodeLabel {
    id: string;
    name: string;
    color: string;
    chains: string[];
    source?: string;
}

interface NodeStatsProps {
    node: GraphNode;
    chainIdToName: Record<string, string>;
    cdn_domain_name: string;
    onClose: () => void;
    address_items_map: Record<string, IAddress>;
}

// Memoize the tab components
const MemoizedPortfolioTab = memo(PortfolioTab);
const MemoizedDeFiTab = memo(DeFiTab);
const MemoizedStatsTab = memo(StatsTab);
const MemoizedNFTsTab = memo(NFTsTab);
const MemoizedTokensTab = memo(TokensTab);

export const NodeStats: React.FC<NodeStatsProps> = ({ 
    node, 
    chainIdToName, 
    cdn_domain_name, 
    onClose,
    address_items_map
}) => {
    const [isRawDataVisible, setIsRawDataVisible] = useState(false);
    
    // Create address data for DeFi tab
    const addressData: Pick<IAddress, 'address' | 'active_chain_ids'> = {
        address: node.friendly_name,
        active_chain_ids: [node.chain_id],
    };

    // Extract block timestamps from node data
    const blockTimestamps = node.block_timestamps || [];

    const items = [
        {
            key: 'overview',
            label: 'Overview',
            children: (
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Space>
                            {node?.source && (
                                <Avatar src={node.source} size="large" />
                            )}
                            <Space direction="vertical" size={0}>
                                <Typography.Title level={5} style={{ margin: 0 }}>
                                    {typeof node.label === 'object' && node.label !== null
                                        ? (node.label as NodeLabel).name 
                                        : node.label || node.entity || 'Unknown Entity'}
                                </Typography.Title>
                                
                                <Space>
                                    <Typography.Text copyable={{ text: node.friendly_name }}>
                                        {`${node.friendly_name?.slice(0, 6)}...${node.friendly_name?.slice(-4)}`}
                                    </Typography.Text>
                                    <Link 
                                        to={`/addresses/show/${node.friendly_name}?chain_ids=${node.chain_id}`}
                                        style={{ fontSize: '12px' }}
                                        target="_blank"
                                    >
                                        <Button 
                                            type="link" 
                                            size="small" 
                                            icon={<EyeOutlined/>}
                                        >
                                            View Address
                                        </Button>
                                    </Link>
                                </Space>
                                {address_items_map[node.friendly_name] && (
                                    renderAddressAvatar(
                                        {
                                            chain_id: node.chain_id,
                                            address_items_map: { 
                                                [node.friendly_name]: address_items_map[node.friendly_name] 
                                            }
                                        },
                                        'from',
                                        node.friendly_name,
                                        {
                                            maxGlobalLabels: 5,
                                            maxUserLabels: 5,
                                            maxTotalLabels: 10,
                                            maxGlobalEntities: 5,
                                            maxUserEntities: 5,
                                            maxTotalEntities: 10,
                                            displayStyle: 'tags',
                                            hideAddress: true
                                        }
                                    )
                                )}
                            </Space>
                        </Space>
                    </Space>

                    <Divider style={{ margin: '12px 0' }} />

                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Statistic 
                                title="Chain" 
                                value={chainIdToName[node.chain_id] || node.chain_id}
                                prefix={<Avatar 
                                    size="large" 
                                    src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${node.chain_id}.svg`}
                                />}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="Outgoing Transactions" 
                                value={node.outgoing_count || 0}
                                prefix={<ArrowUpOutlined/>}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic 
                                title="Incoming Transactions" 
                                value={node.incoming_count || 0}
                                prefix={<ArrowDownOutlined/>}
                            />
                        </Col>
                        <Col span={6}>
                            <Tooltip title={
                                node.token_values && Object.entries(node.token_values)
                                    .map(([token, value]) => `${value.toFixed(4)} ${token}`)
                                    .join('\n')
                            }>
                                <Statistic 
                                    title="Total Value" 
                                    value={node.total_value || 0}
                                    prefix="Ξ"
                                    precision={4}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </Space>
            ),
        },
        {
            key: 'portfolio',
            label: 'Portfolio',
            children: <MemoizedPortfolioTab 
                record={addressData} 
                isEmbedded={true} 
                blockTimestamps={blockTimestamps}
            />
        },
        {
            key: 'defi',
            label: 'DeFi Positions',
            children: <MemoizedDeFiTab record={addressData} isEmbedded={true} />
        },
        {
            key: 'stats',
            label: 'Stats',
            children: <MemoizedStatsTab record={addressData} isEmbedded={true} />
        },
        {
            key: 'nfts',
            label: 'NFTs',
            children: <MemoizedNFTsTab record={addressData} isEmbedded={true} />
        },
        {
            key: 'tokens',
            label: 'Tokens',
            children: <MemoizedTokensTab 
                record={addressData} 
                isEmbedded={true} 
                blockTimestamps={blockTimestamps}
            />
        }
    ];

    return (
        <>
            <Modal
                title="Raw Node Data"
                open={isRawDataVisible}
                onCancel={() => setIsRawDataVisible(false)}
                footer={[
                    <Button key="close" onClick={() => setIsRawDataVisible(false)}>
                        Close
                    </Button>
                ]}
                width={800}
            >
                <pre style={{ 
                    backgroundColor: '#f5f5f5', 
                    padding: '16px', 
                    borderRadius: '4px',
                    overflow: 'auto',
                    maxHeight: '600px'
                }}>
                    {JSON.stringify(node, null, 2)}
                </pre>
            </Modal>

            <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                background: 'white',
                padding: '16px 24px',
                boxShadow: '0 -2px 8px rgba(0,0,0,0.15)',
                zIndex: 1000,
                transition: 'transform 0.3s ease',
                transform: node ? 'translateY(0)' : 'translateY(100%)',
                maxHeight: '80vh',
                overflowY: 'hidden'
            }}>
                <Space style={{ width: '100%', justifyContent: 'space-between', marginBottom: 0 }}>
                    <Space>
                        {node?.source && <Avatar src={node.source} size="large" />}
                        <Space direction="vertical" size={0}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                {typeof node.label === 'object' && node.label !== null
                                    ? (node.label as NodeLabel).name 
                                    : node.label || node.entity || 'Unknown Entity'}
                            </Typography.Title>
                        </Space>
                    </Space>
                    <Space>
                        <Button 
                            icon={<CodeOutlined/>}
                            onClick={() => setIsRawDataVisible(true)}
                            type="text"
                        >
                            View Raw Data
                        </Button>
                        <Button 
                            icon={<CloseOutlined/>} 
                            type="text"
                            onClick={onClose}
                        />
                    </Space>
                </Space>

                <Tabs
                    items={items}
                    defaultActiveKey="overview"
                    style={{ marginTop: 16 }}
                    destroyInactiveTabPane={true}
                    animated={{ tabPane: false }}
                />
            </div>
        </>
    );
}; 
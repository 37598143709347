
// Helper to create node ID
export const createNodeId = (address: string, chain_id: string) => `${chain_id}_${address.toLowerCase()}`;

// Helper to add node if it doesn't exist
export const addNodeIfNotExists = (transaction: any, nodes: any, chain_id: string, address: string) => {
    const nodeId = createNodeId(address, chain_id);
    if (!nodes[nodeId]) {
        const addressInfo = transaction.address_items_map?.[address.toLowerCase()];
        nodes[nodeId] = {
            id: nodeId,
            address: address.toLowerCase(),
            chain_id: chain_id,
            friendly_name: addressInfo?.labels?.[0]?.name || address,
            logo: addressInfo?.labels?.[0]?.logo || addressInfo?.entities?.[0]?.logo || undefined,
            label: addressInfo?.labels?.[0]?.name || '',
            outgoing_count: 0,
            incoming_count: 0,
            total_value: 0,
            block_timestamps: transaction.block_timestamp ? [transaction.block_timestamp] : [],
            entity: addressInfo?.labels?.[0]?.name,
            verified: false,
            tags: [],
            contract_type: undefined,
            security_score: undefined,
            is_contract: true,
            is_token: false,
            token_symbol: undefined,
            token_name: undefined
        };
    } else if (transaction.block_timestamp && !nodes[nodeId].block_timestamps.includes(transaction.block_timestamp)) {
        // Add new timestamp to existing node
        nodes[nodeId].block_timestamps.push(transaction.block_timestamp);
        // Optional: Keep timestamps sorted
        nodes[nodeId].block_timestamps.sort();
    }
    return nodeId;
};
import { Table, Tag, Space, Avatar, Card, Select, Typography, Empty, Tooltip, Row, Col, Skeleton, Divider, Checkbox, Modal, Button, Statistic, DatePicker, Badge } from 'antd';
import { IAddress, IProfitability, IToken } from "interfaces/address.d";
import { ChainAvatar } from './StatsTab';
import { CHAIN_MAPPING } from "interfaces/chain.d";
import { useEffect, useState, useMemo } from 'react';
import { useOne } from '@refinedev/core';
import { LinkOutlined, InfoCircleOutlined, LeftOutlined, RightOutlined, RollbackOutlined, CloseOutlined, ArrowLeftOutlined, SafetyCertificateOutlined, WarningOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
const { Text, Title, Link } = Typography;
import { getExplorerTransactionLink } from '../../../utils/chainExplorer';
import { formatTokenBalance, formatUSD } from '../../../utils/formatters';
import { shortenAddress } from '../../../utils/formatters';
import { ShowButton } from '@refinedev/antd';

interface PortfolioResponse {
    request_id: string;
    results: {
        [chainId: string]: {
            data: IToken[];
            cursor: string | null;
            has_more: boolean;
        };
    };
    errors: Record<string, any>;
}

export interface ProfitabilityResponse {
    request_id: string;
    results: {
        [chainId: string]: {
            data: IProfitability[];
            cursor: string | null;
            has_more: boolean;
        };
    };
    errors: Record<string, any>;
}


interface PortfolioTabProps {
    record: Pick<IAddress, 'address' | 'active_chain_ids'>;
    isEmbedded?: boolean;
    blockTimestamps?: string[]; // ISO date strings
    onClose?: () => void;
    onReturnToGraph?: () => void;
}

export const PortfolioTab: React.FC<PortfolioTabProps> = ({ 
    record, 
    isEmbedded = false,
    blockTimestamps = [],
    onClose,
    onReturnToGraph
}) => {
    const availableChains = record?.active_chain_ids || [];
    const ALL_CHAINS = 'all';
    const [selectedChainIds, setSelectedChainIds] = useState<string[]>([ALL_CHAINS]);
    const [tokenTypeFilter, setTokenTypeFilter] = useState<string[]>([]);
    const [hideSpam, setHideSpam] = useState(true);
    const [selectedTokenAddress, setSelectedTokenAddress] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState<Dayjs>(() => {
        if (isEmbedded && blockTimestamps.length === 1) {
            return dayjs(blockTimestamps[0]);
        }
        return dayjs();
    });
    const [isBlockNavigation, setIsBlockNavigation] = useState("latest");

    const { data, isLoading, refetch } = useOne<PortfolioResponse>({
        resource: "stats",
        id: record?.address,
        meta: {
            address: record?.address,
            chain_ids: availableChains,
            limit: 1,
            operation: "getWalletTokenBalancesPrice",
            to_block: selectedDate.unix(),
            exclude_spam: true,
            exclude_unverified_contracts: true,
            exclude_native: false,
            is_block_navigation: isBlockNavigation,
        },
        queryOptions: {
            enabled: isEmbedded,
            refetchOnWindowFocus: false,
        },
    });

    const { data: profitabilityData, isLoading: isProfitabilityLoading, refetch: refetchProfitability } = useOne<ProfitabilityResponse>({
        resource: "stats",
        id: record?.address,
        meta: {
            address: record?.address,
            chain_ids: availableChains,
            operation: "getWalletProfitability",
            days: "all",
        },
        queryOptions: {
            enabled: isEmbedded,
            refetchOnWindowFocus: false,
        }
    });

    const filteredProfitabilityData = useMemo(() => {
        if (!profitabilityData?.data?.results) return [];
        
        return Object.values(profitabilityData.data.results)
            .flatMap(chainData => chainData.data)
            .filter(token => {
                // Chain filter
                if (!selectedChainIds.includes(ALL_CHAINS) && selectedChainIds.length > 0) {
                    if (!Object.keys(profitabilityData.data.results).some(chainId => selectedChainIds.includes(chainId))) {
                        return false;
                    }
                }
                return true;
            });
    }, [profitabilityData, selectedChainIds]);

    useEffect(() => {
        if(!isEmbedded){
            refetch();
            refetchProfitability();
        }
    }, [availableChains]);    
    
    const getAllTokens = () => {
        if (!data?.data?.results) return [];

        return Object.entries(data.data.results).flatMap(([chainId, chainData]) => 
            chainData.data.map(token => ({
                ...token,
                chainId,
            }))
        );
    };

    const getFilteredTokens = () => {
        const allTokens = getAllTokens();
        return allTokens.filter(token => {
            if (selectedChainIds.length > 0 && !selectedChainIds.includes(ALL_CHAINS) && !selectedChainIds.includes(token.chainId)) {
                return false;
            }
            if (hideSpam && token.possible_spam) {
                return false;
            }
            if (tokenTypeFilter.length > 0) {
                const type = token.native_token ? 'native' : token.contract_type?.toLowerCase();
                if (!tokenTypeFilter.includes(type || 'unknown')) {
                    return false;
                }
            }
            return true;
        });
    };

    const getAvailableTokenTypes = () => {
        const types = new Set(getAllTokens().map(token => 
            token.native_token ? 'native' : token.contract_type?.toLowerCase() || 'unknown'
        ));
        return Array.from(types);
    };

    const getTokenExplorerLink = (token: IToken & { chainId: string }) => {
        return getExplorerTransactionLink(token.chainId, token.token_address);
    };

    const tokens = getFilteredTokens();
    const totalPortfolioValue = tokens.reduce((sum, token) => sum + (token.usd_value || 0), 0);

    const aggregatedProfits = Object.entries(profitabilityData?.data?.results || {})
        .filter(([chainId]) => selectedChainIds.length === 0 || selectedChainIds.includes(ALL_CHAINS) || selectedChainIds.includes(chainId))
        .reduce((acc, [chainId, chainData]) => {
            chainData.data.forEach((profit) => {
                if (!acc[profit.token_address]) {
                    acc[profit.token_address] = {
                        ...profit,
                        chains: new Set([chainId]),
                        total_usd_invested: "0",
                        total_tokens_bought: "0",
                        total_tokens_sold: "0",
                        total_sold_usd: "0",
                        realized_profit_usd: "0",
                        count_of_trades: 0,
                        total_buys: 0,
                        total_sells: 0,
                    };
                } else {
                    acc[profit.token_address].chains.add(chainId);
                }
                
                acc[profit.token_address].total_usd_invested = (
                    Number(acc[profit.token_address].total_usd_invested) + 
                    Number(profit.total_usd_invested)
                ).toString();
                acc[profit.token_address].total_tokens_bought = (
                    Number(acc[profit.token_address].total_tokens_bought) + 
                    Number(profit.total_tokens_bought)
                ).toString();
                acc[profit.token_address].total_tokens_sold = (
                    Number(acc[profit.token_address].total_tokens_sold) + 
                    Number(profit.total_tokens_sold)
                ).toString();
                acc[profit.token_address].total_sold_usd = (
                    Number(acc[profit.token_address].total_sold_usd) + 
                    Number(profit.total_sold_usd)
                ).toString();
                acc[profit.token_address].realized_profit_usd = (
                    Number(acc[profit.token_address].realized_profit_usd) + 
                    Number(profit.realized_profit_usd)
                ).toString();
                acc[profit.token_address].count_of_trades += profit.count_of_trades;
                acc[profit.token_address].total_buys += profit.total_buys;
                acc[profit.token_address].total_sells += profit.total_sells;
            });
            return acc;
        }, {} as Record<string, IProfitability & { chains: Set<string> }>);

    const getSecurityBadge = (score: number | null) => {
        if (!score) return { 
            color: 'default', 
            text: 'No Score',
            icon: <QuestionCircleOutlined style={{ fontSize: '16px', color: '#8c8c8c' }} />
        };
        if (score >= 80) return { 
            color: 'success', 
            text: 'Safe',
            icon: <SafetyCertificateOutlined style={{ fontSize: '16px', color: '#52c41a' }} />
        };
        if (score >= 50) return { 
            color: 'warning', 
            text: 'Medium',
            icon: <WarningOutlined style={{ fontSize: '16px', color: '#faad14' }} />
        };
        return { 
            color: 'error', 
            text: 'Risk',
            icon: <CloseCircleOutlined style={{ fontSize: '16px', color: '#ff4d4f' }} />
        };
    };

    const profitabilityColumns = [
        {
            title: 'Token',
            key: 'token',
            render: (record: IProfitability & { chains: Set<string> }) => (
                <Space>
                    <Tooltip title={`Contract: ${record.token_address}`}>
                        <Avatar size="small" src={record.logo} />
                    </Tooltip>
                    <Space direction="vertical" size={0}>
                        <Space>
                            <Text strong>{record.name}</Text>
                        </Space>
                        <Space size={4}>
                            <Text type="secondary">{record.symbol}</Text>
                            <Tooltip 
                                title={CHAIN_MAPPING[record.chain_id || ''] || record.chain_id}
                            >
                                <span>
                                    <ChainAvatar chainId={record.chain_id || ''} size="small" />
                                </span>
                            </Tooltip>
                            {record.possible_spam && (
                                <Tooltip title="This token has been flagged as potential spam">
                                    <Tag color="warning" style={{ marginLeft: 4 }}>SPAM</Tag>
                                </Tooltip>
                            )}
                        </Space>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Total Invested',
            dataIndex: 'total_usd_invested',
            render: (value: string, record: IProfitability) => {
                const buyPrice = Number(record.avg_buy_price_usd);
                const sellPrice = Number(record.avg_sell_price_usd);
                const priceDiffPercent = ((sellPrice - buyPrice) / buyPrice) * 100;
                
                return (
                    <Tooltip overlayStyle={{ maxWidth: '400px' }} title={
                        <div style={{ color: 'white' }}>
                            <Space direction="vertical">
                                <div>
                                    Buy Price: ${buyPrice.toFixed(Number(record?.decimals) || 12)} per token
                                </div>
                                <div>
                                    Sell Price: ${sellPrice.toFixed(Number(record?.decimals) || 12)} per token
                                </div>
                                <div style={{ color: priceDiffPercent >= 0 ? '#52c41a' : '#ff4d4f' }}>
                                    Price Difference: {priceDiffPercent.toFixed(2)}%
                                </div>
                                <div>
                                    Total Tokens: {Number(record.total_tokens_bought).toLocaleString()}
                                </div>
                            </Space>
                        </div>
                    }>
                        <Text>{formatUSD(Number(value))}</Text>
                    </Tooltip>
                );
            },
            sorter: (a: IProfitability, b: IProfitability) => 
                Number(a.total_usd_invested) - Number(b.total_usd_invested),
        },
        {
            title: 'Realized P/L',
            dataIndex: 'realized_profit_usd',
            render: (value: string, record: IProfitability) => (
                <Tooltip overlayStyle={{ maxWidth: '400px' }} title={
                    <div style={{ color: 'white' }}>
                        <Space direction="vertical">
                            <div>
                                Buy Price: ${Number(record.avg_buy_price_usd).toFixed(12)} per token
                            </div>
                            <div>
                                Sell Price: ${Number(record.avg_sell_price_usd).toFixed(12)} per token
                            </div>
                            <div>
                                Total Sold: {formatUSD(Number(record.total_sold_usd))}
                            </div>
                            <div style={{ color: record.realized_profit_percentage >= 0 ? '#52c41a' : '#ff4d4f' }}>
                                Profit: {record.realized_profit_percentage.toFixed(2)}%
                            </div>
                        </Space>
                    </div>
                }>
                    <Text type={Number(value) >= 0 ? 'success' : 'danger'}>
                        {formatUSD(Number(value))} ({record.realized_profit_percentage.toFixed(2)}%)
                    </Text>
                </Tooltip>
            ),
            sorter: (a: IProfitability, b: IProfitability) => 
                Number(a.realized_profit_usd) - Number(b.realized_profit_usd),
        },
        {
            title: 'Trades',
            key: 'trades',
            render: (record: IProfitability) => (
                <Tooltip title={`
                    Total Trades: ${record.count_of_trades}
                    Tokens Bought: ${Number(record.total_tokens_bought).toLocaleString()}
                    Tokens Sold: ${Number(record.total_tokens_sold).toLocaleString()}
                `}>
                    <Space>
                        <Text type="success">B: {record.total_buys}</Text>
                        <Text type="danger">S: {record.total_sells}</Text>
                    </Space>
                </Tooltip>
            ),
            sorter: (a: IProfitability, b: IProfitability) => 
                a.count_of_trades - b.count_of_trades,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record: IProfitability) => (
                <Space>
                    <ShowButton 
                        type="link" 
                        size="small"
                        id={record.token_address}
                        resource="addresses"
                    >
                        View Token
                    </ShowButton>
                    {record.possible_spam && (
                        <Tooltip title="This token has been flagged as potential spam">
                            <Tag color="warning">SPAM</Tag>
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    const columns = [
        {
            title: 'Token',
            dataIndex: 'name',
            key: 'token',
            render: (text: string, token: IToken & { chainId: string }) => (
                <Space>
                    <Avatar 
                        size="small" 
                        src={token.logo || token.thumbnail}
                    />
                    <Space direction="vertical" size={0}>
                        <Space size={4}>
                            <Text strong>{token.name}</Text>
                            {token.security_score !== undefined && (
                                <Tooltip title={`Security Score: ${token.security_score ?? 'N/A'}/100`}>
                                    <Space size={2}>
                                        {getSecurityBadge(token.security_score).icon}
                                        <Text 
                                            type={token.security_score ? (
                                                token.security_score >= 80 ? 'success' : 
                                                token.security_score >= 50 ? 'warning' : 'danger'
                                            ) : undefined}
                                            style={{ fontSize: '12px' }}
                                        >
                                            {token.security_score ?? 'N/A'}
                                        </Text>
                                    </Space>
                                </Tooltip>
                            )}
                            <Tooltip title={CHAIN_MAPPING[token.chainId] || token.chainId}>
                                <span>
                                    <ChainAvatar chainId={token.chainId} size="small" />
                                </span>
                            </Tooltip>
                        </Space>
                        <Space size={4}>
                            <Tag color="blue">{token.symbol}</Tag>
                            {token.native_token && <Tag color="green">Native</Tag>}
                            {token.possible_spam && <Tag color="red">Spam</Tag>}
                            {token.verified_contract && <Tag color="green">Verified</Tag>}
                            {token.contract_type && (
                                <Tag color="purple">{token.contract_type}</Tag>
                            )}
                        </Space>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Balance',
            key: 'balance',
            render: (_: any, token: IToken) => (
                <Space direction="vertical" size={0}>
                    <Text>
                        {`${formatTokenBalance(token.balance, token.decimals || 0)} ${token.symbol}`}
                    </Text>
                    {token.usd_price !== undefined && token.usd_price !== null && (
                        <Tooltip title="Token Price">
                            <Text type="secondary">
                                ${Number(token.usd_price).toLocaleString(undefined, { 
                                    minimumFractionDigits: 2, 
                                    maximumFractionDigits: 6 
                                })}
                            </Text>
                        </Tooltip>
                    )}
                </Space>
            ),
        },
        {
            title: 'Value (USD)',
            key: 'value',
            sorter: (a: IToken, b: IToken) => (a.usd_value || 0) - (b.usd_value || 0),
            render: (_: any, token: IToken) => (
                <Space direction="vertical" size={0}>
                    {token.usd_value !== undefined && token.usd_value !== null ? (
                        <Text>{formatUSD(token.usd_value)}</Text>
                    ) : (
                        <Text type="secondary">No price data</Text>
                    )}
                    {token.usd_price_24hr_percent_change && (
                        <Text type={token.usd_price_24hr_percent_change > 0 ? 'success' : 'danger'}>
                            {token.usd_price_24hr_percent_change > 0 ? '+' : ''}
                            {token.usd_price_24hr_percent_change.toFixed(2)}%
                        </Text>
                    )}
                </Space>
            ),
        },
        {
            title: 'Portfolio %',
            key: 'portfolio_percentage',
            sorter: (a: IToken, b: IToken) => 
                (a.portfolio_percentage || 0) - (b.portfolio_percentage || 0),
            render: (_: any, token: IToken) => (
                token.portfolio_percentage !== undefined && token.portfolio_percentage !== null ? (
                    <Tooltip title={token.percentage_relative_to_total_supply ? 
                        `${token.percentage_relative_to_total_supply.toFixed(6)}% of total supply` : 
                        'Total supply data unavailable'
                    }>
                        <Text>{token.portfolio_percentage.toFixed(2)}%</Text>
                    </Tooltip>
                ) : (
                    <Text type="secondary">-</Text>
                )
            ),
        },
        {
            title: 'Links',
            key: 'links',
            render: (_: any, token: IToken & { chainId: string }) => {
                const explorerLink = getTokenExplorerLink(token);
                const hasTokenAddress = token.token_address && !token.native_token;
                
                return (
                    <Space>
                        {explorerLink && (
                            <Tooltip title="View on Explorer">
                                <Link href={explorerLink} target="_blank">
                                    <LinkOutlined/>
                                </Link>
                            </Tooltip>
                        )}
                        {hasTokenAddress && (
                            <>
                                <Tooltip title="View on CoinGecko">
                                    <Link 
                                        href={`https://www.coingecko.com/en/coins/${CHAIN_MAPPING[token.chainId].toLowerCase()}/${token.token_address}`} 
                                        target="_blank"
                                    >
                                        <img 
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAZlBMVEVHcEyZzFuQyEyMxj+JxTyIxT6JxT2Mxj+GxTuIxTz76YeGxDr/7Iz96onk4niDwy+501yr0Fz+/vbS22yezE719dd4s0Hw5oF1mE7987z+7qKzuq3R475bW15JSk1AQUSLipAAlT1HzPyyAAAACnRSTlMAYKX/sNNWG/OdxP1tgAAAAZVJREFUOI11U1l2gzAMpBUkBsk7xglZ2t7/kpXMEqCpPnjAjKSRPaqqV5zaRiGqpj1Vb+JcI7hoOKIDrM9HvEVviLTuxhRCMj22+3QFhnS3xJUpoDZFzug3sMSYyOP5hTvqjnEd3cpQfovrleHVrA8mXFOXM+lMMz2M0E4NjCRpHXtrLQ7Dz1yQgilN6vJNERH7+x0QYIhUNKfU10woBcghoA+3W+gBmORkas0l+HxFAecD2PB4Pr+TBaEwR3fhCqeqlRFJ8mz4ejweoRBKmXwNrq2ayB0yCiF9P79ud7vg6HWITaVYgjZCgD7cU8IJ7z2/sAhVNOpYfqP1fsm/JYuuS6xyQyiF57AWMHcjE0oLtwJr4CDjcwsRSf4FDIM8LpfB8HSaRcqYZcqVcWEU0JTz5THloOhPhwnviA9KVOYjAWPBtRy1XFY8EBYDka/Ldef+UKCfDTJdNxvm2CBPriKYra3+a6AW074tQG5j6z2DikE3ti+Ls+ugab84e6WOyPjD6pXlXQj+7fJKfHw2Qmg+PzY/fwFW7iBbUZyqggAAAABJRU5ErkJggg==" 
                                            alt="CoinGecko" 
                                            style={{ width: 16, height: 16 }} 
                                        />
                                    </Link>
                                </Tooltip>
                                <Tooltip title="View on DEX Screener">
                                    <Link 
                                        href={`https://dexscreener.com/${CHAIN_MAPPING[token.chainId].toLowerCase()}/${token.token_address}`} 
                                        target="_blank"
                                    >
                                        <img 
                                            src="https://dexscreener.com/favicon.png" 
                                            alt="DEX Screener" 
                                            style={{ width: 16, height: 16 }} 
                                        />
                                    </Link>
                                </Tooltip>
                            </>
                        )}
                    </Space>
                );
            },
        },
    ];

    // Filter tokens based on all criteria (chains, type, and spam)
    const filteredTokens = Object.values(aggregatedProfits).filter(token => {
        // Chain filter - if no chains selected, show all
        if (selectedChainIds.length > 0 && !selectedChainIds.includes(ALL_CHAINS)) {
            if (!Array.from(token.chains).some(chainId => selectedChainIds.includes(chainId))) {
                return false;
            }
        }

        // Spam filter
        if (hideSpam && token.possible_spam) {
            return false;
        }

        // Token type filter
        if (tokenTypeFilter.length > 0) {
            const tokenType = token.contract_type?.toLowerCase() || 'unknown';
            return tokenTypeFilter.includes(tokenType);
        }

        return true;
    });

    // Calculate total P/L values
    const totalPnL = filteredProfitabilityData.reduce((sum, token) => 
        sum + Number(token.realized_profit_usd) + Number(token.unrealized_profit_usd || 0), 0);
    const totalInvested = filteredProfitabilityData.reduce((sum, token) => 
        sum + Number(token.total_usd_invested), 0);
    const totalPnLPercentage = totalInvested > 0 ? (totalPnL / totalInvested) * 100 : 0;
    const totalRealizedPnL = filteredProfitabilityData.reduce((sum, token) => 
        sum + Number(token.realized_profit_usd), 0);
    const totalUnrealizedPnL = filteredProfitabilityData.reduce((sum, token) => 
        sum + Number(token.unrealized_profit_usd || 0), 0);

    const handleDateChange = (newDate: Dayjs | null, blockNav: "true" | "previous" | "next" | "latest" = "latest") => {
        if (!newDate) return;

        // Just update the states, useEffect will handle the refetch
        setIsBlockNavigation(blockNav);
        setSelectedDate(newDate);
    };

    useEffect(() => {
        if (!isEmbedded && selectedDate) {
            refetch();
        }
    }, [selectedDate, isBlockNavigation, isEmbedded, refetch]);

    const dateControls = (
        <Space direction="vertical" size="small">
            <Space>
                <Text type="secondary">
                    Select a date to view historical portfolio data:
                </Text>
                <Tooltip title="View portfolio data for any past date. Future dates are not available.">
                    <InfoCircleOutlined/>
                </Tooltip>
            </Space>
            <Space wrap>
                {blockTimestamps.length > 0 && (
                    <>
                        <Text type="secondary">Transaction timeline:</Text>
                        {blockTimestamps
                            .sort((a, b) => dayjs(b).unix() - dayjs(a).unix())
                            .slice(0, 3)
                            .map((timestamp) => {
                                const date = dayjs(timestamp);
                                return (
                                    <Space key={timestamp}>
                                        <Tooltip title="View portfolio 1 block before this transaction">
                                            <Button
                                                size="small"
                                                onClick={() => handleDateChange(date.subtract(1, 'second'), "previous")}
                                                icon={<LeftOutlined/>}                                            >
                                                Block -1
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={date.format('MMMM D, YYYY HH:mm:ss')}>
                                            <Button
                                                onClick={() => handleDateChange(date, 'true')}
                                                type={selectedDate.isSame(date, 'second') ? 'primary' : 'default'}
                                            >
                                                {date.format('MMM D')}
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="View portfolio 1 block after this transaction">
                                            <Button
                                                size="small"
                                                onClick={() => handleDateChange(date.add(1, 'second'), "next")}
                                                icon={<RightOutlined/>}
                                                disabled={date.add(1, 'second').isAfter(dayjs(), 'second')}
                                            >
                                                Block +1
                                            </Button>
                                        </Tooltip>
                                    </Space>
                                );
                            })}
                        {blockTimestamps.length > 3 && (
                            <Tooltip title="More timestamps available in calendar">
                                <Button size="small" type="dashed">+{blockTimestamps.length - 3}</Button>
                            </Tooltip>
                        )}
                        <Divider type="vertical" />
                    </>
                )}

                <Button 
                    onClick={() => handleDateChange(dayjs().subtract(1, 'day'), "previous")}
                    icon={<LeftOutlined/>}
                >
                    Previous Day
                </Button>
                <Button 
                    onClick={() => handleDateChange(dayjs())}
                    type={selectedDate.isSame(dayjs(), 'day') ? 'primary' : 'default'}
                >
                    Today
                </Button>
                <Button 
                    onClick={() => handleDateChange(dayjs().add(1, 'day'), "next")}
                    disabled={selectedDate.isAfter(dayjs(), 'day')}
                    icon={<RightOutlined/>}
                >
                    Next Day
                </Button>

                <DatePicker
                    value={selectedDate}
                    onChange={(date) => date && handleDateChange(date, 'true')}
                    allowClear={false}
                    disabledDate={(current) => current && current.isAfter(dayjs(), 'second')}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder="Select date"
                    showTime
                />
                <Text type="secondary">
                    Currently viewing: <Text strong>{selectedDate.format('MMMM D, YYYY HH:mm:ss')}</Text>
                </Text>
            </Space>
        </Space>
    );

    return (
        <Space 
            direction="vertical" 
            style={{ 
                width: '100%',
                ...(isEmbedded && {
                    height: 'calc(80vh - 180px)', // Match container height
                    minHeight: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '0 24px'
                })
            }} 
            size="large"
        >
            {isEmbedded && (
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Space align="center">
                        <Button 
                            type="link" 
                            icon={<ArrowLeftOutlined/>}
                            onClick={() => {
                                const overviewTab = document.querySelector('[data-node-key="overview"]');
                                if (overviewTab) {
                                    (overviewTab as HTMLElement).click();
                                }
                            }}
                        >
                            Back to Graph View
                        </Button>
                        <Divider type="vertical" />
                        <Tag color="warning">
                            <Space>
                                <Text type="secondary">Viewing portfolio for</Text>
                                <Text strong copyable={{ text: record?.address }}>
                                    <Tooltip title={record?.address}>
                                        {shortenAddress(record?.address)}
                                    </Tooltip>
                                </Text>
                                <Text type="secondary">on</Text>
                                <Space>
                                    <ChainAvatar chainId={availableChains?.[0]} size="small" />
                                    <Text>{CHAIN_MAPPING[availableChains?.[0]] || availableChains?.[0]}</Text>
                                </Space>
                            </Space>
                        </Tag>
                    </Space>
                    <Button 
                        type="text" 
                        icon={<CloseOutlined/>}
                        onClick={() => {
                            const overviewTab = document.querySelector('[data-node-key="overview"]');
                            if (overviewTab) {
                                (overviewTab as HTMLElement).click();
                            }
                        }}
                    />
                </Space>
            )}

            <Card style={{
                ...(isEmbedded && {
                    height: 'calc(100% - 60px)', // Account for header
                    overflow: 'auto'
                })
            }}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Space wrap>
                        <Select
                            mode="multiple"
                            value={selectedChainIds}
                            onChange={(values, options) => {
                                // If selecting ALL_CHAINS
                                if (values.includes(ALL_CHAINS) && !selectedChainIds.includes(ALL_CHAINS)) {
                                    setSelectedChainIds([ALL_CHAINS]);
                                    return;
                                }

                                // If selecting an individual chain while ALL_CHAINS is selected
                                if (selectedChainIds.includes(ALL_CHAINS) && values.length > 0) {
                                    // Get the newly selected chain (the last one added)
                                    const newChain = values.find(v => v !== ALL_CHAINS);
                                    if (newChain) {
                                        setSelectedChainIds([newChain]);
                                    }
                                    return;
                                }

                                // Normal selection
                                setSelectedChainIds(values);
                            }}
                            style={{ width: 350 }}
                            placeholder="Select chains"
                            allowClear
                            maxTagCount={3}
                            disabled={isLoading}                            
                            onClear={() => setSelectedChainIds([ALL_CHAINS])}
                        >
                            <Select.OptGroup label="All Active Chains">
                                <Select.Option key={ALL_CHAINS} value={ALL_CHAINS}>
                                    <Space>
                                        <Avatar.Group 
                                            maxCount={3} 
                                            size="small"
                                            maxStyle={{ 
                                                color: '#f56a00', 
                                                backgroundColor: '#fde3cf',
                                            }}
                                        >
                                            {availableChains.map((chainId: string) => (
                                                <Tooltip 
                                                    key={chainId} 
                                                    title={CHAIN_MAPPING[chainId] || chainId}
                                                    overlayStyle={{ zIndex: 1100 }}
                                                >
                                                    <ChainAvatar chainId={chainId} size="small" />
                                                </Tooltip>
                                            ))}
                                        </Avatar.Group>
                                        <Text>All Active Chains ({availableChains.length})</Text>
                                    </Space>
                                </Select.Option>
                            </Select.OptGroup>
                            <Select.OptGroup label="Individual Chains">
                                {availableChains.map((chainId: string) => (
                                    <Select.Option key={chainId} value={chainId}>
                                        <Space>
                                            <ChainAvatar chainId={chainId} size="small" />
                                            {CHAIN_MAPPING[chainId] || chainId}
                                        </Space>
                                    </Select.Option>
                                ))}
                            </Select.OptGroup>
                        </Select>
                        <Select
                            mode="multiple"
                            value={tokenTypeFilter}
                            onChange={setTokenTypeFilter}
                            style={{ width: 200 }}
                            placeholder="Filter by type"
                            allowClear
                        >
                            {getAvailableTokenTypes().map((type) => (
                                <Select.Option key={type} value={type}>
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </Select.Option>
                            ))}
                        </Select>
                        <Checkbox 
                            checked={hideSpam} 
                            onChange={(e) => setHideSpam(e.target.checked)}
                        >
                            Hide Spam Tokens
                        </Checkbox>
                    </Space>
                    
                    <Divider style={{ margin: '12px 0' }} />
                    {dateControls}
                </Space>
            </Card>

            {!isLoading && !isProfitabilityLoading && (
                <Card>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Title level={4}>Portfolio Summary</Title>
                            <Space direction="vertical">
                                <Text strong>Total Value: {formatUSD(totalPortfolioValue)}</Text>
                                {profitabilityData?.data?.results && (
                                    <>
                                        <Text>
                                            Total P/L: {' '}
                                            <Text type={totalPnL > 0 ? 'success' : 'danger'}>
                                                ${totalPnL.toLocaleString()} ({totalPnLPercentage.toFixed(2)}%)
                                            </Text>
                                        </Text>
                                        <Space>
                                            <Text>Realized P/L: ${totalRealizedPnL.toLocaleString()}</Text>
                                            <Text>Unrealized P/L: ${totalUnrealizedPnL.toLocaleString()}</Text>
                                        </Space>
                                    </>
                                )}
                            </Space>
                        </Col>
                        <Col span={12}>
                            <Text strong>Total Tokens: {tokens.length}</Text>
                            {selectedChainIds.length > 0 && (
                                <Text type="secondary"> (filtered by selected chains)</Text>
                            )}
                        </Col>
                    </Row>
                </Card>
            )}

            {isLoading ? (
                <>
                    <Card>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Skeleton.Input style={{ width: 200 }} active />
                                <br />
                                <Skeleton.Input style={{ width: 120, marginTop: 8 }} active />
                            </Col>
                            <Col span={12}>
                                <div style={{ textAlign: 'right' }}>
                                    <Skeleton.Input style={{ width: 150 }} active />
                                </div>
                            </Col>
                        </Row>
                    </Card>

                    <Card>
                        <Skeleton active paragraph={{ rows: 1 }} />
                        {[1, 2, 3].map((key) => (
                            <div key={key} style={{ marginBottom: 16 }}>
                                <Row gutter={[16, 16]} align="middle">
                                    <Col span={12}>
                                        <Space>
                                            <Skeleton.Avatar active size="small" />
                                            <Skeleton.Input style={{ width: 120 }} active />
                                            <Skeleton.Button active size="small" />
                                        </Space>
                                    </Col>
                                    <Col span={4}>
                                        <Skeleton.Input style={{ width: 80 }} active />
                                    </Col>
                                    <Col span={4}>
                                        <Skeleton.Input style={{ width: 100 }} active />
                                    </Col>
                                    <Col span={4}>
                                        <Skeleton.Button active size="small" />
                                    </Col>
                                </Row>
                                <Divider style={{ margin: '16px 0' }} />
                            </div>
                        ))}
                    </Card>
                </>
            ) : tokens.length > 0 ? (
                <Table 
                    dataSource={tokens} 
                    columns={columns}
                    rowKey={(record) => `${record.chainId}-${record.token_address}`}
                    pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        showTotal: (total) => `Total ${total} tokens`
                    }}
                />
            ) : (
                <Empty description={
                    selectedChainIds.length > 0 
                        ? "No tokens found for selected chains" 
                        : "No tokens found"
                } />
            )}

            {!isProfitabilityLoading && profitabilityData && (
                <Card>
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <Statistic
                                title="Total Invested"
                                value={totalInvested}
                                formatter={(value) => formatUSD(Number(value))}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title="Total Realized P/L"
                                value={totalRealizedPnL}
                                formatter={(value) => formatUSD(Number(value))}
                                valueStyle={{ color: totalRealizedPnL >= 0 ? '#3f8600' : '#cf1322' }}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title="Total Tokens"
                                value={filteredTokens.length}
                            />
                        </Col>
                    </Row>
                </Card>
            )}

            {!isProfitabilityLoading && profitabilityData && (
                <Card title="Token Profitability">
                    <Table
                        dataSource={filteredTokens}
                        columns={profitabilityColumns}
                        loading={isProfitabilityLoading}
                        rowKey="token_address"
                        pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                        }}
                    />
                </Card>
            )}
        </Space>
    );
}; 
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import { Space, Button, Tooltip, Dropdown, Menu, Checkbox } from 'antd';
import { SettingOutlined, AimOutlined, CloseOutlined, ShareAltOutlined, ThunderboltOutlined, LoadingOutlined, WarningOutlined, PauseCircleOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { GraphData, GraphNode, GraphLink, GraphSettings as GraphSettingsType, ChainGroup, GraphEntity, GraphChain } from '../../interfaces/graph';
import { drawOffChainNode, OffChainNodeConfig } from './offChainNodeRenderer';
import { NodeStats } from './components/NodeStats';
import { LinkStats } from './components/LinkStats';
import { EntityStats } from './components/EntityStats';
import { ChainStats } from './components/ChainStats';
import { GraphSettings } from './components/GraphSettings';
import { 
    processGraphData, 
    getLinkColor, 
    getMainTransactionIcon,
    rgbToHsl,
    hslToRgb
} from './graphUtils';
import { chainColors } from './utils/chainColors';

import { IAddress } from 'interfaces/address';
import { TimeRangeSlider } from './components/TimeRangeSlider';
import dayjs from 'dayjs';
import { ShareGraphModal } from './components/ShareGraphModal';
import { LiveIndicator } from './components/LiveIndicator';
import { SubscriptionStatuses, SubscriptionStatus } from '../../graphql/types';
import { TransactionReplay } from './components/TransactionReplay';
import { ReplaySettings } from './components/TransactionReplay';
import { StateLegend } from './components/StateLegend';
// Add these constants at the top of the file, after the imports
const TITLE_HEIGHT = 40;
const PADDING = 50;
const MARGIN = 30;

interface TransactionGraphProps {
    data: any[];
    filteredData: any[];
    chainIdToName: Record<string, string>;
    cdn_domain_name: string;
    graphKey: number;
    address_items_map: Record<string, IAddress>;
    setGraphKey: (updater: (prev: number) => number) => void;
    onNodeSelect?: (node: GraphNode | null) => void;
    onLinkSelect?: (link: GraphLink | null) => void;
    subscriptionStatus?: SubscriptionStatus;
    options?: {
        showReplay?: boolean;
        showLive?: boolean;
        showTimeRange?: boolean;
        showSettings?: boolean;
        showShare?: boolean;
    }
}

// Keep drawNode as a helper function outside the component
const drawNode = (node: any, ctx: CanvasRenderingContext2D, globalScale: number, images: HTMLImageElement[]) => {
    // Scale all sizes based on settings
    const sizeMultiplier = (node.__baseSize || 8) / 8;  // Normalize to default size of 8
    
    const fontSize = (12 * sizeMultiplier) / globalScale;
    ctx.font = `${fontSize}px Sans-Serif`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "black";

    // Scale all dimensions by the multiplier
    const logoSize = 52 * sizeMultiplier;
    const tagHeight = 24 * sizeMultiplier;
    const tagPadding = 12 * sizeMultiplier;
    const hitboxPadding = 20 * sizeMultiplier;
    const defaultSize = 12 * sizeMultiplier;
    const size = node.logo ? logoSize : defaultSize;

    let img = null;
    if (node.logo) {
        // Find the loaded image for this node
        img = images.find(img => img.src === node.logo);
    }

    // Check if image was found and successfully loaded
    if (img && img.complete && img.naturalWidth > 0) {
        // Draw hitbox
        ctx.beginPath();
        ctx.rect(
            node.x - size - hitboxPadding,
            node.y - size - hitboxPadding,
            (size + hitboxPadding) * 2,
            (size + hitboxPadding) * 2
        );
        ctx.fillStyle = 'rgba(0, 0, 0, 0)';
        ctx.fill();

        // Draw the image with scaled size
        ctx.drawImage(
            img,
            node.x - size / 2,
            node.y - size / 2,
            size,
            size
        );

        node.__bckgDimensions = {
            width: size + hitboxPadding * 2,
            height: size + hitboxPadding * 2
        };
    } else {
        const address = node.friendly_name || node.id || '';
        const displayText = `${address.slice(0, 10)}...${address.slice(-10)}`;
        
        // Scale text box based on font size
        const textWidth = ctx.measureText(displayText).width;
        const tagWidth = textWidth + (tagPadding * 2);
        
        node.__bckgDimensions = {
            width: tagWidth + hitboxPadding * 2,
            height: tagHeight + hitboxPadding * 2
        };

        ctx.beginPath();
        ctx.rect(
            node.x - node.__bckgDimensions.width / 2,
            node.y - node.__bckgDimensions.height / 2,
            node.__bckgDimensions.width,
            node.__bckgDimensions.height
        );
        ctx.fillStyle = 'rgba(0, 0, 0, 0)';
        ctx.fill();
        
        const radius = tagHeight / 2;
        ctx.fillStyle = '#e6f7ff';
        ctx.strokeStyle = '#1890ff';
        ctx.lineWidth = 1;
        
        ctx.beginPath();
        ctx.moveTo(node.x - tagWidth/2 + radius, node.y - tagHeight/2);
        ctx.lineTo(node.x + tagWidth/2 - radius, node.y - tagHeight/2);
        ctx.arc(node.x + tagWidth/2 - radius, node.y, radius, -Math.PI/2, Math.PI/2);
        ctx.lineTo(node.x - tagWidth/2 + radius, node.y + tagHeight/2);
        ctx.arc(node.x - tagWidth/2 + radius, node.y, radius, Math.PI/2, -Math.PI/2);
        ctx.closePath();
        
        ctx.fill();
        ctx.stroke();
        
        ctx.fillStyle = '#1890ff';
        ctx.fillText(displayText, node.x, node.y);
    }
};

// Keep other helper functions
const handleNodeDrag = () => {
    const canvas = document.querySelector('.force-graph-container canvas') as HTMLCanvasElement;
    if (canvas) {
        canvas.style.cursor = 'grabbing';
    }
};

const handleNodeDragEnd = () => {
    const canvas = document.querySelector('.force-graph-container canvas') as HTMLCanvasElement;
    if (canvas) {
        canvas.style.cursor = 'grab';
    }
};

const handleNodePointerArea = (node: any, color: string, ctx: CanvasRenderingContext2D) => {
    if (node.__bckgDimensions) {
        ctx.beginPath();
        ctx.fillStyle = color;
        if (node.logo) {
            // For nodes with images
            const size = node.__bckgDimensions.width / 2;
            ctx.arc(node.x, node.y, size / 2, 0, 2 * Math.PI);
        } else {
            // For nodes with text labels
            ctx.rect(
                node.x - node.__bckgDimensions.width / 2,
                node.y - node.__bckgDimensions.height / 2,
                node.__bckgDimensions.width,
                node.__bckgDimensions.height
            );
        }
        ctx.fill();
    }
};

export type TransactionState = 'predicted' | 'mempool' | 'pending' | 'confirmed';

export interface StateConfig {
    colors: {
        primary: string;
        secondary: string;
    };
    node: {
        rotationSpeed: number;
        rotationDirection: 1 | -1;
        pulseSpeed: number;
        pulseRange: [number, number];
        dashPattern: [number, number];
        glowIntensity: number;
    };
    link: {
        particleCount: number;
        particleSpeed: number;
        particleWidth: number;
        dashPattern: [number, number] | false;
        pulseRange: [number, number];
        width: number;
        flowSpeed: number;        // Added: Speed of the flowing effect
        flowOffset: number;       // Added: Initial offset for the flow
        pulseSpeed: number;       // Added: Speed of the pulse animation
        particleLength: number;   // Added: Length of each particle
        glowIntensity: number;    // Added: Intensity of the glow effect
    };
}

const STATE_CONFIGS: Record<TransactionState, StateConfig> = {
    predicted: {
        colors: {
            primary: '#722ed1',    // Purple
            secondary: 'rgba(114, 46, 209, 0.3)'
        },
        node: {
            rotationSpeed: 15,
            rotationDirection: -1,
            pulseSpeed: 4,
            pulseRange: [0.8, 1.4],
            dashPattern: [3, 3],
            glowIntensity: 0.4
        },
        link: {
            particleCount: 6,
            particleSpeed: 0.001,
            particleWidth: 4,
            dashPattern: [5, 5],
            pulseRange: [0.3, 0.8],
            width: 2.5,
            flowSpeed: 0.15,
            flowOffset: 0.2,
            pulseSpeed: 3,
            particleLength: 15,
            glowIntensity: 0.4            
        }
    },
    mempool: {
        colors: {
            primary: '#ffa940',    // Orange
            secondary: 'rgba(255, 169, 64, 0.3)'
        },
        node: {
            rotationSpeed: 10,
            rotationDirection: -1,
            pulseSpeed: 3,
            pulseRange: [0.9, 1.3],
            dashPattern: [5, 5],
            glowIntensity: 0.3
        },
        link: {
            particleCount: 4,
            particleSpeed: 0.001,
            particleWidth: 3,
            dashPattern: [10, 10],
            pulseRange: [0.4, 0.9],
            width: 2,
            flowSpeed: 0.1,
            flowOffset: 0.15,
            pulseSpeed: 2,
            particleLength: 12,
            glowIntensity: 0.3            
        }
    },
    pending: {
        colors: {
            primary: '#1890ff',    // Blue
            secondary: 'rgba(24, 144, 255, 0.3)'
        },
        node: {
            rotationSpeed: 12,
            rotationDirection: 1,
            pulseSpeed: 3.5,
            pulseRange: [0.9, 1.3],
            dashPattern: [8, 8],
            glowIntensity: 0.3,
        },
        link: {
            particleCount: 4,
            particleSpeed: 0.001,
            particleWidth: 3,
            dashPattern: [8, 8],
            pulseRange: [0.4, 0.9],
            width: 2,
            flowSpeed: 0.12,
            flowOffset: 0.18,
            pulseSpeed: 2.5,
            particleLength: 10,
            glowIntensity: 0.3            
        }
    },
    confirmed: {
        colors: {
            primary: '#52c41a',    // Green
            secondary: 'rgba(82, 196, 26, 0.3)'
        },
        node: {
            rotationSpeed: 0,      // No rotation for confirmed
            rotationDirection: 1,
            pulseSpeed: 4,
            pulseRange: [1.0, 1.2],
            dashPattern: [0, 0],   // Solid line
            glowIntensity: 0.3
        },
        link: {
            particleCount: 4,
            particleSpeed: 0.001,
            particleWidth: 3,
            dashPattern: [8, 8],
            pulseRange: [0.6, 1.0],
            width: 2,
            flowSpeed: 0.08,
            flowOffset: 0.1,
            pulseSpeed: 1.5,
            particleLength: 8,
            glowIntensity: 0.2            
        }
    }
};

export const TransactionGraph: React.FC<TransactionGraphProps> = ({
    data,
    filteredData,
    chainIdToName,
    cdn_domain_name,
    graphKey,
    address_items_map,
    setGraphKey,
    onNodeSelect,
    onLinkSelect,
    subscriptionStatus,
    options = {
        showReplay: false,
        showLive: false,
        showTimeRange: false,
        showSettings: false,
        showShare: false
    }
}) => {
    const [selectedNode, setSelectedNode] = useState<GraphNode | null>(null);
    const [selectedLink, setSelectedLink] = useState<GraphLink | null>(null);
    const [selectedEntity, setSelectedEntity] = useState<GraphEntity | null>(null);
    const [selectedChain, setSelectedChain] = useState<GraphChain | null>(null);
    const [sortedChains, setSortedChains] = useState<any[]>([]);
    const [images, setImages] = useState<HTMLImageElement[]>([]);
    const [settings, setSettings] = useState<GraphSettingsType>({
        physics: {
            enabled: true,
            strength: 0.5
        },
        nodes: {
            size: 12,
            showLabels: true
        },
        links: {
            width: 2,
            showArrows: true,
            curved: true
        },
        visual: {
            darkMode: false,
            enableEventLogs: false,
            enableBridgeNodes: true,
            enableNodeDrag: true,
            showTimeRange: true
        },
        layout: {
            horizontalSpacing: 350,
            verticalSpacing: 400,
            nodesPerRow: 10
        }
    });
    const fgRef = useRef<any>();
    const [nodesPerRow, setNodesPerRow] = useState(settings.layout?.nodesPerRow || 10);
    const [verticalSpacing, setVerticalSpacing] = useState(settings.layout?.verticalSpacing || 400);
    const [chainGroups, setChainGroups] = useState(new Map<string, ChainGroup>());
    const [entityGroups, setEntityGroups] = useState<Map<string, Map<string, GraphNode[]>>>(new Map());
    const [timeRange, setTimeRange] = useState<[number, number] | null>(null);
    const [shareModalVisible, setShareModalVisible] = useState(false);
    const [nodeRefs] = useState(new Map()); // Store node refs to prevent recreation
    const [replayData, setReplayData] = useState<{
        nodes: any[];
        links: any[];
        currentStep: number;
        sortedItems: any[];
    }>({
        nodes: [],
        links: [],
        currentStep: 0,
        sortedItems: []
    });
    const [isReplaying, setIsReplaying] = useState(false);
    const replayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [replaySettings, setReplaySettings] = useState<ReplaySettings>({
        speed: 1,
        showMempool: true,
        highlightNew: true,
        autoCenter: false,
        showTimestamps: true,
        loop: false,
        stepMode: 'individual',
        deriveStates: false,
        showStateLabels: true
    });
    const [isFullScreen, setIsFullScreen] = useState(false);

    const fullGraphData = useMemo(() => {
        return processGraphData(
            data,
            filteredData,
            chainIdToName,
            settings,
            address_items_map
        );
    }, [data, filteredData, chainIdToName, settings, address_items_map]);

    const graphData = useMemo(() => {
        if (!timeRange) return fullGraphData;

        const [startTime, endTime] = timeRange;
        
        return {
            ...fullGraphData,
            nodes: fullGraphData.nodes.filter((node: any) => {
                // Simple timestamp check without any minimum range enforcement
                return node.block_timestamps.some((ts: any) => {
                    const timestamp = dayjs(ts).valueOf();
                    return timestamp >= startTime && timestamp <= endTime;
                });
            }),
            links: fullGraphData.links.filter((link: any) => {
                // Simple timestamp check for links
                const timestamp = dayjs(link.block_timestamp).valueOf();
                return timestamp >= startTime && timestamp <= endTime;
            })
        };
    }, [fullGraphData, timeRange]);

    const handleTimeRangeChange = useCallback((range: [number, number]) => {
        setTimeRange(range);
    }, []);

    // Load images for nodes
    useEffect(() => {
        const loadImages = async () => {
            const imagePromises = graphData.nodes
                .filter((node: any) => node.logo)
                .map((node: any) => {
                    return new Promise<HTMLImageElement>((resolve) => {
                        const img = new Image();
                        
                        // Set up error handling
                        img.onerror = () => {
                            console.warn(`Failed to load image: ${node.logo}`);
                            // Mark the node to not use the logo anymore
                            node._logoFailed = true;
                            resolve(img); // Still resolve to prevent blocking
                        };
                        
                        // Set up load success handling
                        img.onload = () => {
                            // Reset any previous failure flag
                            node._logoFailed = false;
                            resolve(img);
                        };
                        
                        // Start loading the image
                        img.src = node.logo!;
                    });
                });

            const loadedImages = await Promise.all(imagePromises);
            // Only keep successfully loaded images
            setImages(loadedImages.filter((img: HTMLImageElement) => 
                img.complete && img.naturalWidth > 0
            ));
        };

        loadImages();
    }, [graphData.nodes]);

    const handleGraphLayout = (chainId?: string, centerAll: boolean = false) => {
        if (!fgRef.current) return;

        const fg = fgRef.current;
        if (centerAll) {
            fg.zoomToFit(400, 50);
        } else if (chainId) {
            if (sortedChains.length > 0) {
                const chainBounds = sortedChains.find(([id]) => id === chainId)?.[1];
                if (chainBounds) {
                    // Special case for single point (like offchain nodes)
                    if (chainBounds.minX === chainBounds.maxX && chainBounds.minY === chainBounds.maxY) {
                        fg.centerAt(chainBounds.minX, 200, 1000);
                        fg.zoom(1, 1000);
                        return;
                    }
    
                    // Calculate center point
                    const x = (chainBounds.minX + chainBounds.maxX) / 2;
                    const y = (chainBounds.minY + chainBounds.maxY) / 2;
    
                    // Calculate chain dimensions with padding
                    const padding = 100;
                    const chainWidth = Math.max(chainBounds.maxX - chainBounds.minX, 100) + padding;
                    const chainHeight = Math.max(chainBounds.maxY - chainBounds.minY, 100) + padding;
    
                    // Get current viewport dimensions
                    const bbox = fg.getGraphBbox();
                    const graphWidth = Math.max(bbox.width, 100);
                    const graphHeight = Math.max(bbox.height, 100);
    
                    // Calculate zoom with safety checks
                    let zoom = 1;
                    if (isFinite(graphWidth) && isFinite(graphHeight) && 
                        chainWidth > 0 && chainHeight > 0) {
                        const zoomX = graphWidth / chainWidth;
                        const zoomY = graphHeight / chainHeight;
                        zoom = Math.min(Math.max(Math.min(zoomX, zoomY), 0.1), 10);
                    }
    
                    // Apply position and zoom
                    fg.centerAt(x, y, 1000);
                    fg.zoom(zoom, 1000);
                }
            } else {
                const chainNodes = graphData.nodes.filter((n: any) => n.chain_id === chainId);
                if (chainNodes.length > 0) {
                    const nodeIds = new Set(chainNodes.map((n: any) => n.id));
                    fg.zoomToFit(400, 50, (node: any) => nodeIds.has(node.id));
                }
            }
        }
    };

    const formatNodeLabel = (node: any) => {
        // Define styles using Unicode box drawing characters
        const boxChars = {
            topLeft: '╭',
            topRight: '╮',
            bottomLeft: '╰',
            bottomRight: '╯',
            vertical: '│',
            horizontal: '─',
            separator: '┄'
        };

        // Helper function to safely truncate and format text
        const formatText = (text: any, maxLength: number = 30) => {
            if (!text) return '';
            
            // Handle array of labels
            if (Array.isArray(text)) {
                const labelNames = text.map(label => label?.name || '').filter(Boolean);
                const combinedText = labelNames.join(', ');
                return combinedText.length > maxLength ? `${combinedText.slice(0, maxLength)}...` : combinedText;
            }
            
            // Handle object label
            if (typeof text === 'object' && text !== null) {
                const labelText = text.name || '';
                return labelText.length > maxLength ? `${labelText.slice(0, maxLength)}...` : labelText;
            }
            
            // Handle string
            const stringText = String(text);
            return stringText.length > maxLength ? `${stringText.slice(0, maxLength)}...` : stringText;
        };

        // Helper function to format address for hover text
        const formatAddressForHover = (name: string, address: string) => {
            const shortAddress = `${address.slice(0, 6)}...${address.slice(-6)}`;
            if (name == address) {
                return `${shortAddress}`;
            } else {
                return `${name} (${shortAddress})`;
            }
        };

        // Format each piece of information
        const sections = [];
        
        // Chain info with icon
        const chainName = chainIdToName[node.chain_id] || `Chain ${node.chain_id}` || 'Unknown Chain';
        sections.push({
            icon: '⛓️',
            label: 'Chain',
            value: formatText(chainName)
        });
        
        // Address with icon
        sections.push({
            icon: '🔑',
            label: 'Address',
            value: formatAddressForHover(node.friendly_name, node.friendly_name)
        });
        
        // Label with icon (if exists)
        if (node.label) {
            sections.push({
                icon: '🏷️',
                label: 'Label',
                value: formatText(node.label)
            });
        }
        
        // Entity with icon (if exists)
        if (node.entity) {
            sections.push({
                icon: '👤',
                label: 'Entity',
                value: formatText(node.entity)
            });
        }
        
        return sections.map(section => `${section.icon} ${section.label}: ${section.value}`).join('\n');
    };

    const formatLinkLabel = (link: any) => {
        // Helper function to safely format text
        const formatText = (text: string | undefined | null, maxLength: number = 30) => {
            if (!text || text === '') return null;  // Return null instead of 'N/A'
            return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
        };
    
        if (!link || !link.type) {
            return ['❓ Type: Unknown'].join('\n');
        }
    
        switch (link.type.toLowerCase()) {
            case 'nft': {
                const sections = [
                    '🎨 Type: NFT Transfer',
                    link.token_id && `🔢 Token ID: ${formatText(link.token_id)}`,
                    `📊 Amount: ${link.amount?.toString() || '1'}`,
                    link.token_name && `📝 Collection: ${formatText(link.token_name)}`,
                    link.token_symbol && `🪙 Symbol: ${link.token_symbol}`,
                    link.direction && `↔️ Direction: ${formatText(link.direction)}`,
                    link.contract_type && `📋 Contract: ${formatText(link.contract_type)}`
                ];
                
                if (link.verified_collection) sections.push('✅ Verified Collection');
                if (link.possible_spam) sections.push('⚠️ Warning: Possible Spam');
                
                return sections.filter(Boolean).join('\n');
            }
    
            case 'erc20': {
                const sections = [
                    '💰 Type: ERC20 Transfer',
                    `${link.token_logo ? '🖼️' : '🪙'} Token: ${formatText(link.token_name || link.token_symbol || 'Token')}${link.token_logo ? ` (${link.token_symbol})` : ''}`,
                    `📊 Amount: ${link.value_formatted || '0'} ${link.token_symbol || ''}`,
                    `↔️ Direction: ${formatText(link.direction || 'transfer')}`,
                    `🔒 Security: ${link.security_score || 'N/A'}`
                ];

                if (link.verified_contract) sections.push('✅ Status: Verified');
                if (link.possible_spam) sections.push('⚠️ Warning: Possible Spam');

                return sections.join('\n');
            }
    
            case 'native': {
                const sections = [
                    '💎 Type: Native Transfer',
                    `📊 Amount: ${link.value_formatted || '0'} ${link.token_symbol || ''}`,
                    `↔️ Direction: ${formatText(link.direction || 'transfer')}`,
                    `📝 Transaction: ${formatText(link.internal_transaction ? 'Internal' : 'External')}`
                ];

                if (link.possible_spam) sections.push('⚠️ Warning: Possible Spam');

                return sections.join('\n');
            }

            case 'internal': {
                // Helper function to format Wei to native token with proper decimal places
                const formatWeiToNative = (wei: string) => {
                    const value = BigInt(wei);
                    const nativeValue = Number(value) / 1e18;
                    // Use toFixed(6) for consistent decimal places, but remove trailing zeros
                    return nativeValue.toFixed(6).replace(/\.?0+$/, '');
                };

                const sections = [
                    '🔄 Type: Internal Transaction',
                    `💰 Amount: ${formatWeiToNative(link.value)} ${chainIdToName[link.chain_id]?.split(' ')[0] || 'Native'}`,
                    link.call_type && `📝 Call Type: ${formatText(link.call_type)}`,
                    link.gas && `⛽ Gas Limit: ${link.gas.toLocaleString()}`,
                    link.gas_used !== undefined && `⛽ Gas Used: ${link.gas_used.toLocaleString()}`,
                    link.block_number && `🔢 Block: ${link.block_number.toLocaleString()}`,
                    link.transaction_hash && `📝 Hash: ${formatText(link.transaction_hash, 12)}`
                ];

                if (link.possible_spam) sections.push('⚠️ Warning: Possible Spam');

                return sections.filter(Boolean).join('\n');
            }

            default: {
                const icon = getMainTransactionIcon(link);
                const sections = [
                    `${icon} ${link.summary || ''}`,
                    link.method_label && `📝 Method: ${formatText(link.method_label)}`,
                    link.method && `🔧 Function: ${formatText(link.method)}`,
                    `💰 Value: ${link.value_formatted} ${link.token_symbol || 'ETH'}`,
                    link.gas_used && `⛽ Gas Used: ${link.gas_used.toLocaleString()}`,
                    link.transaction_fee && `💸 Fee: ${link.transaction_fee} ETH`,
                    link.category && `📋 Category: ${formatText(link.category)}`
                ];

                if (link.possible_spam) sections.push('⚠️ Warning: Possible Spam');
                if (link.status === '1') sections.push('✅ Success');
                else if (link.status === '0') sections.push('❌ Failed');

                return sections.filter(Boolean).join('\n');
            }
        }
    };
    
    const handleSettingChange = (key: keyof GraphSettingsType, value: any) => {
        setSettings(prev => ({
            ...prev,
            [key]: value
        }));

        // Apply settings to the graph
        if (fgRef.current) {
            const fg = fgRef.current;
            switch (key) {
                case 'physics':
                    if ('enabled' in value) {
                        if (!value.enabled) {
                            if (fg.d3Force('charge')) {
                                fg.d3Force('charge').strength(0);
                            }
                            if (fg.d3Force('link')) {
                                fg.d3Force('link').strength(0);
                            }
                            setGraphKey(prev => prev + 1);
                        } else {
                            if (fg.d3Force('charge')) {
                                fg.d3Force('charge').strength(-settings.physics.strength);
                            }
                            if (fg.d3Force('link')) {
                                fg.d3Force('link').strength(1);
                            }
                        }
                    }
                    if ('strength' in value || 'linkDistance' in value || 'chargeStrength' in value) {
                        if (fg.d3Force('charge')) {
                            fg.d3Force('charge').strength(-value.chargeStrength);
                        }
                        if (fg.d3Force('link')) {
                            fg.d3Force('link').distance(value.linkDistance);
                        }
                    }
                    break;
                case 'nodes':
                    // Instead of calling nodeRelSize, we'll force a re-render
                    setGraphKey(prev => prev + 1);
                    break;
                case 'links':
                    setGraphKey(prev => prev + 1);
                    break;
                case 'visual':
                    if ('darkMode' in value) {
                        fg.backgroundColor = value.darkMode ? '#1f1f1f' : '#ffffff';
                    }
                    break;
                case 'layout':
                    setGraphKey(prev => prev + 1);
                    fg.d3Force('charge', null);
                    fg.d3Force('link', null);
                    fg.d3Force('center', null);
                    setTimeout(() => {
                        if (fg.d3Force('charge')) {
                            fg.d3Force('charge').strength(-settings.physics.strength);
                        }
                        if (fg.d3Force('link')) {
                            fg.d3Force('link').distance(settings.physics.strength);
                        }
                        handleGraphLayout(undefined, true);
                    }, 100);
                    break;
            }
            // Force a re-render of the graph
            if (fg._rerender) fg._rerender();
        }
    };

    const handleNodesPerRowChange = (value: number) => {
        setNodesPerRow(value);
        setSettings((prev: any) => ({
            ...prev,
            layout: {
                ...prev.layout,
                nodesPerRow: value
            }
        }));  
        setGraphKey(prev => prev + 1);
        handleGraphLayout(undefined, true);
    };

    const handleVerticalSpacingChange = (value: number) => {
        setVerticalSpacing(value);
        setSettings((prev: any) => ({
            ...prev,
            layout: {
                ...prev.layout,
                verticalSpacing: value
            }
        }));       
        setGraphKey(prev => prev + 1);
        handleGraphLayout(undefined, true);
    };

    // Initial load and data updates
    useEffect(() => {
        // Only run on initial load or when data actually changes
        if (!selectedNode) {  // Don't run if a node is selected
            handleGraphLayout(undefined, true);
        }
    }, [graphData.nodes.length, chainGroups.size]); // Only watch for actual data changes

    // Handle window resizing
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            handleGraphLayout(undefined, true);
        });
        
        const containerEl = document.querySelector('.force-graph-container');
        if (containerEl) {
            resizeObserver.observe(containerEl);
        }
        
        return () => {
            if (containerEl) {
                resizeObserver.unobserve(containerEl);
            }
            resizeObserver.disconnect();
        };
    }, []); // Empty dependency array as we only want to set up the observer once

    const drawChainSections = useCallback((
        nodes: GraphNode[], 
        chainIdToName: Record<string, string>, 
        ctx: CanvasRenderingContext2D,
        visibleNodeIds?: Set<string> 
    ) => {
        // Group nodes by chain and entity
        const nodesByChain = new Map<string, GraphNode[]>();
        const entityGroups = new Map<string, Map<string, GraphNode[]>>();
        
        // First pass: group nodes by chain and entity
        nodes.forEach((node) => {
            // Group by chain
            if (!nodesByChain.has(node.chain_id)) {
                nodesByChain.set(node.chain_id, []);
            }
            nodesByChain.get(node.chain_id)!.push(node);

            // Only group by entity if entity exists
            if (node.entity) {
                if (!entityGroups.has(node.chain_id)) {
                    entityGroups.set(node.chain_id, new Map<string, GraphNode[]>());
                }
                const chainMap = entityGroups.get(node.chain_id)!;
                if (!chainMap.has(node.entity)) {
                    chainMap.set(node.entity, []);
                }
                chainMap.get(node.entity)!.push(node);
            }
        });

        // Calculate and sort chain boundaries
        const chainBoundaries = new Map();
        nodesByChain.forEach((nodes, chainId) => {
            const minX = Math.min(...nodes.map(n => n.x ?? 0));
            const maxX = Math.max(...nodes.map(n => n.x ?? 0));
            const minY = Math.min(...nodes.map(n => n.y ?? 0));
            const maxY = Math.max(...nodes.map(n => n.y ?? 0));
            chainBoundaries.set(chainId, { minX, maxX, minY, maxY });
        });

        const sortedChains = Array.from(chainBoundaries.entries())
            .sort(([, a], [, b]) => a.minX - b.minX);

        setSortedChains(sortedChains);
        // Draw sections
        const padding = PADDING;
        const titleHeight = TITLE_HEIGHT;
        const margin = MARGIN;
        let previousRight = -Infinity;
        const newChainGroups = new Map();

        // Draw chain sections first (background layer)
        sortedChains.forEach(([chainId, bounds]) => {
            const chainNodes = nodesByChain.get(chainId)!;
            
            let x = bounds.minX - padding - margin;
            let width = (bounds.maxX - bounds.minX) + (padding * 2) + (margin * 2);
            
            if (previousRight !== -Infinity) {
                const gap = 30;
                x = Math.max(x, previousRight + gap);
            }
            
            const y = bounds.minY - padding - titleHeight - margin;
            const height = (bounds.maxY - bounds.minY) + (padding * 2) + titleHeight + (margin * 2);
            
            previousRight = x + width;

            // Draw chain section
            const colors = chainColors[chainId as keyof typeof chainColors] || {
                fill: 'rgba(128, 128, 128, 0.1)',
                stroke: 'rgb(128, 128, 128)'
            };

            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';

            // Draw chain background
            ctx.beginPath();
            ctx.fillStyle = colors.fill;
            ctx.fillRect(x, y, width, height);
            
            // Draw chain border
            ctx.strokeStyle = colors.stroke;
            ctx.lineWidth = 2;
            ctx.strokeRect(x, y, width, height);

            // Draw chain label
            const chainName = chainIdToName[chainId] || chainId;
            ctx.font = 'bold 16px Arial';
            ctx.fillStyle = colors.stroke;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(chainName, x + (width / 2), y + titleHeight / 2);

            ctx.restore();

            // Store chain bounds
            newChainGroups.set(chainId, {
                x, y, width, height,
                name: chainName,
                chain_id: chainId
            });

            // Sort entity groups by position to prevent overlaps
            const chainEntityGroups = entityGroups.get(chainId);
            if (chainEntityGroups) {
                const sortedEntityGroups = Array.from(chainEntityGroups.entries())
                    .sort(([, a], [, b]) => {
                        const aY = Math.min(...a.map(n => n.y ?? 0));
                        const bY = Math.min(...b.map(n => n.y ?? 0));
                        return aY - bY;
                    });

                let lastGroupBottom = y + titleHeight;

                // Generate color variations for entities within the chain
                const generateEntityColors = (baseColors: { fill: string; stroke: string }, index: number) => {
                    // Parse the base color
                    const match = baseColors.stroke.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/);
                    if (!match) return baseColors;

                    const [_, r, g, b] = match.map(Number);
                    
                    // Create variations using HSL
                    const [h, s, l] = rgbToHsl(r, g, b);
                    
                    // Rotate hue based on entity index while maintaining saturation
                    const newHue = (h + (index * 30)) % 360;
                    const [newR, newG, newB] = hslToRgb(newHue, s, l);

                    return {
                        fill: `rgba(${newR}, ${newG}, ${newB}, 0.15)`,
                        stroke: `rgba(${newR}, ${newG}, ${newB}, 0.6)`
                    };
                };

                sortedEntityGroups.forEach(([entityKey, groupNodes], index) => {
                    if (groupNodes.length < 1) return;

                    const entityPadding = 80;
                    const extraVerticalSpace = 60;
                    
                    // Calculate initial bounds
                    let minX = Math.min(...groupNodes.map(n => n.x ?? 0));
                    let minY = Math.max(lastGroupBottom + entityPadding, Math.min(...groupNodes.map(n => n.y ?? 0)));
                    let maxX = Math.max(...groupNodes.map(n => n.x ?? 0));
                    let maxY = Math.max(...groupNodes.map(n => n.y ?? 0));

                    // Adjust node positions to prevent overlap
                    groupNodes.forEach(node => {
                        if (node.y! < minY) {
                            node.y = minY;
                        }
                    });

                    const entityWidth = maxX - minX + (entityPadding * 2);
                    const entityHeight = maxY - minY + (entityPadding * 2) + extraVerticalSpace;

                    // Get base color for the entity
                    const baseColors = chainColors[chainId as keyof typeof chainColors] || {
                        fill: 'rgba(128, 128, 128, 0.1)',
                        stroke: 'rgb(128, 128, 128)'
                    };

                    const entityColors = generateEntityColors(baseColors, index);

                    // Draw entity group
                    ctx.beginPath();
                    ctx.roundRect(
                        minX - entityPadding,
                        minY - entityPadding,
                        entityWidth,
                        entityHeight,
                        15
                    );
                    ctx.fillStyle = entityColors.fill;
                    ctx.fill();
                    ctx.strokeStyle = entityColors.stroke;
                    ctx.lineWidth = 1;
                    ctx.stroke();

                    // Entity label
                    ctx.fillStyle = 'rgba(0, 0, 0, 0.6)';
                    ctx.font = '12px Arial';
                    ctx.textAlign = 'left';
                    ctx.fillText(
                        `${entityKey} (${groupNodes.length})`,
                        minX - entityPadding + 10,
                        minY - entityPadding + 20
                    );

                    lastGroupBottom = maxY + entityPadding;
                });
            }

            // Constrain nodes within chain bounds
            chainNodes.forEach(node => {
                if (node.x !== undefined) {
                    if (node.x < x + margin) node.x = x + margin;
                    if (node.x > x + width - margin) node.x = x + width - margin;
                }
                if (node.y !== undefined) {
                    if (node.y < y + titleHeight + margin) node.y = y + titleHeight + margin;
                    if (node.y > y + height - margin) node.y = y + height - margin;
                }
            });
        });

        setChainGroups(newChainGroups);
    }, [chainGroups, chainIdToName, settings.links?.curved]);

    useEffect(() => {
        if (fgRef.current) {
            const fg = fgRef.current;
            
            // Configure forces
            fg.d3Force('charge')?.strength(-100);
            fg.d3Force('link')?.distance(100);
            // fg.d3Force('center', d3.forceCenter());
            
            // Adjust forces based on settings
            if (settings.physics?.enabled) {
                fg.d3Force('charge')?.strength(-100 * (settings.physics.strength || 1));
            } else {
                fg.d3Force('charge', null);
            }
        }
    }, [settings.physics, fgRef.current]);

    useEffect(() => {
        if (graphData.nodes) {
            graphData.nodes.forEach((node: any) => {
                // Store the current size setting on each node
                node.__baseSize = settings.nodes?.size || 8;
            });
        }
    }, [graphData.nodes, settings.nodes?.size]);

    const handleShare = (values: any) => {
        // Handle share logic here
        setShareModalVisible(false);
    };

    // Memoize node canvas object function
    const nodeCanvasObjectHandler = useCallback((node: any, ctx: CanvasRenderingContext2D, globalScale: number) => {
        // Existing node rendering
        const existingRender = (node: any, ctx: CanvasRenderingContext2D, globalScale: number) => {
            // Draw chain sections for the first node in each frame
            if (node.index === 0) {
                // Save the current canvas state
                ctx.save();
                
                // Set composite operation to draw sections behind everything
                ctx.globalCompositeOperation = 'destination-over';
                
                // Create set of visible node IDs from current replay state
                const visibleNodeIds = new Set(
                    replayData.nodes.map(n => n.id)
                );   
            
                // Draw the chain sections
                drawChainSections(graphData.nodes, chainIdToName, ctx, visibleNodeIds);
                
                // Restore the canvas state
                ctx.restore();
            }

            // draw off chain nodes
            if (node.is_offchain) {
                // Save the current canvas state
                ctx.save();
                
                // Set composite operation to draw sections behind everything
                ctx.globalCompositeOperation = 'destination-over';
                
                // Determine node type and connected chains
                const nodeType = node.type || 'BRIDGE'; // Default to BRIDGE if not specified
                const connectedChains = node.connected_chains || [];
                
                // Create config for off-chain node
                const config: OffChainNodeConfig = {
                    x: node.x,
                    y: node.y,
                    size: node.__baseSize || 8,
                    type: nodeType as 'DON' | 'BRIDGE' | 'ORACLE',
                    name: node.name || 'Off-chain Node',
                    chainLinks: connectedChains,
                    status: node.status || 'active'
                };
                
                // Draw the off-chain node
                drawOffChainNode(ctx, config, globalScale);

                // Create set of visible node IDs from current replay state
                const visibleNodeIds = new Set(
                    replayData.nodes.map(n => n.id)
                );   

                // Draw the chain sections
                drawChainSections(graphData.nodes, chainIdToName, ctx, visibleNodeIds);

                // Restore the canvas state
                ctx.restore();
            }

            // Keep node within its chain bounds
            if (node.chain_id) {
                const chainBounds = chainGroups.get(node.chain_id);
                if (chainBounds) {
                    const margin = 40;
                    const nodeX = node.x ?? 0;
                    const nodeY = node.y ?? 0;
                    
                    node.x = Math.max(chainBounds.x + margin, 
                        Math.min(chainBounds.x + chainBounds.width - margin, nodeX));
                    node.y = Math.max(chainBounds.y + TITLE_HEIGHT + margin, 
                        Math.min(chainBounds.y + chainBounds.height - margin, nodeY));
                }
            }

            // Draw the node
            drawNode(node, ctx, globalScale, images);
        };
        
        existingRender(node, ctx, globalScale);

        if (!node.hidden) {
            if (node.isNew) {
                const nodeSize = (node.__baseSize || 8) * 2;
                // Ensure state is a valid TransactionState
                let state = (node.state || 'confirmed') as TransactionState;
                // Type check to ensure state is valid
                if (!Object.keys(STATE_CONFIGS).includes(state)) {
                    console.warn(`Invalid state: ${state}, falling back to confirmed`);
                    state = 'confirmed' as TransactionState;
                }
                const config = STATE_CONFIGS[state];
                const time = Date.now() / 1000;

                // Outer glow
                const gradient = ctx.createRadialGradient(
                    node.x, node.y, nodeSize * 0.5,
                    node.x, node.y, nodeSize * 2
                );
                gradient.addColorStop(0, config.colors.secondary);
                gradient.addColorStop(1, `rgba(0, 0, 0, 0)`);
                
                ctx.beginPath();
                ctx.fillStyle = gradient;
                ctx.arc(node.x, node.y, nodeSize * 2, 0, 2 * Math.PI);
                ctx.fill();

                // Rotating/pulsing effect
                if (config.node.rotationSpeed > 0) {
                    ctx.beginPath();
                    ctx.arc(node.x, node.y, nodeSize, 0, 2 * Math.PI);
                    ctx.strokeStyle = config.colors.primary;
                    ctx.lineWidth = 2;
                    ctx.setLineDash(config.node.dashPattern);
                    ctx.lineDashOffset = time * config.node.rotationSpeed * config.node.rotationDirection;
                    ctx.stroke();
                    ctx.setLineDash([]);
                }

                // Pulse effect
                const [min, max] = config.node.pulseRange;
                const pulseSize = Math.sin(time * config.node.pulseSpeed) * ((max - min) / 2) + ((max + min) / 2);
                ctx.beginPath();
                ctx.arc(node.x, node.y, nodeSize * pulseSize, 0, 2 * Math.PI);
                ctx.strokeStyle = config.colors.primary;
                ctx.lineWidth = 3;
                ctx.stroke();
            }

            if (!node.hidden && node.isNew && replaySettings.showStateLabels) {
                const state = (node.state || 'confirmed') as TransactionState;
                const config = STATE_CONFIGS[state];
                
                // Draw state label
                ctx.font = 'bold 12px Arial';
                ctx.fillStyle = config.colors.primary;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                ctx.fillText(state.toUpperCase(), node.x, node.y - (node.__baseSize || 8) * 2 - 5);
            }
        }
    }, [graphData.nodes, chainGroups, chainIdToName, images, replayData.nodes, replaySettings.showStateLabels]);

    // Initialize replay data
    useEffect(() => {
        if (graphData.nodes && graphData.links) {
            if (replaySettings.deriveStates) {
                // Helper to create derived timestamps
                const createDerivedTimestamps = (timestamp: string) => {
                    const confirmedTime = new Date(timestamp).getTime();
                    return {
                        mempool: new Date(confirmedTime - 2000).toISOString(), // 2 seconds before
                        pending: new Date(confirmedTime - 1000).toISOString(), // 1 second before
                        confirmed: timestamp
                     };
                };
    
                // For nodes, create entries for each state
                const nodeEntries = graphData.nodes.flatMap((node: any) => {
                    // Get all timestamps for this node
                    const timestamps = (node.block_timestamps && Array.isArray(node.block_timestamps)) ? 
                        node.block_timestamps : 
                        [node.first_seen];
    
                    return timestamps
                        .filter((timestamp: any): timestamp is string => Boolean(timestamp))
                        .flatMap((timestamp: any) => {
                            const derivedTimes = createDerivedTimestamps(timestamp);
                            
                            // Create entries for each state
                            return [
                                {
                                    ...node,
                                    timestamp: node.first_seen || derivedTimes.mempool,
                                    type: 'node',
                                    state: 'mempool' as TransactionState,
                                    block_timestamps: node.block_timestamps
                                },
                                {
                                    ...node,
                                    timestamp: derivedTimes.pending,
                                    type: 'node',
                                    state: 'pending' as TransactionState,
                                    block_timestamps: node.block_timestamps
                                },
                                {
                                    ...node,
                                    timestamp: derivedTimes.confirmed,
                                    type: 'node',
                                    state: 'confirmed' as TransactionState,
                                    block_timestamps: node.block_timestamps
                                }
                            ];
                        });
                });
    
                // For links, create entries for each state
                const linkEntries = graphData.links
                    .filter((link: any) => Boolean(link.block_timestamp))
                    .flatMap((link: any) => {
                        const derivedTimes = createDerivedTimestamps(link.block_timestamp);
                        
                        // Create entries for each state
                        return [
                            {
                                ...link,
                                timestamp: link.first_seen || derivedTimes.mempool,
                                type: 'link',
                                state: 'mempool' as TransactionState
                            },
                            {
                                ...link,
                                timestamp: derivedTimes.pending,
                                type: 'link',
                                state: 'pending' as TransactionState
                            },
                            {
                                ...link,
                                timestamp: derivedTimes.confirmed,
                                type: 'link',
                                state: 'confirmed' as TransactionState
                            }
                        ];
                    });
    
                // Combine and sort all items
                const allItems = [...nodeEntries, ...linkEntries].sort((a, b) => {
                    if (!a.timestamp) return -1;
                    if (!b.timestamp) return 1;
                    const timeA = new Date(a.timestamp).getTime();
                    const timeB = new Date(b.timestamp).getTime();
                    // If timestamps are equal, sort by state priority
                    if (timeA === timeB) {
                        const statePriority = {
                            mempool: 0,
                            pending: 1,
                            confirmed: 2
                        };
                        return statePriority[a.state as keyof typeof statePriority] - statePriority[b.state as keyof typeof statePriority];
                    }
                    return timeA - timeB;
                });
    
                setReplayData({
                    nodes: [],
                    links: [],
                    currentStep: 0,
                    sortedItems: allItems.filter(item => Boolean(item.timestamp))
                });
            } else {
                let allItems;

                // Simple version without derived states
                const nodeEntries = graphData.nodes.flatMap((node: any) => {
                    const timestamps = (node.block_timestamps && Array.isArray(node.block_timestamps)) ? 
                        node.block_timestamps : 
                        [node.first_seen];

                    return timestamps
                        .filter((timestamp: any): timestamp is string => Boolean(timestamp))
                        .map((timestamp: any) => ({
                            ...node,
                            timestamp,
                            type: 'node',
                            state: 'confirmed' as TransactionState,
                            block_timestamps: node.block_timestamps
                        }));
                });

                const linkEntries = graphData.links
                    .filter((link: any) => Boolean(link.block_timestamp))
                    .map((link: any) => ({
                        ...link,
                        timestamp: link.block_timestamp,
                        type: 'link',
                        state: 'confirmed' as TransactionState
                    }));

                allItems = [...nodeEntries, ...linkEntries].sort((a, b) => {
                    if (!a.timestamp) return -1;
                    if (!b.timestamp) return 1;
                    return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
                });
                setReplayData({
                    nodes: [],
                    links: [],
                    currentStep: 0,
                    sortedItems: allItems?.filter(item => Boolean(item.timestamp)) || []
                });                
            }
        }
    }, [graphData, replaySettings.deriveStates]);

    const handleReplayStep = useCallback((step: number) => {
        const { sortedItems } = replayData;
        
        if (step < 0 || step > sortedItems.length) {
            console.warn('Invalid step:', step);
            return;
        }

        if (replaySettings.stepMode === 'timestamp') {
            // Group items by timestamp
            const itemsByTimestamp = sortedItems.reduce((acc, item) => {
                const timestamp = item.timestamp;
                if (!acc[timestamp]) {
                    acc[timestamp] = [];
                }
                acc[timestamp].push(item);
                return acc;
            }, {} as Record<string, any[]>);

            // Get unique timestamps
            const timestamps = Object.keys(itemsByTimestamp).sort();
            
            // Find current timestamp index
            const currentTimestampIndex = Math.min(step - 1, timestamps.length - 1);
            
            // Get all items up to and including current timestamp
            const itemsToShow = timestamps
                .slice(0, currentTimestampIndex + 1)
                .flatMap(timestamp => itemsByTimestamp[timestamp]);

            // Process items
            const nodeMap = new Map();
            itemsToShow
                .filter(item => item.type === 'node')
                .forEach((node: any) => {
                    const originalNode = graphData.nodes.find((n: any) => n.id === node.id);
                    nodeMap.set(node.id, {
                        ...node,
                        x: originalNode?.x ?? node.x,
                        y: originalNode?.y ?? node.y,
                        isNew: timestamps[currentTimestampIndex] === node.timestamp
                    });
                });

            const linkMap = new Map();
            itemsToShow
                .filter(item => item.type === 'link')
                .forEach(link => {
                    linkMap.set(link.id, {
                        ...link,
                        isNew: timestamps[currentTimestampIndex] === link.timestamp
                    });
                });

            setReplayData(prev => ({
                ...prev,
                nodes: Array.from(nodeMap.values()),
                links: Array.from(linkMap.values()),
                currentStep: step
            }));
        } else {
            // Existing individual step logic
            const itemsToShow = sortedItems.slice(0, step);
            
            // Accumulate nodes (keeping the latest version of each node)
            const nodeMap = new Map();
            itemsToShow
                .filter(item => item.type === 'node')
                .forEach(node => {
                    // Preserve original coordinates from graphData
                    const originalNode = graphData.nodes.find((n: any) => n.id === node.id);
                    nodeMap.set(node.id, {
                        ...node,
                        // Keep original x,y coordinates
                        x: originalNode?.x ?? node.x,
                        y: originalNode?.y ?? node.y,
                        isNew: itemsToShow.indexOf(node) === step - 1
                    });
                });
            const newNodes = Array.from(nodeMap.values());

            // Accumulate links (keeping the latest version of each link)
            const linkMap = new Map();
            itemsToShow
                .filter(item => item.type === 'link')
                .forEach(link => {
                    linkMap.set(link.id, {
                        ...link,
                        isNew: itemsToShow.indexOf(link) === step - 1 // Mark as new if it's the latest item
                    });
                });
            const newLinks = Array.from(linkMap.values());

            setReplayData(prev => ({
                ...prev,
                nodes: newNodes,
                links: newLinks,
                currentStep: step
            }));
        }
    }, [replayData.sortedItems, graphData.nodes, replaySettings.stepMode]);

    const handleStepForward = useCallback(() => {
        if (replayData.currentStep < replayData.sortedItems.length) {
            handleReplayStep(replayData.currentStep + 1);
        }
    }, [replayData, handleReplayStep]);

    const handleStepBack = useCallback(() => {
        if (replayData.currentStep > 0) {
            handleReplayStep(replayData.currentStep - 1);
        }
    }, [replayData, handleReplayStep]);

    const handleReplayReset = useCallback(() => {
        handleReplayStep(0);
    }, [handleReplayStep]);

    // Handle replay animation
    useEffect(() => {
        if (isReplaying) {
            const playNextStep = () => {
                const nextStep = replayData.currentStep + 1;
                
                // Handle loop or completion
                if (nextStep > replayData.sortedItems.length) {
                    if (replaySettings.loop) {
                        handleReplayStep(0);
                    } else {
                        setIsReplaying(false);
                    }
                    return;
                }

                handleReplayStep(nextStep);
            };

            // Schedule next step based on speed setting
            const delay = 1000 / replaySettings.speed;
            replayTimeoutRef.current = setTimeout(playNextStep, delay);

            return () => {
                if (replayTimeoutRef.current) {
                    clearTimeout(replayTimeoutRef.current);
                }
            };
        }
    }, [isReplaying, replaySettings.speed, replaySettings.loop, replayData.currentStep, replayData.sortedItems.length, handleReplayStep]);

    // Handle replay toggle
    const handleReplayComplete = useCallback((playing: boolean) => {
        setIsReplaying(playing);
        if (!playing && replayTimeoutRef.current) {
            clearTimeout(replayTimeoutRef.current);
        }
        // If starting replay and at end, start from beginning
        if (playing && replayData.currentStep >= replayData.sortedItems.length) {
            handleReplayStep(0);
        }
    }, [replayData.currentStep, replayData.sortedItems.length]);

    // Handle settings update from TransactionReplay
    const handleSettingsUpdate = useCallback((newSettings: ReplaySettings) => {
        setReplaySettings(newSettings);
    }, []);

    // Create effective graph data that preserves chain groups
    const effectiveGraphData = useMemo(() => {
        if (replayData.currentStep === 0 || replayData.currentStep === replayData.sortedItems.length) {
            return graphData;
        }
        
        const currentNodes = replayData.nodes.map(node => ({
            ...node,
            // Preserve the original block_timestamps array
            block_timestamps: node.block_timestamps || [node.timestamp]
        }));

        return {
            nodes: currentNodes,
            links: replayData.links
        };
    }, [graphData, replayData]);

    // Format timestamp for display
    const formatTimestamp = useCallback((timestamp: string | undefined): string => {
        if (!timestamp) return 'Start';
        try {
            return new Date(timestamp).toLocaleString();
        } catch (e) {
            return 'Invalid Date';
        }
    }, []);

    // Calculate effective steps and timestamps based on step mode
    const replayInfo = useMemo(() => {
        const { sortedItems } = replayData;
        
        if (replaySettings.stepMode === 'timestamp') {
            // Get unique timestamps
            const timestamps = Array.from(new Set(
                sortedItems
                    .filter(item => item.timestamp)
                    .map(item => item.timestamp)
            )).sort();

            const currentTimestampIndex = Math.max(0, replayData.currentStep - 1);
            
            return {
                totalSteps: timestamps.length,
                currentStep: Math.min(replayData.currentStep, timestamps.length),
                currentTimestamp: timestamps[currentTimestampIndex],
                nextTimestamp: timestamps[currentTimestampIndex + 1]
            };
        }

        // Individual mode
        return {
            totalSteps: sortedItems.length,
            currentStep: replayData.currentStep,
            currentTimestamp: sortedItems[replayData.currentStep - 1]?.timestamp,
            nextTimestamp: sortedItems[replayData.currentStep]?.timestamp
        };
    }, [replayData, replaySettings.stepMode]);

    const calculateLinkCurvature = (link: any, graphData: any, settings: any) => {
        if (!settings.links?.curved) {
            return 0; // No curve if curved setting is disabled
        }
    
        // Get all links between these two nodes in both directions
        const parallelLinks = graphData.links.filter(
            (l: any) => 
                (l.source.id === link.source.id && l.target.id === link.target.id) ||
                (l.source.id === link.target.id && l.target.id === link.source.id)
        );
        
        if (parallelLinks.length === 0) {
            return 0; // Safety check
        }
    
        // Determine if this is a forward or reverse link
        const isForwardLink = link.source.id === parallelLinks[0].source.id;
        
        // Get index of current link among parallel links in its direction
        const directionLinks = parallelLinks.filter(
            (l: any) => 
                (isForwardLink && l.source.id === link.source.id) ||
                (!isForwardLink && l.source.id === link.target.id)
        );
        const linkIndex = directionLinks.indexOf(link);
        
        // Base curvature - positive for forward, negative for reverse
        const baseCurve = isForwardLink ? 0.2 : -0.2;
        
        // Adjust curvature based on number of parallel links
        const multiplier = parallelLinks.length > 1 ? 
            (linkIndex / Math.max(1, directionLinks.length - 1)) : 
            0;
        
        return baseCurve * (1 + multiplier);
    };

    const handleFullScreen = useCallback(() => {
        const graphContainer = document.getElementById('graph-container');
        
        if (!document.fullscreenElement) {
            // Enter full screen
            if (graphContainer?.requestFullscreen) {
                graphContainer.requestFullscreen();
                setIsFullScreen(true);
            }
        } else {
            // Exit full screen
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullScreen(false);
            }
        }
    }, []);

    // Add fullscreen change event listener
    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <div 
                id="graph-container"
                style={{ 
                    width: '100%', 
                    height: isFullScreen ? '100vh' : '600px',
                    border: '1px solid #f0f0f0',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    backgroundColor: settings.visual?.darkMode ? '#1f1f1f' : '#fff',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Space
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 1000
                    }}
                >
                    <Tooltip title={isFullScreen ? "Exit Full Screen" : "Full Screen"}>
                        <Button
                            icon={isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                            onClick={handleFullScreen}
                            type="text"
                            style={{
                                color: settings.visual?.darkMode ? '#ffffff' : '#000000'
                            }}
                        />
                    </Tooltip>

                    {options?.showLive && 
                        <LiveIndicator subscriptionStatus={subscriptionStatus as unknown as SubscriptionStatus} />
                    }   
                    {options?.showReplay && 
                        <TransactionReplay 
                            transactions={replayData.sortedItems}
                            onReplayStep={handleReplayStep as any}
                            onReset={handleReplayReset}
                            onStepForward={handleStepForward}
                            onStepBack={handleStepBack}
                            onReplayComplete={handleReplayComplete}
                            isReplaying={isReplaying}
                            currentStep={replayInfo.currentStep}
                            totalSteps={replayInfo.totalSteps}
                            currentTimestamp={replayInfo.currentTimestamp}
                            nextTimestamp={replayInfo.nextTimestamp}
                            settings={replaySettings}
                            onSettingsChange={handleSettingsUpdate}
                        />
                    }

                    {options?.showShare && 
                        <Tooltip title="Share Graph">
                            <Button
                                icon={<ShareAltOutlined />}
                                onClick={() => setShareModalVisible(true)}
                                type="primary"
                                ghost
                            />
                        </Tooltip>
                    }

                    {options?.showSettings && 
                        <Tooltip title="Graph Settings">
                            <Dropdown 
                                overlay={
                                    <GraphSettings 
                                        nodesPerRow={nodesPerRow}
                                        verticalSpacing={verticalSpacing}
                                        onNodesPerRowChange={handleNodesPerRowChange}
                                        onVerticalSpacingChange={handleVerticalSpacingChange}
                                        settings={settings}
                                        onSettingChange={handleSettingChange}
                                    />
                                }
                                trigger={['click']}
                                placement="bottomRight"
                            >
                                <Button 
                                    icon={<SettingOutlined />} 
                                    type="text"
                                    style={{
                                        color: settings.visual?.darkMode ? '#ffffff' : '#000000'
                                    }}
                                />
                            </Dropdown>
                        </Tooltip>
                    }

                    {Array.from(new Set(graphData.nodes
                        .filter((n: any) => n.chain_id)
                        .map((n: any) => n.chain_id)))
                        .map((chainId: any) => (
                            <Button
                                key={chainId}
                                icon={<AimOutlined />}
                                onClick={() => handleGraphLayout(chainId)}
                            >
                                {chainIdToName[chainId] || `${chainId}`}
                            </Button>
                        ))}
                    
                    <Button
                        icon={<AimOutlined />}
                        type="primary"
                        onClick={() => handleGraphLayout(undefined, true)}
                    >
                        Center on All Nodes
                    </Button>
                </Space>

                <div style={{ flex: 1 }}>
                    <ForceGraph2D
                        key={graphKey}
                        ref={fgRef}
                        graphData={effectiveGraphData}
                        nodeLabel={node => formatNodeLabel(node)}
                        linkLabel={link => formatLinkLabel(link)}
                        nodeAutoColorBy="chain_id"
                        linkDirectionalParticles={20} // when setting it via the config it doesnt work
                        linkDirectionalParticleSpeed={d => {
                            if (!d.isNew) return d.particleSpeed || 0.001;
                            const state = (d.state || 'confirmed') as TransactionState;
                            return STATE_CONFIGS[state].link.particleSpeed || 0.001;
                        }}
                        linkDirectionalParticleWidth={link => {
                            if (!link.isNew) return link.particleWidth || 2;
                            const state = (link.state || 'confirmed') as TransactionState;
                            return STATE_CONFIGS[state].link.particleWidth;
                        }}
                        linkLineDash={link => {
                            if (!link.isNew) return link.dashPattern || null;
                            const state = (link.state || 'confirmed') as TransactionState;
                            return STATE_CONFIGS[state].link.dashPattern || null;
                        }}
                        linkColor={link => {
                            if (!link.isNew) return link.linkColor || '#999';
                            const state = (link.state || 'confirmed') as TransactionState;
                            return STATE_CONFIGS[state].colors.primary;
                        }}
                        linkWidth={link => {
                            const baseWidth = settings.links?.width || 2;
                            if (!link.isNew) return baseWidth;
                            const state = (link.state || 'confirmed') as TransactionState;
                            return baseWidth * STATE_CONFIGS[state].link.width;
                        }}
                        backgroundColor={settings.visual?.darkMode ? '#1f1f1f' : 'white'}
                        nodeCanvasObjectMode={() => "after"}
                        nodeCanvasObject={nodeCanvasObjectHandler}
                        onNodeDrag={handleNodeDrag}
                        onNodeDragEnd={handleNodeDragEnd}
                        nodePointerAreaPaint={handleNodePointerArea}
                        onNodeClick={(node) => {
                            setSelectedLink(null);
                            setSelectedNode(node as GraphNode);
                        }}
                        onLinkClick={(link) => {
                            setSelectedNode(null);
                            setSelectedLink(link as GraphLink);
                        }}
                        d3AlphaDecay={0.1}
                        d3VelocityDecay={1 - (settings.physics?.strength || 0.5)}
                        nodeRelSize={settings.nodes?.size || 8}
                        enableNodeDrag={settings.physics.enabled}
                        warmupTicks={0}
                        cooldownTicks={0}
                        enableZoomInteraction={true}
                        enablePanInteraction={true}
                        linkDirectionalArrowLength={settings.links?.showArrows ? 16 : 0}
                        linkDirectionalArrowRelPos={0.5}
                        linkDirectionalArrowColor={link => {
                            if (!link.isNew) return link.linkColor || '#999';
                            const state = (link.state || 'confirmed') as TransactionState;
                            return STATE_CONFIGS[state].colors.primary;
                        }}
                        linkCurvature={(link) => calculateLinkCurvature(link, graphData, settings)}
                                
                    />
                </div>

                {settings.visual?.showTimeRange && (
                    <div style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: 'white',
                        padding: '16px 24px',
                        boxShadow: '0 -2px 8px rgba(0,0,0,0.15)',
                        zIndex: 1000,
                        transition: 'transform 0.3s ease',
                        transform: 'translateY(0)',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        {/* Close button */}
                        <div style={{ 
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            zIndex: 1
                        }}>
                            <Button
                                type="text"
                                icon={<CloseOutlined />}
                                onClick={() => {
                                    handleSettingChange('visual', { 
                                        ...settings.visual, 
                                        showTimeRange: false 
                                    });
                                }}
                                size="small"
                            />
                        </div>

                        {options?.showTimeRange && 
                            <TimeRangeSlider 
                                graphData={graphData}
                                fullData={fullGraphData}
                                onTimeRangeChange={handleTimeRangeChange}
                            />
                        }
                    </div>
                )}

                {/* Add margin below to ensure visibility */}
                <div style={{ height: '20px' }} />

                {selectedNode && (
                    <NodeStats 
                        node={selectedNode} 
                        chainIdToName={chainIdToName}
                        cdn_domain_name={cdn_domain_name}
                        onClose={() => setSelectedNode(null)}
                        address_items_map={address_items_map}
                    />
                )}
                {selectedLink && (
                    <LinkStats 
                        link={selectedLink}
                        chainIdToName={chainIdToName}
                        onClose={() => setSelectedLink(null)}
                        address_items_map={address_items_map}
                    />
                )}
                {selectedEntity && (
                    <EntityStats 
                        entity={selectedEntity}
                        chainIdToName={chainIdToName}
                        cdn_domain_name={cdn_domain_name}
                        onClose={() => setSelectedEntity(null)}
                    />
                )}
                {selectedChain && (
                    <ChainStats 
                        chainId={selectedChain.id}
                        chainName={selectedChain.name}
                        chainData={selectedChain.data}
                        onClose={() => setSelectedChain(null)}
                    />
                )}
            </div>

            <ShareGraphModal
                open={shareModalVisible}
                onClose={() => setShareModalVisible(false)}
                onFinish={handleShare}
                loading={false}
            />

            <StateLegend 
                replaySettings={replaySettings}
                STATE_CONFIGS={STATE_CONFIGS}
            />
        </div>
    );
};

import React, { Key } from 'react';
import { Space, Typography, Tag, Tooltip } from 'antd';
import type { TableColumnType } from 'antd';
import type { Transaction } from '../types';
import { Link } from 'react-router-dom';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { AddressFilterDropdown } from '../components/AddressFilterDropdown';

interface GetHashColumnProps {
    setJsonModalData: (data: any) => void;
    data: Transaction[];
}

export const getHashColumn = ({
    setJsonModalData,
    data,
}: GetHashColumnProps & { data: Transaction[] }): TableColumnType<Transaction> => ({
    title: 'Hash',
    dataIndex: 'hash',
    key: 'hash',
    width: 200,
    filters: data
        .filter(item => item?.hash)
        .map(item => ({
            text: `${item.hash.slice(0, 10)}...${item.hash.slice(-10)}`,
            value: item.hash
        }))
        .filter((filter, index, self) => 
            index === self.findIndex(f => f.value === filter.value)
        ),
    filterDropdown: (props: FilterDropdownProps) => (
        <AddressFilterDropdown
            {...props}
            transactions={data}
            addressField="hash"
            labelField="hash"  // Use hash for both since we don't have labels
            entityField="hash" // Use hash for both since we don't have entities
            logoField="hash"   // No logos for hashes
            formatDisplayText={(hash: string) => `${hash.slice(0, 10)}...${hash.slice(-10)}`}
            searchFullText={true} // New prop to enable full text search
        />
    ),
    onFilter: (value: boolean | Key, record: Transaction) => 
        record.hash === String(value),
    render: (hash: string, record: Transaction) => {
        if (!hash) return null;
        
        const getTransactionLink = (hash: string) => {
            if (record?.["user_id-block_timestamp-chain_id-hash-type"]) {
                return `/transactions/show/${record["user_id-block_timestamp-chain_id-hash-type"]}`;
            }
            return `/transactions/show/${record.chain_id}_${hash}`;
        }

        return (
            <Space direction="vertical" size={0}>
                <Space>
                    <Link to={getTransactionLink(hash)}>
                        <Tooltip title={hash}>
                            <Typography.Text 
                                copyable={{ text: hash }} 
                                style={{ whiteSpace: 'nowrap' }}
                                data-full-address={hash}
                            >
                                {`${hash.slice(0, 10)}...${hash.slice(-10)}`}
                            </Typography.Text>
                        </Tooltip>
                    </Link>
                    {record.possible_spam === true && (
                        <Tooltip title="This transaction has been flagged as possible spam">
                            <Tag color="red">SPAM</Tag>
                        </Tooltip>
                    )}
                </Space>
            </Space>
        );
    },
});
import React, { useState } from 'react';
import { Card, Typography, Tooltip } from 'antd';
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { StateConfig } from '../TransactionGraph';
import { TransactionState } from '../TransactionGraph';
import { ReplaySettings } from './TransactionReplay';

interface StateLegendProps {
    replaySettings: ReplaySettings;
    STATE_CONFIGS: Record<TransactionState, StateConfig>;
}

export const StateLegend: React.FC<StateLegendProps> = ({
    replaySettings,
    STATE_CONFIGS
}) => {
    const [collapsed, setCollapsed] = useState(false);

    if (!replaySettings.showStateLabels) return null;

    return (
        <Card
            size="small"
            style={{
                position: 'absolute',
                bottom: 20,
                right: 20,
                background: 'rgba(255, 255, 255, 0.95)',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                width: 'auto',
                minWidth: '180px',
                cursor: 'default'
            }}
            title={
                <div 
                    style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        cursor: 'pointer' 
                    }}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    {collapsed ? <CaretRightOutlined/> : <CaretDownOutlined />}
                    <Typography.Text strong style={{ marginLeft: 8 }}>
                        Transaction States
                    </Typography.Text>
                </div>
            }
            bodyStyle={{ 
                padding: collapsed ? 0 : '8px 12px',
                display: collapsed ? 'none' : 'block'
            }}
        >
            {Object.entries(STATE_CONFIGS).map(([state, config]) => (
                <Tooltip 
                    key={state} 
                    title={`${state.charAt(0).toUpperCase() + state.slice(1)} transactions`}
                    placement="left"
                >
                    <div style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        padding: '4px 0',
                        transition: 'background-color 0.3s',
                        borderRadius: '4px',
                    }}>
                        <div style={{
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            backgroundColor: config.colors.primary,
                            marginRight: 8,
                            boxShadow: `0 0 4px ${config.colors.primary}`
                        }} />
                        <Typography.Text style={{ 
                            textTransform: 'capitalize',
                            fontSize: '13px'
                        }}>
                            {state}
                        </Typography.Text>
                    </div>
                </Tooltip>
            ))}
        </Card>
    );
}; 
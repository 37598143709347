import React, { useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { 
    Form, Input, Select, Space, Button, Card, 
    Alert, Steps, Row, Col, Typography, Divider,
    Tabs, Upload as AntdUpload, message 
} from "antd";
import { 
    MinusCircleOutlined, PlusOutlined, 
    CloudServerOutlined, ApiOutlined, CodeOutlined,
    SettingOutlined, UploadOutlined, FileTextOutlined,
    PictureOutlined
} from '@ant-design/icons';
import { IIntegration } from "interfaces";
import { isAddress } from 'viem';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile } from 'antd/es/upload';

const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

export const IntegrationCreate: React.FC<IResourceComponentsProps> = () => {
    const [sourceCodeType, setSourceCodeType] = useState<'inline' | 'file'>('inline');
    const [avatarFile, setAvatarFile] = useState<UploadFile | null>(null);
    const [sourceFile, setSourceFile] = useState<UploadFile | null>(null);

    const { 
        formLoading, 
        formProps, 
        saveButtonProps, 
        mutationResult 
    } = useForm<IIntegration>({
        resource: "integrations",
        action: "create",
        redirect: false
    });

    const beforeUpload = (file: RcFile, acceptedTypes: string[]) => {
        const isAccepted = acceptedTypes.includes(file.type);
        if (!isAccepted) {
            message.error(`You can only upload ${acceptedTypes.join(', ')} files!`);
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('File must be smaller than 2MB!');
        }
        return isAccepted && isLt2M;
    };

    return (
        <Create 
            isLoading={formLoading} 
            saveButtonProps={saveButtonProps}
            title={
                <Space>
                    <SettingOutlined />
                    <span>Create New Integration</span>
                </Space>
            }
        >
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                {mutationResult?.data?.data.follow_build_here && (
                    <Alert
                        message="Integration Created Successfully"
                        description={
                            <span>
                                Follow the build progress here:{' '}
                                <a href={mutationResult?.data?.data?.follow_build_here} target="_blank" rel="noopener noreferrer">
                                    View Build Status
                                </a>
                            </span>
                        }
                        type="success"
                        showIcon
                    />
                )}

                <Form {...formProps} layout="vertical">
                    <Row gutter={16}>
                        {/* Basic Information */}
                        <Col span={12}>
                            <Card title={
                                <Space>
                                    <CloudServerOutlined />
                                    <span>Basic Information</span>
                                </Space>
                            }>
                                <Form.Item
                                    label="Avatar"
                                    name="avatar"
                                    rules={[{ required: true, message: 'Please upload an avatar' }]}
                                >
                                    <AntdUpload
                                        listType="picture-card"
                                        maxCount={1}
                                        beforeUpload={(file) => beforeUpload(file, ['image/jpeg', 'image/png'])}
                                        onChange={({ file }) => setAvatarFile(file)}
                                    >
                                        {!avatarFile && (
                                            <div>
                                                <PictureOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>
                                        )}
                                    </AntdUpload>
                                </Form.Item>

                                <Form.Item
                                    label="Environment"
                                    name="environment"
                                    initialValue={process.env.REACT_APP_ENV === "dev" ? "sandbox" : process.env.REACT_APP_ENV}
                                    rules={[{ required: true }]}
                                >
                                    <Input disabled />
                                </Form.Item>

                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder="Integration name" />
                                </Form.Item>

                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[{ required: true }]}
                                >
                                    <Input.TextArea 
                                        placeholder="Describe what this integration does"
                                        rows={4}
                                    />
                                </Form.Item>
                            </Card>
                        </Col>

                        {/* Configuration */}
                        <Col span={12}>
                            <Card title={
                                <Space>
                                    <SettingOutlined />
                                    <span>Configuration</span>
                                </Space>
                            }>
                                <Form.Item
                                    label="Region"
                                    name="region"
                                    initialValue="us-west-2"
                                    rules={[{ required: true }]}
                                >
                                    <Select placeholder="Select region">
                                        <Option value="us-west-2">US (us-west-2/us-east-1)</Option>
                                        <Option value="eu-central-1">EU (eu-central-1/eu-west-1)</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Integration Type"
                                    name="integration_type"
                                    initialValue="whitelisted"
                                    rules={[{ required: true }]}
                                >
                                    <Select>
                                        <Option value="whitelisted">Whitelisted</Option>
                                        <Option value="custom">Custom</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Pipeline Stages"
                                    name="integration_stages"
                                    initialValue={["pre-process"]}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Select stages"
                                    >
                                        <Option value="pre-process">Pre-process</Option>
                                        <Option value="process">Process</Option>
                                        <Option value="post-process">Post-process</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Source Code Extension"
                                    name="source_file_extension"
                                    initialValue="py"
                                    rules={[{ required: true }]}
                                >
                                    <Select>
                                        <Option value="py">Python (.py)</Option>
                                        <Option value="other">Other</Option>
                                    </Select>
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>

                    {/* Source Code Section */}
                    <Card 
                        title={
                            <Space>
                                <CodeOutlined />
                                <span>Source Code</span>
                            </Space>
                        }
                        style={{ marginTop: 16 }}
                    >
                        <Form.Item
                            label="Source Code Input Method"
                            name="source_code_type"
                        >
                            <Select
                                value={sourceCodeType}
                                onChange={setSourceCodeType}
                                style={{ width: 200 }}
                            >
                                <Option value="inline">Write Inline</Option>
                                <Option value="file">Upload File</Option>
                            </Select>
                        </Form.Item>

                        {sourceCodeType === 'inline' ? (
                            <Form.Item
                                label="Source Code"
                                name="source_code"
                                rules={[{ required: true, message: 'Please enter the source code' }]}
                            >
                                <TextArea 
                                    rows={15}
                                    placeholder="Enter your Python code here..."
                                    style={{ 
                                        fontFamily: 'monospace',
                                        fontSize: '14px'
                                    }}
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                label="Source File"
                                name="source_file"
                                rules={[{ required: true, message: 'Please upload a source file' }]}
                            >
                                <AntdUpload
                                    maxCount={1}
                                    beforeUpload={(file) => beforeUpload(file, ['text/x-python', 'text/plain'])}
                                    onChange={({ file }) => setSourceFile(file)}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        Click to Upload Python File
                                    </Button>
                                </AntdUpload>
                            </Form.Item>
                        )}

                        <Alert
                            message="Source Code Guidelines"
                            description={
                                <ul style={{ paddingLeft: 20, margin: 0 }}>
                                    <li>File must be a single Python file</li>
                                    <li>Maximum file size: 2MB</li>
                                    <li>Must include a lambda_handler function</li>
                                    <li>Follow the example structure shown in the documentation</li>
                                </ul>
                            }
                            type="info"
                            showIcon
                        />
                    </Card>

                    {/* Triggers Section */}
                    <Card 
                        title={
                            <Space>
                                <ApiOutlined />
                                <span>Blockchain Triggers</span>
                            </Space>
                        }
                        style={{ marginTop: 16 }}
                    >
                        <Form.List
                            name="trigger_on_chain_id_addresses"
                            initialValue={[""]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            label={index === 0 ? 'Chain ID and Address' : ''}
                                            required
                                            key={field.key}
                                        >
                                            <Space>
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please enter chain_id and address",
                                                        },
                                                        {
                                                            validator: async (_, value) => {
                                                                if (!value) return;
                                                                if (value.includes("-")) {
                                                                    throw new Error('Cannot contain "-"');
                                                                }
                                                                if (!value.includes("_")) {
                                                                    throw new Error('Must contain "_"');
                                                                }
                                                                const address = value.split("_")[1];
                                                                if (!isAddress(address)) {
                                                                    throw new Error(`Invalid address: ${address}`);
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input 
                                                        placeholder="0x1_0x78Cf1D91C94667a4a6615829e394C9CCe58fEc9E" 
                                                        style={{ width: '400px' }} 
                                                    />
                                                </Form.Item>
                                                {fields.length > 1 && (
                                                    <Button 
                                                        type="text"
                                                        danger
                                                        icon={<MinusCircleOutlined />}
                                                        onClick={() => remove(field.name)}
                                                    />
                                                )}
                                            </Space>
                                        </Form.Item>
                                    ))}
                                    
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            icon={<PlusOutlined />}
                                        >
                                            Add Trigger
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Card>

                    <Alert
                        message="Next Steps"
                        description="After creating the integration, you'll be able to configure additional settings and view the build progress."
                        type="info"
                        showIcon
                        style={{ marginTop: 16 }}
                    />
                </Form>
            </Space>
        </Create>
    );
};

import { GraphData, GraphNode, GraphLink, ChainGroup, GraphSettings, TransactionLog, DecodedEvent } from 'interfaces/graph';
import { selectEntityName, selectEntityLogo, selectLabel } from './utils/addressMetadata';
import { generateOffChainElements } from './utils/offChainNodesGenerator';
import { addNodeIfNotExists } from './utils/addNodeIfNotExists';
import { processProxyCall } from './utils/proxyCallProcessor';
import { AddressLocation, extractAddressesFromTransaction } from 'utils/extractAddresses';
import { findParamInEventValue, findParamInDecodedCall } from './utils/utils';

// Add this at the top with other constants
const sampleEntities = [
    'Uniswap V3',
    'Aave V3',
    'Compound V3',
    'Balancer V2',
    'Curve Finance',
    '1inch',
    'SushiSwap',
    'PancakeSwap',
    'dYdX',
    'Maker DAO'
];

// Helper function to create base link properties
const createBaseLink = (tx: any, type: string, from: string, to: string, chainId: string) => {
    return {
        // Base properties all links should have
        id: `${chainId}_${tx.hash}_${type}_${from}_${to}`,
        transaction_id: tx["user_id-block_timestamp-chain_id-hash-type"],
        type: type,
        source: `${chainId}_${from}`,
        target: `${chainId}_${to}`,
        value: '0',
        value_formatted: '0',
        linkColor: getLinkColor(type),
        arraySize: 5,
        curved: 0,
        // Transaction metadata
        chain_id: chainId,
        hash: tx.hash,
        block_number: tx.block_number,
        transaction_hash: tx.hash,
        gas: tx.gas,
        gas_used: tx.gas_used,
        gas_price: tx.gas_price,
        transaction_fee: tx.transaction_fee,
        status: tx.status,
        possible_spam: tx.possible_spam,
        category: tx.category
    };
};

export const processGraphData = (
    data: any[],
    filteredData: any[],
    chainIdToName: Record<string, string>,
    settings: GraphSettings,
    address_items_map: Record<string, any>
): GraphData => {
    const dataToUse = filteredData?.length > 0 ? filteredData : data;
    
    const result: GraphData = {
        nodes: [] as GraphNode[],
        links: [] as GraphLink[],
        chainGroups: []
    };

    if (!dataToUse || dataToUse.length === 0) {
        return result;
    }

    // Group nodes by chain
    const nodesByChain = new Map<string, GraphNode[]>();
    const nodeMap = new Map<string, GraphNode>();
    
    // First pass: collect all possible nodes from all transaction types
    dataToUse.forEach(tx => {
        const originalTx = {
            from_address: tx.from_address,
            from_metadata: tx.from_metadata,
            to_address: tx.to_address,
            to_metadata: tx.to_metadata,
            receipt_contract_address: tx.receipt_contract_address,
            hash: tx.hash,
            from_address_verified: tx.from_address_verified,
            to_address_verified: tx.to_address_verified,
            from_address_tags: tx.from_address_tags,
            to_address_tags: tx.to_address_tags,
            from_address_contract_type: tx.from_address_contract_type,
            to_address_contract_type: tx.to_address_contract_type,
            from_address_security_score: tx.from_address_security_score,
            to_address_security_score: tx.to_address_security_score,
            from_address_is_contract: tx.from_address_is_contract,
            to_address_is_contract: tx.to_address_is_contract,
            from_address_is_token: tx.from_address_is_token,
            to_address_is_token: tx.to_address_is_token,
            from_address_token_symbol: tx.from_address_token_symbol,
            to_address_token_symbol: tx.to_address_token_symbol,
            from_address_token_name: tx.from_address_token_name,
            to_address_token_name: tx.to_address_token_name,
            
        };

        const addNode = (address: string, chainId: string, tx: any): GraphNode => {
            const nodeId = `${chainId}_${address}`;
            if (!nodeMap.has(nodeId)) {
                const isFromAddress = address === originalTx.from_address;
                const isToAddress = address === originalTx.to_address;
                const isReceiptContractAddress = address === originalTx.receipt_contract_address;
                const node: GraphNode = {
                    id: nodeId,
                    address: address,
                    chain_id: chainId,
                    friendly_name: address,
                    outgoing_count: 0,
                    incoming_count: 0,
                    total_value: 0,
                    // Initialize timestamps array with first transaction
                    block_timestamps: tx.block_timestamp ? [tx.block_timestamp] : [],                    
                    // Randomly assign entity for testing
                    // entity: sampleEntities[Math.floor(Math.random() * sampleEntities.length)],   

                    entity: selectEntityName(address_items_map, originalTx, address, chainId),
                    logo: selectEntityLogo(address_items_map, originalTx, address, chainId),
                    label: selectLabel(address_items_map, originalTx, address, chainId),
                    verified: isFromAddress ? originalTx.from_address_verified : 
                             isToAddress ? originalTx.to_address_verified : undefined,
                    tags: isFromAddress ? originalTx.from_address_tags : 
                          isToAddress ? originalTx.to_address_tags : undefined,
                    contract_type: isFromAddress ? originalTx.from_address_contract_type : 
                                  isToAddress ? originalTx.to_address_contract_type : undefined,
                    security_score: isFromAddress ? originalTx.from_address_security_score : 
                                   isToAddress ? originalTx.to_address_security_score : undefined,
                    is_contract: isFromAddress ? originalTx.from_address_is_contract : 
                                isToAddress ? originalTx.to_address_is_contract : undefined,
                    is_token: isFromAddress ? originalTx.from_address_is_token : 
                             isToAddress ? originalTx.to_address_is_token : undefined,
                    token_symbol: isFromAddress ? originalTx.from_address_token_symbol : 
                                 isToAddress ? originalTx.to_address_token_symbol : undefined,
                    token_name: isFromAddress ? originalTx.from_address_token_name : 
                               isToAddress ? originalTx.to_address_token_name : undefined
                };

                nodeMap.set(nodeId, node);
                
                if (tx.receipt_contract_address && tx.receipt_contract_address.toLowerCase() === address) {
                    // SVG URLs for contract creation icons
                    const CONTRACT_ICONS = {
                        verified: 'https://cdn-icons-png.flaticon.com/512/2521/2521891.png', // Smart contract verified icon
                        unverified: 'https://cdn-icons-png.flaticon.com/512/2521/2521790.png', // Smart contract unverified icon
                        fallback: 'https://cdn-icons-png.flaticon.com/512/1355/1355961.png' // Generic document/contract icon
                    };
                    if (!node.logo) {
                        node.logo = CONTRACT_ICONS.unverified;
                    }
                }

                if (!nodesByChain.has(chainId)) {
                    nodesByChain.set(chainId, []);
                }
                nodesByChain.get(chainId)?.push(node);
            } else {
                // Add new timestamp to existing node's timestamps array
                const existingNode = nodeMap.get(nodeId)!;
                if (tx.block_timestamp && !existingNode.block_timestamps.includes(tx.block_timestamp)) {
                    existingNode.block_timestamps.push(tx.block_timestamp);
                    // Optional: Keep timestamps sorted
                    existingNode.block_timestamps.sort();
                }
            }
            return nodeMap.get(nodeId)!;
        };

        // Update the node creation calls to pass the transaction
        if (originalTx.from_address) addNode(originalTx.from_address, tx.chain_id, tx);
        if (originalTx.to_address && originalTx.to_address !== "null" && originalTx.to_address != "") addNode(originalTx.to_address, tx.chain_id, tx);

        // Add receipt_contract_address to the graph
        if (originalTx.receipt_contract_address && originalTx.receipt_contract_address !== "null" && originalTx.receipt_contract_address != "") {
            addNode(originalTx.receipt_contract_address, tx.chain_id, tx);

            // Add a node for the receipt_contract_address param addresses
            const receiptContractAddresses = extractAddressesFromTransaction(tx, true);
            receiptContractAddresses.forEach((address: AddressLocation) => {
                if (address.locations.some((location: any) => location.path.includes('decoded_call.params'))){
                    addNode(address.address, tx.chain_id, tx);
                }
            });
        }

        // Process proxy calls if they exist
        if (tx.decoded_call) {
            const { nodes: proxyNodes, links: proxyLinks } = processProxyCall(tx, createBaseLink);
            console.log(proxyLinks)
            // Add all nodes to the graph
            proxyNodes.forEach(address => {
                addNode(address.toLowerCase(), tx.chain_id, tx)
            });
            // Add all links to the graph
            proxyLinks.forEach(link => {
                result.links.push(link);
            });
        }
                
        // // it it is not a processed transaction with transfers/interactions, then we to add the main decoded call as a contract interaction
        if (tx.decoded_transaction?.decoded_call && !tx.erc20_transfers && !tx.nft_transfers && !tx.internal_transactions && !tx.contract_interactions) {
            const decodedCall = tx.decoded_transaction.decoded_call;
            // add the basic from to node
            let from = findParamInDecodedCall(decodedCall, ['from', '_from', 'src', 'sender']);
            let to = findParamInDecodedCall(decodedCall, ['to', '_to', 'dst', 'recipient']);
            const value = findParamInDecodedCall(decodedCall, ['_value', 'value', 'amount', 'wad']);
            if ((from && !to) || (to && !from)) {
                if (from && !to && tx.to_address && tx.to_address !== "null" && tx.to_address != "") {
                    to = tx.to_address;
                }
                if (to && !from && tx.from_address && tx.to_address && tx.to_address !== "null" && tx.to_address != "") {
                    from = tx.from_address;
                }
            }

            if (from && to) {
                addNode(from, tx.chain_id, tx);
                addNode(to, tx.chain_id, tx);
                
                // Add the link to the graph
                const link: GraphLink = {
                    id: `${tx.chain_id}_${tx.hash}_decoded_call_${from}_${to}`,
                    transaction_id: tx["user_id-block_timestamp-chain_id-hash-type"],
                    source: `${tx.chain_id}_${from}`,
                    target: `${tx.chain_id}_${to}`,
                    value: '0',
                    value_formatted: '0',
                    type: 'decoded_call',
                    linkLabel: 'Decoded Call',
                    linkColor: getLinkColor('decoded_call'),
                    arraySize: 5,
                    curved: 0,
                    block_timestamp: tx.block_timestamp,
                    chain_id: tx.chain_id,
                    hash: tx.hash,
                    transaction_hash: tx.hash,
                    method: tx.method,
                    method_hash: tx.method_hash,
                    method_label: tx.method_label,
                    decoded_input: tx.decoded_input,
                }
                result.links.push(link);       
            }     
        }

        // For other transaction types
        tx.erc20_transfers?.forEach((transfer: any) => {
            if (transfer.from_address) addNode(transfer.from_address, tx.chain_id, tx);
            if (transfer.to_address && transfer.to_address !== "null" && transfer.to_address != "") addNode(transfer.to_address, tx.chain_id, tx);
        });

        tx.nft_transfers?.forEach((transfer: any) => {
            if (transfer.from_address) addNode(transfer.from_address, tx.chain_id, tx);
            if (transfer.to_address && transfer.to_address !== "null" && transfer.to_address != "") addNode(transfer.to_address, tx.chain_id, tx);
        });

        tx.internal_transactions?.forEach((internal: any) => {
            if (internal.from) addNode(internal.from, tx.chain_id, tx);
            if (internal.to && internal.to !== "null" && internal.to != "") addNode(internal.to, tx.chain_id, tx);
        });

        // Process contract interactions dynamically
        if (tx.contract_interactions) {
            Object.entries(tx.contract_interactions).forEach(([interactionType, interactions]) => {
                // Type guard to ensure interactions is an array
                if (Array.isArray(interactions)) {
                    interactions.forEach((interaction: any) => {
                        // Handle approvals
                        if (interactionType === 'approvals' && interaction.spender) {
                            const link: GraphLink = {
                                id: `${tx.chain_id}_${tx.hash}_approval_${originalTx.from_address}_${interaction.spender.address}`,
                                transaction_id: tx["user_id-block_timestamp-chain_id-hash-type"],
                                type: 'contract_approval',
                                source: `${tx.chain_id}_${originalTx.from_address}`,
                                target: `${tx.chain_id}_${interaction.spender.address}`,
                                value: interaction.value || '0',
                                value_formatted: interaction.value_formatted || '0',
                                linkLabel: `Approval\n${interaction.token?.token_symbol || 'Token'}`,
                                linkColor: getLinkColor('contract_approval'),
                                arraySize: 5,
                                curved: 0,
                                block_timestamp: tx.block_timestamp,
                                // Required base properties
                                chain_id: tx.chain_id,
                                hash: tx.hash,
                                transaction_hash: tx.hash,
                                // Optional properties
                                token_symbol: interaction.token?.token_symbol,
                                token_name: interaction.token?.token_name,
                                token_logo: interaction.token?.token_logo,
                                gas: tx.gas,
                                gas_used: tx.gas_used,
                                gas_price: tx.gas_price,
                                transaction_fee: tx.transaction_fee,
                                status: tx.status,
                                receipt_status: tx.receipt_status,
                                possible_spam: tx.possible_spam,
                                category: tx.category
                            };
                            result.links.push(link);

                            // Add spender node if it doesn't exist
                            addNode(interaction.spender.address, tx.chain_id, {
                                ...tx,
                                to_address: interaction.spender.address,
                                to_address_label: interaction.spender.address_label,
                                to_address_entity: null,
                                to_address_entity_logo: null
                            });
                        } else {
                            // Handle other types of contract interactions
                            if (interaction.to_address && interaction.to_address !== "null" && interaction.to_address != "") {
                                const link: GraphLink = {
                                    id: `${tx.chain_id}_${tx.hash}_${interactionType}_${originalTx.from_address}_${interaction.to_address}`,
                                    transaction_id: tx["user_id-block_timestamp-chain_id-hash-type"],
                                    type: 'contract_interaction',
                                    source: `${tx.chain_id}_${originalTx.from_address}`,
                                    target: `${tx.chain_id}_${interaction.to_address}`,
                                    value: interaction.value || '0',
                                    value_formatted: interaction.value_formatted || '0',
                                    linkLabel: `${interactionType}\n${interaction.method || ''}`,
                                    linkColor: getLinkColor('contract_interaction'),
                                    arraySize: 5,
                                    curved: 0,
                                    block_timestamp: tx.block_timestamp,
                                    // Required base properties
                                    chain_id: tx.chain_id,
                                    hash: tx.hash,
                                    transaction_hash: tx.hash,
                                    // Optional properties
                                    method: interaction.method,
                                    method_label: interaction.method_label,
                                    gas: tx.gas,
                                    gas_used: tx.gas_used,
                                    gas_price: tx.gas_price,
                                    transaction_fee: tx.transaction_fee,
                                    status: tx.status,
                                    receipt_status: tx.receipt_status,
                                    possible_spam: tx.possible_spam,
                                    category: tx.category
                                };
                                result.links.push(link);

                                // Add contract node if it doesn't exist
                                addNode(interaction.to_address, tx.chain_id, {
                                    ...tx,
                                    to_address: interaction.to_address,
                                    to_address_label: interaction.address_label,
                                    to_address_entity: null,
                                    to_address_entity_logo: null
                                });
                            }
                        }
                    });
                }
            });
        }

        // Process decoded events if they exist
        if (settings.visual?.enableEventLogs && (tx.decoded_transaction?.decoded_events || tx.logs || tx.decoded_events)) {
            const { nodes, links } = processTransactionEvents(tx);
            
            // Add new nodes
            nodes.forEach(node => {
                if (!result.nodes.find(n => n.id === node.id)) {
                    // Add node if it doesn't exist
                    addNode(node.address, tx.chain_id, {
                        ...tx,
                        to_address: node.address,
                        to_address_label: null,
                        to_address_entity: null,
                        to_address_entity_logo: null
                    });
                }
            });
            
            // Add new links
            links.forEach(link => {
                if (!result.links.find(l => l.id === link.id)) {
                    result.links.push(link);
                }
            });
        }    
    });

    // Position nodes within their chain sections
    let xOffset = 0;
    nodesByChain.forEach((chainNodes, chainId) => {
        // Calculate grid dimensions based on settings
        const totalNodes = chainNodes.length;
        const nodesPerRow = settings.layout?.nodesPerRow || 10;
        const horizontalSpacing = settings.layout?.horizontalSpacing || 350;
        const verticalSpacing = settings.layout?.verticalSpacing || 400;
        const rows = Math.ceil(totalNodes / nodesPerRow);
        
        // Calculate total section dimensions with padding
        const sectionPadding = 150;
        const sectionWidth = (nodesPerRow * horizontalSpacing) + (sectionPadding * 2);
        const sectionHeight = (rows * verticalSpacing) + (sectionPadding * 2);
        
        // Sort nodes to put image nodes first
        const sortedNodes = [...chainNodes].sort((a, b) => {
            if (a.source && !b.source) return -1;
            if (!a.source && b.source) return 1;
            return 0;
        });
        
        sortedNodes.forEach((node, idx) => {
            const col = idx % nodesPerRow;
            const row = Math.floor(idx / nodesPerRow);
            
            // Add some randomness to prevent perfect grid alignment
            const jitter = node.source ? 20 : 40; // More jitter for text nodes
            const randomX = (Math.random() - 0.5) * jitter;
            const randomY = (Math.random() - 0.5) * jitter;
            
            // Calculate position within chain section, including padding
            node.x = xOffset + sectionPadding + (col * horizontalSpacing) + randomX + (horizontalSpacing / 2);
            node.y = sectionPadding + (row * verticalSpacing) + randomY + (verticalSpacing / 2);
        });
        
        // Update xOffset for next chain section
        xOffset += sectionWidth + 200; // Gap between chain sections
    });

    // Add all nodes and links to result
    result.nodes = Array.from(nodeMap.values());

    // Update link creation for each type
    dataToUse.forEach(tx => {
        // Main transaction link
        if (tx.from_address && tx.to_address && tx.to_address !== "null" && tx.to_address != "") {
            const baseLink = createBaseLink(tx, 'transfer', tx.from_address, tx.to_address, tx.chain_id);
            
            // Get the native transfer if it exists
            const nativeTransfer = tx.native_transfers?.[0];
            
            const link = {
                ...baseLink,
                method: tx.method,
                method_hash: tx.method_hash,
                method_label: tx.method_label,
                decoded_input: tx.decoded_input,
                token_symbol: nativeTransfer?.token_symbol || 'ETH',
                token_name: nativeTransfer?.token_name,
                token_logo: nativeTransfer?.token_logo,
                token_decimals: nativeTransfer?.token_decimals,
                verified_contract: tx.verified_contract,
                security_score: tx.security_score,
                contract_type: tx.contract_type,
                direction: nativeTransfer?.direction,
                value: nativeTransfer?.value || tx.value || '0',
                value_formatted: nativeTransfer?.value_formatted || tx.value_formatted || '0',
                summary: tx.summary,
                linkLabel: getHoverInfo(tx, 'transfer', nativeTransfer, chainIdToName),
                receipt_status: tx.receipt_status,
                block_timestamp: tx.block_timestamp
            };
            result.links.push(link);
        }

        // Contract creation
        if (tx.receipt_contract_address && tx.receipt_contract_address !== "null" && tx.receipt_contract_address != "") {
            const baseLink = createBaseLink(tx, 'contract_creation', tx.from_address, tx.receipt_contract_address, tx.chain_id);
            const link = {
                ...baseLink,
                method: tx.method,
                method_hash: tx.method_hash,
                method_label: tx.method_label,
                decoded_input: tx.decoded_input,
                token_symbol: 'ETH',
                token_name: 'Ether',
                token_logo: 'https://etherscan.io/images/svg/brands/eth.svg',
                token_decimals: 18,
                verified_contract: tx.verified_contract,
                security_score: tx.security_score,
                contract_type: tx.contract_type,
                direction: 'out',
                value: '0',
                value_formatted: '0',
                summary: tx.summary,
                linkLabel: 'Contract Creation',
                receipt_status: tx.receipt_status,
                block_timestamp: tx.block_timestamp,
                logo: 'https://cdn-icons-png.flaticon.com/512/2521/2521891.png'
            };
            result.links.push(link);

            // Add a link for each contructor address found
            const constructorAddresses = extractAddressesFromTransaction(tx, true);
            constructorAddresses.forEach((address: AddressLocation) => {
                if (address.locations.some((location: any) => location.path.includes('decoded_call.params'))){
                    const baseLink = createBaseLink(tx, 'contract_creation', tx.receipt_contract_address, address.address, tx.chain_id);
                    const link: GraphLink = {
                        ...baseLink,
                        linkLabel: address.locations.find((location: any) => location.path.includes('decoded_call.params'))?.description || 'Constructor',
                        receipt_status: tx.receipt_status,
                        block_timestamp: tx.block_timestamp
                    };
                    result.links.push(link);
                }
            });
        }
        
        // ERC20 transfers
        tx.erc20_transfers?.forEach((transfer: any) => {
            if (transfer.from_address && transfer.to_address && transfer.to_address !== "null" && transfer.to_address != "") {
                const baseLink = createBaseLink(tx, 'erc20', transfer.from_address, transfer.to_address, tx.chain_id);
                const link = {
                    ...baseLink,
                    value: transfer.value || '0',
                    value_formatted: transfer.value_formatted || '0',
                    token_symbol: transfer.token_symbol,
                    token_name: transfer.token_name,
                    token_logo: transfer.token_logo,
                    token_decimals: transfer.decimals,
                    direction: transfer.direction,
                    security_score: transfer.security_score,
                    verified_contract: transfer.verified_contract,
                    linkLabel: getHoverInfo(tx, 'erc20', transfer),
                    receipt_status: tx.receipt_status,
                    block_timestamp: tx.block_timestamp
                };
                result.links.push(link);
            }
        });

        // NFT transfers
        tx.nft_transfers?.forEach((transfer: any) => {
            if (transfer.from_address && transfer.to_address && transfer.to_address !== "null" && transfer.to_address != "") {
                const baseLink = createBaseLink(tx, 'nft', transfer.from_address, transfer.to_address, tx.chain_id);
                const link = {
                    ...baseLink,
                    token_id: transfer.token_id,
                    token_symbol: transfer.token_symbol,
                    token_name: transfer.token_name,
                    contract_type: transfer.contract_type,
                    amount: transfer.amount || '1',
                    direction: transfer.direction,
                    verified_collection: transfer.verified_collection,
                    possible_spam: transfer.possible_spam,
                    security_score: transfer.security_score,
                    linkLabel: getHoverInfo(tx, 'nft', transfer),
                    receipt_status: tx.receipt_status,
                    block_timestamp: tx.block_timestamp
                };
                result.links.push(link);
            }
        });

        // Internal transactions
        tx.internal_transactions?.forEach((internal: any) => {
            if (internal.from && internal.to && internal.to !== "null" && internal.to != "") {
                const baseLink = createBaseLink(tx, 'internal', internal.from, internal.to, tx.chain_id);
                const link = {
                    ...baseLink,
                    value: internal.value || '0',
                    value_formatted: internal.value_formatted || '0',
                    call_type: internal.call_type,
                    gas: internal.gas,
                    gas_used: internal.gas_used,
                    block_number: internal.block_number,
                    transaction_hash: internal.transaction_hash,
                    error: internal.error,
                    linkLabel: getHoverInfo(tx, 'internal', internal),
                    receipt_status: tx.receipt_status,
                    block_timestamp: tx.block_timestamp
                };
                result.links.push(link);
            }
        });
    });

    // Reset counts and values
    result.nodes.forEach(node => {
        node.outgoing_count = 0;
        node.incoming_count = 0;
        node.total_value = 0;
        node.token_values = {};
    });

    // Count all transactions and sum values
    result.links.forEach(link => {
        const sourceNode = result.nodes.find(node => node.id === link.source);
        const targetNode = result.nodes.find(node => node.id === link.target);

        // Handle outgoing/incoming counts
        if (sourceNode) sourceNode.outgoing_count++;
        if (targetNode) targetNode.incoming_count++;

        // Handle native transfers
        if (link.type === 'transfer' || link.type === 'internal') {
            const value = parseFloat(link.value_formatted || '0');
            if (value > 0) {
                const nativeSymbol = 'Native'; // or you could use chainIdToName to get specific chain native token

                // Add outgoing value to source node
                if (sourceNode) {
                    if (!sourceNode.token_values) sourceNode.token_values = {};
                    sourceNode.token_values[nativeSymbol] = (sourceNode.token_values[nativeSymbol] || 0) + value;
                    sourceNode.total_value = (sourceNode.total_value || 0) + value;
                }

                // Add incoming value to target node
                if (targetNode) {
                    if (!targetNode.token_values) targetNode.token_values = {};
                    targetNode.token_values[nativeSymbol] = (targetNode.token_values[nativeSymbol] || 0) + value;
                    targetNode.total_value = (targetNode.total_value || 0) + value;
                }
            }
        }

        // Handle ERC20 transfers
        if (link.type === 'erc20' && link.value_formatted) {
            const value = parseFloat(link.value_formatted);
            const tokenSymbol = link.token_symbol || 'Unknown Token';
            if (sourceNode) {
                if (!sourceNode.token_values) sourceNode.token_values = {};
                sourceNode.token_values[tokenSymbol] = (sourceNode.token_values[tokenSymbol] || 0) + value;
            }
            if (targetNode) {
                if (!targetNode.token_values) targetNode.token_values = {};
                targetNode.token_values[tokenSymbol] = (targetNode.token_values[tokenSymbol] || 0) + value;
            }
        }

        // Handle internal transactions
        if (link.type === 'internal' && link.value_formatted) {
            const value = parseFloat(link.value_formatted);
            const nativeSymbol = 'Native';
            if (sourceNode) {
                if (!sourceNode.token_values) sourceNode.token_values = {};
                sourceNode.token_values[nativeSymbol] = (sourceNode.token_values[nativeSymbol] || 0) + value;
                sourceNode.total_value = (sourceNode.total_value || 0) + value;
            }
            if (targetNode) {
                if (!targetNode.token_values) targetNode.token_values = {};
                targetNode.token_values[nativeSymbol] = (targetNode.token_values[nativeSymbol] || 0) + value;
                targetNode.total_value = (targetNode.total_value || 0) + value;
            }
        }

        // Handle contract interactions with value
        if (link.type === 'contract_interaction' && link.value_formatted) {
            const value = parseFloat(link.value_formatted);
            const nativeSymbol = 'Native';

            if (value > 0) {
                if (sourceNode) {
                    if (!sourceNode.token_values) sourceNode.token_values = {};
                    sourceNode.token_values[nativeSymbol] = (sourceNode.token_values[nativeSymbol] || 0) + value;
                    sourceNode.total_value = (sourceNode.total_value || 0) + value;
                }
                if (targetNode) {
                    if (!targetNode.token_values) targetNode.token_values = {};
                    targetNode.token_values[nativeSymbol] = (targetNode.token_values[nativeSymbol] || 0) + value;
                    targetNode.total_value = (targetNode.total_value || 0) + value;
                }
            }
        }
    });

    // Add off chain nodes
    if(settings.visual?.enableBridgeNodes) {
        const { nodes: offChainNodes, links: offChainLinks } = generateOffChainElements(dataToUse, result);
        
        if (offChainNodes) {
            offChainNodes.forEach((node: any) => {
                result.nodes.push(node);
            });
        }

        if (offChainLinks) {
            offChainLinks.forEach((link: any) => {
                result.links.push(link);
            });
        }
    }

    return result;
};

export const getLinkColor = (type: string): string => {
    switch(type) {
        case 'erc20':
            return '#2196f3';
        case 'native':
            return '#ff9800';
        case 'internal':
            return '#9c27b0';
        case 'nft':
            return '#e91e63';
        case 'nft_operator':
            return '#4caf50';
        case 'contract':
            return '#f44336';
        case 'contract_approval':
            return '#8bc34a';  // Green for approvals
        case 'contract_interaction':
            return '#ff5722';  // Orange for other contract interactions
        case 'proxy_implementation': return '#00BCD4';  // Cyan
        case 'proxy_param': return '#009688';  // Teal
        case 'proxy_creation': return '#E91E63';  // Pink
        case 'proxy_owner': return '#673AB7';  // Deep Purple            
        default:
            return '#999';
    }
};

export const calculateCurvature = (index: number, total: number): number => {
    if (total <= 1) return 0;
    const baseOffset = 0.2;
    const spacing = 0.1;
    return baseOffset + (index * spacing);
};

export const handleChainLayout = (nodes: GraphNode[], chainId: string) => {
    const chainNodes = nodes.filter(n => n.chain_id === chainId);
    return chainNodes;
};



export const formatTxHash = (hash: string): string => {
    if (!hash) return '';
    return `${hash.slice(0, 6)}...${hash.slice(-4)}`;
};

// Helper function to safely format text
const formatText = (text: string | undefined | null, maxLength: number = 30) => {
    if (!text || text === '') return 'N/A';
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

// Add icon mapping for link types
export const getLinkIcon = (type: string): string => {
    switch(type) {
        case 'erc20':
            return '💰';  // Money bag for ERC20
        case 'native':
            return '💎';  // Gem for native transfers
        case 'internal':
            return '🔄';  // Arrows for internal transactions
        case 'nft':
            return '🎨';  // Palette for NFTs
        case 'nft_operator':
            return '🎮';  // Game controller for NFT operators
        case 'contract':
            return '📝';  // Document for contracts
        case 'contract_approval':
            return '🔑';  // Key for approvals
        case 'contract_interaction':
            return '🔧';  // Wrench for interactions
        default:
            return '↔️';  // Default arrows
    }
};

// Helper function to get main transaction type emoji
export const getMainTransactionIcon = (tx: any): string => {
    if (tx.category === 'token_approval') return '🔑'; // Approval
    if (tx.category === 'contract_interaction') return '🔧'; // Contract interaction
    if (tx.category === 'airdrop') return '🎁'; // Airdrop
    if (tx.type === 'contract_call') return '��'; // Contract call
    if (tx.value && parseFloat(tx.value) > 0) return '💰'; // Value transfer
    if (tx.method_label?.toLowerCase().includes('swap')) return '🔄'; // Swap
    if (tx.method_label?.toLowerCase().includes('bridge')) return '🌉'; // Bridge
    if (tx.method_label?.toLowerCase().includes('stake')) return '🏦'; // Stake
    if (tx.method_label?.toLowerCase().includes('mint')) return '🎨'; // Mint
    if (tx.method_label?.toLowerCase().includes('burn')) return '🔥'; // Burn
    if (tx.method_label?.toLowerCase().includes('vote')) return '🗳️'; // Vote
    return '↔️'; // Default transfer
};

// Helper function to format Wei to native token
const formatWeiToNative = (wei: string) => {
    const value = BigInt(wei);
    const nativeValue = Number(value) / 1e18;
    return nativeValue.toFixed(6).replace(/\.?0+$/, '');
};

// Update getHoverInfo for internal transactions
const getHoverInfo = (tx: any, type: string, transfer?: any, chainIdToName?: Record<string, string>) => {
    const icon = getLinkIcon(type);
    
    switch (type) {
        case 'erc20':
            return [
                `${icon} ERC20 Transfer`,
                `${transfer?.token_logo ? '🖼️' : '🪙'} Token: ${formatText(transfer?.token_name || transfer?.token_symbol || 'Token')}${transfer?.token_logo ? ` (${transfer?.token_symbol})` : ''}`,
                `📊 Amount: ${transfer?.value_formatted || '0'} ${transfer?.token_symbol}`,
                `️ Direction: ${formatText(transfer?.direction || 'transfer')}`,
                `🔒 Security: ${transfer?.security_score || 'N/A'}`,
                transfer?.verified_contract ? '✅ Status: Verified' : null
            ].filter(Boolean).join('\n');

        case 'nft':
            return [
                `${icon} NFT Transfer`,
                `🎨 Collection: ${formatText(transfer?.token_name || 'Unknown')}`,
                `🔢 Token ID: ${formatText(transfer?.token_id)}`,
                `📊 Amount: ${transfer?.amount || '1'}`,
                `💰 Value: ${transfer?.value_formatted || '0'} ${transfer?.token_symbol || 'ETH'}`,
                `↔️ Direction: ${formatText(transfer?.direction || 'transfer')}`,
                transfer?.contract_type && `📝 Type: ${formatText(transfer?.contract_type)}`,
                transfer?.verified_collection && '✅ Verified Collection',
                transfer?.possible_spam && '⚠️ Warning: Possible Spam',
                transfer?.security_score && `🔒 Security: ${transfer?.security_score}`
            ].filter(Boolean).join('\n');

        case 'internal':
            return [
                `${icon} Internal Transaction`,
                `�� Amount: ${formatWeiToNative(transfer?.value || '0')} ${chainIdToName?.[tx.chain_id]?.split(' ')[0] || 'Native'}`,
                transfer?.call_type && `📝 Type: ${formatText(transfer?.call_type || 'Call')}`,
                transfer?.gas && `⛽ Gas Limit: ${transfer?.gas.toLocaleString()}`,
                transfer?.gas_used !== undefined && `⛽ Gas Used: ${transfer?.gas_used.toLocaleString()}`,
                transfer?.block_number && `🔢 Block: ${transfer?.block_number.toLocaleString()}`,
                transfer?.transaction_hash && `📝 Hash: ${formatText(transfer?.transaction_hash, 12)}`
            ].filter(Boolean).join('\n');

        case 'contract':
        case 'contract_interaction':
        case 'contract_approval':
            return [
                `${icon} Contract ${tx.category === 'token_approval' ? 'Approval' : 'Interaction'}`,
                tx.method_label && `📝 Method: ${formatText(tx.method_label)}`,
                tx.method && `🔧 Function: ${formatText(tx.method)}`,
                tx.value && `💰 Value: ${tx.value_formatted || tx.value} ${tx.token_symbol || 'ETH'}`,
                tx.token_name && `🪙 Token: ${formatText(tx.token_name)} (${tx.token_symbol})`,
                tx.spender && `👤 Spender: ${formatText(tx.spender)}`,
                tx.gas_used && `⛽ Gas Used: ${tx.gas_used}`,
                tx.transaction_fee && `💸 Fee: ${tx.transaction_fee} ETH`,
                tx.contract_type && `📋 Type: ${formatText(tx.contract_type)}`,
                tx.security_score && `🔒 Security: ${tx.security_score}`,
                tx.verified_contract && '✅ Verified Contract',
                tx.possible_spam && '⚠️ Warning: Possible Spam',
                tx.status === '1' ? '✅ Success' : tx.status === '0' ? '❌ Failed' : null
            ].filter(Boolean).join('\n');

        default:
            const mainIcon = getMainTransactionIcon(tx);
            const nativeTransfer = transfer;  // transfer parameter is nativeTransfer for main links
            return [
                `${mainIcon} ${tx.summary || 'Transaction'}`,
                tx.method_label && `📝 Method: ${formatText(tx.method_label)}`,
                tx.method && `🔧 Function: ${formatText(tx.method)}`,
                `💰 Value: ${nativeTransfer?.value_formatted || tx.value_formatted || '0'} ${nativeTransfer?.token_symbol || 'ETH'}`,
                tx.gas_used && `⛽ Gas Used: ${tx.gas_used.toLocaleString()}`,
                tx.transaction_fee && `💸 Fee: ${tx.transaction_fee} ETH`,
                tx.category && `📋 Category: ${formatText(tx.category)}`,
                tx.status === '1' ? '✅ Success' : tx.status === '0' ? '❌ Failed' : null
            ].filter(Boolean).join('\n');
    }
};

// Add these color conversion utilities
export const rgbToHsl = (r: number, g: number, b: number): [number, number, number] => {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    const l = (max + min) / 2;

    if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return [h, s, l];
};
  
export const hslToRgb = (h: number, s: number, l: number): [number, number, number] => {
    const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
    };

    let r, g, b;

    if (s === 0) {
        r = g = b = l;
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        
        r = hue2rgb(p, q, (h / 360 + 1/3));
        g = hue2rgb(p, q, h / 360);
        b = hue2rgb(p, q, (h / 360 - 1/3));
    }

    return [
        Math.round(r * 255),
        Math.round(g * 255),
        Math.round(b * 255)
    ];
};

function processTransactionEvents(transaction: any) {
    const nodes: { [key: string]: GraphNode } = {};
    const links: GraphLink[] = [];
    const chain_id = transaction.chain_id;
    
    // Process each decoded event
    if (transaction.decoded_transaction?.decoded_events || transaction.logs || transaction.decoded_events) {
        const decoded_events = transaction.decoded_transaction?.decoded_events || transaction.logs || transaction.decoded_events;
        
        // Handle both array and object structures
        const eventsArray = Array.isArray(decoded_events) ? decoded_events : Object.values(decoded_events);
        eventsArray.forEach((event: any) => {  

            // Normalize the event structure
            const normalizedEvent = {
                ...event,
                label: event.decoded_event?.label || event.label,
                params: event.decoded_event?.params || event.params,
                signature: event.decoded_event?.signature || event.signature,
                summary: event.decoded_event?.summary || event.summary,
                type: event.decoded_event?.type || event.type,
                address: event.decoded_event?.address || event.address,
                name: event.decoded_event?.name || event.name
            };

            const decodedEvent = normalizedEvent as DecodedEvent;

            if (!decodedEvent?.params) return;
         
            // Add the contract node
            const contractNodeId = addNodeIfNotExists(transaction, nodes, chain_id, decodedEvent.address.toLowerCase());

            // Create TransactionLog
            const log: TransactionLog = {
                decoded_event: decodedEvent,
                data: "0x",
                block_number: transaction.block_number,
                block_hash: transaction.block_hash,
                transaction_index: transaction.transaction_index || "0",
                log_index: "0",
                block_timestamp: transaction.block_timestamp,
                transaction_value: transaction.value || "0",
                transaction_hash: transaction.hash,
                topic0: decodedEvent.signature, // Add actual topics if available
                topic1: decodedEvent.topic1,
                topic2: decodedEvent.topic2,
                topic3: decodedEvent.topic3,
                address: decodedEvent.address,
            };

            // Common parameters for links
            const baseLink = {
                transaction_id: transaction["user_id-block_timestamp-chain_id-hash-type"],
                hash: transaction.hash,
                transaction_hash: transaction.hash,
                chain_id,
                curved: 0.2,
                logs: [log],
                timestamp: transaction.block_timestamp, // Add timestamp to base link
            };

            // Process based on event type
            switch (decodedEvent.name) {
                case 'Transfer': {
                    const from = findParamInEventValue(decodedEvent, ['from', 'src', 'sender']);
                    const to = findParamInEventValue(decodedEvent, ['to', 'dst', 'recipient']);
                    const value = findParamInEventValue(decodedEvent, ['value', 'amount', 'wad']);

                    if (from && to && value) {
                        // Ensure both nodes exist
                        const sourceId = addNodeIfNotExists(transaction, nodes, chain_id, from);
                        const targetId = addNodeIfNotExists(transaction, nodes, chain_id, to);

                        // Get token info from address_items_map
                        const tokenInfo = transaction.address_items_map?.[decodedEvent.address];
                        const tokenSymbol = tokenInfo?.labels?.[0]?.name || 'Token';

                        links.push({
                            ...baseLink,
                            source: sourceId,
                            target: targetId,
                            id: `${chain_id}_${transaction.hash}_${decodedEvent.address}_${from}_${to}`,
                            type: 'transfer',
                            linkLabel: `Transfer\n${transaction.address_items_map?.[decodedEvent.address]?.labels?.[0]?.name || decodedEvent.address}`,
                            receipt_status: transaction.receipt_status,
                            linkColor: '#1890ff',
                            arraySize: 3,
                            curved: 0.2,
                            chain_id: chain_id,
                            value: value,
                            value_formatted: value,
                            logs: [log],
                            block_timestamp: transaction.block_timestamp, // Ensure timestamp is included
                        });
                    }
                    break;
                }
                case 'Swap': {
                    const sender = findParamInEventValue(decodedEvent, ['sender']);
                    const recipient = findParamInEventValue(decodedEvent, ['recipient']);
                    const amount0 = findParamInEventValue(decodedEvent, ['amount0']);
                    const amount1 = findParamInEventValue(decodedEvent, ['amount1']);

                    if (sender && recipient) {
                        const sourceId = addNodeIfNotExists(transaction, nodes, chain_id, sender);
                        const targetId = addNodeIfNotExists(transaction, nodes, chain_id, recipient);

                        links.push({
                            ...baseLink,
                            source: sourceId,
                            target: targetId,
                            id: `${chain_id}_${transaction.hash}_${decodedEvent.address}_swap_${sender}_${recipient}`,
                            linkLabel: `Swap\n${decodedEvent.address}`,
                            receipt_status: transaction.receipt_status,
                            arraySize: 4,
                            curved: 0.3,
                            value: amount0?.toString() || '0',
                            value_formatted: amount0?.toString() || '0',
                            value1: amount1?.toString() || '0',
                            value1_formatted: amount1?.toString() || '0',
                            type: 'swap',
                            linkColor: '#1890ff',
                            block_timestamp: transaction.block_timestamp, // Ensure timestamp is included
                        });
                    }
                    break;
                }
                // Add more event types as needed
                default: {
                    // Handle any other event types with a generic interaction link
                    const sender = findParamInEventValue(decodedEvent, ['sender', 'from']) || transaction.from_address;
                    const recipient = findParamInEventValue(decodedEvent, ['recipient', 'to']) || decodedEvent.address;

                    if (sender && recipient) {
                        const sourceId = addNodeIfNotExists(transaction, nodes, chain_id, sender);
                        const targetId = addNodeIfNotExists(transaction, nodes, chain_id, recipient);

                        links.push({
                            ...baseLink,
                            source: sourceId,
                            target: targetId,
                            id: `${chain_id}_${transaction.hash}_${decodedEvent.address}_${decodedEvent.name}_${sender}_${recipient}`,
                            linkLabel: `${decodedEvent.name}\n${decodedEvent.address}`,
                            receipt_status: transaction.receipt_status,
                            arraySize: 2,
                            curved: 0.1,
                            value: '0',
                            value_formatted: '0',
                            type: 'contract_interaction',
                            linkColor: getLinkColor('contract_interaction'),
                            logs: [log],
                            block_timestamp: transaction.block_timestamp, // Ensure timestamp is included
                        });
                    }
                }
            }
        });
    }

    // Add nodes to result first
    const resultNodes = Object.values(nodes);

    return {
        nodes: resultNodes,
        links
    };
}
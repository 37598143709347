import { DeleteButton } from '@refinedev/antd';
import { List, Avatar, Space, Button, Skeleton, Divider, Typography, Modal, Form, SelectProps, Spin, Select, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useModalForm } from '@refinedev/antd';
import { useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { IUser } from '../../../interfaces/user.d';

export interface DebounceSelectPropsTransactionShow<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

const { Title } = Typography;

interface SharedUser {
    id: string;
    email: string;
}

interface SharedWithUsersProps {
    sharedWith?: SharedUser[];
    ownerId?: string;
    transactionId: string;
    token: any;
    region: any;
    continent: string;
    domain: string;
    qa_environment: string;
}

export const SharedWithUsers: React.FC<SharedWithUsersProps> = ({
    sharedWith,
    ownerId,
    transactionId,
    token,
    region,
    continent,
    domain,
    qa_environment,
}) => {
    const [value, setValue] = useState<UserValue[]>([]);

    function DebounceSelect<
      ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
      >({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectPropsTransactionShow<ValueType>) {
      const [fetching, setFetching] = useState(false);
      const [options, setOptions] = useState<ValueType[]>([]);
      const fetchRef = useRef(0);

        const debounceFetcher = useMemo(() => {
            const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
            };

            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);

        return (
            <Select
                labelInValue
                filterOption={false}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                {...props}
                options={options}
            />
        );
    }

    // Usage of DebounceSelect
    interface UserValue {
        label: string;
        value: string;
    }
    
    const {
        modalProps,
        formProps,
        show: showModal,
        close: closeModal,
        onFinish
    } = useModalForm({
        action: "create",
        resource: "transactions-share-with",
        warnWhenUnsavedChanges: false,
        syncWithLocation: false,
        disableServerSideValidation: true,
        onMutationSuccess: () => {
            closeModal();
        },
    });

    const fetchUserList = async (username: string) => {
        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": region.use_region,
            "Destination-Region": region.use_region,
        };

        const apiUrl = "https://" + region.use_region_alias + "." + continent.toLowerCase() + ".api." + domain;
        const url = qa_environment === "blue"
            ? `${apiUrl}/management/permissions/blue/GET/${continent.toUpperCase()}/v0/users`
            : `${apiUrl}/management/permissions/GET/${continent.toUpperCase()}/v0/users`;
        
        const response = await fetch(url, { headers: requestHeaders });
        const body = await response.json();

        return body.result
            ?.filter((user: IUser) => 
                user.status !== "disabled" && 
                user.status !== "pending_invitation"
            )
            .map((user: IUser) => ({
                label: `${user.name} - ${user.email}`,
                value: user.email,
            }));
    };

    const onFinishShareWith = (values: any) => {
        console.log(values);
        const shared_with_to_add_array = values.shared_with
            .map((item: UserValue) => item.value);

        const new_obj = {
            id: transactionId,
            shared_with_to_add: shared_with_to_add_array,
            shared_with_to_remove: []
        };

        onFinish?.(new_obj);
        closeModal();
    };

    return (
        <>
            <Title level={5}>
                Transaction shared with users:
                <Button 
                    type="primary" 
                    onClick={() => showModal()} 
                    style={{ marginLeft: 16 }}
                >
                    Share with Users
                </Button>
            </Title>

            {/* Share With Modal */}
            <Modal {...modalProps} width="1000" centered title="Share Transaction">
                <Form {...formProps} onFinish={onFinishShareWith}>
                    <Form.Item 
                        label="Share the transaction with the following people in the organization"
                        name="shared_with"
                        rules={[{ required: false }]}
                    >
                        <DebounceSelect
                            mode="multiple"
                            value={value}
                            placeholder="Select users"
                            fetchOptions={fetchUserList}
                            onChange={(newValue: UserValue | UserValue[]) => {
                                setValue(newValue as UserValue[]);
                            }}
                            style={{ width: 500 }}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            {/* Shared Users List */}
            {sharedWith && (
                <InfiniteScroll
                    dataLength={10}
                    next={() => {}}
                    hasMore={false}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={sharedWith}
                        renderItem={(user, index) => (
                            <List.Item key={user.id}>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar 
                                            src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} 
                                        />
                                    }
                                    title={<a href="https://ant.design">{user.id}</a>}
                                    description={user.email}
                                />
                                <Space>
                                    {ownerId === user.id && (
                                        <Button size="small">
                                            Owner
                                        </Button>
                                    )}
                                    {ownerId !== user.id && (
                                        <Tooltip title="Remove user from transaction">
                                            <DeleteButton
                                                size="small"
                                                hideText={true}
                                                recordItemId={transactionId}
                                                resource="transactions-share-with"
                                                meta={{ remove_user_email: user.email }}
                                            />
                                        </Tooltip>
                                    )}
                                </Space>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            )}
        </>
    );
};
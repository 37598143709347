import React, { useState } from 'react';
import { Typography, Space, Card, Divider, Tag, Tooltip, Button, Modal } from 'antd';
import { CopyOutlined, ArrowRightOutlined, CodeOutlined, LinkOutlined } from '@ant-design/icons';
import { AddressData, getAddressInfo } from '../../../utils/addressUtils';
import { getTagColorByParamType, getTooltipTitleForParamType } from '../../../utils/formatters';
import { renderAddressAvatar } from 'components/address-avatar';

const { Text, Paragraph } = Typography;

interface InternalTransactionProps {
    transaction: {
        type?: string;
        from?: string;
        to?: string;
        value?: string;
        gas?: string;
        gas_used?: string;
        input?: {
            label?: string;
            type?: string;
            params?: any[];
            signature?: string;
        } | string;
        output?: string;
        block_number?: number;
        transaction_hash?: string;
        chain_id?: string;
        from_metadata?: {
            labels?: string[];
            entities?: string[];
            tags?: string[];
        };
        to_metadata?: {
            labels?: string[];
            entities?: string[];
            tags?: string[];
        };
        block_timestamp?: string;
    };
    addressData: AddressData;
    chainId?: string;
}

export const InternalTransactionView: React.FC<InternalTransactionProps> = ({
    transaction,
    addressData,
    chainId
}) => {
    const [showRawData, setShowRawData] = useState(false);
    const [showAllParams, setShowAllParams] = useState(false);
    
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const formatTimestamp = (timestamp: string) => {
        return new Date(timestamp).toLocaleString();
    };

    const formatValue = (value: string) => {
        const eth = Number(value) / 1e18;
        return `${eth.toFixed(6)} ETH`;
    };

    const openAbiDecoder = (input: string) => {
        window.open(`https://cleanunicorn.github.io/santoku/#/decode/${input}`, '_blank');
    };
    
    const renderParamValue = (param: any, depth = 0): React.ReactNode => {
        const indent = depth * 16;

        if (param.type === 'tuple') {
            return (
                <Space direction="vertical" style={{ marginLeft: indent }}>
                    {param.value.map((item: any, index: number) => (
                        <Space direction="vertical" key={index} size={2}>
                            <Space>
                                <Text type="secondary">{item.name}:</Text>
                                <Tag color={getTagColorByParamType(item.type)}>{item.type}</Tag>
                            </Space>
                            {renderParamValue(item, depth + 1)}
                        </Space>
                    ))}
                </Space>
            );
        }

        if (param.type === 'tuple[]') {
            return (
                <Space direction="vertical" style={{ marginLeft: indent }}>
                    {param.value.map((tupleItem: any[], index: number) => (
                        <Space direction="vertical" key={index} size={2}>
                            <Text type="secondary">[{index}]:</Text>
                            {tupleItem.map((item: any, subIndex: number) => (
                                <Space direction="vertical" key={subIndex} style={{ marginLeft: 16 }}>
                                    <Space>
                                        <Text type="secondary">{item.name}:</Text>
                                        <Tag color={getTagColorByParamType(item.type)}>{item.type}</Tag>
                                    </Space>
                                    {renderParamValue(item, depth + 2)}
                                </Space>
                            ))}
                        </Space>
                    ))}
                </Space>
            );
        }

        if (param.type === 'bytes[]') {
            return (
                <Space direction="vertical" style={{ marginLeft: indent }}>
                    {param.value.map((item: any, index: number) => (
                        <Space direction="vertical" key={index} size={2}>
                            <Text type="secondary">[{index}]:</Text>
                            {typeof item === 'object' && item.value && typeof item.value === 'object' ? (
                                <Space direction="vertical" style={{ marginLeft: 16 }}>
                                    {Object.entries(item.value)
                                        .sort(([keyA], [keyB]) => {
                                            const numA = parseInt(keyA.split('_')[1]);
                                            const numB = parseInt(keyB.split('_')[1]);
                                            return numA - numB;
                                        })
                                        .map(([key, fieldValue]: [string, any]) => (
                                            <Space key={key}>
                                                <Text type="secondary">{key}:</Text>
                                                <Tag color={getTagColorByParamType(fieldValue.type)}>
                                                    {fieldValue.type}
                                                </Tag>
                                                <Text code>{fieldValue.value.toString()}</Text>
                                            </Space>
                                        ))}
                                </Space>
                            ) : (
                                <Text code copyable style={{ fontSize: '14px' }}>
                                    {item.value || item}
                                </Text>
                            )}
                        </Space>
                    ))}
                </Space>
            );
        }

        // Handle bytes with nested structure
        if (param.type === 'bytes' && typeof param.value === 'object' && param.value.type === 'bytes') {
            return (
                <Space direction="vertical" style={{ marginLeft: indent }}>
                    {typeof param.value.value === 'object' ? (
                        Object.entries(param.value.value)
                            .sort(([keyA], [keyB]) => {
                                const numA = parseInt(keyA.split('_')[1]);
                                const numB = parseInt(keyB.split('_')[1]);
                                return numA - numB;
                            })
                            .map(([key, fieldValue]: [string, any]) => (
                                <Space key={key}>
                                    <Text type="secondary">{key}:</Text>
                                    <Tag color={getTagColorByParamType(fieldValue.type)}>
                                        {fieldValue.type}
                                    </Tag>
                                    <Text code>{fieldValue.value.toString()}</Text>
                                </Space>
                            ))
                    ) : (
                        <Text code copyable style={{ fontSize: '14px' }}>
                            {param.value.value}
                        </Text>
                    )}
                </Space>
            );
        }

        if (param.type === 'address') {
            return (
                <Tooltip title={getTooltipTitleForParamType(addressData, param)}>
                    <Text code copyable style={{ fontSize: '14px' }}>
                        {param.value}
                    </Text>
                </Tooltip>
            );
        }

        if (param.type === 'bytes' || param.type === 'string') {
            const isLongValue = param.value.length > 66;
            const displayValue = isLongValue ? `${param.value.slice(0, 66)}...` : param.value;
            return (
                <Tooltip title={param.value}>
                    <Text code copyable style={{ fontSize: '14px' }}>
                        {displayValue}
                    </Text>
                </Tooltip>
            );
        }

        return <Text code>{param?.value?.toString()}</Text>;
    };

    const renderFunctionParams = (params: any[]) => {
        return (
            <Space direction="vertical" size={4} style={{ width: '100%' }}>
                {params.map((param, index) => (
                    <Space direction="vertical" size={0} key={index} style={{ width: '100%' }}>
                        <Space>
                            <Text type="secondary">{param.name || `param${index}`}</Text>
                            <Tag color="blue">{param.type}</Tag>
                        </Space>
                        {renderParamValue(param)}
                    </Space>
                ))}
            </Space>
        );
    };

    const renderDataField = (label: string, data: any) => {
        if (!data || data === '0x') return null;

        // Handle decoded function input
        if (typeof data === 'object' && data.type === 'function') {
            return (
                <Space direction="vertical" size={0}>
                    <Text type="secondary">{label}</Text>
                    <Space direction="vertical" size={4}>
                        <Text strong>{data.label}</Text>
                        <Text code type="secondary">
                            {data.signature}
                        </Text>
                        {data.params && data.params.length > 0 && (
                            <Card size="small" bordered={false} style={{ background: '#fafafa' }}>
                                {renderFunctionParams(data.params)}
                            </Card>
                        )}
                    </Space>
                </Space>
            );
        }

        // Handle raw hex data
        const isLongData = typeof data === 'string' && data.length > 66;
        const displayData = isLongData ? `${data.slice(0, 66)}...` : data;

        return (
            <Space direction="vertical" size={0}>
                <Space align="center">
                    <Text type="secondary">{label}</Text>
                    {isLongData && (
                        <Space size={4}>
                            <Button 
                                type="link" 
                                size="small"
                                icon={<LinkOutlined/>}
                                onClick={() => openAbiDecoder(data)}
                            >
                                Decode with Santoku
                            </Button>
                            <Button
                                type="link"
                                size="small"
                                icon={<CodeOutlined/>}
                                onClick={() => setShowRawData(true)}
                            >
                                View Raw
                            </Button>
                        </Space>
                    )}
                </Space>
                {typeof data === 'string' && (
                    <Tooltip title="Click to copy">
                        <Text 
                            code 
                            style={{ 
                                cursor: 'pointer',
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100%'
                            }}
                            onClick={() => copyToClipboard(data)}
                        >
                            {displayData} <CopyOutlined style={{ fontSize: '12px' }} />
                        </Text>
                    </Tooltip>
                )}
            </Space>
        );
    };

    return (
        <Card size="small" bordered>
            <Space direction="vertical" size="small" style={{ width: '100%' }}>
                {/* Header Section */}
                <Space align="center" wrap>
                    {transaction.input && typeof transaction.input === 'object' && transaction.input.type === 'function' && (
                        <Tag color="blue">{transaction.input.label}</Tag>
                    )}
                    {transaction.block_number && (
                        <Text type="secondary">
                            Block #{transaction.block_number}
                            {transaction.block_timestamp && (
                                <> ({formatTimestamp(transaction.block_timestamp)})</>
                            )}
                        </Text>
                    )}
                </Space>

                {/* Addresses and Value Section */}
                <Space wrap style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Space direction="vertical" size={1}>
                        <Space align="center" size="small">
                            <Text type="secondary">From:</Text>
                            <Space>
                                <Tooltip title={transaction.from}>               
                                    <Text code copyable style={{ fontSize: '14px' }}>
                                        {transaction.from}
                                    </Text>
                                </Tooltip>
                                {renderAddressAvatar(addressData, 'from', transaction.from || '')}
                            </Space>
                        </Space>
                        <Space align="center" size="small">
                            <Text type="secondary">To:</Text>
                            <Space>
                                <Tooltip title={transaction.to}>               
                                    <Text code copyable style={{ fontSize: '14px' }}>
                                        {transaction.to}
                                    </Text>
                                </Tooltip>
                                {renderAddressAvatar(addressData, 'to', transaction.to || '')}
                            </Space>
                        </Space>
                    </Space>
                    <Space wrap>
                        {transaction.value && Number(transaction.value) > 0 && (
                            <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                <Text type="secondary">Value</Text>
                                <Text strong>{formatValue(transaction.value)}</Text>
                            </Space>
                        )}
                        {transaction.gas && (
                            <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                <Text type="secondary">Gas Limit</Text>
                                <Text>{transaction.gas}</Text>
                            </Space>
                        )}
                        {transaction.gas_used && (
                            <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                <Text type="secondary">Gas Used</Text>
                                <Text>{transaction.gas_used}</Text>
                            </Space>
                        )}
                    </Space>
                </Space>

                {/* Function Call Details */}
                {transaction.input && typeof transaction.input === 'object' && (
                    <>
                        <Divider style={{ margin: '8px 0' }} />
                        <Space direction="vertical" size={2}>
                            <Text type="secondary">Function Call</Text>
                            <Text strong>{transaction.input.label}</Text>
                            <Text code type="secondary">{transaction.input.signature}</Text>
                            
                            {/* Parameters Section */}
                            {transaction.input.params && transaction.input.params.length > 0 && (
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    <Space align="center" style={{ justifyContent: 'space-between', width: '100%' }}>
                                        <Text type="secondary">Parameters</Text>
                                        {transaction.input.params.length > 10 && (
                                            <Button
                                                type="link"
                                                size="small"
                                                onClick={() => setShowAllParams(!showAllParams)}
                                            >
                                                {showAllParams ? 'Show Less' : 'Show All'}
                                            </Button>
                                        )}
                                    </Space>
                                    <Space direction="vertical" size={8} style={{ width: '100%' }}>
                                        {(showAllParams ? transaction.input.params : transaction.input.params.slice(0, 10))
                                            .map((param, index) => (
                                                <Card 
                                                    key={index} 
                                                    size="small" 
                                                    bordered={false}
                                                    style={{ background: '#fafafa' }}
                                                >
                                                    <Space direction="vertical" size={2}>
                                                        <Space>
                                                            <Text type="secondary">{param.name}:</Text>
                                                            <Tag color={getTagColorByParamType(param.type)}>
                                                                {param.type}
                                                            </Tag>
                                                        </Space>
                                                        {renderParamValue(param)}
                                                    </Space>
                                                </Card>
                                            ))}
                                    </Space>
                                </Space>
                            )}
                        </Space>
                    </>
                )}

                {/* View Raw Button */}
                <Button
                    type="link"
                    size="small"
                    icon={<CodeOutlined/>}
                    onClick={() => setShowRawData(true)}
                >
                    View Raw
                </Button>
            </Space>

            {/* Raw Data Modal */}
            <Modal
                title="Raw Transaction Data"
                open={showRawData}
                onCancel={() => setShowRawData(false)}
                footer={null}
                width={800}
            >
                <pre style={{ 
                    maxHeight: '70vh', 
                    overflow: 'auto',
                    backgroundColor: '#f5f5f5',
                    padding: '16px',
                    borderRadius: '4px'
                }}>
                    {JSON.stringify(transaction, null, 2)}
                </pre>
            </Modal>
        </Card>
    );
}; 